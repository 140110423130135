import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useReactToPrint } from 'react-to-print';
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import logo from '../../Assets/img/logo.png';
import '../../Body/BodyStatements/AccountsClientStatement.css';
import GetClientByName from '../../Hooks/BackendConn/ClientList/GetClientByName';
import GetPoliciesByClientName from '../../Hooks/BackendConn/Policies/GetPoliciesByClientName';

const PrintClientStatement = (props) => {
    const clientList = props.onePropUp.postedDocRef[0];
    const fromDate = props.onePropUp.fromDate;
    const toDate = props.onePropUp.toDate;

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //#################################
    //from date echo
    const [echoFromDate, setEchoFromDate] = useState('');
    useEffect(() => {
        new Date('2021-05-01')
        const echoFromDay = new Date(fromDate).toLocaleString('en-us', { day: '2-digit' });
        const echoFromMonth = new Date(fromDate).toLocaleString('en-us', { month: 'short' });
        const echoFromYear = new Date(fromDate).getFullYear();
        const echoDate = echoFromDay + '-' + echoFromMonth + '-' + echoFromYear;
        setEchoFromDate(echoDate);
    }, [fromDate]);

    //#################################
    //to date echo
    const [echoToDate, setEchoToDate] = useState('');
    useEffect(() => {
        const toFromDay = new Date(toDate).toLocaleString('en-us', { day: '2-digit' });
        const toFromMonth = new Date(toDate).toLocaleString('en-us', { month: 'short' });
        const toFromYear = new Date(toDate).getFullYear();
        const toDatee = toFromDay + '-' + toFromMonth + '-' + toFromYear;
        setEchoToDate(toDatee);
    }, [toDate]);

    //#################################
    //client details
    const [selectedClient, setSelectedClient] = useState({});
    (async () => {
        const a = await GetClientByName(clientList);
        setSelectedClient(a)
    })();
console.log(selectedClient)
    //#################################
    //policy docs
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);
    (async () => {
        const a = await GetPoliciesByClientName(clientList);
        setReceivedPolicyDocs(a)
    })();
console.log(receivedPolicyDocs)
    //#################################    
    //array to be used for ageing
    const [filteredDatesPolicyDocs, setFilteredDatesPolicyDocs] = useState([]);
    useEffect(() => {
        const PeriodSelected = [];
        receivedPolicyDocs.map((item) => {
            // if (new Date(item.filterDate).valueOf() >= new Date(fromDate).valueOf() && new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.policyStatus !== 'Pending') {
            //     PeriodSelected.push(item);
            // }
            if (new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.policyStatus !== 'Pending') {
                PeriodSelected.push(item);
            }
        })
        setFilteredDatesPolicyDocs(PeriodSelected)
    }, [receivedPolicyDocs, toDate, fromDate])

    //#################################   
    //filter client
    const [clientReceivedPolicyDocs, setClientReceivedPolicyDocs] = useState([]);
    const [finalFinal, setFinalFinal] = useState([]);
    useEffect(() => {
        const clientSortedData = [];
        filteredDatesPolicyDocs.map((item) => {
            // if (item.clientName.includes(selectedClient.clientName) && selectedClient.clientName !== '' && item.status !== 'Pending') {
            //     clientSortedData.push(item)
            // }
            if (item.clientName === selectedClient.clientName && item.transactionType === 'Debit' && item.status !== 'Pending') {
                clientSortedData.push(item)
            }
            else if (item.clientName === selectedClient.clientName && item.transactionType === 'Credit') {
                clientSortedData.push(item)
            }
            else if (item.clientName.includes(selectedClient.clientName) && item.transactionType === 'Receipt') {
                clientSortedData.push(item)
            }
        })
        setClientReceivedPolicyDocs(clientSortedData);
        setFinalFinal(clientSortedData);

    }, [filteredDatesPolicyDocs])

    


    return (
        <section>
            <div className='body_section_header'>
                <div className='left_content'>
                    <p>Bulk Print Client Statement</p>
                </div>
            </div>
            {/* {ClientStatementContent} */}
        </section>
    )
}

export default PrintClientStatement;