import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { payrollMonthFilter, payrollMainDataSelectedRows } from "../../../atoms";
import Loader from "../Loader/Loader";
import Worker from './Payroll.Worker.js';
import PayroleMainData from './PayrollMainData';
import PayroleStatutoryDeductions from './PayrollStatutoryDeductions';
import { Stepper } from '@mantine/core';
import { utils, writeFile } from 'xlsx';
import PayrollSummary from "./PayrollSummary";
import { useReactToPrint } from 'react-to-print';
import DateFormat from "../../Hooks/UI/DateFormat";
import UUID from './../../Hooks/UUID/UUID';
import styles from './Payslip.module.css';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import NumberFormat from "../../Hooks/UI/NumberFormat";
import Payslip from "./Payslip";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Payroll = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //printing payslip
    const componentRefPaySlip = useRef();
    const handlePaySlipPrint = useReactToPrint({
        content: () => componentRefPaySlip.current,
    });

    //################################################################
    //recoil values
    const payrollMonthFilterRN = useRecoilValue(payrollMonthFilter);
    const payrollMainDataSelectedRowsRN = useRecoilValue(payrollMainDataSelectedRows);

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    const [finalNHIFStatutoryDeduction, setFinalNHIFStatutoryDeduction] = useState([]);
    const [finalNSSFStatutoryDeduction, setFinalNSSFStatutoryDeduction] = useState([]);
    const [finalPAYEStatutoryDeduction, setFinalPAYEStatutoryDeduction] = useState([]);
    const [finalHousingLevyStatutoryDeduction, setfinalHousingLevyStatutoryDeduction] = useState([]);
    const [finalNITAStatutoryDeduction, setFinalNITAStatutoryDeduction] = useState([]);
    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = { payrollMonthFilterRN: payrollMonthFilterRN }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setIsLoading(false);
                setFinalData(event.data.finalData);
                setFinalNHIFStatutoryDeduction(event.data.finalNHIFStatutoryDeduction);
                setFinalNSSFStatutoryDeduction(event.data.finalNSSFStatutoryDeduction);
                setFinalPAYEStatutoryDeduction(event.data.finalPAYEStatutoryDeduction);
                setfinalHousingLevyStatutoryDeduction(event.data.finalHousingLevyStatutoryDeduction);
                setFinalNITAStatutoryDeduction(event.data.finalNITAStatutoryDeduction)
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [payrollMonthFilterRN])
    
    //################################################################
    //combined data
    const [combinedData, setCombinedData] = useState([]);
    useEffect(() => {
        try {

            const a = [...finalNHIFStatutoryDeduction, ...finalNSSFStatutoryDeduction, ...finalPAYEStatutoryDeduction, ...finalHousingLevyStatutoryDeduction, ...finalNITAStatutoryDeduction];
            setCombinedData(a);

        } catch (error) { }
    }, [finalNHIFStatutoryDeduction, finalNSSFStatutoryDeduction, finalPAYEStatutoryDeduction, finalHousingLevyStatutoryDeduction, finalNITAStatutoryDeduction]);


    //################################################################
    //export nssf statutory deductions
    const exportNSSF = useCallback(() => {
        try {
            /* generate worksheet from state */
            const ws = utils.json_to_sheet(finalNSSFStatutoryDeduction);
            /* create workbook and append worksheet */
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "NSSF STATUTORY DEDUCTIONS");
            /* export to XLSX */
            writeFile(wb, "NSSF STATUTORY DEDUCTIONS.xlsx");
        } catch (error) {
            console.log(error)
        }
    }, [finalNSSFStatutoryDeduction]);

    //################################################################
    //export NHIF statutory deductions
    const exportNHIF = useCallback(() => {
        try {
            /* generate worksheet from state */
            const ws = utils.json_to_sheet(finalNHIFStatutoryDeduction);
            /* create workbook and append worksheet */
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "NHIF STATUTORY DEDUCTIONS");
            /* export to XLSX */
            writeFile(wb, "NHIF STATUTORY DEDUCTIONS.xlsx");
        } catch (error) {
            console.log(error)
        }
    }, [finalNHIFStatutoryDeduction]);

    //################################################################
    //export PAYE statutory deductions
    const exportPAYE = useCallback(() => {
        try {
            /* generate worksheet from state */
            const ws = utils.json_to_sheet(finalPAYEStatutoryDeduction);
            /* create workbook and append worksheet */
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "PAYE STATUTORY DEDUCTIONS");
            /* export to XLSX */
            writeFile(wb, "PAYE STATUTORY DEDUCTIONS.xlsx");
        } catch (error) {
            console.log(error)
        }
    }, [finalPAYEStatutoryDeduction]);

    //################################################################
    //export PAYE statutory deductions
    const exportPayroll = useCallback(() => {
        try {
            /* generate worksheet from state */
            const ws = utils.json_to_sheet(finalData);
            /* create workbook and append worksheet */
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "PAYROLL");
            /* export to XLSX */
            writeFile(wb, "PAYROLL.xlsx");
        } catch (error) {
            console.log(error)
        }
    }, [finalData]);

    //################################################################
    //print all
    const printNetPay = () => {

        const printData = [];
        const a = finalData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.name || '',
                doc.kraPin || '',
                doc.nhifNo || '',
                doc.nssfNo || '',
                NumberFormat(doc.basicSalary) || '',
                NumberFormat(doc.NSSFAmount) || '',
                NumberFormat(doc.taxablePayAmount) || '',
                NumberFormat(doc.incomeTaxAmount) || '',
                NumberFormat(doc.NHIFInsuranceRelief) || '',
                NumberFormat(doc.personalReliefAmount) || '',
                NumberFormat(doc.PAYEEAmount) || '',
                NumberFormat(doc.payAfterTaxAmount) || '',
                NumberFormat(doc.NHIFAmount) || '',
                NumberFormat(doc.housingLevyAmount) || '',
                NumberFormat(doc.netPayAmount) || '',
            ])
        });


        const onlyDispatchedheaderData = 'NET PAY SUMMARY    ||    PERIOD:' + DateFormat(payrollMonthFilterRN) + '    ||    DATE PRINTED: ' + DateFormat(new Date());

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 16, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'NAME', 'KRA PIN', 'NSSF NO', 'NHIF NO', 'BASIC SALARY', 'NSSF', 'TAXABLE PAY', 'INCOME TAX', 'NHIF INSURANCE RELIEF', 'PERSONAL RELIEF', 'PAYE', 'PAY AFTER TAX', 'NHIF', 'HOUSING LEVY', 'NET PAY'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //steper  
    // Allow the user to freely go back and forth between visited steps.
    const [active, setActive] = useState(0);
    const shouldAllowSelectStep = (step) => {
        const back = active - step;
        const forward = step - active;
        return back == 1 || forward == 1 && active !== step
    }

    //################################################################
    //content
    const content = <section>
        <div className="h_8"></div>
        <div>
            <Stepper active={active} onStepClick={setActive} breakpoint="xs">
                <Stepper.Step
                    label="First Step"
                    description="Additions / Deductions"
                    allowStepSelect={shouldAllowSelectStep(0)}
                >


                </Stepper.Step>
                <Stepper.Step
                    label="Second Step"
                    description="Payrole"
                    allowStepSelect={shouldAllowSelectStep(1)}
                >
                    <PayroleMainData finalData={finalData} />

                </Stepper.Step>
                <Stepper.Step
                    label="Third Step"
                    description="Statutory"
                    allowStepSelect={shouldAllowSelectStep(2)}
                >
                    <PayroleStatutoryDeductions finalData={combinedData} />

                </Stepper.Step>
            </Stepper>
        </div>
    </section>

    const payslip = <section>
        <div ref={componentRefPaySlip}>
            {
                Array.isArray(payrollMainDataSelectedRowsRN)
                && payrollMainDataSelectedRowsRN.map((employee, index) => {
                    return (
                        <Payslip key={index} employee={employee} payrollMonthFilterRN={payrollMonthFilterRN} />
                    )
                })
            }
        </div>
    </section>

    //################################################################
    //payroll summary
    const payrollSummary = <div style={{ display: "none" }}>
        <div ref={componentRef}>
            <PayrollSummary
                finalData={finalData}
                finalNHIFStatutoryDeduction={finalNHIFStatutoryDeduction}
                finalNSSFStatutoryDeduction={finalNSSFStatutoryDeduction}
                finalPAYEStatutoryDeduction={finalPAYEStatutoryDeduction}
                finalHousingLevyStatutoryDeduction={finalHousingLevyStatutoryDeduction}
                finalNITAStatutoryDeduction={finalNITAStatutoryDeduction}
                payrollMonthFilterRN={payrollMonthFilterRN}
            />
            |</div>
    </div>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Payroll</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrint}>Print Summary</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printNetPay}>Print Net Pay</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePaySlipPrint}>Print Pay Slip</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={exportPayroll}>Export Net Pay</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={exportNSSF}>Export NSSF</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={exportNHIF}>Export NHIF</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={exportPAYE}>Export PAYE</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {payrollSummary}
            {payslip}
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default Payroll;