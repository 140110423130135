import React, { useState, useEffect, useRef } from 'react';
import GetAllMotor from '../../Hooks/BackendConn/GeneralList/GetAllMotor';
import DateFormat from '../../Hooks/UI/DateFormat';
import Paginate from '../BodyPolicy/Paginate';
import Loader from '../Loader/Loader';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { conclusiveMotorReportFilterFromDate, conclusiveMotorReportFilterToDate } from '../../../atoms';
import './ConclusiveMotorReport.css'

const ConclusiveMotorReport = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //#####################################################################
    //recoil values to control valuation content
    const conclusiveMotorReportFilterFromDateRN = useRecoilValue(conclusiveMotorReportFilterFromDate)
    const conclusiveMotorReportFilterToDateRN = useRecoilValue(conclusiveMotorReportFilterToDate)

    //################################################################
    //get all motor
    const [motorDocs, setMotorDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllMotor();
            setMotorDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //extract individual veh
    const [individualVehicle, setIndividualVehicle] = useState([])
    useEffect(() => {
        const vehicles = []
        motorDocs.map((doc) => {
            Object.values(doc.docData.vehicleItems).map((vehicle) => {
                vehicles.push({ docData: doc.docData, docID: doc.docID, vehicle: vehicle })
            })
        });
        setIndividualVehicle(vehicles)
    }, [motorDocs])

    //################################################################
    //sort by date
    const [dateSortedVehicles, setDateSortedVehicles] = useState([]);
    useEffect(() => {
        const sortedList = individualVehicle.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.inceptionDate).valueOf() - new Date(a.docData.inceptionDate).valueOf();
        }
        setDateSortedVehicles(sortedList);
    }, [individualVehicle])

    //################################################################
    //filter date
    const [dateFilteredVehicles, setDateFilteredVehicles] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        dateSortedVehicles.map((doc) => {
            if (new Date(doc.docData.inceptionDate).valueOf() >= new Date(conclusiveMotorReportFilterFromDateRN).valueOf() && new Date(conclusiveMotorReportFilterToDateRN).valueOf() >= new Date(doc.docData.inceptionDate).valueOf()) {
                periodSelected.push(doc);
            }
        })
        setDateFilteredVehicles(periodSelected)
    }, [dateSortedVehicles, conclusiveMotorReportFilterFromDateRN, conclusiveMotorReportFilterToDateRN]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedVehicles, setSearchedVehicles] = useState([])
    useEffect(() => {
        const c = [];
        const values = dateFilteredVehicles.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.vehicle.regNo.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedVehicles(c);
    }, [dateFilteredVehicles, searchTerm])

    //################################################################
    //paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedVehicles.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    function sortValuationsByDate(a, b) {
        return new Date(b.documentDateOfValuation.seconds * 1000).valueOf() - new Date(a.documentDateOfValuation.seconds * 1000).valueOf();
    }

    function sortByDate(a, b) {
        return new Date(b.documentUploadDate.seconds * 1000).valueOf() - new Date(a.documentUploadDate.seconds * 1000).valueOf();
    }

    function sortPolicyDocsByDate(a, b) {
        return new Date(b.documentUploadDate.seconds * 1000).valueOf() - new Date(a.documentUploadDate.seconds * 1000).valueOf();
    }

    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);
        }

        setRightClickedPolicy(e.target.id)
    };

    const uploadDocumentsHandler = () => { 
        const onePropObj = {
            setView: 'Policy Search',
            previousView: 'Conclusive Motor Report',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={uploadDocumentsHandler}><p>Upload Documents</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // content
    const conclusiveMotorReportContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Policy No. / Endorsment No. / Veh Plate No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='conslusive_motor_report_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><center><p>VR</p></center></td>
                                <td><center><p>LB</p></center></td>
                                <td><center><p>SC</p></center></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPosts.map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.vehicle.regNo}</p></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Valuation Report')
                                                            .sort(sortValuationsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Logbook')
                                                            .sort(sortByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Motor Certificate')
                                                            .sort(sortByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.associateDocuments
                                                        ? doc.docData.associateDocuments
                                                            .filter(item => item.documentUploadCategory === 'Policy Document')
                                                            .sort(sortPolicyDocsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tbody>

                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedVehicles.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // content
    const conclusiveMotorReportPrintableContent = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Policy No. / Endorsment No. / Veh Plate No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='conslusive_motor_report_table' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Motor Report</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {DateFormat(conclusiveMotorReportFilterFromDateRN)} - {DateFormat(conclusiveMotorReportFilterToDateRN)}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><center><p>VR</p></center></td>
                                <td><center><p>LB</p></center></td>
                                <td><center><p>SC</p></center></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedVehicles.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td><p>{doc.vehicle.regNo}</p></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Valuation Report')
                                                            .sort(sortValuationsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Logbook')
                                                            .sort(sortByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.toLowerCase().includes(item.documentVehiclePlateNo.toLowerCase()) && item.documentCategory === 'Motor Certificate')
                                                            .sort(sortByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.associateDocuments
                                                        ? doc.docData.associateDocuments
                                                            .filter(item => item.documentUploadCategory === 'Policy Document')
                                                            .sort(sortPolicyDocsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedVehicles.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='conclusive_motor_report body_input_styles'>
            <div className='body_section_header'>
                <p>Motor Report</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {conclusiveMotorReportContent}
                {conclusiveMotorReportPrintableContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default ConclusiveMotorReport;