import React from "react";
import GetCookie from "../Hooks/Cookie/GetCookie";
import { IconChevronRight } from '@tabler/icons-react';
import { Group, Avatar, Text, Menu, UnstyledButton } from '@mantine/core';
import styles from './MainNavFinal.module.css';
import { IconSettings, IconBellRinging, IconCalendarEvent, IconActivity, } from '@tabler/icons-react';
import { signOut } from "firebase/auth";
import { auth } from '../../firebase'

const MainNavFinal = (props) => {
  //current user
  const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

  //################################################################
  //content
  const clientProfileSettings = () => {
    const onePropObj = {
      setView: 'Employee Profile',
      previousView: '',
      postedDocRef: '',
      dateFrom: '',
      dateTo: '',
      searchTerm: '',
      notification: '',
    }
    props.onePropDown(onePropObj)
  }
  const clientProfileNotification = () => {
    const onePropObj = {
      setView: "Employee Notification",
      previousView: "",
      postedDocRef: "",
      dateFrom: "",
      dateTo: "",
      searchTerm: "",
      notification: "",
    };
    props.onePropDown(onePropObj);
  };

  //################################################################
  //sign out
  const signOutHandler = () => {
    signOut(auth).then(() => {
      // Sign-out successful.            
    }).catch((error) => {
      // An error happened.
    });
  }

  //################################################################
  //content
  const content = (
    <section className={` align-middle ${styles.main_nav_items}`}>
      <div></div>
      <div className={`align-middle ${styles.main_nav_item_right}`}>
        <Group position="right" sx={{ height: "100%" }}>
          <Menu
            width={200}
            shadow="md"
            withArrow
            trigger="hover"
            openDelay={100}
            closeDelay={400}
          >
            <Menu.Target>
              <UnstyledButton
                sx={(theme) => ({
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : theme.black,

                  "&:hover": {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.colors.gray[0],
                  },
                })}
              >
                <Group>
                  {/* <Avatar src='https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80' radius="xl" /> */}
                  <Avatar src={null} alt="It's Me" color="indigo" />
                  <div style={{ flex: 1 }}>
                    {/* <Text size="sm" weight={500}>
                            Collins
                        </Text> */}

                    <Text color="dimmed" size="xs">
                      {currentUser && currentUser.userEmail}
                    </Text>
                  </div>

                  {<IconChevronRight size="1rem" />}
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item icon={<IconSettings size={14} />}>
                {" "}
                <p className="f_9" onClick={clientProfileSettings}>
                  Settings{" "}
                </p>
              </Menu.Item>
              <Menu.Item icon={<IconBellRinging size={14} />}>
                <p className="f_9" onClick={clientProfileNotification}>
                  Notification
                </p>
              </Menu.Item>
              <Menu.Item icon={<IconCalendarEvent size={14} />}>
                Events
              </Menu.Item>
              <Menu.Item
                icon={<IconActivity size={14} />}
                rightSection={
                  <Text size="xs" color="dimmed">
                    ⌘K
                  </Text>
                }
              >
                Account Activity
              </Menu.Item>
              <Menu.Label>Danger zone</Menu.Label>
              <Menu.Item icon={<IconSettings size={14} />}><p onClick={signOutHandler}>Log Out</p></Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </div>
    </section>
  );



  return (
    <section className={styles.main_nav_final}>
      {content}
    </section>
  );
}

export default MainNavFinal;