import { useRecoilState } from "recoil";
import { receivedDocumentsFilterFromDate, receivedDocumentsFilterToDate } from "../../../atoms";
import DatePicker from "react-date-picker";

const ReceivedDocumentsReportFilter = () => {
    //################################################################
    //get recoil values
    const [receivedDocumentsFilterFromDateRN, setReceivedDocumentsFilterFromDateRN] = useRecoilState(receivedDocumentsFilterFromDate);
    const [receivedDocumentsFilterToDateRN, setReceivedDocumentsFilterToDateRN] = useRecoilState(receivedDocumentsFilterToDate);

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setReceivedDocumentsFilterFromDateRN} value={receivedDocumentsFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setReceivedDocumentsFilterToDateRN} value={receivedDocumentsFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
        </section>
    )
}

export default ReceivedDocumentsReportFilter;