import React, { useState, useCallback, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import db from '../../../firebase';
import Loader from '../Loader/Loader';
import DatePicker from 'react-date-picker';
import Paginate from '../BodyPolicy/Paginate';
import './DispatchAccountsClearance.css';

const DispatchAccountsClearance = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //###########################################
    //get user input
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    //from date
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])

    //to date
    const [toDate, setToDate] = useState(new Date());

    const [tvalue, onTChange] = useState(new Date('2022-12-31'));
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //###########################################
    //right nav content
    //show hide filter content
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    //filter date content
    const filterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>

    //###########################################
    // change dispatch date
    //to date
    const [selectedDispatchDate, setSelectedDispatchDate] = useState('');
    const [selectedDispatchDateDoc, setSelectedDispatchDateDoc] = useState('');

    const [ddvalue, onDDChange] = useState(new Date());
    const DDyear = new Date(ddvalue).getFullYear();
    const DDmonth = new Date(ddvalue).getMonth() + 1;
    const DDdate = new Date(ddvalue).getDate();
    const DDCleaned = DDyear + '-' + DDmonth + '-' + DDdate;

    useEffect(() => {
        setSelectedDispatchDate(DDCleaned)
    }, [DDCleaned])

    //show hide filter content
    const [dispatchDateRightNav, setDispatchDateRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideDispatchDateRightNav = (instruction, selectedDocID) => {
        if (instruction === 'Show') {
            setDispatchDateRightNav('receipt_allocation_rightNav');
            setSelectedDispatchDateDoc(selectedDocID);
        }
        if (instruction === 'Hide') { setDispatchDateRightNav('receipt_allocation_rightNav hide') }
    }

    //filter date content
    const dispatchDateContent = <div className={dispatchDateRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideDispatchDateRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Update Dispatch Date</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Select Date</p>
                        <DatePicker onChange={onDDChange} value={ddvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>

    //###########################################  
    //make api call
    const [accountsClearanceDocs, setAccountsClearanceDocs] = useState([]);
    const [error, setError] = useState('');

    const fetchDispatchAccountClearanceDocs = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const q = query(collection(db, "PolicyDetails"), where("level", "==", "Accounts Clearing"));


            const querySnapshot = await getDocs(q);
            // console.log(querySnapshot.data.length)
            const a = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                a.push({ docData: doc.data(), docID: doc.id })
            });
            if (a.length < 1) {
                //console.log('ff');
                fetchDispatchAccountClearanceDocs();
                setError('error')
            }
            else {
                //console.log('gg')
                setAccountsClearanceDocs(a);
                setIsLoading(false);
            }
        }
        catch (error) {
            setError(error.message);

            fetchDispatchAccountClearanceDocs();
        }


    }, []);

    useEffect(() => {
        fetchDispatchAccountClearanceDocs();
    }, [fetchDispatchAccountClearanceDocs]);

    //clean data
    const [cleanedAccountsClearanceDocs, setCleanedAccountsClearanceDocs] = useState([]);
    useEffect(() => {
        const RawData = []
        accountsClearanceDocs.map((item, index) => {
            //filter date
            const IDate = item.docData.insurerDebitDate;
            const fYear = new Date(IDate).getFullYear();
            const fMonth = new Date(IDate).getMonth() + 1;
            const fDate = new Date(IDate).getDate();
            const filterDate = fYear + '-' + fMonth + '-' + fDate
            //echo inception date
            const iEchoDate = item.docData.inceptionDate;
            const dayInc = new Date(iEchoDate).toLocaleString('en-us', { day: '2-digit' });
            const monthInc = new Date(iEchoDate).toLocaleString('en-us', { month: 'short' });
            const yearInc = new Date(iEchoDate).getFullYear();
            const echoInceptionDate = dayInc + '-' + monthInc + '-' + yearInc;
            //echo insurer debit date
            const iDEchoDate = item.docData.insurerDebitDate;
            const dayExp = new Date(iDEchoDate).toLocaleString('en-us', { day: '2-digit' });
            const monthExp = new Date(iDEchoDate).toLocaleString('en-us', { month: 'short' });
            const yearExp = new Date(iDEchoDate).getFullYear();
            const echoInsurerDebitDate = dayExp + '-' + monthExp + '-' + yearExp;
            //client veh
            const clientVehicleItems = []
            Object.values(item.docData.vehicleItems).map((veh) => {
                if (!veh.regNo) { clientVehicleItems.push('') }
                else { clientVehicleItems.push(veh.regNo) }
            });
            //client veh to lowecase
            const vehicleItems = []
            Object.values(item.docData.vehicleItems).map((veh) => {
                if (!veh.regNo) { vehicleItems.push('') }
                else { vehicleItems.push((veh.regNo).toLowerCase()) }

            });
            let dispatchData = '';
            if (!item.docData.dispatchStatus.date) { }
            else {
                const DispatchEchoDate = item.docData.dispatchStatus.date;
                const dayInc = new Date(DispatchEchoDate).toLocaleString('en-us', { day: '2-digit' });
                const monthInc = new Date(DispatchEchoDate).toLocaleString('en-us', { month: 'short' });
                const yearInc = new Date(DispatchEchoDate).getFullYear();
                const echoDispatchDate = dayInc + '-' + monthInc + '-' + yearInc;
                dispatchData = echoDispatchDate;
            }
            const a = {
                docID: item.docID,
                clientName: item.docData.clientName,
                insurerName: item.docData.insurerName,
                bankInterest: item.docData.bankInterest,
                policyNumber: item.docData.policyNumber,
                endorsmentNumber: item.docData.endorsmentNumber,
                selectedProductClass: item.docData.selectedProductClass,
                selectedProductSubClass: item.docData.selectedProductSubClass,
                inceptionDate: item.docData.inceptionDate,
                expiryDate: item.docData.expiryDate,
                insurerDebitDate: item.docData.insurerDebitDate,
                filterDate: filterDate,
                echoInceptionDate: echoInceptionDate,
                echoInsurerDebitDate: echoInsurerDebitDate,
                locationOfTheRisk: item.docData.locationOfTheRisk,
                totalSumInsured: item.docData.totalSumInsured,
                policyNet: item.docData.policyNet,
                dispatchStatus: item.docData.dispatchStatus,
                vehicleItems: vehicleItems,
                clientVehicleItems: clientVehicleItems,
                dispatchData: dispatchData,
            }
            RawData.push(a)
        });
        setCleanedAccountsClearanceDocs(RawData);

    }, [accountsClearanceDocs])

    //###########################################
    // sort by date
    const [dateSortedAccountsClearanceDocs, setDateSortedAccountsClearanceDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = cleanedAccountsClearanceDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(b.filterDate).valueOf() - new Date(a.filterDate).valueOf();
        }
        setDateSortedAccountsClearanceDocs(dateSortedList);
    }, [cleanedAccountsClearanceDocs]);

    //###########################################
    //filter date
    const [dateFilterAccountsClearanceDocs, setDateFilterAccountsClearanceDocs] = useState([]);
    useEffect(() => {
        const dateFilteredList = [];
        dateSortedAccountsClearanceDocs.map((item) => {
            if (new Date(item.filterDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.filterDate).valueOf()) {
                dateFilteredList.push(item);
            }
        })
        setDateFilterAccountsClearanceDocs(dateFilteredList)
    }, [dateSortedAccountsClearanceDocs, fromDate, toDate])

    //###########################################
    //search
    const [searchedAccountsClearanceDocs, setSearchedAccountsClearanceDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = dateFilterAccountsClearanceDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.endorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else {
                const vhArr = val.vehicleItems;
                for (var i = 0; i < vhArr.length; i++) {
                    if (vhArr[i].includes(searchTerm.toLowerCase())) {
                        return val;

                    }
                }
            }

        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedAccountsClearanceDocs(c);
    }, [dateFilterAccountsClearanceDocs, searchTerm])
    //############################################

    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedAccountsClearanceDocs.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm])
    //###########################################
    //confirm changes
    function ConfirmChangesAction() {
        var answer = window.confirm("Confirm You Want To Update Document Status?")
        if (answer) {
            return true;
        }
        else {
            return false
        }
    }

    //###########################################
    //reload after clearing
    const reloadAfterClearingDoc = () => {
        const onePropObj = {
            setView: 'Accounts Clearance',
            previousView: 'Accounts Clearance',
            postedDocRef: '',
            dateFrom: fromDate,
            dateTo: toDate,
            searchTerm: '',
            notification: 'Update Successful'
        }
        props.onePropDown(onePropObj)
    }

    //###########################################
    //sticker issued
    async function StickerIssuedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.stickerIssued': 'Yes'
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //revert sticker issued
    async function RevertStickerIssuedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.stickerIssued': ''
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //sticker dispatched
    async function StickerDispatchedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.stickerDispatched': 'Yes'
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //revert sticker dispatched
    async function RevertStickerDispatchedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.stickerDispatched': ''
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //valuation received
    async function ValuationReceivedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.valuationReceived': 'Yes'
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //revert valuation received
    async function RevertValuationReceivedHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.valuationReceived': ''
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //statement of account
    async function StatementOfAccountHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.statementOfAccount': 'Yes'
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //revert statement of account
    async function RevertStatementOfAccountHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.statementOfAccount': ''
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //statement of account
    async function DispatchToClientsHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.dispatchToClients': 'Yes'
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //###########################################
    //revert statement of account
    async function RevertDispatchToClientsHandler(DocID) {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", DocID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.dispatchToClients': ''
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    async function ChangeUpdateDispatchDate() {
        const DocStatusUpdateAction = ConfirmChangesAction();
        if (!DocStatusUpdateAction) { return false }
        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", selectedDispatchDateDoc);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'dispatchStatus.date': selectedDispatchDate
            });
            setIsLoading(false);
            reloadAfterClearingDoc();
            fetchDispatchAccountClearanceDocs();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }
    useEffect(() => {
        if (selectedDispatchDateDoc) { ChangeUpdateDispatchDate() }
    }, [selectedDispatchDate])

    //##########################################
    //accounts clearance content
    const accountsClearingContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy No. / Endorsment No. / Vehicle Plate No.' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='responsive_table'>
                        <table className='dispatch_accounts_clearance_table'>
                            <thead>
                                <tr>
                                    <td><p>No</p></td>
                                    <td><p>Insured</p></td>
                                    <td><p>Policy Number</p></td>
                                    <td><p>Endorsment Number</p></td>
                                    <td><p>Insurer Debit Date</p></td>
                                    <td><p>Details</p></td>
                                    <td><p>Sticker Issued</p></td>
                                    <td><p>Sticker Dispatched</p></td>
                                    <td><p>Valuation</p></td>
                                    <td><p>Statement</p></td>
                                    <td><p>Dispatch To Client</p></td>
                                    <td><p>Dispatch Date</p></td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{item.id}</p></td>
                                            <td><p>{item.clientName}</p></td>
                                            <td><p>{item.policyNumber}</p></td>
                                            <td><p>{item.endorsmentNumber}</p></td>
                                            <td><p>{item.echoInsurerDebitDate}</p></td>
                                            <td>
                                                <p>
                                                    {!item.locationOfTheRisk ? '' : item.locationOfTheRisk}
                                                    {!item.clientVehicleItems[0] ? '' : item.clientVehicleItems.map((item) => { return item + ',   ' })}
                                                </p>
                                            </td>
                                            {item.selectedProductClass === '(07) - Motor Private' || item.selectedProductClass === '(08) - Motor Commercial'
                                                ?
                                                !item.dispatchStatus.stickerIssued
                                                    ? <td onClick={() => StickerIssuedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-history-line'></i>
                                                        </center>
                                                    </td>
                                                    : <td onClick={() => RevertStickerIssuedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-check-line'></i>
                                                        </center>
                                                    </td>
                                                :
                                                <td><center><p>N/A</p></center></td>
                                            }
                                            {item.selectedProductClass === '(07) - Motor Private' || item.selectedProductClass === '(08) - Motor Commercial'
                                                ?
                                                !item.dispatchStatus.stickerDispatched
                                                    ? <td onClick={() => StickerDispatchedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-history-line'></i>
                                                        </center>
                                                    </td>
                                                    : <td onClick={() => RevertStickerDispatchedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-check-line'></i>
                                                        </center>
                                                    </td>
                                                :
                                                <td><center><p>N/A</p></center></td>
                                            }
                                            {item.selectedProductClass === '(07) - Motor Private' || item.selectedProductClass === '(08) - Motor Commercial'
                                                ? item.policyNumber.includes('/COMP/')
                                                    ?
                                                    !item.dispatchStatus.valuationReceived
                                                        ? <td onClick={() => ValuationReceivedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                            <center>
                                                                <i className='ri-history-line'></i>
                                                            </center>
                                                        </td>
                                                        : <td onClick={() => RevertValuationReceivedHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                            <center>
                                                                <i className='ri-check-line'></i>
                                                            </center>
                                                        </td>
                                                    :
                                                    <td><center><p>N/A</p></center></td>
                                                :
                                                <td><center><p>N/A</p></center></td>

                                            }
                                            {
                                                !item.dispatchStatus.statementOfAccount
                                                    ? <td onClick={() => StatementOfAccountHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-history-line'></i>
                                                        </center>
                                                    </td>
                                                    : <td onClick={() => RevertStatementOfAccountHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-check-line'></i>
                                                        </center>
                                                    </td>
                                            }
                                            {
                                                !item.dispatchStatus.dispatchToClients
                                                    ? <td onClick={() => DispatchToClientsHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-history-line'></i>
                                                        </center>
                                                    </td>
                                                    : <td onClick={() => RevertDispatchToClientsHandler(item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-check-line'></i>
                                                        </center>
                                                    </td>
                                            }
                                            {
                                                !item.dispatchStatus.date
                                                    ? <td onClick={() => showHideDispatchDateRightNav('Show', item.docID)} className='clear_for_dispatch_btn'>
                                                        <center>
                                                            <i className='ri-history-line'></i>
                                                        </center>
                                                    </td>
                                                    : <td onClick={() => showHideDispatchDateRightNav('Show', item.docID)}>
                                                        <p>{item.dispatchData}</p>
                                                    </td>
                                            }

                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        <Paginate postsPerPage={postsPerPage} totalPosts={searchedAccountsClearanceDocs.length} paginate={paginate} />
                    </div>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='dispatch_accounts_clearance'>
            <div className='body_section_header'>
                <p>Dispatch</p>
                <div className='page_general_controls'>
                    <span className='btn_yellow' onClick={() => showHideFilterRightNav('Show')}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {accountsClearingContent}
            </div>
            {filterContent}
            {dispatchDateContent}
            {loaderContent}
        </section>
    )
}

export default DispatchAccountsClearance;