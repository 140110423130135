import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import Worker from './ProfitAndLoss.Worker';
import GetAllExpensesAPI from "../../Hooks/APIConn/Expenses/GetAllExpensesAPI";
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType";
import { useRecoilValue } from "recoil";
import { DVListInsurerFilter, profitAndLossFilterYear } from "../../../atoms";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import NumberFormat from "../../Hooks/UI/NumberFormat";
import DateFormat from "../../Hooks/UI/DateFormat";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const ProfitAndLoss = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = "";
    if (isLoading === true) {
        loaderContent = <Loader />;
    }

    //################################################################
    //recoil values
    const DVListInsurerFilterRN = useRecoilValue(DVListInsurerFilter);
    const profitAndLossFilterYearRN = useRecoilValue(profitAndLossFilterYear);

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVListInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, DVListInsurerFilterRN]);

    const [currentMonths, setCurrentMonths] = useState([])
    useEffect(() => {
        try {

            const currentDate = profitAndLossFilterYearRN;
            //const currentYear = '2022';
            const currentYear = currentDate.getFullYear();
            const startMonth = 6; // June is represented as month 5 (0-indexed)
            const monthsToRetrieve = 12; // Retrieve 12 months from June

            const monthsArray = [];

            for (let i = 0; i < monthsToRetrieve; i++) {
                const month = (startMonth + i) % 12;
                const year = currentYear + Math.floor((startMonth + i) / 12);
                const formattedMonth = `${year}-${month + 1}`;
                monthsArray.push(formattedMonth);
            }

            setCurrentMonths(monthsArray);

        } catch (error) {

        }
    }, [profitAndLossFilterYearRN])

    //################################################################
    //entry
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            //setIsLoading(true);
            var worker = new Worker();

            const data = { DVDocs: DVDocs, profitAndLossFilterYearRN: profitAndLossFilterYearRN }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [DVDocs, profitAndLossFilterYearRN]);
    
    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['title', ...currentMonths, 'total'];

    const exportToExcelFileName = 'PROFIT AND LOSS    --    DATE PREPARED: ' + DateFormat(new Date()) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.title,
                NumberFormat(doc[currentMonths[0]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[1]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[2]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[3]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[4]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[5]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[6]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[7]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[8]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[9]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[10]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(doc[currentMonths[11]].reduce((accumulator, object) => accumulator + +object.amount || 0, 0)),
                NumberFormat(Object.values(doc)
                    .reduce((accumulator, array) => accumulator.concat(array), [])
                    .reduce((accumulator, object) => accumulator + (+object.amount || 0), 0))
            ])
        });


        const onlyDispatchedheaderData = 'PROFIT AND LOSS    --    DATE PREPARED: ' + DateFormat(new Date());

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 180, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 15, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'TITLE', ...currentMonths, 'TOTAL'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }


    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }
    
    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([])
    // const [columnDefs, setColumnDefs] = useState([
    //     {
    //         field: 'title', headerName: 'Title', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left',
    //         cellStyle: params => {
    //             if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
    //             return null;
    //         }
    //     },
    //     {
    //         field: currentMonths[0], headerName: <p>{'pp'}</p>, width: 150, sortable: false, filter: false,
    //         cellRenderer: params => {
    //             try {
    //                 return <div>
    //                     <p id="myButton" className='peer rounded'>
    //                         {NumberFormat(params.value.reduce((accumulator, object) => accumulator + +object.amount || 0, 0))}
    //                     </p>
    //                 </div>
    //             } catch (error) { }
    //         },
    //         cellStyle: params => {
    //             if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
    //             return null;
    //         }
    //     },
    //     {
    //         field: currentMonths[1], headerName: currentMonths[1], width: 150, sortable: false, filter: false,
    //         cellRenderer: params => {
    //             try {
    //                 return <div>
    //                     <p id="myButton" className='peer rounded'>
    //                         {NumberFormat(params.value.reduce((accumulator, object) => accumulator + +object.amount || 0, 0))}
    //                     </p>
    //                 </div>
    //             } catch (error) { }
    //         },
    //         cellStyle: params => {
    //             if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
    //             return null;
    //         }
    //     },
    //     {
    //         headerName: 'Total', sortable: false, filter: false, width: 100,
    //         cellRenderer: params => {
    //             try {
    //                 const a = Object.values(params.data)
    //                     .reduce((accumulator, array) => accumulator.concat(array), [])
    //                     .reduce((accumulator, object) => accumulator + (+object.amount || 0), 0);
    //                 return NumberFormat(a)
    //             } catch (error) { }
    //         },
    //         cellStyle: params => {
    //             if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
    //             return null;
    //         }
    //     },
    //     // { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
    //     // { field: 'policyClass', headerName: 'Risk Class', width: 150, },
    //     // { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
    //     // { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
    //     // { field: 'details', headerName: 'Details', width: 150, },
    //     // { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
    //     // { field: 'transactionType', headerName: 'Voucher', width: 40 },
    //     // { field: 'businessType', headerName: 'Type', width: 40 },
    // ]);
    
    useEffect(() => {
        try {

            const a = [
                {
                    field: 'title', headerName: 'Title', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left',
                    cellStyle: params => {
                        if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
                        return null;
                    }
                },
            ];
            currentMonths.map((month) => {
                a.push(

                    {
                        field: month, headerName: month, width: 150, sortable: false, filter: false,
                        cellRenderer: params => {
                            try {
                                return <div>
                                    <p id="myButton" className='peer rounded'>
                                        {NumberFormat(params.value.reduce((accumulator, object) => accumulator + +object.amount || 0, 0))}
                                    </p>
                                </div>
                            } catch (error) { }
                        },
                        cellStyle: params => {
                            if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
                            return null;
                        }
                    }
                )
            });
            a.push(
                {
                    headerName: 'Total', sortable: false, filter: false, width: 100,
                    cellRenderer: params => {
                        try {
                            const a = Object.values(params.data)
                                .reduce((accumulator, array) => accumulator.concat(array), [])
                                .reduce((accumulator, object) => accumulator + (+object.amount || 0), 0);
                            return NumberFormat(a)
                        } catch (error) { }
                    },
                    cellStyle: params => {
                        if (params.data.category === 'Expenses') { return { 'backgroundColor': '#B1AFCF' } }
                        return null;
                    }
                },
            )
            setColumnDefs(a)

        } catch (error) { }
    }, [currentMonths])

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Profit & Loss</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p> */}
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    );
}

export default ProfitAndLoss;