import React, { useState, useEffect } from "react";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import Loader from "../../../Body/Loader/Loader";
import { useRecoilState } from "recoil";
import { insurerStatementInsurerFilter, insurerStatementFilterFromDate, insurerStatementFilterToDate } from "../../../../atoms";
import DatePicker from "react-date-picker";

const InsurerStatementFinalFilter = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //valuation reports filter
    const [insurerStatementInsurerFilterRN, setInsurerStatementInsurerFilterRN] = useRecoilState(insurerStatementInsurerFilter);
    const [insurerStatementFilterFromDateRN, setInsurerStatementFilterFromDateRN] = useRecoilState(insurerStatementFilterFromDate)
    const [insurerStatementFilterToDateRN, setInsurerStatementFilterToDateRN] = useRecoilState(insurerStatementFilterToDate)


    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);
    
    
    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { setInsurerStatementInsurerFilterRN(e.target.value) }} value={insurerStatementInsurerFilterRN}>
                        <option>Select Insurer Filter</option>
                        {
                            InsurerDetails.map((doc,index) => {
                                return (
                                    <option key={index}>{doc.docData.insurer}</option>
                                )
                            })
                        }
                    </select>
                    <label className='placeholder'><p>Insurer Account</p></label>
                </div>
                <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setInsurerStatementFilterFromDateRN} value={insurerStatementFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setInsurerStatementFilterToDateRN} value={insurerStatementFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
            {loaderContent}
        </section>
    )
}

export default InsurerStatementFinalFilter;