import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import { useReactToPrint } from "react-to-print";
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import logo from '../../Assets/img/logo.png';
import './AccountsClientStatement.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clientStatementSelectedClient, rightNavContent, clientStatementInsurerFilter, activeInsurerPortfolio } from '../../../atoms';
import DateFormat from '../../Hooks/UI/DateFormat';

const AccountsClientStatementTwo = (props) => {
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page { 
                size: A4 !important; 
                margin: 7mm !important;
            }
          }`,
        content: () => componentRef.current,
    });

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent);
    //const selectInsurerRN = useRecoilValue(selectInsurer)
    const clientStatementSelectedClientRN = useRecoilValue(clientStatementSelectedClient)
    const clientStatementInsurerFilterRN = useRecoilValue(clientStatementInsurerFilter)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio)

    //input
    //selected client
    // const [clientName, setClientName] = useState('');
    const [selectedClient, setSelectedClient] = useState(props.onePropUp.postedDocRef);
    const selectClientHandler = (value) => {
        setSelectedClient(value);
    }

    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])
    //console.log(fromDate)

    //to
    const [toDate, setToDate] = useState('');

    const [tvalue, onTChange] = useState(new Date());
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //from date echo
    const [echoFromDate, setEchoFromDate] = useState('');
    useEffect(() => {
        new Date('2021-05-01')
        const echoFromDay = new Date(fromDate).toLocaleString('en-us', { day: '2-digit' });
        const echoFromMonth = new Date(fromDate).toLocaleString('en-us', { month: 'short' });
        const echoFromYear = new Date(fromDate).getFullYear();
        const echoDate = echoFromDay + '-' + echoFromMonth + '-' + echoFromYear;
        setEchoFromDate(echoDate);
    }, [fromDate]);

    //to date echo
    const [echoToDate, setEchoToDate] = useState('');
    useEffect(() => {
        const toFromDay = new Date(toDate).toLocaleString('en-us', { day: '2-digit' });
        const toFromMonth = new Date(toDate).toLocaleString('en-us', { month: 'short' });
        const toFromYear = new Date(toDate).getFullYear();
        const toDatee = toFromDay + '-' + toFromMonth + '-' + toFromYear;
        setEchoToDate(toDatee);
    }, [toDate]);

    //show hide search
    const [toogleRightNavv, setToogleRightNavv] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNavv = (instruction) => {
        if (instruction === 'Show') { setToogleRightNavv('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNavv('receipt_allocation_rightNav hide') }
    }

    // search section
    const filterContent = <div className={toogleRightNavv}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNavv('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>


    // get client data
    // const [receivedClientDetails, setReceivedClientDetails] = useState([]);

    // useEffect(() => {
    //     let isCancelled = false;
    //     const fetchData = async () => {
    //         setIsloading(true);
    //         const querySnapshot = await getDocs(collection(db, "ClientDetails"));
    //         if (!isCancelled) {
    //             const CData = []
    //             querySnapshot.forEach((doc) => {
    //                 // doc.data() is never undefined for query doc snapshots
    //                 //console.log(doc.id, " => ", doc.data());
    //                 const allClientDetails = {
    //                     receivedDocID: doc.id,
    //                     clientName: doc.data().clientName,
    //                     IDNumber: doc.data().IDNumber,
    //                     passportNumber: doc.data().passportNumber,
    //                     KRAPin: doc.data().KRAPin,
    //                     occupation: doc.data().occupation,
    //                     phoneNumber: doc.data().phoneNumber,
    //                     email: doc.data().email,
    //                     address: doc.data().address
    //                 }
    //                 CData.push(allClientDetails);
    //                 setIsloading(false);
    //             },
    //                 setReceivedClientDetails(CData)
    //             );
    //         }
    //     }
    //     fetchData();
    //     return () => {
    //         isCancelled = true;
    //     }
    // }, []);

    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);
    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    // assign date
                    let UDate = '';
                    let EDate = '';
                    let echoDate = '';
                    let filterDate = '';
                    let policyClientName = '';
                    let insurerName = '';
                    let insurerID = '';
                    let remittanceDetailsReceiptNumber = '';
                    let remittanceDetailsReceiptDate = '';
                    let remittanceDetailsReceiptDateEchoDate = '';
                    if (doc.data().transactionType === 'Debit') {
                        insurerName = doc.data().insurerName;
                        UDate = doc.data().insurerDebitDate;
                        EDate = doc.data().inceptionDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Credit') {
                        insurerName = doc.data().insurerName;
                        UDate = doc.data().insurerDebitDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Receipt') {
                        insurerName = doc.data().paidTo;
                        UDate = doc.data().receiptDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                        insurerID = doc.data().insurerID;
                        if (doc.data().remitanceDetails) {
                            if (Object.keys(doc.data().remitanceDetails).length > 0) {
                                remittanceDetailsReceiptNumber = doc.data().remitanceDetails.prevReceiptNumber;
                                remittanceDetailsReceiptDate = doc.data().remitanceDetails.prevReceiptDate;

                                UDate = remittanceDetailsReceiptDate;
                                const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                                const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                                const year = new Date(UDate).getFullYear();
                                remittanceDetailsReceiptDateEchoDate = day + '-' + month + '-' + year;
                            }
                        }
                    }
                    //look for pd
                    const policyDocuments = [];
                    if (doc.data().associateDocuments) {
                        Object.values(doc.data().associateDocuments).map((item) => {
                            if (item.documentUploadCategory === 'Policy Document') {
                                policyDocuments.push(item.documentDownloadLink)
                            }
                        })
                    }
                    //look for receipt doc
                    if (doc.data().receiptDocument) {
                        Object.keys(doc.data().receiptDocument).map((key) => {
                            policyDocuments.push(doc.data().receiptDocument[key]['receiptDocumentDownloadLink']);
                        });
                    }
                    const PC = [];
                    const allPolicyDocs = {
                        docID: doc.id,
                        clientName: doc.data().clientName,
                        policyNumber: doc.data().policyNumber,
                        endorsmentNumber: doc.data().endorsmentNumber,
                        selectedProductSubClass: doc.data().selectedProductSubClass,
                        inceptionDate: doc.data().inceptionDate,
                        policyNet: doc.data().policyNet,
                        outstanding: doc.data().outstanding,
                        transactionType: doc.data().transactionType,
                        locationOfTheRisk: doc.data().locationOfTheRisk,
                        vehicleItems: doc.data().vehicleItems,
                        receiptAllocationBreakDown: doc.data().receiptAllocationBreakDown,
                        status: doc.data().status,
                        insurerName: insurerName,

                        receiptDate: doc.data().receiptDate,
                        receiptNumber: doc.data().receiptNumber,
                        receiptAmount: doc.data().receiptAmount,
                        receiptAllocationDetails: doc.data().receiptAllocationDetails,
                        insurerID: insurerID,
                        remittanceDetailsReceiptNumber: remittanceDetailsReceiptNumber,
                        remittanceDetailsReceiptDate: remittanceDetailsReceiptDate,
                        remittanceDetailsReceiptDateEchoDate: remittanceDetailsReceiptDateEchoDate,

                        uDate: UDate,
                        echoDate: echoDate,
                        filterDate: filterDate,
                        cummulativeOutstanding: '',
                        realTimeOutstanding: doc.data().policyNet,
                        policyCredits: PC,
                        policyDocuments: policyDocuments,
                    }
                    Rdata.push(allPolicyDocs);
                    setIsloading(false);
                },
                    setReceivedPolicyDocs(Rdata)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [clientStatementSelectedClientRN, toDate, fromDate])

    //array to be used for ageing
    //remove pending docs( && item.status !== 'Pending')
    const [filteredDatesPolicyDocs, setFilteredDatesPolicyDocs] = useState([]);
    useEffect(() => {
        const PeriodSelected = [];
        receivedPolicyDocs.map((item) => {
            // if (new Date(item.filterDate).valueOf() >= new Date(fromDate).valueOf() && new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.policyStatus !== 'Pending') {
            //     PeriodSelected.push(item);
            // }
            if (new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.status !== 'Pending') {
                PeriodSelected.push(item);
            }
        })
        setFilteredDatesPolicyDocs(PeriodSelected)
    }, [receivedPolicyDocs, toDate, fromDate])

    //filter client
    //remove pending docs (&& item.status !== 'Pending')
    const [clientReceivedPolicyDocs, setClientReceivedPolicyDocs] = useState([]);
    const [finalFinal, setFinalFinal] = useState([]);
    useEffect(() => {
        const clientSortedData = [];
        filteredDatesPolicyDocs.map((item) => {
            // if (item.clientName.includes(selectedClient.clientName) && selectedClient.clientName !== '' && item.status !== 'Pending') {
            //     clientSortedData.push(item)
            // }
            if (item.clientName === clientStatementSelectedClientRN.clientName && item.transactionType === 'Debit' && item.insurerName === clientStatementInsurerFilterRN && item.status !== 'Pending') {
                clientSortedData.push(item);
            }
            else if (item.clientName === clientStatementSelectedClientRN.clientName && item.transactionType === 'Credit' && item.insurerName === clientStatementInsurerFilterRN && item.status !== 'Pending') {
                clientSortedData.push(item)
            }
            else if (item.clientName.includes(clientStatementSelectedClientRN.clientName) && item.transactionType === 'Receipt') {
                if (item.insurerName === clientStatementInsurerFilterRN || item.insurerName === 'SIMIA INSURANCE AGENCY LIMITED') {
                    if (item.insurerID === activeInsurerPortfolioRN) {
                        clientSortedData.push(item)
                    }
                }
            }
        })
        setClientReceivedPolicyDocs(clientSortedData);
        setFinalFinal(clientSortedData);

    }, [filteredDatesPolicyDocs, clientStatementInsurerFilterRN])

    //#########
    //cal outstanding
    //s1
    const [transactionCredits, setTransactionCredits] = useState([]);
    const [transactionDebits, setTransactionDebits] = useState([]);
    const [transactionReceipts, setTransactionReceipts] = useState([]);
    useEffect(() => {
        const Cr = []
        clientReceivedPolicyDocs.map((item) => {
            if (item.transactionType === 'Credit') { Cr.push(item) }
        })
        setTransactionCredits(Cr)
        //
        const Re = []
        clientReceivedPolicyDocs.map((item) => {
            if (item.transactionType === 'Receipt') { Re.push(item) }
        })
        setTransactionReceipts(Re)
        //
        const Dr = []
        clientReceivedPolicyDocs.map((item) => {
            if (item.transactionType === 'Debit') { Dr.push(item) }
        })
        setTransactionDebits(Dr)

        const sortedList = Dr.sort(byDate);
        function byDate(a, b) {
            return new Date(a.inceptionDate).valueOf() - new Date(b.inceptionDate).valueOf();
        }

        setFinalFinal(sortedList);
        // sortedList.map((val)=>{
        //     console.log(val.inceptionDate +'-'+val.policyNumber)
        // })


    }, [clientReceivedPolicyDocs])

    //s2 add credits
    const [addCredits, setAddCredits] = useState([]);
    useEffect(() => {
        transactionCredits.map((item) => {
            let isCancelled = false;
            finalFinal.map((val, index) => {
                //console.log(val.inceptionDate)
                if (!isCancelled) {
                    if (item.policyNumber === val.policyNumber && val.transactionType === 'Debit') {
                        const Values = [...finalFinal];
                        Values[index]['policyCredits'].push(item.policyNet);
                        setFinalFinal(Values);
                        isCancelled = true;
                    }
                }

            })
        })
    }, [transactionCredits])
    //s3 add receipts
    const [addReceipts, setAddReceipts] = useState([]);
    useEffect(() => {
        transactionReceipts.map((item) => {
            const q = item.receiptAllocationDetails;
            q.map((value) => {
                finalFinal.map((val, index) => {
                    if (value.receivedDocID === val.docID && val.transactionType === 'Debit') {
                        const Values = [...finalFinal];
                        Values[index]['policyCredits'].push(value.receivedAllocatedAmount);
                        setFinalFinal(Values);
                    }
                })
            })
        })
    }, [transactionReceipts])

    //final arr for aging
    const [finalArr, setFinalArr] = useState([]);
    useEffect(() => {
        const newArr = []
        finalFinal.map((item, index) => {
            if (item.transactionType === 'Debit') {
                const a = item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0);
                item.outstanding = item.policyNet - a;
                newArr.push(item);
            }
        });
        setFinalArr(newArr)
    }, [finalFinal])
    //console.log(finalArr)
    //###############

    //filter date
    //bf feature
    const [BFReceivedPolicyDocs, setBFReceivedPolicyDocs] = useState([]);
    useEffect(() => {
        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];

        clientReceivedPolicyDocs.map((item, index) => {
            if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.transactionType === 'Debit') {
                BFDebits.push(item.policyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.transactionType === 'Credit') {
                BFCreditsReceipts.push(item.policyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.transactionType === 'Receipt') {
                BFCreditsReceipts.push(item.receiptAmount);
            }
            if (new Date(item.uDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.uDate).valueOf()) {
                PeriodSelected.push(item);
            }
        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            uDate: fromDate,
            echoDate: DateFormat(fromDate),
            policyNumber: 'BF',
            policyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding,
            inceptionDate: fromDate,
            transactionType: '',
            outstanding: BFTotalOutstanding,
            policyDocuments: [],
        }

        PeriodSelected.unshift(BTDetails);
        setBFReceivedPolicyDocs(PeriodSelected)

    }, [clientReceivedPolicyDocs, fromDate, toDate]);

    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFReceivedPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.uDate).valueOf() - new Date(b.uDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [BFReceivedPolicyDocs]);

    //calculate outstanding and final array to be used to populate stmt
    const [outstandingMap, setOutstandingMap] = useState([]);
    useEffect(() => {
        if (sortedPolicyDocs.length > 0) {
            for (let i = 1; i < sortedPolicyDocs.length; i++) {
                const previousIndex = i - 1;

                if (sortedPolicyDocs[0].transactionType === 'Receipt') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].receiptAmount
                }
                else if (sortedPolicyDocs[0].policyNumber === 'BF') {
                    sortedPolicyDocs[0].cummulativeOutstanding = +sortedPolicyDocs[0].cummulativeOutstanding;
                }
                else if (sortedPolicyDocs[0].transactionType === 'Credit') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].policyNet;
                }
                else {
                    sortedPolicyDocs[0].cummulativeOutstanding = +sortedPolicyDocs[0].policyNet;
                }
                if (sortedPolicyDocs[i].transactionType === 'Debit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding + +sortedPolicyDocs[i].policyNet
                }
                if (sortedPolicyDocs[i].transactionType === 'Receipt' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].receiptAmount
                }
                if (sortedPolicyDocs[i].transactionType === 'Credit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].policyNet
                }
            }
        }
        setOutstandingMap(sortedPolicyDocs);
    }, [sortedPolicyDocs])

    //get totals
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);

    useEffect(() => {
        const totalPremiumItems = [];
        const totalReceiptsItems = [];

        BFReceivedPolicyDocs.map((item, index) => {
            if (item.transactionType === 'Debit') {
                totalPremiumItems.push(item.policyNet)
            }
            else if (item.policyNumber === 'BF') {
                totalPremiumItems.push(item.cummulativeOutstanding)
            }
            else if (item.transactionType === 'Credit') {
                totalPremiumItems.push(-item.policyNet)
            }
            else if (item.transactionType === 'Receipt') {
                totalReceiptsItems.push(item.receiptAmount)
            }
        })
        //calc
        const a = totalPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPremium(a);
        const b = totalReceiptsItems.reduce((total, item) => { return total + +item }, 0);
        setTotalReceipts(b);

    }, [BFReceivedPolicyDocs])


    //calculate aging
    const [ageingCredits, setAgeingCredits] = useState(0);
    const [ageingThirtyDays, setAgeingThirtyDays] = useState(0);
    const [ageingSixtyDays, setAgeingSixtyDays] = useState(0);
    const [ageingNinetyDays, setAgeingNinetyDays] = useState(0);
    const [ageingOneTwentyDays, setAgeingOneTwentyDays] = useState(0);
    const [ageingMoreThanOneTwentyDays, setAgeingMoreThanOneTwentyDays] = useState(0);
    const [ageingGrandTotal, setAgeingGrandTotal] = useState(0);

    useEffect(() => {
        const credits = [];
        const thirtyDays = [];
        const sixtyDays = [];
        const ninetyDays = [];
        const oneTwentyDays = [];
        const moreThanOneTwentyDays = [];
        const totalAgeing = [];
        finalArr.map((val, key) => {
            //console.log(val.inceptionDate +'-'+val.outstanding)
            if (val.policyNumber === 'BF') { val.transactionType = 'Debit' }
            if (val.transactionType === 'Debit' && clientStatementSelectedClientRN.clientName !== '' && val.outstanding > 0) {
                const dateOne = new Date(val.inceptionDate);
                const dateTwo = new Date(toDate);
                const datesCompared = Math.abs(dateOne - dateTwo);
                const datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
                totalAgeing.push(val.outstanding);
                if (datesDifference < 31) {
                    thirtyDays.push(val.outstanding);
                }
                else if (datesDifference < 61 && datesDifference > 30) {
                    sixtyDays.push(val.outstanding);
                }
                else if (datesDifference < 91 && datesDifference > 60) {
                    ninetyDays.push(val.outstanding);
                }
                else if (datesDifference < 121 && datesDifference > 90) {
                    oneTwentyDays.push(val.outstanding);
                }
                else if (datesDifference > 120) { moreThanOneTwentyDays.push(val.outstanding); }
            }
            if (val.transactionType === 'Debit' && clientStatementSelectedClientRN.clientName !== '' && val.outstanding < 0) {
                credits.push(val.outstanding)
            }
        })

        const ageingCredits = credits.reduce((total, item) => { return total + +item }, 0);
        const ageingThirtyDays = thirtyDays.reduce((total, item) => { return total + +item }, 0);
        const ageingSixtyDays = sixtyDays.reduce((total, item) => { return total + +item }, 0);
        const ageingNinetyDays = ninetyDays.reduce((total, item) => { return total + +item }, 0);
        const ageingOneTwentyDays = oneTwentyDays.reduce((total, item) => { return total + +item }, 0);
        const ageingMoreThanOneTwentyDays = moreThanOneTwentyDays.reduce((total, item) => { return total + +item }, 0);

        const a = [ageingCredits, ageingMoreThanOneTwentyDays, ageingOneTwentyDays, ageingNinetyDays, ageingSixtyDays, ageingThirtyDays]
        if (ageingCredits < 0) {
            for (let i = 1; i < a.length; i++) {
                const previousIndex = i - 1;


                if (a[previousIndex] <= 0) { a[i] = a[previousIndex] + a[i]; }
            }
        }

        for (let i = 1; i < a.length; i++) {
            if (a[i] < 0) {
                a[0] = a[i]
                a[i] = 0
            }
        }

        //total debits
        const allDebitPolicyNet = []
        transactionDebits.map((item) => { allDebitPolicyNet.push(item.policyNet) })
        const totalClientDebits = allDebitPolicyNet.reduce((total, item) => { return total + +item }, 0);

        //total credits
        const allCreditPolicyNet = []
        transactionCredits.map((item) => { allCreditPolicyNet.push(item.policyNet) })
        const totalClientCredits = allCreditPolicyNet.reduce((total, item) => { return total + +item }, 0);

        //total receipts
        const allReceiptsNet = []
        transactionReceipts.map((item) => { allReceiptsNet.push(item.receiptAmount) })
        const totalReceipts = allReceiptsNet.reduce((total, item) => { return total + +item }, 0);


        setAgeingThirtyDays(a[5]);
        setAgeingSixtyDays(a[4]);
        setAgeingNinetyDays(a[3]);
        setAgeingOneTwentyDays(a[2]);
        setAgeingMoreThanOneTwentyDays(a[1]);
        setAgeingGrandTotal(totalClientDebits - totalClientCredits - totalReceipts);
    }, [finalArr])

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //stmt
    const ClientStatementContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Client Statement</p>
                <div className='client_controls'>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three CS_table'>
                    <table>
                        <thead>
                            <tr>
                                <td><p>Date</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Risk Class</p></td>
                                <td><p>Details</p></td>
                                <td><p>Debit Net</p></td>
                                <td><p>Credit Net</p></td>
                                <td><p>Outstanding</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {outstandingMap.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.vehicleItems) {
                                    Object.values(item.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td><p>
                                            {
                                                item.remittanceDetailsReceiptDateEchoDate === '' ? item.echoDate : item.remittanceDetailsReceiptDateEchoDate
                                            }
                                        </p></td>
                                        <td>
                                            <p>
                                                {item.policyNumber}
                                                {
                                                    item.remittanceDetailsReceiptNumber === '' ? item.receiptNumber : item.remittanceDetailsReceiptNumber
                                                }
                                            </p>
                                        </td>
                                        <td><p>{item.endorsmentNumber}</p></td>
                                        <td><p>{item.selectedProductSubClass}</p></td>
                                        <td><p>
                                            {
                                                item.locationOfTheRisk ? item.locationOfTheRisk.substring(0, 20) + ' ...' : ''
                                            }
                                            {
                                                item.vehicleItems ?
                                                    Object.keys(item.vehicleItems).map((veh, index) => {
                                                        if (index == 0) {
                                                            return <p key={index}>{item.vehicleItems[veh]['regNo']}</p>
                                                        }
                                                    })
                                                    : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {item.transactionType === 'Debit'
                                                ? item.policyNet > 0 ? parseInt(item.policyNet).toLocaleString() : '-'
                                                : ''}
                                            {item.transactionType === 'Credit'
                                                ? item.policyNet ? '(' + parseInt(item.policyNet).toLocaleString() + ')' : '-'
                                                : ''}
                                        </p></td>
                                        <td><p>{item.receiptAmount > 0 ? parseInt(item.receiptAmount).toLocaleString() : item.receiptAmount}</p></td>
                                        <td><p>{item.cummulativeOutstanding.toLocaleString()}</p></td>
                                        <td><p>{
                                            item.policyDocuments.map((docLink, index) => {
                                                return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                                            })
                                        }</p></td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan='4'></td>
                                <td><p>Totals</p></td>
                                <td><p>{totalPremium.toLocaleString()}</p></td>
                                <td><p>{totalReceipts.toLocaleString()}</p></td>
                            </tr>
                        </tbody>
                    </table>
                    {/* aging */}
                    <div className='ageing_report'>
                        <div className='h_10'></div>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td rowSpan='2'><p>AGEING</p></td>
                                        <td><p>Credit</p></td>
                                        <td><p>0 - 30 Days</p></td>
                                        <td><p>31 - 60 Days</p></td>
                                        <td><p>61 - 90 Days</p></td>
                                        <td><p>91 - 120 Days</p></td>
                                        <td><p>Above 120 Days</p></td>
                                        <td><p>Total</p></td>
                                    </tr>
                                    <tr>
                                        <td><p>{
                                            ageingGrandTotal < 0
                                                ? ageingGrandTotal.toLocaleString()
                                                : 0
                                        }</p></td>
                                        <td><p>{ageingThirtyDays.toLocaleString()}</p></td>
                                        <td><p>{ageingSixtyDays.toLocaleString()}</p></td>
                                        <td><p>{ageingNinetyDays.toLocaleString()}</p></td>
                                        <td><p>{ageingOneTwentyDays.toLocaleString()}</p></td>
                                        <td><p>{ageingMoreThanOneTwentyDays.toLocaleString()}</p></td>
                                        <td><p>{ageingGrandTotal.toLocaleString()}</p></td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className='h_5'></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //stmt date
    let STMTDate = new Date();
    const STMTDay = STMTDate.toLocaleString('en-us', { day: '2-digit' });
    const STMTMonth = STMTDate.toLocaleString('en-us', { month: 'long' });
    const STMTYear = STMTDate.getFullYear();
    STMTDate = STMTDay + ' ' + STMTMonth + ' ' + STMTYear;

    //printable stmt
    const printableStatement = <section>
        <div className='CS_printable' style={{ display: "none" }}>
            <div ref={componentRef}>
                <div className='CS_content customer_statement_preview'>
                    <div className='logo_container'><img alt='logo' src={logo} /></div>
                    {/* <div className='h_5'></div> */}
                    <div className='title_info'>
                        <p className='CS_title_info_header bold'>Client Statement</p>
                        <div className='h_1'></div>
                        <table className='CS_Document_Header'>
                            <thead>
                                <tr>
                                    <td><p className='bold'>Name</p></td>
                                    <td><p>{clientStatementSelectedClientRN.clientName}</p></td>
                                    <td><p className='bold'>Date</p></td>
                                    <td><p>{STMTDate}</p></td>
                                </tr>
                                <tr>
                                    <td><p className='bold'>KRA Pin</p></td>
                                    <td><p>{clientStatementSelectedClientRN.KRAPin}</p></td>
                                    <td rowSpan='2'><p className='bold'>Period</p></td>
                                    <td><p><span className='bold'>From: </span><span>{echoFromDate}</span></p></td>
                                </tr>
                                <tr>
                                    <td><p className='bold'>Address</p></td>
                                    <td><p>{clientStatementSelectedClientRN.address}</p></td>
                                    <td><p><span className='bold'>To: </span><span>{echoToDate}</span></p></td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className='h_10'></div>
                    <div className='client_statement_category CS_table'>
                        <div className=''>
                            <div className='CS_table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td><p>Date</p></td>
                                            <td><p>Policy Number</p></td>
                                            <td><p>Endorsment Number</p></td>
                                            <td><p>Risk Class</p></td>
                                            <td><p>Details</p></td>
                                            <td><p>Policy Net</p></td>
                                            <td><p>Credit Net</p></td>
                                            <td><p>Outstanding</p></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {outstandingMap.map((item, index) => {
                                            const clientVehicleItems = []
                                            if (item.vehicleItems) {
                                                Object.values(item.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td><p>
                                                        {
                                                            item.remittanceDetailsReceiptDateEchoDate === '' ? item.echoDate : item.remittanceDetailsReceiptDateEchoDate
                                                        }
                                                    </p></td>
                                                    <td>
                                                        <p>
                                                            {item.policyNumber}
                                                            {
                                                                item.remittanceDetailsReceiptNumber === '' ? item.receiptNumber : item.remittanceDetailsReceiptNumber
                                                            }
                                                        </p>
                                                    </td>
                                                    <td><p>{item.endorsmentNumber}</p></td>
                                                    <td><p>{item.selectedProductSubClass}</p></td>
                                                    <td><p>
                                                        {
                                                            item.locationOfTheRisk ? item.locationOfTheRisk.substring(0, 20) + ' ...' : ''
                                                        }
                                                        {
                                                            item.vehicleItems ?
                                                                Object.keys(item.vehicleItems).map((veh, index) => {
                                                                    if (index == 0) {
                                                                        return <p key={index}>{item.vehicleItems[veh]['regNo']}</p>
                                                                    }
                                                                })
                                                                : ''
                                                        }
                                                    </p></td>
                                                    <td><p>
                                                        {item.transactionType === 'Debit'
                                                            ? item.policyNet > 0 ? parseInt(item.policyNet).toLocaleString() : '-'
                                                            : ''}
                                                        {item.transactionType === 'Credit'
                                                            ? item.policyNet ? '(' + parseInt(item.policyNet).toLocaleString() + ')' : '-'
                                                            : ''}
                                                    </p></td>
                                                    <td><p>{item.receiptAmount > 0 ? parseInt(item.receiptAmount).toLocaleString() : item.receiptAmount}</p></td>
                                                    <td><p>{item.cummulativeOutstanding.toLocaleString()}</p></td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan='4'></td>
                                            <td><p>Totals</p></td>
                                            <td><p>{totalPremium.toLocaleString()}</p></td>
                                            <td><p>{totalReceipts.toLocaleString()}</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* aging */}
                                <div className='ageing_report'>
                                    <div className='h_10'></div>
                                    <div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td rowSpan='2'><p>AGEING</p></td>
                                                    <td><p>Credit</p></td>
                                                    <td><p>0 - 30 Days</p></td>
                                                    <td><p>31 - 60 Days</p></td>
                                                    <td><p>61 - 90 Days</p></td>
                                                    <td><p>91 - 120 Days</p></td>
                                                    <td><p>Above 120 Days</p></td>
                                                    <td><p>Total</p></td>
                                                </tr>


                                                <tr>
                                                    <td><p>{
                                                        ageingGrandTotal < 0
                                                            ? ageingGrandTotal.toLocaleString()
                                                            : 0
                                                    }</p></td>
                                                    <td><p>{ageingThirtyDays.toLocaleString()}</p></td>
                                                    <td><p>{ageingSixtyDays.toLocaleString()}</p></td>
                                                    <td><p>{ageingNinetyDays.toLocaleString()}</p></td>
                                                    <td><p>{ageingOneTwentyDays.toLocaleString()}</p></td>
                                                    <td><p>{ageingMoreThanOneTwentyDays.toLocaleString()}</p></td>
                                                    <td><p>{ageingGrandTotal.toLocaleString()}</p></td>
                                                </tr>
                                            </thead>
                                        </table>

                                    </div>
                                    <div className='h_5'></div>
                                    <div className='stmt_date_view'>
                                        <p>Prepared On: {STMTDate}</p>
                                    </div>
                                    <div className='footer'>
                                        <center><p>SIMIA INSURANCE AGENCY | P.O BOX 623 – 30100, ELDORET | TEL: +254722968637 | EMAIL: info@simiainsurance.com</p></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    //search term
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    // search section
    // const searchContent = <div className={toogleRightNav}>
    //     <div className='receipt_allocation_minimise' onClick={() => showHideRightNav('Hide')}></div>
    //     <div className='receipt_allocation_search'>
    //         <div className='search_input'>
    //             <p>Search Client</p>
    //             <div className='h_5'></div>
    //             <input onChange={searchTermChangeHandler} type='text' placeholder='Client Name' />
    //         </div>
    //         <div className='h_5'></div>
    //         <div className='search_results'>
    //             {receivedClientDetails.filter((val) => {
    //                 if (searchTerm === '') { return val }
    //                 else if (val.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
    //                     return val
    //                 }
    //             }).map((val, key) => (
    //                 <div key={key}>
    //                     <div className='result' onClick={() => selectClientHandler(val)}>
    //                         <p>{val.clientName}</p>
    //                         <div className='h_2'></div>
    //                     </div>
    //                     <div className='h_5'></div>
    //                 </div>
    //             )
    //             )}
    //         </div>
    //     </div>
    // </div>




    // //back feature handler
    // useEffect(() => {
    //     const a = props.backFeatureObj;
    //     if (Object.keys(a).length !== 0) {
    //         if (a.dateFrom !== '') {
    //             const b = new Date(a.dateFrom);
    //             onFChange(b)
    //         }
    //         if (a.dateTo !== '') {
    //             const c = new Date(a.dateTo);
    //             onTChange(c)
    //         }
    //     }
    // }, [])

    // //back button
    // const backButtonHandler = () => {
    //     props.onSuccessPolicyCreate({ setView: 'Agewise Outstanding', backFeatureObj: props.backFeatureObj });
    // }


    // ##################################################################################################
    // ##################################################################################################
    // ##################################################################################################

    return (
        <section>
            <div className='body_section_header'>
                <div className='left_content'>
                    {/* {Object.keys(props.backFeatureObj).length !== 0 ?
                        <span className='btn_back' onClick={backButtonHandler}>
                            <i className="ri-arrow-left-s-line"></i>
                        </span>
                        : <span></span>
                    }

                    <div className='w_5'></div> */}
                    <p>Client Statement</p>
                </div>
                <span className='btn_pink' onClick={() => showHideFilterRightNavv('Show')}>
                    <i className="ri-filter-2-line"></i>
                </span>
            </div>
            <div className='body_section'>
                <div className='h_5'></div>
                <div className='category'>
                    <div className='category_header client_details_category'>
                        <p>Client Details</p>
                        <div className='client_controls'>
                            {/* <span className='btn_yellow'>
                                <i className="ri-user-add-line"></i>
                            </span>
                            <div className='w_5'></div> */}
                            <span onClick={() => { setRightNavContentRV('Client Statement Select Client') }} className='btn_pink'>
                                <i className="ri-search-2-line"></i>
                            </span>
                        </div>
                    </div>
                    <div className='category_content'>
                        <div className='category_content_three'>
                            <input value={clientStatementSelectedClientRN.clientName || ''} type='text' placeholder='Client Name' readOnly />
                        </div>
                    </div>
                </div>
                {ClientStatementContent}
            </div>
            {loaderContent}
            {filterContent}
            {printableStatement}
        </section>
    )
}

export default AccountsClientStatementTwo;