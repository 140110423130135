
import GetAllClientTransactions from "../../Hooks/BackendConn/InsurerSpecific/GetAllClientTransactions";
import GetClientDetails from "../../Hooks/BackendConn/InsurerSpecific/GetClientDetails";
import DateFormat from "../../Hooks/UI/DateFormat";

const ProcessClientStatementTransactions = async (props) => {
    const clientDocs = props.clientDocs
    const clientName = props.clientName;
    const clientDetails = props.clientDetails;
    const agewiseOutstandingFilterFromDateRN = props.agewiseOutstandingFilterFromDateRN;
    const agewiseOutstandingFilterToDateRN = props.agewiseOutstandingFilterToDateRN;
    const agewiseOutstandingInsurerFilterRN = props.agewiseOutstandingInsurerFilterRN;



    //################################################################
    //get trans
    // async function clientTransactions() {
    //     try {
    //         const a = await GetAllClientTransactions({ clientName: clientName, insurer: agewiseOutstandingInsurerFilterRN });

    //         return a;
    //     } catch (error) {

    //     }
    // }

    //################################################################
    //clean data
    function cleanData(docs) {


        const cleanDocs = [];
        if (docs.length < 1) { return false }
        docs.map((doc) => {
            if (doc.docData.transactionType === 'Debit' || doc.docData.transactionType === 'Credit') {
                if (doc.docData.status !== 'Pending') {
                    //details
                    const details = []
                    if (doc.docData.selectedProductClass === '(07) - Motor Private'
                        || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                        || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                        || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                        || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                        || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                        if (doc.docData.vehicleItems) {
                            Object.values(doc.docData.vehicleItems).map((veh) => { details.push(veh.regNo) })
                        }
                    }

                    else {
                        details.push(doc.docData.locationOfTheRisk)
                    }

                    //final obj
                    cleanDocs.push(
                        {
                            docID: doc.docID,
                            transactionType: doc.docData.transactionType,
                            universalDate: doc.docData.insurerDebitDate,
                            policyNumber: doc.docData.policyNumber,
                            endorsmentNumber: doc.docData.endorsmentNumber,
                            policyClass: doc.docData.selectedProductClass,
                            policySubClass: doc.docData.selectedProductSubClass,
                            details: details,
                            policyNet: doc.docData.policyNet,
                            cummulativeOutstanding: 0,
                            allocatedDocID: doc.docData.allocatedDocID,
                        }
                    )
                }
            }
            else if (doc.docData.transactionType === 'Receipt') {
                cleanDocs.push(
                    {
                        docID: doc.docID,
                        transactionType: doc.docData.transactionType,
                        universalDate: doc.docData.receiptDate,
                        policyNumber: doc.docData.receiptNumber,
                        endorsmentNumber: '',
                        policyClass: '',
                        policySubClass: '',
                        details: '',
                        policyNet: doc.docData.receiptAmount,
                        cummulativeOutstanding: 0,
                        receiptAllocationDetails: doc.docData.receiptAllocationDetails,
                    }
                )
            }
        })

        bfFeature(cleanDocs);
        ageingFeature(cleanDocs);
        gridThreeContent(cleanDocs);

    }

    //################################################################
    //bf
    function bfFeature(docs) {


        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];

        docs.map((doc) => {
            if (doc.transactionType === 'Debit' || doc.transactionType === 'Credit') {
                if (new Date(agewiseOutstandingFilterFromDateRN).valueOf() > new Date(doc.universalDate).valueOf() && doc.transactionType === 'Debit') {
                    BFDebits.push(doc.policyNet);
                }
                else if (new Date(agewiseOutstandingFilterFromDateRN).valueOf() > new Date(doc.universalDate).valueOf() && doc.transactionType === 'Credit') {
                    BFCreditsReceipts.push(doc.policyNet);
                }
                else if (new Date(doc.universalDate).valueOf() >= new Date(agewiseOutstandingFilterFromDateRN).valueOf() && new Date(agewiseOutstandingFilterToDateRN).valueOf() >= new Date(doc.universalDate).valueOf()) {
                    PeriodSelected.push(doc);
                }
            }
            if (doc.transactionType === 'Receipt') {
                if (new Date(agewiseOutstandingFilterFromDateRN).valueOf() > new Date(doc.universalDate).valueOf() && doc.docData.transactionType === 'Receipt') {
                    BFCreditsReceipts.push(doc.policyNet);
                }
                else if (new Date(doc.universalDate).valueOf() >= new Date(agewiseOutstandingFilterFromDateRN).valueOf() && new Date(agewiseOutstandingFilterToDateRN).valueOf() >= new Date(doc.universalDate).valueOf()) {
                    PeriodSelected.push(doc);
                }
            }


        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            transactionType: 'Brought Forward',
            universalDate: agewiseOutstandingFilterFromDateRN,
            policyNumber: 'BF',
            endorsmentNumber: '',
            policyClass: '',
            policySubClass: '',
            details: '',
            policyNet: BFTotalOutstanding,
            cummulativeOutstanding: 0,
        }

        PeriodSelected.unshift(BTDetails);

        sortByDate(PeriodSelected)

    }

    //################################################################
    //SORT BY DATE
    function sortByDate(docs) {

        const sortedList = docs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.universalDate).valueOf() - new Date(b.universalDate).valueOf();
        }

        calcOutstanding(sortedList)

    }

    //################################################################
    //calc outstanding
    function calcOutstanding(docs) {


        if (docs.length > 0) {
            for (let i = 1; i < docs.length; i++) {
                const previousIndex = i - 1;

                if (docs[0].transactionType === 'Receipt') {
                    docs[0].cummulativeOutstanding = -docs[0].policyNet
                }
                else if (docs[0].policyNumber === 'BF') {
                    docs[0].cummulativeOutstanding = +docs[0].cummulativeOutstanding;
                }
                else if (docs[0].transactionType === 'Credit') {
                    docs[0].cummulativeOutstanding = -docs[0].policyNet;
                }
                else {
                    docs[0].cummulativeOutstanding = +docs[0].policyNet;
                }
                if (docs[i].transactionType === 'Debit' && docs.length > 1) {
                    docs[i].cummulativeOutstanding = +docs[previousIndex].cummulativeOutstanding + +docs[i].policyNet
                }
                if (docs[i].transactionType === 'Receipt' && docs.length > 1) {
                    docs[i].cummulativeOutstanding = +docs[previousIndex].cummulativeOutstanding - +docs[i].policyNet
                }
                if (docs[i].transactionType === 'Credit' && docs.length > 1) {
                    docs[i].cummulativeOutstanding = +docs[previousIndex].cummulativeOutstanding - +docs[i].policyNet
                }
            }
        }


        gridOneContent(docs);


    }

    const aa = {};
    const aaa = []

    //################################################################
    //echo grid
    function gridOneContent(docs) {
        aa['outstandingObj'] = docs
        //aaa.push({outstandingObj:docs}) 
        //Object.defineProperty(aa, 'outstandingObj', docs);

        const lastItem = docs[docs.length - 1].cummulativeOutstanding;
        aa['cummulativeOutstanding'] = lastItem;
    }


    //################################################################
    //################################################################
    //################################################################
    //ageing feature
    const ageingFeature = (docs) => {
        let Debits = [];
        let Credits = [];
        let Receipts = [];

        docs.map((doc) => {
            if (doc.transactionType === 'Debit') {
                Debits.push(
                    {
                        docID: doc.docID,
                        transactionType: doc.transactionType,
                        universalDate: doc.universalDate,
                        policyNumber: doc.policyNumber,
                        endorsmentNumber: doc.endorsmentNumber,
                        policyNet: doc.policyNet,
                        allocatedDocID: doc.allocatedDocID,
                        policyCredits: [],
                        receiptNumbers: [],
                        policyCreditsDetails: [],
                        outstanding: 0,
                    }
                )
            }
            else if (doc.transactionType === 'Credit') {
                Credits.push(
                    {
                        docID: doc.docID,
                        transactionType: doc.transactionType,
                        universalDate: doc.universalDate,
                        policyNumber: doc.policyNumber,
                        endorsmentNumber: doc.endorsmentNumber,
                        policyNet: doc.policyNet,
                        allocatedDocID: doc.allocatedDocID,
                    }
                )
            }
            else if (doc.transactionType === 'Receipt') {
                Receipts.push(
                    {
                        docID: doc.docID,
                        transactionType: doc.transactionType,
                        universalDate: doc.universalDate,
                        policyNumber: doc.policyNumber,
                        endorsmentNumber: doc.endorsmentNumber,
                        policyNet: doc.policyNet,
                        receiptAllocationDetails: doc.receiptAllocationDetails,
                    }
                )
            }
        });


        //calc outstanding
        Receipts.map((item) => {
            Object.values(item.receiptAllocationDetails).map((value) => {
                Debits.map((val, index) => {
                    if (value.receivedDocID === val.docID) {
                        const Values = [...Debits];
                        if (Values[index]['receiptNumbers'].indexOf(item.receiptNumber) == -1) {
                            Values[index]['receiptNumbers'].push(item.policyNumber);
                            Values[index]['policyCredits'].push(value.receivedAllocatedAmount);
                            const a = item.policyNumber + ' - ' + item.universalDate + ' - ' + value.receivedAllocatedAmount
                            Values[index]['policyCreditsDetails'].push(a);
                            Debits = Values;
                        }
                    }
                })
            });
        });

        Credits.map((item) => {
            Debits.map((val, index) => {
                if (item.allocatedDocID === val.docID) {
                    const Values = [...Debits];
                    if (Values[index]['receiptNumbers'].indexOf(item.docID) == -1) {
                        Values[index]['receiptNumbers'].push(item.docID);
                        Values[index]['policyCredits'].push(item.policyNet);
                        const a = item.policyNumber + ' - ' + item.endorsmentNumber + ' - ' + item.policyNet;
                        Values[index]['policyCreditsDetails'].push(a);
                        Debits = Values;
                    }
                }
            });
        });


        calculateOutstanding(Debits)

    }

    //################################################################
    //calc outstanding per policy
    const calculateOutstanding = (docs) => {

        docs.map((doc, index) => {
            const outstanding = parseInt(doc.policyNet) - doc.policyCredits.reduce((total, item) => { return total + +item }, 0);
            docs[index].outstanding = outstanding
        });


        distributeAgeing(docs);

    }

    //################################################################
    //distribute ageing
    const distributeAgeing = (docs) => {

        const thirtyDays = [];
        const sixtyDays = [];
        const ninetyDays = [];
        const oneTwentyDays = [];
        const aboveOneTwentyDays = [];
        const credit = [];
        const total = [];
        const totalCredits = [];
        const totalDedits = [];
        docs.map((doc) => {

            if (doc.outstanding > 0) {
                const dateOne = new Date(doc.universalDate);
                const dateTwo = new Date();
                const datesCompared = Math.abs(dateOne - dateTwo);
                const datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
                total.push(doc.outstanding);
                if (datesDifference < 31) {
                    thirtyDays.push(doc.outstanding);
                }
                else if (datesDifference < 61 && datesDifference > 30) {
                    sixtyDays.push(doc.outstanding);
                }
                else if (datesDifference < 91 && datesDifference > 60) {
                    ninetyDays.push(doc.outstanding);
                }
                else if (datesDifference < 121 && datesDifference > 90) {
                    oneTwentyDays.push(doc.outstanding);
                }
                else if (datesDifference > 120) {
                    aboveOneTwentyDays.push(doc.outstanding);
                }
            }
            else {
                credit.push(doc.outstanding)
                total.push(doc.outstanding)
            }

        });

        const ageingObj = {
            thirtyDays: thirtyDays.reduce((total, item) => { return total + +item }, 0),
            sixtyDays: sixtyDays.reduce((total, item) => { return total + +item }, 0),
            ninetyDays: ninetyDays.reduce((total, item) => { return total + +item }, 0),
            oneTwentyDays: oneTwentyDays.reduce((total, item) => { return total + +item }, 0),
            aboveOneTwentyDays: aboveOneTwentyDays.reduce((total, item) => { return total + +item }, 0),
            credit: credit.reduce((total, item) => { return total + +item }, 0),
            total: total.reduce((total, item) => { return total + +item }, 0),
        }

        gridTwoContent(ageingObj);

    }


    //################################################################
    //echo grid
    const gridTwoContent = (docs) => {
        aa['ageingObj'] = docs;
        aa['ageingThirtyDays'] = docs.thirtyDays;
        aa['ageingSixtyDays'] = docs.sixtyDays;
        aa['ageingNinetyDays'] = docs.ninetyDays;
        aa['ageingOneTwentyDays'] = docs.oneTwentyDays;
        aa['ageingMoreThanOneTwentyDays'] = docs.aboveOneTwentyDays;
        aa['ageingCredit'] = docs.credit;
        aa['cummulativeAgeingOutstanding'] = docs.total;
    }

    //################################################################
    //echo grid
    const gridThreeContent = (docs) => {

        //get client details
        aa['clientDetailsObj'] = clientDetails;
        aa['id'] = clientName;

        //bind data
        const stmtDetails = {
            periodFrom: DateFormat(agewiseOutstandingFilterFromDateRN),
            periodTo: DateFormat(agewiseOutstandingFilterToDateRN),
            insurer: agewiseOutstandingInsurerFilterRN,
        }

        aa['stmtDetails'] = stmtDetails;

        //get ageing totals
        const ageingDebits = [];
        const ageingCreditsReceipts = [];
        docs.map((doc) => {
            if (doc.transactionType === 'Debit') {
                ageingDebits.push(doc.policyNet)
            }
            else if (doc.transactionType === 'Credit') {
                ageingCreditsReceipts.push(doc.policyNet)
            }
            else if (doc.transactionType === 'Receipt') {
                ageingCreditsReceipts.push(doc.policyNet)
            }
        });

        const ageingTotalDebits = ageingDebits.reduce((total, item) => { return total + +item }, 0);
        const ageingTotalCreditsReceipts = ageingCreditsReceipts.reduce((total, item) => { return total + +item }, 0);
        aa['ageingTotalClientDebits'] = ageingTotalDebits;
        aa['ageingTotalClientCreditsReceipts'] = ageingTotalCreditsReceipts;
        

    }

    //################################################################
    //begining
    //clientTransactions().then((response) => { cleanData(response) })
    cleanData(clientDocs)
    return (aa);

}

export default ProcessClientStatementTransactions;