import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../Body/Loader/Loader";
import { useDropzone } from 'react-dropzone';
import axios from "axios";

const WithholdingCertificate = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //#####################################################################
    //get user input
    const [expenseReceiptFile, setExpenseReceiptFile] = useState([]);
    console.log(expenseReceiptFile)

    //################################################################
    //file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setExpenseReceiptFile(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    //################################################################
    //submit handler
    const withholdingCertificateSubmitHandler = (e) => {
        e.preventDefault();

        try {
            const file = expenseReceiptFile[0];

            const reader = new FileReader();

            reader.onload = (e) => {
                const base64File = e.target.result.split(',')[1]; // Remove data:image/jpeg;base64, prefix
                const requestBody = {
                    file: base64File,
                    filename: file.name,
                    filetype: file.type,
                };

                axios.post('http://localhost:5000/simia-9e230/us-central1/addmessage/uploadDocument',
                    requestBody,
                ).then((response) => {
                    console.log(response)
                }).catch(error => { console.log(error) });

            }
            reader.readAsDataURL(file);
        }

        catch (error) { console.log(error) }

    }

    // <img src="https://drive.google.com/uc?export=view&id=0B6wwyazyzml-OGQ3VUo0Z2thdmc">
    // https://drive.google.com/file/d/1xk3_4_1Xx5OGIrwbTFvQb-lpvFxIq2gG/view?usp=drive_link

    //################################################################
    //content  
    const WithholdingCertificateContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <form>
                <div className='h_5'></div>
                <p className='bold'>Withholding Cert</p>
                <div className='h_5'></div>
                <div className='file_upload_container'>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            expenseReceiptFile.length > 0
                                ? <p>{expenseReceiptFile[0].name}</p>
                                : isDragActive
                                    ? <p>Drop the files here ...</p>
                                    : <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>
                </div>
                <div className='h_5'></div>
                <input type="file" name="file" id="files" multiple onChange={handleFileChange}></input>
                <button type="button" onClick={withholdingCertificateSubmitHandler} className='btn_pink'>Upload</button>
            </form>
        </div>
    </section>


    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {WithholdingCertificateContent}
            {loaderContent}
        </section>
    )
}

export default WithholdingCertificate;