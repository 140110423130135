import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import { useRecoilState } from "recoil";
import { rightNavContent } from "../../../atoms";
import Worker from './FinanceControls.Worker';
import axios from "axios";
import UUID from '../../Hooks/UUID/UUID'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { reloadOnSuccessExpenseAccountCreationHandler } from "../../../atoms";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const FinanceControls = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [reloadOnSuccessExpenseAccountCreationHandlerRN, setReloadOnSuccessExpenseAccountCreationHandlerRN] = useRecoilState(reloadOnSuccessExpenseAccountCreationHandler);

    //################################################################
    //reload
    const [reload, setReload] = useState(0);

    //################################################################
    // reload on success policy class creation
    const [reloadTwo, setReloadTwo] = useState(0)
    useEffect(() => {
        if (reloadOnSuccessExpenseAccountCreationHandlerRN) {
            setReloadTwo(Math.random(1, 100000));
            setReloadOnSuccessExpenseAccountCreationHandlerRN(false);
        }
    }, [reloadOnSuccessExpenseAccountCreationHandlerRN]);

    //################################################################
    //delete
    //step 2
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Finance Controls',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Deleted Account.'
        }
        props.onePropDown(onePropObj);
        setReload(UUID());
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Finance Controls',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Deleting Expense Account.'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    //post data
    const deleteExpenseAccountHandler = (documentIdToDelete) => {
        setIsLoading(true);

        const CreateExpenseAPI = async () => {

            axios.get(`https://addmessage-7fqanz2g2q-uc.a.run.app/deleteexpenseaccount/${documentIdToDelete}`)
                .then(response => {
                    console.log(response)
                    //console.log(response)
                    // Handle the response data
                    //console.log(response.data);
                    showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    //console.log(error);
                    showErrorMSGOnExpenseSave();
                });
        }
        CreateExpenseAPI();

    }

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = { sampleData: 'sample data' }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [reload, reloadTwo]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'type', headerName: 'Type', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        {
            field: 'data', headerName: 'Account Titles', sortable: false, filter: false, width: 400, wrapText: true, autoHeight: true,
            cellRenderer: params => {
                return params.value.map((doc, index) => {
                    return <span className='border p-2 mr-1 rounded	 border-indigo-500 cursor-pointer' key={index}>
                        <span className='inline-block'>
                            <p className='inline-block mr-1'>{doc.account}</p>
                            <i className='ri-close-line  align-middle text-pink-950' onClick={() => { deleteExpenseAccountHandler(doc._id) }}></i>
                        </span>
                    </span>
                })
            }

        },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Finance Controls</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Finance Controls Create Expense Account') }}>Create Expense A/C</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default FinanceControls;