const MotorCommTPOOwnGoods = (receivedVehicleDetails) => {
    try {
        
        let premium = [];

        //premium
        if (receivedVehicleDetails.tareWeight <= 3) {
            premium.push(7500);
        }
        else if(receivedVehicleDetails.tareWeight >3 && receivedVehicleDetails.tareWeight <= 7){
            premium.push(12500);
        }
        else if(receivedVehicleDetails.tareWeight > 7){
            premium.push(17500);
        }

        const p = premium.reduce((total, item) => { return total + +item }, 0);

        const updatedPremiumCalculation = { ...receivedVehicleDetails };
        updatedPremiumCalculation.premiumCharge = p;

        return (updatedPremiumCalculation)

    } catch (error) {}
}

export default MotorCommTPOOwnGoods;