const MotorPrivateComprehensivePremiumCalculator = (receivedVehicleDetails) => {
    try {
        let premium = [];
        let windScreen = [];
        let radioCasset = [];
        let excessProtector = [];
        let pvt = [];



        //premium
        if ((receivedVehicleDetails.value * receivedVehicleDetails.basicPremiumRating / 100) >= 15000) {
            premium.push(receivedVehicleDetails.value * receivedVehicleDetails.basicPremiumRating / 100);
        }
        else {
            premium.push(15000);
        }
        //excess
        if ((receivedVehicleDetails.value * receivedVehicleDetails.excessProtectorRating / 100) >= 5000) {
            excessProtector.push(receivedVehicleDetails.value * receivedVehicleDetails.excessProtectorRating / 100);
        }
        else if (receivedVehicleDetails.excessProtectorRating == 0) {
            excessProtector.push(0)
        }
        else {
            excessProtector.push(5000);
        }
        //pvt
        if ((receivedVehicleDetails.value * receivedVehicleDetails.PVTRating / 100) >= 2500) {
            pvt.push(receivedVehicleDetails.value * receivedVehicleDetails.PVTRating / 100);
        }
        else if (receivedVehicleDetails.PVTRating == 0) {
            pvt.push(0);
        }
        else {
            pvt.push(2500);
        }
        //wind screen
        if (receivedVehicleDetails.windScreen > 50000) {
            windScreen.push((receivedVehicleDetails.windScreen - 50000) * receivedVehicleDetails.windScreenRating / 100);
        }
        //radio casset
        if (receivedVehicleDetails.radioCasset > 50000) {
            radioCasset.push((receivedVehicleDetails.radioCasset - 50000) * receivedVehicleDetails.radioCassetRating / 100);
        }



        const p = premium.reduce((total, item) => { return total + +item }, 0);
        const w = windScreen.reduce((total, item) => { return total + +item }, 0);
        const r = radioCasset.reduce((total, item) => { return total + +item }, 0);
        const e = excessProtector.reduce((total, item) => { return total + +item }, 0);
        const v = pvt.reduce((total, item) => { return total + +item }, 0);

        let stampDuty = 0;
        if(receivedVehicleDetails.subClass !== '(060) - Marine Hull'){
            stampDuty = 40
        }        

        // const totalPremium = p + w + r + e + v;
        // const TLevy = totalPremium * 0.002;
        // const PHCFund = totalPremium * 0.0025;
        // const policyNet = totalPremium + TLevy + PHCFund;

        const updatedPremiumCalculation = { ...receivedVehicleDetails };
        updatedPremiumCalculation.premiumCharge = p;
        updatedPremiumCalculation.windScreenCharge = w;
        updatedPremiumCalculation.radioCassetCharge = r;
        updatedPremiumCalculation.excessProtectorCharge = e;
        updatedPremiumCalculation.pvtCharge = v;
        updatedPremiumCalculation.stampDuty = stampDuty;
        
        return (updatedPremiumCalculation)


    } catch (error) { }
}

export default MotorPrivateComprehensivePremiumCalculator;