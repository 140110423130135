import React, { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { emailClientStatementSelectedClient, emailClientStatementInsurerName } from "../../../atoms";
import { emailClientStatementFilterFromDate, emailClientStatementFilterToDate } from "../../../atoms";
import { rightNavContent } from "../../../atoms";
import Loader from "../Loader/Loader";
import Worker from './EmailClientStatement.Worker';
import { Stepper, Button, Group } from '@mantine/core';
import { useReactToPrint } from 'react-to-print';
import EmailClientStatementStepOne from "./EmailClientStatementStepOne";
import EmailClientStatementStepTwo from "./EmailClientStatementStepTwo";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import EmailClientStatementPrintable from "./EmailClientStatementPrintable";
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import axios from "axios";
import { toPng } from 'html-to-image';
import { jsPDF } from "jspdf";
import companyLogo from '../../Assets/img/logo.png';
import { activeInsurerPortfolio } from "../../../atoms";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const EmailClientStatement = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle:
            ` @page {
                size: auto;
                margin: 5mm 0 !important;
              }
        `,
    });

    //################################################################
    // recoil values
    const [emailClientStatementSelectedClientRN, setEmailClientStatementSelectedClientRN] = useRecoilState(emailClientStatementSelectedClient);
    const emailClientStatementFilterFromDateRN = useRecoilValue(emailClientStatementFilterFromDate);
    const emailClientStatementFilterToDateRN = useRecoilValue(emailClientStatementFilterToDate);
    const emailClientStatementInsurerNameRN = useRecoilValue(emailClientStatementInsurerName);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio)

    const propHandler = (receivedData) => {
        props.onePropDown(receivedData)
    }

    //################################################################
    //steper
    const [active, setActive] = useState(0);
    const [highestStepVisited, setHighestStepVisited] = useState(active);

    const handleStepChange = (nextStep) => {
        const isOutOfBounds = nextStep > 3 || nextStep < 0;

        if (isOutOfBounds) {
            return;
        }

        setActive(nextStep);
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
    };

    // Allow the user to freely go back and forth between visited steps.
    // const shouldAllowSelectStep = (step) => highestStepVisited >= step && active !== step;
    const shouldAllowSelectStep = (step) => {
        const back = active - step;
        const forward = step - active;
        return back == 1 || forward == 1 && active !== step
    }

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    const [rowData, setRowData] = useState([])

    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = {
                selectedClient: emailClientStatementSelectedClientRN,
                emailClientStatementFilterFromDateRN: emailClientStatementFilterFromDateRN,
                emailClientStatementFilterToDateRN: emailClientStatementFilterToDateRN,
                emailClientStatementInsurerNameRN: emailClientStatementInsurerNameRN,
                activeInsurerPortfolioRN: activeInsurerPortfolioRN,
            }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
                if (event.data) {
                    if (event.data.mainSection && event.data.mainSectionAgeing) {
                        setRowData([...event.data.mainSection, ...event.data.mainSectionAgeing]);
                    }
                }
            }

            return () => { worker.terminate() };

        } catch (error) { }
    }, [emailClientStatementSelectedClientRN, emailClientStatementFilterFromDateRN, emailClientStatementFilterToDateRN, emailClientStatementInsurerNameRN, activeInsurerPortfolioRN]);

    //################################################################
    //content
    const content = <section>
        <div className="h_8"></div>
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
            <Stepper.Step
                label="First Step"
                description="Verify Statement"
                allowStepSelect={shouldAllowSelectStep(0)}
            >
                <EmailClientStatementStepOne rowData={rowData} finalData={finalData} />
            </Stepper.Step>
            <Stepper.Step
                label="Second Step"
                description="Verify and Send Email"
                allowStepSelect={shouldAllowSelectStep(1)}
            >
                <EmailClientStatementStepTwo finalData={finalData} propHandler={propHandler} />
            </Stepper.Step>
        </Stepper>

    </section>

    const [a, setA] = useState(null)
    useEffect(() => {
        try {

            // const addContentToPDF = () => {
            //     if (componentRef.current) {
            //       const pdf = new jsPDF();
            //       const content = componentRef.current.innerHTML;

            //       pdf.html(content, {
            //         callback: function () {
            //           // Get the internal data of the PDF document
            //           const pdfData = pdf.output();

            //           // Convert the data to base64
            //           const pdfBase64 = btoa(pdfData);
            //           pdf.save('document-watermarked.pdf');
            //           // pdfBase64 is a base64-encoded string
            //           //setA(pdfBase64);
            //         },
            //         width:'100%'
            //       });
            //     }
            //   };
            //   addContentToPDF()


            var element = componentRef.current;
            var opt = {
                margin: 1,
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                enableLinks: true,
            };

            // New Promise-based usage:
            //html2pdf().from(element).set(opt).save();
            // html2pdf().from(element).set(opt).outputPdf().then(function(pdf) {
            //     // This logs the right base64
            //     console.log(btoa(pdf));
            // });
            // Old monolithic-style usage:
            // html2pdf(element, opt);


        } catch (error) {
            console.log(error)
        }
    }, [finalData])

    //mail log
    const mailLog = () => {
        try {

            var element = componentRef.current;
            var opt = {
                margin: 1,
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                enableLinks: true,
            };
            let aa = ''
            // New Promise-based usage:
            //html2pdf().from(element).set(opt).save();
            html2pdf().from(element).set(opt).outputPdf().then(function (pdf) {
                // This logs the right base64
                aa = btoa(pdf);
                const ff = {
                    file: btoa(pdf),
                    filename: 'name.pdf',
                    filetype: 'application/pdf',
                }



                axios.post('http://localhost:5000/simia-9e230/us-central1/addmessage/send', ff)
                    .then(response => {
                        if (response.status == 200) {
                            console.log(response)
                        }
                    }).catch(error => { console.log(error); });
            });



        } catch (error) { console.log(error); }
    }


    //################################################################
    //printable
    const printable = <section style={{ display: "none" }}>
        <div ref={componentRef}>
            <EmailClientStatementPrintable finalData={finalData} />
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Client Statement</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Email Client Statement Select Client') }}>Select Client</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Email Client Statement Filter Date') }}>Filter Date</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrint}>Print</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {printable}
            {/* {URRightClickMenu} */}
        </section>
    );
}

export default EmailClientStatement;