import React, { useState, useEffect, useCallback } from 'react';
import Loader from '../../Body/Loader/Loader';
import { doc, runTransaction } from "firebase/firestore";
import db, { storage } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDropzone } from 'react-dropzone';
import UUID from '../../Hooks/UUID/UUID';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { rightNavPolicyItem, pageReloaderHandler } from '../../../atoms';
import './ClaimDocumentsUpload.css';
import '../../RightNav/RightNav.css'

const ClaimDocumentsUpload = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get recoli value
    const rightNavPolicyItemRV = useRecoilValue(rightNavPolicyItem)

    //################################################################
    //get user input
    const [claimDocumentCategory, setClaimDocumentCategory] = useState('')
    const [claimDocumentCategoryNarration, setClaimDocumentCategoryNarration] = useState('')
    const [claimDocument, setClaimDocument] = useState('')
    const [claimDocUploadProgress, setClaimDocUploadProgress] = useState(0);

    //################################################################
    //claim file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setClaimDocument(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //################################################################
    //set recoils state to reload claim view
    const [pageReloaderHandlerCDU, setPageReloaderHandlerCDU] = useRecoilState(pageReloaderHandler)

    //################################################################
    //step 3
    const changeViewOnSuccessClaimDocUpload = () => {
        //reset state var
        setClaimDocumentCategory('');
        setClaimDocument('');
        setClaimDocUploadProgress(0);
        //open view
        const onePropObj = {
            setView: 'View Claim',
            previousView: 'View Claim',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Claim Document Uploaded Successfully'
        }
        props.onePropDown(onePropObj)
        setPageReloaderHandlerCDU(onePropObj)
    }

    //################################################################
    //step 2
    async function updateAssociateDocRecord(downloadURL) {

        setIsLoading(true);

        const sfDocRef = doc(db, "Claims", "lqj4Qnx0jn6CJfHQdiff");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                //create obj to post
                const claimObj = {
                    claimDocumentDownloadLink: downloadURL,
                    claimDocumentCategory: claimDocumentCategory,
                    claimDocumentCategoryNarration: claimDocumentCategoryNarration,
                    documentPolicyNumber: rightNavPolicyItemRV.docData.policyNumber,
                    documentEndorsmentNumber: rightNavPolicyItemRV.docData.endorsmentNumber,
                    documentPolicyID: rightNavPolicyItemRV.docData.policyDocID,
                    documentUploadDate: new Date(),
                    uploadedBy: currentUser,
                };

                const extracted = []

                Object.entries(sfDoc.data()).map((item) => { extracted.push({ docID: item[0], docData: item[1] }) })
                const filtered = extracted.filter(item => item.docID === props.onePropUp.postedDocRef)

                let qwe = Object.values(filtered[0].docData.claimDocuments)
                qwe.push(claimObj)

                const newObj = {
                    policyDocID: filtered[0].docData.policyDocID,
                    clientName: filtered[0].docData.clientName,
                    insurerName: filtered[0].docData.insurerName,
                    insurerClaimNumber: filtered[0].docData.insurerClaimNumber,
                    policyNumber: filtered[0].docData.policyNumber,
                    endorsmentNumber: filtered[0].docData.endorsmentNumber,
                    bankInterest: filtered[0].docData.bankInterest,
                    selectedProductClass: filtered[0].docData.selectedProductClass,
                    selectedProductSubClass: filtered[0].docData.selectedProductSubClass,
                    inceptionDate: filtered[0].docData.inceptionDate,
                    expiryDate: filtered[0].docData.expiryDate,
                    insurerDebitDate: filtered[0].docData.insurerDebitDate,
                    locationOfTheRisk: filtered[0].docData.locationOfTheRisk,
                    totalSumInsured: filtered[0].docData.totalSumInsured,
                    dateOfLoss: filtered[0].docData.dateOfLoss,
                    natureOfLoss: filtered[0].docData.natureOfLoss,
                    vehicleDetails: filtered[0].docData.vehicleDetails,
                    estimatedAmountOfLoss: filtered[0].docData.estimatedAmountOfLoss,
                    locationOfTheVehicle: filtered[0].docData.locationOfTheVehicle,
                    contactPerson: filtered[0].docData.contactPerson,
                    reserveAmount: filtered[0].docData.reserveAmount,
                    assessor: filtered[0].docData.assessor,
                    assessmentDate: filtered[0].docData.assessmentDate,
                    assessorFirm: filtered[0].docData.assessorFirm,
                    assessorAppointmentDate: filtered[0].docData.assessorAppointmentDate,
                    createdBy: filtered[0].docData.createdBy,
                    dateCreated: filtered[0].docData.dateCreated,
                    status: filtered[0].docData.status,
                    stage: filtered[0].docData.stage,
                    paidAmount: filtered[0].docData.paidAmount,
                    title: filtered[0].docData.title,
                    claimDocuments: { ...qwe },
                };
                transaction.update(sfDocRef, { [props.onePropUp.postedDocRef]: newObj });
                setIsLoading(false);
            });
            //console.log("Transaction successfully committed!");
            changeViewOnSuccessClaimDocUpload()
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    //step 1
    //upload doc right nav
    const valuationReportUploadSubmitHandler = (e) => {
        e.preventDefault();

        const file = claimDocument[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRV.docData.clientName,
                'clientID': '',
                'policyNumber': rightNavPolicyItemRV.docData.policyNumber,
                'endorsmentNumber': rightNavPolicyItemRV.docData.endorsmentNumber,
                'policyDocID': rightNavPolicyItemRV.docData.policyDocID,
                'documentID': UUID(),
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'claim documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setClaimDocUploadProgress(Math.round(progress))
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
                if (progress < 100) { setIsLoading(true); }
                else { setIsLoading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateAssociateDocRecord(downloadURL)
                });
            }
        );
    }
    //console.log(rightNavPolicyItemRV.docData.natureOfLoss)
    //################################################################
    // documents
    const claimDocumentsContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p>Document Upload</p>
            <div className='h_10'></div>
            {
                claimDocUploadProgress > 0
                    ? <div><p className='f_7'>Upload Progress:  {claimDocUploadProgress}% Done</p></div>
                    : ''
            }
            <div className='h_15'></div>
            {
                rightNavPolicyItemRV.docData
                    ? rightNavPolicyItemRV.docData.selectedProductClass === '(07) - Motor Private' || rightNavPolicyItemRV.docData.selectedProductClass === '(08) - Motor Commercial'
                        ? <div className='input_group'>
                            <select value={claimDocumentCategory} className='select' onChange={(e) => { setClaimDocumentCategory(e.target.value) }}>
                                <option>Select Option</option>
                                <option>Claim Form</option>
                                <option>Driving License</option>
                                <option>Logbook</option>
                                <option>Police Abstract</option>
                                <option>Driver's Statement</option>
                                <option>Driver's ID</option>
                                <option>ETR Receipt</option>
                                <option>Picture Of The Damage</option>
                                <option>Garage Estimate</option>
                                <option>Discharge Vourcher</option>
                                <option>Cheque</option>
                                <option>Other</option>
                            </select>
                            <label className='placeholder'><p>Document Category</p></label>
                        </div>
                        : <div className='input_group'>
                            <select value={claimDocumentCategory} className='select' onChange={(e) => { setClaimDocumentCategory(e.target.value) }}>
                                <option>Select Option</option>
                                <option>Claim Form</option>
                                <option>Picture Of The Damage</option>
                                <option>Receipt</option>
                                <option>Discharge Vourcher</option>
                                <option>Cheque</option>
                                <option>Other</option>
                            </select>
                            <label className='placeholder'><p>Document Category</p></label>
                        </div>
                    : ''

            }
            {
                claimDocumentCategory === 'Other'
                &&
                <div>
                    <div className='h_10'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setClaimDocumentCategoryNarration(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Narration</p></label>
                    </div>
                </div>
            }


            <div className='h_10'></div>
            <div className='file_upload_container'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        claimDocument
                            ? <p>{claimDocument[0].name}</p>
                            : isDragActive
                                ? <p>Drop the files here ...</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </div>
            <div className='h_10'></div>
            {/* valuationReportUploadSubmitHandler */}
            <button onClick={valuationReportUploadSubmitHandler} className='btn_pink'>Upload</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {claimDocumentsContent}
            {loaderContent}
        </section>
    );
}

export default ClaimDocumentsUpload;