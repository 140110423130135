import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import db from '../../../firebase';
import { addDoc, doc, updateDoc, collection } from "firebase/firestore";
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import { useReactToPrint } from 'react-to-print';
import './RenewedReport.css';
import ConfirmAction from '../../Hooks/ConfirmAction/ConfirmAction';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import { useRecoilValue } from 'recoil';
import { unrenewedReportFilterFromDate, unrenewedReportFilterToDate, unrenewedReportFilterPolicyClass } from '../../../atoms';
import { unrenewedReportLapseAsOfDate, unrenewedReportCancelAsOfDate, unrenewedReportFilterInsurer } from '../../../atoms';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import GetAllDocsTransactionAndInsurerWise from '../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllInsurerTransactions from '../../Hooks/BackendConn/InsurerSpecific/GetAllInsurerTransactions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const UnrenewedReportGrid = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //get items from lrecoil state
    const unrenewedReportFilterFromDateRN = useRecoilValue(unrenewedReportFilterFromDate);
    const unrenewedReportFilterToDateRN = useRecoilValue(unrenewedReportFilterToDate);
    const unrenewedReportFilterPolicyClassRN = useRecoilValue(unrenewedReportFilterPolicyClass);
    const unrenewedReportLapseAsOfDateRN = useRecoilValue(unrenewedReportLapseAsOfDate);
    const unrenewedReportCancelAsOfDateRN = useRecoilValue(unrenewedReportCancelAsOfDate);
    const unrenewedReportFilterInsurerRN = useRecoilValue(unrenewedReportFilterInsurer);

    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerTransactions({ insurerName: unrenewedReportFilterInsurerRN, transactionType: 'Debit' });;
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //remove endor, dj, jv, cn, marine, bond, travel
    const [removedEndorsments, setRemovedEndorsments] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        debitDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && !item.docData.policyNumber.startsWith('DV')
                && !item.docData.policyNumber.startsWith('JV')
                && !item.docData.policyNumber.startsWith('CN')
                && !item.docData.policyNumber.startsWith('Rev')
                && item.docData.selectedProductSubClass !== '(060) - Marine Hull'
                && item.docData.selectedProductSubClass !== '(061) - Marine Certificate'
                && item.docData.selectedProductSubClass !== '(120) - Immigration Bond'
                && item.docData.selectedProductSubClass !== '(127) - Advance Payment Bond'
                && item.docData.selectedProductSubClass !== '(128) - Custom Bond'
                && item.docData.selectedProductSubClass !== '(093) - Travel'
                && item.docData.selectedProductSubClass !== '(020) - Contractors All Risks'
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled'
                && item.docData.businessType !== 'Nil Endorsment') {
                removedEndorsmentsList.push(item);
            }
        });
        setRemovedEndorsments(removedEndorsmentsList)
    }, [debitDocs]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        removedEndorsments.map((item) => {
            const oldInceptionDate = new Date(item.docData.expiryDate); // current date
            const newInceptionDate = new Date(oldInceptionDate);
            newInceptionDate.setDate(oldInceptionDate.getDate() + 1); // add one dayF
            const formattedNewInceptionDate = new Date(newInceptionDate)
            formattedNewInceptionDate.setHours(0, 0, 0);

            //filter period choosen
            if (new Date(formattedNewInceptionDate).valueOf() >= new Date(unrenewedReportFilterFromDateRN).valueOf() && new Date(unrenewedReportFilterToDateRN).valueOf() >= new Date(formattedNewInceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [removedEndorsments, unrenewedReportFilterFromDateRN, unrenewedReportFilterToDateRN]);

    //################################################################
    //find renewal
    const [renewedDebits, setRenewedDebits] = useState([]);
    useEffect(() => {
        // console.log('03/COMP/01/0800/7902/2022'.replace(/\//g, "").slice(-12))
        const renewalsSelected = [];
        selectedPeriodFilteredDocs.map((item) => {
            debitDocs.map((value) => {
                if ((value.docData.policyNumber).replace(/\//g, "") === (item.docData.policyNumber).replace(/\//g, "")
                    && item.docData.endorsmentNumber !== value.docData.endorsmentNumber
                    && new Date(value.docData.insurerDebitDate).valueOf() > new Date(item.docData.insurerDebitDate).valueOf()
                    && !value.docData.endorsmentNumber.startsWith('05/')
                    && value.docData.businessType !== 'Nil Endorsment'
                    && value.docData.businessType !== 'Endorsment'
                    && value.docData.businessType !== 'Comesa Endorsment') {
                    renewalsSelected.push(item);
                }
            });
        });
        setRenewedDebits(renewalsSelected);
    }, [selectedPeriodFilteredDocs,debitDocs]);

    //################################################################
    //removed renewed
    const [removedRenewed, setRemovedRenewed] = useState([]);
    useEffect(() => {
        const filteredUnrenewed = selectedPeriodFilteredDocs.filter(item => !renewedDebits.includes(item));
        setRemovedRenewed(filteredUnrenewed)
    }, [renewedDebits])

    //################################################################
    //filter policy class
    const [filteredClassDocs, setFilteredClassDocs] = useState([])
    useEffect(() => {
        const a = removedRenewed.filter((doc) => {
            if (unrenewedReportFilterPolicyClassRN === 'ALL') { return doc }
            else if (unrenewedReportFilterPolicyClassRN === 'MOTOR') {
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    return doc;
                }
            }
            else if (unrenewedReportFilterPolicyClassRN === 'NON-MOTOR') {
                if (doc.docData.selectedProductClass !== '(07) - Motor Private'
                    && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
                    && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO') {
                    return doc;
                }
            }
        });
        setFilteredClassDocs(a);
    }, [removedRenewed, unrenewedReportFilterPolicyClassRN])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = filteredClassDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [filteredClassDocs]);


    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateSortedDocs.map((doc) => {

                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                //look for vr
                const valuationReportDocuments = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Valuation Report') {
                            valuationReportDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //look for lb
                const logBookDocuments = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Logbook') {
                            logBookDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //look for pd
                const policyDocuments = [];
                if (doc.docData.associateDocuments) {
                    Object.values(doc.docData.associateDocuments).map((item) => {
                        if (item.documentUploadCategory === 'Policy Document') {
                            policyDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                    VR: valuationReportDocuments,
                    LB: logBookDocuments,
                    PD: policyDocuments,
                })

            });

            setFinalData(finalObj)

        } catch (error) { }
    }, [dateSortedDocs]);




    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //################################################################
    //################################################################
    // grid states
    const [rightClickedPolicyID, setrightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'policyNumber', 'endorsmentNumber', 'policyClass', 'inceptionDate', 'expiryDate', 'details', 'policyNet']

    const exportToExcelFileName = 'UNRENEWED REPORT    --    INSURER: ' + unrenewedReportFilterInsurerRN + '    --    PERIOD: ' + DateFormat(unrenewedReportFilterFromDateRN) + ' - ' + DateFormat(unrenewedReportFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'UNRENEWED REPORT    ||    INSURER: ' + unrenewedReportFilterInsurerRN + '    ||    PERIOD: ' + DateFormat(unrenewedReportFilterFromDateRN) + ' - ' + DateFormat(unrenewedReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 150, 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 9, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'UNRENEWED REPORT    ||    INSURER: ' + unrenewedReportFilterInsurerRN + '    ||    PERIOD: ' + DateFormat(unrenewedReportFilterFromDateRN) + ' - ' + DateFormat(unrenewedReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 150,'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 9, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //################################################################
    //################################################################
    

    //################################################################
    //renew policy
    const renewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Renew This Policy?');
        if (!executeAbort) { return false }

        const onePropObj = {
            setView: 'Body Renewal Final',
            previousView: 'Unrenewed Report',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //lapse
    //################################################################
    //step 4    
    // show lasped msg and open new view
    const displayLapsedMSG = () => {
        const onePropObj = {
            setView: 'Lapsed Policy View',
            previousView: 'Unrenewed Report',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Lapsed',
            activity: 'Policy Lapsing',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################ 
    //step 3
    //create nil endors doc
    async function createNilEndorsmentPolicyHandler(newObj) {
        setIsLoading(true);

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), newObj);
            console.log("Document written with ID: ", docRef.id);
            setIsLoading(false)
            displayLapsedMSG();
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    //################################################################
    //step 2
    //create nil endor policy
    const nilEndorsmentHandler = () => {
        const a = debitDocs.filter(doc => doc.docID === rightClickedPolicyID)
        const b = a[0];

        const additionalInstructionsItems = [
            { additionalInstructionItem: 'Kindly Note That This Is A Nil Endorsment Document To Indicate Lapsed Policy.' },
        ];

        const insurerDebitDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

        const newObj = {
            clientName: b.docData.clientName,
            insurerName: b.docData.insurerName,
            bankInterest: b.docData.bankInterest,
            policyNumber: b.docData.policyNumber,
            selectedProductClass: b.docData.selectedProductClass,
            selectedProductSubClass: b.docData.selectedProductSubClass,
            commissionRate: 0,
            businessType: 'Nil Endorsment',
            endorsmentNumber: '',
            inceptionDate: b.docData.inceptionDate,
            expiryDate: b.docData.expiryDate,
            insurerDebitDate: insurerDebitDate,
            locationOfTheRisk: b.docData.locationOfTheRisk,
            totalSumInsured: b.docData.totalSumInsured,
            premium: 0,
            PVTPremium: 0,
            stampDuty: 0,
            TLevy: 0,
            PHCFund: 0,
            commission: 0,
            commissionPayable: 0,
            expectedCommissionPayable: 0,
            outstandingCommission: 0,
            withholdingTax: 0,
            policyNet: 0,
            earthquake: 0,
            excessProtector: 0,
            comesaCard: 0,
            windScreen: 0,
            radioCasset: 0,
            insurerPolicyNet: 0,
            receiptAllocationBreakDown: [],
            commissionAllocationBreakDown: [],
            status: 'Pending',
            outstanding: 0,
            transactionType: 'Debit',
            employeesDetailsItems: {},
            propertyDescriptionItems: {},
            benefitsItems: {},
            deductibleItems: {},
            vehicleItems: {},
            clauseItems: {},
            additionalInstructionsItems: additionalInstructionsItems,
            motorDocuments: {},
            allocatedDocID: b.docID,
            dateCreated: new Date(),
            createdBy: currentUser,
        }

        createNilEndorsmentPolicyHandler(newObj);
    }

    //################################################################
    //step 1
    //lapse policy
    async function lapsePolicyHandler() {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Lapse This Policy?');
        if (!executeAbort) { return false }

        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightClickedPolicyID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'status': 'Lapsed',
                'lapsedDate': unrenewedReportLapseAsOfDateRN,
                'lapsedBy': currentUser,
                'lapsedOn': new Date(),
            });
            setIsLoading(false);
            nilEndorsmentHandler();
            setReload(Math.random(0, 1000))
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //step 3
    //show cancelled msg
    const viewCreatedCreditPolicyOnSuccessPosting = (newCreditDocID) => {
        const onePropObj = {
            setView: 'Pending List Edit',
            previousView: 'Unrenewed Report',
            postedDocRef: newCreditDocID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Cancelled Successfully. Credit Note Created Successfully.',
            activity: 'Policy Cancellation',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################ 
    //step 3
    //create credit
    async function createCreditPolicyHandler(newObj) {
        setIsLoading(true);

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), newObj);
            console.log("Document written with ID: ", docRef.id);
            setIsLoading(false)
            viewCreatedCreditPolicyOnSuccessPosting(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    //################################################################ 
    //step 2
    //calc prem
    const calculateNewPremium = () => {
        const a = debitDocs.filter(doc => doc.docID === rightClickedPolicyID)
        const b = a[0]
        const originalTotalPremium = +b.docData.premium + +b.docData.earthquake + +b.docData.windScreen + +b.docData.radioCasset + +b.docData.comesaCard

        //calculate days
        const dateOne = new Date(b.docData.inceptionDate);
        const dateTwo = new Date(unrenewedReportCancelAsOfDateRN);
        const datesCompared = Math.abs(dateOne - dateTwo);
        let datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
        const daysUnutilized = 365 - datesDifference + 1;

        const premium = Math.round(originalTotalPremium * daysUnutilized / 365);

        const TLevy = Math.round(premium * 0.002);

        const PHCFund = Math.round(premium * 0.0025);

        const commission = Math.round(premium * b.docData.commissionRate);

        const withholdingTax = Math.round(commission * 0.1);

        const policyNet = premium + TLevy + PHCFund;

        const insurerPolicyNet = policyNet - commission + withholdingTax;

        const insurerDebitDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

        const additionalInstructionsItems = [
            { additionalInstructionItem: 'Kindly Note This Policy Is Deemed To Be Cancelled As Of ' + DateFormat(unrenewedReportCancelAsOfDateRN) + ' And The Below Premium Refund Is Expected.' },
            { additionalInstructionItem: 'Note The Below Prorated Premium Calculation.' },
            { additionalInstructionItem: 'Unutilized Days = 365 - Debit Inception Date - Date Of Cancellation' },
            { additionalInstructionItem: daysUnutilized + ' = 365 - ' + DateFormat(b.docData.inceptionDate) + ' - ' + DateFormat(unrenewedReportCancelAsOfDateRN) },
            { additionalInstructionItem: 'Refund Premium = Debit Total Premium x Unutilized Days / 365' },
            { additionalInstructionItem: premium + ' = ' + originalTotalPremium + ' x ' + daysUnutilized + ' / 365' },
        ];

        const newObj = {
            clientName: b.docData.clientName,
            insurerName: b.docData.insurerName,
            bankInterest: b.docData.bankInterest,
            policyNumber: b.docData.policyNumber,
            selectedProductClass: b.docData.selectedProductClass,
            selectedProductSubClass: b.docData.selectedProductSubClass,
            commissionRate: b.docData.commissionRate,
            businessType: 'Refund',
            endorsmentNumber: '',
            inceptionDate: b.docData.inceptionDate,
            expiryDate: b.docData.expiryDate,
            insurerDebitDate: insurerDebitDate,
            locationOfTheRisk: b.docData.locationOfTheRisk,
            totalSumInsured: b.docData.totalSumInsured,
            premium: premium,
            PVTPremium: 0,
            stampDuty: 0,
            TLevy: TLevy,
            PHCFund: PHCFund,
            commission: commission,
            commissionPayable: 0,
            expectedCommissionPayable: 0,
            outstandingCommission: 0,
            withholdingTax: withholdingTax,
            policyNet: policyNet,
            earthquake: 0,
            excessProtector: 0,
            comesaCard: 0,
            windScreen: 0,
            radioCasset: 0,
            insurerPolicyNet: insurerPolicyNet,
            receiptAllocationBreakDown: [],
            commissionAllocationBreakDown: [],
            status: 'Pending',
            outstanding: 0,
            transactionType: 'Credit',
            employeesDetailsItems: b.docData.employeesDetailsItems,
            propertyDescriptionItems: b.docData.propertyDescriptionItems,
            benefitsItems: b.docData.benefitsItems,
            deductibleItems: b.docData.deductibleItems,
            vehicleItems: b.docData.vehicleItems,
            clauseItems: b.docData.clauseItems,
            additionalInstructionsItems: additionalInstructionsItems,
            motorDocuments: b.docData.motorDocuments ? b.docData.motorDocuments : {},
            allocatedDocID: b.docID,
            dateCreated: new Date(),
            createdBy: currentUser,
        }

        createCreditPolicyHandler(newObj);

    }

    //################################################################ 
    //step 1   
    //cancel policy
    async function cancelPolicyHandler() {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Calcel This Policy?');
        if (!executeAbort) { return false }

        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightClickedPolicyID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'status': 'Cancelled',
                'cancelledDate': unrenewedReportCancelAsOfDateRN,
                'cancelledBy': currentUser,
                'cancelledOn': new Date(),
            });
            setIsLoading(false);
            calculateNewPremium()
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={renewPolicyHandler}><p>Renew Policy</p></span>
        <div className=''></div>
        <span onClick={lapsePolicyHandler}><p>Lapse Policy</p></span>
        <div className=''></div>
        <span onClick={cancelPolicyHandler}><p>Cancel Policy</p></span>
    </div>

    //################################################################
    //################################################################
    //################################################################
    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setrightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        {
            field: 'VR', headerName: 'VR', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'LB', headerName: 'LB', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'PD', headerName: 'PD', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='renewed_policies unrenewed_policies'>
            <div className='body_section_header'>
                <p>Unrenewed Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {URRightClickMenu}
            {loaderContent}
        </section>
    )
}

export default UnrenewedReportGrid;