const InsuranceProducts = [
    {
        productClass: 'Select Product Class',
        subClass: {
            1: 'Select Product Sub Class'
        }
    },
    {
        productClass: '(02) - Engineering',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(020) - Contractors All Risks',
            3: '(021) - ContractorsPlant and Machinery',
            4: '(022) - Erection All Risks',
            5: '(023) - Machinery Break Down',
            6: '(024) - Computer Electronic Equipment',
            7: '(025) - Boiler and Pressure Vessel',
            8: '(026) - Loss of Profit (L.O.P) Following Machinery Breakdown',
            9: '(027) - Deterioration of Stock',
            10: '(028) - Engineering Completed Risks'
        }
    },
    {
        productClass: '(03) - Domestic',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(030) - Domestic Package',
            3: '(031) - Domestic Contents'
        }
    },
    {
        productClass: '(04) - Fire Industrial',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(040) - Fire Industrial',
            3: '(041) - Con Loss Following Fire',
            4: '(042) - Industrial All Risk',
            5: '(043) - Terrorism',
            6: '(044) - Business Combined'
        }
    },
    {
        productClass: '(05) - Public Liability',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(050) - Products Liabilty',
            3: '(051) - Professional Liability',
            4: '(052) - Public Liabilty',
            5: '(054) - Contractual Liabilty'
        }
    },
    {
        productClass: '(06) - Marine',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(060) - Marine Hull',
            3: '(061) - Marine Certificate',
            4: '(062) - Goods in Transit (GIT)',
            5: '(064) - CARRIERS LEGAL LIABILITY'
        }
    },
    {
        productClass: '(07) - Motor Private - Comprehensive',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0700) - Motor Private - Comprehensive',
            3: '(0701) - Motor Cycle - Comprehensive',
            4: '(0730) - Private Certificate Binder - Comprehensive'
        }
    },
    {
        productClass: '(07) - Motor Private - TPO',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0700) - Motor Private - TPO',
            3: '(0701) - Motor Cycle - TPO',
            4: '(0730) - Private Certificate Binder - TPO'
        }
    },
    {
        productClass: '(08) - Motor Commercial - Comprehensive',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0800) - Motor Commercial - Comprehensive',
            3: '(0801) - Tractor - Comprehensive',
            4: '(0802) - New Trade - Comprehensive',
            5: '(0803) - Private Hire - Comprehensive',
            6: '(0804) - PSV - Comprehensive',
            7: '(0807) - General Cartage - Comprehensive',
            8: '(0811) - Special Vehicle - Comprehensive',
            9: '(0812) - Driving School - Comprehensive',
            10: '(0890) - PTA Yellow Card - Comprehensive'
        }
    },
    {
        productClass: '(08) - Motor Commercial - TPO',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0800) - Motor Commercial - TPO',
            3: '(0801) - Tractor - TPO',
            4: '(0802) - New Trade - TPO',
            5: '(0803) - Private Hire - TPO',
            6: '(0804) - PSV - TPO',
            7: '(0807) - General Cartage - TPO',
            8: '(0811) - Special Vehicle - TPO',
            9: '(0812) - Driving School - TPO',
            10: '(0890) - PTA Yellow Card - TPO'
        }
    },
    {
        productClass: '(09) - Personal Accident',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(090) - Personal Accident',
            3: '(091) - Medical',
            4: '(092) - Group Personal Accident',
            5: '(093) - Travel'
        }
    },
    {
        productClass: '(10) - Theft',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(100) - Cash in Transit',
            3: '(101) - Fidelity Gurantee',
            4: '(102) - Burglary',
            5: '(103) - All Risk',
            6: '(104) - Bankers Blanket'
        }
    },
    {
        productClass: '(11) - Workmens Compensation',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(112) - Wiba',
            3: '(113) - Common Law',
            4: '(114) - Wiba Plus'
        }
    },
    {
        productClass: '(12) - Miscellaneous',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(120) - Immigration Bond',
            3: '(123) - PLATE GLASS',
            4: '(125) - GOLFERS',
            5: '(127) - Advance Payment Bond',
            6: '(128) - Custom Bond',
            7: '(129) - Excess Protection',
            8: '(130) - Goods In Transit'
        }
    },
    {
        productClass: '(07) - Motor Private',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0700) - Motor Private',
            3: '(0701) - Motor Cycle',
            4: '(0730) - Private Certificate Binder'
        }
    },
    {
        productClass: '(08) - Motor Commercial',
        subClass: {
            1: 'Select Product Sub Class',
            2: '(0800) - Motor Commercial',
            3: '(0801) - Tractor',
            4: '(0802) - New Trade',
            5: '(0803) - Private Hire',
            6: '(0804) - PSV',
            7: '(0807) - General Cartage',
            8: '(0811) - Special Vehicle',
            9: '(0812) - Driving School',
            10: '(0890) - PTA Yellow Card'
        }
    }
];

export default InsuranceProducts;