import STDClauses from "./STDClauses";

const STDClausesHandler = (fruit) => {   
  
    const logFruit = {
      '(020) - Contractors All Risks': () => STDClauses.ContractorsAllRisks,
      'ContractorsAllRisksDeductible': () => STDClauses.ContractorsAllRisksDeductible,

      '(021) - ContractorsPlant and Machinery': () => STDClauses.ContractorsPlantAndMachinery,
      'ContractorsPlantAndMachineryDeductible': () => STDClauses.ContractorsAllRisksDeductible,

      '(023) - Machinery Break Down': () => STDClauses.MachineryBreakDown,
      'MachineryBreakDownDeductible': () => STDClauses.MachineryBreakDownDeductible,
      
      '(024) - Computer Electronic Equipment': () => STDClauses.ComputerElectronicEquipment,
      'ComputerElectronicEquipmentDeductible': () => STDClauses.ComputerElectronicEquipmentDeductible,

      '(026) - Loss of Profit (L.O.P) Following Machinery Breakdown': () => STDClauses.LossOfProfitFollowingMachineryBreakdown,

      '(030) - Domestic Package': () => STDClauses.DomesticPackage,
      'DomesticPackageDeductible': () => STDClauses.DomesticPackageDeductible,

      '(031) - Domestic Contents': () => STDClauses.DomesticContents,
      'DomesticContentsDeductible': () => STDClauses.DomesticContentsDeductible,

      '(040) - Fire Industrial': () => STDClauses.FireIndustrial,
      'FireIndustrialDeductible': () => STDClauses.FireIndustrialDeductible,

      '(041) - Con Loss Following Fire': () => STDClauses.ConLossFollowingFire,
      'ConLossFollowingFireDeductible': () => STDClauses.ConLossFollowingFireDeductible,

      '(043) - Terrorism': () => STDClauses.Terrorism,
      'TerrorismDeductible': () => STDClauses.TerrorismDeductible,

      '(051) - Professional Liability': () => STDClauses.ProfessionalLiability,
      'ProfessionalLiabilityDeductible': () => STDClauses.ProfessionalLiabilityDeductible,
      
      '(052) - Public Liabilty': () => STDClauses.PublicLiabilty,

      '(054) - Contractual Liabilty': () => STDClauses.ContractualLiabilty,
      'ContractualLiabiltyDeductible': () => STDClauses.ContractualLiabiltyDeductible,

      '(061) - Marine Certificate': () => STDClauses.MarineCertificate,

      '(062) - Goods in Transit (GIT)': () => STDClauses.GoodsInTransit,
      'GoodsInTransitDeductible': () => STDClauses.GoodsInTransitDeductible,

      '(064) - CARRIERSLEGALLIABILITY': () => STDClauses.CARRIERSLEGALLIABILITY,
      'CARRIERSLEGALLIABILITYDeductible': () => STDClauses.CARRIERSLEGALLIABILITYDeductible,

      '(090) - Personal Accident': () => STDClauses.PersonalAccident,

      '(092) - Group Personal Accident': () => STDClauses.GroupPersonalAccident,

      '(0700) - Motor Private - Comprehensive': () => STDClauses.MotorPrivateComprehensive,
      '(0700) - Motor Private - TPO': () => STDClauses.MotorPrivateTPO,

      '(0701) - Motor Cycle - Comprehensive': () => STDClauses.MotorCycleComprehensive,
      '(0701) - Motor Cycle - TPO': () => STDClauses.MotorCycleTPO,

      '(0800) - Motor Commercial - Comprehensive': () => STDClauses.MotorCommercialComprehensive,
      '(0800) - Motor Commercial - TPO': () => STDClauses.MotorCommercialTPO,

      '(0801) - Tractor - TPO': () => STDClauses.TractorTPO,

      '(0802) - New Trade - Comprehensive': () => STDClauses.NewTradeComprehensive,

      '(0804) - PSV - Comprehensive': () => STDClauses.PSVComprehensive,

      '(0807) - General Cartage - Comprehensive': () => STDClauses.GeneralCartageComprehensive,
      '(0807) - General Cartage - TPO': () => STDClauses.GeneralCartageTPO,
      
      '(0811) - Special Vehicle - Comprehensive': () => STDClauses.SpecialVehicleComprehensive,
      '(0811) - Special Vehicle - TPO': () => STDClauses.SpecialVehicleTPO,

      '(100) - Cash in Transit': () => STDClauses.CashInTransit,
      'CashInTransitDeductible': () => STDClauses.CashInTransitDeductible,

      '(101) - Fidelity Gurantee': () => STDClauses.FidelityGurantee,
      'FidelityGuranteeDeductible': () => STDClauses.FidelityGuranteeDeductible,

      '(102) - Burglary': () => STDClauses.Burglary,
      'BurglaryDeductible': () => STDClauses.BurglaryDeductible,

      '(103) - All Risk': () => STDClauses.AllRisk,
      'AllRiskDeductible': () => STDClauses.AllRiskDeductible,

      '(112) - Wiba': () => STDClauses.Wiba,
      'WibaDeductible': () => STDClauses.WibaDeductible,
      'WibaBenefits': () => STDClauses.WibaBenefits,

      '(113) - Common Law': () => STDClauses.CommonLaw,
      'CommonLawDeductible': () => STDClauses.CommonLawDeductible,  

      '(114) - Wiba Plus': () => STDClauses.WibaPlus,
      'WibaPlusDeductible': () => STDClauses.WibaPlusDeductible,
      'WibaPlusBenefits': () => STDClauses.WibaPlusBenefits,

      '(123) - PLATE GLASS': () => STDClauses.PLATEGLASS,
      'PLATEGLASSDeductible': () => STDClauses.PLATEGLASSDeductible,

      '(125) - GOLFERS': () => STDClauses.GOLFERS,
            
      'default': () => STDClauses.defaultValue,
    };
    
    return (logFruit[fruit] || logFruit['default'])();
    
  }
  
  export default STDClausesHandler;
  
  
  
  
  
  
  
  
  
  
  
  
  
  