import React, { useState, useEffect } from "react";
import Loader from "../../Body/Loader/Loader";
import { useRecoilValue } from "recoil";
import { viewPendingBillDoc } from "../../../atoms";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import axios from "axios";
import { activeBrokerBranchPortfolio } from "../../../atoms";
import { DatePickerInput } from '@mantine/dates';

const InvoicePayment = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    // recoil values
    const viewPendingBillDocRN = useRecoilValue(viewPendingBillDoc)
    const activeBrokerBranchPortfolioRN = useRecoilValue(activeBrokerBranchPortfolio);
 
    //################################################################
    //get user input
    const [purposeOfPayment, setPurposeOfPayment] = useState('');
    const [amount, setAmount] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [paymentID, setPaymentID] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    const [receiptNo, setReceiptNumber] = useState('');
    const [expenseReceiptDocument, setExpenseReceiptDocument] = useState([]);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [vendorAccount, setVendorAccount] = useState('')

    //################################################################
    //get active expense
    useEffect(() => {
        try {

            if (Object.keys(viewPendingBillDocRN).length > 0) {
                setPurposeOfPayment(viewPendingBillDocRN.purposeOfPayment)
                setModeOfPayment(viewPendingBillDocRN.modeOfPayment)
                setPaymentID(viewPendingBillDocRN.paymentID)
                setExpenseAccount(viewPendingBillDocRN.expenseAccount)
                setReceiptNumber(viewPendingBillDocRN.receiptNo)
                if (viewPendingBillDocRN.invoiceDate) { setInvoiceDate(new Date(viewPendingBillDocRN.invoiceDate)) }
                else { setInvoiceDate(null) }                
                setVendorAccount(viewPendingBillDocRN.vendorAccount);
            }

        } catch (error) { }
    }, [viewPendingBillDocRN]);

    //################################################################
    //step 3
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'View Pending Bill',
            previousView: '',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Expense Created.'
        }
        props.onePropDown(onePropObj);
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'View Pending Bill',
            previousView: '',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Creating Expense.'
        }
        props.onePropDown(onePropObj);
    }

    //step 2
    //post data
    const createExpenseHandler = () => {
        setIsLoading(true);

        const quoteFinalObj = {
            purposeOfPayment: purposeOfPayment.trim().toUpperCase(),
            amount: amount.trim(),
            paymentID: paymentID.trim(),
            modeOfPayment: modeOfPayment,
            expenseAccount: expenseAccount,
            receiptNo: receiptNo,
            status: "Cleared",
            createdBy: userDetails,
            expenseReceiptDocument: expenseReceiptDocument,
            branchID: activeBrokerBranchPortfolioRN,
            paymentDate: paymentDate,
            invoiceDate: invoiceDate,
            vendorAccount: vendorAccount,
            category: 'PnL',
        }

        const CreateExpenseAPI = async () => {

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createexpense', quoteFinalObj)
                .then(response => {
                    // Handle the response data
                    console.log(response.data);
                    showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                    showErrorMSGOnExpenseSave();
                });
        }
        CreateExpenseAPI()

    }

    //################################################################
    //content 
    const createVendorContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Post Payment</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={paymentID || ''} onChange={(e) => { setPaymentID(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Cheque No</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={modeOfPayment || ''} onChange={(e) => { setModeOfPayment(e.target.value) }} type='text' className='input' placeholder='' disabled/>
                <label className='placeholder'><p>Credit Account</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Amount</p></label>
            </div>
            <div className='h_5'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="Payment Date"
                    placeholder="Pick date"
                    value={paymentDate}
                    onChange={setPaymentDate}
                    mx="auto"
                    maw={400}
                />
            </div>
            <div className='h_5'></div>
            <button onClick={createExpenseHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {createVendorContent}
            {loaderContent}
        </section>
    );
}

export default InvoicePayment;