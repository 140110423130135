import React, { useState, useEffect, useCallback } from "react";
import Loader from "../Loader/Loader";
import Worker from './GeneratePayroll.Worker.js'
import { Stepper } from '@mantine/core';
import { utils, writeFile } from 'xlsx';
import { useRecoilValue } from "recoil";
import axios from "axios";
import { activeBrokerBranchPortfolio } from "../../../atoms";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import { generatePayrollMonthFilter } from "../../../atoms";
import GeneratePayrollMainData from "./GeneratePayrollMainData";
import GeneratePayrollStatutoryDeductions from "./GeneratePayrollStatutoryDeductions";

const GeneratePayroll = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const generatePayrollMonthFilterRN = useRecoilValue(generatePayrollMonthFilter);
    const activeBrokerBranchPortfolioRN = useRecoilValue(activeBrokerBranchPortfolio);

    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    const [finalNHIFStatutoryDeduction, setFinalNHIFStatutoryDeduction] = useState([]);
    const [finalNSSFStatutoryDeduction, setFinalNSSFStatutoryDeduction] = useState([]);
    const [finalPAYEStatutoryDeduction, setFinalPAYEStatutoryDeduction] = useState([]);
    const [finalHousingLevyStatutoryDeduction, setfinalHousingLevyStatutoryDeduction] = useState([]);
    const [finalNITAStatutoryDeduction, setFinalNITAStatutoryDeduction] = useState([]);

    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = { sampleData: 'sample data' }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setIsLoading(false);
                setFinalData(event.data.finalData);
                setFinalNHIFStatutoryDeduction(event.data.finalNHIFStatutoryDeduction);
                setFinalNSSFStatutoryDeduction(event.data.finalNSSFStatutoryDeduction);
                setFinalPAYEStatutoryDeduction(event.data.finalPAYEStatutoryDeduction);
                setfinalHousingLevyStatutoryDeduction(event.data.finalHousingLevyStatutoryDeduction);
                setFinalNITAStatutoryDeduction(event.data.finalNITAStatutoryDeduction)
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, []);

    //################################################################
    //create payroll
    function getMonthInWords(date) {
        try {

            const months = [
                "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
                "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
            ];

            const monthIndex = new Date(date).getMonth();
            return months[monthIndex];

        } catch (error) { }
    }

    //step 2
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Payroll',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payroll Saved.',
            activity: 'Payroll Creation',
        }
        props.onePropDown(onePropObj);
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Payrole',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Saving Payroll.'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const createPayroll = () => {
        try {

            setIsLoading(true);

            const paymentID = new Date(generatePayrollMonthFilterRN).getFullYear() + '-' + (new Date(generatePayrollMonthFilterRN).getMonth() + 1)

            const narration = getMonthInWords(generatePayrollMonthFilterRN) + ' ' + new Date(generatePayrollMonthFilterRN).getFullYear();

            const patmentDate = new Date(new Date(generatePayrollMonthFilterRN).getFullYear(), new Date(generatePayrollMonthFilterRN).getMonth() + 1, 0);
            const paymentDateISO = patmentDate.toISOString();

            //payee
            const finalPayrollExpense = finalData.map((doc) => {
                return ({
                    purposeOfPayment: 'SALARY FOR ' + doc.name + ' FOR THE MONTH OF ' + narration,
                    amount: doc.netPayAmount,
                    paymentID: paymentID,
                    modeOfPayment: 'CIB Kenya - 040XXX0081',
                    expenseAccount: 'Salaries, Benefits and Wages',
                    receiptNo: '',
                    status: "Cleared",
                    createdBy: userDetails,
                    expenseReceiptDocument: {},
                    branchID: activeBrokerBranchPortfolioRN,
                    paymentDate: paymentDateISO,
                    invoiceDate: '',
                    vendorID: '',
                    category: 'Expense',
                    dateCreated: new Date(),
                    allocationDetails: '',
                    payrollRawData: doc,
                    associateID: doc.employeeID,
                })
            });

            //nhif
            let finalNHIFStatutoryDeductionAmount = 0;
            if (finalNHIFStatutoryDeduction.length > 0) {
                const obj = finalNHIFStatutoryDeduction.find(doc => doc.name === 'NHIF Total');
                if (Object.keys(obj).length > 0) { finalNHIFStatutoryDeductionAmount = obj.amount }
            }

            const finalNHIFStatutoryDeductioneExpense = [{
                purposeOfPayment: 'NHIF STATUTORY DEDUCTION FOR THE MONTH OF ' + narration,
                amount: finalNHIFStatutoryDeductionAmount,
                paymentID: paymentID,
                modeOfPayment: 'CIB Kenya - 040XXX0081',
                expenseAccount: 'Statutory Deductions',
                receiptNo: '',
                status: "Cleared",
                createdBy: userDetails,
                expenseReceiptDocument: {},
                branchID: activeBrokerBranchPortfolioRN,
                paymentDate: paymentDateISO,
                invoiceDate: '',
                vendorID: '',
                category: 'Expense',
                dateCreated: new Date(),
                allocationDetails: '',
                payrollRawData: finalNHIFStatutoryDeduction,
                associateID: 'NHIFStatutoryDeduction',
            }];

            //nssf
            let finalNSSFStatutoryDeductionAmount = 0;
            if (finalNSSFStatutoryDeduction.length > 0) {
                const obj = finalNSSFStatutoryDeduction.find(doc => doc.name === 'NSSF Total');
                if (Object.keys(obj).length > 0) { finalNSSFStatutoryDeductionAmount = obj.amount }
            }

            const finalNSSFStatutoryDeductioneExpense = [{
                purposeOfPayment: 'NSSF STATUTORY DEDUCTION FOR THE MONTH OF ' + narration,
                amount: finalNSSFStatutoryDeductionAmount,
                paymentID: paymentID,
                modeOfPayment: 'CIB Kenya - 040XXX0081',
                expenseAccount: 'Statutory Deductions',
                receiptNo: '',
                status: "Cleared",
                createdBy: userDetails,
                expenseReceiptDocument: {},
                branchID: activeBrokerBranchPortfolioRN,
                paymentDate: paymentDateISO,
                invoiceDate: '',
                vendorID: '',
                category: 'Expense',
                dateCreated: new Date(),
                allocationDetails: '',
                payrollRawData: finalNSSFStatutoryDeduction,
                associateID: 'NSSFStatutoryDeduction',
            }];

            //housing levy
            let finalHOUSINGlEVYStatutoryDeductionAmount = 0;
            if (finalHousingLevyStatutoryDeduction.length > 0) {
                const obj = finalHousingLevyStatutoryDeduction.find(doc => doc.name === 'HOUSING LEVY Total');
                if (Object.keys(obj).length > 0) { finalHOUSINGlEVYStatutoryDeductionAmount = obj.amount }
            }

            const finalHOUSINGLEVYStatutoryDeductioneExpense = [{
                purposeOfPayment: 'HOUSING LEVY STATUTORY DEDUCTION FOR THE MONTH OF ' + narration,
                amount: finalHOUSINGlEVYStatutoryDeductionAmount,
                paymentID: paymentID,
                modeOfPayment: 'CIB Kenya - 040XXX0081',
                expenseAccount: 'Statutory Deductions',
                receiptNo: '',
                status: "Cleared",
                createdBy: userDetails,
                expenseReceiptDocument: {},
                branchID: activeBrokerBranchPortfolioRN,
                paymentDate: paymentDateISO,
                invoiceDate: '',
                vendorID: '',
                category: 'Expense',
                dateCreated: new Date(),
                allocationDetails: '',
                payrollRawData: finalHousingLevyStatutoryDeduction,
                associateID: 'HOUSINGLEVYStatutoryDeduction',
            }];

            //NITA
            let finalNITAStatutoryDeductionAmount = 0;
            if (finalNITAStatutoryDeduction.length > 0) {
                const obj = finalNITAStatutoryDeduction.find(doc => doc.name === 'NITA Total');
                if (Object.keys(obj).length > 0) { finalNITAStatutoryDeductionAmount = obj.amount }
            }

            const finalNITAStatutoryDeductioneExpense = [{
                purposeOfPayment: 'NITA STATUTORY DEDUCTION FOR THE MONTH OF ' + narration,
                amount: finalNITAStatutoryDeductionAmount,
                paymentID: paymentID,
                modeOfPayment: 'CIB Kenya - 040XXX0081',
                expenseAccount: 'Statutory Deductions',
                receiptNo: '',
                status: "Cleared",
                createdBy: userDetails,
                expenseReceiptDocument: {},
                branchID: activeBrokerBranchPortfolioRN,
                paymentDate: paymentDateISO,
                invoiceDate: '',
                vendorID: '',
                category: 'Expense',
                dateCreated: new Date(),
                allocationDetails: '',
                payrollRawData: finalNITAStatutoryDeduction,
                associateID: 'NITAStatutoryDeduction',
            }];


            //paye
            let finalPAYEStatutoryDeductionAmount = 0;
            if (finalPAYEStatutoryDeduction.length > 0) {
                const obj = finalPAYEStatutoryDeduction.find(doc => doc.name === 'PAYE Total');
                if (Object.keys(obj).length > 0) { finalPAYEStatutoryDeductionAmount = obj.amount }
            }

            const finalPAYEStatutoryDeductioneExpense = [{
                purposeOfPayment: 'PAYE STATUTORY DEDUCTION FOR THE MONTH OF ' + narration,
                amount: finalPAYEStatutoryDeductionAmount,
                paymentID: paymentID,
                modeOfPayment: 'CIB Kenya - 040XXX0081',
                expenseAccount: 'Statutory Deductions',
                receiptNo: '',
                status: "Cleared",
                createdBy: userDetails,
                expenseReceiptDocument: {},
                branchID: activeBrokerBranchPortfolioRN,
                paymentDate: paymentDateISO,
                invoiceDate: '',
                vendorID: '',
                category: 'Expense',
                dateCreated: new Date(),
                allocationDetails: '',
                payrollRawData: finalPAYEStatutoryDeduction,
                associateID: 'PAYEStatutoryDeduction',
            }];

            const finalFinal = [
                ...finalPayrollExpense,
                ...finalNHIFStatutoryDeductioneExpense,
                ...finalNSSFStatutoryDeductioneExpense,
                ...finalPAYEStatutoryDeductioneExpense,
                ...finalHOUSINGLEVYStatutoryDeductioneExpense,
                ...finalNITAStatutoryDeductioneExpense,
            ];

            (async () => {
                axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createbulkpayrolldata', finalFinal)
                    .then(response => {
                        console.log(response)
                        //console.log(response)
                        // Handle the response data
                        //console.log(response.data);
                        showSuccessMSGOnExpenseSave();
                    })
                    .catch(error => {
                        console.log(error)
                        // Handle any errors
                        //console.log(error);
                        showErrorMSGOnExpenseSave();
                    });
            })()

        } catch (error) { console.log(error) }


    }

    //################################################################
    //combined data
    const [combinedData, setCombinedData] = useState([]);
    useEffect(() => {
        try {

            const a = [...finalNHIFStatutoryDeduction, ...finalNSSFStatutoryDeduction, ...finalPAYEStatutoryDeduction, ...finalHousingLevyStatutoryDeduction, ...finalNITAStatutoryDeduction];
            setCombinedData(a);

        } catch (error) {console.log(error) }
    }, [finalNHIFStatutoryDeduction, finalNSSFStatutoryDeduction, finalPAYEStatutoryDeduction, finalHousingLevyStatutoryDeduction, finalNITAStatutoryDeduction])

    //################################################################
    //steper  
    // Allow the user to freely go back and forth between visited steps.
    const [active, setActive] = useState(0);
    const shouldAllowSelectStep = (step) => {
        const back = active - step;
        const forward = step - active;
        return back == 1 || forward == 1 && active !== step
    }

    //################################################################
    //content
    const content = <section>
        <div className="h_8"></div>
        <div>
            <Stepper active={active} onStepClick={setActive} breakpoint="xs">
                <Stepper.Step
                    label="First Step"
                    description="Additions / Deductions"
                    allowStepSelect={shouldAllowSelectStep(0)}
                >


                </Stepper.Step>
                <Stepper.Step
                    label="Second Step"
                    description="Payrole"
                    allowStepSelect={shouldAllowSelectStep(1)}
                >
                    <GeneratePayrollMainData finalData={finalData} />

                </Stepper.Step>
                <Stepper.Step
                    label="Third Step"
                    description="Statutory"
                    allowStepSelect={shouldAllowSelectStep(2)}
                >
                    <GeneratePayrollStatutoryDeductions finalData={combinedData} />

                </Stepper.Step>

                <Stepper.Completed>
                    <p>Completed, click back button to get to previous step</p>
                </Stepper.Completed>
            </Stepper>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Generate Payrole</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded' onClick={() => { createPayroll() }}>Save Payroll</p>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default GeneratePayroll;