import React, { useEffect, useState } from 'react';
import db, { auth } from '../../firebase';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import Loader from "../Body/Loader/Loader";
import SetCookie from '../Hooks/Cookie/SetCookie';
import './SignUp.css';
import ActivityLogger from '../Hooks/ActivityLog/ActvitityLogger';
import GetUserDeviceDetails from '../Hooks/ActivityLog/GetUserDeviceDetails';
import GetCookie from '../Hooks/Cookie/GetCookie';

const SignIn = (props) => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //process date
    const todaysDate = new Date();
    const year = new Date(todaysDate).getFullYear();
    const month = new Date(todaysDate).getMonth() + 1;
    const date = new Date(todaysDate).getDate();
    const todaysDateCleaned = year + '-' + month + '-' + date;

    //echo date
    const dayEcho = new Date(todaysDate).toLocaleString('en-us', { day: '2-digit' });
    const monthEcho = new Date(todaysDate).toLocaleString('en-us', { month: 'short' });
    const yearEcho = new Date(todaysDate).getFullYear();
    const echoDate = dayEcho + '-' + monthEcho + '-' + yearEcho;

    //get user input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //device details
    useEffect(() => {
        (async () => {
            const details = await GetUserDeviceDetails();
            SetCookie('userDeviceDetails', JSON.stringify(details))
        })()
    }, []);



    //auth user
    async function signInSubmitHandler(event) {
        event.preventDefault();
        setIsloading(true);

        //auth user
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const userDetails = {
                    userUID: user.uid,
                    userEmail: user.email,
                    userEmailVerified: user.emailVerified,
                    userPhoneNumber: user.phoneNumber,
                    userDisplayName: user.displayName,
                    userPhotoURL: user.photoURL,
                }
                SetCookie('CurrentUserSession', JSON.stringify(userDetails));

                // const userDeviceDetails = GetCookie('userDeviceDetails');

                // ActivityLogger({
                //     activity: 'Sign In',
                //     date: new Date(),
                //     user: userDetails,
                //     affectedDoc: '',
                //     deviceDetails: JSON.parse(userDeviceDetails),
                // });

                props.instructionsDown('Dashboard');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log(errorMessage)
            });
        setIsloading(false)
    }

    const redirectHandler = () => { props.instructionsDown('Sign Up') }

    return (
        <section>
            <div className='sign_up_hero'>
                <div className='sign_up_sub'></div>
                <div className='sign_up_sub'>
                    <div className='sign_in_empty'></div>
                    <div className='sign_in_content'>
                        <p className='sign_up_header bold'>Account Sign In.</p>
                        <form>
                            <div className='h_20'></div>
                            <div className='log_in_input_container'>
                                <input onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                            </div>
                            <div className='h_10'></div>
                            <div className='log_in_input_container'>
                                <input onChange={(e) => setPassword(e.target.value)} placeholder='password' type='password' />
                            </div>
                            <div className='h_15'></div>
                            <span className='btn_pink sign_up_button bold' onClick={signInSubmitHandler}>SIGN IN</span>
                            <div className='h_10'></div>
                            <div className='redirectController' onClick={redirectHandler}>
                                <p>Don't Have An Account? Sign Up.</p>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='sign_up_sub'></div>
            </div>
            {loaderContent}
        </section>
    )
}

export default SignIn;