import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { receiptListFilterFromDate, receiptListFilterToDate, receiptListTypeFilter } from "../../../../atoms";
import { DatePickerInput } from "@mantine/dates";

const ReceiptListGridFilter = () => {
    //################################################################
    //valuation reports filter
    const [receiptListFilterFromDateRN, setReceiptListFilterFromDateRN] = useRecoilState(receiptListFilterFromDate)
    const [receiptListFilterToDateRN, setReceiptListFilterToDateRN] = useRecoilState(receiptListFilterToDate)
    const [receiptListTypeFilterRN, setReceiptListTypeFilterRN] = useRecoilState(receiptListTypeFilter)

    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_15'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setReceiptListTypeFilterRN(e.target.value) }} value={receiptListTypeFilterRN}>
                    <option>Select Filter</option>
                    <option>All</option>
                    <option>Receipt Document Present</option>
                    <option>Receipt Document Missing</option>
                </select>
                <label className='placeholder'><p>Report Filter</p></label>
            </div>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="From Date"
                    placeholder="From Date"
                    value={receiptListFilterFromDateRN}
                    onChange={setReceiptListFilterFromDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="To Date"
                    placeholder="To Date"
                    value={receiptListFilterToDateRN}
                    onChange={setReceiptListFilterToDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
        </section>
    )
}

export default ReceiptListGridFilter;