import { useRecoilState } from "recoil";
import { stickerCopyReportTypeFilter, stickerCopyReportFilterFromDate, stickerCopyReportFilterToDate } from "../../../atoms";
import DatePicker from "react-date-picker";

const StickerCopyReportFilter = () => {
    //################################################################
    //get recoil values
    const [stickerCopyReportTypeFilterRN,setStickerCopyReportTypeFilterRN] = useRecoilState(stickerCopyReportTypeFilter);
    const [stickerCopyReportFilterFromDateRN,setStickerCopyReportFilterFromDateRN] = useRecoilState(stickerCopyReportFilterFromDate);
    const [stickerCopyReportFilterToDateRN,setStickerCopyReportFilterToDateRN] = useRecoilState(stickerCopyReportFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { setStickerCopyReportTypeFilterRN(e.target.value) }}>
                        <option>Select Filter</option>
                        <option>All</option>
                        <option>Motor Certificate Present</option>
                        <option>Motor Certificate Missing</option>
                    </select>
                    <label className='placeholder'><p>Report Filter</p></label>
                </div>
                <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setStickerCopyReportFilterFromDateRN} value={stickerCopyReportFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setStickerCopyReportFilterToDateRN} value={stickerCopyReportFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
        </section>
    )
}

export default StickerCopyReportFilter;