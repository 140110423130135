import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { doc, deleteDoc } from 'firebase/firestore';
import GetADocument from '../../Hooks/BackendConn/InsurerSpecific/GetADocument';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import Loader from '../Loader/Loader';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rightNavPolicyItem, pageReloaderHandler, activeInsurerPortfolio } from '../../../atoms';
import { rightNavContent } from '../../../atoms';
import OpenInNewTab from '../../Hooks/UI/OpenInNewTab';
import GetClientDetails from '../../Hooks/BackendConn/InsurerSpecific/GetClientDetails';
import logo from '../../Assets/img/logo.png';
import { useReactToPrint } from 'react-to-print';
import './ViewReceipt.css';
import './ViewReceiptPrint.css';
import GetClientData from '../../Hooks/BackendConn/ClientList/GetClientData';

const ViewReceipt = (props) => {
    //################################################################
    //recoil
    const [rightNavPolicyItemRN, setRightNavPolicyItemRN] = useRecoilState(rightNavPolicyItem);
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get receipt data
    const [document, setDocument] = useState({});
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetADocument(props.onePropUp.postedDocRef);
            setDocument(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, pageReloaderHandlerRN]);

    //################################################################
    //send allocated docs to session storage
    const [reloadTwo, setReloadTwo] = useState(0)
    const allocatedDocsHandler = (val) => {
        const items = JSON.parse(sessionStorage.getItem('ViewReceiptAllocatedDocs'));
        items.push(val);
        sessionStorage.setItem('ViewReceiptAllocatedDocs', JSON.stringify(items));
        setReloadTwo((Math.random(0, 10000)))
    }

    //################################################################
    //get allocated docs
    useEffect(() => {
        try {

            sessionStorage.setItem('ViewReceiptAllocatedDocs', JSON.stringify([]));
            if (Object.entries(document).length > 0) {

                document.docData.receiptAllocationDetails.map((doc) => {
                    (async () => {
                        setIsLoading(true)
                        const a = await GetADocument(doc.receivedDocID);
                        allocatedDocsHandler({ docID: a.docID, docData: a.docData, receiptDetails: doc })
                        setIsLoading(false)
                    })()

                });

            }

        } catch (error) { }
    }, [document])

    //################################################################
    //get all client data
    const [clientDataDocs, setClientDataDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetClientData();
            setClientDataDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree]);

    //################################################################
    //filter insurer
    const [insurerFilteredClientList, setInsurerFilteredClientList] = useState([]);
    useEffect(() => {
        const list = clientDataDocs.filter(doc => doc.docData.insurerID === activeInsurerPortfolioRN);
        setInsurerFilteredClientList(list)
    }, [clientDataDocs, activeInsurerPortfolioRN])

    //################################################################
    //rerender site
    //calc totals
    //get client details
    const [allocatedTotals, setAllocatedTotals] = useState(0)
    const [clientDetails, setClientDetails] = useState({})

    useEffect(() => {
        //calc totals
        try {

            if (Object.entries(document).length > 0) {
                const totals = []

                document.docData.receiptAllocationDetails.map((doc) => {
                    totals.push(doc.receivedAllocatedAmount)
                })

                const a = totals.reduce((total, item) => { return total + +item }, 0);
                setAllocatedTotals(a)
            }

            //set right clicked policy - recoil state to be used for doc upload
            if (Object.entries(document).length > 0) {
                setRightNavPolicyItemRN(document)
            }

            //get client details
            if (Object.entries(document).length > 0) {
                if (document.docData.paidTo === 'SIMIA INSURANCE AGENCY LIMITED') {
                    // async function a() {
                    //     try {
                    //         setIsLoading(true)
                    //         const a = await GetClientDetails(document.docData.clientName[0])
                    //         setClientDetails(a)
                    //         setIsLoading(false)
                    //     } catch (error) {

                    //     }
                    // }
                    // a()

                    const sd = insurerFilteredClientList.filter(doc => doc.docData.clientName.toLowerCase() === document.docData.clientName[0].toLowerCase());
                    if (sd.length > 0) {
                        setClientDetails(sd[0])
                    }

                }
            }

        } catch (error) { }

    }, [document, insurerFilteredClientList])

    //################################################################
    //reload on success upload
    useEffect(() => {
        if (pageReloaderHandlerRN.notification === 'Receipt Document Uploaded Successfully') {
            setReload(Math.random(10001, 20000))
            setPageReloaderHandlerRN({})
        }
        else if (pageReloaderHandlerRN.notification === 'Receipt Remited To Insurer') {
            setReload(Math.random(10001, 20000))
            setPageReloaderHandlerRN({})
        }
    }, [pageReloaderHandlerRN])

    //################################################################
    //delete receipt
    //step 2
    const onSuccessReceiptDeleteHandler = () => { }

    //step 1
    async function deleteReceiptHandler() {
        const a = await deleteDoc(doc(db, "PolicyDocuments", rightNavPolicyItemRN.docID));
        onSuccessReceiptDeleteHandler(a)
    }

    //################################################################
    //remit to insurer
    //step 1
    const remitHandler = () => {
        setRightNavContentRN('Remit Receipt To Insurer')
    }

    //################################################################
    //upload doc
    const uploadReceiptDocumentHandler = () => {
        setRightNavContentRN('View Receipt')
    }

    //################################################################
    //receipt document
    let receiptDocumentContent = '';
    if (Object.entries(document).length > 0) {
        if (document.docData.receiptDocument) {
            if (Object.entries(document.docData.receiptDocument).length > 0) {
                receiptDocumentContent = <section>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Receipt Document</p></div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <table className='view_receipt_receipt_document'>
                                    <thead>
                                        <tr>
                                            <td><p>No</p></td>
                                            <td><p>Document Download Link</p></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.values(document.docData.receiptDocument).map((doc, index) => {
                                                return (
                                                    <tr key={index} onClick={() => OpenInNewTab(doc.receiptDocumentDownloadLink)}>
                                                        <td><p>{index + 1}</p></td>
                                                        <td><p>Receipt Document</p></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            }
        }

    }

    //################################################################
    //simia receipt
    let simiaReceiptContent = <section style={{ display: "none" }}>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three' ref={componentRef}>
                    <div className='simia_receipt_template'>
                        <div className='simia_receipt_template_logo watermark'>
                            <img alt='logo' src={logo} />
                        </div>
                        <div className='simia_receipt_template_content'>
                            {/* section 1 */}
                            <div className='simia_receipt_template_table_section_one'>
                                <p className='bold f_10'>E-RECEIPT</p>
                            </div>
                            {/* section 2 */}
                            <table className='simia_receipt_template_table_section_two'>
                                <thead>
                                    <tr>
                                        <td>
                                            <p className='bold f_8'>Billed By</p>
                                            <div className='h_5'></div>
                                            <p>SIMIA INSURANCE AGENCY LIMITED</p>
                                            <div className='h_5'></div>
                                            <p>P.O BOX 623 – 30100 ELDORET </p>
                                            <div className='h_5'></div>
                                            <p>TEL: +254 722 968 637 </p>
                                            <div className='h_5'></div>
                                            <p>EMAIL: info@simiainsurance.com</p>
                                        </td>
                                        <td>
                                            <p className='bold f_8'>Billed To</p>
                                            <div className='h_5'></div>
                                            <p>{Object.keys(clientDetails).length > 0 ? clientDetails.docData.clientName : ''}</p>
                                            <div className='h_5'></div>
                                            <p>{Object.keys(clientDetails).length > 0 ? clientDetails.docData.KRAPin : ''}</p>
                                            <div className='h_5'></div>
                                            <p>{Object.keys(clientDetails).length > 0 ? clientDetails.docData.address : ''}</p>
                                        </td>
                                        <td>
                                            <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Receipt No:</p>
                                                <p>{Object.entries(document).length > 0 ? document.docData.receiptNumber : ''}</p>
                                            </div>
                                            <div className='h_5'></div>
                                            <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Receipt Date:</p>
                                                <p>{Object.entries(document).length > 0 ? DateFormat(document.docData.receiptDate) : ''}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                            {/* section 3 */}
                            <div className='h_15'></div>
                            <table className='simia_receipt_template_table_section_three'>
                                <thead>
                                    <tr>
                                        <td><p>Policy Number</p></td>
                                        <td><p>Endorsment Number</p></td>
                                        <td><p>Policy Class</p></td>
                                        <td><p>Inception Date</p></td>
                                        <td><p>Expiry Date</p></td>
                                        <td><p>Policy Net</p></td>
                                        <td><p>Allocated Amount</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sessionStorage.getItem('ViewReceiptAllocatedDocs')
                                        && JSON.parse(sessionStorage.getItem('ViewReceiptAllocatedDocs')).map((doc, index) => {
                                            try {

                                                return (
                                                    <tr key={index}>
                                                        <td><p>{doc.docData.policyNumber}</p></td>
                                                        <td><p>{doc.docData.endorsmentNumber}</p></td>
                                                        <td><p>{doc.docData.selectedProductSubClass}</p></td>
                                                        <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                                        <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                                        <td><p>{NumberFormat(doc.docData.policyNet)}</p></td>
                                                        <td><p>{NumberFormat(doc.receiptDetails.receivedAllocatedAmount)}</p></td>
                                                    </tr>
                                                )

                                            } catch (error) { }
                                        })
                                    }
                                </tbody>
                            </table>
                            {/* section 4 */}
                            <div className='h_15'></div>
                            <table className='simia_receipt_template_table_section_four'>
                                <thead>
                                    <tr>
                                        <td><p className='bold f_7'>Sub Total</p></td>
                                        <td><p className='bold f_7'>{NumberFormat(allocatedTotals)}</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold f_7'>On Account</p></td>
                                        <td><p className='bold f_7'>
                                            {
                                                Object.entries(document).length > 0
                                                    ? NumberFormat(+document.docData.receiptAmount - +allocatedTotals)
                                                    : ''
                                            }
                                        </p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold f_7'>Total</p></td>
                                        <td><p className='bold f_7'>
                                            {
                                                Object.entries(document).length > 0
                                                    ? NumberFormat(document.docData.receiptAmount)
                                                    : ''
                                            }
                                        </p></td>
                                    </tr>
                                </thead>
                            </table>
                            <div className='footerr'>
                                <center><p>SIMIA INSURANCE AGENCY | P.O BOX 623 – 30100, ELDORET | TEL: +254722968637 | EMAIL: info@simiainsurance.com</p></center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo
    const viewReceiptContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'><p>Receipt Details</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='view_receipt_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Allocated Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sessionStorage.getItem('ViewReceiptAllocatedDocs')
                                && JSON.parse(sessionStorage.getItem('ViewReceiptAllocatedDocs')).map((doc, index) => {
                                    try {

                                        return (
                                            <tr key={index}>
                                                <td><p>{index + 1}</p></td>
                                                <td><p>{doc.docData.clientName}</p></td>
                                                <td><p>{doc.docData.policyNumber}</p></td>
                                                <td><p>{doc.docData.endorsmentNumber}</p></td>
                                                <td><p>{doc.docData.selectedProductSubClass}</p></td>
                                                <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                                <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                                <td><p>{NumberFormat(doc.docData.policyNet)}</p></td>
                                                <td><p>{NumberFormat(doc.receiptDetails.receivedAllocatedAmount)}</p></td>
                                            </tr>
                                        )

                                    } catch (error) { }

                                })
                            }
                            <tr>
                                <td colSpan='5'><p></p></td>
                                <td colSpan='3'><p className='bold'>Receipt Amount</p></td>
                                <td><p className='bold'>
                                    {
                                        Object.entries(document).length > 0
                                            ? NumberFormat(document.docData.receiptAmount)
                                            : ''
                                    }
                                </p></td>
                            </tr>
                            <tr>
                                <td colSpan='5'><p></p></td>
                                <td colSpan='3'><p className='bold'>Total Amount Allocated</p></td>
                                <td><p className='bold'>{NumberFormat(allocatedTotals)}</p></td>
                            </tr>
                            <tr>
                                <td colSpan='5'><p></p></td>
                                <td colSpan='3'><p className='bold'>Amount On Account</p></td>
                                <td><p className='bold'>
                                    {
                                        Object.entries(document).length > 0
                                            ? NumberFormat(+document.docData.receiptAmount - +allocatedTotals)
                                            : ''
                                    }
                                </p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //#########################################################################
    //receipt view controls
    const [hideShowControls, setHideShowControls] = useState('control_list')
    const hideShowControlsClickHandler = (instruction) => {
        setHideShowControls(instruction)
    }

    return (
        <section className='view_receipt body_input_styles'>
            <div className='body_section_header'>
                <p>View Receipt</p>
                <div className='view_policy_controls'>
                    <span onMouseOver={() => hideShowControlsClickHandler('newlist shadow')} className='btn_pink toogle'>Controls</span>
                    <div className={hideShowControls} onMouseLeave={() => hideShowControlsClickHandler('control_list')}>
                        {
                            Object.entries(document).length > 0 &&
                            document.docData.paidTo === 'SIMIA INSURANCE AGENCY LIMITED' &&
                            <div className="controls_items" onClick={handlePrint}>
                                <p>Print Receipt</p>
                            </div>

                        }
                        {
                            Object.entries(document).length > 0 &&
                            document.docData.paidTo === 'SIMIA INSURANCE AGENCY LIMITED' &&
                            <div className="controls_items" onClick={remitHandler}>
                                <p>Remit To Insurer</p>
                            </div>

                        }
                        {
                            Object.entries(document).length > 0 &&
                            document.docData.paidTo != 'SIMIA INSURANCE AGENCY LIMITED' &&
                            <div className="controls_items" onClick={uploadReceiptDocumentHandler}>
                                <p>Upload Receipt Document</p>
                            </div>

                        }

                    </div>
                </div>
            </div>
            <div className='body_section'>
                {viewReceiptContent}
                {receiptDocumentContent}
                {simiaReceiptContent}
            </div>
            {loaderContent}
        </section>
    );
}

export default ViewReceipt;