import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import GetAllQuotes from "../../Hooks/BackendConn/Quotes/GetAllQuotes";
import { useRecoilValue } from "recoil";
import { quoteListFilterFromDate,quoteListFilterToDate } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const Quotes = () => {

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const quoteListFilterFromDateRN = useRecoilValue(quoteListFilterFromDate);
    const quoteListFilterToDateRN = useRecoilValue(quoteListFilterToDate);

    //################################################################
    //get all action memos
    const [quotationDocs, setQuotationDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllQuotes();
            setQuotationDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);
    
    //################################################################
    //sort open claims
    const [dateSortedQuotationDocs, setDateSortedQuotationDocs] = useState([])
    useEffect(() => {
        try {

            const sortedList = quotationDocs.sort(byDate);
            function byDate(a, b) {
                return new Date(b.docData.dateCreated.seconds * 1000).valueOf() - new Date(a.docData.dateCreated.seconds * 1000).valueOf();
            }
            setDateSortedQuotationDocs(sortedList);

        } catch (error) { }
    }, [quotationDocs]);

    //################################################################
    //filter open claims
    const [dateFilteredQuotationDocs, setDateFilteredQuotationDocs] = useState([])
    useEffect(() => {
        try {

            const filteredList = [];
            dateSortedQuotationDocs.map((doc) => {
                //clean date
                const formattedDateCreated = new Date(doc.docData.dateCreated.seconds * 1000);
                formattedDateCreated.setHours(0, 0, 0);
                //filter period choosen
                if (new Date(formattedDateCreated).valueOf() >= new Date(quoteListFilterFromDateRN).valueOf() && new Date(formattedDateCreated).valueOf() <= new Date(quoteListFilterToDateRN).valueOf()) {
                    filteredList.push(doc);
                }
            });
            setDateFilteredQuotationDocs(filteredList);

        } catch (error) { }
    }, [dateSortedQuotationDocs, quoteListFilterFromDateRN, quoteListFilterToDateRN]);

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateFilteredQuotationDocs.map((doc) => {
                finalObj.push({
                    docID: doc.docID,
                    title:doc.docData.title,
                    insured: doc.docData.insured,
                    insurer: doc.docData.insurer,
                    dateCreated: DateFormat(doc.docData.dateCreated),
                });
            });
            setFinalData(finalObj);

        } catch (error) { }
    }, [dateFilteredQuotationDocs]);

     //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    
    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'insured', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left',  },
        { field: 'title', headerName: 'Title', width: 150, },
        { field: 'insurer', headerName: 'Insurer', width: 150, },
        { field: 'dateCreated', headerName: 'Date', width: 150, sortable: false, filter: false, width: 100, },
        
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Quotes List</p>
                {/* <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div> */}
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default Quotes;