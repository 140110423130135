import React, { useEffect, useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import { useRecoilState } from "recoil";
import { PendingListUpdateDispatchDateInput, PendingListUpdateDispatchDateInputHandler } from "../../../../atoms";

const PendingListGridDispatchDateUpdate = () => {

    const [pendingListUpdateDispatchDateInputRN, setPendingListUpdateDispatchDateInputRN] = useRecoilState(PendingListUpdateDispatchDateInput);
    const [pendingListUpdateDispatchDateInputHandlerRN, setPendingListUpdateDispatchDateInputHandlerRN] = useRecoilState(PendingListUpdateDispatchDateInputHandler)
    const [dispatchDateError, setDispatchDateError] = useState(false)

    useEffect(() => {
        if (pendingListUpdateDispatchDateInputRN) {
            setDispatchDateError(false);
        }
    }, [pendingListUpdateDispatchDateInputRN])

    const pendingListHandler = () => {
        if (!pendingListUpdateDispatchDateInputRN) {
            setDispatchDateError(true);
            return false;
        }
        setPendingListUpdateDispatchDateInputHandlerRN(true)
    }

    //################################################################
    // select insurer Content
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Dispatch Date Update</p>
            <div className='h_5'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="Pick Date"
                    placeholder="Pick Date"
                    value={pendingListUpdateDispatchDateInputRN}
                    onChange={setPendingListUpdateDispatchDateInputRN}
                    mx="auto"
                    maw={400}
                    error={dispatchDateError && <p>Date Required</p>}
                    required={true}
                />
            </div>
            <div className='h_5'></div>
            <button onClick={pendingListHandler} className='btn_pink'>Update</button>
        </div>
    </section>

    return (
        <section className='right_nav_container'>
            {content}
        </section>
    );
}

export default PendingListGridDispatchDateUpdate;