import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType";
import GetAllDVAllocations from "../../Hooks/BackendConn/Accounts/GetAllDVAllocations";
import { useRecoilValue } from "recoil";
import { paidCommissionInsurerFilter } from "../../../atoms";

const PaidCommission = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

     //################################################################
    // recoil values
    const paidCommissionInsurerFilterRN = useRecoilValue(paidCommissionInsurerFilter);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: paidCommissionInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, paidCommissionInsurerFilterRN]);

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: paidCommissionInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 1000)) }
        })();
    }, [reloadThree, paidCommissionInsurerFilterRN]);

    //################################################################
    //get past allocated dvs
    const [PastDVAllocationData, setPastDVAllocationData] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDVAllocations();
            setPastDVAllocationData(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo]);

    const [CombinedDVDocsDVAllocationData, setCombinedDVDocsDVAllocationData] = useState([])
    useEffect(() => {
        try {

            const combined = DVDocs.reduce((acc, obj) => {
                const match = PastDVAllocationData.find(item => item.docData.DVDocID === obj.docID);
                if (match) {
                    acc.push({ 'DVDebitDocID': obj.docID, 'DVDebitDocData': obj.docData, ...match });
                }
                return acc;
            }, []);

            setCombinedDVDocsDVAllocationData(combined)

        } catch (error) { }
    }, [DVDocs, PastDVAllocationData]);
    
    const [combinedDebitDocsDVDocsDVAllocationData, setCombinedDebitDocsDVDocsDVAllocationData] = useState([])
    useEffect(() => {
        try {

            if (Object.keys(CombinedDVDocsDVAllocationData).length > 0) {
                if (Object.keys(CombinedDVDocsDVAllocationData.docData.DVAllocatedDebitDocs).length > 0) {

                    const combined = Object.keys(CombinedDVDocsDVAllocationData.docData.DVAllocatedDebitDocs).reduce((acc, obj) => {
                        const match = debitDocs.find(item => item.docID === obj);
                        if (match) {
                            acc.push({ 'allocatedAmount': CombinedDVDocsDVAllocationData.docData.DVAllocatedDebitDocs[obj]['allocatedAmount'], ...match });
                        }
                        return acc;
                    }, []);

                    setCombinedDebitDocsDVDocsDVAllocationData(combined);

                }
            }

        } catch (error) { }
    }, [CombinedDVDocsDVAllocationData])
console.log(CombinedDVDocsDVAllocationData)
    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Paid Commission</p>
                {/* <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div> */}
            </div>
            <div className='body_section'>
                {/* {content} */}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default PaidCommission;