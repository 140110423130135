const STDDeductibles = {
    defaultValue: [{ deductibleItem: '' }],
  
    //020
    ContractorsAllRisksDeductible: [
      { deductibleItem: 'ACT OF GOD - 10% of loss minimum 1% of Sum Insured' },
      { deductibleItem: 'BURGLARY/ANY OTHER CLAIM - 10% of loss minimum 0.5% of Sums Insured' },
      { deductibleItem: 'THIRD PARTY LIABILITY - Kshs. 100,000/- eel' },
      { deductibleItem: 'CPM EXCESS - 10% of claim Amount min kshs. 100,000/-' },
      { deductibleItem: 'CAR proposal form completion' },
    ],
  
    //021
    ContractorsPlantAndMachineryDeductible: [
      { deductibleItem: '1	10% of each and every loss,minimum ksh.500,000/-' },
    ],
  
    //023
    MachineryBreakDownDeductible: [
      { deductibleItem: '10% of Claim Amount,Minimum Kshs. 50,000/-' },
    ],
  
    //024
    ComputerElectronicEquipmentDeductible: [
      { deductibleItem: 'Normal Excess Percentage of Claim Amount With Minimum 5% each and every loss minimum KSH 10,000.00' },
      { deductibleItem: 'Theft: 25% each and every loss minimum KSH 10,000.00' },
    ],
  
    //030    
    DomesticPackageDeductible: [
      { deductibleItem: 'Excess - Sec C 10% of each and every loss minimum Kshs. 10,000/-' },
      { deductibleItem: 'Peril E : 2.5% of Sum Insured subject to maximum KSH .00 per claim whichever is less in respect of any' },
      { deductibleItem: 'loss or damage directly or indirectly caused by earthquake' },
    ],
  
    //031
    DomesticContentsDeductible: [
      { deductibleItem: 'Single article limit: Contents -5% of sum insured - Unless specifically stated' },
      { deductibleItem: 'Earthquake Excess .....subject to 2% of claim amount maximum Kshs. 5,000,000/-' },
      { deductibleItem: 'Excess (Section B&C).....subject to 10% of claim amount minimum Kshs. 5,000/-' },
    ],
  
    //040
    FireIndustrialDeductible: [
      { deductibleItem: 'Political violence and terrorism deductables : 5% of claim amount, minimum 0.5% of sum insured' },
      { deductibleItem: 'Peril E 2% of Sum insured subject to maximum KSH 5.000.000.00 per location whichever is less in respect of any loss or damage directly or indirectly caused by earthquake during any one period of forty eight hours ' },
      { deductibleItem: 'Peril D,F,G,H........ ...................KSH 2,500.00' },
    ],
  
    //041
    ConLossFollowingFireDeductible: [
      { deductibleItem: 'Time Excess : 7 days' },
      { deductibleItem: 'Earthquake Excess - 2% of sum insured per location Maximum KSH 5,000,000.00' },
    ],
  
    //043
    TerrorismDeductible: [
      { deductibleItem: '10% of each and every loss minimum Kshs. 100,000/-' },
    ],
  
    //050
    TerrorismDeductible: [
      { deductibleItem: '2% of Limit of Liability any one claim' },
    ],
  
    //051
    ProfessionalLiabilityDeductible: [
      { deductibleItem: 'Excess...10% of claim amount minimum KSH 10,000.00' },
    ],

    //054
    ContractualLiabiltyDeductible: [
      { deductibleItem: 'excess 1% of limit of indemnity' },
    ],
  
    //062
    GoodsInTransitDeductible: [
      { deductibleItem: '10% of claim amount minimum 10,000/-' },
    ],
  
    //064
    CARRIERSLEGALLIABILITYDeductible: [
      { deductibleItem: '10% Each and Every loss Minimum Kshs. 100,000/-' },
    ],
  
    //100
    CashInTransitDeductible: [
      { deductibleItem: '10% of Claim Amount, Minimum 20,000.00' },
    ],
  
    //101
    FidelityGuranteeDeductible: [
      { deductibleItem: '10 % of Claim amount, minimum KSH  10,000.00' },
    ],
  
    //102
    BurglaryDeductible: [
      { deductibleItem: '10% of Claim Amount, Minimum KSH 10,000.00' },
    ],
  
    //103
    AllRiskDeductible: [
      { deductibleItem: '10% of claim amount minimum Kshs. 10,000/' },
    ],
  
    //112
    WibaDeductible: [
      { deductibleItem: 'The insured shall be responsible for the first KSH 5,000.00 of each and every claim, excluding claims for funeral expenses' },
    ],
  
    //113
    CommonLawDeductible: [
      { deductibleItem: 'The Insured Shall Be Responsible for the First KSH 25,000.00 of each and every claim' },
    ],
  
    //114
    WibaPlusDeductible: [
      { deductibleItem: 'TTD excess..................    3 Days' },
      { deductibleItem: 'The insured shall be responsible for the first KSH 5,000.00 of each and every claim, excluding claimsfor funeral expenses' },
    ],
  
    //123
    PLATEGLASSDeductible: [
      { deductibleItem: '10% of Each and Every Loss minimum KSH 10,000.00' },
    ],
  
  }
  
  export default STDDeductibles;