import React, { useState, useEffect } from "react";
import Loader from "../../Body/Loader/Loader";
import GetBusinessType from '../../Hooks/BackendConn/InsurerSpecific/GetBusinessType';
import { useRecoilValue, useRecoilState } from "recoil";
import { DVAllocationInsurerFilter, DVAllocationSelectedDV } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";

const DVAllocationSelectDV = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const DVAllocationInsurerFilterRN = useRecoilValue(DVAllocationInsurerFilter);
    const [DVAllocationSelectedDVRN, setDVAllocationSelectedDVRN] = useRecoilState(DVAllocationSelectedDV)

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVAllocationInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, DVAllocationInsurerFilterRN]);

    //################################################################
    // get user input
    const [searchTerm, setSearchTerm] = useState('');

    //################################################################
    // filter dv list
    const [filteredDVData, setFilteredDVData] = useState([]);
    useEffect(() => {
        try {
            const list = DVDocs.filter((doc) => {
                if (searchTerm == '') {
                    return (doc);
                }
                else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            });

            setFilteredDVData(list);

        } catch (error) { console.log(error) }
    }, [DVDocs, searchTerm])

    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} value={searchTerm} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search DV No.</p></label>
            </div>
            <div className='h_5'></div>
            <div>
                {
                    filteredDVData.map((doc, index) => {
                        return (
                            <div key={index} onClick={() => { setDVAllocationSelectedDVRN(doc) }} className={`px-2 py-1 mb-1 rounded cursor-pointer f_7D ${DVAllocationSelectedDVRN.docID === doc.docID ? 'border border-rose-500' : 'border'}`}>
                                <p className='text-black'>DV No</p>
                                <p>{doc.docData.policyNumber}</p>
                                <p className='text-black'>DV Amount</p>
                                <p>{NumberFormat(doc.docData.policyNet)}</p>
                                <p className='text-black'>Insurer Debit Date</p>
                                <p>{DateFormat(new Date(doc.docData.insurerDebitDate))}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>

    return (
        <section className="right_nav_container">
            {content}
            {loaderContent}
        </section>
    );
}

export default DVAllocationSelectDV;