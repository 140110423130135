import React, { useEffect, useState, useRef, useCallback } from 'react';
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import { useReactToPrint } from 'react-to-print';
import './RenewedReport.css';

const RenewedReport = () => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //right nav filter date
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2022-06-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])

    //to
    const [toDate, setToDate] = useState(new Date());

    const [tvalue, onTChange] = useState(new Date('2022-06-30'));
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //################################################################
    //set default filter to current month
    const setDefaultFilterDate = useCallback(() => {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        onFChange(firstDay);

        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        onTChange(lastDay);
    }, [])

    useEffect(() => { setDefaultFilterDate() }, [setDefaultFilterDate])

    //################################################################
    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    // search section
    const dateFilterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDebit();
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 100)) }
        })();
    }, [reload]);

    //################################################################
    //remove endor, dj, jv, cn, marine, bond, travel
    const [removedEndorsments, setRemovedEndorsments] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        debitDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && !item.docData.policyNumber.startsWith('DV')
                && !item.docData.policyNumber.startsWith('JV')
                && !item.docData.policyNumber.startsWith('CN')
                && !item.docData.policyNumber.startsWith('Rev')
                && item.docData.selectedProductSubClass !== '(060) - Marine Hull'
                && item.docData.selectedProductSubClass !== '(061) - Marine Certificate'
                && item.docData.selectedProductSubClass !== '(120) - Immigration Bond'
                && item.docData.selectedProductSubClass !== '(127) - Advance Payment Bond'
                && item.docData.selectedProductSubClass !== '(128) - Custom Bond'
                && item.docData.selectedProductSubClass !== '(093) - Travel'
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled') {
                removedEndorsmentsList.push(item);
            }
        });
        setRemovedEndorsments(removedEndorsmentsList)
    }, [debitDocs]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        removedEndorsments.map((item) => {
            //calculate next inception
            const newInceptionDate = new Date(item.docData.expiryDate);
            newInceptionDate.setDate(newInceptionDate.getDate() + 1);
            newInceptionDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(newInceptionDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(newInceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [removedEndorsments, fromDate, toDate]);

    //################################################################
    //find renewal
    const [renewedDebits, setRenewedDebits] = useState([]);
    useEffect(() => {
        const renewalsSelected = [];
        selectedPeriodFilteredDocs.map((item) => {
            removedEndorsments.map((value) => {
                if (item.docData.policyNumber === value.docData.policyNumber && item.docData.endorsmentNumber !== value.docData.endorsmentNumber && new Date(value.docData.inceptionDate).valueOf() > new Date(item.docData.inceptionDate).valueOf()) {
                    renewalsSelected.push(value)
                }
            })
        });
        setRenewedDebits(renewalsSelected)
    }, [selectedPeriodFilteredDocs])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = renewedDebits.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [renewedDebits])

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //echo
    const renewedPoliciesContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='renewed_policies_report'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.policyTransactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><i className="ri-attachment-2"></i></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //printable
    const printablerenewedPoliciesContent = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='renewed_policies_report' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Renewed Report</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {new Date(fromDate).toDateString()} - {new Date(toDate).toDateString()}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedPolicyDocs.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.policyTransactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><i className="ri-attachment-2"></i></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='renewed_policies'>
            <div className='body_section_header'>
                <p>Renewed Report</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={() => showHideFilterRightNav('Show')}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {renewedPoliciesContent}
                {printablerenewedPoliciesContent}
            </div>
            {loaderContent}
            {dateFilterContent}
        </section>
    )
}

export default RenewedReport;