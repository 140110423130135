import { useRecoilState } from "recoil";
import { valuationReportsTypeFilter, valuationReportsFilterFromDate, valuationReportsFilterToDate} from "../../../atoms";
import DatePicker from "react-date-picker";

const ValuationReportFilter = () => {
    //################################################################
    //valuation reports filter
    const [valuationReportsTypeFilterRN, onValuationReportsTypeFilterRNChange] = useRecoilState(valuationReportsTypeFilter);
    const [valuationReportsFilterFromDateRN, setValuationReportsFilterFromDateRN] = useRecoilState(valuationReportsFilterFromDate)
    const [valuationReportsFilterToDateRN, setValuationReportsFilterToDateRN] = useRecoilState(valuationReportsFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { onValuationReportsTypeFilterRNChange(e.target.value) }} value={valuationReportsTypeFilterRN}>
                        <option>Select Filter</option>
                        <option>All</option>
                        <option>Valuation Report Present</option>
                        <option>Valuation Report Missing</option>
                    </select>
                    <label className='placeholder'><p>Valuation Reports</p></label>
                </div>
                {/* <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setValuationReportsFilterFromDateRN} value={valuationReportsFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setValuationReportsFilterToDateRN} value={valuationReportsFilterToDateRN} format='d/ M/y' />
                </div> */}
            </div>
        </section>
    )
}

export default ValuationReportFilter;