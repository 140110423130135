import React, { useState, useEffect, useRef, useCallback,useMemo } from 'react';
import db from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import GetAllReceipts from '../../Hooks/BackendConn/GeneralList/GetAllReceipts';
import Loader from '../Loader/Loader';
import GetAllPolicies from '../../Hooks/BackendConn/InsurerSpecific/GetAllPolicies';
import DateFormat from '../../Hooks/UI/DateFormat';
import { read, utils, writeFileXLSX } from 'xlsx';
import { useRecoilState, useRecoilValue } from 'recoil';
import { insurerStatementInsurerFilter, insurerStatementFilterFromDate, insurerStatementFilterToDate } from '../../../atoms';
import './InsurerStatementFinal.css';

const InsurerStatementFinal = (props) => {

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);
    const a = () => {
        const wb = XLSX.utils.table_to_book(tbl.current);
        XLSX.writeFile(wb, "ReactTable.xlsx");
    }

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "INSURER STATEMENT.xlsx");
    }, [tbl]);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const [insurerStatementInsurerFilterRN, setInsurerStatementInsurerFilterRN] = useRecoilState(insurerStatementInsurerFilter);
    const insurerStatementFilterFromDateRN = useRecoilValue(insurerStatementFilterFromDate);
    const insurerStatementFilterToDateRN = useRecoilValue(insurerStatementFilterToDate);

    //################################################################
    //get all claims
    const [allDocs, setAllDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllPolicies({ insurer: insurerStatementInsurerFilterRN });
            setAllDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, insurerStatementInsurerFilterRN]);

    //################################################################
    //brought forward feature
    const [BFPolicyDocs, setBFPolicyDocs] = useState([]);
    useEffect(() => {
        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];

        allDocs.map((doc, index) => {
            //declare filter date
            let filterDate = '';
            switch (doc.docData.transactionType) {
                case 'Debit':
                    filterDate = new Date(doc.docData.insurerDebitDate);
                    filterDate.setHours(0, 0, 0);
                    break;
                case 'Credit':
                    filterDate = new Date(doc.docData.insurerDebitDate);
                    filterDate.setHours(0, 0, 0);
                    break;
                case 'Receipt':
                    filterDate = new Date(doc.docData.receiptDate);
                    filterDate.setHours(0, 0, 0);
                    break;
            }
            //sort into array
            if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Debit') {
                BFDebits.push(doc.docData.insurerPolicyNet);
            }
            else if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Credit') {
                BFCreditsReceipts.push(doc.docData.insurerPolicyNet);
            }
            else if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Receipt') {
                BFCreditsReceipts.push(doc.docData.receiptAmount);
            }
            else if (new Date(filterDate).valueOf() >= new Date(insurerStatementFilterFromDateRN).valueOf() && new Date(filterDate).valueOf() <= new Date(insurerStatementFilterToDateRN).valueOf()) {
                PeriodSelected.push(doc);
            }
        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            policyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding,
            docData: {
                transactionType: 'Brought Forward',
                insurerDebitDate: insurerStatementFilterFromDateRN,
                policyNumber: 'BF',
            },
        }

        PeriodSelected.unshift(BTDetails);
        setBFPolicyDocs(PeriodSelected);

    }, [allDocs, insurerStatementFilterFromDateRN, insurerStatementFilterToDateRN])

    //################################################################
    // sort by date
    const [dateSortedPolicyDocs, setDateSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFPolicyDocs.sort(byDate);
        function byDate(a, b) {
            function ab(doc) {
                //declare filter date
                let filterDate = '';
                switch (doc.docData.transactionType) {
                    case 'Debit':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Credit':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Receipt':
                        filterDate = new Date(doc.docData.receiptDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Brought Forward':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                }
                return filterDate;
            }
            return new Date(ab(a)).valueOf() - new Date(ab(b)).valueOf();
        }
        setDateSortedPolicyDocs(sortedList);
    }, [BFPolicyDocs]);

    //################################################################
    //calculate outstanding
    const [calculatedOutstandingPolicyDocs, setCalculatedOutstandingPolicyDocs] = useState([]);
    useEffect(() => {
        if (dateSortedPolicyDocs.length > 0) {
            for (let i = 1; i < dateSortedPolicyDocs.length; i++) {
                const previousIndex = i - 1;

                if (dateSortedPolicyDocs[0].docData.transactionType === 'Receipt') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = -dateSortedPolicyDocs[0].docData.receiptAmount
                }
                else if (dateSortedPolicyDocs[0].docData.transactionType === 'Brought Forward') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = dateSortedPolicyDocs[0].cummulativeOutstanding;
                }
                else if (dateSortedPolicyDocs[0].docData.transactionType === 'Credit') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = -dateSortedPolicyDocs[0].docData.insurerPolicyNet;
                }
                else {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = dateSortedPolicyDocs[0].docData.insurerPolicyNet
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Debit' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding + +dateSortedPolicyDocs[i].docData.insurerPolicyNet
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Receipt' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding - +dateSortedPolicyDocs[i].docData.receiptAmount
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Credit' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding - +dateSortedPolicyDocs[i].docData.insurerPolicyNet
                }
            }
        }
        setCalculatedOutstandingPolicyDocs(dateSortedPolicyDocs);
    }, [dateSortedPolicyDocs])

    //################################################################
    //get totals
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalPVTPremium, setTotalPVTPremium] = useState(0);
    const [totalStampDuty, setTotalStampDuty] = useState(0);
    const [totalTLevy, setTotalTLevy] = useState(0);
    const [totalPHCFund, setTotalPHCFund] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalWithholding, setTotalWithholding] = useState(0);
    const [totalPolicyNet, setTotalPolicyNet] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);

    useEffect(() => {
        const totalPremiumItems = [];
        const totalPVTPremiumItems = [];
        const totalStampDutyItems = [];
        const totalTLevyItems = [];
        const totalPHCFundItems = [];
        const totalCommissionItems = [];
        const totalWithholdingTaxItems = [];
        const totalPolicyNetItems = [];
        const totalReceiptsItems = [];

        calculatedOutstandingPolicyDocs.map((doc) => {
            if (doc.docData.transactionType === 'Debit' && !doc.docData.policyNumber.startsWith('JV') && !doc.docData.policyNumber.startsWith('DV') && !doc.docData.policyNumber.startsWith('Rev')) {
                totalPremiumItems.push(doc.docData.premium)
                totalPremiumItems.push(doc.docData.earthquake)
                totalPremiumItems.push(doc.docData.excessProtector)
                totalPremiumItems.push(doc.docData.windScreen)
                totalPremiumItems.push(doc.docData.radioCasset)
                totalPremiumItems.push(doc.docData.comesaCard)
                totalPVTPremiumItems.push(doc.docData.PVTPremium)
                totalStampDutyItems.push(doc.docData.stampDuty)
                totalTLevyItems.push(doc.docData.TLevy)
                totalPHCFundItems.push(doc.docData.PHCFund)
                totalCommissionItems.push(doc.docData.commission)
                totalWithholdingTaxItems.push(doc.docData.withholdingTax)
                totalPolicyNetItems.push(doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.transactionType === 'Credit' && !doc.docData.policyNumber.startsWith('CN')) {
                totalPremiumItems.push(-doc.docData.premium)
                totalPremiumItems.push(-doc.docData.earthquake)
                totalPremiumItems.push(-doc.docData.excessProtector)
                totalPremiumItems.push(-doc.docData.windScreen)
                totalPremiumItems.push(-doc.docData.radioCasset)
                totalPremiumItems.push(-doc.docData.comesaCard)
                totalPVTPremiumItems.push(-doc.docData.PVTPremium)
                totalStampDutyItems.push(-doc.docData.stampDuty)
                totalTLevyItems.push(-doc.docData.TLevy)
                totalPHCFundItems.push(-doc.docData.PHCFund)
                totalCommissionItems.push(-doc.docData.commission)
                totalWithholdingTaxItems.push(-doc.docData.withholdingTax)
                totalPolicyNetItems.push(-doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.transactionType === 'Receipt') {
                totalReceiptsItems.push(doc.docData.receiptAmount)
            }
            else if (doc.docData.policyNumber.startsWith('JV') || doc.docData.policyNumber.startsWith('DV') || doc.docData.policyNumber.startsWith('Rev')) {
                totalReceiptsItems.push(-doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.policyNumber.startsWith('CN')) {
                totalReceiptsItems.push(doc.docData.insurerPolicyNet)
            }
        })

        //calc
        const a = totalPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPremium(a);
        const b = totalPVTPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPVTPremium(b);
        const c = totalStampDutyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalStampDuty(c);
        const d = totalTLevyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalTLevy(d);
        const e = totalPHCFundItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPHCFund(e);
        const f = totalCommissionItems.reduce((total, item) => { return total + +item }, 0);
        setTotalCommission(f);
        const g = totalWithholdingTaxItems.reduce((total, item) => { return total + +item }, 0);
        setTotalWithholding(g);
        const h = totalPolicyNetItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPolicyNet(h);
        const i = totalReceiptsItems.reduce((total, item) => { return total + +item }, 0);
        setTotalReceipts(i);

    }, [calculatedOutstandingPolicyDocs])

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(()=>{},[])

    //################################################################
    //search
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);

    useEffect(() => {
        const a = calculatedOutstandingPolicyDocs.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.transactionType == 'Debit' || doc.docData.transactionType == 'Credit') {
                if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
                else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
                else if (doc.docData.endorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            }
            else if (doc.docData.transactionType == 'Receipt') {
                if (doc.docData.clientName.length > 0) {
                    if (doc.docData.clientName[0].toLowerCase().includes(searchTerm.toLowerCase())) {
                        return doc;
                    }
                }
                else if (doc.docData.receiptNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            }
        })
        setSearchedPolicyDocs(a)
    }, [calculatedOutstandingPolicyDocs, searchTerm])

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################
    //view policy
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Insurer Statement Final',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>



    





    //################################################################
    //echo
    const insurerStatementContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => { setSearchTerm(e.target.value) }} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Client Name / Policy No. / Endorsment No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='insurer_statement_final_table' ref={tbl}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Insured</p></td>
                                <td><p>Transaction Type</p></td>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>S.Duty</p></td>
                                <td><p>T.Levy</p></td>
                                <td><p>PHC Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W.Tax</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Credit Net</p></td>
                                <td><p>Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedPolicyDocs.map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{index + 1}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Debit'
                                                    && DateFormat(doc.docData.insurerDebitDate)
                                                }
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                    && DateFormat(doc.docData.insurerDebitDate)
                                                }
                                                {
                                                    doc.docData.transactionType === 'Receipt'
                                                    && DateFormat(doc.docData.receiptDate)
                                                }
                                                {
                                                    doc.docData.transactionType === 'Brought Forward'
                                                    && DateFormat(doc.docData.insurerDebitDate)
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}{doc.docData.receiptNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.endorsmentNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Receipt'
                                                        ? doc.docData.clientName[0]
                                                        : doc.docData.clientName
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.transactionType}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.premium >= 0 ? '(' + parseInt(+doc.docData.premium + +doc.docData.earthquake + +doc.docData.excessProtector + +doc.docData.windScreen + +doc.docData.radioCasset + +doc.docData.comesaCard).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.premium >= 0 ? parseInt(+doc.docData.premium + +doc.docData.earthquake + +doc.docData.excessProtector + +doc.docData.windScreen + +doc.docData.radioCasset + +doc.docData.comesaCard).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.PVTPremium >= 0 ? '(' + parseInt(doc.docData.PVTPremium).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.PVTPremium >= 0 ? parseInt(doc.docData.PVTPremium).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.stampDuty >= 0 ? '(' + parseInt(doc.docData.stampDuty).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.stampDuty >= 0 ? parseInt(doc.docData.stampDuty).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.TLevy >= 0 ? '(' + parseInt(doc.docData.TLevy).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.TLevy >= 0 ? parseInt(doc.docData.TLevy).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.PHCFund >= 0 ? '(' + parseInt(doc.docData.PHCFund).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.PHCFund >= 0 ? parseInt(doc.docData.PHCFund).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.commission >= 0 ? '(' + parseInt(doc.docData.commission).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.commission >= 0 ? parseInt(doc.docData.commission).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.transactionType === 'Credit'
                                                        ? doc.docData.withholdingTax >= 0 ? '(' + parseInt(doc.docData.withholdingTax).toLocaleString("en-US") + ')' : ''
                                                        : doc.docData.withholdingTax >= 0 ? parseInt(doc.docData.withholdingTax).toLocaleString("en-US") : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.policyNumber
                                                        ? doc.docData.policyNumber.startsWith('JV') || doc.docData.policyNumber.startsWith('DV') || doc.docData.policyNumber.startsWith('CN') || doc.docData.policyNumber.startsWith('Rev')
                                                            ? ''
                                                            : doc.docData.insurerPolicyNet
                                                                ? doc.docData.transactionType === 'Credit'
                                                                    ? '(' + parseInt(doc.docData.insurerPolicyNet).toLocaleString("en-US") + ')'
                                                                    : parseInt(doc.docData.insurerPolicyNet).toLocaleString("en-US")
                                                                : ''
                                                        : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {doc.docData.receiptAmount ? '(' + parseInt(doc.docData.receiptAmount).toLocaleString("en-US") + ')' : ''}
                                                {
                                                    doc.docData.policyNumber
                                                        ? doc.docData.policyNumber.startsWith('CN')
                                                            ? doc.docData.insurerPolicyNet ? '(' + parseInt(doc.docData.insurerPolicyNet).toLocaleString("en-US") + ')' : ''
                                                            : ''
                                                        : ''
                                                }
                                                {
                                                    doc.docData.policyNumber
                                                        ? doc.docData.policyNumber.startsWith('JV') || doc.docData.policyNumber.startsWith('DV') || doc.docData.policyNumber.startsWith('Rev')
                                                            ? doc.docData.insurerPolicyNet ? parseInt(doc.docData.insurerPolicyNet).toLocaleString("en-US") : ''
                                                            : ''
                                                        : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.cummulativeOutstanding ? parseInt(doc.cummulativeOutstanding).toLocaleString("en-US") : ''}</p></td>
                                        </tr>
                                    )
                                })
                            }
                            <tr className='insurer_statement_final_totals'>
                                <td colSpan='5'><p></p></td>
                                <td><p>Totals</p></td>
                                <td><p>{parseInt(totalPremium).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPVTPremium).toLocaleString("en-US")}</p></td>
                                <td><p>
                                    {

                                        parseInt(totalStampDuty) > 0
                                            ? parseInt(totalStampDuty).toLocaleString("en-US")
                                            : '(' + parseInt(Math.abs(totalStampDuty)).toLocaleString("en-US") + ')'
                                    }
                                </p></td>
                                <td><p>{parseInt(totalTLevy).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPHCFund).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalCommission).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalWithholding).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPolicyNet).toLocaleString("en-US")}</p></td>
                                <td><p>{'(' + parseInt(totalReceipts).toLocaleString("en-US") + ')'}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>


    return (
        <section className='insurer_statement_final body_input_styles'>
            <div className='body_section_header'>
                <p>Insurer Statement</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={exportFile} >
                        <i className="ri-file-excel-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {insurerStatementContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default InsurerStatementFinal;