import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { enableIndexedDbPersistence } from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: "AIzaSyCa6MIqH_hgx_iUW7HnB6aTLept5RPe6Lw",
    authDomain: "simia-9e230.firebaseapp.com",
    projectId: "simia-9e230",
    storageBucket: "simia-9e230.appspot.com",
    messagingSenderId: "179237761327",
    appId: "1:179237761327:web:17a910f1198f976c7f31da",
    measurementId: "G-1XPN9MXYY1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });


export default db;
export {auth, storage};