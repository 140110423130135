const MotorPrivateCompClauses = () => {
    return (
        <section>
            <p className='leading-none'>Conditions</p>
            <p className='leading-none'>Excess</p>
            <li className='leading-none'>Own damage - 2.5% of value min Kshs 15,000/- subject to a maximum of Kshs 100,000.00</li>
            <li className='leading-none'>Theft or any attempt thereof - 10% of value min 20,000 with anti theft, - 20% of value min 20,000 without anti theft, - 2.5% of value min 20,000 with tracking device.</li>
            <li className='leading-none'>Third party property damage Kshs 7,500</li>
            <li className='leading-none'>Third party injury - Nil</li>
            <li className='leading-none'>New & young drivers Kshs 5,000</li>
            <p className='leading-none'>Limits</p>
            <li className='leading-none'>TPPD - Kshs 5,000,000</li>
            <li className='leading-none'>Passanger Liability - Kshs 20,000,000 per event, - kshs 3,000,000 per person</li>
            <li className='leading-none'>Third party injury - Kshs unlimited per event,- Kshs 3,000,000 per person</li>
            <li className='leading-none'>Repair limit - Kshs 50,000</li>
            <li className='leading-none'>Towing limit - Kshs 50,000</li>
            <li className='leading-none'>M/Expenses - Kshs 50,000</li>
            <li className='leading-none'>Windscreen - Kshs 75,000</li>
            <li className='leading-none'>Radiocasette - Kshs 75,000</li>
            <li className='leading-none'>Including political violence and terrorism</li>
            <li className='leading-none'>Inclusive of strike riot and civil commotion</li>
            <li className='leading-none'>Inclusive of excess protector on own damage</li>
            <li className='leading-none'>No blame no excess</li>
            <li className='leading-none'>Agreed value at 85% of the current valuation report by insurers approved valuer</li>
        </section>
    )
}

export default MotorPrivateCompClauses;