import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PendingListInsurerFilter, PendingListFilterFromDate, PendingListFilterToDate } from '../../../atoms';
import GetPendingDocs from '../../Hooks/BackendConn/InsurerSpecific/GetPendingDocs';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import Loader from '../Loader/Loader';
import Paginate from './Paginate';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';
import { nanoid } from 'nanoid';
import './PendingListFinal.css';

const PendingListFinal = (props) => {
    //################################################################
    //recoil values
    const PendingListInsurerFilterRN = useRecoilValue(PendingListInsurerFilter)
    const PendingListFilterFromDateRN = useRecoilValue(PendingListFilterFromDate)
    const PendingListFilterToDateRN = useRecoilValue(PendingListFilterToDate)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);
    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(componentRef.current);
        writeFileXLSX(wb, "Pending List.xlsx");
    }, [tbl]);

    //################################################################
    //get all debits
    const [pendingDocs, setPendingDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useMemo(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetPendingDocs(PendingListInsurerFilterRN);
            setPendingDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, PendingListInsurerFilterRN]);

    //################################################################
    //calc no of pending days
    const [calculatedDays, setCalculatedDays] = useState([]);
    useMemo(() => {
        const calcDays = []
        pendingDocs.map((doc) => {
            //calculate pending days
            const dateOne = new Date();
            const dateTwo = new Date(doc.docData.dateCreated.seconds * 1000);
            const datesCompared = Math.abs(dateOne - dateTwo);
            let datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
            calcDays.push({ docData: doc.docData, docID: doc.docID, daysPending: datesDifference })
        });
        setCalculatedDays(calcDays)
    }, [pendingDocs])

    //################################################################
    //sort by days pending
    const [pendingDaysSortedDocs, setPendingDaysSortedDocs] = useState([])
    useMemo(() => {
        const a = calculatedDays.sort(byDaysPending)
        function byDaysPending(a, b) {
            return parseInt(b.daysPending - a.daysPending)
        }
        setPendingDaysSortedDocs(a)
    }, [calculatedDays])

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useMemo(() => {
        const periodSelected = [];
        pendingDaysSortedDocs.map((item) => {
            //calculate next inception
            let filterDate = '';
            if (item.docData.transactionType === 'Credit') {
                filterDate = new Date(item.docData.dateCreated.seconds * 1000);
            }
            else {
                filterDate = new Date(item.docData.inceptionDate)
            }
            filterDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(filterDate).valueOf() >= new Date(PendingListFilterFromDateRN).valueOf() && new Date(PendingListFilterToDateRN).valueOf() >= new Date(filterDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [pendingDaysSortedDocs, PendingListFilterFromDateRN, PendingListFilterToDateRN]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useMemo(() => {
        const searchedDocs = [];
        const values = selectedPeriodFilteredDocs.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [selectedPeriodFilteredDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################
    //view policy
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'Pending List Edit',
            previousView: 'Pending List Final',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //echo
    const pendingListFinalContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Client Name / Policy Number</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='pending_list_final_print_only_component'>
                        <center><p className='bold'>PENDING LIST</p></center>
                        <div className='h_4'></div>
                        <center><p className='bold'>PERIOD : {DateFormat(PendingListFilterFromDateRN)} - {DateFormat(PendingListFilterToDateRN)}</p></center>
                        <div className='h_4'></div>
                        <center><p className='bold'>INSURER : {PendingListInsurerFilterRN}</p></center>
                        <div className='h_4'></div>
                    </div>
                    <table className='pending_list_final_table' ref={componentRef}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Transaction Type</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Incep- tion Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Date Submi- tted To Insurer</p></td>
                                <td><center><p>Days Pending</p></center></td>
                                <td><center><p>VR</p></center></td>
                                <td><center><p>LB</p></center></td>
                                <td><center><p>SC</p></center></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedPolicyDocs.map((doc) => {
                                    const valuationReportDocuments = [];
                                    if (doc.docData.motorDocuments) {
                                        Object.values(doc.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Valuation Report') {
                                                valuationReportDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    const logBookDocuments = [];
                                    if (doc.docData.motorDocuments) {
                                        Object.values(doc.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Logbook') {
                                                logBookDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    const stickerCopyDocuments = [];
                                    if (doc.docData.motorDocuments) {
                                        Object.values(doc.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Motor Certificate') {
                                                stickerCopyDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    const policyDocuments = [];
                                    if (doc.docData.associateDocuments) {
                                        Object.values(doc.docData.associateDocuments).map((item) => {
                                            if (item.documentUploadCategory === 'Policy Document') {
                                                policyDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    return (
                                        <tr key={nanoid()} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.transactionType}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.businessType}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.endorsmentNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductSubClass}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                                        ? doc.docData.vehicleItems
                                                        && Object.values(doc.docData.vehicleItems).map((veh) => {
                                                            return (
                                                                veh.regNo + ', '
                                                            )
                                                        })
                                                        : doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{NumberFormat(doc.docData.policyNet)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.dateCreated)}</p></td>
                                            <td id={doc.docID}><center><p id={doc.docID}>{doc.daysPending}</p></center></td>
                                            <td>
                                                {
                                                    doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                                        ? valuationReportDocuments.length > 0
                                                            ? valuationReportDocuments.map((item, index) => {
                                                                return (
                                                                    <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                )
                                                            })
                                                            : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                        : <center><p>n/a</p></center>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                                        ? logBookDocuments.length > 0
                                                            ? logBookDocuments.map((item, index) => {
                                                                return (
                                                                    <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                )
                                                            })
                                                            : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                        : <center><p>n/a</p></center>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                                        ? stickerCopyDocuments.length > 0
                                                            ? stickerCopyDocuments.map((item, index) => {
                                                                return (
                                                                    <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                )
                                                            })
                                                            : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                        : <center><p>n/a</p></center>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    policyDocuments.length > 0
                                                        ? policyDocuments.map((item, index) => {
                                                            return (
                                                                <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                            )
                                                        })
                                                        : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    // //################################################################
    // //printable
    // const pendingListFinalContentPrintable = <section style={{ display: "none" }}>
    //     <div className='h_5'></div>
    //     <div className='category'>
    //         <div className='category_header'>
    //             <div className='search_wrapper'>
    //                 <div className='input_group_search'>
    //                     <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
    //                     <label className='placeholder'><p>Search Client Name / Policy Number</p></label>
    //                 </div>
    //                 <span className='search_icon'><i className="ri-search-2-line"></i></span>
    //             </div>
    //         </div>
    //         <div className='category_content'>
    //             <div className='category_content_three'>
    //                 <table className='pending_list_final_table' ref={componentRef}>
    //                     <thead>
    //                         <tr><td colSpan='12'><center><p className='bold'>Pending List</p></center></td></tr>
    //                         <tr><td colSpan='12'><center><p className='bold'>Period : {DateFormat(PendingListFilterFromDateRN)} - {DateFormat(PendingListFilterToDateRN)}</p></center></td></tr>
    //                         <tr><td colSpan='12'><center><p className='bold'>Insurer : {PendingListInsurerFilterRN}</p></center></td></tr>
    //                         <tr>
    //                             <td><p>No</p></td>
    //                             <td><p>Name</p></td>
    //                             <td><p>Voucher Type</p></td>
    //                             <td><p>Policy Number</p></td>
    //                             <td><p>Endorsment Number</p></td>
    //                             <td><p>Policy Class</p></td>
    //                             <td><p>Incep- tion Date</p></td>
    //                             <td><p>Expiry Date</p></td>
    //                             <td><p>Details</p></td>
    //                             <td><p>Policy Net</p></td>
    //                             <td><p>Date Submi- tted To Insurer</p></td>
    //                             <td><center><p>Days Pending</p></center></td>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {
    //                             searchedPolicyDocs.map((doc, index) => {
    //                                 return (
    //                                     <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.transactionType}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.endorsmentNumber}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductSubClass}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.inceptionDate)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.expiryDate)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>
    //                                             {
    //                                                 doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
    //                                                     ? doc.docData.vehicleItems
    //                                                     && Object.values(doc.docData.vehicleItems).map((veh) => {
    //                                                         return (
    //                                                             veh.regNo + ', '
    //                                                         )
    //                                                     })
    //                                                     : doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'
    //                                             }
    //                                         </p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{NumberFormat(doc.docData.policyNet)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.dateCreated)}</p></td>
    //                                         <td id={doc.docID}><center><p id={doc.docID}>{doc.daysPending}</p></center></td>
    //                                     </tr>
    //                                 )
    //                             })
    //                         }
    //                     </tbody>
    //                 </table>
    //                 <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
    //             </div>
    //         </div>
    //     </div>
    // </section>

    // //################################################################
    // //export to excel
    // const pendingListFinalContentExcelExport = <section style={{ display: "none" }}>
    //     <div className='h_5'></div>
    //     <div className='category'>
    //         <div className='category_header'>
    //             <div className='search_wrapper'>
    //                 <div className='input_group_search'>
    //                     <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
    //                     <label className='placeholder'><p>Search Client Name / Policy Number</p></label>
    //                 </div>
    //                 <span className='search_icon'><i className="ri-search-2-line"></i></span>
    //             </div>
    //         </div>
    //         <div className='category_content'>
    //             <div className='category_content_three'>
    //                 <table className='pending_list_final_table' ref={tbl}>
    //                     <thead>
    //                         <tr><td colSpan='12'><center><p className='bold'>Pending List</p></center></td></tr>
    //                         <tr><td colSpan='12'><center><p className='bold'>Period : {DateFormat(PendingListFilterFromDateRN)} - {DateFormat(PendingListFilterToDateRN)}</p></center></td></tr>
    //                         <tr><td colSpan='12'><center><p className='bold'>Insurer : {PendingListInsurerFilterRN}</p></center></td></tr>
    //                         <tr>
    //                             <td><p>No</p></td>
    //                             <td><p>Name</p></td>
    //                             <td><p>Voucher Type</p></td>
    //                             <td><p>Policy Number</p></td>
    //                             <td><p>Endorsment Number</p></td>
    //                             <td><p>Policy Class</p></td>
    //                             <td><p>Incep- tion Date</p></td>
    //                             <td><p>Expiry Date</p></td>
    //                             <td><p>Details</p></td>
    //                             <td><p>Policy Net</p></td>
    //                             <td><p>Date Submi- tted To Insurer</p></td>
    //                             <td><center><p>Days Pending</p></center></td>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {
    //                             searchedPolicyDocs.map((doc, index) => {
    //                                 return (
    //                                     <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.transactionType}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.endorsmentNumber}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductSubClass}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.inceptionDate)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.expiryDate)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>
    //                                             {
    //                                                 doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
    //                                                     ? doc.docData.vehicleItems
    //                                                     && Object.values(doc.docData.vehicleItems).map((veh) => {
    //                                                         return (
    //                                                             veh.regNo + ', '
    //                                                         )
    //                                                     })
    //                                                     : doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'
    //                                             }
    //                                         </p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{NumberFormat(doc.docData.policyNet)}</p></td>
    //                                         <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.dateCreated)}</p></td>
    //                                         <td id={doc.docID}><center><p id={doc.docID}>{doc.daysPending}</p></center></td>
    //                                     </tr>
    //                                 )
    //                             })
    //                         }
    //                     </tbody>
    //                 </table>
    //                 <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
    //             </div>
    //         </div>
    //     </div>
    // </section>

    return (
        <section>
            <section className='pending_list_final body_input_styles'>
                <div className='body_section_header'>
                    <p>Pending List</p>
                    <div className='right_side_controls'>
                        <span className='btn_pink' onClick={exportFile} >
                            <i className="ri-file-excel-2-line"></i>
                        </span>
                        <div className='w_5'></div>
                        <span className='btn_pink' onClick={handlePrint}>
                            <i className="ri-printer-line"></i>
                        </span>
                    </div>
                </div>
                <div className='body_section'>
                    {pendingListFinalContent}
                    {/* {pendingListFinalContentPrintable} */}
                    {/* {pendingListFinalContentExcelExport} */}
                </div>
            </section>
            {URRightClickMenu}
            {loaderContent}
        </section>
    );
}

export default PendingListFinal;