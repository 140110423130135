import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetAllMotorDocsTransactionAndInsurerWise = async (receivedDetails) => {

    const q = query(collection(db, "PolicyDetails"), where("transactionType", "==", receivedDetails.transactionType), where("insurerName", "==", receivedDetails.insurerName), where('selectedProductClass', 'in', ['(07) - Motor Private', '(07) - Motor Private - Comprehensive', '(07) - Motor Private - TPO', '(08) - Motor Commercial', '(08) - Motor Commercial - Comprehensive', '(08) - Motor Commercial - TPO']));

    const querySnapshot = await getDocs(q);

    const DData = [];
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        DData.push({ docData: doc.data(), docID: doc.id })
    });

    return DData;

}

export default GetAllMotorDocsTransactionAndInsurerWise;