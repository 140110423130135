import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import DatePicker from 'react-date-picker';
import { useReactToPrint } from "react-to-print";
import Loader from '../Loader/Loader';
import InsuranceProducts from '../BodyPolicy/InsuranceProducts';
import BodySelectOption from '../BodyPolicy/BodySelectOption';
import '../BodyStatements/AccountsInsurerStatement.css';
import './ReportPolicyClass.css';

const ReportPolicyClass = () => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //get user input
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])
    //console.log(fromDate)

    //to
    const [toDate, setToDate] = useState('');

    const [tvalue, onTChange] = useState(new Date('2022-12-31'));
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //from date echo
    const [echoFromDate, setEchoFromDate] = useState('');
    useEffect(() => {
        const echoFromDay = new Date(fromDate).toLocaleString('en-us', { day: '2-digit' });
        const echoFromMonth = new Date(fromDate).toLocaleString('en-us', { month: 'short' });
        const echoFromYear = new Date(fromDate).getFullYear();
        const echoDate = echoFromDay + '-' + echoFromMonth + '-' + echoFromYear;
        setEchoFromDate(echoDate);
    }, [fromDate]);

    //filter
    const [filterCreteria, setFilterCreteria] = useState('All Policies');
    const filterCreteriaChangeHandler = (event) => {
        setFilterCreteria(event.target.value)
    }

    // set selected product class to state
    const [insuranceProducts, setInsuranceProducts] = useState([{
        productClass: 'Select Product Class',
        subClass: {
            1: 'Select Product Sub Class'
        }
    }]);
    //push motor into above array
    useEffect(() => {
        const insuranceProducts = InsuranceProducts;
        let isCancelled = false;
        const fetchData = () => {
            if (!isCancelled) {
                const motorClassIndepth = {
                    productClass: 'Motor',
                    subClass: {
                        1: 'Select Product Sub Class',
                        2: 'Motor Comprehensive',
                        3: 'Motor TPO'
                    }
                }
                const PVT = {
                    productClass: 'PVT',
                    subClass: {
                        1: 'Select Product Sub Class',
                        2: 'PVT As an Extension',
                        3: 'PVT As Standalone Policy',
                        4: 'PVT Extension in Fire',
                        5: 'PVT Extension in Burglary',
                        6: 'PVT Extension in GIT',
                        7: 'PVT Extension in WIBA',
                        8: 'PVT Extension in Motor',
                    }
                }

                const s = [...insuranceProducts, motorClassIndepth, PVT]
                // insuranceProducts.push(motorClassIndepth)
                setInsuranceProducts(s)
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [])


    const [selectedProductClass, setSelectedProductClass] = useState('Select Product Class');
    const productClassChangeHandler = (event) => {
        setSelectedProductClass(event.target.value);
    }

    // get subclass array
    const selectedProductSubClassIndex = insuranceProducts.find(({ productClass }) => productClass === selectedProductClass);
    const selectedProductSubClassIndexed = Object.values(selectedProductSubClassIndex.subClass);

    const [selectedProductSubClass, setSelectedProductSubClass] = useState('');
    const productSubClassChangeHandler = (event) => {
        setSelectedProductSubClass(event.target.value)
        setFilterCreteria(event.target.value)
    }




    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    // search section
    const filterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
                {/* select */}
                <div>
                    <div className='h_15'></div>
                    <p>Filter Category</p>
                    <div className='h_5'></div>
                    <select onChange={productClassChangeHandler}>
                        {insuranceProducts.map((product) => (
                            <BodySelectOption key={product.productClass} option={product.productClass} />
                        ))}
                    </select>
                    <div className='h_10'></div>
                    <select onChange={productSubClassChangeHandler}>
                        {selectedProductSubClassIndexed.map((product) => (
                            <BodySelectOption key={product} option={product} />
                        ))}
                    </select>
                </div>
            </div>
        </div>
    </div>


    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    // assign date
                    let UDate = '';
                    let echoDate = '';
                    let policyClientName = '';
                    if (doc.data().transactionType === 'Receipt') {
                        UDate = doc.data().receiptDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName[0];
                    }
                    else if (doc.data().transactionType === 'Debit') {
                        UDate = doc.data().inceptionDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName;
                    }
                    else if (doc.data().transactionType === 'Credit') {
                        UDate = doc.data().inceptionDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName;
                    }
                    const allPolicyDocs = {
                        policyClientName: policyClientName,
                        policyPolicyNumber: doc.data().policyNumber,
                        policyEndorsmentNumber: doc.data().endorsmentNumber,
                        policyselectedProductClass: doc.data().selectedProductClass,
                        policySelectedProductSubClass: doc.data().selectedProductSubClass,
                        policyInceptionDate: doc.data().inceptionDate,
                        policyPolicyNet: doc.data().policyNet,
                        policyOutstanding: doc.data().outstanding,
                        policyTransactionType: doc.data().transactionType,
                        policyInceptionDate: doc.data().inceptionDate,
                        policyLocationOfTheRisk: doc.data().locationOfTheRisk,
                        policyVehicleItems: doc.data().vehicleItems,
                        policyPremium: doc.data().premium,
                        policyPVTPremium: doc.data().PVTPremium,
                        policyStampDuty: doc.data().stampDuty,
                        policyTLevy: doc.data().TLevy,
                        policyPHCFund: doc.data().PHCFund,
                        policyCommission: doc.data().commission,
                        policyCommissionPayable: doc.data().commissionPayable,
                        policyWithholdingTax: doc.data().withholdingTax,
                        policyInsurerPolicyNet: doc.data().insurerPolicyNet,
                        receiptAllocationDetails: doc.data().receiptAllocationDetails,
                        receiptAmount: doc.data().receiptAmount,
                        receiptNumber: doc.data().receiptNumber,
                        receiptDate: doc.data().receiptDate,
                        uDate: UDate,
                        echoDate: echoDate,
                        cummulativeOutstanding: ''
                    }
                    Rdata.push(allPolicyDocs);
                    setIsloading(false);
                });
                setReceivedPolicyDocs(Rdata);

            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    const [filterPolicyClass, setFilterPolicyClass] = useState([]);
    useEffect(() => {
        const filteredPolicyDocs = [];
        receivedPolicyDocs.map((item) => {
            setIsloading(true);
            if (item.policyTransactionType !== 'Receipt') {
                setIsloading(true)
                if (filterCreteria === 'All Policies') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(020) - Contractors All Risks' && item.policySelectedProductSubClass === '(020) - Contractors All Risks') { setIsloading(true); filteredPolicyDocs.push(item) }
                if (filterCreteria === '(021) - ContractorsPlant and Machinery' && item.policySelectedProductSubClass === '(021) - ContractorsPlant and Machinery') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(022) - Erection All Risks' && item.policySelectedProductSubClass === '(022) - Erection All Risks') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(023) - Machinery Break Down' && item.policySelectedProductSubClass === '(023) - Machinery Break Down') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(024) - Computer Electronic Equipment' && item.policySelectedProductSubClass === '(024) - Computer Electronic Equipment') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(025) - Boiler and Pressure Vessel' && item.policySelectedProductSubClass === '(025) - Boiler and Pressure Vessel') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(026) - Loss of Profit (L.O.P) Following Machinery Breakdown' && item.policySelectedProductSubClass === '(026) - Loss of Profit (L.O.P) Following Machinery Breakdown') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(027) - Deterioration of Stock' && item.policySelectedProductSubClass === '(027) - Deterioration of Stock') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(028) - Engineering Completed Risks' && item.policySelectedProductSubClass === '(028) - Engineering Completed Risks') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(030) - Domestic Package' && item.policySelectedProductSubClass === '(030) - Domestic Package') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(031) - Domestic Contents' && item.policySelectedProductSubClass === '(031) - Domestic Contents') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(040) - Fire Industrial' && item.policySelectedProductSubClass === '(040) - Fire Industrial') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(041) - Con Loss Following Fire' && item.policySelectedProductSubClass === '(041) - Con Loss Following Fire') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(042) - Industrial All Risk' && item.policySelectedProductSubClass === '(042) - Industrial All Risk') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(043) - Terrorism' && item.policySelectedProductSubClass === '(043) - Terrorism') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(044) - Business Combined' && item.policySelectedProductSubClass === '(044) - Business Combined') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(050) - Products Liabilty' && item.policySelectedProductSubClass === '(050) - Products Liabilty') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(051) - Professional Liability' && item.policySelectedProductSubClass === '(051) - Professional Liability') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(052) - Public Liabilty' && item.policySelectedProductSubClass === '(052) - Public Liabilty') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(060) - Marine Hull' && item.policySelectedProductSubClass === '(060) - Marine Hull') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(061) - Marine Certificate' && item.policySelectedProductSubClass === '(061) - Marine Certificate') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(062) - Goods in Transit (GIT)' && item.policySelectedProductSubClass === '(062) - Goods in Transit (GIT)') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(064) - CARRIERS LEGAL LIABILITY' && item.policySelectedProductSubClass === '(064) - CARRIERS LEGAL LIABILITY') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0700) - Motor Private' && item.policySelectedProductSubClass === '(0700) - Motor Private') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0701) - Motor Cycle' && item.policySelectedProductSubClass === '(0701) - Motor Cycle') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0730) - Private Certificate Binder' && item.policySelectedProductSubClass === '(0730) - Private Certificate Binder') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0800) - Motor Commercial' && item.policySelectedProductSubClass === '(0800) - Motor Commercial') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0801) - Tractor' && item.policySelectedProductSubClass === '(0801) - Tractor') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0802) - New Trade' && item.policySelectedProductSubClass === '(0802) - New Trade') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0803) - Private Hire' && item.policySelectedProductSubClass === '(0803) - Private Hire') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0804) - PSV' && item.policySelectedProductSubClass === '(0804) - PSV') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0807) - General Cartage' && item.policySelectedProductSubClass === '(0807) - General Cartage') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0811) - Special Vehicle' && item.policySelectedProductSubClass === '(0811) - Special Vehicle') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0812) - Driving School' && item.policySelectedProductSubClass === '(0812) - Driving School') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(0890) - PTA Yellow Card' && item.policySelectedProductSubClass === '(0890) - PTA Yellow Card') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(090) - Personal Accident' && item.policySelectedProductSubClass === '(090) - Personal Accident') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(091) - Medical' && item.policySelectedProductSubClass === '(091) - Medical') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(092) - Group Personal Accident' && item.policySelectedProductSubClass === '(092) - Group Personal Accident') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(093) - Travel' && item.policySelectedProductSubClass === '(093) - Travel') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(100) - Cash in Transit' && item.policySelectedProductSubClass === '(100) - Cash in Transit') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(101) - Fidelity Gurantee' && item.policySelectedProductSubClass === '(101) - Fidelity Gurantee') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(102) - Burglary' && item.policySelectedProductSubClass === '(102) - Burglary') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(103) - All Risk' && item.policySelectedProductSubClass === '(103) - All Risk') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(104) - Bankers Blanket' && item.policySelectedProductSubClass === '(104) - Bankers Blanket') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(112) - Wiba' && item.policySelectedProductSubClass === '(112) - Wiba') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(113) - Common Law' && item.policySelectedProductSubClass === '(113) - Common Law') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(114) - Wiba Plus' && item.policySelectedProductSubClass === '(114) - Wiba Plus') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(120) - Immigration Bond' && item.policySelectedProductSubClass === '(120) - Immigration Bond') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(123) - PLATE GLASS' && item.policySelectedProductSubClass === '(123) - PLATE GLASS') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(125) - GOLFERS' && item.policySelectedProductSubClass === '(125) - GOLFERS') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(127) - Advance Payment Bond' && item.policySelectedProductSubClass === '(127) - Advance Payment Bond') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(128) - Custom Bond' && item.policySelectedProductSubClass === '(128) - Custom Bond') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(129) - Excess Protection' && item.policySelectedProductSubClass === '(129) - Excess Protection') { filteredPolicyDocs.push(item) }
                if (filterCreteria === '(130) - Goods In Transit' && item.policySelectedProductSubClass === '(130) - Goods In Transit') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'Motor Comprehensive' && item.policyPolicyNumber.includes('COMP')) { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'Motor TPO' && item.policyPolicyNumber.includes('TPO')) { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT As an Extension' && item.policyPVTPremium > 0) { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT As Standalone Policy' && item.policySelectedProductSubClass === '(043) - Terrorism') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in Fire' && item.policyPVTPremium > 0 && item.policySelectedProductSubClass === '(040) - Fire Industrial') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in Burglary' && item.policyPVTPremium > 0 && item.policySelectedProductSubClass === '(102) - Burglary') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in GIT' && item.policyPVTPremium > 0 && item.policySelectedProductSubClass === '(062) - Goods in Transit (GIT)') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in WIBA' && item.policyPVTPremium > 0 && item.policySelectedProductSubClass === '(112) - Wiba') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in WIBA' && item.policyPVTPremium > 0 && item.policySelectedProductSubClass === '(114) - Wiba Plus') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in Motor' && item.policyPVTPremium > 0 && item.policyselectedProductClass === '(07) - Motor Private') { filteredPolicyDocs.push(item) }
                if (filterCreteria === 'PVT Extension in Motor' && item.policyPVTPremium > 0 && item.policyselectedProductClass === '(08) - Motor Commercial') { filteredPolicyDocs.push(item) }
            }
        });
        setFilterPolicyClass(filteredPolicyDocs);
        setIsloading(false);

    }, [receivedPolicyDocs, fromDate, toDate, filterCreteria])


    //bf feature
    const [BFPolicyDocs, setBFPolicyDocs] = useState([]);
    useEffect(() => {
        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];

        filterPolicyClass.map((item, index) => {
            if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Debit') {
                BFDebits.push(item.policyInsurerPolicyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Credit') {
                BFCreditsReceipts.push(item.policyInsurerPolicyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Receipt') {
                BFCreditsReceipts.push(item.receiptAmount);
            }
            if (new Date(item.uDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.uDate).valueOf()) {
                PeriodSelected.push(item);
            }
        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            uDate: fromDate,
            echoDate: echoFromDate,
            policyPolicyNumber: 'BF',
            policyPolicyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding
        }

        PeriodSelected.unshift(BTDetails);
        setBFPolicyDocs(PeriodSelected)

    }, [filterPolicyClass, echoFromDate])

    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = BFPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.policyClientName && val.policyClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.policyPolicyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.policyEndorsmentNumber && val.policyEndorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            // else if (val.policyClientVehicleItems.includes(searchTerm.toLowerCase())) {
            //     return val;
            // }
            // else {
            //     const vhArr = val.policyClientVehicleItems
            //     for (var i = 0; i < vhArr.length; i++) {
            //         if (vhArr[i].includes(searchTerm.toLowerCase())) {
            //             return val;

            //         }
            //     }
            // }

        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedPolicyDocs(c);
    }, [BFPolicyDocs, searchTerm])

    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = searchedPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.uDate).valueOf() - new Date(b.uDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [searchedPolicyDocs]);

    //calculate outstanding
    const [outstandingMap, setOutstandingMap] = useState([]);
    useEffect(() => {
        if (sortedPolicyDocs.length > 0) {
            for (let i = 1; i < sortedPolicyDocs.length; i++) {
                const previousIndex = i - 1;

                if (sortedPolicyDocs[0].policyTransactionType === 'Receipt') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].receiptAmount
                }
                else if (sortedPolicyDocs[0].policyPolicyNumber === 'BF') {
                    sortedPolicyDocs[0].cummulativeOutstanding = sortedPolicyDocs[0].cummulativeOutstanding;
                }
                else if (sortedPolicyDocs[0].policyTransactionType === 'Credit') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].policyInsurerPolicyNet;
                }
                else {
                    sortedPolicyDocs[0].cummulativeOutstanding = sortedPolicyDocs[0].policyInsurerPolicyNet
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Debit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding + +sortedPolicyDocs[i].policyInsurerPolicyNet
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Receipt' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].receiptAmount
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Credit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].policyInsurerPolicyNet
                }
            }
        }
        setOutstandingMap(sortedPolicyDocs);
    }, [sortedPolicyDocs])

    //get totals
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalPVTPremium, setTotalPVTPremium] = useState(0);
    const [totalStampDuty, setTotalStampDuty] = useState(0);
    const [totalTLevy, setTotalTLevy] = useState(0);
    const [totalPHCFund, setTotalPHCFund] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalWithholding, setTotalWithholding] = useState(0);
    const [totalPolicyNet, setTotalPolicyNet] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);

    useEffect(() => {
        const totalPremiumItems = [];
        const totalPVTPremiumItems = [];
        const totalStampDutyItems = [];
        const totalTLevyItems = [];
        const totalPHCFundItems = [];
        const totalCommissionItems = [];
        const totalWithholdingTaxItems = [];
        const totalPolicyNetItems = [];
        const totalReceiptsItems = [];

        outstandingMap.map((item, index) => {
            if (item.policyTransactionType === 'Debit') {
                totalPremiumItems.push(item.policyPremium)
                totalPVTPremiumItems.push(item.policyPVTPremium)
                totalStampDutyItems.push(item.policyStampDuty)
                totalTLevyItems.push(item.policyTLevy)
                totalPHCFundItems.push(item.policyPHCFund)
                totalCommissionItems.push(item.policyCommission)
                totalWithholdingTaxItems.push(item.policyWithholdingTax)
                totalPolicyNetItems.push(item.policyInsurerPolicyNet)
            }
            else if (item.policyTransactionType === 'Credit') {
                totalPremiumItems.push(-item.policyPremium)
                totalPVTPremiumItems.push(-item.policyPVTPremium)
                totalStampDutyItems.push(-item.policyStampDuty)
                totalTLevyItems.push(-item.policyTLevy)
                totalPHCFundItems.push(-item.policyPHCFund)
                totalCommissionItems.push(-item.policyCommission)
                totalWithholdingTaxItems.push(-item.policyWithholdingTax)
                totalPolicyNetItems.push(-item.policyInsurerPolicyNet)
            }
            else if (item.policyTransactionType === 'Receipt') {
                totalReceiptsItems.push(item.receiptAmount)
            }
        })
        //calc
        const a = totalPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPremium(a);
        const b = totalPVTPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPVTPremium(b);
        const c = totalStampDutyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalStampDuty(c);
        const d = totalTLevyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalTLevy(d);
        const e = totalPHCFundItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPHCFund(e);
        const f = totalCommissionItems.reduce((total, item) => { return total + +item }, 0);
        setTotalCommission(f);
        const g = totalWithholdingTaxItems.reduce((total, item) => { return total + +item }, 0);
        setTotalWithholding(g);
        const h = totalPolicyNetItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPolicyNet(h);
        const i = totalReceiptsItems.reduce((total, item) => { return total + +item }, 0);
        setTotalReceipts(i);

    }, [outstandingMap])

    //insurer stmt
    const insurerStatementContent = <section className='insurer_statement_category'>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Policy Class Filter - {filterCreteria}</p>
                <div className='client_controls'>
                    <span className='btn_yellow' onClick={() => showHideFilterRightNav('Show')}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='category_content' ref={componentRef}>
                <div className='category_content_three'>
                    <div className='category_header policy_search_container'>
                        <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number / Endorsment Number.' />
                        <i className="ri-search-2-line"></i>
                    </div>
                    <div className='h_5'></div>
                    <table className='insurer_statement_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Insured</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>Stamp Duty</p></td>
                                <td><p>T Levy</p></td>
                                <td><p>PHC Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W Tax</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Credit Net</p></td>
                                <td><p>Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {outstandingMap.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{index + 1}</p></td>
                                        <td><p>{item.echoDate}</p></td>
                                        <td><p>{item.policyClientName}</p></td>
                                        <td><p>{item.policyPolicyNumber}{item.receiptNumber}</p></td>
                                        <td><p>{item.policyEndorsmentNumber}</p></td>
                                        <td><p>{item.policySelectedProductSubClass}</p></td>
                                        <td><p>{item.policyPremium > 0 ? parseInt(item.policyPremium).toLocaleString() : item.policyPremium}</p></td>
                                        <td><p>{item.policyPVTPremium > 0 ? parseInt(item.policyPVTPremium).toLocaleString() : item.policyPVTPremium}</p></td>
                                        <td><p>{item.policyStampDuty > 0 ? item.policyStampDuty.toLocaleString() : item.policyStampDuty}</p></td>
                                        <td><p>{item.policyTLevy > 0 ? item.policyTLevy.toLocaleString() : item.policyTLevy}</p></td>
                                        <td><p>{item.policyPHCFund > 0 ? item.policyPHCFund.toLocaleString() : item.policyPHCFund}</p></td>
                                        <td><p>{item.policyCommission > 0 ? item.policyCommission.toLocaleString() : item.policyCommission}</p></td>
                                        <td><p>{item.policyWithholdingTax > 0 ? item.policyWithholdingTax.toLocaleString() : item.policyWithholdingTax}</p></td>
                                        <td><p>{item.policyInsurerPolicyNet > 0 ? item.policyInsurerPolicyNet.toLocaleString() : item.policyInsurerPolicyNet}</p></td>
                                        <td><p>{item.receiptAmount > 0 ? item.receiptAmount.toLocaleString() : item.receiptAmount}</p></td>
                                        <td><p>{item.cummulativeOutstanding.toLocaleString()}</p></td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan='5'><p></p></td>
                                <td><p>Totals</p></td>
                                <td><p>{totalPremium.toLocaleString()}</p></td>
                                <td><p>{totalPVTPremium.toLocaleString()}</p></td>
                                <td><p>{totalStampDuty.toLocaleString()}</p></td>
                                <td><p>{totalTLevy.toLocaleString()}</p></td>
                                <td><p>{totalPHCFund.toLocaleString()}</p></td>
                                <td><p>{totalCommission.toLocaleString()}</p></td>
                                <td><p>{totalWithholding.toLocaleString()}</p></td>
                                <td><p>{totalPolicyNet.toLocaleString()}</p></td>
                                <td><p>{totalReceipts.toLocaleString()}</p></td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <div className='footer'>
                        <center><p>SIMIA INSURANCE AGENCY | P.O BOX 623 – 30100, ELDORET | TEL: +254722968637 | EMAIL: info@simiainsurance.com</p></center>
                    </div> */}
                </div>
            </div>
        </div>
    </section>


    return (
        <section className='insurer_statement'>
            <div className='body_section_header'>
                <p>Report - Policy Class</p>
            </div>
            <div className='body_section'>
                {insurerStatementContent}
            </div>
            {filterContent}
            {loaderContent}
        </section>
    );
}

export default ReportPolicyClass;