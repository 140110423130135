const MotorCommTPOClauses = () => {
    return (
        <section>
            <p className='leading-none'>Conditions</p>
            <p className='leading-none'>Excess</p>
            <li className='leading-none'>Own damage 5% of sum insured subject to min Ksh 30,000</li>
            <li className='leading-none'>Young and new drivers Kshs 7,500</li>
            <li className='leading-none'>Theft or attempted theft therof with anti theft device 10% subject to min Ksh 20,000</li>
            <li className='leading-none'>Theft or attempted thereof without anti theft device 25% subject to minimum 20,000</li>
            <li className='leading-none'>Third party property damage Ksh 10,000</li>
             </section>
    )
}

export default MotorCommTPOClauses;