import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { doc, updateDoc, runTransaction } from "firebase/firestore";
import db from "../../../firebase";
import UUID from "../../Hooks/UUID/UUID";
import Loader from "../Loader/Loader";
import styles from './CreateExpense.module.css'
import GetCookie from "../../Hooks/Cookie/GetCookie";
import GetAllExpenses from "../../Hooks/BackendConn/Finance/GetAllExpenses";
import { useRecoilState } from 'recoil';
import { reloadViewExpenseAfterExpenseReceiptUpload } from '../../../atoms';
import GetExpenseAPI from "../../Hooks/APIConn/Expenses/GetExpenseAPI";
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from "@tabler/icons-react";

const PettyCashVoucher = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [reloadViewExpenseAfterExpenseReceiptUploadRN, setReloadViewExpenseAfterExpenseReceiptUploadRN] = useRecoilState(reloadViewExpenseAfterExpenseReceiptUpload)

    //#####################################################################
    //received prop
    const activeExpenseDocID = props.onePropUp.postedDocRef;

    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //get expenses
    const [expensesDocs, setExpensesDocs] = useState({});
    const [reload, setReload] = useState(0);
    useEffect(() => {
        setIsLoading(true)
        GetExpenseAPI(props.onePropUp.postedDocRef)
            .then(data => {
                setExpensesDocs(data);
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)
            });
    }, [props.onePropUp.postedDocRef]);

    //################################################################
    // reload on success receipt upload
    useEffect(() => {
        if (reloadViewExpenseAfterExpenseReceiptUploadRN) {
            setReload(Math.random(10000, 100000));
            setReloadViewExpenseAfterExpenseReceiptUploadRN(false);
        }
    }, [reloadViewExpenseAfterExpenseReceiptUploadRN]);

    //################################################################
    //set default
    const modeOfPaymentOptions = ['Petty Cash', 'Cash', 'Salary Advance'];
    const ExpenseAccountOptions = ['Salaries, Benefits and Wages', 'Salary Advance', 'Utilities', 'Miscellaneous', 'Petty Cash', 'Advertising and Marketing', 'Office Supplies and Stationary', 'Office Equipment', 'Furniture and Fittings', 'Telecommunication', 'Travel', 'Rent', 'Repairs and Maintenance', 'Entertainment', 'Courier Services', 'Airtime', 'Cleaning Services', 'Taxi Service', 'Fuel', 'Tea', 'Permits and Licences']

    //################################################################
    //get user input
    const [purposeOfPayment, setPurposeOfPayment] = useState('');
    const [amount, setAmount] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [paymentID, setPaymentID] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    const [receiptNo, setReceiptNumber] = useState('');
    const [paymentDate, setPaymentDate] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [createdBy, setCreatedBy] = useState({})
    const [expenseReceiptDocument, setExpenseReceiptDocument] = useState([]);

    //################################################################
    //get active expense
    useEffect(() => {
        try {

            if (Object.keys(expensesDocs).length > 0) {
                setPurposeOfPayment(expensesDocs.purposeOfPayment)
                setAmount(expensesDocs.amount)
                setModeOfPayment(expensesDocs.modeOfPayment)
                setPaymentID(expensesDocs.paymentID)
                setExpenseAccount(expensesDocs.expenseAccount)
                setReceiptNumber(expensesDocs.receiptNo)
                setPaymentDate(new Date(expensesDocs.paymentDate))
                if (expensesDocs.paymentDate) { setPaymentDate(new Date(expensesDocs.paymentDate)) }
                else { setPaymentDate(null) }
                if (expensesDocs.invoiceDate) { setInvoiceDate(new Date(expensesDocs.invoiceDate)) }
                else { setInvoiceDate(null) }
                setCreatedBy(expensesDocs.createdBy)
                setExpenseReceiptDocument(expensesDocs.expenseReceiptDocument)
            }

        } catch (error) { }
    }, [expensesDocs, props.onePropUp.postedDocRef]);

    //################################################################
    //step 2
    //show msg
    const showSuccessMSGOnPendingBillSave = (instruction) => {
        setIsLoading(false);
        let setView = '';
        let notification = '';
        if (instruction === 'Pending') {
            setView = 'Petty Cash Voucher';
            notification = 'Changes Saved';
        }
        else if (instruction === 'Cleared') {
            setView = 'Petty Cash Voucher';
            notification = 'Changes Saved & Item Cleared.';
        }

        const onePropObj = {
            setView: setView,
            previousView: '',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: notification,
        }
        props.onePropDown(onePropObj);
    }

    //################################################################
    //step 2
    //show msg
    const showFailMSGOnPendingBillSave = (instruction) => {
        setIsLoading(false);
        let setView = '';
        let notification = '';
        if (instruction === 'Pending') {
            setView = 'Petty Cash Voucher';
            notification = 'Operation Failed, Please Try Again';
        }
        else if (instruction === 'Cleared') {
            setView = 'Petty Cash Voucher';
            notification = 'Operation Failed, Please Try Again.';
        }

        const onePropObj = {
            setView: setView,
            previousView: '',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Failed To Update',
        }
        props.onePropDown(onePropObj);
    }

    //################################################################
    //step 1
    //save handler
    const saveDetailsHandler = async (instruction) => {
        fetch(`https://addmessage-7fqanz2g2q-uc.a.run.app/updateexpense?id=${props.onePropUp.postedDocRef}&purposeOfPayment=${purposeOfPayment}&amount=${amount}&modeOfPayment=${modeOfPayment}&expenseAccount=${expenseAccount}&receiptNo=${receiptNo}&status=${instruction}&paymentDate=${paymentDate}&invoiceDate=${invoiceDate}&paymentID=${paymentID}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (Object.keys(data).length > 0) {
                    if (data.modifiedCount == 1) { showSuccessMSGOnPendingBillSave(instruction); }
                    else { showFailMSGOnPendingBillSave(instruction) }
                }
            })
            .catch(error => { console.error(error) });
    }

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //content
    const content = <section className='f_7'>
        <table className='w-full table-fixed border-collapse border border-slate-500'>
            <tbody>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Expense Account (Dr) </p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        {
                            ExpenseAccountOptions.map((expenseAccountOption, index) => {
                                return (
                                    <span className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${expenseAccountOption === expenseAccount && styles.selected_insurance_classes}`} key={index} onClick={() => { setExpenseAccount(expenseAccountOption) }}>{expenseAccountOption}</span>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Mode Of Payment (Cr)</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        {
                            modeOfPaymentOptions.map((modeOfPaymentOption, index) => {
                                return (
                                    <span className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${modeOfPaymentOption === modeOfPayment && styles.selected_insurance_classes}`} key={index} onClick={() => { setModeOfPayment(modeOfPaymentOption) }}>{modeOfPaymentOption}</span>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Purpose / Description</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={purposeOfPayment || ''} onChange={(e) => { setPurposeOfPayment(e.target.value) }} placeholder='Purchase of office stationary' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Receipt / Invoice Number</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={receiptNo || ''} onChange={(e) => { setReceiptNumber(e.target.value) }} placeholder='549932' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Amount</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} placeholder='1,000' className={styles.veh_input} />
                    </td>
                </tr>
                <tr className={`${modeOfPayment === 'Cash' && styles.create_expense_hide_component}`}>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Payment ID / Cheque No</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={paymentID || ''} onChange={(e) => { setPaymentID(e.target.value) }} placeholder='Cheque Number / Petty Cash Vourcher Number' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Receipt Document</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        {
                            Object.keys(expenseReceiptDocument).length > 0 &&
                            Object.keys(expenseReceiptDocument).map((docKey, index) => {
                                return <i onClick={() => openInNewTab(expenseReceiptDocument[docKey]['expenseReceiptDocument'])} key={index} className='ri-attachment-2'></i>
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Payment Date</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <div className='mantine_main_content_table_date_picker'>
                            <DatePickerInput
                                dropdownType="modal"
                                placeholder="Pick date"
                                value={paymentDate}
                                onChange={setPaymentDate}
                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                maw={400}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Invoice Date</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <div className='mantine_main_content_table_date_picker'>
                            <DatePickerInput
                                dropdownType="modal"
                                placeholder="Pick date"
                                value={invoiceDate}
                                onChange={setInvoiceDate}
                                maw={400}
                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>


    return (
        <section>
            <div className='body_section_header'>
                <p>Petty Cash Voucher</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { saveDetailsHandler('Cleared') }}>Save Changes</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { saveDetailsHandler('Pending') }}>Restore To Pending</p> */}
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
        </section>
    )
}

export default PettyCashVoucher;