import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { policyListGridFilterFromDate, policyListGridFilterToDate } from "../../../../atoms";
import DatePicker from "react-date-picker";

const PolicyListGridFilter = () => {
    //################################################################
    //valuation reports filter
    const [policyListGridFilterFromDateRN, setPolicyListGridFilterFromDateRN] = useRecoilState(policyListGridFilterFromDate)
    const [policyListGridFilterToDateRN, setPolicyListGridFilterToDateRN] = useRecoilState(policyListGridFilterToDate)


    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_15'></div>
            <p className='bold'>Filter Date</p>
            <div className='h_5'></div>
            <div className='lapse_date_picker_container'>
                <p>From</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyListGridFilterFromDateRN} value={policyListGridFilterFromDateRN} format='d/ M/y' />
            </div>
            <div className='h_10'></div>
            <div className='lapse_date_picker_container'>
                <p>To</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyListGridFilterToDateRN} value={policyListGridFilterToDateRN} format='d/ M/y' />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
        </section>
    )
}

export default PolicyListGridFilter;