import React, { useRef, useState } from "react";
import Loader from "../../Body/Loader/Loader";
import { doc, updateDoc, runTransaction } from "firebase/firestore";
import db from "../../../firebase";
import { useRecoilValue, useRecoilState } from "recoil";
import { DatePickerInput } from "@mantine/dates";
import { leaveApplicationActiveEmployee, employeeProfileReloaderLeaveRequest } from "../../../atoms";

const LeaveApplication = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = "";
    if (isLoading === true) {
        loaderContent = <Loader />;
    }

    //################################################################
    // recoil values
    const leaveApplicationActiveEmployeeRN = useRecoilValue(leaveApplicationActiveEmployee);
    const [employeeProfileReloaderLeaveRequestRN, setEmployeeProfileReloaderLeaveRequestRN] = useRecoilState(employeeProfileReloaderLeaveRequest);

    //################################################################
    // get user input
    const [dateRange, setDateRange] = useState([]);
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('')

    //################################################################
    // step 2
    // show msg
    const showSuccessLeaveReqMsg = () => {
        setIsLoading(false);
        setEmployeeProfileReloaderLeaveRequestRN(true);
        const onePropObj = {
            setView: 'Employee Profile',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Leave Request Submitted Pending Approval.'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    // step 1
    // save leave record
    const [newPendingLeaveRecord, setNewPendingLeaveRecords] = useState({})
    const createLeaveDay = async () => {
        try {

            setIsLoading(true);

            const sfDocRef = doc(db, "EmployeeDetails", "hsPhw4lkUuDuR8jgABkH");

            let givenReason = '';
            if (reason === 'Other') {
                givenReason = otherReason
            }
            else {
                givenReason = reason
            }

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const allData = sfDoc.data();
                const employeeData = allData[leaveApplicationActiveEmployeeRN];

                if (Object.keys(allData).length > 0) {
                    // if (Object.keys(employeeData).length > 0) {
                    if(Object.keys(employeeData).length > 0) {
                        const previousPendingLeaveRecord = employeeData.pendingLeaveRequest;

                        if(previousPendingLeaveRecord.length > 0) {
                            setNewPendingLeaveRecords({
                                leaveDate: [...dateRange, ...previousPendingLeaveRecord],
                                reason: givenReason,
                            })
                        } else {
                            setNewPendingLeaveRecords({
                                leaveDate: [...dateRange],
                                reason: givenReason
                            })
                        }
                    
                        const objKey = leaveApplicationActiveEmployeeRN + '.pendingLeaveRequest'
                        transaction.update(sfDocRef, { [objKey]: newPendingLeaveRecord });

                        showSuccessLeaveReqMsg()

                    }
                }
            });
        } catch (e) { console.log("Transaction failed: ", e); }
    }


    //################################################################
    // crete insurer Content
    const createInsurerContent =
        <section className="doc_upload">
            <div className="right_nav_content">
                <p className='bold'>Leave Application</p>
                <div className="h_8"></div>
                <div className="mantine-right-nav-modal-date-picker">
                    <DatePickerInput
                        type="multiple"
                        icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                        dropdownType="modal"
                        label="Pick Leave Days"
                        placeholder="Pick date"
                        value={dateRange}
                        onChange={setDateRange}
                        minDate={new Date()}
                        firstDayOfWeek="0"
                        weekendDays="DayOfWeek[1-5]"
                        hideOutsideDates="true"
                        mx="left"
                        maw={170}
                    />                    
                </div>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { setReason(e.target.value) }}>
                        <option>Select Option</option>
                        <option value='Sick'>Sick</option>
                        <option value='Bereavement'>Bereavement</option>
                        <option value='Unpaid'>Unpaid</option>
                        <option value='Personal Leave'>Personal Leave</option>
                        <option value='Maternity / paternity'>Maternity / paternity</option>
                        <option value='Other'>Other</option>
                    </select>
                    <label className='placeholder'><p>Reason</p></label>
                </div>
                {
                    reason === 'Other' &&
                    <div>
                        <div className='h_10'></div>
                        <div className='input_group'>
                            <input onChange={(e) => { setOtherReason(e.target.value) }} type='text' className='input' placeholder='' />
                            <label className='placeholder'><p>Narration</p></label>
                        </div>
                    </div>
                }
                <div className="h_5"></div>
                    <button className="btn_pink" onClick={createLeaveDay}>Submit</button>

            </div>
        </section>


    return (
        <section className="right_nav_container body_input_styles">
            {createInsurerContent}
            {loaderContent}
        </section>
    );
};

export default LeaveApplication;
