import db from '../../../../firebase';
import { collection, getDocs, where, query, getDoc, doc } from 'firebase/firestore';

const SearchSpecificPolicy = async (searchTerm) => {
    const DData = []

    //query 1
    const q = query(collection(db, "PolicyDetails"), where("policyNumber", "==", searchTerm));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        DData.push({ docData: doc.data(), docID: doc.id })
    });

    //query 2
    const b = query(collection(db, "PolicyDetails"), where("endorsmentNumber", "==", searchTerm));

    const querySnapshotb = await getDocs(b);

    querySnapshotb.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        DData.push({ docData: doc.data(), docID: doc.id })
    });

    //query 3
    if (!searchTerm.includes('/')) {
        const docRef = doc(db, "PolicyDetails", searchTerm);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            DData.push({ docID: searchTerm, docData: docSnap.data() })

        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    }

    return DData;
    
}

export default SearchSpecificPolicy;