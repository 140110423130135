import React, { useState, useRef } from "react";
import db from "../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import Loader from "../Loader/Loader";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import UUID from "../../Hooks/UUID/UUID";
import styles from './CreateActionMemo.module.css';

const CreateActionMemo = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // get user input
    const [inputError, setInputError] = useState(false);

    const titleRef = useRef(null);
    const actionNeededRef = useRef(null);

    //################################################################
    // create action memo
    //step 2
    const changeViewOnSuccessActionMemoCreation = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Open Action Memo',
            previousView: 'Create Action Memo',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Action Memo Created'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const createActionMemoConn = async () => {
        setIsLoading(true);

        const actionMemoObj = {
            title: titleRef.current.value,
            actionNeeded: actionNeededRef.current.value,
            status:'Open',
            createdBy:currentUser,
            dateCreated:new Date(),
        }

        const sfDocRef = doc(db, "ActionMemo", "FXjncWTtNGyrkbowKMVs");

        try {

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //get unique id
                const actionMemoID = UUID() + ' - ' + UUID();

                transaction.update(sfDocRef, { [actionMemoID]: actionMemoObj });
            });
            //console.log("Transaction successfully committed!");
            changeViewOnSuccessActionMemoCreation();

        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //step 1
    const createActionMemoHandler = () => {
        if (!titleRef.current.value || !actionNeededRef.current.value) {
            setInputError(true);
            return false;
        }

        setIsLoading(true);
        createActionMemoConn();

    }

    const content = <section className={styles.f_1}>
        <table className='w-full table-fixed border-collapse border border-slate-500'>
            <tbody>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p>Enter Title</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input className={`${styles.action_memo_input} + ${inputError && styles.input_error}`} ref={titleRef} name='attr1CAM' placeholder='Missing Debit Note' />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p>Enter Action Needed</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <textarea rows={4} className={`${styles.action_memo_input} + ${inputError && styles.input_error}`} ref={actionNeededRef} name='attr2CAM' placeholder='Follow Up On Debit Note For Eldoret Club Policy No: 03/COMP/01/0700/3425/2023 Endorsment No: New' />
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Create Action Memo</p>
                <button onClick={createActionMemoHandler} type='submit' className='btn_pink'>Save Details</button>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
        </section>
    );
}

export default CreateActionMemo;