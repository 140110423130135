import React, { useState, useEffect, useRef, useCallback } from 'react';
import Loader from '../Loader/Loader';
import GetBusinessType from '../../Hooks/BackendConn/InsurerSpecific/GetBusinessType';
import { useRecoilValue } from 'recoil';
import { DVListInsurerFilter, DVListFilterFromDate, DVListFilterToDate } from '../../../atoms';
import './DVList.css';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';

const DVList = (props) => {
    //################################################################
    //recoil values
    const DVListInsurerFilterRN = useRecoilValue(DVListInsurerFilter)
    const DVListFilterFromDateRN = useRecoilValue(DVListFilterFromDate)
    const DVListFilterToDateRN = useRecoilValue(DVListFilterToDate)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "DV List.xlsx");
    }, [tbl]);

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVListInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, DVListInsurerFilterRN]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDVDocs, setSelectedPeriodFilteredDVDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        DVDocs.map((doc) => {
            //calculate next inception
            const newInsurerDebitDate = new Date(doc.docData.insurerDebitDate);
            newInsurerDebitDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(newInsurerDebitDate).valueOf() >= new Date(DVListFilterFromDateRN).valueOf() && new Date(DVListFilterToDateRN).valueOf() >= new Date(newInsurerDebitDate).valueOf()) {
                periodSelected.push(doc);
            }
        });
        setSelectedPeriodFilteredDVDocs(periodSelected);
    }, [DVDocs, DVListFilterFromDateRN, DVListFilterToDateRN]);

    //################################################################
    //sort by date
    const [dateSortedDVDocs, setDateSortedDVDocs] = useState([])
    useEffect(() => {
        const sortedList = selectedPeriodFilteredDVDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.insurerDebitDate).valueOf() - new Date(b.docData.insurerDebitDate).valueOf();
        }
        setDateSortedDVDocs(sortedList);
    }, [selectedPeriodFilteredDVDocs])

    //################################################################
    //filter open claims
    const [dateFilteredDVDocs, setDateFilteredDVDocs] = useState([])
    useEffect(() => {
        const a = dateSortedDVDocs.filter(doc => new Date(doc.docData.insurerDebitDate).valueOf() >= new Date(DVListFilterFromDateRN).valueOf() && new Date(doc.docData.insurerDebitDate).valueOf() <= new Date(DVListFilterToDateRN).valueOf());
        setDateFilteredDVDocs(a)
    }, [dateSortedDVDocs, DVListFilterFromDateRN, DVListFilterToDateRN])

    //################################################################
    // search open claims
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedDVDocs, setSearchedDVDocs] = useState([])
    useEffect(() => {
        const c = [];
        const docs = dateFilteredDVDocs.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        });
        setSearchedDVDocs(c);
    }, [dateFilteredDVDocs, searchTerm])

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################
    //view dv
    const viewDVHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View DV',
            previousView: 'DV List',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewDVHandler}><p>View DV</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //echo
    const DVListContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search DV No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='DVList_table' ref={tbl}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>DV Number</p></td>
                                <td><p>Narration</p></td>
                                <td><p>Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedDVDocs.map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.insurerDebitDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.locationOfTheRisk}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{NumberFormat(doc.docData.policyNet)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo
    const DVListContentPrintable = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='DVList_table DVList_table_printable' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>DV LIST</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>PERIOD : {DateFormat(DVListFilterFromDateRN)} - {DateFormat(DVListFilterToDateRN)}</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>INSURER : {DVListInsurerFilterRN}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>DV Number</p></td>
                                <td><p>Narration</p></td>
                                <td><p>Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedDVDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{DateFormat(doc.docData.insurerDebitDate)}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.locationOfTheRisk}</p></td>
                                            <td><p>{NumberFormat(doc.docData.policyNet)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='DVList'>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>DV List</p>
                    <div className='right_side_controls'>
                        <span className='btn_pink' onClick={exportFile} >
                            <i className="ri-file-excel-2-line"></i>
                        </span>
                        <div className='w_5'></div>
                        <span className='btn_pink' onClick={handlePrint}>
                            <i className="ri-printer-line"></i>
                        </span>
                    </div>
                </div>
                <div className='body_section'>
                    {DVListContent}
                    {DVListContentPrintable}
                </div>
            </section>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default DVList;