import React, { useState, useEffect } from "react";
import styles from './TextNavMain.module.css';
import GetCookie from "../Hooks/Cookie/GetCookie";
import GetAllEmployeeDetails from "../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import Loader from "../Body/Loader/Loader";

const TextNavMain = (props) => {
  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }

  //################################################################
  //current user
  const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

  //################################################################
  // change view 
  const changeViewOnClick = (instruction) => {
    const onePropObj = {
      setView: instruction,
      previousView: '',
      postedDocRef: '',
      dateFrom: '',
      dateTo: '',
      searchTerm: '',
    }
    props.onePropDown(onePropObj)
  }

  //################################################################
  // get all employees
  const [allEmployees, setAllEmployees] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAllEmployeeDetails();
      setAllEmployees(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReload(Math.random(0, 10000));
      }
    })();
  }, [reload]);

  //################################################################
  //active employee
  const [activeEmployeeProfile, setActiveEmployeeProfile] = useState({});
  useEffect(() => {
    try {
      const employee = allEmployees.filter(
        (doc) => doc.docData.email === currentUser.userEmail
      );
      if (employee.length > 0) {
        setActiveEmployeeProfile(employee[0]);
      }
    } catch (error) { }
  }, [activeEmployeeProfile, allEmployees]);

  //################################################################
  //content
  const content = (
    <section>
      <div className={`${styles.sidebar}`}>
        <div className={`${styles.sidebar_menu}`}>

          <li className={styles.item} id="profile">
            <a href="#profile" className={styles.menu_btn}>
              <i className="ri-dashboard-fill pr-2"></i>
              <span>Dashboard</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['Calendar'] &&
                <div onClick={() => changeViewOnClick("Calendar")}><p>Calendar</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['Insurer Portfolio'] &&
                <div onClick={() => changeViewOnClick("Insurer Portfolio")}><p>Insurer Portfolio</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['Policy Categories'] &&
                <div onClick={() => changeViewOnClick("Policy Categories")}><p>Policy Categories</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['Productivity Report'] &&
                <div onClick={() => changeViewOnClick("Productivity Report")}><p>Productivity Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['System Logs'] &&
                <div onClick={() => changeViewOnClick("System Logs")}><p>System Logs</p></div>
                //<div onClick={() => changeViewOnClick("Demo")}><p>System Logs</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Dashboard']['Access Control'] &&
                <div onClick={() => changeViewOnClick("Access Control")}><p>Access Control</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="underwriting">
            <a href="#underwriting" className={styles.menu_btn}>
              <i className="ri-draft-fill pr-2"></i>
              <span>Underwriting</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Create Client'] &&
                <div onClick={() => changeViewOnClick("Create Client")}><p>Create Client</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Client List'] &&
                <div onClick={() => changeViewOnClick("Client List")}><p>Client List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Client Group'] &&
                <div onClick={() => changeViewOnClick("Client Group")}><p>Client Group</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Create New Policy'] &&
                <div onClick={() => changeViewOnClick("Create Policy")}><p>Create New Policy</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Policy List'] &&
                <div onClick={() => changeViewOnClick("Policy List Grid")}><p>Policy List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Pending List'] &&
                <div onClick={() => changeViewOnClick("Pending List Grid")}><p>Pending List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Lapsed Policies'] &&
                <div onClick={() => changeViewOnClick("Lapsed Policies")}><p>Lapsed Policies</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Cancelled Policies'] &&
                <div onClick={() => changeViewOnClick("Cancelled Policies")}><p>Cancelled Policies</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Search Policy'] &&
                <div onClick={() => changeViewOnClick("Policy Search")}><p>Search Policy</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Comesa Policies'] &&
                <div onClick={() => changeViewOnClick("Comesa Policies")}><p>Comesa Policies</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Renewed Report'] &&
                <div onClick={() => changeViewOnClick("Renewed Report")}><p>Renewed Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Unrenewed Report'] &&
                <div onClick={() => changeViewOnClick("Unrenewed Report")}><p>Unrenewed Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Create Quote'] &&
                <div onClick={() => changeViewOnClick("Create Quote")}><p>Create Quote</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Quotes List'] &&
                <div onClick={() => changeViewOnClick("Quotes List")}><p>Quotes List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Create Action Memo'] &&
                <div onClick={() => changeViewOnClick("Create Action Memo")}><p>Create Action Memo</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Open Action Memo'] &&
                <div onClick={() => changeViewOnClick("Open Action Memo")}><p>Open Action Memo</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Underwriting']['Closed Action Memo'] &&
                <div onClick={() => changeViewOnClick("Closed Action Memo")}><p>Closed Action Memo</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="accounts">
            <a href="#accounts" className={styles.menu_btn}>
              <i className="ri-meteor-fill pr-2"></i>
              <span>Accounts</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Receipt Posting'] &&
                <div onClick={() => changeViewOnClick("Post New Receipt")}><p>Receipt Posting</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Receipt List'] &&
                <div onClick={() => changeViewOnClick("Receipt List")}><p>Receipt List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Real Time Balance'] &&
                <div onClick={() => changeViewOnClick("Real Time Balance")}><p>Real Time Balance</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Credit Note Allocations'] &&
                <div onClick={() => changeViewOnClick("Credit Note Allocations")}><p>Credit Note Allocations</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Agewise Outstanding'] &&
                <div onClick={() => changeViewOnClick("Agewise Outstanding")}><p>Agewise Outstanding</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Client Statement'] &&
                <div onClick={() => changeViewOnClick("Client Statement")}><p>Client Statement</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Email Client Statement'] &&
                <div onClick={() => changeViewOnClick("Email Client Statement")}><p>Email Client Statement</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Insurer Statement'] &&
                <div onClick={() => changeViewOnClick("Insurer Statement Grid")}><p>Insurer Statement</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Agent Statement'] &&
                <div onClick={() => changeViewOnClick("Agent Statement")}><p>Agent Statement</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Direct Collections Unrem'] &&
                <div onClick={() => changeViewOnClick("Direct Collections Unremmitted")}><p>Direct Collections Unrem</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Direct Collections Rem'] &&
                <div onClick={() => changeViewOnClick("Direct Collections Remmitted")}><p>Direct Collections Rem</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Commission Request'] &&
                <div onClick={() => changeViewOnClick("Commission Request")}><p>Commission Request</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Commissions Report'] &&
                <div onClick={() => changeViewOnClick("Commission Report Final")}><p>Commissions Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Post DV'] &&
                <div onClick={() => changeViewOnClick("Post DV")}><p>Post DV</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['DV List'] &&
                <div onClick={() => changeViewOnClick("DV List")}><p>DV List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['DV Allocation'] &&
                <div onClick={() => changeViewOnClick("DV Allocation")}><p>DV Allocation</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['DV Reallocation'] &&
                <div onClick={() => changeViewOnClick("DV Reallocation")}><p>DV Reallocation</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['View DV Allocation'] &&
                <div onClick={() => changeViewOnClick("View DV Allocation")}><p>View DV Allocation</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Post CN'] &&
                <div onClick={() => changeViewOnClick("Post CN")}><p>Post CN</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['CN List'] &&
                <div onClick={() => changeViewOnClick("CN List")}><p>CN List</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['Post JV'] &&
                <div onClick={() => changeViewOnClick("Post JV")}><p>Post JV</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Accounts']['JV List'] &&
                <div onClick={() => changeViewOnClick("JV List")}><p>JV List</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="claims">
            <a href="#claims" className={styles.menu_btn}>
              <i className="ri-open-source-fill pr-2"></i>
              <span>Claims</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Claims']['Create New Claim'] &&
                <div onClick={() => changeViewOnClick("Register A Claim")}><p>Create New Claim</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Claims']['Open Claims'] &&
                <div onClick={() => changeViewOnClick("Open Claims")}><p>Open Claims</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Claims']['Closed Claims'] &&
                <div onClick={() => changeViewOnClick("Closed Claims")}><p>Closed Claims</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="reports">
            <a href="#reports" className={styles.menu_btn}>
              <i className="ri-bar-chart-box-fill pr-2"></i>
              <span>Reports</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Dispatch'] &&
                <div onClick={() => changeViewOnClick("Accounts Clearance")}><p>Dispatch</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Policy Document'] &&
                <div onClick={() => changeViewOnClick("Policy Documents Report")}><p>Policy Documents</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Valuation Reports'] &&
                <div onClick={() => changeViewOnClick("Valuation Reports")}><p>Valuation Reports</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Logbook Report'] &&
                <div onClick={() => changeViewOnClick("Logbook Report")}><p>Logbook Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Sticker Copy Report'] &&
                <div onClick={() => changeViewOnClick("Sticker Copy Report")}><p>Sticker Copy Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Motor Report'] &&
                <div onClick={() => changeViewOnClick("Conclusive Motor Report")}><p>Motor Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Received Documents'] &&
                <div onClick={() => changeViewOnClick("Received Documents Report")}><p>Received Documents</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Reports']['Business Report'] &&
                <div onClick={() => changeViewOnClick("Business Report")}><p>Business Report</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="hr">
            <a href="#hr" className={styles.menu_btn}>
              <i className="ri-open-source-fill pr-2"></i>
              <span>Human Resource</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Create Employee'] &&
                <div onClick={() => changeViewOnClick("Create Employee")}><p>Create Employee</p></div>
              }
               {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Biometrics Data'] &&
                <div onClick={() => changeViewOnClick("Biometrics Data")}><p>Biometrics Data</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Employee Details'] &&
                <div onClick={() => changeViewOnClick("Human Resource")}><p>Employee Details</p></div>
              }
              {/* {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Payrole Settings'] &&
                <div onClick={() => changeViewOnClick("Payrole Settings")}><p>Payrole Settings</p></div>
              } */}
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Generate Payroll'] &&
                <div onClick={() => changeViewOnClick("Generate Payroll")}><p>Generate Payroll</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Human Resource']['Payroll'] &&
                <div onClick={() => changeViewOnClick("Payroll")}><p>Payroll</p></div>
              }
            </div>
          </li>
          <li className={styles.item} id="finance">
            <a href="#finance" className={styles.menu_btn}>
              <i className="ri-open-source-fill pr-2"></i>
              <span>Finance</span>{" "}
              <i className={`fas fa-chevron-down  ${styles.drop_down}`}></i>
            </a>
            <div className={styles.sub_menu}>
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Create Expense'] &&
                <div onClick={() => changeViewOnClick("Create Expense")}><p>Create Expense</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Petty Cash Report'] &&
                <div onClick={() => changeViewOnClick("Petty Cash Report")}><p>Petty Cash Report</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Vendor Profile'] &&
                <div onClick={() => changeViewOnClick("Vendor Profile")}><p>Vendor Profile</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Purchase Order'] &&
                <div onClick={() => changeViewOnClick("Purchase Order")}><p>Create Invoice</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Post Payment'] &&
                <div onClick={() => changeViewOnClick("Post Payment")}><p>Post Payment</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Vendor Statement'] &&
                <div onClick={() => changeViewOnClick("Vendor Statement")}><p>Vendor Statement</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Expenses List'] &&
                <div onClick={() => changeViewOnClick("Expenses List")}><p>Expenses List</p></div>
              }
              {/* {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Income'] &&
                <div onClick={() => changeViewOnClick("Income")}><p>Income</p></div>
              } */}
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Profit & Loss'] &&
                <div onClick={() => changeViewOnClick("Profit & Loss")}><p>Profit & Loss</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Finance Controls'] &&
                <div onClick={() => changeViewOnClick("Finance Controls")}><p>Finance Controls</p></div>
              }
              {
                Object.keys(activeEmployeeProfile).length > 0 &&
                activeEmployeeProfile['docData']['accessControl']['Finance']['Bank Recon'] &&
                <div onClick={() => changeViewOnClick("Bank Recon")}><p>Bank Recon</p></div>
              }
            </div>
          </li>
        </div>
      </div>
    </section>
  );

  return (
    <section>
      {content}
      {loaderContent}
    </section>
  );
}

export default TextNavMain;