const CapitalizedLetterOne = (str) => {

    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join the words back into a new string
    const result = capitalizedWords.join(' ');

    return result;

}

export default CapitalizedLetterOne;