import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import db from "../../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import Loader from "../../../Body/Loader/Loader";
import { useRecoilValue, useRecoilState } from "recoil";
import { clientGroupSelection, reloadOnSuccessClientGroupChangeHandler, clientGroupSelectionPrintSchedule } from "../../../../atoms";
import GetAllClientGroups from "../../../Hooks/BackendConn/ClientList/GetAllClientGroups";


const ClientGroupDetailView = (props) => {
    //################################################################
    //recoil values
    const clientGroupSelectionRN = useRecoilValue(clientGroupSelection);
    const [clientGroupSelectionPrintScheduleRN, setClientGroupSelectionPrintScheduleRN] = useRecoilState(clientGroupSelectionPrintSchedule)
    const [reloadOnSuccessClientGroupChangeHandlerRN, setReloadOnSuccessClientGroupChangeHandlerRN] = useRecoilState(reloadOnSuccessClientGroupChangeHandler)

    
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get client groups
    const [clientGroups, setClientGroups] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClientGroups();
            setClientGroups(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    // order client groups aphabetically
    const [aphabetClientGroup, setAlpabetClientGroup] = useState([]);
    useEffect(() => {
        const a = clientGroups.sort(byName);
        function byName(a, b) {
            if (a.docData.groupTitle > b.docData.groupTitle) {
                return 1;
            }
            else if (b.docData.groupTitle > a.docData.groupTitle) {
                return -1;
            }
            else { return 0 }
        }
        setAlpabetClientGroup(a)
    }, [clientGroups])

    //################################################################
    // get selected client group
    const [selectedClientGroup, setSelectedClientGroup] = useState({})
    useEffect(() => {
        const a = clientGroups.filter(group => group.docID === clientGroupSelectionRN.docData.group)
        if (a.length > 0) {
            setSelectedClientGroup(a[0])
        }
        else { setSelectedClientGroup({ docData: { groupTitle: "UNGROUPED" } }) }

    }, [clientGroups, clientGroupSelectionRN])


    //################################################################
    // step 2
    // change view
    const changeViewOnSuccessfulClientGroupUpdate = () => {
        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client Group Updated Successfully'
        }
        props.onePropDown(onePropObj);
        setReloadOnSuccessClientGroupChangeHandlerRN(true);

    }

    //################################################################
    // step 1
    // add client to group
    const [newGroup, setNewGroup] = useState('');
    const updateClientGroup = async () => {
        setIsLoading(true);
        try {

            const docRef = doc(db, "ClientDetails", clientGroupSelectionRN.docID);
            await updateDoc(docRef, {
                group: newGroup
            });
            changeViewOnSuccessfulClientGroupUpdate();

        } catch (error) {

        }
        setIsLoading(false);

    }

    const updateClientGroupHandler = async (specificEdit) => {
        const docRef = doc(db, "ClientData", "JbrVkeL56d5lrA8R2gdF");

        await updateDoc(docRef, {
            [specificEdit]: newGroup,
        });
    }

    const as = () => {
        try {

            if (Object.keys(clientGroupSelectionRN).length > 0 && newGroup) {
                let asd = { ...clientGroupSelectionRN }
                let asadf = { ...asd['docData'] }
                let clientID = asd['docID']

                asadf['group'] = newGroup;
                
                const specificEdit = clientID + '.group';                              

                updateClientGroupHandler(specificEdit)
                .then(()=>{changeViewOnSuccessfulClientGroupUpdate()})
                .catch(error => {});

            }

        } catch (error) { console.log(error) }
    }

    //################################################################
    // echo content
    const createClientGroupContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Client Details</p>
            <div className='h_5'></div>
            <div>
                {
                    clientGroupSelectionRN &&
                    <div>
                        <span className='border px-1.5 py-0.5 border-white rounded-sm text-[10px]'>Name</span>
                        <div className='h_2 '></div>
                        <p>{clientGroupSelectionRN.docData.clientName}</p>
                        <div className='h_5'></div>
                        <span className='border px-1.5 py-0.5 border-white rounded-sm text-[10px]'>Group</span>
                        <div className='h_2'></div>
                        {
                            Object.values(selectedClientGroup).length > 0 && <span>{selectedClientGroup.docData.groupTitle}</span>
                        }

                        <div className='h_5'></div>
                        <span className='border px-1.5 py-0.5 border-white rounded-sm text-[10px]'>Controls</span>
                        <div className='h_8'></div>
                        <span className='btn_pink' onClick={() => { setClientGroupSelectionPrintScheduleRN(Math.random(0, 100000)) }}>Renewal Schedule</span>
                        {/* <div className='h_10'></div>
                        <span className='btn_pink'>Client Statement</span> */}

                    </div>
                }
            </div>

            <div className='h_15'></div>
            <div className='separator'></div>
            <div className='h_3'></div>
            <p className='bold'>Update Client Group</p>
            <div className='h_15'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setNewGroup(e.target.value) }}>
                    <option>Select Group</option>
                    {aphabetClientGroup.map((group, index) => {
                        return (
                            <option key={index} value={group.docID}>{group.docData.groupTitle}</option>
                        )
                    })}
                </select>
                <label className='placeholder'><p>Select Group</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={as} className='btn_pink'>Submit</button>

        </div>
    </section >

    return (
        <section className='right_nav_container body_input_styles'>
            {createClientGroupContent}
            {loaderContent}
        </section>
    );
}

export default ClientGroupDetailView;