import React, { useState, useEffect } from "react";
import { doc, updateDoc, runTransaction } from 'firebase/firestore';
import db from "../../../../firebase";
import DatePicker from "react-date-picker";
import Loader from "../../../Body/Loader/Loader";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import { useRecoilState } from "recoil";
import { pageReloaderHandler, rightNavContent } from "../../../../atoms";
import GetCookie from "../../../Hooks/Cookie/GetCookie";


const RemitReceiptToInsurer = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    // recoil values
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get user input
    const [receiptNumber, setReceiptNumber] = useState('')
    const [receiptDate, setReceiptDate] = useState(new Date());
    const [oldreceiptDateFormat, setReceiptDateFormat] = useState(new Date());
    const [insurerAccount, setInsurerAccount] = useState('')

    //################################################################
    //old receipt date format
    const Ryear = new Date(receiptDate).getFullYear();
    const Rmonth = new Date(receiptDate).getMonth() + 1;
    const Rdate = new Date(receiptDate).getDate();
    const receiptDateCleaned = Ryear + '-' + Rmonth + '-' + Rdate;

    useEffect(() => {
        setReceiptDateFormat(receiptDateCleaned)
    }, [receiptDateCleaned])

    //################################################################
    //show msg
    //step 3
    const onSuccessReceiptRemit = () => {
        const onePropObj = {
            setView: 'View Receipt',
            previousView: 'View Receipt',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Receipt Remited To Insurer.'
        }
        setRightNavContentRN('View Receipt')
        setPageReloaderHandlerRN(onePropObj)
        props.onePropDown(onePropObj)
    }

    //################################################################
    //remit receipt
    //step 1
    async function a() {
        setIsLoading(true);
        const sfDocRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const newReceiptDetails = sfDoc.data();

                const remitanceDetails = {
                    prevReceiptDate:newReceiptDetails.receiptDate,
                    prevReceiptNumber:newReceiptDetails.receiptNumber,
                    prevReceiptAccount:newReceiptDetails.paidTo,
                    remitedBy:currentUser,
                }

                const newObj = {
                    clientName: newReceiptDetails.clientName,
                    receiptDate: oldreceiptDateFormat,
                    newReceiptDate: receiptDate,
                    receiptNumber: receiptNumber,
                    receiptAmount: newReceiptDetails.receiptAmount,
                    paidBy: newReceiptDetails.paidBy,
                    paidTo: insurerAccount,
                    transactionType: newReceiptDetails.transactionType,
                    receiptAllocationDetails: newReceiptDetails.receiptAllocationDetails,
                    remitanceDetails:remitanceDetails,
                }

                transaction.update(sfDocRef, newObj);

            });
            console.log("Transaction successfully committed!");
            setIsLoading(false);
            onSuccessReceiptRemit();

        } catch (e) {
            console.log("Transaction failed: ", e);
        }

    }

    //################################################################
    //content 
    const remitReceiptToInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Receipt Details</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setInsurerAccount(e.target.value) }} value={insurerAccount}>
                    <option>Select Insurer Account</option>
                    {
                        InsurerDetails.map((doc, index) => {
                            return (
                                <option key={index}>{doc.docData.insurer}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Insurer Account</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setReceiptNumber(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Receipt Number</p></label>
            </div>
            <div className='h_5'></div>
            <div className='lapse_date_picker_container'>
                <p>Receipt Date</p>
                <div className='h_5'></div>
                <DatePicker onChange={setReceiptDate} value={receiptDate} format='d/ M/y' />
            </div>
            <div className='h_5'></div>
            <button onClick={a} className='btn_pink'>Remit Receipt</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {remitReceiptToInsurerContent}
            {loaderContent}
        </section>
    );
}

export default RemitReceiptToInsurer;