import React, { useState, useEffect, useRef } from "react";
import { MonthPicker } from "@mantine/dates";
import Loader from "../../Body/Loader/Loader";
import { Group, em } from "@mantine/core";
import { useRecoilState } from "recoil";
import { payroleGeneratorSelectedDate } from "../../../atoms";
import GetAllEmployeeDetails from "../../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import GetAllPayroleSettings from "../../Hooks/BackendConn/HR/GetAllPayroleSettings";
import { useReactToPrint } from "react-to-print";
import styles from "./GeneratePayroll.module.css"
import companyLogo from "../../Assets/img/logo.png"
import { async, keys } from "pdfmake/build/pdfmake";
import DateFormat from "../../Hooks/UI/DateFormat";


const GeneratePayrole = () => {
  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }

  //################################################################
  // printing
  const componentRef = useRef();
  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  //################################################################
  // get employee details
  const [employeeDocs, setEmployeeDocs] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAllEmployeeDetails();
      setEmployeeDocs(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReload(Math.random(0, 10000));
      }
    })();
  }, [reload]);

  //################################################################
  // get payroll settings
  const [payrollSettings, setPayrollSetting] = useState([])
  const [reloadPayroll, setReloadPayroll] = useState(0)
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const DDocs = await GetAllPayroleSettings();
      setPayrollSetting(DDocs)
      if (DDocs) {
        setIsLoading(false)
      } else {
        setReloadPayroll(Math.random(0, 10000))
      }
    })()
  }, [reloadPayroll])

  //################################################################
  // recoil values
  const [payroleGeneratorSelectedDateRN, setPayroleGeneratorSelectedDateRN] =
    useRecoilState(payroleGeneratorSelectedDate);

  //################################################################
  // Drop down handler
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  // Get employee information
  const [employeeBasicSalary, setEmployeeBasicSalary] = useState('');
  const [employeeName, setEmployeeName] = useState('')
  const [employeeKraPin, setEmployeeKraPin] = useState('')
  const [employeeNssfNo, setEmployeeNssfNo] = useState('')
  const [employeeNhifNo, setEmployeeNhifNo] = useState('')
  const [employeeIdNo, setEmployeeIdNo] = useState('')
  const [employeeAccountName, setEmployeeAccountName] = useState('')
  const [employeeBankAccountNo, setEmployeeBankAccountNo] = useState('')
  const [employeeBankName, setEmployeeBankName] = useState('')
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState()

  useEffect(() => {
    const employeeSalary = employeeDocs.filter(
      (doc) => doc.docData.name === selectedEmployee
    );
    console.log(employeeSalary)
    if (selectedEmployee.length > 0 && employeeSalary.length > 0) {
      setEmployeeBasicSalary(employeeSalary[0].docData.basicSalary);
      setEmployeeName(employeeSalary[0].docData.name)
      setEmployeeKraPin(employeeSalary[0].docData.kraPin)
      setEmployeeNssfNo(employeeSalary[0].docData.nssfNo)
      setEmployeeNhifNo(employeeSalary[0].docData.nhifNo)
      setEmployeeIdNo(employeeSalary[0].docData.idNo)
      setEmployeeAccountName(employeeSalary[0].docData.bankAccName)
      setEmployeeBankAccountNo(employeeSalary[0].docData.bankAccNo)
      setEmployeeBankName(employeeSalary[0].docData.bankName)
      setEmployeePhoneNumber(employeeSalary[0].docData.phoneNo)
    }
  }, [employeeDocs, selectedEmployee]);

  // Calculate Pay
  const [nssfCharge, setNssfCharge] = useState('');
  const [taxablePay, setTaxablePay] = useState('')
  const [nhifPayable, setNhifPayable] = useState('')
  const [insuranceRelief, setInsuranceRelief] = useState('')
  const [personalRelief, setPersonalRelief] = useState('2400')
  const [payAfterTax, setPayAfterTax] = useState('')
  const [housingLevy, setHousingLevy] = useState('')

  const [advanceDeduction, setAdvanceDeduction] = useState(0);
  // useEffect(() => {
  //   if (selectedEmployee === 'COLLINS ONGOMA MILIMO') {
  //     setAdvanceDeduction(1000)
  //   }
  //   else if (selectedEmployee === 'JURGEN AYAYE') {
  //     setAdvanceDeduction(6000)
  //   }
  //   else if (selectedEmployee === 'JULIUS KIILU') {
  //     setAdvanceDeduction(11500)
  //   }
  //   else if (selectedEmployee === 'MARION ODHIAMBO') {
  //     setAdvanceDeduction(2000)
  //   }
  //   else if (selectedEmployee === 'BRIAN NGERESO') {
  //     setAdvanceDeduction(1000)
  //   }
  //   else if (selectedEmployee === 'ISAAC KIPKOGEI KOSGEI') {
  //     setAdvanceDeduction(3000)
  //   }
  //   else{setAdvanceDeduction(0)}
  // }, [selectedEmployee])


  // PAYE Values
  const [payeValueA, setPayeValueA] = useState('');
  const [payeValueB, setPayeValueB] = useState('');
  const [payeValueC, setPayeValueC] = useState('');
  const [incomeTax, setIncomeTax] = useState('');
  const [payeAmount, setPayeAmount] = useState('');

  // NET PAY
  const [netPay, setNetPay] = useState('')

  useEffect(() => {
    if (employeeBasicSalary >= 17999) {
      setNssfCharge('1080')
      setTaxablePay(employeeBasicSalary - 1080)
    } else {
      // const theCharge = employeeBasicSalary * 0.3
      // setNssfCharge(theCharge)
      // setTaxablePay(employeeBasicSalary - theCharge)

      const theCharge = employeeBasicSalary * 0.06
      setNssfCharge(theCharge)
      setTaxablePay(employeeBasicSalary - theCharge)
    }

    // Income Tax
    // if(taxablePay > 0) {
    //   const incomeTaxAmount = parseFloat(taxablePay) * 0.1
    //   setIncomeTax(incomeTaxAmount)
    // }
    // if(taxablePay >= 24510) {
    //   const incomeTaxAmount = parseFloat(taxablePay) * 0.1;
    //   setIncomeTax(incomeTaxAmount)
    // } else if (taxablePay < 24510) {
    //   setIncomeTax(parseFloat(taxablePay) * 0.1);
    // }

    // nhif payable
    if (employeeBasicSalary > 0) {
      switch (true) {
        case employeeBasicSalary <= 5999:
          setNhifPayable("150");
          break;
        case employeeBasicSalary <= 7999:
          setNhifPayable("300");
          break;
        case employeeBasicSalary <= 11999:
          setNhifPayable("400");
          break;
        case employeeBasicSalary <= 14999:
          setNhifPayable("500");
          break;
        case employeeBasicSalary <= 19999:
          setNhifPayable("600");
          break;
        case employeeBasicSalary <= 24999:
          setNhifPayable("750");
          break;
        case employeeBasicSalary <= 29999:
          setNhifPayable("850");
          break;
        case employeeBasicSalary <= 34999:
          setNhifPayable("900");
          break;
        case employeeBasicSalary <= 39999:
          setNhifPayable("950");
          break;
        case employeeBasicSalary <= 44999:
          setNhifPayable("1000");
          break;
        case employeeBasicSalary <= 49999:
          setNhifPayable("1100");
          break;
        case employeeBasicSalary <= 59999:
          setNhifPayable("1200");
          break;
        case employeeBasicSalary <= 69999:
          setNhifPayable("1300");
          break;
        case employeeBasicSalary <= 79999:
          setNhifPayable("1400");
          break;
        case employeeBasicSalary <= 89999:
          setNhifPayable("1500");
          break;
        case employeeBasicSalary <= 99999:
          setNhifPayable("1600");
          break;
        default:
          setNhifPayable("1700");
          break;
      }
    }

    // Insurance relief
    if (nhifPayable > 0) {
      const insuranceReliefAmount = nhifPayable * 0.15
      setInsuranceRelief(insuranceReliefAmount);
    }

    // housing levy
    const housingLevyDeduction = employeeBasicSalary * 0.015
    setHousingLevy(housingLevyDeduction);

    // PAYE Functionality
    if ((taxablePay >= 24510 && employeeBasicSalary >= 25590)) {
      if (taxablePay >= 24000) {
        setPayeValueA("2400");
      } else {
        setPayeValueA('')
      }

      const secondPaye = taxablePay - 24000
      if (secondPaye >= 8333) {
        setPayeValueB("2083.25");
      } else if (secondPaye < 8333) {
        const secondPayeVal = secondPaye * 0.25
        setPayeValueB(secondPayeVal)
      } else {
        setPayeValueB('0')
      }

      if (taxablePay >= 32333) {
        const thirdPayee = (taxablePay - 32333) * 0.3
        setPayeValueC(thirdPayee)
      } else if (taxablePay < 32333) {
        setPayeValueC('')
      }

      if (payeValueA > 0 && payeValueB > 0 && payeValueC > 0) {
        const payeAmt =
          parseFloat(payeValueA) +
          parseFloat(payeValueB) +
          parseFloat(payeValueC);
        setIncomeTax(payeAmt);
      } else if (payeValueA > 0 && payeValueB > 0) {
        const payeAmt = parseFloat(payeValueA) + parseFloat(payeValueB);
        setIncomeTax(payeAmt);
      } else if (payeValueA > 0) {
        const payeAmt = parseFloat(payeValueA);
        setIncomeTax(payeAmt);
      } else {
        setIncomeTax("0");
      }
    } else {
      setPayeValueA('')
      setPayeValueB('')
      setPayeValueC('')
      setPayeAmount('0')
      setIncomeTax(taxablePay * 0.1)
    }

    // PAYE
    //if(incomeTax > 0) {
    if (taxablePay >= 24510) {
      const payablePaye =
        parseFloat(incomeTax) - parseFloat(insuranceRelief) - parseFloat(personalRelief);
      setPayeAmount(payablePaye)
    }

    // Pay after tax

    // NET Pay 
    if (payeAmount > 0) {
      const payAfterTaxes = parseFloat(taxablePay) - parseFloat(payeAmount)
      const netSalary = parseFloat(taxablePay) - parseFloat(payeAmount) - parseFloat(nhifPayable) - parseFloat(housingLevy) - parseFloat(advanceDeduction);
      setPayAfterTax(payAfterTaxes)
      setNetPay(netSalary)
    } else if (payeAmount <= 0) {
      const netSalary = parseFloat(taxablePay) - parseFloat(nhifPayable) - parseFloat(housingLevy) - parseFloat(advanceDeduction);
      setPayAfterTax(taxablePay)
      setNetPay(netSalary)
    }

  }, [employeeBasicSalary, taxablePay, incomeTax, nhifPayable, insuranceRelief, payeValueA, payeValueB, payeValueC, incomeTax, payeAmount, payAfterTax, netPay])

  function getMonthInWords(date) {
    try {

      const months = [
        "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
      ];

      const monthIndex = date.getMonth();
      return months[monthIndex];

    } catch (error) { }
  }

  const printPayroll = (
    <section className={styles.universal}>
      <div className={styles.imageContainer}>
        <img
          src={companyLogo}
          alt="Company Logo"
          className={styles.companyLogo}
        />
      </div>

      <div className={styles.addressContainer}>
        <table className={styles.tablesContainer}>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>POSTAL CODE</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>623-30100</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>TOWN/CITY</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>ELDORET</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>LOCATION</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>1ST FLOOR RUPA'S MALL</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>EMAIL</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>simia_insurance@protonmail.com</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>CO. KRA PIN</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>P052024008J</p>
            </td>
          </tr>
        </table>
      </div>

      <div className={styles.payrollTitleContainer}>
        <p className={styles.payrollTitle}>SALARY VOUCER FOR THE MONTH OF 
          {' ' + getMonthInWords(payroleGeneratorSelectedDateRN)}
        </p>
      </div>

      <div className={styles.employeeInfoContainer}>
        <table className={styles.tablesContainer}>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>NAME:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeName}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>KRA PIN NO:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeKraPin}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>NSSF NO:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeNssfNo}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>ID NO:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeIdNo}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>ACCOUNT NAME:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeAccountName}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>BANK ACCOUNT NO:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeBankAccountNo}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>BANK NAME: </p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeeBankName}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>PHONE NO:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{employeePhoneNumber}</p>
            </td>
          </tr>
        </table>
      </div>

      <div className={styles.additionalInfo}>
        <table className={styles.tablesContainer}>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>DATE OF PAYMENT:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>{DateFormat(new Date())}</p>
            </td>
          </tr>
          <tr>
            <td className={styles.tablesDataTitle}>
              <p>PAYING BANK:</p>
            </td>
            <td className={styles.tablesDataContent}>
              <p>MAYFAIR BANK ELDORET BRANCH</p>
            </td>
          </tr>
        </table>
      </div>

      <div>
        <table className={styles.payrollValuesContainer}>
          <tr>
            <td className={styles.payrollDataTitle}>BASIC PAY</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(employeeBasicSalary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>NSSF</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(nssfCharge).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>TAXABLE PAY</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(taxablePay).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>INCOME TAX</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(incomeTax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>INSURANCE (NHIF) RELIEF</td>
            <td className={styles.payrollDataContent}>
              <div>-{parseFloat(insuranceRelief).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>PERSONAL RELIEF </td>
            <td className={styles.payrollDataContent}>
              <div>-{parseFloat(personalRelief).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>P.A.Y.E.</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(payeAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>PAY AFTER TAX</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(payAfterTax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>HOUSING LEVY</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(housingLevy).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>NHIF</td>
            <td className={styles.payrollDataContent}>
              <div>-{parseFloat(nhifPayable).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>DEDUCTION</td>
            <td className={styles.payrollDataContent}>
              <div>-{parseFloat(advanceDeduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
          <tr>
            <td className={styles.payrollDataTitle}>NET PAY</td>
            <td className={styles.payrollDataContent}>
              <div>{parseFloat(netPay).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </td>
          </tr>
        </table>
      </div>

      <div className={styles.employeeInfoContainer}>
        <table className={styles.signatureContainer}>
          <div className={styles.signatureSpacer} />
          <tr className={styles.signatureRow}>
            <td className={styles.signatureDataTitle}>
              <p>AUTHORISED BY (DIRECTOR) :</p>
            </td>
            <td className={styles.signatureDataContent}>
              <p></p>
            </td>
          </tr>
          <div className={styles.signatureSpacer} />
          <tr className={styles.signatureRow}>
            <td className={styles.signatureDataTitle}>
              <p>DATE :</p>
            </td>
            <td className={styles.signatureDataContent}>
              <p></p>
            </td>
          </tr>
          <div className={styles.signatureSpacer} />
          <tr className={styles.signatureRow}>
            <td className={styles.signatureDataTitle}>
              <p>SIGN :</p>
            </td>
            <td className={styles.signatureDataContent}>
              <p></p>
            </td>
          </tr>
        </table>
      </div>
    </section>
  );



  // crete insurer Content
  const createInsurerContent = (
    <section className="doc_upload">
      <div className="right_nav_content">
        <p className="bold">Generate Payrole</p>
        <div className="h_5"></div>
        <div className="h_5"></div>

        <div className="input_group">
          <select className="select" onChange={handleEmployeeChange}>
            <option>Select Employee</option>
            {employeeDocs.map((item) => {
              return <option key={item.docID}>{item.docData.name}</option>;
            })}
          </select>
          <label className="placeholder">
            <p>Employee Name</p>
          </label>
        </div>

        <div className="h_10"></div>
        <p className="p_7">Select Month</p>
        <div className="h_2"></div>
        <div className="p-2 border rounded">
          <Group position="center">
            <MonthPicker
              value={payroleGeneratorSelectedDateRN}
              onChange={setPayroleGeneratorSelectedDateRN}
              minDate={new Date()}
              size="xs"
            />
          </Group>
        </div>
        <div className="h_5"></div>
        <button className="btn_pink" onClick={printHandler}>Submit</button>
      </div>
    </section>
  );

  return (
    <section className="right_nav_container body_input_styles">
      {createInsurerContent}
      {loaderContent}

      <div style={{ display: "none" }}>
        <div ref={componentRef}>{printPayroll}</div>
      </div>
    </section>
  );
}

export default GeneratePayrole;