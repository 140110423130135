import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { postPaymentSelectedVendor } from "../../../atoms";
import Loader from "../../Body/Loader/Loader";
import GetAllVendors from "../../Hooks/APIConn/Expenses/GetAllVendors";

const SelectVendor = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const [postPaymentSelectedVendorRN, setPostPaymentSelectedVendorRN] = useRecoilState(postPaymentSelectedVendor);

    //################################################################
    //get all vendors
    const [vendorDocs, setVendorDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllVendors();
            setVendorDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    // search vendor
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedVendor, setSearchedVendor] = useState([]);
    useEffect(() => {
        try {

            const vendor = vendorDocs.filter((doc) => {
                if (searchTerm === '') { return doc }
                else if (doc.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            });
            setSearchedVendor(vendor);

        } catch (error) { }
    }, [vendorDocs, searchTerm]);

    //################################################################
    // select insurer Content
    const selectInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Select Vendor</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search Vendor</p></label>
            </div>
            <div className='h_10'></div>
            <div>
                <ul className='select_insurer_list'>
                    {
                        searchedVendor.map((doc, index) => {
                            return (
                                <li key={index} onClick={() => { setPostPaymentSelectedVendorRN(doc) }} className={`px-2 py-1 mb-1 rounded cursor-pointer f_7D ${postPaymentSelectedVendorRN._id === doc._id ? 'border border-rose-500' : 'border'}`}>
                                    <p onClick={() => { setPostPaymentSelectedVendorRN(doc) }}>{doc.name}</p>
                                </li>
                            )
                        })
                    }</ul>
            </div>
        </div>
    </section>

    return (
        <section className='select_insurer right_nav_container body_input_styles'>
            {selectInsurerContent}
            {loaderContent}
        </section>
    );
}

export default SelectVendor;