import { useRecoilState } from "recoil";
import { actionMemoFilterFromDate,actionMemoFilterToDate } from "../../../../atoms";
import DatePicker from "react-date-picker";

const OpenActionMemoDateFilter = () => {
    //################################################################
    //get recoil values
    const [actionMemoFilterFromDateRN,setActionMemoFilterFromDateRN] = useRecoilState(actionMemoFilterFromDate);
    const [actionMemoFilterToDateRN,setActionMemoFilterToDateRN] = useRecoilState(actionMemoFilterToDate);

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>                
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setActionMemoFilterFromDateRN} value={actionMemoFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setActionMemoFilterToDateRN} value={actionMemoFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
        </section>
    )
}

export default OpenActionMemoDateFilter;