import React, { useCallback, useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Body from '../Body/Body';
import IconNav from '../IconNav/IconNav';
import TextNav from '../TextNav/TextNav';
import './Dashboard.css';
import SignIn from '../Authentication/SignIn';
import SignUp from '../Authentication/SignUp';
import RightNav from '../RightNav/RightNav';
import GetCookie from '../Hooks/Cookie/GetCookie';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeTabs, activeInsurerPortfolio } from '../../atoms';
import ProcessClientStatementTransactions from '../Body/BodyStatements/ProcessClientStatementTransactions';
import MainNav from '../MainNav/MainNav';
import TextNavMain from '../TextNav/TextNavMain';
import ActivityLogger from '../Hooks/ActivityLog/ActvitityLogger';
import MainNavFinal from '../MainNav/MainNavFinal';
import CreateUserActivity from '../Hooks/APIConn/UserActivity/CreateUserActivity';

const Dashboard = (props) => {
    //################################################################
    //recoil values
    const [activeTabsRN, setActiveTabsRN] = useRecoilState(activeTabs)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    const a = {
        setView: 'Main Screen',
        previousView: '',
        postedDocRef: '',
        dateFrom: '',
        dateTo: '',
        searchTerm: '',
        notification: '',
    }

    //one prop
    const [onePropMemory, setOnePropMemory] = useState(a);
    const onePropDownHandler = (receivedData) => {
        setOnePropMemory(receivedData)
    }

    //user activity
    useEffect(() => {
        try {
            if (Object.keys(onePropMemory).length > 0) {
                if (onePropMemory.activity) {

                    let postedDocRef = ''
                    if (onePropMemory.postedDocRef) {
                        postedDocRef = onePropMemory.postedDocRef
                    }

                    const userDeviceDetails = GetCookie('userDeviceDetails');
                    const userDetails = GetCookie('CurrentUserSession');
                    CreateUserActivity({
                            activity: onePropMemory.activity,
                            date: new Date(),
                            user: JSON.parse(userDetails),
                            affectedDoc: postedDocRef,
                            deviceDetails: JSON.parse(userDeviceDetails),
                            insurerID: activeInsurerPortfolioRN,
                        })
                    // ActivityLogger({
                    //     activity: onePropMemory.activity,
                    //     date: new Date(),
                    //     user: JSON.parse(userDetails),
                    //     affectedDoc: postedDocRef,
                    //     deviceDetails: JSON.parse(userDeviceDetails),
                    //     insurerID: activeInsurerPortfolioRN,
                    // });

                }
            }
        } catch (error) { }
    }, [onePropMemory])

    const [activeIconNav, setActiveIconNav] = useState('Dashboard');
    const iconNavClickedHandler = (iconNavClicked) => {
        setActiveIconNav(iconNavClicked);
    };

    const [activeTextNav, setActiveTextNav] = useState('')
    const textNavClickedHandler = (textNavInstruction) => {
        setActiveTextNav(textNavInstruction);
        setOnePropMemory({})
    }

    const [postedDocRef, setPostedDocRef] = useState('');
    const [backFeatureObj, setBackFeatureObj] = useState({});

    //tell body to view policy
    const successPolicyCreate = (viewPolicyCommand) => {
        setPostedDocRef(viewPolicyCommand.postedDocRef);
        setActiveTextNav(viewPolicyCommand.setView);
        setBackFeatureObj(viewPolicyCommand.backFeatureObj)
    }

    //view client portfolio
    const successCreateClientHandler = (viewClientPortfolioCommand) => {
        setActiveTextNav(viewClientPortfolioCommand);
    }

    //view endorsment
    const passEndorsmentHandler = (postedData) => {
        setPostedDocRef(postedData.postedDocRef);
        setActiveTextNav(postedData.setView);
    }

    //################################################################
    //add tab
    useEffect(() => {
        if (Object.values(onePropMemory).length > 0) {
            const a = activeTabsRN.filter(tab => tab.setView === onePropMemory.setView)
            if (a.length < 1) {
                setActiveTabsRN([onePropMemory, ...activeTabsRN])
            }

        }
    }, [onePropMemory])

    //notification
    useEffect(() => {
        if (!onePropMemory.notification) { }
        else {
            toast.info(onePropMemory.notification, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [onePropMemory])

    //state to control content 
    const [userCredentials, setUserCredentials] = useState('Sign In')

    //check session
    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                setUserCredentials('Dashboard')
                //check cookies
                const q = GetCookie('CurrentUserSession')
                if (!q) { setUserCredentials('Sign In'); }
                // ...
            } else {
                // User is signed out
                setUserCredentials('Sign In')
            }
        });

    }, [activeTextNav, onePropMemory, activeIconNav]);

    //sign in / sign up handler
    const signInPageDownHandler = (instructions) => {
        setUserCredentials(instructions)
    }

    //content
    let content = '';
    switch (userCredentials) {
        case 'Dashboard':
            content = <section className='dashboard_container'>
                {/* <div className='icon-nav'>
                    <IconNav onIconNavClicked={iconNavClickedHandler} />
                </div> */}
                <div className='text-nav'>
                    {/* <TextNav onePropDown={onePropDownHandler} activeIconNav={activeIconNav} onTextNavClicked={textNavClickedHandler} /> */}
                    <TextNavMain onePropDown={onePropDownHandler} onePropUp={onePropMemory} />
                </div>
                <div className='main-nav'>
                    <MainNavFinal onePropDown={onePropDownHandler} onePropUp={onePropMemory} />
                    {/* <MainNav onePropDown={onePropDownHandler} onePropUp={onePropMemory} /> */}
                </div>
                <div className='bodyy'>
                    <Body onePropDown={onePropDownHandler} onePropUp={onePropMemory} activeTextNavMenu={activeTextNav} onSuccessPolicyCreate={successPolicyCreate} backFeatureObj={backFeatureObj} successCreateClient={successCreateClientHandler} postedDocRef={postedDocRef} passEndorsment={passEndorsmentHandler} />
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
                <div className='right-navv'>
                    <RightNav onePropDown={onePropDownHandler} onePropUp={onePropMemory} />
                </div>
            </section>;
            break;
        case 'Sign Up':
            content = <SignUp instructionsDown={signInPageDownHandler} />;
            break;
        case 'Sign In':
            content = <SignIn instructionsDown={signInPageDownHandler} />;
            break;

    }

    return (
        <section>{content}</section>
    )
}

export default Dashboard;