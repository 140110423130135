import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllExpenses from "../../Hooks/BackendConn/Finance/GetAllExpenses";
import { useRecoilValue } from "recoil";
import { expensesListFilterFromDate, expensesListFilterToDate } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";
import Loader from "../Loader/Loader";
import Worker from './ExpensesList.Worker.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllExpensesAPI from "../../Hooks/APIConn/Expenses/GetAllExpensesAPI";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};


const ExpensesList = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const expensesListFilterFromDateRN = useRecoilValue(expensesListFilterFromDate);
    const expensesListFilterToDateRN = useRecoilValue(expensesListFilterToDate)

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = {
                expensesListFilterFromDateRN: expensesListFilterFromDateRN,
                expensesListFilterToDateRN: expensesListFilterToDateRN
            }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [expensesListFilterFromDateRN, expensesListFilterToDateRN]);
    
    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['purposeOfPayment', 'amount', 'paymentID', 'modeOfPayment', 'expenseAccount', 'receiptNo', 'paymentDate']

    const exportToExcelFileName = "EXPENSES LIST";

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.expenseAccount,
                doc.paymentDate,
                doc.modeOfPayment,
                doc.amount,
                doc.receiptNo,
                doc.paymentID,
                doc.purposeOfPayment,
            ])
        });

        const onlyDispatchedheaderData = 'EXPENSES LIST';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 150, 'auto', 'auto', 'auto', 'auto', 'auto', 120],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'EXPENSE ACCOUNT', 'DATE CREATED', 'MODE OF PAYMENT', 'AMOUNT', 'RECEIPT NUMBER', 'PAYMENT ID', 'PURPOSE OF PAYMENT'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.expenseAccount,
                doc.paymentDate,
                doc.modeOfPayment,
                doc.amount,
                doc.receiptNo,
                doc.paymentID,
                doc.purposeOfPayment,
            ])
        });

        const onlyDispatchedheaderData = 'EXPENSES LIST';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 150, 'auto', 'auto', 'auto', 'auto', 'auto', 120],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'EXPENSE ACCOUNT', 'DATE CREATED', 'MODE OF PAYMENT', 'AMOUNT', 'RECEIPT NUMBER', 'PAYMENT ID', 'PURPOSE OF PAYMENT'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //view expense
    const viewExpenseHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Expense',
            previousView: '',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewExpenseHandler}><p>View Details</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'paymentDate', headerName: 'Date', sortable: false, filter: false, width: 100, },
        { field: 'expenseAccount', headerName: 'Expense Account', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'purposeOfPayment', headerName: 'Purpose', width: 150, },
        { field: 'amount', headerName: 'Amount', width: 150, sortable: false, filter: false, cellStyle: { 'backgroundColor': '#B1AFCF' } },
        { field: 'receiptNo', headerName: 'Receipt No', sortable: false, filter: false, width: 90, },
        { field: 'modeOfPayment', headerName: 'Mode', width: 150, },
        { field: 'paymentID', headerName: 'Payment ID', sortable: false, filter: false, width: 100, },
        
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
      <section className="policy_list_grid">
        <div className="body_section_header">
          <p>Expenses List</p>
          <div className="cursor-pointer">
            <p className="peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded">
              Controls
            </p>
            <div className="hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999">
              <p className="hover:bg-gray-200 px-2 py-2 rounded" onClick={printAll}>Print</p>
              <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
              <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
            </div>
          </div>
        </div>
        <div className="body_section">{content}</div>
        {loaderContent}
        {URRightClickMenu}
      </section>
    );
}

export default ExpensesList;