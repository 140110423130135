import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetAllDebit = async () => {
    try {
        const q = query(collection(db, "PolicyDetails"), where("transactionType", "==", "Debit"));

        const querySnapshot = await getDocs(q);
        
        const DData = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            DData.push({ docData: doc.data(), docID: doc.id })
        });

        if (DData.length < 1) {
            GetAllDebit();
        }
        else {
            return DData;
        }
    }
    catch (error) {
        GetAllDebit();
    }
}

export default GetAllDebit;