import React, { useState, useEffect } from "react";
import GetClientData from "../../../../Hooks/BackendConn/ClientList/GetClientData";
import Loader from "../../../../Body/Loader/Loader";
import { useRecoilState, useRecoilValue } from "recoil";
import { createPolicySelectedClient, activeInsurerPortfolio } from "../../../../../atoms";

const CreatePolicySelectClient = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [createPolicySelectedClientRN, setCreatePolicySelectedClientRN] = useRecoilState(createPolicySelectedClient);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    // get user input
    const [searchTerm, setSearchTerm] = useState('');

    //################################################################
    //get all client data
    const [clientDataDocs, setClientDataDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetClientData();
            setClientDataDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //filter insurer
    const [insurerFilteredClientList, setInsurerFilteredClientList] = useState([]);
    useEffect(() => {
        const list = clientDataDocs.filter(doc => doc.docData.insurerID === activeInsurerPortfolioRN);
        setInsurerFilteredClientList(list)
    }, [clientDataDocs, activeInsurerPortfolioRN])

    //################################################################
    // filter client list
    const [filteredClientsData, setFilteredClientsData] = useState([]);
    useEffect(() => {
        try {
            const list = insurerFilteredClientList.filter((doc) => {
                if (searchTerm == '') {
                    return (doc);
                }
                else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            });

            setFilteredClientsData(list)


        } catch (error) { console.log(error) }
    }, [insurerFilteredClientList, searchTerm])

    //################################################################
    // crete insurer Content
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} value={searchTerm} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search Client</p></label>
            </div>
            <div className='h_5'></div>
            <div>
                {
                    filteredClientsData.map((doc, index) => {
                        return (
                            <div key={index} onClick={() => { setCreatePolicySelectedClientRN(doc.docData.clientName) }} className={`border p-2 mb-1 rounded cursor-pointer`}>
                                {doc.docData.clientName}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {content}
            {loaderContent}
        </section>
    );
}

export default CreatePolicySelectClient;