import React, { useState, useCallback } from "react";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import db, { storage } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRecoilState, useRecoilValue } from "recoil";
import { rightNavContent, rightNavPolicyItem, pageReloaderHandler } from "../../../atoms";
import { useDropzone } from 'react-dropzone';
import UUID from "../../Hooks/UUID/UUID";
import { nanoid } from 'nanoid';
import GetCookie from "../../Hooks/Cookie/GetCookie";
import Loader from "../../Body/Loader/Loader";

const PolicyDocumentUpload = (props) => {
    //#####################################################################
    //recoil values
    const rightNavPolicyItemRN = useRecoilValue(rightNavPolicyItem)
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler)

    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //#####################################################################
    //get user input
    const [policyDocumentFile, setPolicyDocumentFile] = useState([]);

    //#####################################################################
    //log book upload progress
    const [policyDocumentUploadProgress, setpolicyDocumentUploadProgress] = useState(0)

    //################################################################
    //policy doc file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setPolicyDocumentFile(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //reload on success update
    //step 3
    const onSuccessAssociateDocUpdate = () => {
        //open view
        const onePropObj = {
            setView: 'Policy Search',
            previousView: 'Policy Search',
            postedDocRef: rightNavPolicyItemRN.docID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Document Uploaded Successfully',
            activity: 'Debit / Credit Document Upload',
        }

        setPageReloaderHandlerRN(onePropObj);
        props.onePropDown(onePropObj);

        setIsLoading(false);
        //reset state var
        setPolicyDocumentFile([]);
        setpolicyDocumentUploadProgress(0);
        setRightNavContentRN('');

    }

    //update db record
    //step 2
    async function updateAssociateDocRecord(downloadURL) {
        //create obj to post
        const associateDocObj = {
            documentDownloadLink: downloadURL,
            documentUploadCategory: 'Policy Document',
            documentPolicyNumber: rightNavPolicyItemRN.docData.policyNumber,
            documentEndorsmentNumber: rightNavPolicyItemRN.docData.endorsmentNumber,
            documentPolicyID: rightNavPolicyItemRN.docID,
            documentClientName: rightNavPolicyItemRN.docData.clientName,
            documentUploadDate: new Date(),
            uploadedBy: currentUser,
        }

        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightNavPolicyItemRN.docID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'associateDocuments': arrayUnion(associateDocObj)
            });
            onSuccessAssociateDocUpdate();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //file upload
    //step 1
    const policyDocumentUploadSubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const file = policyDocumentFile[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRN.docData.clientName,
                'policyNumber': rightNavPolicyItemRN.docData.policyNumber,
                'endorsmentNumber': rightNavPolicyItemRN.docData.endorsmentNumber,
                'policyDocID': rightNavPolicyItemRN.docID,
                'documentID': UUID(),
            }

        };

        const filename = UUID() + ' ' + nanoid()

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'documents/' + filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setpolicyDocumentUploadProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }

                // setIsLoading(true);
                // if (progress < 100) { setIsLoading(true); }
                // else { setIsLoading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateAssociateDocRecord(downloadURL)
                });
            }
        );
    }

    //################################################################
    //content    
    const PolicyDocumentUploadContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Policy Document Upload</p>
            <div className='h_10'></div>
            {
                policyDocumentUploadProgress > 0
                    ? <div><p className='f_7'>Upload Progress: {policyDocumentUploadProgress}% Done</p></div>
                    : ''
            }
            <div className='h_5'></div>
            <div className='file_upload_container'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        policyDocumentFile.length > 0
                            ? <p>{policyDocumentFile[0].name}</p>
                            : isDragActive
                                ? <p>Drop the files here ...</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </div>
            <div className='h_5'></div>
            <button onClick={policyDocumentUploadSubmitHandler} className='btn_pink'>Upload</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {PolicyDocumentUploadContent}
            {loaderContent}
        </section>
    );
}

export default PolicyDocumentUpload;