import './ClientsAccountsOverview.css';
import React, { useState, useEffect, useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import { useReactToPrint } from "react-to-print";
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import logo from '../../Assets/img/logo.png';
import ClientOutstanding from './ClientOutstanding'
import PrintClientStatement from './PrintClientStatement';
import { utils, writeFileXLSX } from 'xlsx';
import { useRecoilState, useRecoilValue } from "recoil";
import { agewiseOutstandingInsurerFilter, agewiseOutstandingFilterFromDate, agewiseOutstandingFilterToDate } from "../../../atoms";


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import ProcessClientStatementTransactions from './ProcessClientStatementTransactions';
import ClientStatementTemplate from './ClientStatementTemplate';
import { nanoid, random } from 'nanoid';


const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
};

const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = '100%';
    api.setDomLayout();
};

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const Overview = (props) => {
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //printing stmt
    const componentRefStmt = useRef();
    const handlePrintStmt = useReactToPrint({
        content: () => componentRefStmt.current,
    });

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "Agewise Outstanding.xlsx");
    }, [tbl]);

    //################################################################
    //recoil values
    const agewiseOutstandingInsurerFilterRN = useRecoilValue(agewiseOutstandingInsurerFilter)
    const agewiseOutstandingFilterFromDateRN = useRecoilValue(agewiseOutstandingFilterFromDate)
    const agewiseOutstandingFilterToDateRN = useRecoilValue(agewiseOutstandingFilterToDate)


    //##############################################
    //from date
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])
    //console.log(fromDate)

    //to
    const [toDate, setToDate] = useState('');

    const [tvalue, onTChange] = useState(new Date());
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //show hide search
    const [toogleRightNavv, setToogleRightNavv] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNavv = (instruction) => {
        if (instruction === 'Show') { setToogleRightNavv('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNavv('receipt_allocation_rightNav hide') }
    }

    // search section
    const filterContent = <div className={toogleRightNavv}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNavv('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>


    //get clients
    const [clientDetails, setClientDetails] = useState([]);
    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "ClientDetails"));
            if (!isCancelled) {
                const CData = []
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    const allClientDetails = {
                        receivedDocID: doc.id,
                        clientName: doc.data().clientName,
                        IDNumber: doc.data().IDNumber,
                        passportNumber: doc.data().passportNumber,
                        KRAPin: doc.data().KRAPin,
                        occupation: doc.data().occupation,
                        phoneNumber: doc.data().phoneNumber,
                        email: doc.data().email,
                        address: doc.data().address
                    }
                    CData.push(allClientDetails);
                    setIsloading(false);
                },
                    setClientDetails(CData)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);


    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);
    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    // assign date
                    let UDate = '';
                    let echoDate = '';
                    let policyClientName = '';
                    let filterDate = '';
                    if (doc.data().transactionType === 'Receipt') {
                        UDate = doc.data().receiptDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Debit') {
                        UDate = doc.data().insurerDebitDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Credit') {
                        UDate = doc.data().inceptionDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    const PC = [];
                    const allPolicyDocs = {
                        docID: doc.id,
                        clientName: doc.data().clientName,
                        policyNumber: doc.data().policyNumber,
                        endorsmentNumber: doc.data().endorsmentNumber,
                        selectedProductSubClass: doc.data().selectedProductSubClass,
                        inceptionDate: doc.data().inceptionDate,
                        policyNet: doc.data().policyNet,
                        outstanding: doc.data().outstanding,
                        transactionType: doc.data().transactionType,
                        locationOfTheRisk: doc.data().locationOfTheRisk,
                        vehicleItems: doc.data().vehicleItems,
                        receiptAllocationBreakDown: doc.data().receiptAllocationBreakDown,
                        status: doc.data().status,

                        receiptDate: doc.data().receiptDate,
                        receiptNumber: doc.data().receiptNumber,
                        receiptAmount: doc.data().receiptAmount,
                        receiptAllocationDetails: doc.data().receiptAllocationDetails,

                        uDate: UDate,
                        echoDate: echoDate,
                        filterDate: filterDate,
                        cummulativeOutstanding: '',
                        realTimeOutstanding: doc.data().policyNet,
                        policyCredits: PC
                    }
                    Rdata.push(allPolicyDocs);
                    setIsloading(false);
                },
                    setReceivedPolicyDocs(Rdata)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [toDate, fromDate])

    const [selectedClient, setSelectedClient] = useState('')

    //###################################################

    //filter client
    const [clientReceivedPolicyDocs, setClientReceivedPolicyDocs] = useState([]);
    const [finalFinal, setFinalFinal] = useState([]);

    const [docs, setDocs] = useState([{ clientName: '', docs: [] }]);
    const [ageingSummary, setAgeingSummary] = useState([{}]);

    const [filteredDatesPolicyDocs, setFilteredDatesPolicyDocs] = useState([]);
    useEffect(() => {
        const PeriodSelected = [];
        receivedPolicyDocs.map((item) => {
            // if (new Date(item.filterDate).valueOf() >= new Date(fromDate).valueOf() && new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.policyStatus !== 'Pending') {
            //     PeriodSelected.push(item);
            // }
            if (new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf() && item.policyStatus !== 'Pending') {
                PeriodSelected.push(item);
            }
        })
        setFilteredDatesPolicyDocs(PeriodSelected)
    }, [receivedPolicyDocs, toDate, fromDate])

    useEffect(() => {
        const q = []
        const r = []
        clientDetails.map((val) => {
            const clientSortedData = [];
            filteredDatesPolicyDocs.map((item) => {
                if (item.clientName === val.clientName && item.transactionType === 'Debit' && item.status !== 'Pending') {
                    clientSortedData.push(item)
                }
                else if (item.clientName === val.clientName && item.transactionType === 'Credit') {
                    clientSortedData.push(item)
                }
                else if (item.clientName.includes(val.clientName) && item.transactionType === 'Receipt') {
                    clientSortedData.push(item)
                }
            })
            q.push({ clientName: val.clientName, docs: clientSortedData })
            const clientSpecificDocs = clientSortedData;


            const Cr = []
            clientSpecificDocs.map((item) => {
                if (item.transactionType === 'Credit') { Cr.push(item) }
            })
            //setTransactionCredits(Cr)
            //
            const Re = []
            clientSpecificDocs.map((item) => {
                if (item.transactionType === 'Receipt') { Re.push(item) }
            })
            //setTransactionReceipts(Re)
            //
            const Dr = []
            clientSpecificDocs.map((item) => {
                if (item.transactionType === 'Debit') { Dr.push(item) }
            })
            //setTransactionDebits(Dr)

            // sort DR
            const sortedList = Dr.sort(byDate);
            function byDate(a, b) {
                return new Date(a.inceptionDate).valueOf() - new Date(b.inceptionDate).valueOf();
            }
            const clientSpecificDocsEditedUsed = sortedList;
            const clientSpecificDocsEdited = sortedList;

            //s2 add credits
            Cr.map((item) => {
                let isCancelled = false;
                clientSpecificDocsEditedUsed.map((val, index) => {
                    if (!isCancelled) {
                        if (item.policyNumber === val.policyNumber && val.transactionType === 'Debit') {
                            clientSpecificDocsEdited[index]['policyCredits'].push(item.policyNet);
                            isCancelled = true;
                        }
                    }

                })
            })

            //s3 add receipts
            Re.map((item) => {
                const q = item.receiptAllocationDetails;
                q.map((value) => {
                    clientSpecificDocsEditedUsed.map((val, index) => {
                        if (value.receivedDocID === val.docID && val.transactionType === 'Debit') {
                            clientSpecificDocsEdited[index]['policyCredits'].push(value.receivedAllocatedAmount);
                        }
                    })
                })
            })

            //final arr for aging
            const newArr = []
            clientSpecificDocsEdited.map((item, index) => {
                if (item.transactionType === 'Debit') {
                    const a = item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0);
                    item.outstanding = item.policyNet - a;
                    newArr.push(item);
                }
            });


            //calculate aging
            const credits = [];
            const thirtyDays = [];
            const sixtyDays = [];
            const ninetyDays = [];
            const oneTwentyDays = [];
            const moreThanOneTwentyDays = [];
            const totalAgeing = [];
            newArr.map((val, key) => {
                if (val.policyNumber === 'BF') { val.transactionType = 'Debit' }
                if (val.transactionType === 'Debit' && val.outstanding > 0) {
                    const dateOne = new Date(val.inceptionDate);
                    const dateTwo = new Date(toDate);
                    const datesCompared = Math.abs(dateOne - dateTwo);
                    const datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
                    totalAgeing.push(val.outstanding);
                    if (datesDifference < 31) {
                        thirtyDays.push(val.outstanding);
                    }
                    else if (datesDifference < 61 && datesDifference > 30) {
                        sixtyDays.push(val.outstanding);
                    }
                    else if (datesDifference < 91 && datesDifference > 60) {
                        ninetyDays.push(val.outstanding);
                    }
                    else if (datesDifference < 121 && datesDifference > 90) {
                        oneTwentyDays.push(val.outstanding);
                    }
                    else if (datesDifference > 120) { moreThanOneTwentyDays.push(val.outstanding); }
                }
                if (val.transactionType === 'Debit' && val.outstanding < 0) {
                    credits.push(val.outstanding)
                }
            })

            const ageingCredits = credits.reduce((total, item) => { return total + +item }, 0);
            const ageingThirtyDays = thirtyDays.reduce((total, item) => { return total + +item }, 0);
            const ageingSixtyDays = sixtyDays.reduce((total, item) => { return total + +item }, 0);
            const ageingNinetyDays = ninetyDays.reduce((total, item) => { return total + +item }, 0);
            const ageingOneTwentyDays = oneTwentyDays.reduce((total, item) => { return total + +item }, 0);
            const ageingMoreThanOneTwentyDays = moreThanOneTwentyDays.reduce((total, item) => { return total + +item }, 0);

            const a = [ageingCredits, ageingMoreThanOneTwentyDays, ageingOneTwentyDays, ageingNinetyDays, ageingSixtyDays, ageingThirtyDays]
            if (ageingCredits < 0) {
                for (let i = 1; i < a.length; i++) {
                    const previousIndex = i - 1;


                    if (a[previousIndex] <= 0) { a[i] = a[previousIndex] + a[i]; }
                }
            }

            for (let i = 1; i < a.length; i++) {
                if (a[i] < 0) {
                    a[0] = a[i]
                    a[i] = 0
                }
            }

            //total outstanding
            const b = [a[1], a[2], a[3], a[4], a[5]]
            const ageingGrandTotal = b.reduce((total, item) => { return total + +item }, 0);

            //total debits
            const allDebitPolicyNet = []
            Dr.map((item) => { allDebitPolicyNet.push(item.policyNet) })
            const totalClientDebits = allDebitPolicyNet.reduce((total, item) => { return total + +item }, 0);

            //total credits
            const allCreditPolicyNet = []
            Cr.map((item) => { allCreditPolicyNet.push(item.policyNet) })
            const totalClientCredits = allCreditPolicyNet.reduce((total, item) => { return total + +item }, 0);

            //total receipts
            const allReceiptsNet = []
            Re.map((item) => { allReceiptsNet.push(item.receiptAmount) })
            const totalReceipts = allReceiptsNet.reduce((total, item) => { return total + +item }, 0);


            const final = {
                client: val.clientName,
                clientObj: val,
                ageingThirtyDays: a[5],
                ageingSixtyDays: a[4],
                ageingNinetyDays: a[3],
                ageingOneTwentyDays: a[2],
                ageingMoreThanOneTwentyDays: a[1],
                totalClientDebits: totalClientDebits,
                totalClientCredits: totalClientCredits + totalReceipts,
                ageingGrandTotal: ageingGrandTotal,
                cummulativeAgeingOutstanding: totalClientDebits - totalClientCredits - totalReceipts
            }

            r.push(final)

        })
        setDocs(q)
        setAgeingSummary(r)
    }, [filteredDatesPolicyDocs, toDate])

    //###################################################################


    // //console.log(ageingSummary)
    // const [totalAgeingThirtyDays, setTotalAgeingThirtyDays] = useState(0);
    // const [totalAgeingSixtyDays, setTotalAgeingSixtyDays] = useState(0);
    // const [totalAgeingNinetyDays, setTotalAgeingNinetyDays] = useState(0);
    // const [totalAgeingOneTwentyDays, setTotalAgeingOneTwentyDays] = useState(0);
    // const [totalAgeingMoreThanOneTwentyDays, setTotalAgeingMoreThanOneTwentyDays] = useState(0);
    // const [totalDRs, setTotalDRs] = useState(0);
    // const [totalCRs, setTotalCRs] = useState(0);
    // const [total, setTotal] = useState(0);

    // useEffect(() => {
    //     const totalAgeingThirtyDays = [];
    //     const totalAgeingSixtyDays = [];
    //     const totalAgeingNinetyDays = [];
    //     const totalAgeingOneTwentyDays = [];
    //     const totalAgeingMoreThanOneTwentyDays = [];
    //     const totalDRs = [];
    //     const totalCRs = [];
    //     const total = [];

    //     ageingSummary.map((item) => {
    //         totalAgeingThirtyDays.push(item.ageingThirtyDays)
    //         totalAgeingSixtyDays.push(item.ageingSixtyDays)
    //         totalAgeingNinetyDays.push(item.ageingNinetyDays)
    //         totalAgeingOneTwentyDays.push(item.ageingOneTwentyDays)
    //         totalAgeingMoreThanOneTwentyDays.push(item.ageingMoreThanOneTwentyDays)
    //         totalDRs.push(item.totalClientDebits)
    //         totalCRs.push(item.totalClientCredits)
    //         total.push(item.cummulativeAgeingOutstanding)
    //     });

    //     const a = totalAgeingThirtyDays.reduce((total, item) => { return total + +item }, 0);
    //     setTotalAgeingThirtyDays(a)
    //     const b = totalAgeingSixtyDays.reduce((total, item) => { return total + +item }, 0);
    //     setTotalAgeingSixtyDays(b)
    //     const c = totalAgeingNinetyDays.reduce((total, item) => { return total + +item }, 0);
    //     setTotalAgeingNinetyDays(c)
    //     const d = totalAgeingOneTwentyDays.reduce((total, item) => { return total + +item }, 0);
    //     setTotalAgeingOneTwentyDays(d)
    //     const e = totalAgeingMoreThanOneTwentyDays.reduce((total, item) => { return total + +item }, 0);
    //     setTotalAgeingMoreThanOneTwentyDays(e)
    //     const f = totalDRs.reduce((total, item) => { return total + +item }, 0);
    //     setTotalDRs(f)
    //     const g = totalCRs.reduce((total, item) => { return total + +item }, 0);
    //     setTotalCRs(g)
    //     const h = total.reduce((total, item) => { return total + +item }, 0);
    //     setTotal(h)

    // }, [ageingSummary])


    // //sort
    // const [sortedAgeingSummary, setSortedAgeingSummary] = useState([]);
    // useEffect(() => {
    //     const a = ageingSummary.sort(byOutstanding)
    //     function byOutstanding(a, b) {
    //         return parseInt(b.cummulativeAgeingOutstanding) - parseInt(a.cummulativeAgeingOutstanding)
    //     }
    //     setSortedAgeingSummary(a);
    // }, [ageingSummary])

    // //search
    // const [searchTerm, setSearchTerm] = useState('');
    // const searchTermChangeHandler = (event) => {
    //     setSearchTerm(event.target.value);
    // }

    // const [searchedAgeingSummary, setSearchedAgeingSummary] = useState([]);
    // useEffect(() => {
    //     const a = sortedAgeingSummary.filter((val) => {
    //         if (searchTerm === '') { return val; }
    //         else if (val.client.toLowerCase().includes(searchTerm.toLowerCase())) {
    //             return val;
    //         }
    //     })
    //     setSearchedAgeingSummary(a)
    // }, [sortedAgeingSummary, searchTerm])

    // // change view on client click
    // const openClientStatementClickHandler = (clientObj) => {
    //     const onePropObj = {
    //         setView: 'Client Statement',
    //         previousView: 'Agewise Outstanding',
    //         postedDocRef: clientObj,
    //         dateFrom: fromDate,
    //         dateTo: toDate,
    //         searchTerm: searchTerm,
    //         notification: ''
    //     }
    //     props.onePropDown(onePropObj)
    // }

    // //#########################################################################
    // //policy controls
    // const [hideShowControls, setHideShowControls] = useState('control_list')
    // const hideShowControlsClickHandler = (instruction) => {
    //     setHideShowControls(instruction)
    // }

    // const [printStatementClientList, setPrintStatementClientList] = useState([])
    // const printStatementClientListHandler = (event) => {
    //     if (event.target.checked) {
    //         setPrintStatementClientList([...printStatementClientList, event.target.value])
    //     }
    //     else {
    //         const arrayList = printStatementClientList;
    //         const index = arrayList.indexOf(event.target.value);
    //         if (index > -1) { // only splice array when item is found
    //             arrayList.splice(index, 1); // 2nd parameter means remove one item only
    //         }
    //         setPrintStatementClientList(arrayList)
    //     }
    // }

    // const printStatementHandler = () => {
    //     const onePropObj = {
    //         setView: 'Bulk Print Statement',
    //         previousView: 'Agewise Outstanding',
    //         postedDocRef: printStatementClientList,
    //         dateFrom: fromDate,
    //         dateTo: toDate,
    //         searchTerm: searchTerm,
    //         notification: ''
    //     }
    //     props.onePropDown(onePropObj)
    // }

    // //###################################

    // //client statement content 
    // const ClientsPortfolioContent = <section className=''>
    //     <div className='category'>
    //         <div className='category_header'>
    //             <p>Clients Agewise Outstanding</p>
    //             <div className='client_controls'>
    //                 <span className='btn_pink' onClick={exportFile} >
    //                     <i className="ri-file-excel-2-line"></i>
    //                 </span>
    //                 <div className='w_5'></div>
    //                 <span className='btn_pink' onClick={() => showHideFilterRightNavv('Show')}>
    //                     <i className="ri-filter-2-line"></i>
    //                 </span>
    //                 <div className='w_5'></div>
    //                 <span className='btn_pink' onClick={handlePrint}>
    //                     <i className="ri-printer-line"></i>
    //                 </span>
    //             </div>
    //         </div>
    //         <div className='category_content ageing_overview'>
    //             <div className='category_content_three'>
    //                 <div className='policy_search_container'>
    //                     <input onChange={searchTermChangeHandler} placeholder='Search Client Name' />
    //                     <i className="ri-search-2-line"></i>
    //                 </div>
    //                 <div className='h_10'></div>
    //                 <form>
    //                     <table className='CP_overview' ref={componentRef}>
    //                         <thead>
    //                             <tr>
    //                                 <td>
    //                                     <div className='view_policy_controls'>
    //                                         <span onMouseOver={() => hideShowControlsClickHandler('newlist shadow')} className='toogle'><i className="ri-more-2-line"></i></span>
    //                                         <div className={hideShowControls} onMouseLeave={() => hideShowControlsClickHandler('control_list')}>
    //                                             <div className="controls_items" onClick={event => printStatementHandler(event)}>
    //                                                 <p>Print Statement</p>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </td>
    //                                 <td><p>No</p></td>
    //                                 <td><p>Client Name</p></td>
    //                                 <td><p>Credit</p></td>
    //                                 <td><p>0 - 30 Days</p></td>
    //                                 <td><p>31 - 60 Days</p></td>
    //                                 <td><p>61 - 90 Days</p></td>
    //                                 <td><p>91 - 120 Days</p></td>
    //                                 <td><p>Over 120 Days</p></td>
    //                                 <td><p>Debits</p></td>
    //                                 <td><p>Credits Receipts</p></td>
    //                                 <td><p>Outstanding</p></td>
    //                             </tr>
    //                         </thead>
    //                         <tbody>
    //                             {searchedAgeingSummary.map((item, index) => {
    //                                 return (
    //                                     <tr key={index}>
    //                                         <td>
    //                                             <input onChange={printStatementClientListHandler} type='checkbox' name={index} value={item.client} />
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{index + 1}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{item.client}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>
    //                                                 {item.cummulativeAgeingOutstanding < 0
    //                                                     ? parseInt(item.cummulativeAgeingOutstanding).toLocaleString()
    //                                                     : 0
    //                                                 }
    //                                             </p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.ageingThirtyDays).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.ageingSixtyDays).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.ageingNinetyDays).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.ageingOneTwentyDays).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.ageingMoreThanOneTwentyDays).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.totalClientDebits).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.totalClientCredits).toLocaleString()}</p>
    //                                         </td>
    //                                         <td onClick={() => openClientStatementClickHandler(item.clientObj)}>
    //                                             <p>{parseInt(item.cummulativeAgeingOutstanding).toLocaleString()}</p>
    //                                         </td>
    //                                     </tr>
    //                                 )
    //                             })}
    //                             <tr>
    //                                 <td colSpan='2'></td>
    //                                 <td><p>Totals</p></td>
    //                                 <td><p>{totalAgeingThirtyDays.toLocaleString()}</p></td>
    //                                 <td><p>{totalAgeingSixtyDays.toLocaleString()}</p></td>
    //                                 <td><p>{totalAgeingNinetyDays.toLocaleString()}</p></td>
    //                                 <td><p>{totalAgeingOneTwentyDays.toLocaleString()}</p></td>
    //                                 <td><p>{totalAgeingMoreThanOneTwentyDays.toLocaleString()}</p></td>
    //                                 <td><p>{totalDRs.toLocaleString()}</p></td>
    //                                 <td><p>{totalCRs.toLocaleString()}</p></td>
    //                                 <td><p>{total.toLocaleString()}</p></td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // </section>

    //################################################################
    //################################################################
    //################################################################
    //grid actions

    //################################################################
    //print step 2
    const onBtPrint = useCallback(() => {
        const api = gridRef.current.api;
        setPrinterFriendly(api);
        setTimeout(function () {

            handlePrint()

            setNormal(api);

            autoSizeAll(false)

        }, 2000);
    }, []);

    //################################################################
    //print step 1
    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        if (skipHeader === true) {
            onBtPrint()
        }
    }, []);

    //################################################################
    //export to excel
    const columnsToExport = ['#', 'client', 'policyNo', 'endorNo', 'class', 'inception', 'expiry', 'details', 'policyNet']

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'Cancelled Policies.csv',
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    // print stmt
    const [printStmtSelection, setPrintStmtSelection] = useState([])

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow.client)
            //ProcessClientStatementTransactions({ clientName: selectedRow.client, insurer: 'MAYFAIR INSURANCE COMPANY LIMITED' })
        });
        setPrintStmtSelection(a);
    };




    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%', size: 'landscape' }), []);

    const [rowData, setRowData] = useState([ageingSummary]);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 50, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'client', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, },
        {
            field: 'cummulativeAgeingOutstanding', headerName: 'Credit', sortable: false, filter: false, maxWidth: 90,
            cellRenderer: params => {
                if (params.value < 0) {
                    return <p>{params.value}</p>
                }
                else { return <p>0</p> }
            }
        },
        { field: 'ageingThirtyDays', headerName: '0 - 30 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingSixtyDays', headerName: '31 - 60 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingNinetyDays', headerName: '61 - 90 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingOneTwentyDays', headerName: '91 - 120 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingMoreThanOneTwentyDays', headerName: 'Over 120 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'totalClientDebits', headerName: 'Debits', filter: false, },
        { field: 'totalClientCredits', headerName: 'CR RC', filter: false, },
        { field: 'cummulativeAgeingOutstanding', headerName: 'Outstanding', filter: false, },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        resizable: true, minWidth: 120,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine" ref={componentRef}>
            <span id="selectedRows"></span>
            <AgGridReact
                ref={gridRef}
                rowData={ageingSummary}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
            ></AgGridReact>

        </div>
    </section>





    const Obj = {
        outstandingObj: [{
            docID: '',
            transactionType: '',
            universalDate: '',
            policyNumber: '',
            endorsmentNumber: '',
            policyClass: '',
            policySubClass: '',
            details: '',
            policyNet: '',
            cummulativeOutstanding: '',
            allocatedDocID: '',
        }],
        ageingObj: {
            thirtyDays: '',
            sixtyDays: '',
            ninetyDays: '',
            oneTwentyDays: '',
            aboveOneTwentyDays: '',
            credit: '',
            total: '',
        },
        clientDetailsObj: {
            docData: {
                clientName: '',
                KRAPin: '',
                address: ''
            },
            docID: '',
        },
        stmtDetails: {
            periodFrom: '',
            periodTo: '',
            insurer: '',
        }
    }

    let [processedDataForStmtPrinting, setProcessedDataForStmtPrinting] = useState([]);

    useEffect(() => {

        printStmtSelection.map((client, index) => {
            let a = ProcessClientStatementTransactions({
                clientName: client,
                agewiseOutstandingFilterFromDateRN: agewiseOutstandingFilterFromDateRN,
                agewiseOutstandingFilterToDateRN: agewiseOutstandingFilterToDateRN,
                agewiseOutstandingInsurerFilterRN: agewiseOutstandingInsurerFilterRN,
            });

            setProcessedDataForStmtPrinting((p) => {
                return [...p, a]
            })

            if(index == (printStmtSelection.length -1)){
                setProcessedDataForStmtPrinting((p) => {
                    console.log(index)
                    return [...p, Obj];                    
                })
            }


        })



    }, [printStmtSelection])


    const display = () => {

        if (processedDataForStmtPrinting.length > 0) {

            const track = {};
            const unique = [];
            const duplicate = [];


            for (const item of processedDataForStmtPrinting) {
                if (track?.[item.id] === undefined) {
                    unique.push(item);
                    track[item.id] = true;
                } else {
                    duplicate.push(item);
                }
            }


            return (
                <section ref={componentRefStmt}>

                    {
                        unique.length > 0 ?
                            unique.map((data, index) => {
                                const a = printStmtSelection.filter(client => client === data.id);
                                console.log(a)
                                if (data.hasOwnProperty('clientDetailsObj') && a.length > 0) {

                                    return (
                                        <ClientStatementTemplate data={data} key={index} />
                                    )

                                }
                            })

                            : ''
                    }

                </section>
            )


        }
    }



    return (
        <section>
            <div className='body_section_header'>
                <p>Agewise Outstanding</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' >Prepare</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrintStmt}>Print</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
                {display()}
            </div>
            {loaderContent}
            {filterContent}
        </section>
    )
}

export default Overview;