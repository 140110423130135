import DatePicker from "react-date-picker";
import { useRecoilState } from "recoil";
import { unrenewedReportFilterFromDate, unrenewedReportFilterToDate, unrenewedReportFilterPolicyClass, unrenewedReportLapseAsOfDate, unrenewedReportCancelAsOfDate } from "../../../atoms";

const UnrenewedReportFilter = () => {
    //################################################################
    //get recoil values
    const [unrenewedReportFilterFromDateRN, setUnrenewedReportFilterFromDateRN] = useRecoilState(unrenewedReportFilterFromDate)
    const [unrenewedReportFilterToDateRN, setUnrenewedReportFilterToDateRN] = useRecoilState(unrenewedReportFilterToDate)
    const [unrenewedReportFilterPolicyClassRN, setUnrenewedReportFilterPolicyClassRN] = useRecoilState(unrenewedReportFilterPolicyClass)
    const [unrenewedReportLapseAsOfDateRN, setunrenewedReportLapseAsOfDateRN] = useRecoilState(unrenewedReportLapseAsOfDate)
    const [unrenewedReportCancelAsOfDateRN, setUnrenewedReportCancelAsOfDateRN] = useRecoilState(unrenewedReportCancelAsOfDate)

    const UnrenewedReportFilterContent = <section>
        <div className='right_nav_content'>
            <div>
                <div>
                    <div className='h_10'></div>
                    <div className='input_group'>
                        <select className='select' value={unrenewedReportFilterPolicyClassRN} onChange={(e) => { setUnrenewedReportFilterPolicyClassRN(e.target.value) }}>
                            <option>Select Class Filter</option>
                            <option>ALL</option>
                            <option>MOTOR</option>
                            <option>NON-MOTOR</option>
                        </select>
                        <label className='placeholder'><p>Policy Class Filter</p></label>
                    </div>
                    <div className='h_15'></div>
                    <p className='bold'>Filter Date</p>
                    <div className='h_5'></div>
                    <div className='lapse_date_picker_container'>
                        <p>From</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setUnrenewedReportFilterFromDateRN} value={unrenewedReportFilterFromDateRN} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='lapse_date_picker_container'>
                        <p>To</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setUnrenewedReportFilterToDateRN} value={unrenewedReportFilterToDateRN} format='d/ M/y' />
                    </div>
                </div>
                <div className='h_10'></div>
                <div className='separator'></div>
                <div className='h_10'></div>
                <div>
                    <p className='bold'>Lapse Policy As Of:</p>
                    <div className='h_5'></div>
                    <div className='lapse_date_picker_container'>
                        <DatePicker onChange={setunrenewedReportLapseAsOfDateRN} value={unrenewedReportLapseAsOfDateRN} format='d/ M/y' />
                    </div>
                </div>
                <div className='h_10'></div>
                <div className='separator'></div>
                <div className='h_10'></div>
                <div>
                    <p className='bold'>Cancel Policy As Of:</p>
                    <div className='h_5'></div>
                    <div className='lapse_date_picker_container'>
                        <DatePicker onChange={setUnrenewedReportCancelAsOfDateRN} value={unrenewedReportCancelAsOfDateRN} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className="body_input_styles">
            {UnrenewedReportFilterContent}
        </section>
    );
}

export default UnrenewedReportFilter;