import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { pendingBillsFilterFromDate, pendingBillsFilterToDate } from "../../../atoms";
import { DatePickerInput } from "@mantine/dates";

const PendingBillsFilter = () => {
    //################################################################
    //valuation reports filter
    const [pendingBillsFilterFromDateRN, setPendingBillsFilterFromDateRN] = useRecoilState(pendingBillsFilterFromDate)
    const [pendingBillsFilterToDateRN, setPendingBillsFilterToDateRN] = useRecoilState(pendingBillsFilterToDate)

    //################################################################
    //echo
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="From Date"
                    placeholder="From Date"
                    value={pendingBillsFilterFromDateRN}
                    onChange={setPendingBillsFilterFromDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="To Date"
                    placeholder="To Date"
                    value={pendingBillsFilterToDateRN}
                    onChange={setPendingBillsFilterToDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {content}
        </section>
    )
}

export default PendingBillsFilter;