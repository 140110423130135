import axios from "axios";

const GetAllVendors = async () => {
    const url = 'https://addmessage-7fqanz2g2q-uc.a.run.app/getvendors';

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default GetAllVendors;