import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { doc, runTransaction, updateDoc } from "firebase/firestore";
import db from "../../../firebase";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType";
import GetAllDVAllocations from "../../Hooks/BackendConn/Accounts/GetAllDVAllocations";
import { useRecoilValue, useRecoilState } from "recoil";
import { DVReallocationInsurerFilter, DVReallocationDVDocs, DVReallocationSelectedDV, rightNavContent } from "../../../atoms";
import { DVReallocationSelectedDebits, DVReallocationUnselectedDebits, activeInsurerPortfolio } from "../../../atoms";
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import UUID from '../../Hooks/UUID/UUID'

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const DVReallocation = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const DVReallocationInsurerFilterRN = useRecoilValue(DVReallocationInsurerFilter);
    const [DVReallocationDVDocsRN, setDVReallocationDVDocsRN] = useRecoilState(DVReallocationDVDocs)
    const DVReallocationSelectedDVRN = useRecoilValue(DVReallocationSelectedDV)
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent)
    const [DVReallocationSelectedDebitsRN, setDVReallocationSelectedDebitsRN] = useRecoilState(DVReallocationSelectedDebits)
    const [DVReallocationUnselectedDebitsRN, setDVReallocationUnselectedDebitsRN] = useRecoilState(DVReallocationUnselectedDebits)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: DVReallocationInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, DVReallocationInsurerFilterRN]);

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVReallocationInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 1000)) }
        })();
    }, [reloadThree, DVReallocationInsurerFilterRN]);

    //################################################################
    //get past allocated dvs
    const [PastDVAllocationData, setPastDVAllocationData] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDVAllocations();
            setPastDVAllocationData(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //past dv docs
    useEffect(() => {
        try {
            PastDVAllocationData.map((doc)=>{console.log(doc.docData.DVDocID + '-' + doc.docID)})

            const combined = DVDocs.reduce((acc, obj) => {
                const match = PastDVAllocationData.find(item => item.docData.DVDocID === obj.docID);
                if (match) {
                    acc.push({ 'DVDebitDocID': obj.docID, 'DVDebitDocData': obj.docData, ...match });
                }
                return acc;
            }, []);

            setDVReallocationDVDocsRN(combined)

        } catch (error) { }
    }, [DVDocs, PastDVAllocationData]);


    //################################################################
    //state reset
    const [stateResetter, setStateResetter] = useState(0)
    useEffect(() => {
        setFinalData([])
        setStateResetter(UUID())
    }, [DVReallocationSelectedDVRN]);
    useEffect(() => {

        try {

            if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
                const allocatedKeys = Object.keys(DVReallocationSelectedDVRN.docData.DVAllocatedDebitDocs)

                if (allocatedKeys.length > 0) {

                    const combinedSelected = allocatedKeys.reduce((acc, obj) => {
                        const match = debitDocs.find(item => item.docID == obj);
                        if (match) {
                            acc.push({ ...match, allocatedAmount: DVReallocationSelectedDVRN.docData.DVAllocatedDebitDocs[obj]['allocatedAmount'] });
                        }
                        return acc;
                    }, []);

                    setDVReallocationSelectedDebitsRN(combinedSelected)

                    const combinedUnselected = debitDocs.reduce((acc, obj) => {
                        const match = Object.keys(DVReallocationSelectedDVRN.docData.DVAllocatedDebitDocs).find(item => item == obj.docID);
                        if (!match) {
                            acc.push(obj);
                        }
                        return acc;
                    }, []);

                    setDVReallocationUnselectedDebitsRN(combinedUnselected)

                }
            }

        } catch (error) { console.log(error) }
    }, [stateResetter])


    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const difference = DVReallocationSelectedDebitsRN.filter(item1 => !finalData.some(item2 => item2.docID === item1.docID));
            const finalObj = [];
            difference.map((doc) => {

                //look for allocated amnt
                let allocatedAmount = 0
                if (doc.allocatedAmount) {
                    allocatedAmount = doc.allocatedAmount
                }

                //look for details
                const details = [];
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                    commission: NumberFormat(doc.docData.commission),
                    withholdingTax: NumberFormat(doc.docData.withholdingTax),
                    commissionPayable: NumberFormat(doc.docData.commissionPayable),
                    commissionPaid: allocatedAmount,
                })

            });

            setFinalData([...finalObj, ...finalData])

        } catch (error) { }
    }, [DVReallocationSelectedDebitsRN]);

    //################################################################
    // calculate Total
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0)

    //################################################################
    // selected DV
    const [totalsRow, setTotalsRow] = useState([{ clientName: 'Select A DV' }])
    useEffect(() => {
        try {

            if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
                setTotalsRow([{
                    docID: DVReallocationSelectedDVRN.docID,
                    clientName: DVReallocationSelectedDVRN.DVDebitDocData.policyNumber,
                    policyNumber: 'DV Amounts:',
                    endorsmentNumber: NumberFormat(+DVReallocationSelectedDVRN.DVDebitDocData.policyNet),
                    policyClass: '',
                    inceptionDate: '',
                    expiryDate: '',
                    details: '',
                    policyNet: '',
                    commission: NumberFormat(totalAllocatedAmount),
                    withholdingTax: 'Remaining Amount:',
                    commissionPayable: NumberFormat(+DVReallocationSelectedDVRN.DVDebitDocData.policyNet - totalAllocatedAmount),
                    commissionPaid: 'Total Allocated:',
                }])
            }

        } catch (error) { }
    }, [DVReallocationSelectedDVRN, finalData, totalAllocatedAmount])

    useEffect(() => {
        try {

            const sum = finalData.reduce((acc, cur) => acc + +cur.commissionPaid, 0);
            setTotalAllocatedAmount(sum);

        } catch (error) { }
    }, [finalData])

    //################################################################
    // post dv
    //step 2
    const showMSGOnSuccessfulDVAllocationSaved = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'DV Reallocation',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'DV Allocation Saved'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const postDVHandler = async () => {

        setIsLoading(true);

        const DVAllocatedDebitDocs = finalData.reduce((result, current) => {
            result[current.docID] = { 'allocatedAmount': current.commissionPaid };
            return result;
        }, {});

        let objKey = '';
        let DVDebitDocID = '';
        if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
            objKey = DVReallocationSelectedDVRN.docID;
            DVDebitDocID = DVReallocationSelectedDVRN.DVDebitDocID;
        }

        const FinalDVAllocationObj = {
            DVAllocatedDebitDocs: DVAllocatedDebitDocs,
            DVDocID: DVDebitDocID,
            dateAllocated: new Date(),
            insurerID: activeInsurerPortfolioRN,
        }

        // Create an initial document to update.
        const sfDocRef = doc(db, "DVAllocations", "pn3firx4ZBkAp2DkzMVR");

        // To update age and favorite color:
        await updateDoc(sfDocRef, {
            [objKey]: FinalDVAllocationObj
        });

        showMSGOnSuccessfulDVAllocationSaved();

    }

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // remove handler
    const removeDebitHandler = () => {
        try {
            setxPos('-1000px');
            setyPos('-1000px');

            const unselectedValues = [...DVReallocationUnselectedDebitsRN]
            const selectedValues = [...DVReallocationSelectedDebitsRN];

            const c = selectedValues.filter(doc => doc.docID === rightClickedPolicyID)
            const a = unselectedValues.filter(doc => doc.docID === rightClickedPolicyID);
            const b = selectedValues.filter(doc => doc.docID !== rightClickedPolicyID);
            const d = finalData.filter(doc => doc.docID !== rightClickedPolicyID);
            if (a.length == 0) {
                setDVReallocationUnselectedDebitsRN([c[0], ...unselectedValues]);
                setDVReallocationSelectedDebitsRN(b)
                setFinalData(d)
            }

            const sum = d.reduce((acc, cur) => acc + +cur.commissionPaid, 0);
            setTotalAllocatedAmount(sum);

        } catch (error) { }
    }

    //################################################################
    //export to excel
    const columnsToExport = ['clientName', 'policyNumber', 'endorsmentNumber', 'commissionPaid', 'commission', 'withholdingTax', 'commissionPayable', 'policyClass', 'inceptionDate', 'expiryDate', 'details', 'policyNet']

    let DVNo = '';
    let DVAmount = '';
    let DVInsurerDebit = '';
    if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
        DVNo = DVReallocationSelectedDVRN.DVDebitDocData.policyNumber;
        DVAmount = NumberFormat(DVReallocationSelectedDVRN.DVDebitDocData.policyNet);
        DVInsurerDebit = DateFormat(DVReallocationSelectedDVRN.DVDebitDocData.insurerDebitDate)
    }

    const exportToExcelFileName = 'DV ALLOCATION    --    INSURER: ' + DVReallocationInsurerFilterRN + '    --    DV NUMBER: ' + DVNo + '    --    DV AMOUNT: ' + DVAmount + '    --    DV INSURER DEBIT DATE: ' + DVInsurerDebit + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                NumberFormat(doc.commissionPaid),
                doc.commission,
                doc.withholdingTax,
                doc.commissionPayable,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });

        let DVNo = '';
        let DVAmount = '';
        let DVInsurerDebit = '';
        if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
            DVNo = DVReallocationSelectedDVRN.DVDebitDocData.policyNumber;
            DVAmount = NumberFormat(DVReallocationSelectedDVRN.DVDebitDocData.policyNet);
            DVInsurerDebit = DateFormat(DVReallocationSelectedDVRN.DVDebitDocData.insurerDebitDate)
        }

        const onlyDispatchedheaderData = 'DV ALLOCATION    ||    INSURER: ' + DVReallocationInsurerFilterRN + '    ||    DV NUMBER: ' + DVNo + '    ||    DV AMOUNT: ' + DVAmount + '    ||    DV INSURER DEBIT DATE: ' + DVInsurerDebit;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 150, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 25, 25, 100, 25],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'COMM PAID', 'COMM', 'W TAX', 'COMM PAYABLE', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                NumberFormat(doc.commissionPaid),
                doc.commission,
                doc.withholdingTax,
                doc.commissionPayable,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });

        let DVNo = '';
        let DVAmount = '';
        let DVInsurerDebit = '';
        if (Object.keys(DVReallocationSelectedDVRN).length > 0) {
            DVNo = DVReallocationSelectedDVRN.DVDebitDocData.policyNumber;
            DVAmount = NumberFormat(DVReallocationSelectedDVRN.DVDebitDocData.policyNet);
            DVInsurerDebit = DateFormat(DVReallocationSelectedDVRN.DVDebitDocData.insurerDebitDate)
        }

        const onlyDispatchedheaderData = 'DV ALLOCATION    ||    INSURER: ' + DVReallocationInsurerFilterRN + '    ||    DV NUMBER: ' + DVNo + '    ||    DV AMOUNT: ' + DVAmount + '    --    DV INSURER DEBIT DATE: ' + DVInsurerDebit;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 150, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 25, 25, 100, 25],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'COMM PAID', 'COMM', 'W TAX', 'COMM PAYABLE', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }


    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onCellValueChanged = (event) => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });

        const sum = data.reduce((acc, cur) => acc + +cur.commissionPaid, 0);
        setTotalAllocatedAmount(sum);

    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={removeDebitHandler}><p>Remove Policy</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'commissionPaid', headerName: 'C. Paid', sortable: false, filter: false, width: 90, editable: true, singleClickEdit: true, },
        { field: 'commission', headerName: 'Commission', sortable: false, filter: false, width: 90, },
        { field: 'withholdingTax', headerName: 'W.Tax', sortable: false, filter: false, width: 90, },
        { field: 'commissionPayable', headerName: 'C.A.T', sortable: false, filter: false, width: 90, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },

        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, sortable: false, filter: false, width: 100, },


    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
                pinnedBottomRowData={totalsRow}
                onCellValueChanged={onCellValueChanged}
            ></AgGridReact>
        </div>
    </section>



    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>DV Reallocation</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRV('DV Reallocation Select DV') }}>Select DV</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRV('DV Reallocation Select Debits') }}>Select Debits</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={postDVHandler}>Save Allocation</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    )
}

export default DVReallocation;