import React, { useState, useEffect } from "react";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import Loader from "../../../Body/Loader/Loader";
import { useRecoilState } from "recoil";
import { agentStatementFilterFromDate, agentStatementFilterToDate } from "../../../../atoms";
import DatePicker from "react-date-picker";

const AgentStatementFilter = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //valuation reports filter
    const [agentStatementFilterFromDateRN, setAgentStatementFilterFromDateRN] = useRecoilState(agentStatementFilterFromDate);
    const [agentStatementFilterToDateRN, setAgentStatementFilterToDateRN] = useRecoilState(agentStatementFilterToDate)

    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);


    //################################################################
    //echo
    const agentStatementFilterContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div><p className='right_nav_help'>This report queries agent's direct collections</p></div>
            <div className='h_15'></div>
            <p className='bold'>Filter Date</p>
            <div className='h_5'></div>
            <div className='lapse_date_picker_container'>
                <p>From</p>
                <div className='h_5'></div>
                <DatePicker onChange={setAgentStatementFilterFromDateRN} value={agentStatementFilterFromDateRN} format='d/ M/y' />
            </div>
            <div className='h_10'></div>
            <div className='lapse_date_picker_container'>
                <p>To</p>
                <div className='h_5'></div>
                <DatePicker onChange={setAgentStatementFilterToDateRN} value={agentStatementFilterToDateRN} format='d/ M/y' />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {agentStatementFilterContent}
            {loaderContent}
        </section>
    )
}

export default AgentStatementFilter;