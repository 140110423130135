const PolicyClasses = [
    // {
    //     productClass: '(02) - Engineering',
    //     subClass: {
    //         1: '(020) - Contractors All Risks',
    //         2: '(021) - ContractorsPlant and Machinery',
    //         3: '(022) - Erection All Risks',
    //         4: '(023) - Machinery Break Down',
    //         5: '(024) - Computer Electronic Equipment',
    //         6: '(025) - Boiler and Pressure Vessel',
    //         7: '(026) - Loss of Profit (L.O.P) Following Machinery Breakdown',
    //         8: '(027) - Deterioration of Stock',
    //         9: '(028) - Engineering Completed Risks'
    //     }
    // },
    // {
    //     productClass: '(03) - Domestic',
    //     subClass: {
    //         1: '(030) - Domestic Package',
    //         2: '(031) - Domestic Contents'
    //     }
    // },
    // {
    //     productClass: '(04) - Fire Industrial',
    //     subClass: {
    //         1: '(040) - Fire Industrial',
    //         2: '(041) - Con Loss Following Fire',
    //         3: '(042) - Industrial All Risk',
    //         4: '(043) - Terrorism',
    //         5: '(044) - Business Combined'
    //     }
    // },
    // {
    //     productClass: '(05) - Public Liability',
    //     subClass: {
    //         1: '(050) - Products Liabilty',
    //         2: '(051) - Professional Liability',
    //         3: '(052) - Public Liabilty',
    //         4: '(054) - Contractual Liabilty'
    //     }
    // },
    // {
    //     productClass: '(06) - Marine',
    //     subClass: {
    //         1: '(060) - Marine Hull',
    //         2: '(061) - Marine Certificate',
    //         3: '(062) - Goods in Transit (GIT)',
    //         4: '(064) - CARRIERS LEGAL LIABILITY'
    //     }
    // },
    {
        productClass: '(07) - Motor Private - Comprehensive',
        subClass: {
            1: '(0700) - Motor Private - Comprehensive',
            // 2: '(0701) - Motor Cycle - Comprehensive',
            // 3: '(0730) - Private Certificate Binder - Comprehensive'
        }
    },
    // {
    //     productClass: '(07) - Motor Private - TPO',
    //     subClass: {
    // 1: '(0700) - Motor Private - TPO',
    // 2: '(0701) - Motor Cycle - TPO',
    // 3: '(0730) - Private Certificate Binder - TPO'
    //     }
    // },
    {
        productClass: '(08) - Motor Commercial - Comprehensive',
        subClass: {
            1: '(0800) - Motor Commercial - Comprehensive',
            // 2: '(0801) - Tractor - Comprehensive',
            // 3: '(0802) - New Trade - Comprehensive',
            // 4: '(0803) - Private Hire - Comprehensive',
            // 5: '(0804) - PSV - Comprehensive',
            // 6: '(0807) - General Cartage - Comprehensive',
            // 7: '(0811) - Special Vehicle - Comprehensive',
            // 8: '(0812) - Driving School - Comprehensive',
            // 9: '(0890) - PTA Yellow Card - Comprehensive'
        }
    },
    {
        productClass: '(08) - Motor Commercial - TPO',
        subClass: {
            1: '(0800) - Motor Commercial - TPO',
            //         2: '(0801) - Tractor - TPO',
            //         3: '(0802) - New Trade - TPO',
            //         4: '(0803) - Private Hire - TPO',
            //         5: '(0804) - PSV - TPO',
            6: '(0807) - General Cartage - TPO',
            //         7: '(0811) - Special Vehicle - TPO',
            //         8: '(0812) - Driving School - TPO',
            //         9: '(0890) - PTA Yellow Card - TPO'
        }
    },
    // {
    //     productClass: '(09) - Personal Accident',
    //     subClass: {
    //         1: '(090) - Personal Accident',
    //         2: '(091) - Medical',
    //         3: '(092) - Group Personal Accident',
    //         4: '(093) - Travel'
    //     }
    // },
    // {
    //     productClass: '(10) - Theft',
    //     subClass: {
    //         1: '(100) - Cash in Transit',
    //         2: '(101) - Fidelity Gurantee',
    //         3: '(102) - Burglary',
    //         4: '(103) - All Risk',
    //         5: '(104) - Bankers Blanket'
    //     }
    // },
    // {
    //     productClass: '(11) - Workmens Compensation',
    //     subClass: {
    //         1: '(112) - Wiba',
    //         2: '(113) - Common Law',
    //         3: '(114) - Wiba Plus'
    //     }
    // },
    // {
    //     productClass: '(12) - Miscellaneous',
    //     subClass: {
    //         1: '(120) - Immigration Bond',
    //         2: '(123) - PLATE GLASS',
    //         3: '(125) - GOLFERS',
    //         4: '(127) - Advance Payment Bond',
    //         5: '(128) - Custom Bond',
    //         6: '(129) - Excess Protection',
    //         7: '(130) - Goods In Transit'
    //     }
    // },
];

export default PolicyClasses;