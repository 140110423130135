import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { emailClientStatementSelectedClient } from "../../../atoms";
import { useRecoilState } from "recoil";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import EmailClientStatementViewable from "./EmailClientStatementViewable";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const EmailClientStatementStepOne = (props) => {
     //################################################################
    //send mail component
    const componentRef = useRef();

    //################################################################
    // get data props
    const [rowData, setRowData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        setRowData(props.rowData);
        setFinalData(props.finalData);
    }, [props.rowData]);

    //################################################################
    // recoil values
    const [emailClientStatementSelectedClientRN, setEmailClientStatementSelectedClientRN] = useRecoilState(emailClientStatementSelectedClient);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');
    
    const [clientDetailsRow, setClientDetailsRow] = useState([]);
    useEffect(() => {
        try {

            if (Object.keys(emailClientStatementSelectedClientRN).length > 0) {
                setClientDetailsRow([{
                    policyNumber: emailClientStatementSelectedClientRN.clientName,

                    docID: '',
                    transactionType: '',
                    universalDate: '',
                    displayDate: 'CLIENT DETAILS',
                    endorsmentNumber: '',
                    policyClass: '',
                    policySubClass: '',
                    details: '',
                    policyNet: '',
                    receiptNet: '',
                    cummulativeOutstanding: '',
                    allocatedDocID: '',
                    policyDocuments: [],
                }])
            }

        } catch (error) { }
    }, [emailClientStatementSelectedClientRN])
    
    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 14rem)', width: '100%', }), []);

    // const [rowData, setRowData] = useState(props.rowData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 50, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'displayDate', headerName: 'Date', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 135, width: 135, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No',minWidth:170, width: 170, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        {
            field: 'displayPolicyNet', headerName: 'Debit Net', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                if (params.data.transactionType === 'Debit') {
                    return (<p>{params.value}</p>);
                }
                else if (params.data.transactionType === 'Credit') {
                    return (<p> {" ( " + params.value + " ) "}</p>);
                }
                else if (params.data.transactionType === 'Totals') {
                    return (<p> {params.value}</p>);
                }
                else if (params.data.transactionType === 'AgeingMainSection') {
                    return (<p> {params.data.oneTwentyDays}</p>);
                }
            },
        },
        {
            field: 'displayPolicyNet', headerName: 'Credit Net', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                if (params.data.transactionType === 'Receipt') {
                    return (<p>{params.value}</p>);
                }
                else if (params.data.transactionType === 'Totals') {
                    return (<p> {params.data.receiptNet}</p>);
                }
                else if (params.data.transactionType === 'AgeingMainSection') {
                    return (<p> {params.value}</p>);
                }
            },
        },
        { field: 'displayCummulativeOutstanding', headerName: 'Outstanding', sortable: false, filter: false, width: 100, },
        { field: 'policySubClass', headerName: 'Risk Class', width: 150, },
        { field: 'details', headerName: 'Details', width: 150, },
        {
            field: 'policyDocuments', headerName: 'PD', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        // { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        // { field: 'details', headerName: 'Details', width: 150, },
        // { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        // {
        //     field: 'VR', headerName: 'VR', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'LB', headerName: 'LB', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'SC', headerName: 'SC', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'PD', headerName: 'PD', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
        cellStyle: { 'padding': 2,fontSize: '10px' }
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
                pinnedBottomRowData={clientDetailsRow}
                
            ></AgGridReact>
        </div>
    </section>

    //################################################################
    //viewable
    const viewable = <section>
        <div ref={componentRef}>
            <EmailClientStatementViewable finalData={finalData} />
        </div>
    </section>

    return (viewable)
}

export default EmailClientStatementStepOne;