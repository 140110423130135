import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetAllClientDebits = async (receivedObj) => {
    
        const q = query(collection(db, "PolicyDetails"), where("transactionType", "==", receivedObj.transactionType), where("clientName", "==", receivedObj.clientName));

        const querySnapshot = await getDocs(q);
        
        const DData = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            DData.push({ docData: doc.data(), docID: doc.id })
        });

        return DData;
        
}

export default GetAllClientDebits;