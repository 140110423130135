import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { doc, runTransaction, updateDoc, query, getDocs, deleteDoc, where, collection } from 'firebase/firestore';
import GetAllReceipts from '../../Hooks/BackendConn/GeneralList/GetAllReceipts';
import Loader from '../Loader/Loader';
import { async } from '@firebase/util';
import GetAllDocsTransactionAndInsurerWise from '../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise';
import GetPendingDocs from '../../Hooks/BackendConn/InsurerSpecific/GetPendingDocs';
import GetAllClients from '../../Hooks/BackendConn/ClientList/GetAllClients';

import UUID from '../../Hooks/UUID/UUID';

const Demo = (props) => {

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }


    //################################################################
    //get all debits
    // const [debitDocs, setDebitDocs] = useState([]);
    // const [reload, setReload] = useState(0);
    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true)
    //         const DDocs = await GetAllClients();
    //         setDebitDocs(DDocs);
    //         if (DDocs) {
    //             setIsLoading(false)
    //         }
    //         else { setReload(Math.random(0, 10000)) }
    //     })();
    // }, [reload]);

    const [debitDocs, setDebitDocs] = useState([]);
    useEffect(() => {

        (async () => {

            const q = query(collection(db, "PolicyDetails"), where("comesaCard", ">", "0"));

            const querySnapshot = await getDocs(q);

            const DData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                DData.push({ docData: doc.data(), docID: doc.id })
            });

            setDebitDocs(DData)

        })()

    }, [])
    //console.log(debitDocs)

    const a = async (receivedDocID) => {
        const washingtonRef = doc(db, "PolicyDetails", receivedDocID);

        // Set the "capital" field of the city 'DC'
        // await updateDoc(washingtonRef, {
        //     businessType: 'Comesa Endorsment'
        // });
    }

    useEffect(() => {
        setIsLoading(true)

        debitDocs.map((doc) => {
            console.log(doc.docData.businessType + ' - ' + doc.docData.endorsmentNumber)            

            //a(doc.docID)

        })

        setIsLoading(false)
        console.log('done')
    }, [debitDocs])

    return (
        <section>
            <div className='body_section_header'>
                <p>Bulk Updater</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>

            </div>
            {loaderContent}
        </section>
    );
}

export default Demo;