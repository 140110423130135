import React, { useState, useEffect, useRef, useCallback } from 'react';
import db from '../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loader from '../../Loader/Loader';
import './SystemLogs.css';

const SystemLogs = () => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    const [error, setError] = useState(null);
    const [systemLogDocs, setSystemLogDocs] = useState([]);

    //get data
    const fetchSystemLogDocsHandler = useCallback(async () => {
        setIsloading(true);
        setError(null);
        try {
            const querySnapshot = await getDocs(collection(db, "SystemLogs"));

            const tempHold = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                tempHold.push(doc.data())
            });
            setSystemLogDocs(tempHold);
        }
        catch (error) {
            setError(error.message);
        }

        setIsloading(false);
    }, []);

    useEffect(() => {
        fetchSystemLogDocsHandler();
    }, [fetchSystemLogDocsHandler]);

    //clean docs systemLogsDocsCleaned
    const [systemLogsDocsCleaned, setSystemLogsDocsCleaned] = useState([])
    useEffect(() => {
        const a = []
        systemLogDocs.map((item) => {
            Object.values(item).map((subItem) => {
                Object.values(subItem).map((subSubItem) => {
                    a.push(subSubItem)
                });
            });
        })
        setSystemLogsDocsCleaned(a)
    }, [systemLogDocs])

    //content
    const systemLogContent = <div>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>All Time</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='system_logs_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>User</p></td>
                                <td><p>Activity</p></td>
                                <td><p>Date</p></td>
                                <td><p>Time</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {systemLogsDocsCleaned.map((subSubItem, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{index+1}</p></td>
                                        <td><p>{subSubItem.email}</p></td>
                                        <td><p>{subSubItem.activity}</p></td>
                                        <td><p>{subSubItem.echoDate}</p></td>
                                        <td><p>{subSubItem.time}</p></td>
                                    </tr>
                                );
                            })
                            }


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    //####################################################################################
    //####################################################################################
    //####################################################################################

    return (
        <section>
            <div className='body_section_header'>
                <p>System Logs</p>
            </div>
            <div className='body_section create_policy'>
                {systemLogContent}
            </div>
            {loaderContent}
        </section>
    );
}

export default SystemLogs;