import React, { useCallback, useEffect, useState, useRef } from 'react';
import db, { auth, storage } from '../../../firebase';
import { collection, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'react-toastify/dist/ReactToastify.css';
import './BodyCreatePolicy.css';
import BodySelectOption from './BodySelectOption';
import BodyRiskNote from './BodyRiskNote';
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import InsuranceProducts from './InsuranceProducts';
import { useReactToPrint } from "react-to-print";
import { UniqueRandomNumber } from '../../Random/Random';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import { useRecoilValue, useRecoilState } from 'recoil';
import { insurerLockDate } from '../../../atoms';
import { DatePickerInput } from '@mantine/dates';
import { viewPolicyFinalSelectedClient, rightNavContent } from '../../../atoms';

const BodyViewPolicyFinal = (props) => {
    const insuranceProducts = InsuranceProducts;

    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const insurerLockDateRN = useRecoilValue(insurerLockDate);
    const [viewPolicyFinalSelectedClientRN, setViewPolicyFinalSelectedClientRN] = useRecoilState(viewPolicyFinalSelectedClient);
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent);

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //new date display options
    const dateDispayOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    // get client data
    const [receivedClientDetails, setReceivedClientDetails] = useState([]);
    const setRetrievedClientData = (receivedData) => {
        setReceivedClientDetails((prevState) => {
            return [receivedData, ...prevState]
        });
    }

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "ClientDetails"));
            if (!isCancelled) {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    const allClientDetails = {
                        receivedDocID: doc.id,
                        receivedClientName: doc.data().clientName,
                    }
                    setRetrievedClientData(allClientDetails);
                    setIsloading(false);
                });
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [])

    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    //search term
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    //selected client
    const [clientName, setClientName] = useState('');
    const selectClientHandler = (value) => {
        setClientName(value);
    }

    //selected client obj
    const [clientID, setClientID] = useState('');
    const selectClientIDHandler = (value) => {
        setClientID(value);
    }

    useEffect(() => {
        const filteredClient = receivedClientDetails.filter(item => item.receivedClientName === clientName);
        if (filteredClient.length > 0) {
            setClientID(filteredClient[0].receivedDocID)
        }

    }, [clientName, receivedClientDetails])

    // search section
    const searchContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='search_input'>
                <p>Search Client</p>
                <div className='h_5'></div>
                <input onChange={searchTermChangeHandler} type='text' placeholder='Client Name' />
            </div>
            <div className='h_5'></div>
            <div className='search_results'>
                {receivedClientDetails.filter((val) => {
                    if (searchTerm === '') { return val }
                    else if (val.receivedClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val
                    }
                }).map((val, key) => (
                    <div key={key}>
                        <div className='result' onClick={() => selectClientHandler(val.receivedClientName)}>
                            <p>{val.receivedClientName}</p>
                            <div className='h_2'></div>
                        </div>
                        <div className='h_5'></div>
                    </div>
                )
                )}

            </div>
        </div>
    </div>

    // set insurer name
    const [insurerName, setInsurerName] = useState('MAYFAIR INSURANCE COMPANY LIMITED');
    const insurerNameChangeHandler = (event) => {
        setInsurerName(event.target.value);
    }

    const [bankInterest, setBankInterest] = useState('')
    const bankInterestChangeHandler = (event) => {
        setBankInterest(event.target.value);
    }

    // set policy number
    const [policyNumber, setPolicyNumber] = useState('');
    const policyNumberChangeHandler = (event) => {
        setPolicyNumber(event.target.value);
    }

    // set selected product class to state
    const [selectedProductClass, setSelectedProductClass] = useState('Select Product Class');
    const productClassChangeHandler = (event) => {
        setSelectedProductClass(event.target.value);
    }

    // get subclass array
    const selectedProductSubClassIndex = insuranceProducts.find(({ productClass }) => productClass === selectedProductClass);
    const selectedProductSubClassIndexed = Object.values(selectedProductSubClassIndex.subClass);

    const [selectedProductSubClass, setSelectedProductSubClass] = useState('');
    const productSubClassChangeHandler = (event) => {
        setSelectedProductSubClass(event.target.value)
    }

    // set commission rate
    const [commissionRate, setCommissionRate] = useState(0);
    useEffect(() => {
        switch (selectedProductClass) {
            case '(02) - Engineering':
                setCommissionRate(0.2);
                break;
            case '(03) - Domestic':
                setCommissionRate(0.2);
                break;
            case '(04) - Fire Industrial':
                setCommissionRate(0.25);
                break;
            case '(05) - Public Liability':
                setCommissionRate(0.2);
                break;
            case '(06) - Marine':
                setCommissionRate(0.175);
                break;
            case '(07) - Motor Private':
                setCommissionRate(0.1);
                break;
            case '(08) - Motor Commercial':
                setCommissionRate(0.1);
                break;
            case '(09) - Personal Accident':
                setCommissionRate(0.2);
                break;
            case '(10) - Theft':
                setCommissionRate(0.2);
                break;
            case '(11) - Workmens Compensation':
                setCommissionRate(0.2);
                break;
            case '(12) - Miscellaneous':
                setCommissionRate(0.1);
                break;
        }
    }, [selectedProductClass]);

    useEffect(() => {
        switch (selectedProductSubClass) {
            case '(043) - Terrorism':
                setCommissionRate(0.1);
                break;
            case '(064) - CARRIERS LEGAL LIABILITY':
                setCommissionRate(0.2);
                break;
        }
    }, [selectedProductSubClass])

    // set business type
    const [businessType, setBusinessType] = useState('');
    const businessTypeChangeHandler = (event) => {
        setBusinessType(event.target.value)
    }

    // set endorsment number
    const [endorsmentNumber, setEndorsmentNumber] = useState('');
    const endorsmentNumberChangeHandler = (event) => {
        setEndorsmentNumber(event.target.value)
    }

    //set to new if business is new
    useEffect(() => {
        if (businessType === 'New') { setEndorsmentNumber('New') }
    }, [businessType])

    //hide show endowsment number
    let endorsmentNumberContent = <p></p>
    if (businessType !== 'New') {
        endorsmentNumberContent = <div>
            <div className='h_10'></div>
            <input value={endorsmentNumber} type='text' placeholder='Endorsment Number' onChange={endorsmentNumberChangeHandler} />
        </div>
    }

    // set inception date
    const [inceptionDate, setInceptionDate] = useState('');
    const inceptionDateChangeHandler = (event) => {
        setInceptionDate(event.target.value);
    }

    // set expiry date
    const [expiryDate, setExpiryDate] = useState('');
    const expiryDateChangeHandler = (event) => {
        setExpiryDate(event.target.value);
    }

    //insurer debit date
    const [insurerDebitDate, setInsurerDebitDate] = useState('');
    const [dvalue, onDChange] = useState(new Date());

    const Dyear = new Date(dvalue).getFullYear();
    const Dmonth = new Date(dvalue).getMonth() + 1;
    const Ddate = new Date(dvalue).getDate();
    const DexpiryDateCleanedJan = Dyear + '-' + Dmonth + '-' + Ddate;
    useEffect(() => {
        setInsurerDebitDate(DexpiryDateCleanedJan)
    }, [DexpiryDateCleanedJan])

    //################################################################
    //lock date disable feature
    const [lockDateDisableInput, setLockDateDisableInput] = useState(false)
    useEffect(() => {
        try {

            const formattedinsurerDebitDate = new Date(insurerDebitDate);
            formattedinsurerDebitDate.setHours(0, 0, 0);
            const formattedinsurerLockDateRN = new Date(insurerLockDateRN);
            formattedinsurerLockDateRN.setHours(0, 0, 0);

            if (new Date(formattedinsurerLockDateRN).valueOf() > new Date(formattedinsurerDebitDate).valueOf()) {
                setLockDateDisableInput(true)
            }
            else {
                setLockDateDisableInput(false)
            }

        } catch (error) { }
    }, [insurerDebitDate, insurerLockDateRN])

    // particulars of the risk
    //set location of the risk
    const [locationOfTheRisk, setLocationOfTheRisk] = useState('');
    const locationOfTheRiskChangeHandler = (event) => {
        setLocationOfTheRisk(event.target.value);
    }

    // set total sum insured
    const [totalSumInsured, setTotalSumInsured] = useState('');
    const totalSumInsuredChangeHandler = (event) => {
        setTotalSumInsured(event.target.value);
    }

    // particulars of the risk content
    let particularsOfTheRiskContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        particularsOfTheRiskContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Particulars Of The Risk</p></div>
                <div className='category_content'>
                    <div className='category_content_one'>
                        <textarea value={locationOfTheRisk} rows='3' placeholder='Location Of Risk' onChange={locationOfTheRiskChangeHandler} />
                    </div>
                    <div className='category_content_two'>
                        <input value={totalSumInsured} placeholder='Total Sum Insured' onChange={totalSumInsuredChangeHandler} />
                    </div>
                </div>
            </div>
        </section>
    }

    // policy amounts
    const [premium, setPremium] = useState(0);
    const premiumChangeHandler = (event) => {
        setPremium(event.target.value);
    }

    const [PVTPremium, setPVTPremium] = useState(0);
    const PVTPremiumChangeHandler = (event) => {
        setPVTPremium(event.target.value);
    }

    const [stampDuty, setStampDuty] = useState(0);
    const stampDutyChangeHandler = (event) => {
        setStampDuty(event.target.value);
    }

    const [TLevy, setTLevy] = useState(0);
    const TLevyChangeHandler = (event) => {
        setTLevy(event.target.value);
    }

    const [PHCFund, setPHCFund] = useState(0);
    const PHCFundChangeHandler = (event) => {
        setPHCFund(event.target.value);
    }

    const [commission, setCommission] = useState(0);
    const commissionChangeHandler = (event) => {
        setCommission(event.target.value);
    }

    const [withholdingTax, setWithholdingTax] = useState(0);
    const withholdingTaxChangeHandler = (event) => {
        setWithholdingTax(event.target.value);
    }

    const [commissionPayable, setCommissionPayable] = useState(0);

    const [policyNet, setPolicyNet] = useState(0);
    const policyNetChangeHandler = (event) => {
        setPolicyNet(event.target.value);
    }

    const [insurerPolicyNet, setInsurerPolicyNet] = useState(0);
    const insurerPolicyNetChangeHandler = (event) => {
        setInsurerPolicyNet(event.target.value);
    }

    //table 2
    const [earthquake, setEarthquake] = useState(0);
    const earthquakeChangeHandler = (event) => {
        setEarthquake(event.target.value);
    }

    const [excessProtector, setExcessProtector] = useState(0);
    const excessProtectorChangeHandler = (event) => {
        setExcessProtector(event.target.value);
    }

    const [comesaCard, setComesaCard] = useState(0);
    const comesaCardChangeHandler = (event) => {
        setComesaCard(event.target.value);
    }

    const [windScreen, setWindScreen] = useState(0);
    const windScreenChangeHandler = (event) => {
        setWindScreen(event.target.value);
    }

    const [radioCasset, setRadioCasset] = useState(0);
    const radioCassetChangeHandler = (event) => {
        setRadioCasset(event.target.value);
    }

    let policyAmountContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Policy Amounts</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='policy_amounts_table'>
                        <thead>
                            <tr>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>Earth Quake</p></td>
                                <td><p>Excess Protector</p></td>
                                <td><p>Comesa Card</p></td>
                                <td><p>Wind Screen</p></td>
                                <td><p>Radio Casset</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={premiumChangeHandler} value={premium} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={PVTPremiumChangeHandler} value={PVTPremium} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={earthquakeChangeHandler} value={earthquake} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={excessProtectorChangeHandler} value={excessProtector} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={comesaCardChangeHandler} value={comesaCard} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={windScreenChangeHandler} value={windScreen} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={radioCassetChangeHandler} value={radioCasset} disabled={lockDateDisableInput} /></td>

                            </tr>
                        </tbody>
                    </table>
                    <table className='policy_amounts_table_two'>
                        <thead>
                            <tr>
                                <td><p>S. Duty</p></td>
                                <td><p>T. Levy</p></td>
                                <td><p>P.H.C Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W. Tax</p></td>
                                <td><p>Insurer Policy Net</p></td>
                                <td><p>Policy Net</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={stampDutyChangeHandler} value={stampDuty} disabled={lockDateDisableInput}/></td>
                                <td><input onChange={TLevyChangeHandler} value={TLevy} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={PHCFundChangeHandler} value={PHCFund} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={commissionChangeHandler} value={commission} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={withholdingTaxChangeHandler} value={withholdingTax} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={insurerPolicyNetChangeHandler} value={insurerPolicyNet} disabled={lockDateDisableInput} /></td>
                                <td><input onChange={policyNetChangeHandler} value={policyNet} disabled={lockDateDisableInput} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    // employee's details
    const [employeesDetailsItems, setEmployeesDetailsItems] = useState([{ positionOfEmployee: '', noOfEmployees: '', estimatedAnnualSalary: '' },]);
    const onEmployeeDetailItemChangeHandler = (index, event) => {
        const employeesDetailsValues = [...employeesDetailsItems];
        employeesDetailsValues[index][event.target.name] = event.target.value;
        setEmployeesDetailsItems(employeesDetailsValues);
    }

    const addEmployeeDetailhandler = () => {
        setEmployeesDetailsItems([...employeesDetailsItems, { positionOfEmployee: '', noOfEmployees: '', estimatedAnnualSalary: '' }])
    }

    const removeEmployeeDetailhandler = (index) => {
        const values = [...employeesDetailsItems];
        values.splice(index, 1);
        setEmployeesDetailsItems(values);
    }

    let employeesDetailsContent = <p></p>
    if (selectedProductClass === '(11) - Workmens Compensation') {
        employeesDetailsContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'>
                    <p>Employee's Details</p>
                    <div className='btn_pink' onClick={addEmployeeDetailhandler}>
                        <i className="ri-add-circle-line"></i>
                    </div>
                </div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='employees_details_table'>
                            <thead>
                                <tr>
                                    <td><p>Position Of Employee</p></td>
                                    <td><p>No Of Employees</p></td>
                                    <td><p>Estimated Annual Salary</p></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {employeesDetailsItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.positionOfEmployee} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='positionOfEmployee' /></td>
                                            <td><input value={item.noOfEmployees} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='noOfEmployees' /></td>
                                            <td><input value={item.estimatedAnnualSalary} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='estimatedAnnualSalary' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeEmployeeDetailhandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addEmployeeDetailhandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // description
    const [propertyDescriptionItems, setPropertyDescriptionItems] = useState([{ propertyDescription: '', sumInsured: '' },]);
    const onDescriptionItemChangeHandler = (index, event) => {
        const values = [...propertyDescriptionItems];
        values[index][event.target.name] = event.target.value;
        setPropertyDescriptionItems(values);
    }

    const addPropertyDescriptionhandler = () => {
        setPropertyDescriptionItems([...propertyDescriptionItems, { propertyDescription: '', sumInsured: '' }])
    }

    const removePropertyDescriptionhandler = (index) => {
        const values = [...propertyDescriptionItems];
        values.splice(index, 1);
        setPropertyDescriptionItems(values);
    }

    let descriptionContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        descriptionContent = <section>
            <div>
                <div className='h_15'></div>
                <div className='category'>
                    <div className='category_header'>
                        <p>Description / Liability / Circumstances / Limits</p>
                        <div className='btn_pink' onClick={addPropertyDescriptionhandler}>
                            <i className="ri-add-circle-line"></i>
                        </div>
                    </div>
                    <div className='category_content'>
                        <div className='category_content_three'>
                            <table className='property_description_table'>
                                <thead>
                                    <tr>
                                        <td><p>Description</p></td>
                                        <td><p>Sum Insured</p></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {propertyDescriptionItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><textarea value={item.propertyDescription} onChange={event => onDescriptionItemChangeHandler(index, event)} type='text' name='propertyDescription' /></td>
                                                <td><input value={item.sumInsured} onChange={event => onDescriptionItemChangeHandler(index, event)} type='text' name='sumInsured' /></td>
                                                <td className='property_description_controls'>
                                                    <i className="ri-close-circle-line" onClick={() => removePropertyDescriptionhandler(index)}></i>
                                                    <div className='w_10'></div>
                                                    <i className="ri-add-circle-line" onClick={addPropertyDescriptionhandler}></i>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }

    // benefits
    const [benefitsItems, setBenefitsItems] = useState([{ title: '', elaboration: '' },]);
    const onBenefitsItemChangeHandler = (index, event) => {
        const benefitsValues = [...benefitsItems];
        benefitsValues[index][event.target.name] = event.target.value;
        setBenefitsItems(benefitsValues);
    }

    const addBenefitshandler = () => {
        setBenefitsItems([...benefitsItems, { title: '', elaboration: '' }])
    }

    const removeBenefitshandler = (index) => {
        const benefitsValues = [...benefitsItems];
        benefitsValues.splice(index, 1);
        setBenefitsItems(benefitsValues);
    }

    let benefitsContent = <p></p>
    if (selectedProductSubClass === '(112) - Wiba' || selectedProductSubClass === '(114) - Wiba Plus') {
        benefitsContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'>
                    <p>Benefits</p>
                    <div className='btn_pink' onClick={addBenefitshandler}>
                        <i className="ri-add-circle-line"></i>
                    </div>
                </div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='benefits_table'>
                            <thead>
                                <tr>
                                    <td><p>Title</p></td>
                                    <td><p>Elaboration</p></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {benefitsItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.title} onChange={event => onBenefitsItemChangeHandler(index, event)} type='text' name='title' /></td>
                                            <td><textarea value={item.elaboration} onChange={event => onBenefitsItemChangeHandler(index, event)} type='text' name='elaboration' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeBenefitshandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addBenefitshandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // deductibles
    const [deductibleItems, setDeductibleItems] = useState([{ deductibleItem: '' }]);
    const onDeductibleItemChangeHandler = (index, event) => {
        const deductiblesValues = [...deductibleItems];
        deductiblesValues[index][event.target.name] = event.target.value;
        setDeductibleItems(deductiblesValues);
    }

    const addDeductibleItemChangeHandler = () => {
        setDeductibleItems([...deductibleItems, { deductibleItem: '' }])
    }

    const removeDeductibleItemChangeHandler = (index) => {
        const deductiblesValues = [...deductibleItems];
        deductiblesValues.splice(index, 1);
        setDeductibleItems(deductiblesValues);
    }

    let deductiblesContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        deductiblesContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'>
                    <p>Deductibles</p>
                    <div className='btn_pink' onClick={addDeductibleItemChangeHandler}>
                        <i className="ri-add-circle-line"></i>
                    </div>
                </div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='deductibles_table'>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                {deductibleItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.deductibleItem} onChange={event => onDeductibleItemChangeHandler(index, event)} type='text' name='deductibleItem' /></td>
                                            <td className='deductibles_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeDeductibleItemChangeHandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addDeductibleItemChangeHandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // particulars of the vehicle
    const [vehicleItems, setVehicleItems] = useState([{ regNo: '', make: '', bodyType: '', horsePower: '', yearOfManufacture: '', carryingCapacity: '', estimatedValue: '', windScreenLimit: '', radioCassetLimit: '', valuationValue: '', valuationDate: '', valuationDocumentName: '', valuationDocumentLocation: '' },]);
    const onVehicleItemChangeHandler = (index, event) => {
        const vehicleValues = [...vehicleItems];
        vehicleValues[index][event.target.name] = event.target.value;
        setVehicleItems(vehicleValues);
    }

    const addVehiclehandler = () => {
        setVehicleItems([...vehicleItems, { regNo: '', make: '', bodyType: '', horsePower: '', yearOfManufacture: '', carryingCapacity: '', estimatedValue: '', windScreenLimit: '', radioCassetLimit: '', valuationValue: '', valuationDate: '', valuationDocumentName: '', valuationDocumentLocation: '' }]);
    }

    const removeVehiclehandler = (index) => {
        const vehicleValues = [...vehicleItems];
        vehicleValues.splice(index, 1);
        setVehicleItems(vehicleValues);
    }

    let particularsOfTheVehicleContent = <p></p>
    if (selectedProductClass === '(07) - Motor Private'
        || selectedProductClass === '(07) - Motor Private - Comprehensive'
        || selectedProductClass === '(07) - Motor Private - TPO'
        || selectedProductClass === '(08) - Motor Commercial'
        || selectedProductClass === '(08) - Motor Commercial - Comprehensive'
        || selectedProductClass === '(08) - Motor Commercial - TPO') {
        particularsOfTheVehicleContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'>
                    <p>Particulars Of The Vehicle</p>
                    <div className='btn_pink' onClick={addVehiclehandler}>
                        <i className="ri-add-circle-line"></i>
                    </div>
                </div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='vehicle_table'>
                            <thead>
                                <tr>
                                    <td><p>Reg No.</p></td>
                                    <td><p>Make</p></td>
                                    <td><p>Body Type</p></td>
                                    <td><p>Horse Power</p></td>
                                    <td><p>Year Of Manuf.</p></td>
                                    <td><p>Carrying Capacity</p></td>
                                    <td><p>Estimated Value</p></td>
                                    <td><p>Wind Screen Limit</p></td>
                                    <td><p>Radio Casset Limit</p></td>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.regNo} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='regNo' /></td>
                                            <td><textarea value={item.make} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='make' /></td>
                                            <td><textarea value={item.bodyType} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='bodyType' /></td>
                                            <td><textarea value={item.horsePower} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='horsePower' /></td>
                                            <td><textarea value={item.yearOfManufacture} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='yearOfManufacture' /></td>
                                            <td><textarea value={item.carryingCapacity} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='carryingCapacity' /></td>
                                            <td><textarea value={item.estimatedValue} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='estimatedValue' /></td>
                                            <td><textarea value={item.windScreenLimit} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='windScreenLimit' /></td>
                                            <td><textarea value={item.radioCassetLimit} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='radioCassetLimit' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeVehiclehandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addVehiclehandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // clauses
    const [clauseItems, setClauseItems] = useState([{ clauseItem: '' }]);
    const onclauseItemChangeHandler = (index, event) => {
        const clausesValues = [...clauseItems];
        clausesValues[index][event.target.name] = event.target.value;
        setClauseItems(clausesValues);
    }

    const addClauseItemChangeHandler = () => {
        setClauseItems([...clauseItems, { clauseItem: '' }])
    }

    const removeClauseItemChangeHandler = (index) => {
        const clausesValues = [...clauseItems];
        clausesValues.splice(index, 1);
        setClauseItems(clausesValues);
    }

    let clausesContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Clauses</p>
                <div className='btn_pink' onClick={addClauseItemChangeHandler}>
                    <i className="ri-add-circle-line"></i>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='clauses_table'>
                        <thead><tr><td></td><td></td></tr></thead>
                        <tbody>
                            {clauseItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><textarea value={item.clauseItem} onChange={event => onclauseItemChangeHandler(index, event)} type='text' name='clauseItem' /></td>
                                        <td className='clauses_controls'>
                                            <i className="ri-close-circle-line" onClick={() => removeClauseItemChangeHandler(index)}></i>
                                            <div className='w_10'></div>
                                            <i className="ri-add-circle-line" onClick={addClauseItemChangeHandler}></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>


    // additional info
    let processAdditionalInstructions = [{ additionalInstructionItem: '' }]

    const [additionalInstructionsItems, setadditionalInstructionsItems] = useState(processAdditionalInstructions);
    const onAdditionalInstructionsItemsChangeHandler = (index, event) => {
        const additionalInstructionsValues = [...additionalInstructionsItems];
        additionalInstructionsValues[index][event.target.name] = event.target.value;
        setadditionalInstructionsItems(additionalInstructionsValues);
    }

    const addAdditionalInstructionItemChangeHandler = () => {
        setadditionalInstructionsItems([...additionalInstructionsItems, { additionalInstructionItem: '' }])
    }

    const removeAdditionalInstructionItemChangeHandler = (index) => {
        const additionalInstructionsValues = [...additionalInstructionsItems];
        additionalInstructionsValues.splice(index, 1);
        setadditionalInstructionsItems(additionalInstructionsValues);
    }

    //additionalInstructions
    let additionalInstructionsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Additional Instructions</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='clauses_table'>
                        <thead><tr><td></td><td></td></tr></thead>
                        <tbody>
                            {additionalInstructionsItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><textarea value={item.additionalInstructionItem} onChange={event => onAdditionalInstructionsItemsChangeHandler(index, event)} type='text' name='additionalInstructionItem' /></td>
                                        <td className='clauses_controls'>
                                            <i className="ri-close-circle-line" onClick={() => removeAdditionalInstructionItemChangeHandler(index)}></i>
                                            <div className='w_10'></div>
                                            <i className="ri-add-circle-line" onClick={addAdditionalInstructionItemChangeHandler}></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //policy documents content
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const [associateDocuments, setAssociateDocuments] = useState([{ documentDownloadLink: '', documentUploadCategory: '', documentUploadNarration: '', documentPolicyNumber: '', documentEndorsmentNumber: '', documentPolicyID: '', documentClientName: '', documentClientID: '', documentUploadDate: '', uploadedBy: '' }]);

    let policyDocumentsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Policy Associate Documents</p>
                {/* <div className='btn_pink' onClick={() => showHideFilterRightNav('Show')}>
                    <i className="ri-add-circle-line"></i>
                </div> */}
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='policy_documents_table'>
                        <thead>
                            <tr>
                                <td><p>Document Category</p></td>
                                <td><p>Document Details</p></td>
                                <td><p>Uploaded By</p></td>
                                <td><p>Upload Date</p></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {associateDocuments.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => openInNewTab(item.documentDownloadLink)}>
                                        <td><p>{item.documentUploadCategory}</p></td>
                                        <td><p>{item.documentUploadNarration}</p></td>
                                        <td><p>{item.uploadedBy.userEmail}</p></td>
                                        <td><p>
                                            {
                                                item.documentUploadDate.seconds
                                                    ? new Date(item.documentUploadDate.seconds * 1000).toLocaleString('en-US', dateDispayOptions)
                                                    : ''
                                            }
                                        </p></td>
                                        <td className='clauses_controls'>
                                            <i className="ri-close-circle-line"></i>
                                            <div className='w_10'></div>
                                            <i className="ri-add-circle-line" onClick={() => showHideFilterRightNav('Show')}></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className='h_15'></div>
    </section>

    //##############################
    //fetch policy data
    const fetchPolicyDetails = useCallback(async () => {
        setIsloading(true);
        const docRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            setViewPolicyFinalSelectedClientRN(docSnap.data().clientName);
            setInsurerName(docSnap.data().insurerName);
            setBankInterest(docSnap.data().bankInterest);
            setPolicyNumber(docSnap.data().policyNumber);
            setSelectedProductClass(docSnap.data().selectedProductClass);
            setSelectedProductSubClass(docSnap.data().selectedProductSubClass);
            setCommissionRate(docSnap.data().commissionRate);
            setBusinessType(docSnap.data().businessType);
            setEndorsmentNumber(docSnap.data().endorsmentNumber);
            if (!docSnap.data().inceptionDate) { setInceptionDate(''); }
            else { setInceptionDate(docSnap.data().inceptionDate); }
            if (!docSnap.data().expiryDate) { setExpiryDate(''); }
            else { setExpiryDate(docSnap.data().expiryDate); }
            if (!docSnap.data().insurerDebitDate) { onDChange(new Date()); }
            else { onDChange(new Date(docSnap.data().insurerDebitDate)); }
            setLocationOfTheRisk(docSnap.data().locationOfTheRisk);
            setTotalSumInsured(docSnap.data().totalSumInsured);
            setPremium(docSnap.data().premium);
            setPVTPremium(docSnap.data().PVTPremium);
            setStampDuty(docSnap.data().stampDuty);
            setTLevy(docSnap.data().TLevy);
            setPHCFund(docSnap.data().PHCFund);
            setEarthquake(docSnap.data().earthquake);
            setExcessProtector(docSnap.data().excessProtector);
            setComesaCard(docSnap.data().comesaCard);
            setWindScreen(docSnap.data().windScreen);
            setRadioCasset(docSnap.data().radioCasset);
            setCommission(docSnap.data().commission);
            setCommissionPayable(docSnap.data().commissionPayable);
            setWithholdingTax(docSnap.data().withholdingTax);
            setPolicyNet(docSnap.data().policyNet);
            setInsurerPolicyNet(docSnap.data().insurerPolicyNet);

            const mappedEmployeesDetails = Object.values(docSnap.data().employeesDetailsItems);
            setEmployeesDetailsItems(mappedEmployeesDetails)
            const mappedPropertyDescriptionItems = Object.values(docSnap.data().propertyDescriptionItems);
            setPropertyDescriptionItems(mappedPropertyDescriptionItems)
            const mappedBenefitsItems = Object.values(docSnap.data().benefitsItems);
            setBenefitsItems(mappedBenefitsItems)
            const mappedDeductibleItem = Object.values(docSnap.data().deductibleItems);
            setDeductibleItems(mappedDeductibleItem)
            const mappedVehicleItems = Object.values(docSnap.data().vehicleItems);
            setVehicleItems(mappedVehicleItems)
            const mappedClauseItems = Object.values(docSnap.data().clauseItems);
            setClauseItems(mappedClauseItems)
            if (!docSnap.data().additionalInstructionsItems) { setadditionalInstructionsItems([{ additionalInstructionItem: '' }]) }
            else { setadditionalInstructionsItems(Object.values(docSnap.data().additionalInstructionsItems)) }
            if (docSnap.data().associateDocuments) { setAssociateDocuments(Object.values(docSnap.data().associateDocuments)) }
            else { setAssociateDocuments([{ documentDownloadLink: '', documentUploadCategory: '', documentUploadNarration: '', documentPolicyNumber: '', documentEndorsmentNumber: '', documentPolicyID: '', documentClientName: '', documentClientID: '', documentUploadDate: '', uploadedBy: '' }]) }

            setIsloading(false);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }, [])

    useEffect(() => {
        fetchPolicyDetails();
    }, [])


    // change view on success post
    const goBackToPolicyLIst = () => {
        const onePropObj = {
            setView: 'Policy List Grid',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: props.onePropUp.dateFrom,
            dateTo: props.onePropUp.dateTo,
            searchTerm: props.onePropUp.searchTerm,
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    // change view on success edit
    const viewEditedPolicyOnSuccessEdit = () => {
        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: props.onePropUp.dateFrom,
            dateTo: props.onePropUp.dateTo,
            searchTerm: props.onePropUp.searchTerm,
            notification: 'Changes Saved'
        }
        props.onePropDown(onePropObj)
    }

    //edit record
    async function editPolicyFormSubmitHandler(event) {
        event.preventDefault();

        //update data
        try {
            setIsloading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'clientName': viewPolicyFinalSelectedClientRN,
                'insurerName': insurerName,
                'bankInterest': bankInterest,
                'policyNumber': policyNumber,
                'selectedProductClass': selectedProductClass,
                'selectedProductSubClass': selectedProductSubClass,
                'commissionRate': commissionRate,
                'businessType': businessType,
                'endorsmentNumber': endorsmentNumber,
                'inceptionDate': inceptionDate,
                'expiryDate': expiryDate,
                'insurerDebitDate': insurerDebitDate,
                'locationOfTheRisk': locationOfTheRisk,
                'totalSumInsured': totalSumInsured,
                'premium': premium,
                'PVTPremium': PVTPremium,
                'stampDuty': stampDuty,
                'TLevy': TLevy,
                'PHCFund': PHCFund,
                'commission': commission,
                'commissionPayable': commissionPayable,
                'expectedCommissionPayable': commissionPayable,
                'outstandingCommission': commission,
                'withholdingTax': withholdingTax,
                'policyNet': policyNet,
                'earthquake': earthquake,
                'excessProtector': excessProtector,
                'comesaCard': comesaCard,
                'windScreen': windScreen,
                'radioCasset': radioCasset,
                'insurerPolicyNet': insurerPolicyNet,
                'employeesDetailsItems': { ...employeesDetailsItems },
                'propertyDescriptionItems': { ...propertyDescriptionItems },
                'benefitsItems': { ...benefitsItems },
                'deductibleItems': { ...deductibleItems },
                'vehicleItems': { ...vehicleItems },
                'clauseItems': { ...clauseItems },
                'additionalInstructionsItems': { ...additionalInstructionsItems }
            });
            setIsloading(false);
            viewEditedPolicyOnSuccessEdit();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //#########################################################################
    // show lasped msg
    const displayLapsedMSG = () => {
        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: props.onePropUp.dateFrom,
            dateTo: props.onePropUp.dateTo,
            searchTerm: props.onePropUp.searchTerm,
            notification: 'Policy Lapsed'
        }
        props.onePropDown(onePropObj)
    }

    //########################################################################
    //right nav upload doc
    // change view on success posting
    const viewEditedPolicyOnSuccessDocPosting = () => {
        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Pending List Edit',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: props.onePropUp.dateFrom,
            dateTo: props.onePropUp.dateTo,
            searchTerm: props.onePropUp.searchTerm,
            notification: 'Document Uploaded Successfully'
        }
        props.onePropDown(onePropObj)
    }

    //get user input
    const [documentUploadCategory, setDocumentUploadCategory] = useState('');
    const [documentUploadNarration, setDocumentUploadNarration] = useState('');

    useEffect(() => {
        switch (documentUploadCategory) {
            case 'Policy Document':
                setDocumentUploadNarration(policyNumber + ' - ' + endorsmentNumber);
                break;
            case 'Log Book':
                setDocumentUploadNarration('Enter Plate No.');
                break;
            case 'Valuation':
                setDocumentUploadNarration('Enter Plate No.');
                break;
            case 'Other':
                setDocumentUploadNarration('Other');
                break;
        }
    }, [documentUploadCategory]);

    //update doc record
    async function updateAssociateDocRecord(downloadURL) {
        //create obj to post
        const associateDocObj = {
            documentDownloadLink: downloadURL,
            documentUploadCategory: documentUploadCategory,
            documentUploadNarration: documentUploadNarration,
            documentPolicyNumber: policyNumber,
            documentEndorsmentNumber: endorsmentNumber,
            documentPolicyID: props.onePropUp.postedDocRef,
            documentClientName: viewPolicyFinalSelectedClientRN,
            documentClientID: clientID,
            documentUploadDate: new Date(),
            uploadedBy: currentUser,
        }

        //update data
        try {
            setIsloading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'associateDocuments': arrayUnion(associateDocObj)
            });
            setIsloading(false);
            viewEditedPolicyOnSuccessDocPosting();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    const [docUploadProgress, setDocUploadProgress] = useState(0);
    //upload doc right nav
    const UploadAssociateFileHandler = (e) => {
        e.preventDefault();
        const file = e.target[2].files[0];
        const fileName = documentUploadCategory + policyNumber + endorsmentNumber + UniqueRandomNumber + file.name;

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': viewPolicyFinalSelectedClientRN,
                'clientID': clientID,
                'policyNumber': policyNumber,
                'endorsmentNumber': endorsmentNumber,
                'policyDocID': props.onePropUp.postedDocRef,
                'documentID': UniqueRandomNumber,
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setDocUploadProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateAssociateDocRecord(downloadURL)
                });
            }
        );

    }


    //###########################################
    //show hide filter content
    const [tooglePolicyDocRightNav, setTooglePolicyDocRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setTooglePolicyDocRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setTooglePolicyDocRightNav('receipt_allocation_rightNav hide') }
    }

    //filter date content
    const policyDocSelectContent = <div className={tooglePolicyDocRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Document Upload</p>
                {
                    docUploadProgress > 0
                        ? <span><div className='h_10'></div><p className='f_7'>Upload Progress: {docUploadProgress}% Done</p></span>
                        : ''
                }
                <div className='h_10'></div>
                <form onSubmit={UploadAssociateFileHandler}>
                    <select onChange={(e) => setDocumentUploadCategory(e.target.value)}>
                        <option>Select Document Category</option>
                        <option>Policy Document</option>
                        <option>Log Book</option>
                        <option>Valuation</option>
                        <option>Other</option>
                    </select>
                    <div className='h_10'></div>
                    <textarea value={documentUploadNarration} onChange={(e) => setDocumentUploadNarration(e.target.value)} placeholder='Document Narration' />
                    <div className='h_10'></div>
                    <input type='file' className='file_input' />
                    <div className='h_10'></div>
                    <button className='btn_pink' type='submit'>Upload</button>
                </form>
            </div>
        </div>
    </div>

    //#########################################################################
    //policy controls
    const [hideShowControls, setHideShowControls] = useState('control_list')
    const hideShowControlsClickHandler = (instruction) => {
        setHideShowControls(instruction)
    }

    //policy action handlers
    const passEndorsmentHandler = () => {
        const onePropObj = {
            setView: 'Body Endorsment Final',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    const passRefundHandler = () => {
        const onePropObj = {
            setView: 'Body Refund Final',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    const policyRenewalHandler = () => {
        const onePropObj = {
            setView: 'Body Renewal Final',
            previousView: 'View Policy Final',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }


    // #############################################################################################
    // #############################################################################################
    // ##############################################################################################

    return (
        <section className='create_policy_section'>
            <form>
                <div className='body_section_header'>
                    <div className='left_content'>
                        <span className='btn_back' onClick={goBackToPolicyLIst}>
                            <i className="ri-arrow-left-s-line"></i>
                        </span>
                        <div className='w_5'></div>
                        <p>View / Edit Policy</p>
                    </div>
                    <div className='view_policy_controls'>
                        <span onMouseOver={() => hideShowControlsClickHandler('newlist shadow')} className='btn_pink toogle'>Controls</span>
                        <div className={hideShowControls} onMouseLeave={() => hideShowControlsClickHandler('control_list')}>
                            <div className="controls_items" onClick={event => editPolicyFormSubmitHandler(event)}>
                                <p>Save Changes</p>
                            </div>
                            <div className="controls_items" onClick={passEndorsmentHandler}>
                                <p>Pass Endorsment</p>
                            </div>
                            <div className="controls_items" onClick={passRefundHandler}>
                                <p>Pass Refund</p>
                            </div>
                            <div className="controls_items" onClick={policyRenewalHandler}>
                                <p>Renew Policy</p>
                            </div>
                            <div className="controls_items" onClick={handlePrint}>
                                <p>Print Risk Note</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='body_section create_policy'>
                    <div className='h_5'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Client Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink' onClick={() => { setRightNavContentRV('View Policy Final Select Client') }}>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={viewPolicyFinalSelectedClientRN} type='text' placeholder='Client Name' readOnly />
                            </div>
                        </div>
                    </div>
                    {/* end client details */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Insurer Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink'>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={insurerName} type='text' placeholder='Insurer' readOnly />
                            </div>
                        </div>
                    </div>
                    {/* end insurer details */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Bank Interest Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink'>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={bankInterest} onChange={bankInterestChangeHandler} type='text' placeholder='Bank' />
                            </div>
                        </div>
                    </div>
                    {/* end bank interest */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Policy Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input value={policyNumber} type='text' placeholder='Policy Number' onChange={policyNumberChangeHandler} />
                                <div className='h_10'></div>
                                <select value={selectedProductClass} onChange={productClassChangeHandler}>
                                    {insuranceProducts.map((product) => (
                                        <BodySelectOption key={product.productClass} option={product.productClass} />
                                    ))}
                                </select>
                                <div className='h_10'></div>
                                <select value={selectedProductSubClass} onChange={productSubClassChangeHandler}>
                                    {selectedProductSubClassIndexed.map((product) => (
                                        <BodySelectOption key={product} option={product} />
                                    ))}
                                </select>
                            </div>
                            {/* end column one */}
                            <div className='category_content_two'>
                                <select value={businessType} onChange={businessTypeChangeHandler}>
                                    <option>Select Business Type</option>
                                    <option>New</option>
                                    <option>Renewal</option>
                                    <option>Endorsment</option>
                                    <option>Refund</option>
                                    <option>Nil Endorsment</option>
                                    <option>Comesa Endorsment</option>
                                </select>
                                {endorsmentNumberContent}
                                <div className='h_10'></div>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={inceptionDate} placeholder='Inception Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={inceptionDateChangeHandler} />
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={expiryDate} placeholder='Expiry Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={expiryDateChangeHandler} />
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                {/* <div className='date_picker_container'>
                                    <div className='date_container'>
                                        <p>Insurer Debit Date</p>
                                        <div className='w_5'></div>
                                        <DatePicker onChange={onDChange} value={dvalue} format='d/ M/y' minDate={new Date("2023-01-01")} />
                                    </div>
                                </div> */}
                                <div className='mantine_main_content_date_picker'>
                                    <DatePickerInput
                                        dropdownType="modal"
                                        label="Insurer Debit Date"
                                        placeholder="Pick date"
                                        value={dvalue}
                                        onChange={onDChange}
                                        mx="auto"
                                        maw={400}
                                        minDate={new Date(insurerLockDateRN)}
                                        disabled={lockDateDisableInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end policy details */}
                    {policyAmountContent}
                    {particularsOfTheRiskContent}
                    {employeesDetailsContent}
                    {descriptionContent}
                    {benefitsContent}
                    {deductiblesContent}
                    {particularsOfTheVehicleContent}
                    {clausesContent}
                    {additionalInstructionsContent}
                    {policyDocumentsContent}
                </div>
            </form>
            <div className='risk_note_preview'>
                <div ref={componentRef}>
                    <BodyRiskNote docRefNo={props.onePropUp.postedDocRef} />
                </div>
            </div>
            {searchContent}
            {loaderContent}
            {policyDocSelectContent}
        </section>
    );
}

export default BodyViewPolicyFinal;