import React, {useState, useEffect} from "react";
import Loader from "../Loader/Loader";
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType"
import { useRecoilValue } from "recoil";
import { comesaInsurerFilter } from "../../../atoms";

const BodyComesaPolicy = () => {
  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }

  //################################################################
  //recoil values
  const comesaInsurerFilterRN = useRecoilValue(comesaInsurerFilter);
  
  //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetBusinessType({ businessType: 'Comesa Endorsment', insurer: comesaInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, comesaInsurerFilterRN]);

    console.log(DVDocs)

  return (
    <section>
      <div className="body_section_header">
        <p>Comesa Policies</p>
      </div>
      <div>
        <div>
          <p>Welcome to Comesa</p>
        </div>
      </div>
      {/* <div className="body_section">{content}</div> */}
      {loaderContent}
    </section>
  );
}

export default BodyComesaPolicy;

