import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { stickerCopyReportFilterFromDate, stickerCopyReportFilterToDate, stickerCopyReportTypeFilter, stickerCopyReportInsurerFilter } from "../../../atoms";
import GetAllMotorDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllMotorDocsTransactionAndInsurerWise";
import DateFormat from "../../Hooks/UI/DateFormat";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const StickerCopyReportGrid = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values  
    const stickerCopyReportFilterFromDateRN = useRecoilValue(stickerCopyReportFilterFromDate)
    const stickerCopyReportFilterToDateRN = useRecoilValue(stickerCopyReportFilterToDate)
    const stickerCopyReportTypeFilterRN = useRecoilValue(stickerCopyReportTypeFilter)
    const stickerCopyReportInsurerFilterRN = useRecoilValue(stickerCopyReportInsurerFilter)

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllMotorDocsTransactionAndInsurerWise({ insurerName: stickerCopyReportInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, stickerCopyReportInsurerFilterRN]);

    //################################################################
    //clean docs
    const [cleanDebitDocs, setCleanDebitDocs] = useState([]);
    useEffect(() => {
        try {

            const list = debitDocs.filter(doc => doc.docData.status != 'Cancelled' && doc.docData.status != 'Lapsed' && doc.docData.businessType != 'Endorsment' && doc.docData.businessType !== 'JV' && doc.docData.businessType !== 'DV' && !doc.docData.endorsmentNumber.startsWith('05/'));
            setCleanDebitDocs(list);

        } catch (error) { }
    }, [debitDocs]);

    //################################################################
    //extract veh details
    const [extractedData, setExtractedData] = useState([])
    useEffect(() => {
        try {

            const list = [];
            cleanDebitDocs.map((doc) => {
                if (doc.docData.vehicleItems) {
                    Object.values(doc.docData.vehicleItems).map((veh) => {
                        if (veh.regNo) {
                            list.push({
                                docID: doc.docID, docData: doc.docData, plateNo: veh.regNo,
                            })
                        }
                    })

                }
            });
            setExtractedData(list)

        } catch (error) { }
    }, [cleanDebitDocs]);

    //################################################################
    //extract sc link
    const [analysedData, setAnalsedData] = useState([]);
    useEffect(() => {
        try {

            const list = [];
            extractedData.map((doc) => {
                const SCLink = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Motor Certificate' && item.documentVehiclePlateNo.trim().toLowerCase() === doc.plateNo.trim().toLowerCase()) {
                            SCLink.push(item.documentDownloadLink);
                        }
                    })
                }
                list.push({
                    docID: doc.docID, docData: doc.docData, plateNo: doc.plateNo, stickerCopyDocumentLink: SCLink,
                });
            });

            setAnalsedData(list);

        } catch (error) { }
    }, [extractedData]);

    //################################################################
    //filter missing n present
    const [typeFilteredDocs, setTypeFilteredDocs] = useState([]);
    useEffect(() => {
        try {

            const list = [];
            analysedData.map((doc) => {
                if (stickerCopyReportTypeFilterRN === 'All') {
                    list.push(doc)
                }
                else if (stickerCopyReportTypeFilterRN === 'Motor Certificate Present') {
                    if (doc.stickerCopyDocumentLink.length > 0) {
                        list.push(doc)
                    }
                }
                else if (stickerCopyReportTypeFilterRN === 'Motor Certificate Missing') {
                    if (doc.stickerCopyDocumentLink.length == 0) {
                        list.push(doc)
                    }
                }
            });
            setTypeFilteredDocs(list);

        } catch (error) { console.log(error) }
    }, [analysedData, stickerCopyReportTypeFilterRN]);

    //################################################################
    //filter dates
    const [selectedPeriodDocs, setSelectedPeriodDocs] = useState([]);
    useEffect(() => {
        try {

            const periodSelected = [];

            typeFilteredDocs.map((doc) => {
                //clean date
                const formattedInceptionDate = new Date(doc.docData.inceptionDate);
                formattedInceptionDate.setHours(0, 0, 0);
                //filter period choosen
                if (new Date(formattedInceptionDate).valueOf() >= new Date(stickerCopyReportFilterFromDateRN).valueOf() && new Date(stickerCopyReportFilterToDateRN).valueOf() >= new Date(formattedInceptionDate).valueOf()) {
                    periodSelected.push(doc);
                }
            });

            setSelectedPeriodDocs(periodSelected);

        } catch (error) { }
    }, [typeFilteredDocs, stickerCopyReportFilterFromDateRN, stickerCopyReportFilterToDateRN]);

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        try {

            const dateSortedDocList = selectedPeriodDocs.sort(byDate);
            function byDate(a, b) {
                return new Date(a.docData.inceptionDate).valueOf() - new Date(b.docData.inceptionDate).valueOf();
            }
            setDateSortedDocs(dateSortedDocList);

        } catch (error) { }
    }, [selectedPeriodDocs]);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateSortedDocs.map((doc) => {

                //look for pd
                const policyDocuments = [];
                if (doc.docData.associateDocuments) {
                    Object.values(doc.docData.associateDocuments).map((item) => {
                        if (item.documentUploadCategory === 'Policy Document') {
                            policyDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    plateNo: doc.plateNo,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    stickerCopyDocumentLink: doc.stickerCopyDocumentLink,
                    policyDocuments: policyDocuments,
                });
            });

            setFinalData(finalObj)

        } catch (error) { }
    }, [dateSortedDocs])


    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');


    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'plateNo', 'policyNumber', 'endorsmentNumber', 'policyClass', 'inceptionDate', 'expiryDate']

    const exportToExcelFileName = 'Motor Certificate Report    --    INSURER: ' + stickerCopyReportInsurerFilterRN + '    --    PERIOD: ' + DateFormat(stickerCopyReportFilterFromDateRN) + ' - ' + DateFormat(stickerCopyReportFilterToDateRN) + '    --    Category: ' + stickerCopyReportTypeFilterRN + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.plateNo,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
            ])
        });


        const onlyDispatchedheaderData = 'Motor Certificate Report    --    INSURER: ' + stickerCopyReportInsurerFilterRN + '    --    PERIOD: ' + DateFormat(stickerCopyReportFilterFromDateRN) + ' - ' + DateFormat(stickerCopyReportFilterToDateRN) + '    --    Category: ' + stickerCopyReportTypeFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'INSURED', 'PLATE NO', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.plateNo,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
            ])
        });


        const onlyDispatchedheaderData = 'Motor Certificate Report    --    INSURER: ' + stickerCopyReportInsurerFilterRN + '    --    PERIOD: ' + DateFormat(stickerCopyReportFilterFromDateRN) + ' - ' + DateFormat(stickerCopyReportFilterToDateRN) + '    --    Category: ' + stickerCopyReportTypeFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'INSURED', 'PLATE NO', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }


    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'plateNo', headerName: 'Plate No', width: 150, },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        {
            field: 'stickerCopyDocumentLink', headerName: 'SC', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'policyDocuments', headerName: 'PD', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Sticker Copy Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default StickerCopyReportGrid;