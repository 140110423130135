import React, { useState, useEffect, useRef, useCallback } from 'react';
import Loader from '../Loader/Loader';
import GetBusinessType from '../../Hooks/BackendConn/InsurerSpecific/GetBusinessType';
import { useRecoilValue } from 'recoil';
import { JVListInsurerFilter, JVListFilterFromDate, JVListFilterToDate } from '../../../atoms';
import './DVList.css';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';

const JVList = (props) => {
    //################################################################
    //recoil values
    const JVListInsurerFilterRN = useRecoilValue(JVListInsurerFilter)
    const JVListFilterFromDateRN = useRecoilValue(JVListFilterFromDate)
    const JVListFilterToDateRN = useRecoilValue(JVListFilterToDate)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "JV List.xlsx");
    }, [tbl]);

    //################################################################
    //get all JVs
    const [JVDocs, setJVDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetBusinessType({ businessType: 'JV', insurer: JVListInsurerFilterRN });
            setJVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, JVListInsurerFilterRN]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredJVDocs, setSelectedPeriodFilteredJVDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        JVDocs.map((doc) => {
            //calculate next inception
            const newInsurerDebitDate = new Date(doc.docData.insurerDebitDate);
            newInsurerDebitDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(newInsurerDebitDate).valueOf() >= new Date(JVListFilterFromDateRN).valueOf() && new Date(JVListFilterToDateRN).valueOf() >= new Date(newInsurerDebitDate).valueOf()) {
                periodSelected.push(doc);
            }
        });
        setSelectedPeriodFilteredJVDocs(periodSelected);
    }, [JVDocs, JVListFilterFromDateRN, JVListFilterToDateRN]);

    //################################################################
    //sort by date
    const [dateSortedJVDocs, setDateSortedJVDocs] = useState([])
    useEffect(() => {
        const sortedList = selectedPeriodFilteredJVDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.insurerDebitDate).valueOf() - new Date(b.docData.insurerDebitDate).valueOf();
        }
        setDateSortedJVDocs(sortedList);
    }, [selectedPeriodFilteredJVDocs])

    //################################################################
    //filter open claims
    const [dateFilteredJVDocs, setDateFilteredJVDocs] = useState([])
    useEffect(() => {
        const a = dateSortedJVDocs.filter(doc => new Date(doc.docData.insurerDebitDate).valueOf() >= new Date(JVListFilterFromDateRN).valueOf() && new Date(doc.docData.insurerDebitDate).valueOf() <= new Date(JVListFilterToDateRN).valueOf());
        setDateFilteredJVDocs(a)
    }, [dateSortedJVDocs, JVListFilterFromDateRN, JVListFilterToDateRN])

    //################################################################
    // search open claims
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedJVDocs, setSearchedJVDocs] = useState([])
    useEffect(() => {
        const c = [];
        const docs = dateFilteredJVDocs.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        });
        setSearchedJVDocs(c);
    }, [dateFilteredJVDocs, searchTerm])

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################
    //view dv
    const viewJVHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View JV',
            previousView: 'JV List',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewJVHandler}><p>View JV</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //echo
    const JVListContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search JV No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='DVList_table' ref={tbl}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>JV Number</p></td>
                                <td><p>Narration</p></td>
                                <td><p>Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedJVDocs.map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.insurerDebitDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.locationOfTheRisk}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{NumberFormat(doc.docData.policyNet)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo
    const JVListContentPrintable = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='DVList_table DVList_table_printable' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>JV LIST</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>PERIOD : {DateFormat(JVListFilterFromDateRN)} - {DateFormat(JVListFilterToDateRN)}</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>INSURER : {JVListInsurerFilterRN}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>JV Number</p></td>
                                <td><p>Narration</p></td>
                                <td><p>Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedJVDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{DateFormat(doc.docData.insurerDebitDate)}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.locationOfTheRisk}</p></td>
                                            <td><p>{NumberFormat(doc.docData.policyNet)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='DVList'>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>JV List</p>
                    <div className='right_side_controls'>
                        <span className='btn_pink' onClick={exportFile} >
                            <i className="ri-file-excel-2-line"></i>
                        </span>
                        <div className='w_5'></div>
                        <span className='btn_pink' onClick={handlePrint}>
                            <i className="ri-printer-line"></i>
                        </span>
                    </div>
                </div>
                <div className='body_section'>
                    {JVListContent}
                    {JVListContentPrintable}
                </div>
            </section>
            {URRightClickMenu}
            {loaderContent}
        </section>
    );
}

export default JVList;