import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { generatePayrollMonthFilter } from "../../../atoms";
import { MonthPickerInput  } from "@mantine/dates";

const GeneratePayrollMonthFilter = () => {
    //################################################################
    //valuation reports filter
    const [generatePayrollMonthFilterRN, setGeneratePayrollMonthFilterRN] = useRecoilState(generatePayrollMonthFilter)

    //################################################################
    //echo
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <MonthPickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="Pick Month"
                    placeholder="Pick Month"
                    value={generatePayrollMonthFilterRN}
                    onChange={setGeneratePayrollMonthFilterRN}
                />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {content}
        </section>
    )
}

export default GeneratePayrollMonthFilter;