import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { postPaymentSelectedVendor } from "../../../atoms";
import Loader from "../Loader/Loader";
import GetInvoiceByVendorID from "../../Hooks/APIConn/Expenses/GetInvoiceByVendorID";
import Worker from './PostPayment.Worker.js';
import { DatePickerInput } from "@mantine/dates";
import styles from './PostPayment.module.css';
import axios from "axios";
import { activeBrokerBranchPortfolio } from "../../../atoms";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import UUID from "../../Hooks/UUID/UUID";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import NumberFormat from "../../Hooks/UI/NumberFormat";
import DateFormat from '../../Hooks/UI/DateFormat';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const PostPayment = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const postPaymentSelectedVendorRN = useRecoilValue(postPaymentSelectedVendor);
    const activeBrokerBranchPortfolioRN = useRecoilValue(activeBrokerBranchPortfolio);

    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //get user input
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [chequeAmount, setChequeAmount] = useState('');
    const [paymentID, setPaymentID] = useState('');

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            //if(Object.keys(postPaymentSelectedVendorRN).length < 1){return false}
            setIsLoading(true);
            var worker = new Worker();

            const data = { vendorID: postPaymentSelectedVendorRN._id }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [postPaymentSelectedVendorRN]);

    //################################################################
    //update array to include allocated amount
    const onAllocatedAmountChange = (index, event) => {
        const selectedDebitsForAllocationValues = [...finalData];
        selectedDebitsForAllocationValues[index][event.target.name] = event.target.value;
        setFinalData(selectedDebitsForAllocationValues);
    }

    //################################################################
    // calculate total
    const [totalAllocated, setTotalAllocated] = useState(0)
    useEffect(() => {
        try {

            const sum = finalData.reduce((acc, cur) => acc + +cur.allocatedAmount, 0);
            setTotalAllocated(sum);

        } catch (error) { }
    }, [finalData])

    //################################################################
    // post payment
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Post Payment',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payment Posted Successfully.',
            activity: 'Invoice Payment Posting',
        }
        props.onePropDown(onePropObj);
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Post Payment',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Posting Payment.'
        }
        props.onePropDown(onePropObj);
    }

    //step 2
    //post data
    const createExpenseHandler = async (receivedData) => {
        setIsLoading(true);

        // const quoteFinalObj = {
        //     purposeOfPayment: receivedData.purposeOfPayment,
        //     amount: receivedData.allocatedAmount.trim(),
        //     paymentID: paymentID.trim(),
        //     modeOfPayment: receivedData.modeOfPayment,
        //     expenseAccount: receivedData.expenseAccount,
        //     receiptNo: receivedData.receiptNo,
        //     status: "Cleared",
        //     createdBy: userDetails,
        //     expenseReceiptDocument: {},
        //     branchID: activeBrokerBranchPortfolioRN,
        //     paymentDate: paymentDate,
        //     invoiceDate: receivedData.invoiceDate,
        //     vendorID: postPaymentSelectedVendorRN._id,
        //     category: 'Expense',
        //     dateCreated: new Date(),
        //     allocationDetails: receivedData._id,
        // }
        
        const CreateExpenseAPI = async () => {

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createbulkexpense', receivedData)
                .then(response => {
                    // Handle the response data
                    console.log(response.data);
                    //showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    console.log(error);
                    //showErrorMSGOnExpenseSave();
                });
        }
        CreateExpenseAPI()

    }

    // step 1
    const postPayment = () => {
        if (totalAllocated == 0) {
            showErrorMSGOnExpenseSave();
            return false;
        }

        try {

            const allocatedItems = finalData.filter(doc => doc.allocatedAmount > 0);

            async function processArray() {
                // for (const item of allocatedItems) {
                //     createObjHandler(item);
                // }
                const paymentAllocationID = UUID();

                let paymntDate = paymentDate;
                if(paymentDate){paymntDate = paymentDate.toISOString()}

                const items = allocatedItems.map((doc) => {
                    return {
                        purposeOfPayment: doc.purposeOfPayment,
                        amount: doc.allocatedAmount.trim(),
                        paymentID: paymentID.trim(),
                        modeOfPayment: doc.modeOfPayment,
                        expenseAccount: doc.expenseAccount,
                        receiptNo: doc.receiptNo,
                        status: "Cleared",
                        createdBy: userDetails,
                        expenseReceiptDocument: {},
                        branchID: activeBrokerBranchPortfolioRN,
                        paymentDate: paymntDate,
                        invoiceDate: doc.invoiceDate,
                        vendorID: postPaymentSelectedVendorRN._id,
                        category: 'Expense',
                        dateCreated: new Date(),
                        allocationDetails: doc._id,
                        paymentAllocationID: paymentAllocationID,
                    }
                });

                console.log(items);
                await createExpenseHandler(items);
                showSuccessMSGOnExpenseSave();

                // {
                //     "acknowledged": true,
                //     "insertedCount": 6,
                //     "insertedIds": {
                //         "0": "64ad526f03edcf999458ad27",
                //         "1": "64ad526f03edcf999458ad28",
                //         "2": "64ad526f03edcf999458ad29",
                //         "3": "64ad526f03edcf999458ad2a",
                //         "4": "64ad526f03edcf999458ad2b",
                //         "5": "64ad526f03edcf999458ad2c"
                //     }
                // }
            }

            processArray().catch((error) => {
                showErrorMSGOnExpenseSave();
                console.log('Error:', error);
            });

        } catch (error) { }
    }

    //################################################################
    //content
    const content = <section className='f_7'>
        <table className='w-full table-fixed border-collapse border border-slate-500'>
            <thead>
                <tr>
                    <td className='w-1/9 border border-slate-600 p-1'>Debit Account</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Invoice Amount</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Allocated Amount</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Outstanding</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Credit Details</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Total Credit</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Invoice Date</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Credit Account</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Purpose</td>
                </tr>
            </thead>
            <tbody>
                {

                    finalData.map((doc, index) => {
                        const outstandingAmount = doc.amount - doc.creditsData.reduce((acc, obj) => acc + +obj.amount, 0);
                        //if (outstandingAmount > 0) {

                        return (

                            <tr key={index}>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.expenseAccount}</td>
                                <td className='w-1/9 border border-slate-600 p-1'><p className="bold">{NumberFormat(doc.amount)}</p></td>
                                <td className='w-1/9 border border-slate-600 p-1'>
                                    <input value={doc.allocatedAmount} onChange={event => onAllocatedAmountChange(index, event)} name='allocatedAmount' className={styles.veh_input} />
                                </td>
                                <td className='w-1/9 border border-slate-600 p-1'><p className="bold">{NumberFormat(doc.amount - doc.allocatedAmount - doc.creditsData.reduce((acc, obj) => acc + +obj.amount, 0))}</p></td>
                                <td className='w-1/9 border border-slate-600 p-1'>
                                    {
                                        doc.creditsData.map((cItem, indexx) => {
                                            return (
                                                <p key={indexx}>{cItem.paymentID + ' - ' + DateFormat(cItem.paymentDate) + ' - ' + NumberFormat(cItem.amount) + ' ## '}</p>
                                            )
                                        })
                                    }
                                </td>
                                <td className='w-1/9 border border-slate-600 p-1'>
                                    {
                                        NumberFormat(doc.creditsData.reduce((acc, obj) => acc + +obj.amount, 0))
                                    }
                                </td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.invoiceDateDisplay}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.modeOfPayment}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.purposeOfPayment}</td>
                            </tr>

                        );
                        // }
                    })
                }
                <tr>
                    <td className='w-1/9 border border-slate-600 p-1'>Total Allocated</td>
                    <td className='w-1/9 border border-slate-600 p-1'><p className="bold">{totalAllocated}</p></td>
                    <td className='w-1/9 border border-slate-600 p-1'>Amount Unallocated</td>
                    <td className='w-1/9 border border-slate-600 p-1'><p className="bold">{chequeAmount - totalAllocated}</p></td>
                    <td className='w-1/9 border border-slate-600 p-1' colSpan={5}></td>
                </tr>
                <tr>
                    <td className='w-1/9 border border-slate-600 p-1'>Amount</td>
                    <td className='w-1/9 border border-slate-600 p-1'>
                        <input value={chequeAmount || ''} onChange={(e) => { setChequeAmount(e.target.value) }} className={styles.veh_input} />
                    </td>
                    <td className='w-1/9 border border-slate-600 p-1'>Payment Date</td>
                    <td className='w-1/9 border border-slate-600 p-1' colSpan={3}>
                        <div className='mantine_main_content_table_date_picker'>
                            <DatePickerInput
                                icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                                dropdownType="modal"
                                placeholder="Pick date"
                                value={paymentDate}
                                onChange={setPaymentDate}
                                mx="auto"
                                maw={400}
                            />
                        </div>
                    </td>
                    <td className='w-1/9 border border-slate-600 p-1'>Cheque No</td>
                    <td className='w-1/9 border border-slate-600 p-1' colSpan={2}>
                        <input value={paymentID || ''} onChange={(e) => { setPaymentID(e.target.value) }} className={styles.veh_input} />
                    </td>
                </tr>
            </tbody>
        </table>
    </section>



    return (
        <section>
            <div className='body_section_header'>
                <p>Post Payment</p>
                <button onClick={postPayment} className='btn_pink'>Save Details</button>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default PostPayment;