import { atom } from "recoil";

export const rightNavContent = atom({
    key: 'rightNavContent',
    default: '',
});

export const rightNavPolicyItem = atom({
    key: 'rightNavPolicyItem',
    default: {},
});

//################################################################
//active tabs
export const activeInsurerPortfolio = atom({
    key: 'activeInsurerPortfolio',
    default: '98526b1b-271a-46a5-a183-d4c20f2367d7',
});

//################################################################
//active tabs
export const activeBrokerBranchPortfolio = atom({
    key: 'activeBrokerBranchPortfolio',
    default: 'f34a-c6e4-4bd0-bd48-804d345e3479 - 08bf5040-93a8-43c2-8eac-461424cc590c',
});

//################################################################
//active tabs
export const activeTabs = atom({
    key: 'activeTabs',
    default: [],
});

//################################################################
//unrenewed reports
export const unrenewedReportFilterFromDate = atom({
    key: 'unrenewedReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const unrenewedReportFilterToDate = atom({
    key: 'unrenewedReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const unrenewedReportFilterPolicyClass = atom({
    key: 'unrenewedReportFilterPolicyClass',
    default: 'ALL',
});

export const unrenewedReportLapseAsOfDate = atom({
    key: 'unrenewedReportLapseAsOfDate',
    default: new Date(new Date()),
});

export const unrenewedReportCancelAsOfDate = atom({
    key: 'unrenewedReportCancelAsOfDate',
    default: new Date(new Date()),
});

export const unrenewedReportFilterInsurer = atom({
    key: 'unrenewedReportFilterInsurer',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//renewed reports
export const renewedReportFilterFromDate = atom({
    key: 'renewedReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const renewedReportFilterToDate = atom({
    key: 'renewedReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const renewedReportFilterPolicyClass = atom({
    key: 'renewedReportFilterPolicyClass',
    default: 'ALL',
});

export const renewedReportFilterInsurer = atom({
    key: 'renewedReportFilterInsurer',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//COMMISSION REQUEST
export const commissionRequestFilterFromDate = atom({
    key: 'commissionRequestFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const commissionRequestFilterToDate = atom({
    key: 'commissionRequestFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
export const totalCommissionFilterFromDateRS = atom({
    key: 'totalCommissionFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const totalCommissionFilterToDateRS = atom({
    key: 'totalCommissionFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//valuation reports
export const valuationReportsTypeFilter = atom({
    key: 'valuationReportsTypeFilter',
    default: 'All',
});

export const valuationReportsFilterFromDate = atom({
    key: 'valuationReportsFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const valuationReportsFilterToDate = atom({
    key: 'valuationReportsFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const valuationReportsInsurerFilter = atom({
    key: 'valuationReportsInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//log book report filter
export const logbookReportFilterType = atom({
    key: 'logbookReportFilterType',
    default: 'All',
});

export const logbookReportFilterFromDate = atom({
    key: 'logbookReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const logbookReportFilterToDate = atom({
    key: 'logbookReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const logbookReportInsurerFilter = atom({
    key: 'logbookReportInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});


//################################################################
//sticker copy report filter
export const stickerCopyReportTypeFilter = atom({
    key: 'stickerCopyReportTypeFilter',
    default: 'All',
});

export const stickerCopyReportFilterFromDate = atom({
    key: 'stickerCopyReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const stickerCopyReportFilterToDate = atom({
    key: 'stickerCopyReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const stickerCopyReportInsurerFilter = atom({
    key: 'stickerCopyReportInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//comprehensive motor report filter
export const conclusiveMotorReportFilterFromDate = atom({
    key: 'comperehensiveMotorReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const conclusiveMotorReportFilterToDate = atom({
    key: 'comperehensiveMotorFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});


//################################################################
//open claims
export const openClaimsFilterFromDate = atom({
    key: 'openClaimsFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1),
});

export const openClaimsFilterToDate = atom({
    key: 'openClaimsFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const openClaimsSelectInsurer = atom({
    key: 'openClaimsSelectInsurer',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//closed claims
export const closedClaimsFilterFromDate = atom({
    key: 'closedClaimsFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1),
});

export const closedClaimsFilterToDate = atom({
    key: 'closedClaimsFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const closedClaimsSelectInsurer = atom({
    key: 'closedClaimsSelectInsurer',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//claim doc upload
export const pageReloaderHandler = atom({
    key: 'pageReloaderHandler',
    default: {},
});

//################################################################
//select insurer
export const selectInsurer = atom({
    key: 'selectInsurer',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const selectInsurerPostNewReceipt = atom({
    key: 'selectInsurerPostNewReceipt',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const selectInsurerPostDV = atom({
    key: 'selectInsurerPostDV',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const selectInsurerViewDV = atom({
    key: 'selectInsurerViewDV',
    default: '',
});

export const selectInsurerPostCN = atom({
    key: 'selectInsurerPostCN',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const selectInsurerViewCN = atom({
    key: 'selectInsurerViewCN',
    default: '',
});

export const selectInsurerPostJV = atom({
    key: 'selectInsurerPostJV',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const selectInsurerViewJV = atom({
    key: 'selectInsurerViewJV',
    default: '',
});

//################################################################
//select client
export const selectClient = atom({
    key: 'selectClient',
    default: '',
});


//################################################################
//insurer stmt filter
export const insurerStatementInsurerFilter = atom({
    key: 'insurerStatementInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const insurerStatementFilterFromDate = atom({
    key: 'insurerStatementFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const insurerStatementFilterToDate = atom({
    key: 'insurerStatementFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//agent stmt filter
export const agentStatementFilterFromDate = atom({
    key: 'agentStatementFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const agentStatementFilterToDate = atom({
    key: 'agentStatementFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//dv list filter
export const DVListInsurerFilter = atom({
    key: 'DVListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const DVListFilterFromDate = atom({
    key: 'DVListFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const DVListFilterToDate = atom({
    key: 'DVListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//CN list filter
export const CNListInsurerFilter = atom({
    key: 'CNListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const CNListFilterFromDate = atom({
    key: 'CNListFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const CNListFilterToDate = atom({
    key: 'CNListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//JV list filter
export const JVListInsurerFilter = atom({
    key: 'JVListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const JVListFilterFromDate = atom({
    key: 'JVListFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const JVListFilterToDate = atom({
    key: 'JVListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});


//################################################################
//business report filter
export const businessReportFilterFromDate = atom({
    key: 'businessReportFilterFromDate',
    default: new Date('2021-01-01'),
});

export const businessReportFilterToDate = atom({
    key: 'businessReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//pending list filter
export const PendingListInsurerFilter = atom({
    key: 'PendingListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const PendingListFilterFromDate = atom({
    key: 'PendingListFilterFromDate',
    default: new Date(new Date().getFullYear() - 1, 0, 1),
});

export const PendingListFilterToDate = atom({
    key: 'PendingListFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});

export const PendingListUpdateDispatchDate = atom({
    key: 'PendingListUpdateDispatchDate',
    default: '',
});

export const PendingListUpdateDispatchDateInput = atom({
    key: 'PendingListUpdateDispatchDateInput',
    default: null,
});

export const PendingListUpdateDispatchDateInputHandler = atom({
    key: 'PendingListUpdateDispatchDateInputHandler',
    default: false,
});

//################################################################
//cancelled list filter
export const cancelledPolicyListInsurerFilter = atom({
    key: 'cancelledPolicyListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const cancelledPolicyListFilterFromDate = atom({
    key: 'cancelledPolicyListFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const cancelledPolicyListFilterToDate = atom({
    key: 'cancelledPolicyListFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});

export const cancelledPolicyListFilterPolicyClass = atom({
    key: 'cancelledPolicyListFilterPolicyClass',
    default: 'ALL',
});

//################################################################
//agewise filter
export const agewiseOutstandingInsurerFilter = atom({
    key: 'agewiseOutstandingInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const agewiseOutstandingFilterFromDate = atom({
    key: 'agewiseOutstandingFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const agewiseOutstandingFilterToDate = atom({
    key: 'agewiseOutstandingFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});


export const bulk = atom({
    key: 'bulk',
    default: [],
});

//################################################################
//client group selection
export const clientGroupSelection = atom({
    key: 'clientGroupSelection',
    default: {},
});

export const clientGroupSelectionPrintSchedule = atom({
    key: 'clientGroupSelectionPrintSchedule',
    default: '',
});

//################################################################
//policy list grid
export const policyListGridInsurerFilter = atom({
    key: 'policyListGridInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const policyListGridFilterFromDate = atom({
    key: 'policyListGridFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const policyListGridFilterToDate = atom({
    key: 'policyListGridFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});

//################################################################
//action memo
export const actionMemoFilterFromDate = atom({
    key: 'actionMemoFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1),
});

export const actionMemoFilterToDate = atom({
    key: 'actionMemoFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
// closed action memo
export const closedActionMemoFilterFromDate = atom({
    key: 'closedActionMemoFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1),
});

export const closedActionMemoFilterToDate = atom({
    key: 'closedActionMemoFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//policy documents report
export const policyDocumentsReportInsurerFilter = atom({
    key: 'policyDocumentsReportInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const policyDocumentsReportFilterFromDate = atom({
    key: 'policyDocumentsReportFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const policyDocumentsReportFilterToDate = atom({
    key: 'policyDocumentsReportFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});

export const policyDocumentsFilterType = atom({
    key: 'policyDocumentsFilterType',
    default: 'All',
});

//################################################################
//received documents
export const receivedDocumentsInsurerFilter = atom({
    key: 'receivedDocumentsInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const receivedDocumentsFilterFromDate = atom({
    key: 'receivedDocumentsFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const receivedDocumentsFilterToDate = atom({
    key: 'receivedDocumentsFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//policy category
export const reloadOnSuccessPolicyCategoryCreationHandler = atom({
    key: 'reloadOnSuccessPolicyCategoryCreationHandler',
    default: false,
});

export const reloadOnSuccessPolicySubCategoryCreationHandler = atom({
    key: 'reloadOnSuccessPolicySubCategoryCreationHandler',
    default: false,
});

export const rightClickedPolicyCategory = atom({
    key: 'rightClickedPolicyCategory',
    default: false,
});

//################################################################
//create policy select client
export const createPolicySelectedClient = atom({
    key: 'createPolicySelectedClient',
    default: '',
});

//################################################################
//client statement select client
export const clientStatementSelectedClient = atom({
    key: 'clientStatementSelectedClient',
    default: '',
});

//################################################################
//view policy final selected client
export const viewPolicyFinalSelectedClient = atom({
    key: 'viewPolicyFinalSelectedClient',
    default: '',
});

//################################################################
//client statement insurer filter
export const clientStatementInsurerFilter = atom({
    key: 'clientStatementInsurerFilter',
    default: '',
});

//################################################################
//insurer lock date
export const insurerLockDate = atom({
    key: 'insurerLockDate',
    default: '',
});

//################################################################
//broker stmt
export const brokerStatementInsurerFilter = atom({
    key: 'brokerStatementInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const brokerStatementFilterFromDate = atom({
    key: 'brokerStatementFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const brokerStatementFilterToDate = atom({
    key: 'brokerStatementFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//receipt list
export const receiptListInsurerFilter = atom({
    key: 'receiptListInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const receiptListFilterFromDate = atom({
    key: 'receiptListFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const receiptListFilterToDate = atom({
    key: 'receiptListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const receiptListTypeFilter = atom({
    key: 'receiptListTypeFilter',
    default: 'All',
});

//################################################################
//direct collections remmitted
export const directCollectionsRemmittedFilterFromDate = atom({
    key: 'directCollectionsRemmittedFilterFromDate',
    default: new Date(new Date().getFullYear() - 1, 0, 1),
});

export const directCollectionsRemmittedFilterToDate = atom({
    key: 'directCollectionsRemmittedFilterToDate',
    default: new Date(new Date().getFullYear() + 1, 11, 31),
});

export const directCollectionsRemmittedInsurerFilter = atom({
    key: 'directCollectionsRemmittedInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//dv allocation 
export const DVAllocationInsurerFilter = atom({
    key: 'DVAllocationInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const DVAllocationSelectedDV = atom({
    key: 'DVAllocationSelectedDV',
    default: {},
});

export const DVAllocationSelectedDebits = atom({
    key: 'DVAllocationSelectedDebits',
    default: [],
});

export const DVAllocationUnselectedDebits = atom({
    key: 'DVAllocationUnselectedDebits',
    default: [],
});

//################################################################
//dv allocation 
export const DVReallocationInsurerFilter = atom({
    key: 'DVReallocationInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

export const DVReallocationDVDocs = atom({
    key: 'DVReallocationDVDocs',
    default: [],
});

export const DVReallocationSelectedDV = atom({
    key: 'DVReallocationSelectedDV',
    default: {},
});

export const DVReallocationSelectedDebits = atom({
    key: 'DVReallocationSelectedDebits',
    default: [],
});

export const DVReallocationUnselectedDebits = atom({
    key: 'DVReallocationUnselectedDebits',
    default: [],
});

//################################################################
//view dv allocation 
export const ViewDVAllocationSelectedDV = atom({
    key: 'ViewDVAllocationSelectedDV',
    default: {},
});

export const ViewDVAllocationDVDocs = atom({
    key: 'ViewDVAllocationDVDocs',
    default: [],
});

//################################################################
//paid comm
export const paidCommissionInsurerFilter = atom({
    key: 'paidCommissionInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//productivity report
export const productivityReportFilterFromDate = atom({
    key: 'productivityReportFilterFromDate',
    default: new Date(new Date().setHours(0,0,0,0)),
});

export const productivityReportFilterToDate = atom({
    key: 'productivityReportFilterToDate',
    default: new Date(new Date().setHours(0,0,0,0)),
});

export const productivityReportInsurerFilter = atom({
    key: 'productivityReportInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//quote
export const quoteListFilterFromDate = atom({
    key: 'quoteListFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const quoteListFilterToDate = atom({
    key: 'quoteListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//expenses
export const expensesListFilterFromDate = atom({
    key: 'expensesListFilterFromDate',
    default: new Date(new Date().getFullYear() - 1, 0, 1),
});

export const expensesListFilterToDate = atom({
    key: 'expensesListFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//expenses
export const pendingBillsFilterFromDate = atom({
    key: 'pendingBillsFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const pendingBillsFilterToDate = atom({
    key: 'pendingBillsFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//petty cash
export const pettyCashReportFilterFromDate = atom({
    key: 'pettyCashReportFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    //default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const pettyCashReportFilterToDate = atom({
    key: 'pettyCashReportFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

//################################################################
//view expense
export const reloadViewExpenseAfterExpenseReceiptUpload = atom({
    key: 'reloadViewExpenseAfterExpenseReceiptUpload',
    default: false,
});

//################################################################
//hr
export const reloadOnSuccessPayroleCategoryCreationHandler = atom({
    key: 'reloadOnSuccessPayroleCategoryCreationHandler',
    default: false,
});

export const rightClickedPayroleSettingdCategory = atom({
    key: 'rightClickedPayroleSettingdCategory',
    default: {},
});

export const reloadOnSuccessPayroleSubSettingCreationHandler = atom({
    key: 'reloadOnSuccessPayroleSubSettingCreationHandler',
    default: false,
});

export const leaveApplicationActiveEmployee = atom({
    key: 'leaveApplicationActiveEmployee',
    default: false,
});

export const payroleGeneratorSelectedDate = atom({
    key: 'payroleGeneratorSelectedDate',
    default: null,
});

export const payroleGeneratorSelectedEmployees = atom({
    key: 'payroleGeneratorSelectedEmployees',
    default: [],
});

export const employeeProfileReloaderLeaveRequest = atom({
    key: 'employeeProfileReloaderLeaveRequest',
    default: false,
});

//################################################################
//real time balance
export const realTimeBalanceInsurerFilter = atom({
    key: 'realTimeBalanceInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//real time balance
export const commissionRequestInsurerFilter = atom({
    key: 'commissionRequestInsurerFilter',
    default: 'MAYFAIR INSURANCE COMPANY LIMITED',
});

//################################################################
//vendor profile
export const reloadOnSuccessVendorCreationHandler = atom({
    key: 'reloadOnSuccessVendorCreationHandler',
    default: false,
});

//################################################################
//view pending bill
export const viewPendingBillDoc = atom({
    key: 'viewPendingBillDoc',
    default: {},
});

//################################################################
//post payment
export const postPaymentSelectedVendor = atom({
    key: 'postPaymentSelectedVendor',
    default: {_id:'io234werf'},
});

//################################################################
//vendor stmt
export const vendorStatementFilterFromDate = atom({
    key: 'vendorStatementFilterFromDate',
    default: new Date(new Date().getFullYear() - 2, 0, 1),
});

export const vendorStatementFilterToDate = atom({
    key: 'vendorStatementFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});


//################################################################
//profit n loss
export const profitAndLossFilterYear = atom({
    key: 'profitAndLossFilterYear',
    default: new Date(),
});

//################################################################
//finance controls
export const reloadOnSuccessExpenseAccountCreationHandler = atom({
    key: 'reloadOnSuccessExpenseAccountCreationHandler',
    default: false,
});

//################################################################
//client group
export const reloadOnSuccessClientGroupChangeHandler = atom({
    key: 'reloadOnSuccessClientGroupChangeHandler',
    default: false,
});

//################################################################
//email client statement
export const emailClientStatementSelectedClient = atom({
    key: 'emailClientStatementSelectedClient',
    default: {},
});

export const emailClientStatementFilterFromDate = atom({
    key: 'emailClientStatementFilterFromDate',
    default: new Date("2021-05-01"),
});

export const emailClientStatementFilterToDate = atom({
    key: 'emailClientStatementFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const emailClientStatementInsurerName = atom({
    key: 'emailClientStatementInsurerName',
    default: '',
});

//################################################################
//biometrics data
export const biometricsDataFile = atom({
    key: 'biometricsDataFile',
    default: [],
});

export const biometricsDataMonthFilter = atom({
    key: 'biometricsDataMonthFilter',
    default: new Date(),
});

//comesa insurer  filter
export const comesaInsurerFilter = atom({
  key: "comesaInsurerFilter",
  default: "MAYFAIR INSURANCE COMPANY LIMITED",
});

//################################################################
//payroll
export const payrollMonthFilter = atom({
    key: 'payrollMonthFilter',
    default: new Date(),
});

export const generatePayrollMonthFilter = atom({
    key: 'generatePayrollMonthFilter',
    default: new Date(),
});

export const payrollMainDataSelectedRows = atom({
    key: 'payrollMainDataSelectedRows',
    default: [],
});

//################################################################
//agent stmt filter
export const agentStatementGridFilterFromDate = atom({
    key: 'agentStatementGridFilterFromDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
});

export const agentStatementGridFilterToDate = atom({
    key: 'agentStatementGridFilterToDate',
    default: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
});

export const agentStatementGridInsurerName = atom({
    key: 'agentStatementGridInsurerName',
    default: '',
});