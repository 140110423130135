const ConfirmAction = (props) => {
    var answer = window.confirm(props)
    if (answer) {
        return true;
    }
    else {
        return false
    }
}

export default ConfirmAction;