import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetClientByName = async (propsClientName) => {

    const q = query(collection(db, "ClientDetails"), where("clientName", "==", propsClientName));

    const CData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        const allClientDetails = {
            receivedDocID: doc.id,
            clientName: doc.data().clientName,
            IDNumber: doc.data().IDNumber,
            passportNumber: doc.data().passportNumber,
            KRAPin: doc.data().KRAPin,
            occupation: doc.data().occupation,
            phoneNumber: doc.data().phoneNumber,
            email: doc.data().email,
            address: doc.data().address
        }
        CData.push(allClientDetails);
    })

    return CData;
}

export default GetClientByName;