import Cookies from "js-cookie";

const SetCookie = (cookieName,cookieValue) => {
    Cookies.set(cookieName,cookieValue,{
        expires: 365,
        secure: true,
        sameSite: 'strict',
        path: '/'
    });
}

export default SetCookie;