import React, { useState, useEffect, useRef } from 'react';
import styles from './EmailClientStatementStepTwo.module.css';
import { MultiSelect } from '@mantine/core';
import axios from "axios";
import GetCookie from '../../Hooks/Cookie/GetCookie';
import Loader from '../Loader/Loader';
import html2pdf from 'html2pdf.js';
import EmailClientStatementElectronic from './EmailClientStatementElectronic';

const EmailClientStatementStepTwo = (props) => {
    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //send mail component
    const componentRef = useRef();

    //################################################################
    // get user input
    const [toList, setToList] = useState([]);
    const [toListFinal, setToListFinal] = useState([]);

    const [ccList, setCCList] = useState([]);
    const [ccListFinal, setCCListFinal] = useState(['info@simia.co.ke','jurgen@simiainsurance.com']);

    const [selectedClient, setSelectedClient] = useState({})

    const [body, setBody] = useState('We hope this message finds you well. At SIMIA, we understand the value of financial security and the importance of keeping your insurance coverage up to date. As part of our commitment to providing you with the best insurance services, we are pleased to share your Insurance Premium Statement for the current period. This statement is a summary of the premium amounts due for your insurance coverage. It provides a clear overview of your policy details and payment information. Please take a moment to review this statement carefully, ensuring the accuracy of the details mentioned. If you have any questions or need further assistance, our dedicated support team is here to help.')

    //################################################################
    // get data props
    const [finalData, setFinalData] = useState([])
    useEffect(() => {
        try {

            setFinalData(props.finalData);
            //set email if it exist
            if (Object.keys(props.finalData).length > 0) {
                if (Object.keys(props.finalData.clientDetails).length > 0) {
                    setSelectedClient(props.finalData.clientDetails);
                    if (props.finalData.clientDetails.email) {
                        const clientEmail = props.finalData.clientDetails.email;
                        setToList((current) => {
                            // if (!current.includes(clientEmail)) {
                            //     return [...current, { label: clientEmail, value: clientEmail }];
                            // }

                            const containsValue1 = current.some(obj => obj.value === clientEmail);

                            if (containsValue1) {
                                return [...current]
                            } else {
                                return [...current, { label: clientEmail, value: clientEmail }];
                            }

                        });
                    }
                }
            }

        } catch (error) { }
    }, [props.finalData]);

    //################################################################
    //step 3
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Email Client Statement',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Email Sent Successfully.',
            activity: 'Client Statement Email Dispatch',
        }
        props.propHandler(onePropObj);
    }

    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Email Client Statement',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Sending Mail.',
        }
        props.propHandler(onePropObj);
    }

    //################################################################
    //step 2
    //mail log
    const mailLog = () => {
        try {

            const currentDate = new Date();
            const isoDate = currentDate.toISOString();

            const emailLogEntry = {
                toList: toListFinal,
                ccList: ccListFinal,
                category: 'Client Statement Email Dispatch',
                title: selectedClient.clientName + 'Statement',
                sentBy: userDetails,
                time: isoDate,
            }

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createemaillogentry', emailLogEntry)
                .then(response => {
                    if (response.status == 200) {
                        showSuccessMSGOnExpenseSave();
                    }
                }).catch(error => { console.log(error); showErrorMSGOnExpenseSave(); });

        } catch (error) { console.log(error); showErrorMSGOnExpenseSave(); }
    }

  //################################################################
    //step 1
    //send mail
    const mail = () => {
        try {

            setIsLoading(true);
            const fileName = selectedClient.clientName + ' _ CLIENT STATEMENT _ SIMIA INSURANCE AGENCY LIMITED.pdf';
            const fname = selectedClient.clientName + ' _ CLIENT STATEMENT _ SIMIA INSURANCE AGENCY LIMITED';
            const subject = selectedClient.clientName + ' - CLIENT STATEMENT - SIMIA INSURANCE AGENCY LIMITED';
            var element = componentRef.current;
            var opt = {
                margin: .3,
                filename: fileName,
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                enableLinks: true,
            };

            // New Promise-based usage:
            //html2pdf().from(element).set(opt).save();
            html2pdf().from(element).set(opt).outputPdf().then(function (pdf) {
                // This logs the right base64
                // const base64 = btoa(pdf);
                const ff = {
                    file: btoa(pdf),
                    subject: subject,
                    toList: toListFinal,
                    ccList: ccListFinal,
                    body:body,
                    fileName:fileName,
                    fname:fname,
                }

                axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/sendelectronicstatement', ff)
                    .then(response => {
                        mailLog();
                    }).catch(error => { showErrorMSGOnExpenseSave() });
            });

        } catch (error) { showErrorMSGOnExpenseSave() }
    }

    const triggerMail = async () => {
        try {
            setIsLoading(true);
            ccListFinal.push('info.simiainsurance@gmail.com');
            finalData['toList'] = toListFinal;
            finalData['ccList'] = ccListFinal;

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/mailClientStatement', finalData)
                .then(response => {
                    if (response.status == 200) {
                        mailLog();
                    }
                }).catch(error => { console.log(error); showErrorMSGOnExpenseSave(); });

        } catch (error) { console.log(error); showErrorMSGOnExpenseSave(); }
    }

    //################################################################
    //content
    const content = <section className='f_7 email_client_statement_step_two'>
        <table className='w-full table-fixed border-collapse'>
            <tbody>
                <tr>
                    <td className='w-2/12 border border-gray-950 px-3 text-right'>
                        <p className="bold">CLIENT</p>
                    </td>
                    <td className='w-10/12 border border-gray-950 p-3'>
                        <p className='bold'>
                            {Object.keys(selectedClient).length > 0 ?
                                selectedClient.clientName ? selectedClient.clientName : ''
                                : ''}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className='w-2/12 border border-gray-950 px-3 text-right'>
                        <p className="bold">TO</p>
                    </td>
                    <td className='w-10/12 border border-gray-950'>
                        <MultiSelect
                            data={toList}
                            placeholder="Select items"
                            searchable
                            creatable
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = { value: query, label: query };
                                setToList((current) => [...current, item]);
                                return item;
                            }}
                            onChange={(toList) => { setToListFinal(() => [...toList]) }}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='w-2/12 border border-gray-950 px-3 text-right'>
                        <p className="bold">CC</p>
                    </td>
                    <td className='w-10/12 border border-gray-950'>
                        <MultiSelect
                            data={ccList}
                            placeholder="Select items"
                            searchable
                            creatable
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = { value: query, label: query };
                                setCCList((current) => [...current, item]);
                                return item;
                            }}
                            onChange={(ccList) => { setCCListFinal(() => [...ccList]) }}
                        />
                    </td>
                </tr>
                <tr>
                    <td className='w-2/12 border border-gray-950 px-3 text-right'>
                        <p className="bold">BODY</p>
                    </td>
                    <td className='w-10/12 border border-gray-950 p-3'>
                        <textarea value={body || ''} onChange={(e) => { setBody(e.target.value) }} placeholder='Email Body' className={styles.veh_input} />
                    </td>
                </tr>
            </tbody>
        </table>
        <div className='h_5'></div>
        <button onClick={mail} className='px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded float-right'>
            <i className="ri-send-plane-line mr-2"></i>
            <span>Send</span>
        </button>
    </section>

    //################################################################
    //electronic
    const electronic = <section style={{ display: "none" }}>
        <div ref={componentRef}>
            <EmailClientStatementElectronic finalData={finalData} />
        </div>
    </section>

    return (
        <section>
            {content}
            {electronic}
            {loaderContent}
        </section>
    );
}

export default EmailClientStatementStepTwo;