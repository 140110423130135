import './InsurerPortfolio.css';

const InsurerPortfolio = () => {
    return (
        <section>
            <div className='body_section_header'>
                <p>Insurer Portfolio</p>
            </div>
            <div className='body_section'>
                
            </div>
        </section>
    );
}

export default InsurerPortfolio;