import db from '../../../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const GetAllEmployeeDetails = async () => {
    const docRef = doc(db,"EmployeeDetails", "hsPhw4lkUuDuR8jgABkH");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        const DData = docSnap.data();

        const a = [];
        Object.entries(DData).map((doc) => {
            a.push({ docID: doc[0], docData: doc[1] })
        })

        return a;

    } else {
        // doc.data() will be undefined in this case
        //console.log("No such document!");
    }
}

export default GetAllEmployeeDetails;