import React, { useState, useEffect } from "react";
import GetAllReceipts from "../../Hooks/BackendConn/GeneralList/GetAllReceipts";
import Loader from "../Loader/Loader";
import { useRecoilValue } from 'recoil';
import Paginate from "../BodyPolicy/Paginate";
import './BodyReceiptList.css';

const BodyReceiptList = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //get items from recoil
    const fromDate = ''
    const toDate = ''

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllReceipts();
            setReceiptDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        receiptDocs.map((item) => {
            if (new Date(item.docData.receiptDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.docData.receiptDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [receiptDocs, fromDate, toDate]);

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = selectedPeriodFilteredDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.receiptDate).valueOf() - new Date(a.docData.receiptDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [selectedPeriodFilteredDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.receiptNumber.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    //right click

    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    const viewReceiptAllocation = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Receipt',
            previousView: 'Receipt List',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewReceiptAllocation}><p>View Allocation</p></span>
        <div className=''></div>
        <span><p>Print Receipt</p></span>
        <div className=''></div>
        <span><p>Reverse Receipt</p></span>
    </div>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //echo
    const receiptListContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Receipt Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='receipt_list_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Receipt No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Amount</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                return (
                                    <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                        <td id={item.docID}><p id={item.docID}>{item.id}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.receiptNumber}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{new Date(item.docData.receiptDate).toDateString()}</p></td>
                                        <td id={item.docID}><p id={item.docID}>
                                            {
                                                Object.values(item.docData.receiptAllocationDetails).map((value) => {
                                                    return (
                                                        value.receivedClientName + ' - ' + value.receivedPolicyNumber + ' - ' + value.receivedEndorsmentNumber + ' ## '
                                                    )
                                                })
                                            }
                                        </p></td>
                                        <td id={item.docID}><p id={item.docID}>{parseInt(item.docData.receiptAmount).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>


    //################################################################
    //################################################################
    //################################################################

    return (
        <section className='receipt_list'>
            <div className='body_section_header'>
                <p>Receipt List</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {receiptListContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default BodyReceiptList;