import React, { useEffect, useState } from 'react';
import db from '../../../firebase';
import { collection, addDoc, getDocs } from "firebase/firestore";
import DatePicker from 'react-date-picker';
import 'react-toastify/dist/ReactToastify.css';
import './BodyCreatePolicy.css';
import BodySelectOption from './BodySelectOption';
import Loader from '../Loader/Loader';
import InsuranceProducts from './InsuranceProducts';


const BodyRenewPolicy = (props) => {
    const [postedDocRef, setPostedDocRef] = useState(props.postedDocRef);

    const insuranceProducts = InsuranceProducts;

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    // get client data
    const [receivedClientDetails, setReceivedClientDetails] = useState([]);
    const setRetrievedClientData = (receivedData) => {
        setReceivedClientDetails((prevState) => {
            return [receivedData, ...prevState]
        });
    }

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "ClientDetails"));
            if (!isCancelled) {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    const allClientDetails = {
                        receivedDocID: doc.id,
                        receivedClientName: doc.data().clientName,
                    }
                    setRetrievedClientData(allClientDetails);
                    setIsloading(false);
                });
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [])

    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    //search term
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    //selected client
    const [clientName, setClientName] = useState(postedDocRef.clientName);
    const selectClientHandler = (value) => {
        setClientName(value);
    }

    // search section
    const searchContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='search_input'>
                <p>Search Client</p>
                <div className='h_5'></div>
                <input onChange={searchTermChangeHandler} type='text' placeholder='Client Name' />
            </div>
            <div className='h_5'></div>
            <div className='search_results'>
                {receivedClientDetails.filter((val) => {
                    if (searchTerm === '') { return val }
                    else if (val.receivedClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val
                    }
                }).map((val, key) => (
                    <div key={key}>
                        <div className='result' onClick={() => selectClientHandler(val.receivedClientName)}>
                            <p>{val.receivedClientName}</p>
                            <div className='h_2'></div>
                        </div>
                        <div className='h_5'></div>
                    </div>
                )
                )}

            </div>
        </div>
    </div>

    // set insurer name
    const [insurerName, setInsurerName] = useState('MAYFAIR INSURANCE COMPANY LIMITED');
    const insurerNameChangeHandler = (event) => {
        setInsurerName(event.target.value);
    }

    const [bankInterest, setBankInterest] = useState(postedDocRef.bankInterest)
    const bankInterestChangeHandler = (event) => {
        setBankInterest(event.target.value);
    }

    // set policy number
    const [policyNumber, setPolicyNumber] = useState(postedDocRef.policyNumber);
    const policyNumberChangeHandler = (event) => {
        setPolicyNumber(event.target.value);
    }

    // set selected product class to state
    const [selectedProductClass, setSelectedProductClass] = useState(postedDocRef.selectedProductClass);
    const productClassChangeHandler = (event) => {
        setSelectedProductClass(event.target.value);
    }

    // get subclass array
    const selectedProductSubClassIndex = insuranceProducts.find(({ productClass }) => productClass === selectedProductClass);
    const selectedProductSubClassIndexed = Object.values(selectedProductSubClassIndex.subClass);

    const [selectedProductSubClass, setSelectedProductSubClass] = useState(postedDocRef.selectedProductSubClass);
    const productSubClassChangeHandler = (event) => {
        setSelectedProductSubClass(event.target.value)
    }

    // set commission rate
    const [commissionRate, setCommissionRate] = useState(postedDocRef.commissionRate);
    useEffect(() => {
        switch (selectedProductClass) {
            case '(02) - Engineering':
                setCommissionRate(0.2);
                break;
            case '(03) - Domestic':
                setCommissionRate(0.2);
                break;
            case '(04) - Fire Industrial':
                setCommissionRate(0.25);
                break;
            case '(05) - Public Liability':
                setCommissionRate(0.2);
                break;
            case '(06) - Marine':
                setCommissionRate(0.175);
                break;
            case '(07) - Motor Private':
                setCommissionRate(0.1);
                break;
            case '(08) - Motor Commercial':
                setCommissionRate(0.1);
                break;
            case '(09) - Personal Accident':
                setCommissionRate(0.2);
                break;
            case '(10) - Theft':
                setCommissionRate(0.2);
                break;
            case '(11) - Workmens Compensation':
                setCommissionRate(0.2);
                break;
            case '(12) - Miscellaneous':
                setCommissionRate(0.1);
                break;
        }
    }, [selectedProductClass]);

    useEffect(() => {
        switch (selectedProductSubClass) {
            case '(043) - Terrorism':
                setCommissionRate(0.1);
                break;
            case '(064) - CARRIERS LEGAL LIABILITY':
                setCommissionRate(0.2);
                break;
        }
    }, [selectedProductSubClass])

    // set business type
    const [businessType, setBusinessType] = useState('Renewal');
    const businessTypeChangeHandler = (event) => {
        setBusinessType(event.target.value)
    }

    // set endorsment number
    const [endorsmentNumber, setEndorsmentNumber] = useState('');
    const endorsmentNumberChangeHandler = (event) => {
        setEndorsmentNumber(event.target.value)
    }

    //set to new if business is new
    useEffect(() => {
        if (businessType === 'New') { setEndorsmentNumber('New') }
    }, [businessType])

    //hide show endowsment number
    let endorsmentNumberContent = <p></p>
    if (businessType !== 'New') {
        endorsmentNumberContent = <div>
            <div className='h_10'></div>
            <input type='text' placeholder='Endorsment Number' onChange={endorsmentNumberChangeHandler} />
        </div>
    }

    // set inception date
    const [inceptionDate, setInceptionDate] = useState('');
    const inceptionDateChangeHandler = (event) => {
        setInceptionDate(event.target.value);
    }

    // set expiry date
    const [expiryDate, setExpiryDate] = useState('');
    const expiryDateChangeHandler = (event) => {
        setExpiryDate(event.target.value);
    }

    //insurer debit date
    const [insurerDebitDate, setInsurerDebitDate] = useState('');
    const [dvalue, onDChange] = useState('');
    useEffect(() => {
        const Dyear = new Date(dvalue).getFullYear();
        const Dmonth = new Date(dvalue).getMonth() + 1;
        const Ddate = new Date(dvalue).getDate();
        const DexpiryDateCleanedJan = Dyear + '-' + Dmonth + '-' + Ddate;
        setInsurerDebitDate(DexpiryDateCleanedJan)
    }, [dvalue])

    useEffect(() => {
        if (inceptionDate !== '') {
            const year = new Date(inceptionDate).getFullYear() + 1;
            const month = new Date(inceptionDate).getMonth() + 1;
            const date = new Date(inceptionDate).getDate() - 1;
            if (date === 0) {
                switch (month) {
                    case 1:
                        const yearJan = new Date(inceptionDate).getFullYear();
                        const monthJan = 12;
                        const dateJan = 31;
                        const expiryDateCleanedJan = yearJan + '-' + monthJan + '-' + dateJan;
                        setExpiryDate(expiryDateCleanedJan);
                        break;
                    case 2:
                        const yearFeb = new Date(inceptionDate).getFullYear() + 1;
                        const monthFeb = 1;
                        const dateFeb = 31;
                        const expiryDateCleanedFeb = yearFeb + '-' + monthFeb + '-' + dateFeb;
                        setExpiryDate(expiryDateCleanedFeb);
                        break;
                    case 3:
                        const yearMar = new Date(inceptionDate).getFullYear() + 1;
                        const monthMar = 2;
                        const dateMar = 28;
                        const expiryDateCleanedMar = yearMar + '-' + monthMar + '-' + dateMar;
                        setExpiryDate(expiryDateCleanedMar);
                        break;
                    case 4:
                        const yearApr = new Date(inceptionDate).getFullYear() + 1;
                        const monthApr = 3;
                        const dateApr = 31;
                        const expiryDateCleanedApr = yearApr + '-' + monthApr + '-' + dateApr;
                        setExpiryDate(expiryDateCleanedApr);
                        break;
                    case 5:
                        const yearMay = new Date(inceptionDate).getFullYear() + 1;
                        const monthMay = 4;
                        const dateMay = 30;
                        const expiryDateCleanedMay = yearMay + '-' + monthMay + '-' + dateMay;
                        setExpiryDate(expiryDateCleanedMay);
                        break;
                    case 6:
                        const yearJun = new Date(inceptionDate).getFullYear() + 1;
                        const monthJun = 5;
                        const dateJun = 31;
                        const expiryDateCleanedJun = yearJun + '-' + monthJun + '-' + dateJun;
                        setExpiryDate(expiryDateCleanedJun);
                        break;
                    case 7:
                        const yearJul = new Date(inceptionDate).getFullYear() + 1;
                        const monthJul = 6;
                        const dateJul = 30;
                        const expiryDateCleanedJul = yearJul + '-' + monthJul + '-' + dateJul;
                        setExpiryDate(expiryDateCleanedJul);
                        break;
                    case 8:
                        const yearAug = new Date(inceptionDate).getFullYear() + 1;
                        const monthAug = 7;
                        const dateAug = 31;
                        const expiryDateCleanedAug = yearAug + '-' + monthAug + '-' + dateAug;
                        setExpiryDate(expiryDateCleanedAug);
                        break;
                    case 9:
                        const yearSep = new Date(inceptionDate).getFullYear() + 1;
                        const monthSep = 8;
                        const dateSep = 31;
                        const expiryDateCleanedSep = yearSep + '-' + monthSep + '-' + dateSep;
                        setExpiryDate(expiryDateCleanedSep);
                        break;
                    case 10:
                        const yearOct = new Date(inceptionDate).getFullYear() + 1;
                        const monthOct = 9;
                        const dateOct = 30;
                        const expiryDateCleanedOct = yearOct + '-' + monthOct + '-' + dateOct;
                        setExpiryDate(expiryDateCleanedOct);
                        break;
                    case 11:
                        const yearNov = new Date(inceptionDate).getFullYear() + 1;
                        const monthNov = 10;
                        const dateNov = 31;
                        const expiryDateCleanedNov = yearNov + '-' + monthNov + '-' + dateNov;
                        setExpiryDate(expiryDateCleanedNov);
                        break;
                    case 12:
                        const yearDec = new Date(inceptionDate).getFullYear() + 1;
                        const monthDec = 11;
                        const dateDec = 30;
                        const expiryDateCleanedDec = yearDec + '-' + monthDec + '-' + dateDec;
                        setExpiryDate(expiryDateCleanedDec);
                        break;
                }
            }
            else {
                const expiryDateCleaned = year + '-' + month + '-' + date;
                setExpiryDate(expiryDateCleaned);
            }


        }
    }, [inceptionDate])

    // particulars of the risk
    //set location of the risk
    const [locationOfTheRisk, setLocationOfTheRisk] = useState(postedDocRef.locationOfTheRisk);
    const locationOfTheRiskChangeHandler = (event) => {
        setLocationOfTheRisk(event.target.value);
    }

    // set total sum insured
    const [totalSumInsured, setTotalSumInsured] = useState(postedDocRef.totalSumInsured);
    const totalSumInsuredChangeHandler = (event) => {
        setTotalSumInsured(event.target.value);
    }

    // particulars of the risk content
    let particularsOfTheRiskContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private' && selectedProductClass !== '(08) - Motor Commercial') {
        particularsOfTheRiskContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Particulars Of The Risk</p></div>
                <div className='category_content'>
                    <div className='category_content_one'>
                        <textarea value={locationOfTheRisk} rows='3' placeholder='Location Of Risk' onChange={locationOfTheRiskChangeHandler} />
                    </div>
                    <div className='category_content_two'>
                        <input value={totalSumInsured} placeholder='Total Sum Insured' onChange={totalSumInsuredChangeHandler} />
                    </div>
                </div>
            </div>
        </section>
    }

    // policy amounts
    const [premium, setPremium] = useState(postedDocRef.premium);
    const premiumChangeHandler = (event) => {
        setPremium(event.target.value);
    }

    const [PVTPremium, setPVTPremium] = useState(postedDocRef.PVTPremium);
    const PVTPremiumChangeHandler = (event) => {
        setPVTPremium(event.target.value);
    }

    const [stampDuty, setStampDuty] = useState(0);
    const stampDutyChangeHandler = (event) => {
        setStampDuty(event.target.value);
    }

    useEffect(() => {
        if (businessType === 'New') {
            setStampDuty(40);
        }
        else { setStampDuty(0) }
    }, [businessType])

    const [TLevy, setTLevy] = useState(postedDocRef.TLevy);
    const TLevyChangeHandler = (event) => {
        setTLevy(event.target.value);
    }

    const [PHCFund, setPHCFund] = useState(postedDocRef.PHCFund);
    const PHCFundChangeHandler = (event) => {
        setPHCFund(event.target.value);
    }

    const [commission, setCommission] = useState(postedDocRef.commission);
    const commissionChangeHandler = (event) => {
        setCommission(event.target.value);
    }

    const [withholdingTax, setWithholdingTax] = useState(postedDocRef.withholdingTax);
    const withholdingTaxChangeHandler = (event) => {
        setWithholdingTax(event.target.value);
    }

    const [commissionPayable, setCommissionPayable] = useState(0);
    const [insurerPolicyNet, setinsurerPolicyNet] = useState(0);

    const [policyNet, setPolicyNet] = useState(postedDocRef.policyNet);
    const policyNetChangeHandler = (event) => {
        setPolicyNet(event.target.value);
    }

    //table 2
    const [earthquake, setEarthquake] = useState(postedDocRef.earthquake);
    const earthquakeChangeHandler = (event) => {
        setEarthquake(event.target.value);
    }

    const [excessProtector, setExcessProtector] = useState(postedDocRef.excessProtector);
    const excessProtectorChangeHandler = (event) => {
        setExcessProtector(event.target.value);
    }

    const [comesaCard, setComesaCard] = useState(postedDocRef.comesaCard);
    const comesaCardChangeHandler = (event) => {
        setComesaCard(event.target.value);
    }

    const [windScreen, setWindScreen] = useState(postedDocRef.windScreen);
    const windScreenChangeHandler = (event) => {
        setWindScreen(event.target.value);
    }

    const [radioCasset, setRadioCasset] = useState(postedDocRef.radioCasset);
    const radioCassetChangeHandler = (event) => {
        setRadioCasset(event.target.value);
    }

    const receiptAllocationBreakDown = [];
    const commissionAllocationBreakDown = [];

    useEffect(() => {
        const calculatedTLevy = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * 0.002);
        setTLevy(calculatedTLevy);
        const calculatedPHCFund = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * 0.0025);
        setPHCFund(calculatedPHCFund);
        const calculatedCommission = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * commissionRate);
        setCommission(calculatedCommission);
    }, [premium, PVTPremium, excessProtector, earthquake, windScreen, radioCasset]);

    useEffect(() => {
        const calculatedWithholdingTax = Math.round(commission * 0.1);
        setWithholdingTax(calculatedWithholdingTax);
    }, [commission]);

    useEffect(() => {
        const calculatedPayableCommission = +commission - +withholdingTax;
        setCommissionPayable(calculatedPayableCommission);
    }, [commission, withholdingTax]);

    useEffect(() => {
        const calculatedPolicyNet = +premium + +PVTPremium + +stampDuty + +TLevy + +PHCFund + +earthquake + +excessProtector + +comesaCard + +windScreen + +radioCasset;
        setPolicyNet(calculatedPolicyNet);
    }, [premium, PVTPremium, stampDuty, TLevy, PHCFund, earthquake, excessProtector, comesaCard, windScreen, radioCasset]);

    useEffect(() => {
        const calculatedInsurerPolicyNet = +policyNet - +commissionPayable;
        setinsurerPolicyNet(calculatedInsurerPolicyNet);
    }, [commissionPayable, policyNet]);


    let policyAmountContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Policy Amounts</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='policy_amounts_table'>
                        <thead>
                            <tr>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>Earth Quake</p></td>
                                <td><p>Excess Protector</p></td>
                                <td><p>Comesa Card</p></td>
                                <td><p>Wind Screen</p></td>
                                <td><p>Radio Casset</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={premiumChangeHandler} value={premium} /></td>
                                <td><input onChange={PVTPremiumChangeHandler} value={PVTPremium} /></td>
                                <td><input onChange={earthquakeChangeHandler} value={earthquake} /></td>
                                <td><input onChange={excessProtectorChangeHandler} value={excessProtector} /></td>
                                <td><input onChange={comesaCardChangeHandler} value={comesaCard} /></td>
                                <td><input onChange={windScreenChangeHandler} value={windScreen} /></td>
                                <td><input onChange={radioCassetChangeHandler} value={radioCasset} /></td>

                            </tr>
                        </tbody>
                    </table>
                    <table className='policy_amounts_table_two'>
                        <thead>
                            <tr>
                                <td><p>S. Duty</p></td>
                                <td><p>T. Levy</p></td>
                                <td><p>P.H.C Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W. Tax</p></td>
                                <td><p>Policy Net</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={stampDutyChangeHandler} value={stampDuty} /></td>
                                <td><input onChange={TLevyChangeHandler} value={TLevy} /></td>
                                <td><input onChange={PHCFundChangeHandler} value={PHCFund} /></td>
                                <td><input onChange={commissionChangeHandler} value={commission} /></td>
                                <td><input onChange={withholdingTaxChangeHandler} value={withholdingTax} /></td>
                                <td><input onChange={policyNetChangeHandler} value={policyNet} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    // employee's details
    const [employeesDetailsItems, setEmployeesDetailsItems] = useState(Object.values(postedDocRef.employeesDetailsItems));
    const onEmployeeDetailItemChangeHandler = (index, event) => {
        const employeesDetailsValues = [...employeesDetailsItems];
        employeesDetailsValues[index][event.target.name] = event.target.value;
        setEmployeesDetailsItems(employeesDetailsValues);
    }

    const addEmployeeDetailhandler = () => {
        setEmployeesDetailsItems([...employeesDetailsItems, { positionOfEmployee: '', noOfEmployees: '', estimatedAnnualSalary: '' }])
    }

    const removeEmployeeDetailhandler = (index) => {
        const values = [...employeesDetailsItems];
        values.splice(index, 1);
        setEmployeesDetailsItems(values);
    }

    let employeesDetailsContent = <p></p>
    if (selectedProductClass === '(11) - Workmens Compensation') {
        employeesDetailsContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Employee's Details</p></div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='employees_details_table'>
                            <thead>
                                <tr>
                                    <td><p>Position Of Employee</p></td>
                                    <td><p>No Of Employees</p></td>
                                    <td><p>Estimated Annual Salary</p></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {employeesDetailsItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.positionOfEmployee} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='positionOfEmployee' /></td>
                                            <td><input value={item.noOfEmployees} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='noOfEmployees' /></td>
                                            <td><input value={item.estimatedAnnualSalary} onChange={event => onEmployeeDetailItemChangeHandler(index, event)} type='text' name='estimatedAnnualSalary' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeEmployeeDetailhandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addEmployeeDetailhandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // description
    const [propertyDescriptionItems, setPropertyDescriptionItems] = useState(Object.values(postedDocRef.propertyDescriptionItems));
    const onDescriptionItemChangeHandler = (index, event) => {
        const values = [...propertyDescriptionItems];
        values[index][event.target.name] = event.target.value;
        setPropertyDescriptionItems(values);
    }

    const addPropertyDescriptionhandler = () => {
        setPropertyDescriptionItems([...propertyDescriptionItems, { propertyDescription: '', sumInsured: '' }])
    }

    const removePropertyDescriptionhandler = (index) => {
        const values = [...propertyDescriptionItems];
        values.splice(index, 1);
        setPropertyDescriptionItems(values);
    }

    let descriptionContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private' && selectedProductClass !== '(08) - Motor Commercial') {
        descriptionContent = <section>
            <div>
                <div className='h_15'></div>
                <div className='category'>
                    <div className='category_header'><p>Description / Liability / Circumstances / Limits</p></div>
                    <div className='category_content'>
                        <div className='category_content_three'>
                            <table className='property_description_table'>
                                <thead>
                                    <tr>
                                        <td><p>Description</p></td>
                                        <td><p>Sum Insured</p></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {propertyDescriptionItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><textarea value={item.propertyDescription} onChange={event => onDescriptionItemChangeHandler(index, event)} type='text' name='propertyDescription' /></td>
                                                <td><input value={item.sumInsured} onChange={event => onDescriptionItemChangeHandler(index, event)} type='text' name='sumInsured' /></td>
                                                <td className='property_description_controls'>
                                                    <i className="ri-close-circle-line" onClick={() => removePropertyDescriptionhandler(index)}></i>
                                                    <div className='w_10'></div>
                                                    <i className="ri-add-circle-line" onClick={addPropertyDescriptionhandler}></i>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }

    // benefits
    const [benefitsItems, setBenefitsItems] = useState(Object.values(postedDocRef.benefitsItems));
    const onBenefitsItemChangeHandler = (index, event) => {
        const benefitsValues = [...benefitsItems];
        benefitsValues[index][event.target.name] = event.target.value;
        setBenefitsItems(benefitsValues);
    }

    const addBenefitshandler = () => {
        setBenefitsItems([...benefitsItems, { title: '', elaboration: '' }])
    }

    const removeBenefitshandler = (index) => {
        const benefitsValues = [...benefitsItems];
        benefitsValues.splice(index, 1);
        setBenefitsItems(benefitsValues);
    }

    let benefitsContent = <p></p>
    if (selectedProductSubClass === '(112) - Wiba' || selectedProductSubClass === '(114) - Wiba Plus') {
        benefitsContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Benefits</p></div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='benefits_table'>
                            <thead>
                                <tr>
                                    <td><p>Title</p></td>
                                    <td><p>Elaboration</p></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {benefitsItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.title} onChange={event => onBenefitsItemChangeHandler(index, event)} type='text' name='title' /></td>
                                            <td><textarea value={item.elaboration} onChange={event => onBenefitsItemChangeHandler(index, event)} type='text' name='elaboration' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeBenefitshandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addBenefitshandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // deductibles
    const [deductibleItems, setDeductibleItems] = useState(Object.values(postedDocRef.deductibleItems));
    const onDeductibleItemChangeHandler = (index, event) => {
        const deductiblesValues = [...deductibleItems];
        deductiblesValues[index][event.target.name] = event.target.value;
        setDeductibleItems(deductiblesValues);
    }

    const addDeductibleItemChangeHandler = () => {
        setDeductibleItems([...deductibleItems, { deductibleItem: '' }])
    }

    const removeDeductibleItemChangeHandler = (index) => {
        const deductiblesValues = [...deductibleItems];
        deductiblesValues.splice(index, 1);
        setDeductibleItems(deductiblesValues);
    }

    let deductiblesContent = <p></p>
    if (selectedProductClass !== '(07) - Motor Private' && selectedProductClass !== '(08) - Motor Commercial') {
        deductiblesContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Deductibles</p></div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='deductibles_table'>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                {deductibleItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.deductibleItem} onChange={event => onDeductibleItemChangeHandler(index, event)} type='text' name='deductibleItem' /></td>
                                            <td className='deductibles_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeDeductibleItemChangeHandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addDeductibleItemChangeHandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // particulars of the vehicle
    const [vehicleItems, setVehicleItems] = useState(Object.values(postedDocRef.vehicleItems));
    const onVehicleItemChangeHandler = (index, event) => {
        const vehicleValues = [...vehicleItems];
        vehicleValues[index][event.target.name] = event.target.value;
        setVehicleItems(vehicleValues);
    }

    const addVehiclehandler = () => {
        setVehicleItems([...vehicleItems, { regNo: '', make: '', bodyType: '', horsePower: '', yearOfManufacture: '', carryingCapacity: '', estimatedValue: '', windScreenLimit: '', radioCassetLimit: '', valuationValue: '', valuationDate: '', valuationDocumentName: '', valuationDocumentLocation: '' }]);
    }

    const removeVehiclehandler = (index) => {
        const vehicleValues = [...vehicleItems];
        vehicleValues.splice(index, 1);
        setVehicleItems(vehicleValues);
    }

    let particularsOfTheVehicleContent = <p></p>
    if (selectedProductClass === '(07) - Motor Private' || selectedProductClass === '(08) - Motor Commercial') {
        particularsOfTheVehicleContent = <section>
            <div className='h_15'></div>
            <div className='category'>
                <div className='category_header'><p>Particulars Of The Vehicle</p></div>
                <div className='category_content'>
                    <div className='category_content_three'>
                        <table className='vehicle_table'>
                            <thead>
                                <tr>
                                    <td><p>Reg No.</p></td>
                                    <td><p>Make</p></td>
                                    <td><p>Body Type</p></td>
                                    <td><p>Horse Power</p></td>
                                    <td><p>Year Of Manuf.</p></td>
                                    <td><p>Carrying Capacity</p></td>
                                    <td><p>Estimated Value</p></td>
                                    <td><p>Wind Screen Limit</p></td>
                                    <td><p>Radio Casset Limit</p></td>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleItems.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><textarea value={item.regNo} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='regNo' /></td>
                                            <td><textarea value={item.make} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='make' /></td>
                                            <td><textarea value={item.bodyType} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='bodyType' /></td>
                                            <td><textarea value={item.horsePower} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='horsePower' /></td>
                                            <td><textarea value={item.yearOfManufacture} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='yearOfManufacture' /></td>
                                            <td><textarea value={item.carryingCapacity} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='carryingCapacity' /></td>
                                            <td><textarea value={item.estimatedValue} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='estimatedValue' /></td>
                                            <td><textarea value={item.windScreenLimit} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='windScreenLimit' /></td>
                                            <td><textarea value={item.radioCassetLimit} onChange={event => onVehicleItemChangeHandler(index, event)} type='text' name='radioCassetLimit' /></td>
                                            <td className='property_description_controls'>
                                                <i className="ri-close-circle-line" onClick={() => removeVehiclehandler(index)}></i>
                                                <div className='w_10'></div>
                                                <i className="ri-add-circle-line" onClick={addVehiclehandler}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    }

    // clauses
    const [clauseItems, setClauseItems] = useState(Object.values(postedDocRef.clauseItems));
    const onclauseItemChangeHandler = (index, event) => {
        const clausesValues = [...clauseItems];
        clausesValues[index][event.target.name] = event.target.value;
        setClauseItems(clausesValues);
    }

    const addClauseItemChangeHandler = () => {
        setClauseItems([...clauseItems, { clauseItem: '' }])
    }

    const removeClauseItemChangeHandler = (index) => {
        const clausesValues = [...clauseItems];
        clausesValues.splice(index, 1);
        setClauseItems(clausesValues);
    }

    let clausesContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Clauses</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='clauses_table'>
                        <thead><tr><td></td><td></td></tr></thead>
                        <tbody>
                            {clauseItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><textarea value={item.clauseItem} onChange={event => onclauseItemChangeHandler(index, event)} type='text' name='clauseItem' /></td>
                                        <td className='clauses_controls'>
                                            <i className="ri-close-circle-line" onClick={() => removeClauseItemChangeHandler(index)}></i>
                                            <div className='w_10'></div>
                                            <i className="ri-add-circle-line" onClick={addClauseItemChangeHandler}></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    // additional info
    let processAdditionalInstructions = [{ additionalInstructionItem: '' }]
   
    const [additionalInstructionsItems, setadditionalInstructionsItems] = useState(processAdditionalInstructions);
    const onAdditionalInstructionsItemsChangeHandler = (index, event) => {
        const additionalInstructionsValues = [...additionalInstructionsItems];
        additionalInstructionsValues[index][event.target.name] = event.target.value;
        setadditionalInstructionsItems(additionalInstructionsValues);
    }

    const addAdditionalInstructionItemChangeHandler = () => {
        setadditionalInstructionsItems([...additionalInstructionsItems, { additionalInstructionItem: '' }])
    }

    const removeAdditionalInstructionItemChangeHandler = (index) => {
        const additionalInstructionsValues = [...additionalInstructionsItems];
        additionalInstructionsValues.splice(index, 1);
        setadditionalInstructionsItems(additionalInstructionsValues);
    }

    //content
    let additionalInstructionsContent = <section>
    <div className='h_15'></div>
    <div className='category'>
        <div className='category_header'><p>Additional Instructions</p></div>
        <div className='category_content'>
            <div className='category_content_three'>
                <table className='clauses_table'>
                    <thead><tr><td></td><td></td></tr></thead>
                    <tbody>
                        {additionalInstructionsItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><textarea value={item.additionalInstructionItem} onChange={event => onAdditionalInstructionsItemsChangeHandler(index, event)} type='text' name='additionalInstructionItem' /></td>
                                    <td className='clauses_controls'>
                                        <i className="ri-close-circle-line" onClick={() => removeAdditionalInstructionItemChangeHandler(index)}></i>
                                        <div className='w_10'></div>
                                        <i className="ri-add-circle-line" onClick={addAdditionalInstructionItemChangeHandler}></i>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div className='h_15'></div>
</section>

    // change view on success post
    const viewCreatedPolicyOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'View Policy', postedDocRef: postedDocRef });
    }

    // form submission
    async function createPolicyFormSubmitHandler(event) {
        event.preventDefault();
        setIsloading(true);

        //create main object for posting
        const policyProcessedData = {
            clientName: clientName,
            insurerName: insurerName,
            bankInterest: bankInterest,
            policyNumber: policyNumber,
            selectedProductClass: selectedProductClass,
            selectedProductSubClass: selectedProductSubClass,
            commissionRate: commissionRate,
            businessType: businessType,
            endorsmentNumber: endorsmentNumber,
            inceptionDate: inceptionDate,
            expiryDate: expiryDate,
            insurerDebitDate: insurerDebitDate,
            locationOfTheRisk: locationOfTheRisk,
            totalSumInsured: totalSumInsured,
            premium: premium,
            PVTPremium: PVTPremium,
            stampDuty: stampDuty,
            TLevy: TLevy,
            PHCFund: PHCFund,
            commission: commission,
            commissionPayable: commissionPayable,
            expectedCommissionPayable: commissionPayable,
            outstandingCommission: commission,
            withholdingTax: withholdingTax,
            policyNet: policyNet,
            earthquake: earthquake,
            excessProtector: excessProtector,
            comesaCard: comesaCard,
            windScreen: windScreen,
            radioCasset: radioCasset,
            insurerPolicyNet: insurerPolicyNet,
            receiptAllocationBreakDown: receiptAllocationBreakDown,
            commissionAllocationBreakDown: commissionAllocationBreakDown,
            status: 'Pending',
            outstanding: policyNet,
            transactionType: 'Debit',
            employeesDetailsItems: { ...employeesDetailsItems },
            propertyDescriptionItems: { ...propertyDescriptionItems },
            benefitsItems: { ...benefitsItems },
            deductibleItems: { ...deductibleItems },
            vehicleItems: { ...vehicleItems },
            clauseItems: { ...clauseItems },
            dateCreated: new Date(),
            createdBy: '',
            policyDocID: '',
            policyDocName: '',
            policyDocLocation: '',
            additionalInstructionsItems: { ...additionalInstructionsItems }
        }

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), policyProcessedData);
            console.log("Document written with ID: ", docRef.id);
            setIsloading(false)
            viewCreatedPolicyOnSuccessPosting(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }


    // #############################################################################################
    // #############################################################################################
    //##############################################################################################

    return (
        <section className='create_policy_section'>
            <form onSubmit={createPolicyFormSubmitHandler}>
                <div className='body_section_header'>
                    <p>Renew Policy</p>
                    <button type='submit' className='btn_pink'>Save Details</button>
                </div>
                <div className='body_section create_policy'>
                    <div className='h_5'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Client Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink' onClick={() => showHideRightNav('Show')}>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={clientName} type='text' placeholder='Client Name' readOnly />
                            </div>
                        </div>
                    </div>
                    {/* end client details */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Insurer Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink'>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={insurerName} type='text' placeholder='Insurer' readOnly />
                            </div>
                        </div>
                    </div>
                    {/* end insurer details */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Bank Interest Details</p>
                            <div className='client_details_controls'>
                                <div className='btn_yellow'>
                                    <i className="ri-user-add-line"></i>
                                </div>
                                <div className='w_5'></div>
                                <div className='btn_pink'>
                                    <i className="ri-search-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={bankInterest} onChange={bankInterestChangeHandler} type='text' placeholder='Bank' />
                            </div>
                        </div>
                    </div>
                    {/* end bank interest */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Policy Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input value={policyNumber} type='text' placeholder='Policy Number' onChange={policyNumberChangeHandler} readOnly />
                                <div className='h_10'></div>
                                <select onChange={productClassChangeHandler} value={selectedProductClass} disabled={true}>
                                    {insuranceProducts.map((product) => (
                                        <BodySelectOption key={product.productClass} option={product.productClass} />
                                    ))}
                                </select>
                                <div className='h_10'></div>
                                <select onChange={productSubClassChangeHandler} value={selectedProductSubClass} disabled={true}>
                                    {selectedProductSubClassIndexed.map((product) => (
                                        <BodySelectOption key={product} option={product} />
                                    ))}
                                </select>
                            </div>
                            {/* end column one */}
                            <div className='category_content_two'>
                                <select onChange={businessTypeChangeHandler} value={businessType} disabled={true}>
                                    <option>Select Business Type</option>
                                    <option>New</option>
                                    <option>Renewal</option>
                                </select>
                                {endorsmentNumberContent}
                                <div className='h_10'></div>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={inceptionDate} placeholder='Inception Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={inceptionDateChangeHandler} />
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={expiryDate} placeholder='Expiry Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={expiryDateChangeHandler} />
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                <div className='date_picker_container'>
                                    <div className='date_container'>
                                        <p>Insurer Debit Date</p>
                                        <div className='w_5'></div>
                                        <DatePicker onChange={onDChange} value={dvalue} format='d/ M/y' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end policy details */}
                    {policyAmountContent}
                    {particularsOfTheRiskContent}
                    {employeesDetailsContent}
                    {descriptionContent}
                    {benefitsContent}
                    {deductiblesContent}
                    {particularsOfTheVehicleContent}
                    {clausesContent}
                    {additionalInstructionsContent}
                </div>
            </form>
            {searchContent}
            {loaderContent}
        </section>
    );
}

export default BodyRenewPolicy;