import { useEffect, useState } from "react";
import styles from './EmailClientStatementElectronic.module.css';
import companyLogo from '../../Assets/img/simia_logo_dark.png';
import DateFormat from '../../Hooks/UI/DateFormat'
import NumberFormat from "../../Hooks/UI/NumberFormat";
import CIBLogo from '../../Assets/img/cib_logo.png';
import MPESALogo from '../../Assets/img/mpesa_logo.png';
import GUARDIANLogo from '../../Assets/img/guardian_logo.png';

const EmailClientStatementViewable = (props) => {
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        setFinalData(props.finalData);
    }, [props]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const content = <section>
        <div className={styles.stmt_head_details}>
            <table className={styles.email_client_statement_printable_main_table} style={{ width: '100%' }}>
                <tbody>

                    {/* logo */}
                    {/* <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>
                            <div><img className={styles.companyLogo} src={companyLogo} /></div>
                        </td>
                    </tr> */}

                    {/* details */}
                    {/* <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>

                            <table className={styles.stmt_head_details_table}>
                                <thead>
                                    <tr>
                                        <td colSpan="3" className={`bold ${styles.stmt_section_header}`}>DETAILS</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="bold">Billed To</p>
                                            <div className="h_2"></div>
                                            <p>
                                                {
                                                    finalData.clientDetails
                                                    && Object.keys(finalData.clientDetails).length > 0
                                                    && finalData.clientDetails.clientName
                                                    && finalData.clientDetails.clientName
                                                }
                                            </p>
                                            <div className="h_1"></div>
                                            <p>
                                                {
                                                    finalData.clientDetails
                                                    && Object.keys(finalData.clientDetails).length > 0
                                                    && finalData.clientDetails.address
                                                    && finalData.clientDetails.address
                                                }
                                            </p>
                                            <div className="h_1"></div>
                                            <p>
                                                {
                                                    finalData.clientDetails
                                                    && Object.keys(finalData.clientDetails).length > 0
                                                    && finalData.clientDetails.KRAPin
                                                    && finalData.clientDetails.KRAPin
                                                }
                                            </p>
                                        </td>
                                        <td>
                                            <p className="bold">Billed By</p>
                                            <div className="h_2"></div>
                                            <p>SIMIA INSURANCE AGENCY LIMITED</p>
                                            <div className="h_1"></div>
                                            <p>P.O BOX 623 - 10300, ELDORET</p>
                                            <div className="h_1"></div>
                                            <p>Email: info@simia.co.ke</p>
                                            <div className="h_1"></div>
                                            <p>Tel: 0723777780</p>
                                        </td>
                                        <td>
                                            <p className="bold">Period Covered</p>
                                            <div className="h_1"></div>
                                            <p>
                                                {
                                                    finalData.emailClientStatementFilterFromDateRN
                                                    && finalData.emailClientStatementFilterFromDateRN
                                                }
                                                {' - '}
                                                {
                                                    finalData.emailClientStatementFilterToDateRN
                                                    && finalData.emailClientStatementFilterToDateRN
                                                }
                                            </p>
                                            <div className="h_2"></div>
                                            <p className="bold">Statement Date</p>
                                            <div className="h_1"></div>
                                            <p>
                                                {
                                                    finalData.statementDate
                                                    && finalData.statementDate
                                                }
                                            </p>
                                            <div className="h_2"></div>
                                            <p className="bold">Insurer</p>
                                            <div className="h_1"></div>
                                            <p>MAYFAIR INSURANCE COMPANY LIMITED</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </td>
                    </tr> */}

                    {/* spacer */}
                    <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>
                            <div className='h_5'></div>
                        </td>
                    </tr>

                    {/* policies */}
                    <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>

                            <table className={styles.stmt_head_policies_table}>
                                <thead>
                                    <tr>
                                        <td className={`bold ${styles.stmt_section_headerr}`} colSpan='9'>POLICIES</td>
                                    </tr>
                                    <tr>
                                        <td><p className="bold">Date</p></td>
                                        <td><p className="bold">Policy Number</p></td>
                                        <td><p className="bold">Endorsment Number</p></td>
                                        <td><p className="bold">Risk Class</p></td>
                                        <td><p className="bold">Details</p></td>
                                        <td><p className="bold">Policy Net</p></td>
                                        <td><p className="bold">Credit Net</p></td>
                                        <td><p className="bold">Outstanding</p></td>
                                        <td><p className="bold">Docs</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(finalData.mainSection)
                                        && finalData.mainSection.map((doc, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><p>
                                                        {
                                                            doc.transactionType !== 'Totals'
                                                            && DateFormat(doc.universalDate)
                                                        }
                                                    </p></td>
                                                    <td><p>{doc.policyNumber}</p></td>
                                                    <td><p>{doc.endorsmentNumber}</p></td>
                                                    <td><p>{doc.policySubClass}</p></td>
                                                    <td><p>{doc.details}</p></td>
                                                    <td><p>
                                                        {
                                                            doc.transactionType === 'Debit'
                                                            && NumberFormat(doc.policyNet)
                                                        }
                                                        {
                                                            doc.transactionType === 'Credit'
                                                            && "(" + NumberFormat(doc.policyNet) + ")"
                                                        }
                                                        {
                                                            doc.transactionType === 'Totals'
                                                            && doc.policyNet
                                                        }
                                                    </p></td>
                                                    <td><p>
                                                        {
                                                            doc.transactionType === 'Receipt'
                                                            && "(" + NumberFormat(doc.policyNet) + ")"
                                                        }
                                                        {
                                                            doc.transactionType === 'Totals'
                                                            && doc.receiptNet
                                                        }
                                                    </p></td>
                                                    <td><p>
                                                        {
                                                            doc.transactionType !== 'Totals'
                                                            && NumberFormat(doc.cummulativeOutstanding)
                                                        }
                                                    </p></td>
                                                    <td><p>
                                                        {
                                                            doc.policyDocuments.map((docLink, index) => {
                                                                return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                                                            })
                                                        }
                                                    </p></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>


                        </td>
                    </tr>

                    {/* spacer */}
                    <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>
                            <div className='h_15'></div>
                        </td>
                    </tr>

                    {/* ageing */}
                    <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>

                            <table className={styles.stmt_head_ageing_table}>
                                <thead>
                                    <tr>
                                        <td className={`bold ${styles.stmt_section_header}`} colSpan='7'>AGEING SUMMARY</td>
                                    </tr>
                                    <tr>
                                        <td><p className="bold">Credit</p></td>
                                        <td><p className="bold">0 - 30 Days</p></td>
                                        <td><p className="bold">31 - 60 Days</p></td>
                                        <td><p className="bold">61 - 90 Days</p></td>
                                        <td><p className="bold">91 - 120 Days</p></td>
                                        <td><p className="bold">Above 120 Days</p></td>
                                        <td><p className="bold">Outstanding</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.credit
                                                && finalData.ageingObj.credit
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.thirtyDays
                                                && finalData.ageingObj.thirtyDays
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.sixtyDays
                                                && finalData.ageingObj.sixtyDays
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.ninetyDays
                                                && finalData.ageingObj.ninetyDays
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.oneTwentyDays
                                                && finalData.ageingObj.oneTwentyDays
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.aboveOneTwentyDays
                                                && finalData.ageingObj.aboveOneTwentyDays
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                finalData.ageingObj
                                                && Object.keys(finalData.ageingObj).length > 0
                                                && finalData.ageingObj.total
                                                && finalData.ageingObj.total
                                            }
                                        </p></td>
                                    </tr>
                                </tbody>
                            </table>


                        </td>
                    </tr>

                    {/* spacer */}
                    {
                        Array.isArray(finalData.fleetVehicles)
                        && finalData.fleetVehicles.length > 0
                        &&
                        <tr>
                            <td className={styles.email_client_statement_printable_main_table_td}>
                                <div className='h_15'></div>
                            </td>
                        </tr>
                    }


                    {/* fleet */}

                    {
                        Array.isArray(finalData.fleetVehicles)
                        && finalData.fleetVehicles.length > 0
                        &&
                        <tr>
                            <td className={styles.email_client_statement_printable_main_table_td}>
                                <table className={styles.stmt_head_fleet_vehicles_table}>

                                    <thead>
                                        <tr>
                                            <td className={`bold ${styles.stmt_section_header}`} colSpan={4}>VEHICLE FLEET DETAILS</td>
                                        </tr>
                                        <tr>
                                            <td><p className="bold">Date</p></td>
                                            <td><p className="bold">Policy No</p></td>
                                            <td><p className="bold">Endorsment No</p></td>
                                            <td><p className="bold">Details</p></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(finalData.fleetVehicles)
                                            && finalData.fleetVehicles.map((doc, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><p>{doc.date}</p></td>
                                                        <td><p>{doc.policyNumber}</p></td>
                                                        <td><p>{doc.endorsmentNumber}</p></td>
                                                        <td><p>
                                                            {
                                                                Array.isArray(doc.details)
                                                                && doc.details.map((doc, index) => {
                                                                    return (
                                                                        <span key={index}>{doc + ', '}</span>
                                                                    )
                                                                })
                                                            }
                                                        </p></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    }

                    {/* spacer */}
                    <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>
                            <div className='h_15'></div>
                        </td>
                    </tr>

                    {/* additional */}
                    {/* <tr>
                        <td className={styles.email_client_statement_printable_main_table_td}>

                            <table className={styles.stmt_head_additional_table}>
                                <thead>
                                    <tr>
                                        <td className={`bold ${styles.stmt_section_header}`}>ADDITIONAL</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="h_5"></div>
                                            <p className="bold text-center">THIS IS AN AUTHENTICATED STATEMENT. CUSTOMERS ARE REQUESTED TO NOTIFY US OF ANY DISCREPANCY IN THE STATEMENT WITHIN 14 DAYS FROM THE DATE OF DISPATCH, OTHERWISE THE ACCOUNT STATEMENT WILL BE PRESUMED TO BE IN ORDER.</p>
                                            <div className="h_5"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </td>
                    </tr> */}
                </tbody>
                {/* <tfoot><tr><td>
                    <div className={styles.footer_space}>&nbsp;</div>
                </td></tr></tfoot> */}
            </table>

            {/* <div className={styles.footer}>
                <table className={styles.email_client_statement_printable_footer}>
                    <thead>
                        <tr>
                            <td className={styles.email_client_statement_printable_main_table_td}>
                                <div className={styles.email_client_statement_printable_footer_category}>
                                    <div>
                                        <img className={styles.MPESALogo} src={MPESALogo} />
                                    </div>
                                    <div>
                                        <p className="bold">PAYBILL NO:</p>
                                        <p className="bold">4121345</p>
                                        <p className="bold">ACCOUNT NO:</p>
                                        <p className="bold">PLATE NO / NAME</p>
                                    </div>
                                </div>
                            </td>
                            <td className={styles.email_client_statement_printable_main_table_td}>
                                <div className={styles.email_client_statement_printable_footer_category}>
                                    <div>
                                        <img className={styles.CIBLogo} src={CIBLogo} />
                                    </div>
                                    <div>
                                        <p className="bold">CHEQUE PAYMENT TO: </p>
                                        <p className="bold">SIMIA INSURANCE AGENCY <br></br> LIMITED</p>
                                    </div>
                                </div>
                            </td>
                            <td className={styles.email_client_statement_printable_main_table_td}>
                                <div className={styles.email_client_statement_printable_footer_category}>
                                    <div>
                                        <img className={styles.GUARDIANLogo} src={GUARDIANLogo} />
                                    </div>
                                    <div>
                                        <p className="bold">CHEQUE PAYMENT TO:</p>
                                        <p className="bold">SIMIA INSURANCE AGENCY <br></br> LIMITED</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>

            </div> */}

        </div>
    </section>

    return (content)
}

export default EmailClientStatementViewable;