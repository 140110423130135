import React, { useState, useEffect } from "react";
import db from "../../../firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { useRecoilState } from 'recoil';
import { rightNavContent, selectInsurerViewDV} from '../../../atoms';
import Loader from "../Loader/Loader";
import DatePicker from "react-date-picker";
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";

const ViewDV = (props) => {
    //################################################################
    //recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [selectInsurerViewDVRN, setSelectInsurerViewDVRN] = useRecoilState(selectInsurerViewDV);

    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get user input
    const [DVNumber, setDVNumber] = useState('')
    const [DVDate, setDVDate] = useState(new Date())
    const [narration, setNarration] = useState('')
    const [amount, setAmount] = useState(0)

    //################################################################
    //insurer debit date
    const [insurerDebitDate, setInsurerDebitDate] = useState('');

    useEffect(() => {
        const Dyear = new Date(DVDate).getFullYear();
        const Dmonth = new Date(DVDate).getMonth() + 1;
        const Ddate = new Date(DVDate).getDate();
        const DexpiryDateCleanedJan = Dyear + '-' + Dmonth + '-' + Ddate;
        setInsurerDebitDate(DexpiryDateCleanedJan)
    }, [DVDate])

    //################################################################
    //get doc
    useEffect(() => {

        (async () => {
            const docRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                //console.log("Document data:", docSnap.data());
                setDVNumber(docSnap.data().policyNumber)
                setDVDate(new Date(docSnap.data().insurerDebitDate))
                setNarration(docSnap.data().locationOfTheRisk)
                setAmount(docSnap.data().insurerPolicyNet)
                setSelectInsurerViewDVRN(docSnap.data().insurerName)

            } else {
                //doc.data() will be undefined in this case
                //console.log("No such document!");
            }
        })()

    }, [props.onePropUp.postedDocRef])

    //################################################################
    //step 3
    //show msg
    const displayDVSuccessUpdateMSG = () => {
        const onePropObj = {
            setView: 'View DV',
            previousView: 'View DV',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'DV Updated successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //post data
    async function editDV(DVObj) {
        try {
            setIsLoading(true);
            const docRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);

            await updateDoc(docRef, DVObj);
            setIsLoading(false);
            displayDVSuccessUpdateMSG()
        } catch (error) {

        }
    }

    //################################################################
    //step 1
    //create obj for posting
    const editDVPostingObj = () => {
        //create main object for posting
        const DVProcessedData = {
            insurerName: selectInsurerViewDVRN,
            policyNumber: DVNumber,
            inceptionDate: insurerDebitDate,
            expiryDate: insurerDebitDate,
            insurerDebitDate: insurerDebitDate,
            newInceptionDate: DVDate,
            newExpiryDate: DVDate,
            newInsurerDebitDate: DVDate,
            locationOfTheRisk: narration,
            policyNet: amount,
            insurerPolicyNet: amount,
        }

        editDV(DVProcessedData)
    }

    //################################################################
    //step 2
    //change view after success delete
    const displayDVSuccessDeleteMSG = () => {
        const onePropObj = {
            setView: 'DV List',
            previousView: 'View DV',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'DV Record Deleted successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 1
    //delete dv
    async function deleteDV() {
        const executeAbort = ConfirmAction('Confirm You Want To Delete This DV Record?');
        if (!executeAbort) { return false }

        try {
            setIsLoading(true);
            await deleteDoc(doc(db, "PolicyDetails", props.onePropUp.postedDocRef));
            setIsLoading(false);
            displayDVSuccessDeleteMSG()
        } catch (error) {

        }

    }

    //################################################################
    //insurer content
    const insurerContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Insurer Details</p>
                <div className='client_details_controls'>
                    <div className='btn_yellow'>
                        <i className="ri-user-add-line"></i>
                    </div>
                    <div className='w_5'></div>
                    <div className='btn_pink' onClick={() => { setRightNavContentRN('Select Insurer') }}>
                        <i className="ri-search-2-line"></i>
                    </div>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <input value={selectInsurerViewDVRN} type='text' placeholder='Insurer' readOnly />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //insurer constent
    const DVDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>DV Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={DVNumber || ''} onChange={(e) => { setDVNumber(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>DV Number</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Amount</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Insurer Debit Date</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setDVDate} value={DVDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <textarea value={narration || ''} onChange={(e) => { setNarration(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Narration</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //#########################################################################
    //policy controls
    const [hideShowControls, setHideShowControls] = useState('control_list')
    const hideShowControlsClickHandler = (instruction) => {
        setHideShowControls(instruction)
    }

    return (
        <section className="view_DV create_policy_section">
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>View DV</p>
                    <div className='view_policy_controls'>
                        <span onMouseOver={() => hideShowControlsClickHandler('newlist shadow')} className='btn_pink toogle'>Controls</span>
                        <div className={hideShowControls} onMouseLeave={() => hideShowControlsClickHandler('control_list')}>
                            <div onClick={editDVPostingObj} className="controls_items">
                                <p>Save Changes</p>
                            </div>
                            <div onClick={deleteDV} className="controls_items">
                                <p>Delete DV Record</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    {insurerContent}
                    {DVDetailsContent}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default ViewDV;