import React, { useState, useEffect } from "react";
import GetAllInsurerDetails from "../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import Loader from "../../Body/Loader/Loader";
import { useRecoilState } from "recoil";
import { renewedReportFilterFromDate, renewedReportFilterToDate, renewedReportFilterInsurer, renewedReportFilterPolicyClass } from "../../../atoms";
import DatePicker from "react-date-picker";

const RenewedReportGridFilter = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //valuation reports filter
    const [renewedReportFilterFromDateRN, setRenewedReportFilterFromDateRN] = useRecoilState(renewedReportFilterFromDate);
    const [renewedReportFilterToDateRN, setRenewedReportFilterToDateRN] = useRecoilState(renewedReportFilterToDate)
    const [renewedReportFilterInsurerRN, setRenewedReportFilterInsurerRN] = useRecoilState(renewedReportFilterInsurer)
    const [renewedReportFilterPolicyClassRN, setRenewedReportFilterPolicyClassRN] = useRecoilState(renewedReportFilterPolicyClass)

    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);


    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setRenewedReportFilterInsurerRN(e.target.value) }} value={renewedReportFilterInsurerRN}>
                    <option>Select Insurer Filter</option>
                    {
                        InsurerDetails.map((doc, index) => {
                            return (
                                <option key={index}>{doc.docData.insurer}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Insurer Account</p></label>
            </div>
            <div className='h_15'></div>
            <div className='input_group'>
                <select className='select' value={renewedReportFilterPolicyClassRN} onChange={(e) => { setRenewedReportFilterPolicyClassRN(e.target.value) }}>
                    <option>Select Class Filter</option>
                    <option>ALL</option>
                    <option>MOTOR</option>
                    <option>NON-MOTOR</option>
                </select>
                <label className='placeholder'><p>Policy Class Filter</p></label>
            </div>
            <div className='h_15'></div>
            <p className='bold'>Filter Date</p>
            <div className='h_5'></div>
            <div className='lapse_date_picker_container'>
                <p>From</p>
                <div className='h_5'></div>
                <DatePicker onChange={setRenewedReportFilterFromDateRN} value={renewedReportFilterFromDateRN} format='d/ M/y' />
            </div>
            <div className='h_10'></div>
            <div className='lapse_date_picker_container'>
                <p>To</p>
                <div className='h_5'></div>
                <DatePicker onChange={setRenewedReportFilterToDateRN} value={renewedReportFilterToDateRN} format='d/ M/y' />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
            {loaderContent}
        </section>
    )
}

export default RenewedReportGridFilter;