import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { doc, updateDoc, arrayUnion, runTransaction } from "firebase/firestore";
import Loader from '../Loader/Loader';
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import DatePicker from 'react-date-picker';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import UUID from '../../Hooks/UUID/UUID';
import './RegisterAClaim.css';

const RegisterAClaim = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDebit();
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //remove endor
    const [removedEndorsments, setRemovedEndorsments] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        debitDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled') {
                removedEndorsmentsList.push(item);
            }
        });
        setRemovedEndorsments(removedEndorsmentsList)
    }, [debitDocs]);

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = removedEndorsments.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [removedEndorsments]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.endorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //selected policy
    const [selectedPolicy, setSelectedPolicy] = useState([]);
    const [selectedPolicyObj, setSelectedPolicyObj] = useState([]);
    const selectPolicyHandler = (selectedPolicy) => {
        setSelectedPolicy([selectedPolicy]);
        setSelectedPolicyObj(selectedPolicy);
        setSearchTerm('');
    }

    //################################################################
    // echo search
    const claimPolycyDetailsContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Search Policy</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className="search_wrapper">
                        <div className='input_group_search'>
                            <input onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} placeholder='' type="text" className='input' name="search" id="search" autoComplete="off" />
                            <label className='placeholder'><p>Search Client Name / Policy Number</p></label>
                        </div>
                        <span className='search_icon'><i className="ri-search-2-line"></i></span>
                        <div className={`${searchTerm.length < 3 ? 'results' : 'show'}`}>
                            <div className='h_10'></div>
                            <table className='select_claim_policy_table' cellSpacing="0">
                                <thead>
                                    <tr>
                                        <td><p>No</p></td>
                                        <td><p>Name</p></td>
                                        <td><p>Policy Number</p></td>
                                        <td><p>Endorsment Number</p></td>
                                        <td><p>Policy Class</p></td>
                                        <td><p>Inception Date</p></td>
                                        <td><p>Expiry Date</p></td>
                                        <td><p>Details</p></td>
                                        <td><p>Doc</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchedPolicyDocs.map((item, index) => {
                                            const clientVehicleItems = [];
                                            Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                            return (
                                                <tr key={index} onClick={() => { selectPolicyHandler(item) }}>
                                                    <td><p>{item.id}</p></td>
                                                    <td><p>{item.docData.clientName}</p></td>
                                                    <td><p>{item.docData.policyNumber}</p></td>
                                                    <td><p>{item.docData.endorsmentNumber}</p></td>
                                                    <td><p>{item.docData.selectedProductSubClass}</p></td>
                                                    <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                                    <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                                    <td><p>
                                                        {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                        {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                                    </p></td>
                                                    {
                                                        item.docData.associateDocuments
                                                            ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                            : <td></td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // policy Details Content
    const selectedPolicyDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Policy Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='h_10'></div>
                    <table className='select_claim_policy_table' cellSpacing="0">
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Outstanding</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(selectedPolicy).map((item, index) => {
                                    const clientVehicleItems = [];
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                    return (
                                        <tr key={index}>
                                            <td><p>{item.id}</p></td>
                                            <td><p>{item.docData.clientName}</p></td>
                                            <td><p>{item.docData.policyNumber}</p></td>
                                            <td><p>{item.docData.endorsmentNumber}</p></td>
                                            <td><p>{item.docData.selectedProductSubClass}</p></td>
                                            <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                            <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                            <td><p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p></td>
                                            {
                                                item.docData.associateDocuments
                                                    ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                    : <td></td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //################################################################
    // get user Input for motor
    const [dateOfLoss, dateOfLossChangeHandler] = useState(new Date());
    const [natureOfLoss, setNatureOfLoss] = useState('');
    const [vehicleDetails, setVehicleDetails] = useState({})
    const [estimatedAmountOfLoss, setEstimatedAmountOfLoss] = useState('');
    const [locationOfTheVehicle, setLocationOfTheVehicle] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [reserveAmount, setReserveAmount] = useState('');
    const [title, setTitle] = useState('');

    //################################################################
    // change view
    const changeViewOnSuccessfulClaimCreation = () => {
        const onePropObj = {
            setView: 'Open Claim',
            previousView: 'Register A Claim',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Claim Created Successfully'
        }
        props.onePropDown(onePropObj)
    }
    
    //################################################################
    // post data to db
    const submitClaimHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        //get unique id
        const claimID = UUID();

        //claim obj
        const claimProcessedData = {
            policyDocID: selectedPolicyObj.docID,
            clientName: selectedPolicyObj.docData.clientName,
            insurerName: selectedPolicyObj.docData.insurerName,
            insurerClaimNumber: '',
            policyNumber: selectedPolicyObj.docData.policyNumber,
            endorsmentNumber: selectedPolicyObj.docData.endorsmentNumber,
            bankInterest: selectedPolicyObj.docData.bankInterest,
            selectedProductClass: selectedPolicyObj.docData.selectedProductClass,
            selectedProductSubClass: selectedPolicyObj.docData.selectedProductSubClass,
            inceptionDate: selectedPolicyObj.docData.inceptionDate,
            expiryDate: selectedPolicyObj.docData.expiryDate,
            insurerDebitDate: selectedPolicyObj.docData.insurerDebitDate,
            locationOfTheRisk: selectedPolicyObj.docData.locationOfTheRisk,
            totalSumInsured: selectedPolicyObj.docData.totalSumInsured,
            dateOfLoss: dateOfLoss,
            natureOfLoss: natureOfLoss,
            vehicleDetails: vehicleDetails,
            estimatedAmountOfLoss: estimatedAmountOfLoss,
            locationOfTheVehicle: locationOfTheVehicle,
            contactPerson: contactPerson,
            reserveAmount: reserveAmount,
            assessor: '',
            assessmentDate: '',
            assessorFirm: '',
            assessorAppointmentDate: '',
            createdBy: currentUser,
            dateCreated: new Date(),
            status: { narration: '' },
            stage: 'Open',
            paidAmount: '',
            title: title,
            claimDocuments: {},
        }

        //post data
        const sfDocRef = doc(db, "Claims", "lqj4Qnx0jn6CJfHQdiff");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //get unique id
                const claimID = UUID();

                transaction.update(sfDocRef, { [claimID]: claimProcessedData });
            });
            setIsLoading(false);
            changeViewOnSuccessfulClaimCreation()
            //console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    // policy Details Content
    const motorClaimDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Claim Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <select className='select' onChange={(e) => { setNatureOfLoss(e.target.value) }}>
                            <option>Select Option</option>
                            <option>Own Damage</option>
                            <option>Third Party</option>
                            <option>Windscreen</option>
                        </select>
                        <label className='placeholder'><p>Nature Of Loss</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <select className='select' onChange={(e) => { setVehicleDetails(JSON.parse(e.target.value)) }}>
                            <option>Select Vehicle</option>
                            {
                                Object.keys(selectedPolicyObj).length > 0
                                    ? Object.values(selectedPolicyObj.docData.vehicleItems).map((item, index) => {
                                        return (
                                            <option value={JSON.stringify(item)} key={index}>{item.regNo}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                        <label className='placeholder'><p>Vehicle Plate No</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setEstimatedAmountOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Estimated Amount Of Loss</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Loss</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={dateOfLossChangeHandler} value={dateOfLoss} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setLocationOfTheVehicle(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Location Of The Vehicle</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setLocationOfTheVehicle(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Contact Person</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // policy Details Content
    const nonMotorClaimDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Claim Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setEstimatedAmountOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Estimated Amount Of Loss</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setNatureOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Nature Of Loss</p></label>
                    </div>
                    {
                        Object.keys(selectedPolicyObj).length > 0
                            ? selectedPolicyObj.docData.selectedProductSubClass === '(112) - Wiba' || selectedPolicyObj.docData.selectedProductSubClass === '(114) - Wiba Plus'
                                ?
                                <div>
                                    <div className='h_15'></div>
                                    <div className='input_group'>
                                        <input onChange={(e) => { setTitle(e.target.value) }} type='text' className='input' placeholder='' />
                                        <label className='placeholder'><p>Title</p></label>
                                    </div>
                                </div>
                                : ''
                            : ''
                    }
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Loss</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={dateOfLossChangeHandler} value={dateOfLoss} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setContactPerson(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Contact Person</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setReserveAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Reserve Amount</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //#######################################################################
    //#######################################################################
    //#######################################################################

    return (
        <section className='register_a_claim body_input_styles'>
            <div className='body_section_header'>
                <p>Register A Claim</p>
                <button onClick={submitClaimHandler} className='btn_pink'>Save Details</button>
            </div>
            <div className='body_section'>
                {claimPolycyDetailsContent}
                {
                    selectedPolicy.length > 0
                        ? selectedPolicyDetailsContent
                        : ''
                }
                {
                    Object.keys(selectedPolicyObj).length > 0
                        ? selectedPolicyObj.docData.selectedProductClass === '(07) - Motor Private' || selectedPolicyObj.docData.selectedProductClass === '(08) - Motor Commercial'
                            ? motorClaimDetailsContent
                            : ''
                        : ''
                }
                {
                    Object.keys(selectedPolicyObj).length > 0
                        ? selectedPolicyObj.docData.selectedProductClass != '(07) - Motor Private' && selectedPolicyObj.docData.selectedProductClass != '(08) - Motor Commercial'
                            ? nonMotorClaimDetailsContent
                            : ''
                        : ''
                }
            </div>
            {loaderContent}
        </section>
    );
}

export default RegisterAClaim;