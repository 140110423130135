import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import { useReactToPrint } from "react-to-print";
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import logo from '../../Assets/img/logo.png';
import '../BodyPolicy/BodyPolicyPortfolio.css';
import Paginate from '../BodyPolicy/Paginate';

const BodyPolicyPortfolio = (props) => {
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //input
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2022-07-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])

    //to
    const [toDate, setToDate] = useState(new Date());

    const [tvalue, onTChange] = useState(new Date('2022-07-31'));
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])


    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    // search section
    const filterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>


    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);
    const [noOfRecords, setNoOfRecords] = useState(0)

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    // assign date
                    if (doc.data().transactionType === 'Debit' && !doc.data().endorsmentNumber.includes('05/')) {
                        const noOfRecordsPulled = querySnapshot.size.toString();
                        setNoOfRecords(noOfRecordsPulled)
                        const clientVehicleItems = [];
                        const clientVehicleItemsObj = Object.values(doc.data().vehicleItems);
                        if (clientVehicleItemsObj.hasOwnProperty('regNo')) {
                            Object.values(doc.data().vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo.toLowerCase()) });
                        }
                        //echo date
                        const calInceptionDate = doc.data().inceptionDate;
                        const dayInc = new Date(calInceptionDate).toLocaleString('en-us', { day: '2-digit' });
                        const monthInc = new Date(calInceptionDate).toLocaleString('en-us', { month: 'short' });
                        const yearInc = new Date(calInceptionDate).getFullYear();
                        const echoInceptionDate = dayInc + '-' + monthInc + '-' + yearInc;
                        const calExpiryDate = doc.data().expiryDate;
                        const dayExp = new Date(calExpiryDate).toLocaleString('en-us', { day: '2-digit' });
                        const monthExp = new Date(calExpiryDate).toLocaleString('en-us', { month: 'short' });
                        const yearExp = new Date(calExpiryDate).getFullYear();
                        const echoExpiryDate = dayExp + '-' + monthExp + '-' + yearExp;
                        const allPolicyDocs = {
                            policyID: doc.id,
                            policyClientName: doc.data().clientName,
                            policyPolicyNumber: doc.data().policyNumber,
                            policyEndorsmentNumber: doc.data().endorsmentNumber,
                            policySelectedProductSubClass: doc.data().selectedProductSubClass,
                            policyInceptionDate: doc.data().inceptionDate,
                            policyPolicyNet: doc.data().policyNet,
                            policyOutstanding: doc.data().outstanding,
                            policyTransactionType: doc.data().transactionType,
                            policyInceptionDate: doc.data().inceptionDate,
                            policyExpiryDate: doc.data().expiryDate,
                            policyLocationOfTheRisk: doc.data().locationOfTheRisk,
                            policyVehicleItems: doc.data().vehicleItems,
                            policyClientVehicleItems: clientVehicleItems,

                            clientName: doc.data().clientName,
                            insurerName: doc.data().insurerName,
                            bankInterest: doc.data().bankInterest,
                            policyNumber: doc.data().policyNumber,
                            selectedProductClass: doc.data().selectedProductClass,
                            selectedProductSubClass: doc.data().selectedProductSubClass,
                            commissionRate: doc.data().commissionRate,
                            businessType: doc.data().businessType,
                            endorsmentNumber: doc.data().endorsmentNumber,
                            inceptionDate: doc.data().inceptionDate,
                            expiryDate: doc.data().expiryDate,
                            insurerDebitDate: doc.data().insurerDebitDate,
                            locationOfTheRisk: doc.data().locationOfTheRisk,
                            totalSumInsured: doc.data().totalSumInsured,
                            premium: doc.data().premium,
                            PVTPremium: doc.data().PVTPremium,
                            stampDuty: doc.data().stampDuty,
                            TLevy: doc.data().TLevy,
                            PHCFund: doc.data().PHCFund,
                            commission: doc.data().commission,
                            commissionPayable: doc.data().commissionPayable,
                            expectedCommissionPayable: doc.data().commissionPayable,
                            outstandingCommission: doc.data().commission,
                            withholdingTax: doc.data().withholdingTax,
                            policyNet: doc.data().policyNet,
                            earthquake: doc.data().earthquake,
                            excessProtector: doc.data().excessProtector,
                            comesaCard: doc.data().comesaCard,
                            windScreen: doc.data().windScreen,
                            radioCasset: doc.data().radioCasset,
                            insurerPolicyNet: doc.data().insurerPolicyNet,
                            receiptAllocationBreakDown: doc.data().receiptAllocationBreakDown,
                            commissionAllocationBreakDown: doc.data().commissionAllocationBreakDown,
                            status: doc.data().status,
                            outstanding: doc.data().outstanding,
                            transactionType: doc.data().transactionType,
                            employeesDetailsItems: doc.data().employeesDetailsItems,
                            propertyDescriptionItems: doc.data().propertyDescriptionItems,
                            benefitsItems: doc.data().benefitsItems,
                            deductibleItems: doc.data().deductibleItems,
                            vehicleItems: doc.data().vehicleItems,
                            clauseItems: doc.data().clauseItems,
                            dateCreated: doc.data().dateCreated,
                            createdBy: doc.data().createdBy,
                            policyDocID: doc.data().policyDocID,
                            policyDocName: doc.data().policyDocName,
                            policyDocLocation: doc.data().policyDocLocation,
                            echoInceptionDate: echoInceptionDate,
                            echoExpiryDate: echoExpiryDate,
                        }
                        Rdata.push(allPolicyDocs);
                        setIsloading(false);
                    }
                },
                    setReceivedPolicyDocs(Rdata)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = receivedPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(b.expiryDate).valueOf() - new Date(a.expiryDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [receivedPolicyDocs]);

    //filter date
    const [dateFilterPolicyDocs, setDateFilterPolicyDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        sortedPolicyDocs.map((item) => {
            if (new Date(item.expiryDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.expiryDate).valueOf()) {
                periodSelected.push(item);
            }
        })
        setDateFilterPolicyDocs(periodSelected)
    }, [sortedPolicyDocs, fromDate, toDate])

    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = dateFilterPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.policyClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.policyPolicyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.policyClientVehicleItems.includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedPolicyDocs(c);
    }, [dateFilterPolicyDocs, searchTerm])

    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost)

    // change view on success post
    const viewCreatedPolicyOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'Renew Policy', postedDocRef: postedDocRef });
    }

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm])

    const allPolicyContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number / Vehicle Plate No.' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='all_policies_table' ref={componentRef}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.policyTransactionType !== 'Receipt') {
                                    Object.values(item.policyVehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index} onClick={() => viewCreatedPolicyOnSuccessPosting(item)}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.policyClientName}</p></td>
                                        <td><p>{item.policyTransactionType}</p></td>
                                        <td><p>{item.policyPolicyNumber}{item.receiptNumber}</p></td>
                                        <td><p>{item.policyEndorsmentNumber}</p></td>
                                        <td><p>{item.echoInceptionDate}</p></td>
                                        <td><p>{item.echoExpiryDate}</p></td>
                                        <td>
                                            <p>
                                                {item.totalSumInsured}{clientVehicleItems}
                                            </p>
                                        </td>
                                        <td><p>{item.policyPolicyNet}</p></td>
                                        <td><p>{item.policyOutstanding}</p></td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    const printableRenewalReport = <div style={{ display: "none" }}>
        <table className='all_policies_table' ref={componentRef}>
            <thead>
                <tr><td colSpan='10'><center><p className='bold'>RENEWAL REPORT</p></center></td></tr>
                <tr>
                    <td><p>No</p></td>
                    <td><p>Name</p></td>
                    <td><p>Voucher Type</p></td>
                    <td><p>Policy Number</p></td>
                    <td><p>Endorsment Number</p></td>
                    <td><p>Inception Date</p></td>
                    <td><p>Expiry Date</p></td>
                    <td><p>Details</p></td>
                    <td><p>Policy Net</p></td>
                    <td><p>Outstanding</p></td>
                </tr>
            </thead>
            <tbody>
                {searchedPolicyDocs.map((item, index) => {
                    const clientVehicleItems = []
                    if (item.policyTransactionType !== 'Receipt') {
                        Object.values(item.policyVehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                    }
                    return (
                        <tr key={index} onClick={() => viewCreatedPolicyOnSuccessPosting(item)}>
                            <td><p>{item.id}</p></td>
                            <td><p>{item.policyClientName}</p></td>
                            <td><p>{item.policyTransactionType}</p></td>
                            <td><p>{item.policyPolicyNumber}{item.receiptNumber}</p></td>
                            <td><p>{item.policyEndorsmentNumber}</p></td>
                            <td><p>{item.echoInceptionDate}</p></td>
                            <td><p>{item.echoExpiryDate}</p></td>
                            <td>
                                <p>
                                    {item.totalSumInsured}{clientVehicleItems}
                                </p>
                            </td>
                            <td><p>{item.policyPolicyNet}</p></td>
                            <td><p>{item.policyOutstanding}</p></td>
                        </tr>
                    )
                })
                }
            </tbody>
        </table>
    </div>

    return (
        <section className='policy_portfolio'>
            <div className='body_section_header'>
                <p>Renewal Report</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={() => showHideFilterRightNav('Show')}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {allPolicyContent}
            </div>
            {filterContent}
            {loaderContent}
            {printableRenewalReport}
        </section>
    )
}

export default BodyPolicyPortfolio;