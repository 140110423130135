import React, { useState } from "react";
import Loader from "../../Body/Loader/Loader";
import axios from "axios";
import { useRecoilState } from "recoil";
import { reloadOnSuccessVendorCreationHandler } from "../../../atoms";

const CreateVendor = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [reloadOnSuccessVendorCreationHandlerRN, setReloadOnSuccessVendorCreationHandlerRN] = useRecoilState(reloadOnSuccessVendorCreationHandler);

    //################################################################
    //get user input
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')

    //################################################################
    //post data
    //step 2
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);
        setReloadOnSuccessVendorCreationHandlerRN(true);

        setName('');
        setPhoneNumber('');
        setEmail('');

        const onePropObj = {
            setView: 'Vendor Profile',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Vendor Created Successfully.'
        }
        props.onePropDown(onePropObj);
    }

    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Vendor Profile',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error While Creating Vendor, Please Try Again.'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const createHandler = () => {
        setIsLoading(true);

        const vendorObj = {
            name: name.trim().toLocaleUpperCase(),
            phoneNumber: phoneNumber.trim(),
            email: email.trim(),
        }

        const CreateVendorAPI = async () => {

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createvendor', vendorObj)
                .then(response => {
                    // Handle the response data
                    //console.log(response.data);
                    showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    //console.error(error);
                    showErrorMSGOnExpenseSave();
                });
        }
        CreateVendorAPI()
    }

    //################################################################
    //content 
    const createVendorContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Create Vendor</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={name || ''} onChange={(e) => { setName(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Name</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={email || ''} onChange={(e) => { setEmail(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Email</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={phoneNumber || ''} onChange={(e) => { setPhoneNumber(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Phone No</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {createVendorContent}
            {loaderContent}
        </section>
    );
}

export default CreateVendor;