import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {  policyDocumentsReportFilterFromDate, policyDocumentsReportFilterToDate, policyDocumentsFilterType } from "../../../atoms";
import DatePicker from "react-date-picker";

const PolicyDocumentsReportFilter = () => {
    //################################################################
    //valuation reports filter
    const [policyDocumentsReportFilterFromDateRN, setPolicyDocumentsReportFilterFromDateRN] = useRecoilState(policyDocumentsReportFilterFromDate)
    const [policyDocumentsReportFilterToDateRN, setPolicyDocumentsReportFilterToDateRN] = useRecoilState(policyDocumentsReportFilterToDate)
    const [policyDocumentsFilterTypeRN, setPolicyDocumentsFilterTypeRN] = useRecoilState(policyDocumentsFilterType)

    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_15'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setPolicyDocumentsFilterTypeRN(e.target.value) }} value={policyDocumentsFilterTypeRN}>
                    <option>Select Filter</option>
                    <option>All</option>
                    <option>Policy Documents Present</option>
                    <option>Policy Documents Missing</option>
                </select>
                <label className='placeholder'><p>Report Filter</p></label>
            </div>
            <div className='h_10'></div>
            <p className='bold'>Filter Date</p>
            <div className='h_5'></div>
            <div className='lapse_date_picker_container'>
                <p>From</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyDocumentsReportFilterFromDateRN} value={policyDocumentsReportFilterFromDateRN} format='d/ M/y' />
            </div>
            <div className='h_10'></div>
            <div className='lapse_date_picker_container'>
                <p>To</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyDocumentsReportFilterToDateRN} value={policyDocumentsReportFilterToDateRN} format='d/ M/y' />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
        </section>
    )
}

export default PolicyDocumentsReportFilter;