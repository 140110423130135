import React, { useEffect, useState, useCallback } from 'react';
import db from '../../../firebase';
import { collection, doc, getDoc, addDoc } from "firebase/firestore";
import './BodyCreatePolicy.css';
import Loader from '../Loader/Loader';

const BodyEndorsment = (props) => {
    const [postedDocRef, setPostedDocRef] = useState(props.postedDocRef);

    useEffect(()=>{})
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //get user input
    //bank interest
    const [bankInterest, setBankInterest] = useState('');
    const bankInterestChangeHandler = (event) => {
        setBankInterest(event.target.value)
    }

    //endorsment number
    const [endorsmentNumber, setEndorsmentNumber] = useState('');
    const endorsmentNumberChangeHandler = (event) => {
        setEndorsmentNumber(event.target.value)
    }

    //set vars after db pull
    const [clientName, setClientName] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [selectedProductClass, setSelectedProductClass] = useState('');
    const [selectedProductSubClass, setSelectedProductSubClass] = useState('');

    // set insurer name
    const [insurerName, setInsurerName] = useState('MAYFAIR INSURANCE COMPANY LIMITED');
    const insurerNameChangeHandler = (event) => {
        setInsurerName(event.target.value);
    }

    // set inception date
    const [inceptionDate, setInceptionDate] = useState('');
    const inceptionDateChangeHandler = (event) => {
        setInceptionDate(event.target.value);
    }

    // set expiry date
    const [expiryDate, setExpiryDate] = useState('');
    const expiryDateChangeHandler = (event) => {
        setExpiryDate(event.target.value);
    }

    useEffect(() => {
        if (inceptionDate !== '') {
            const year = new Date(inceptionDate).getFullYear() + 1;
            const month = new Date(inceptionDate).getMonth() + 1;
            const date = new Date(inceptionDate).getDate() - 1;
            if (date === 0) {
                switch (month) {
                    case 1:
                        const yearJan = new Date(inceptionDate).getFullYear();
                        const monthJan = 12;
                        const dateJan = 31;
                        const expiryDateCleanedJan = yearJan + '-' + monthJan + '-' + dateJan;
                        setExpiryDate(expiryDateCleanedJan);
                        break;
                    case 2:
                        const yearFeb = new Date(inceptionDate).getFullYear() + 1;
                        const monthFeb = 1;
                        const dateFeb = 31;
                        const expiryDateCleanedFeb = yearFeb + '-' + monthFeb + '-' + dateFeb;
                        setExpiryDate(expiryDateCleanedFeb);
                        break;
                    case 3:
                        const yearMar = new Date(inceptionDate).getFullYear() + 1;
                        const monthMar = 2;
                        const dateMar = 28;
                        const expiryDateCleanedMar = yearMar + '-' + monthMar + '-' + dateMar;
                        setExpiryDate(expiryDateCleanedMar);
                        break;
                    case 4:
                        const yearApr = new Date(inceptionDate).getFullYear() + 1;
                        const monthApr = 3;
                        const dateApr = 31;
                        const expiryDateCleanedApr = yearApr + '-' + monthApr + '-' + dateApr;
                        setExpiryDate(expiryDateCleanedApr);
                        break;
                    case 5:
                        const yearMay = new Date(inceptionDate).getFullYear() + 1;
                        const monthMay = 4;
                        const dateMay = 30;
                        const expiryDateCleanedMay = yearMay + '-' + monthMay + '-' + dateMay;
                        setExpiryDate(expiryDateCleanedMay);
                        break;
                    case 6:
                        const yearJun = new Date(inceptionDate).getFullYear() + 1;
                        const monthJun = 5;
                        const dateJun = 31;
                        const expiryDateCleanedJun = yearJun + '-' + monthJun + '-' + dateJun;
                        setExpiryDate(expiryDateCleanedJun);
                        break;
                    case 7:
                        const yearJul = new Date(inceptionDate).getFullYear() + 1;
                        const monthJul = 6;
                        const dateJul = 30;
                        const expiryDateCleanedJul = yearJul + '-' + monthJul + '-' + dateJul;
                        setExpiryDate(expiryDateCleanedJul);
                        break;
                    case 8:
                        const yearAug = new Date(inceptionDate).getFullYear() + 1;
                        const monthAug = 7;
                        const dateAug = 31;
                        const expiryDateCleanedAug = yearAug + '-' + monthAug + '-' + dateAug;
                        setExpiryDate(expiryDateCleanedAug);
                        break;
                    case 9:
                        const yearSep = new Date(inceptionDate).getFullYear() + 1;
                        const monthSep = 8;
                        const dateSep = 31;
                        const expiryDateCleanedSep = yearSep + '-' + monthSep + '-' + dateSep;
                        setExpiryDate(expiryDateCleanedSep);
                        break;
                    case 10:
                        const yearOct = new Date(inceptionDate).getFullYear() + 1;
                        const monthOct = 9;
                        const dateOct = 30;
                        const expiryDateCleanedOct = yearOct + '-' + monthOct + '-' + dateOct;
                        setExpiryDate(expiryDateCleanedOct);
                        break;
                    case 11:
                        const yearNov = new Date(inceptionDate).getFullYear() + 1;
                        const monthNov = 10;
                        const dateNov = 31;
                        const expiryDateCleanedNov = yearNov + '-' + monthNov + '-' + dateNov;
                        setExpiryDate(expiryDateCleanedNov);
                        break;
                    case 12:
                        const yearDec = new Date(inceptionDate).getFullYear() + 1;
                        const monthDec = 11;
                        const dateDec = 30;
                        const expiryDateCleanedDec = yearDec + '-' + monthDec + '-' + dateDec;
                        setExpiryDate(expiryDateCleanedDec);
                        break;
                }
            }
            else {
                const expiryDateCleaned = year + '-' + month + '-' + date;
                setExpiryDate(expiryDateCleaned);
            }


        }
    }, [inceptionDate])

    // set commission rate
    const [commissionRate, setCommissionRate] = useState(0);
    useEffect(() => {
        switch (selectedProductClass) {
            case '(02) - Engineering':
                setCommissionRate(0.2);
                break;
            case '(03) - Domestic':
                setCommissionRate(0.2);
                break;
            case '(04) - Fire Industrial':
                setCommissionRate(0.25);
                break;
            case '(05) - Public Liability':
                setCommissionRate(0.2);
                break;
            case '(06) - Marine':
                setCommissionRate(0.175);
                break;
            case '(07) - Motor Private':
                setCommissionRate(0.1);
                break;
            case '(08) - Motor Commercial':
                setCommissionRate(0.1);
                break;
            case '(09) - Personal Accident':
                setCommissionRate(0.2);
                break;
            case '(10) - Theft':
                setCommissionRate(0.2);
                break;
            case '(11) - Workmens Compensation':
                setCommissionRate(0.2);
                break;
            case '(12) - Miscellaneous':
                setCommissionRate(0.1);
                break;
        }
    }, [selectedProductClass]);

    useEffect(() => {
        switch (selectedProductSubClass) {
            case '(043) - Terrorism':
                setCommissionRate(0.1);
                break;
            case '(064) - CARRIERS LEGAL LIABILITY':
                setCommissionRate(0.2);
                break;
        }
    }, [selectedProductSubClass])

    //allocation
    const receiptAllocationBreakDown = [];
    const commissionAllocationBreakDown = [];

    // policy amounts
    const [premium, setPremium] = useState(0);
    const premiumChangeHandler = (event) => {
        setPremium(event.target.value);
    }

    const [PVTPremium, setPVTPremium] = useState(0);
    const PVTPremiumChangeHandler = (event) => {
        setPVTPremium(event.target.value);
    }

    const [stampDuty, setStampDuty] = useState(0);
    const stampDutyChangeHandler = (event) => {
        setStampDuty(event.target.value);
    }

    const [TLevy, setTLevy] = useState(0);
    const TLevyChangeHandler = (event) => {
        setTLevy(event.target.value);
    }

    const [PHCFund, setPHCFund] = useState(0);
    const PHCFundChangeHandler = (event) => {
        setPHCFund(event.target.value);
    }

    const [commission, setCommission] = useState(0);
    const commissionChangeHandler = (event) => {
        setCommission(event.target.value);
    }

    const [withholdingTax, setWithholdingTax] = useState(0);
    const withholdingTaxChangeHandler = (event) => {
        setWithholdingTax(event.target.value);
    }

    const [commissionPayable, setCommissionPayable] = useState(0);
    const [insurerPolicyNet, setinsurerPolicyNet] = useState(0);

    const [policyNet, setPolicyNet] = useState(0);
    const policyNetChangeHandler = (event) => {
        setPolicyNet(event.target.value);
    }

    //table 2
    const [earthquake, setEarthquake] = useState(0);
    const earthquakeChangeHandler = (event) => {
        setEarthquake(event.target.value);
    }

    const [excessProtector, setExcessProtector] = useState(0);
    const excessProtectorChangeHandler = (event) => {
        setExcessProtector(event.target.value);
    }

    const [comesaCard, setComesaCard] = useState(0);
    const comesaCardChangeHandler = (event) => {
        setComesaCard(event.target.value);
    }

    const [windScreen, setWindScreen] = useState(0);
    const windScreenChangeHandler = (event) => {
        setWindScreen(event.target.value);
    }

    const [radioCasset, setRadioCasset] = useState(0);
    const radioCassetChangeHandler = (event) => {
        setRadioCasset(event.target.value);
    }

    useEffect(() => {
        const calculatedTLevy = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * 0.002);
        setTLevy(calculatedTLevy);
        const calculatedPHCFund = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * 0.0025);
        setPHCFund(calculatedPHCFund);
        const calculatedCommission = Math.round((+premium + +PVTPremium + +excessProtector + +earthquake + +windScreen + +radioCasset) * commissionRate);
        setCommission(calculatedCommission);
    }, [premium, PVTPremium, excessProtector, earthquake, windScreen, radioCasset]);

    useEffect(() => {
        const calculatedWithholdingTax = Math.round(commission * 0.1);
        setWithholdingTax(calculatedWithholdingTax);
    }, [commission]);

    useEffect(() => {
        const calculatedPayableCommission = +commission - +withholdingTax;
        setCommissionPayable(calculatedPayableCommission);
    }, [commission, withholdingTax]);

    useEffect(() => {
        const calculatedPolicyNet = +premium + +PVTPremium + +stampDuty + +TLevy + +PHCFund + +earthquake + +excessProtector + +comesaCard + +windScreen + +radioCasset;
        setPolicyNet(calculatedPolicyNet);
    }, [premium, PVTPremium, stampDuty, TLevy, PHCFund, earthquake, excessProtector, comesaCard, windScreen, radioCasset]);

    useEffect(() => {
        const calculatedInsurerPolicyNet = +policyNet - +commissionPayable;
        setinsurerPolicyNet(calculatedInsurerPolicyNet);
    }, [commissionPayable, policyNet]);


    let policyAmountContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Policy Amounts</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='policy_amounts_table'>
                        <thead>
                            <tr>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>Earth Quake</p></td>
                                <td><p>Excess Protector</p></td>
                                <td><p>Comesa Card</p></td>
                                <td><p>Wind Screen</p></td>
                                <td><p>Radio Casset</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={premiumChangeHandler} value={premium} /></td>
                                <td><input onChange={PVTPremiumChangeHandler} value={PVTPremium} /></td>
                                <td><input onChange={earthquakeChangeHandler} value={earthquake} /></td>
                                <td><input onChange={excessProtectorChangeHandler} value={excessProtector} /></td>
                                <td><input onChange={comesaCardChangeHandler} value={comesaCard} /></td>
                                <td><input onChange={windScreenChangeHandler} value={windScreen} /></td>
                                <td><input onChange={radioCassetChangeHandler} value={radioCasset} /></td>

                            </tr>
                        </tbody>
                    </table>
                    <table className='policy_amounts_table_two'>
                        <thead>
                            <tr>
                                <td><p>S. Duty</p></td>
                                <td><p>T. Levy</p></td>
                                <td><p>P.H.C Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W. Tax</p></td>
                                <td><p>Policy Net</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input onChange={stampDutyChangeHandler} value={stampDuty} /></td>
                                <td><input onChange={TLevyChangeHandler} value={TLevy} /></td>
                                <td><input onChange={PHCFundChangeHandler} value={PHCFund} /></td>
                                <td><input onChange={commissionChangeHandler} value={commission} /></td>
                                <td><input onChange={withholdingTaxChangeHandler} value={withholdingTax} /></td>
                                <td><input onChange={policyNetChangeHandler} value={policyNet} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //fetch data
    const fetchPolicyDetails = useCallback(async () => {
        setIsloading(true);
        const docRef = doc(db, "PolicyDetails", postedDocRef);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            setClientName(docSnap.data().clientName);
            setInsurerName(docSnap.data().insurerName);
            setPolicyNumber(docSnap.data().policyNumber);
            setSelectedProductClass(docSnap.data().selectedProductClass);
            setSelectedProductSubClass(docSnap.data().selectedProductSubClass);

            setIsloading(false);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }, [])

    useEffect(() => {
        fetchPolicyDetails();
    }, []);

    //after success post
    const viewEndorsmentOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'View Policy', postedDocRef: postedDocRef });
    }

    //post data
    async function passEndorsmentFormSubmitHandler(event){
        event.preventDefault();
        setIsloading(true)

        //create main object for posting
        const policyEndorsmentData = {
            clientName: clientName,
            insurerName: insurerName,
            bankInterest: bankInterest,
            policyNumber: policyNumber,
            selectedProductClass: selectedProductClass,
            selectedProductSubClass: selectedProductSubClass,
            commissionRate: commissionRate,
            businessType: '',
            endorsmentNumber: endorsmentNumber,
            inceptionDate: inceptionDate,
            expiryDate: expiryDate,
            insurerDebitDate: inceptionDate,
            locationOfTheRisk: '',
            totalSumInsured: '',
            premium: premium,
            PVTPremium: PVTPremium,
            stampDuty: stampDuty,
            TLevy: TLevy,
            PHCFund: PHCFund,
            commission: commission,
            commissionPayable: commissionPayable,
            expectedCommissionPayable: commissionPayable,
            outstandingCommission: commission,
            withholdingTax: withholdingTax,
            policyNet: policyNet,
            earthquake: earthquake,
            excessProtector: excessProtector,
            comesaCard: comesaCard,
            windScreen: windScreen,
            radioCasset: radioCasset,
            insurerPolicyNet: insurerPolicyNet,
            receiptAllocationBreakDown: receiptAllocationBreakDown,
            commissionAllocationBreakDown: commissionAllocationBreakDown,
            status: '',
            outstanding: policyNet,
            transactionType: 'Debit',
            employeesDetailsItems: {},
            propertyDescriptionItems: {},
            benefitsItems: {},
            deductibleItems: {},
            vehicleItems: {},
            clauseItems: {},
            dateCreated: new Date(),
            createdBy: '',
            policyDocID: '',
            policyDocName: '',
            policyDocLocation: ''
        }

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), policyEndorsmentData);
            console.log("Document written with ID: ", docRef.id);
            setIsloading(false)
            viewEndorsmentOnSuccessPosting(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    return (
        <section>
            <form onSubmit={passEndorsmentFormSubmitHandler}>
                <div className='body_section_header'>
                    <p>Pass An Endorsment</p>
                    <button type='submit' className='btn_pink'>Save Details</button>
                </div>
                <div className='body_section create_policy'>
                    <div className='h_5'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Client Details</p>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input value={clientName} type='text' placeholder='Client Name' readOnly />
                            </div>
                        </div>
                    </div>
                    {/* end client details */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'>
                            <p>Bank Interest Details</p>
                        </div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <input onChange={bankInterestChangeHandler} type='text' placeholder='Bank' />
                            </div>
                        </div>
                    </div>
                    {/* end bank interest */}
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Policy Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input value={policyNumber} type='text' placeholder='Policy Number' readOnly />
                                <div className='h_10'></div>
                                <input onChange={endorsmentNumberChangeHandler} type='text' placeholder='Endorsment Number' />
                                <div className='h_10'></div>
                                <input value={selectedProductClass} type='text' placeholder='Product Class' readOnly />
                                <div className='h_10'></div>
                                <input value={selectedProductSubClass} type='text' placeholder='Product Sub Class' readOnly />
                            </div>
                            {/* end column one */}
                            <div className='category_content_two'>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={inceptionDate} placeholder='Inception Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={inceptionDateChangeHandler} />
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                <div className='date_container'>
                                    <div className='date_view'>
                                        <input type='text' value={expiryDate} placeholder='Expiry Date' readOnly />
                                    </div>
                                    <div className='date_selector'>
                                        <i className="ri-calendar-2-line"></i>
                                        <input type='date' onChange={expiryDateChangeHandler} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end policy details */}
                    {policyAmountContent}
                </div>
            </form>
            {loaderContent}
        </section>
    )
}

export default BodyEndorsment;