import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Worker from "./my.worker.js";
import Loader from "../Loader/Loader.js";
import { useRecoilValue } from "recoil";
import { realTimeBalanceInsurerFilter, activeInsurerPortfolio, DVReallocationInsurerFilter } from "../../../atoms.js";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise.js";
import GetAllDVAllocations from "../../Hooks/BackendConn/Accounts/GetAllDVAllocations.js";
import GetAllReceiptsInsurerID from '../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurerID';
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType.js";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const CommissionReportFinal = () => {

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const realTimeBalanceInsurerFilterRN = useRecoilValue(realTimeBalanceInsurerFilter);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const DVReallocationInsurerFilterRN = useRecoilValue(DVReallocationInsurerFilter)

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: realTimeBalanceInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, realTimeBalanceInsurerFilterRN]);

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: realTimeBalanceInsurerFilterRN, transactionType: 'Credit' });
            setCreditDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo, realTimeBalanceInsurerFilterRN]);

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllReceiptsInsurerID({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree, activeInsurerPortfolioRN]);


    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reloadFive, setReloadFive] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVReallocationInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadFive(Math.random(0, 1000)) }
        })();
    }, [reloadFive, DVReallocationInsurerFilterRN]);

    //################################################################
    //get past allocated dvs
    const [PastDVAllocationData, setPastDVAllocationData] = useState([]);
    const [reloadFour, setReloadFour] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDVAllocations();
            setPastDVAllocationData(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadFour(Math.random(0, 10000)) }
        })();
    }, [reloadFour]);

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            var worker = new Worker();

            const data = { debitDocs: debitDocs, DVDocs: DVDocs, PastDVAllocationData: PastDVAllocationData, receiptDocs: receiptDocs, creditDocs: creditDocs };
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data)
            }

            return () => {
                worker.terminate();
            };

        } catch (error) { }
    }, [debitDocs, DVDocs, PastDVAllocationData]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'policyNumber', 'endorsmentNumber', 'commAllocatedAmounts', 'commAllocatedDetails', 'receivedAllocatedAmount', 'policyCreditsDetails', 'outstanding', 'policyNet', 'policyClass', 'inceptionDate', 'expiryDate',]

    const exportToExcelFileName = 'COMMISSION REPORT    --    INSURER: ' + realTimeBalanceInsurerFilterRN + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,

                doc.commAllocatedAmounts,
                doc.commAllocatedDetails,

                doc.receivedAllocatedAmount,
                doc.policyCreditsDetails,
                doc.outstanding,

                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'COMMISSION REPORT    ||    INSURER: ' + realTimeBalanceInsurerFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 110, 'auto', 110, 'auto', 70, 25, 25, 25,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'COMM PAID', 'COMM DETAILS', 'TOTAL CREDITS', 'CREDIT DETAILS', 'OUTSTANDING', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'POLICY NET',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,

                doc.commAllocatedAmounts,
                doc.commAllocatedDetails,

                doc.receivedAllocatedAmount,
                doc.policyCreditsDetails,
                doc.outstanding,

                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'REAL TIME BALANCE    ||    INSURER: ' + realTimeBalanceInsurerFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 110, 'auto', 110, 'auto', 70, 25, 25, 25,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'COMM PAID', 'COMM DETAILS', 'TOTAL CREDITS', 'CREDIT DETAILS', 'OUTSTANDING', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'POLICY NET',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', sort: 'asc', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },

        { field: 'commAllocatedAmounts', headerName: 'Comm Paid', width: 150, sortable: false, filter: false, },
        { field: 'commAllocatedDetails', headerName: 'C. Details', width: 150, sortable: false, },

        { field: 'receivedAllocatedAmount', headerName: 'Total Credits', width: 150, sortable: false, filter: false, },
        { field: 'policyCreditsDetails', headerName: 'Credit Details', width: 150, sortable: false, },

        { field: 'outstanding', headerName: 'Outstanding', width: 150, filter: false, cellStyle: { 'backgroundColor': '#B1AFCF' } },

        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Commission Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default CommissionReportFinal;