import React, { useCallback, useEffect, useState } from 'react';
import db from '../../../firebase';
import { collection, getDocs, doc, addDoc, arrayUnion, updateDoc } from "firebase/firestore";
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import './BodyPostReceipts.css';

const BodyPostReceipts = (props) => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    // get data
    const [receivedPolicyDocs, setReveivedPolicyDocs] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const PData = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    if (doc.data().transactionType === 'Debit' && doc.data().receivedPolicyNumber !== '') {
                        const allPolicyDocs = {
                            receivedDocID: doc.id,
                            receivedClientName: doc.data().clientName,
                            receivedPolicyNumber: doc.data().policyNumber,
                            receivedEndorsmentNumber: doc.data().endorsmentNumber,
                            receivedPolicyNet: doc.data().policyNet,
                            receivedOutstanding: doc.data().outstanding,
                            receivedAllocatedAmount: 0
                        }
                        PData.push(allPolicyDocs);
                        setIsloading(false);
                    }
                });
                setReveivedPolicyDocs(PData);
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    //process inputs
    const [receiptDate, setReceiptDate] = useState('');

    const [rvalue, onRChange] = useState(new Date());
    const Ryear = new Date(rvalue).getFullYear();
    const Rmonth = new Date(rvalue).getMonth() + 1;
    const Rdate = new Date(rvalue).getDate();
    const receiptDateCleaned = Ryear + '-' + Rmonth + '-' + Rdate;

    useEffect(() => {
        setReceiptDate(receiptDateCleaned)
    }, [receiptDateCleaned])

    const [receiptNumber, setReceiptNumber] = useState(0);
    const receiptNumberChangeHandler = (event) => {
        setReceiptNumber(event.target.value);
    }

    const [receiptAmount, setReceiptAmount] = useState(0);
    const receiptAmountChangeHandler = (event) => {
        setReceiptAmount(event.target.value);
    }

    const [paidBy, setPaidBy] = useState('');
    const paidByChangeHandler = (event) => {
        setPaidBy(event.target.value);
    }

    const [paidTo, setPaidTo] = useState('');
    const paidToChangeHandler = (event) => {
        setPaidTo(event.target.value);
    }

    // client allocation list
    const [clientAllocationList, setClientAllocationList] = useState([]);
    const addClientAllocationListHandler = (clientObject) => {
        setClientAllocationList([...clientAllocationList, clientObject]);
    }

    //search term
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const values = receivedPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.receivedClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.receivedPolicyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        })
        setSearchedPolicyDocs(values);
    }, [receivedPolicyDocs, searchTerm])


    // search section
    const searchContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='search_input'>
                <p>Search Policy</p>
                <div className='h_5'></div>
                <input onChange={searchTermChangeHandler} type='text' placeholder='Policy No / Client Name' />
            </div>
            <div className='h_5'></div>
            <div className='search_results'>
                {searchedPolicyDocs.map((val, key) => (
                    <div key={key}>
                        <div className='result' onClick={() => addClientAllocationListHandler(val)}>
                            <p>{val.receivedClientName}</p>
                            <div className='h_2'></div>
                            <p>Policy No.: {val.receivedPolicyNumber}</p>
                            <div className='h_2'></div>
                            <p>Endorsment No.: {val.receivedEndorsmentNumber}</p>
                            <div className='h_2'></div>
                            <p>Policy Net: {val.receivedPolicyNet}</p>
                            <div className='h_2'></div>
                            <p>Outstanding: {val.receivedOutstanding}</p>
                        </div>
                        <div className='h_5'></div>
                    </div>
                )
                )}

            </div>
        </div>
    </div>

    //calculate total allocated
    const [allocatedAmount, setAllocatedAmount] = useState(0);
    useEffect(() => {
        let totalAmountAllocated = 0;
        clientAllocationList.forEach(item => {
            totalAmountAllocated += +item.receivedAllocatedAmount;
        });
        setAllocatedAmount(totalAmountAllocated);
    }, [clientAllocationList]);

    //calculate remaining amount
    const [remainingAmount, setRemainingAmount] = useState(0);
    useEffect(() => {
        const rem = +receiptAmount - +allocatedAmount;
        setRemainingAmount(rem);
    })

    //receipt_allocation_rightNav

    const onAllocatedAmountChange = (index, event) => {
        const clientAllocationListValues = [...clientAllocationList];
        clientAllocationListValues[index][event.target.name] = event.target.value;
        setClientAllocationList(clientAllocationListValues);
    }

    // allocation section
    let allocationContent = <section className='receipt_allocation_section'>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header receipt_allocation_section'>
                <p>Allocation</p>
                <i className="ri-search-2-line btn_pink" onClick={() => showHideRightNav('Show')}></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='receipt_allocation_table'>
                        <thead>
                            <tr>
                                <td><p>Client Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Outstanding Before Allocation</p></td>
                                <td><p>Allocation</p></td>
                                <td><p>Outstanding After Allocation</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {clientAllocationList.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{item.receivedClientName}</p></td>
                                        <td><p>{item.receivedPolicyNumber}</p></td>
                                        <td><p>{item.receivedPolicyNet}</p></td>
                                        <td><p>{item.receivedOutstanding}</p></td>
                                        <td><input value={item.receivedAllocatedAmount} onChange={event => onAllocatedAmountChange(index, event)} name='receivedAllocatedAmount' /></td>
                                        <td><p>{+item.receivedOutstanding - +item.receivedAllocatedAmount}</p></td>
                                    </tr>
                                )
                            })
                            }
                            <tr>
                                <td colSpan='4'><p></p></td>
                                <td><p>Allocated Amount</p></td>
                                <td><p>{allocatedAmount}</p></td>
                            </tr>
                            <tr>
                                <td colSpan='4'><p></p></td>
                                <td><p>Remaining Amount</p></td>
                                <td><p>{remainingAmount}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //view receipts
    const viewCreatedReceiptOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'View Receipt', postedDocRef: postedDocRef });
    }

    //update record in db
    async function postReceiptFormSubmitHandler(event) {
        event.preventDefault();

        //create obj
        const receiptAllocationBreakDown = {
            receiptNumber: receiptNumber,
            receiptDate: receiptDate,
            receiptAmount: receiptAmount
        }

        //get client nameS
        const allocatedClients = []
        clientAllocationList.map((item) => {
            allocatedClients.push(item.receivedClientName);
        })

        //create obj
        const processedReceiptData = {
            clientName: allocatedClients,
            receiptDate: receiptDate,
            receiptNumber: receiptNumber,
            receiptAmount: receiptAmount,
            paidBy: paidBy,
            paidTo: paidTo,
            transactionType: 'Receipt',
            receiptAllocationDetails: clientAllocationList
        }

        //update data
        try {
            setIsloading(true);
            clientAllocationList.forEach(async (item) => {
                // Create an initial document to update.
                const policyDocRef = doc(db, "PolicyDetails", item.receivedDocID);

                // To update age and favorite color:
                await updateDoc(policyDocRef, {
                    'outstanding': +item.receivedOutstanding - +item.receivedAllocatedAmount,
                    'receiptAllocationBreakDown': arrayUnion(receiptAllocationBreakDown)
                });
            });
            setIsloading(false);
        } catch (error) {
            console.error("Error updating document: ", error);
        }

        //post data
        try {
            setIsloading(true);
            const docRef = await addDoc(collection(db, "PolicyDetails"), processedReceiptData);
            console.log("Document written with ID: ", docRef.id);
            setIsloading(false);
            viewCreatedReceiptOnSuccessPosting(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    //#####################################################################################################
    //#####################################################################################################
    //#####################################################################################################


    return (
        <section>
            <form onSubmit={postReceiptFormSubmitHandler}>
                <div className='body_section_header'>
                    <p>Receipt Posting</p>
                    <button type='submit' className='btn_pink'>Submit</button>
                </div>
                <div className='body_section post_receipts_content'>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Receipt Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <div className=''>
                                    <div className='date_picker_container'>
                                        <div className='date_container_vertical'>
                                            <div className='w_5'></div>
                                            <p>Receipt Date</p>
                                            <div className='w_10'></div>
                                            <DatePicker onChange={onRChange} value={rvalue} format='d/ M/y' />
                                        </div>
                                    </div>
                                </div>
                                <div className='h_10'></div>
                                <input onChange={receiptNumberChangeHandler} type='text' placeholder='Receipt Number' />
                                <div className='h_10'></div>
                                <input onChange={receiptAmountChangeHandler} type='text' placeholder='Amount' />
                                <div className='h_10'></div>
                            </div>
                            <div className='category_content_two'>
                                <input onChange={paidByChangeHandler} type='text' placeholder='Paid By' />
                                <div className='h_10'></div>
                                <input onChange={paidToChangeHandler} type='text' placeholder='Paid To' />
                            </div>
                        </div>
                    </div>
                    {/* end receipt details */}
                    {allocationContent}
                    {searchContent}
                </div>
            </form>
            {loaderContent}
        </section>

    );
}

export default BodyPostReceipts;