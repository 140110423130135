import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../../firebase";
import PolicyClasses from "./PolicyClasses";
import styles from './CreateQuote.module.css'
import { useRecoilValue } from "recoil";
import { activeInsurerPortfolio } from "../../../atoms";
import Loader from "../Loader/Loader";
import NumberFormat from '../../Hooks/UI/NumberFormat';
import logo from '../../Assets/img/logo.png'
import UUID from "../../Hooks/UUID/UUID";
import GetCookie from "../../Hooks/Cookie/GetCookie";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import MotorPrivateComprehensivePremiumCalculator from "./PremiumCalculators/MotorPrivateComprehensivePremiumCalculator";
import MotorCommercialComprehensivePremiumCalculator from "./PremiumCalculators/MotorCommercialComprehensivePremiumCalculator";
import { DatePickerInput } from '@mantine/dates';
import GetAllInsurerDetails from "../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import MotorPrivateCompClauses from "./QuoteClauses/MotorPrivateCompClauses";
import { useReactToPrint } from "react-to-print";
import MotorCommCompClauses from "./QuoteClauses/MotorCommCompClauses";
import MotorCommTPOGeneralCartage from "./PremiumCalculators/MotorCommTPOGeneralCartage";
import MotorCommTPOOwnGoods from "./PremiumCalculators/MotorCommTPOOwnGoods";
import MotorCommTPOClauses from "./QuoteClauses/MotorCommTPoClauses";

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const CreateQuote = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //user
    const userDetails = GetCookie('CurrentUserSession');

    //################################################################
    //policy classes
    const PolicyClassesData = PolicyClasses;

    //################################################################
    // recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all insurer details
    const [insurerDocs, setInsurerDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //filter active insurer
    const [activeInsurerDocs, setActiveInsurerDocs] = useState({})
    useEffect(() => {
        const list = insurerDocs.filter(doc => doc.docID === activeInsurerPortfolioRN);
        setActiveInsurerDocs(list[0])
    }, [insurerDocs]);

    //################################################################
    //get use input
    const [insuranceClasses, setInsuranceClasses] = useState([]);
    const [insuranceSubClasses, setInsuranceSubClasses] = useState([]);
    const title = useRef(null);
    const insured = useRef(null);
    const insurer = useRef(null);

    //################################################################
    //user input
    const [inceptionDate, setInceptionDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    //const [] = useState(new Date());
    const [selectedInsuranceClass, setSelectedInsuranceClass] = useState('');
    const [selectedInsuranceSubClass, setSelectedInsuranceSubClass] = useState('');
    const [quotationClasses, setQuotationClasses] = useState([])

    //################################################################
    //quote array
    const [quoteObj, setQuoteObj] = useState([]);
    useEffect(() => {
        try {

            //################################################################
            // (0700) - Motor Private - Comprehensive
            if (quotationClasses.indexOf('(0700) - Motor Private - Comprehensive') == -1) {
                if (selectedInsuranceSubClass === '(0700) - Motor Private - Comprehensive') {

                    let ass = {};
                    if (Object.keys(activeInsurerDocs).length > 0) {
                        ass = activeInsurerDocs.docData.policyCategories;
                    }

                    const aaa = ass[selectedInsuranceClass];
                    const bbb = aaa[selectedInsuranceSubClass];

                    let basicPremiumRating = 0;
                    let excessProtectorRating = 0;
                    let PVTRating = 0;
                    let windScreenRating = 0;
                    let windScreenFreeLimit = 0;
                    let radioCassetRating = 0;
                    let radioCassetFreeLimit = 0;

                    if (Object.keys(bbb).length > 0) {
                        basicPremiumRating = bbb.premiumRate.basicPremium;
                        excessProtectorRating = bbb.premiumRate.excessProtector;
                        PVTRating = bbb.premiumRate.PVT;
                        windScreenRating = bbb.premiumRate.windScreen;
                        radioCassetRating = bbb.premiumRate.radioCasset;
                        windScreenFreeLimit = bbb.premiumRate.windScreenFreeLimit;
                        radioCassetFreeLimit = bbb.premiumRate.radioCassetFreeLimit;
                    }

                    const a = {
                        policyClass: selectedInsuranceClass,
                        subClass: '(0700) - Motor Private - Comprehensive',
                        vehicleDetails: [
                            {
                                plateNo: 'TBA',
                                value: 0,
                                basicPremiumRating: basicPremiumRating,
                                premiumCharge: 0,
                                windScreenRating: windScreenRating,
                                windScreen: 50000,
                                windScreenFreeLimit: windScreenFreeLimit,
                                windScreenCharge: 0,
                                radioCassetRating: radioCassetRating,
                                radioCasset: 50000,
                                radioCassetFreeLimit: radioCassetFreeLimit,
                                radioCassetCharge: 0,
                                excessProtectorRating: excessProtectorRating,
                                excessProtectorCharge: 0,
                                PVTRating: PVTRating,
                                pvtCharge: 0,
                                pll: 2,
                                pllCharge: 0,
                                tareWeight: 0,
                                stampDuty: 0
                            },
                        ],

                    }
                    setQuotationClasses([...quotationClasses, '(0700) - Motor Private - Comprehensive'])
                    setQuoteObj([...quoteObj, a])

                }
            }

            //################################################################
            // (0701) - Motor Cycle - Comprehensive
            if (quotationClasses.indexOf('(0701) - Motor Cycle - Comprehensive') == -1) {
                if (selectedInsuranceSubClass === '(0701) - Motor Cycle - Comprehensive') {
                    const a = {
                        subClass: '(0701) - Motor Cycle - Comprehensive',
                        vehicleDetails: [
                            { plateNo: 'TBA', value: 0, windScreen: 0, radioCasset: 0, pll: 0, tareWeight: 0, premiumCharge: 5000, windScreenCharge: 0, radioCassetCharge: 0, excessProtectorCharge: 0, pvtCharge: 0, pllCharge: 0 }
                        ],

                    }
                    setQuotationClasses([...quotationClasses, '(0701) - Motor Cycle - Comprehensive'])
                    setQuoteObj([...quoteObj, a])

                }
            }

            //################################################################
            // (0700) - Motor Private - TPO
            if (quotationClasses.indexOf('(0700) - Motor Private - TPO') == -1) {
                if (selectedInsuranceSubClass === '(0700) - Motor Private - TPO') {
                    const a = {
                        subClass: '(0700) - Motor Private - TPO',
                        vehicleDetails: [
                            { plateNo: 'TBA', value: 0, windScreen: 0, radioCasset: 0, pll: 0, tareWeight: 0, premiumCharge: 7500, windScreenCharge: 0, radioCassetCharge: 0, excessProtectorCharge: 0, pvtCharge: 0, pllCharge: 0 }
                        ],

                    }
                    setQuotationClasses([...quotationClasses, '(0700) - Motor Private - TPO'])
                    setQuoteObj([...quoteObj, a])

                }
            }

            //################################################################
            // (0700) - Motor Private - TPO
            if (quotationClasses.indexOf('(0701) - Motor Cycle - TPO') == -1) {
                if (selectedInsuranceSubClass === '(0701) - Motor Cycle - TPO') {
                    const a = {
                        subClass: '(0701) - Motor Cycle - TPO',
                        vehicleDetails: [
                            { plateNo: 'TBA', value: 0, windScreen: 0, radioCasset: 0, pll: 0, tareWeight: 0, premiumCharge: 3000, windScreenCharge: 0, radioCassetCharge: 0, excessProtectorCharge: 0, pvtCharge: 0, pllCharge: 0 }
                        ],

                    }
                    setQuotationClasses([...quotationClasses, '(0701) - Motor Cycle - TPO'])
                    setQuoteObj([...quoteObj, a])

                }
            }

            //################################################################
            // (0800) - Motor Commercial - Comprehensive
            if (quotationClasses.indexOf('(0800) - Motor Commercial - Comprehensive') == -1) {
                if (selectedInsuranceSubClass === '(0800) - Motor Commercial - Comprehensive') {

                    let ass = {};
                    if (Object.keys(activeInsurerDocs).length > 0) {
                        ass = activeInsurerDocs.docData.policyCategories;
                    }

                    const aaa = ass[selectedInsuranceClass];
                    const bbb = aaa[selectedInsuranceSubClass];

                    let basicPremiumRating = 0;
                    let excessProtectorRating = 0;
                    let PVTRating = 0;
                    let windScreenRating = 0;
                    let windScreenFreeLimit = 0;
                    let radioCassetRating = 0;
                    let radioCassetFreeLimit = 0;
                    let PLLRating = 0;

                    if (Object.keys(bbb).length > 0) {
                        basicPremiumRating = bbb.premiumRate.basicPremium;
                        excessProtectorRating = bbb.premiumRate.excessProtector;
                        PVTRating = bbb.premiumRate.PVT;
                        windScreenRating = bbb.premiumRate.windScreen;
                        radioCassetRating = bbb.premiumRate.radioCasset;
                        PLLRating = bbb.premiumRate.PLL;
                        windScreenFreeLimit = bbb.premiumRate.windScreenFreeLimit;
                        radioCassetFreeLimit = bbb.premiumRate.radioCassetFreeLimit;
                    }

                    const a = {
                        policyClass: selectedInsuranceClass,
                        subClass: '(0800) - Motor Commercial - Comprehensive',
                        vehicleDetails: [
                            {
                                plateNo: 'TBA',
                                value: 0,
                                basicPremiumRating: basicPremiumRating,
                                premiumCharge: 0,
                                windScreenRating: windScreenRating,
                                windScreen: 50000,
                                windScreenFreeLimit: windScreenFreeLimit,
                                windScreenCharge: 0,
                                radioCassetRating: radioCassetRating,
                                radioCasset: 50000,
                                radioCassetFreeLimit: radioCassetFreeLimit,
                                radioCassetCharge: 0,
                                excessProtectorRating: excessProtectorRating,
                                excessProtectorCharge: 0,
                                PVTRating: PVTRating,
                                pvtCharge: 0,
                                PLLRating: PLLRating,
                                pll: 2,
                                pllCharge: 0,
                                tareWeight: 0,
                                stampDuty: 0
                            },
                        ],

                    }

                    setQuotationClasses([...quotationClasses, '(0800) - Motor Commercial - Comprehensive'])
                    setQuoteObj([...quoteObj, a])

                }
            }

            //################################################################
            // (0800) - Motor Commercial - tpo
            if (quotationClasses.indexOf('(0807) - General Cartage - TPO') == -1) {
                if (selectedInsuranceSubClass === '(0807) - General Cartage - TPO') {

                    const a = {
                        policyClass: selectedInsuranceClass,
                        subClass: '(0807) - General Cartage - TPO',
                        vehicleDetails: [
                            {
                                plateNo: 'TBA',
                                value: 0,
                                basicPremiumRating: 0,
                                premiumCharge: 0,
                                windScreenRating: 0,
                                windScreen: 0,
                                windScreenCharge: 0,
                                radioCassetRating: 0,
                                radioCasset: 0,
                                radioCassetCharge: 0,
                                excessProtectorRating: 0,
                                excessProtectorCharge: 0,
                                PVTRating: 0,
                                pvtCharge: 0,
                                PLLRating: 0,
                                pll: 0,
                                pllCharge: 0,
                                tareWeight: 0,
                            },
                        ],

                    }

                    setQuotationClasses([...quotationClasses, '(0807) - General Cartage - TPO'])
                    setQuoteObj([...quoteObj, a])
                }
            }


            //################################################################
            // (0800) - Motor Commercial - tpo
            if (quotationClasses.indexOf('(0800) - Motor Commercial - TPO') == -1) {
                if (selectedInsuranceSubClass === '(0800) - Motor Commercial - TPO') {

                    const a = {
                        policyClass: selectedInsuranceClass,
                        subClass: '(0800) - Motor Commercial - TPO',
                        vehicleDetails: [
                            {
                                plateNo: 'TBA',
                                value: 0,
                                basicPremiumRating: 0,
                                premiumCharge: 0,
                                windScreenRating: 0,
                                windScreen: 0,
                                windScreenCharge: 0,
                                radioCassetRating: 0,
                                radioCasset: 0,
                                radioCassetCharge: 0,
                                excessProtectorRating: 0,
                                excessProtectorCharge: 0,
                                PVTRating: 0,
                                pvtCharge: 0,
                                PLLRating: 0,
                                pll: 0,
                                pllCharge: 0,
                                tareWeight: 0,
                            },
                        ],

                    }

                    setQuotationClasses([...quotationClasses, '(0800) - Motor Commercial - TPO'])
                    setQuoteObj([...quoteObj, a])
                }
            }


        } catch (error) { console.log(error) }
    }, [selectedInsuranceSubClass, selectedInsuranceClass])

    //################################################################
    //on quote array change - update
    const onQuoteItemChangeHandler = (index, indexx, event) => {
        const values = [...quoteObj];
        values[index].vehicleDetails[indexx][event.target.name] = event.target.value;

        values.map((policyClass, index) => {
            if (policyClass.subClass === '(0700) - Motor Private - Comprehensive') {
                policyClass.vehicleDetails.map((veh, indexx) => {
                    const calculatedPremium = MotorPrivateComprehensivePremiumCalculator(veh);
                    const values = [...quoteObj];
                    values[index].vehicleDetails[indexx] = calculatedPremium;
                });
            }

            if (policyClass.subClass === '(0800) - Motor Commercial - Comprehensive') {
                policyClass.vehicleDetails.map((veh, indexx) => {
                    const calculatedPremium = MotorCommercialComprehensivePremiumCalculator(veh);
                    const values = [...quoteObj];
                    values[index].vehicleDetails[indexx] = calculatedPremium;
                });
            }

            if (policyClass.subClass === '(0807) - General Cartage - TPO') {
                policyClass.vehicleDetails.map((veh, indexx) => {
                    const calculatedPremium = MotorCommTPOGeneralCartage(veh);
                    const values = [...quoteObj];
                    values[index].vehicleDetails[indexx] = calculatedPremium;
                });
            }

            if (policyClass.subClass === '(0800) - Motor Commercial - TPO') {
                policyClass.vehicleDetails.map((veh, indexx) => {
                    const calculatedPremium = MotorCommTPOOwnGoods(veh);
                    const values = [...quoteObj];
                    values[index].vehicleDetails[indexx] = calculatedPremium;
                });
            }

        });

        setQuoteObj(values);
    }

    //################################################################
    //set insurance classes
    useEffect(() => {
        const insuranceClasses = []
        PolicyClassesData.map((doc) => {
            insuranceClasses.push(
                doc.productClass
            )
        });
        setInsuranceClasses(insuranceClasses)
    }, [])

    //################################################################
    //change insurance sub classes
    useEffect(() => {
        const a = PolicyClassesData.filter(doc => doc.productClass === selectedInsuranceClass);
        const sub = [];
        a.map((doc) => {
            Object.values(doc.subClass).map((item) => {
                sub.push(item)
            });
        });
        setInsuranceSubClasses(sub);
    }, [selectedInsuranceClass]);

    //################################################################
    //save quote
    const showSuccessMSGOnQuoteSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Quotes List',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Quote Saved'
        }
        props.onePropDown(onePropObj);
    }

    const saveQuoteHandler = async () => {
        try {

            setIsLoading(true);

            const quoteFinalObj = {
                title: title.current.value.trim().toUpperCase(),
                insured: insured.current.value.trim().toUpperCase(),
                insurer: insurer.current.value.trim().toUpperCase(),
                quoteObj: quoteObj,
                dateCreated: new Date(),
                createdBy: JSON.parse(userDetails),
            }

            const washingtonRef = doc(db, "Quotes", "NHv0Af6yzenwu3N96OwI");

            //get unique id
            const quoteID = UUID() + ' - ' + UUID();

            // Set the "capital" field of the city 'DC'
            await updateDoc(washingtonRef, {
                [quoteID]: quoteFinalObj
            });

            showSuccessMSGOnQuoteSave();

        } catch (error) { }
    }

    //################################################################
    //content ${styles.quotes_table_print_only}
    const content = <section className={`${styles.quote_container} f_7`} ref={componentRef}>
        <div className={`${styles.logo_container, styles.quotes_template_print_only_img_container}`}><img className={`${styles.quotes_template_print_only_img}`} alt='logo' src={logo} /></div>
        <div className={`text-center ${styles.quotes_template_print_only}`}><p className='bold'>QUOTE</p></div>
        <table className='w-full table-fixed border-collapse border border-slate-500'>


            <thead>
                {/* <tr>
                    <td className='w-3/12 border border-slate-600 px-1'>
                        <p>Policy Period</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 px-1'>
                        <DatePickerInput
                            dropdownType="modal"
                            label="Inception date"
                            placeholder="Pick date"
                            value={inceptionDate}
                            onChange={setInceptionDate}
                            mx="auto"
                            maw={400}
                        />


                        <DatePickerInput
                            dropdownType="modal"
                            label="Expiry date"
                            placeholder="Pick date"
                            value={expiryDate}
                            onChange={setExpiryDate}
                            mx="auto"
                            maw={400}
                        />
                    </td>
                </tr> */}
                <tr className={styles.quotes_table_screen_only}>
                    <td className='w-2/12 border border-slate-600 px-1'>
                        <p>Choose Policy Class</p>
                    </td>
                    <td className='w-10/12 border border-slate-600 px-1'>
                        {
                            insuranceClasses.map((policyClass, index) => {
                                return (
                                    <span key={index} className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${selectedInsuranceClass === policyClass && styles.selected_insurance_classes}`} onClick={() => { setSelectedInsuranceClass(policyClass) }}>{policyClass}</span>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr className={styles.quotes_table_screen_only}>
                    <td className='w-2/12 border border-slate-600 px-1'>
                        <p>Choose Policy Sub Class</p>
                    </td>
                    <td className='w-10/12 border border-slate-600 px-1'>
                        {
                            insuranceSubClasses.map((policySubClass, index) => {
                                return (
                                    <span className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${selectedInsuranceSubClass === policySubClass && styles.selected_insurance_classes}`} key={index} onClick={() => { setSelectedInsuranceSubClass(policySubClass) }}>{policySubClass}</span>
                                )
                            })
                        }
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='w-2/12 border border-slate-600 px-1'>
                        <p>TITLE</p>
                    </td>
                    <td className='w-10/12 border border-slate-600 px-1'>
                        <input ref={title} className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-2/12 border border-slate-600 px-1'>
                        <p>INSURER</p>
                    </td>
                    <td className='w-10/12 border border-slate-600 px-1'>
                        <input ref={insurer} className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-2/12 border border-slate-600 px-1'>
                        <p>CLIENT</p>
                    </td>
                    <td className='w-10/12 border border-slate-600 px-1'>
                        <input ref={insured} className={styles.veh_input} />
                    </td>
                </tr>
            </tbody>
            <tbody>
                {
                    quoteObj.map((item, index) => {

                        // ################################################################    
                        // (07) - Motor Private - Comprehensive
                        switch (item.subClass) {
                            case '(0700) - Motor Private - Comprehensive':
                                return (
                                    <tr key={index}>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <p>{item.subClass}</p>
                                        </td>
                                        <td className='w-3/12 '>

                                            <table className='w-full table-fixed '>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Interest Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Category</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Sum Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Rate ( % )</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Premium ( Kshs )</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {
                                                item.vehicleDetails.map((veh, indexx) => {
                                                    return (
                                                        <table key={indexx} className='w-full table-fixed '>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden' rowSpan={5}><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Basic Premium</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.value} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='value' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.basicPremiumRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='basicPremiumRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Wind Screen</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreen} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreen' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreenRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreenRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreenCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreenCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Radio Casset</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCasset} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCasset' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCassetRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCassetRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCassetCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCassetCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Excess Protector</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.excessProtectorRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='excessProtectorRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.excessProtectorCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='excessProtectorCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>PVT</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.PVTRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='PVTRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pvtCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pvtCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )
                                                })
                                            }

                                            <table className='w-full table-fixed'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>Premium</p></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0))
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className='w-full table-fixed '>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden' colSpan={3}></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>T Levy</p></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0) * 0.002)
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className='w-full table-fixed '>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>PHC Fund</p></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0) * 0.0025)
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className='w-full table-fixed'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>Stamp Duty</p></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p>
                                                            {
                                                                item.vehicleDetails.reduce((acc, cur) => acc + cur.stampDuty, 0)
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className='w-full table-fixed'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p className='bold'>Total Premium</p></td>
                                                        <td className='w-1/3 border-b border-slate-600 px-1 overflow-hidden'><p className='bold'>
                                                            {
                                                                NumberFormat(
                                                                    item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0)
                                                                    + item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0) * 0.002
                                                                    + item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge, 0) * 0.0025
                                                                    + item.vehicleDetails.reduce((acc, cur) => acc + cur.stampDuty, 0)
                                                                )
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.quotes_table_print_only}`} >
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><MotorPrivateCompClauses /></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )
                                break;

                            // ################################################################    
                            // (0800) - Motor Commercial - Comprehensive
                            case '(0800) - Motor Commercial - Comprehensive':
                                return (
                                    <tr key={index}>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <p>{item.subClass}</p>
                                        </td>
                                        <td className='w-3/12 border border-slate-600'>

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Interest Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Category</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Sum Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Rate ( % )</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Premium ( Kshs )</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {
                                                item.vehicleDetails.map((veh, indexx) => {
                                                    return (
                                                        <table key={indexx} className='w-full table-fixed border-collapse border border-slate-500'>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden' rowSpan={6}><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Basic Premium</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.value} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='value' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.basicPremiumRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='basicPremiumRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Wind Screen</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreen} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreen' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreenRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreenRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreenCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreenCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Radio Casset</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCasset} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCasset' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCassetRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCassetRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCassetCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCassetCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>PLL</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pll} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pll' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.PLLRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='PLLRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pllCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pllCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Excess Protector</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.excessProtectorRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='excessProtectorRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.excessProtectorCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='excessProtectorCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>PVT</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.PVTRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='PVTRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pvtCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pvtCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )
                                                })
                                            }

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>Total</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge + cur.windScreenCharge + cur.radioCassetCharge + cur.excessProtectorCharge + cur.pvtCharge + cur.pllCharge, 0))
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.quotes_table_print_only}`} >
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><MotorCommCompClauses /></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )
                                break;

                            // ################################################################    
                            // (0807) - General Cartage - TPO
                            case '(0807) - General Cartage - TPO':
                                return (
                                    <tr key={index}>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <p>{item.subClass}</p>
                                        </td>
                                        <td className='w-3/12 border border-slate-600'>

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Interest Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Category</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Tonnage</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Rate ( % )</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Premium ( Kshs )</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {
                                                item.vehicleDetails.map((veh, indexx) => {
                                                    return (
                                                        <table key={indexx} className='w-full table-fixed border-collapse border border-slate-500'>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden' rowSpan={6}><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Basic Premium</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.tareWeight} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='tareWeight' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.basicPremiumRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='basicPremiumRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )
                                                })
                                            }

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>Total</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge, 0))
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.quotes_table_print_only}`} >
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><MotorCommTPOClauses /></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )
                                break;

                            // ################################################################    
                            // (0800) - Motor Commercial - TPO
                            case '(0800) - Motor Commercial - TPO':
                                return (
                                    <tr key={index}>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <p>{item.subClass}</p>
                                        </td>
                                        <td className='w-3/12 border border-slate-600'>

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Interest Insured</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Category</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Tonnage</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Rate ( % )</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Premium ( Kshs )</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {
                                                item.vehicleDetails.map((veh, indexx) => {
                                                    return (
                                                        <table key={indexx} className='w-full table-fixed border-collapse border border-slate-500'>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden' rowSpan={6}><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p>Basic Premium</p></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.tareWeight} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='tareWeight' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.basicPremiumRating} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='basicPremiumRating' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )
                                                })
                                            }

                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>Total</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><p className='bold'>
                                                            {
                                                                NumberFormat(item.vehicleDetails.reduce((acc, cur) => acc + cur.premiumCharge, 0))
                                                            }
                                                        </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.quotes_table_print_only}`} >
                                                <tbody>
                                                    <tr>
                                                        <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><MotorCommTPOClauses /></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )
                                break;

                            // ################################################################    
                            // (0701) - Motor Cycle - Comprehensive
                            // case '(0701) - Motor Cycle - Comprehensive':
                            //     return (
                            //         <tr key={index}>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <p>{item.subClass}</p>
                            //             </td>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <table className='w-full table-fixed border-collapse border border-slate-500'>
                            //                     <thead>
                            //                         <tr>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Plate No</p></td>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Premium Charge</p></td>
                            //                         </tr>
                            //                     </thead>
                            //                     <tbody>
                            //                         {
                            //                             item.vehicleDetails.map((veh, indexx) => {
                            //                                 return (
                            //                                     <tr key={indexx}>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                            //                                     </tr>
                            //                                 )
                            //                             })
                            //                         }
                            //                     </tbody>
                            //                 </table>
                            //             </td>
                            //         </tr>
                            //     )
                            //     break;

                            // ################################################################    
                            // (0700) - Motor Private - TPO
                            // case '(0700) - Motor Private - TPO':
                            //     return (
                            //         <tr key={index}>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <p>{item.subClass}</p>
                            //             </td>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <table className='w-full table-fixed border-collapse border border-slate-500'>
                            //                     <thead>
                            //                         <tr>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Plate No</p></td>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Premium Charge</p></td>
                            //                         </tr>
                            //                     </thead>
                            //                     <tbody>
                            //                         {
                            //                             item.vehicleDetails.map((veh, indexx) => {
                            //                                 return (
                            //                                     <tr key={indexx}>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                            //                                     </tr>
                            //                                 )
                            //                             })
                            //                         }
                            //                     </tbody>
                            //                 </table>
                            //             </td>
                            //         </tr>
                            //     )
                            //     break;

                            // ################################################################    
                            // (0701) - Motor Cycle - TPO
                            // case '(0701) - Motor Cycle - TPO':
                            //     return (
                            //         <tr key={index}>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <p>{item.subClass}</p>
                            //             </td>
                            //             <td className='w-3/12 border border-slate-600 px-1'>
                            //                 <table className='w-full table-fixed border-collapse border border-slate-500'>
                            //                     <thead>
                            //                         <tr>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Plate No</p></td>
                            //                             <td className='w-1/2 border border-slate-600 px-1'><p>Premium Charge</p></td>
                            //                         </tr>
                            //                     </thead>
                            //                     <tbody>
                            //                         {
                            //                             item.vehicleDetails.map((veh, indexx) => {
                            //                                 return (
                            //                                     <tr key={indexx}>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                            //                                         <td className='w-1/2 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                            //                                     </tr>
                            //                                 )
                            //                             })
                            //                         }
                            //                     </tbody>
                            //                 </table>
                            //             </td>
                            //         </tr>
                            //     )
                            //     break;

                            // ################################################################    
                            // (0800) - Motor Commercial - Comprehensive
                            case '(0800) - Motor Commercial - Comprehensive':
                                return (
                                    <tr key={index}>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <p>{item.subClass}</p>
                                        </td>
                                        <td className='w-3/12 border border-slate-600 px-1'>
                                            <table className='w-full table-fixed border-collapse border border-slate-500'>
                                                <thead>
                                                    <tr>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>Plate No</p></td>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>Vehicle Value</p></td>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>Wind Screen Value</p></td>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>Radio Casset Value</p></td>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>PLL</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1'><p>Premium Charge</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1'><p>Wind Screen Charge</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1'><p>Radio Casset Charge</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1'><p>Excess Protector Charge</p></td>
                                                        <td className='w-1/3 border border-slate-600 px-1'><p>PVT Charge</p></td>
                                                        <td className='w-1/4 border border-slate-600 px-1'><p>PLL Charge</p></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.vehicleDetails.map((veh, indexx) => {
                                                            return (
                                                                <tr key={indexx}>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.plateNo} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='plateNo' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.value} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='value' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreen} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreen' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCasset} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCasset' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pll} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pll' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.premiumCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='premiumCharge' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.windScreenCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='windScreenCharge' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.radioCassetCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='radioCassetCharge' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.excessProtectorCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='excessProtectorCharge' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pvtCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pvtCharge' className={styles.veh_input} /></td>
                                                                    <td className='w-1/3 border border-slate-600 px-1 overflow-hidden'><input value={veh.pllCharge} onChange={event => onQuoteItemChangeHandler(index, indexx, event)} name='pllCharge' className={styles.veh_input} /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )
                                break;


                            // case '':
                            //     return ()
                            //     break;

                            default:
                                break;
                        }



                    })
                }






            </tbody>
        </table>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Create Quote</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrint}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={saveQuoteHandler}>Save</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
        </section>
    );
}

export default CreateQuote;