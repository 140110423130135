import db from '../../../../firebase';
import { getDoc, doc } from 'firebase/firestore';

const GetADocument = async (props) => {

    let data = {};

    const docRef = doc(db, "PolicyDetails", props);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        data = {docID: props, docData:docSnap.data()}
        
    } else {
        // doc.data() will be undefined in this case
        //console.log("No such document!");
    }

    return data;

}

export default GetADocument;