const NumberFormat = (props) => {
    try {
        return (
            parseFloat(props).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
             //props.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            // parseInt(props).toLocaleString("en-US")
        )
    } catch (error) { console.log(error)}   
}

export default NumberFormat;