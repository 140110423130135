import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import Paginate from '../BodyPolicy/Paginate';
import { useRecoilValue } from 'recoil';
import { totalCommissionFilterFromDateRS, totalCommissionFilterToDateRS } from '../../../atoms';
import './TotalCommission.css';
import GetDebitsCredits from '../../Hooks/BackendConn/GeneralList/GetDebitsCredits';

const TotalCommission = () => {
    //################################################################
    //get items from local storage
    const fromDate = useRecoilValue(totalCommissionFilterFromDateRS)
    const toDate = useRecoilValue(totalCommissionFilterToDateRS)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all debits
    // const [debitDocs, setAllDebitDocs] = useState([]);
    // const [reload, setReload] = useState(0);
    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true)
    //         const DDocs = await GetAllDebit();
    //         setAllDebitDocs(DDocs)
    //         if (DDocs) {
    //             setIsLoading(false)
    //         }
    //         else { setReload(Math.random(0, 1000)) }
    //     })();
    // }, [reload]);

    //################################################################
    //get all debits and credits
    const [debitCreditsDocs, setAllDebitCreditDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetDebitsCredits();
            setAllDebitCreditDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get all debits
    const [cleanedDocs, setCleanedDocs] = useState([]);
    useEffect(() => {
        const clean = [];
        debitCreditsDocs.map((item) => {
            if (item.docData.status != 'Pending'
                && !item.docData.policyNumber.startsWith('DV')
                && !item.docData.policyNumber.startsWith('JV')
                && !item.docData.policyNumber.startsWith('CN')) {
                clean.push(item)
            }
        });
        setCleanedDocs(clean)
    }, [debitCreditsDocs]);

    //################################################################
    //filter dates
    const [dateFilteredDocs, setDateFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        cleanedDocs.map((item) => {
            //calculate next inception
            const newInsurerDebitDate = new Date(item.docData.insurerDebitDate);
            newInsurerDebitDate.setDate(newInsurerDebitDate.getDate());
            newInsurerDebitDate.setDate(newInsurerDebitDate.getMonth());
            //filter period choosen
            if (new Date(newInsurerDebitDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(newInsurerDebitDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setDateFilteredDocs(periodSelected);
    }, [cleanedDocs, fromDate, toDate]);

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = dateFilteredDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.insurerDebitDate).valueOf() - new Date(b.docData.insurerDebitDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [dateFilteredDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // calc total
    const [commissionTotal, setCommissionTotal] = useState([]);
    const [withholdingTax, setWithholdingTax] = useState([]);
    const [commissionExLevies, setCommissionExLevies] = useState([]);
    useEffect(() => {
        const commissionTotal = [];
        const withholdingTax = [];
        const commissionExLevies = [];
        searchedPolicyDocs.map((item) => {
            if (item.docData.transactionType === 'Debit') {
                commissionTotal.push(parseInt(item.docData.commission))
                withholdingTax.push(parseInt(item.docData.withholdingTax))
                commissionExLevies.push(parseInt(item.docData.commission) - parseInt(item.docData.withholdingTax))
            }
            else if (item.docData.transactionType === 'Credit') {
                commissionTotal.push(parseInt(-item.docData.commission))
                withholdingTax.push(parseInt(-item.docData.withholdingTax))
                commissionExLevies.push(parseInt(- item.docData.commission) - parseInt(-item.docData.withholdingTax))
            }

        });
        const a = commissionTotal.reduce((total, item) => { return total + +item }, 0);
        setCommissionTotal(a);
        const b = withholdingTax.reduce((total, item) => { return total + +item }, 0);
        setWithholdingTax(b);
        const c = commissionExLevies.reduce((total, item) => { return total + +item }, 0);
        setCommissionExLevies(c);
    }, [searchedPolicyDocs]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    //echo
    const totalCommisssionContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='total_commission_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Insurer Debit Date</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Commission</p></td>
                                <td><p>Withholding Tax</p></td>
                                <td><p>Commission Payable</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.insurerDebitDate).toDateString()}</p></td>
                                        <td><p>{item.docData.policyNet}</p></td>
                                        <td><p>{item.docData.commission}</p></td>
                                        <td><p>{item.docData.withholdingTax}</p></td>
                                        <td><p>{parseInt(item.docData.commission) - parseInt(item.docData.withholdingTax)}</p></td>
                                    </tr>
                                )
                            })
                            }
                            <tr>
                                <td colSpan='8'></td>
                                <td><p>Totals</p></td>
                                <td><p>{commissionTotal}</p></td>
                                <td><p>{withholdingTax}</p></td>
                                <td><p>{commissionExLevies}</p></td>
                            </tr>
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //################################################################
    //################################################################

    return (
        <section className='total_commission'>
            <div className='body_section_header'>
                <p>Total Commission</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {totalCommisssionContent}
            </div>
            {loaderContent}
        </section>
    );
}

export default TotalCommission;