import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { activeInsurerPortfolio, directCollectionsRemmittedFilterFromDate, directCollectionsRemmittedFilterToDate } from "../../../atoms";
import { directCollectionsRemmittedInsurerFilter } from "../../../atoms";
import GetDirectCollectionsRemmitted from "../../Hooks/BackendConn/InsurerSpecific/GetDirectCollectionsRemmitted";
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const DirectCollectionsRemmitted = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const directCollectionsRemmittedFilterFromDateRN = useRecoilValue(directCollectionsRemmittedFilterFromDate);
    const directCollectionsRemmittedFilterToDateRN = useRecoilValue(directCollectionsRemmittedFilterToDate);
    const directCollectionsRemmittedInsurerFilterRN = useRecoilValue(directCollectionsRemmittedInsurerFilter)

    //################################################################
    //get all debits
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetDirectCollectionsRemmitted({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, activeInsurerPortfolioRN]);

    //################################################################
    //brought forward feature
    const [BFReceiptDocs, setBFReceiptDocs] = useState([]);
    useEffect(() => {
        const BFReceipts = [];
        const PeriodSelected = [];

        receiptDocs.map((doc, index) => {
            //declare filter date
            let filterDate = '';
            switch (doc.docData.transactionType) {
                case 'Receipt':
                    filterDate = new Date(doc.docData.receiptDate);
                    filterDate.setHours(0, 0, 0);
                    break;
            }
            //sort into array
            if (new Date(directCollectionsRemmittedFilterFromDateRN).valueOf() > new Date(filterDate).valueOf()) {
                BFReceipts.push(doc.docData.receiptAmount);
            }
            else if (new Date(filterDate).valueOf() >= new Date(directCollectionsRemmittedFilterFromDateRN).valueOf() && new Date(filterDate).valueOf() <= new Date(directCollectionsRemmittedFilterToDateRN).valueOf()) {
                PeriodSelected.push(doc);
            }
        })

        const BFTotalReceipts = BFReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = BFTotalReceipts

        //get BF
        const BTDetails = {
            policyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding,
            docData: {
                transactionType: 'Brought Forward',
                receiptDate: directCollectionsRemmittedFilterFromDateRN,
                receiptNumber: 'BF',
                receiptAmount: 0
            },
        }

        PeriodSelected.unshift(BTDetails);
        setBFReceiptDocs(PeriodSelected);

    }, [receiptDocs, directCollectionsRemmittedFilterFromDateRN, directCollectionsRemmittedFilterToDateRN])

    //################################################################
    // sort by date
    const [dateSortedReceiptDocs, setDateSortedReceiptDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFReceiptDocs.sort(byDate);
        function byDate(a, b) {
            function ab(doc) {
                //declare filter date
                let filterDate = '';
                switch (doc.docData.transactionType) {
                    case 'Receipt':
                        filterDate = new Date(doc.docData.receiptDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Brought Forward':
                        filterDate = new Date(doc.docData.receiptDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                }
                return filterDate;
            }
            return new Date(ab(a)).valueOf() - new Date(ab(b)).valueOf();
        }
        setDateSortedReceiptDocs(sortedList);
    }, [BFReceiptDocs]);

    //################################################################
    //calculate outstanding
    const [calculatedOutstandingReceiptDocs, setCalculatedOutstandingReceiptDocs] = useState([]);
    useEffect(() => {
        if (dateSortedReceiptDocs.length > 0) {
            for (let i = 1; i < dateSortedReceiptDocs.length; i++) {
                const previousIndex = i - 1;

                if (dateSortedReceiptDocs[0].docData.transactionType === 'Receipt') {
                    dateSortedReceiptDocs[0].cummulativeOutstanding = dateSortedReceiptDocs[0].docData.receiptAmount
                }
                else if (dateSortedReceiptDocs[0].docData.transactionType === 'Brought Forward') {
                    dateSortedReceiptDocs[0].cummulativeOutstanding = dateSortedReceiptDocs[0].cummulativeOutstanding;
                }
                if (dateSortedReceiptDocs[i].docData.transactionType === 'Receipt' && dateSortedReceiptDocs.length > 1) {
                    dateSortedReceiptDocs[i].cummulativeOutstanding = +dateSortedReceiptDocs[previousIndex].cummulativeOutstanding + +dateSortedReceiptDocs[i].docData.receiptAmount
                }
            }
        }
        setCalculatedOutstandingReceiptDocs(dateSortedReceiptDocs);
    }, [dateSortedReceiptDocs])

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = []
            calculatedOutstandingReceiptDocs.map((doc) => {

                //look for vr
                const receiptDocuments = [];
                if (doc.docData.receiptDocument) {
                    Object.keys(doc.docData.receiptDocument).map((key) => {
                        receiptDocuments.push(doc.docData.receiptDocument[key]['receiptDocumentDownloadLink']);
                    });
                }

                const receiptAllocationDetails = [];
                if (doc.docData.receiptAllocationDetails) {
                    doc.docData.receiptAllocationDetails.map((allocation) => {
                        receiptAllocationDetails.push(
                            allocation.receivedClientName + ' - ' + allocation.receivedPolicyNumber + ' - ' + allocation.receivedEndorsmentNumber + ' - ' + allocation.receivedAllocatedAmount
                        )
                    })
                }

                let prevReceiptAccount = '';
                let prevReceiptDate = '';
                let prevReceiptNumber = '';
                if (doc.docData.remitanceDetails) {
                    if (Object.keys(doc.docData.remitanceDetails).length > 0) {
                        prevReceiptAccount = doc.docData.remitanceDetails.prevReceiptAccount;
                        prevReceiptDate = doc.docData.remitanceDetails.prevReceiptDate;
                        prevReceiptNumber = doc.docData.remitanceDetails.prevReceiptNumber;
                    }
                }
                if (doc.docData.transactionType === 'Brought Forward') {
                    prevReceiptDate = directCollectionsRemmittedFilterFromDateRN;
                }

                finalObj.push({
                    receiptDate: DateFormat(doc.docData.receiptDate),
                    receiptNumber: doc.docData.receiptNumber,
                    receiptAmount: NumberFormat(doc.docData.receiptAmount),
                    receiptDocuments: receiptDocuments,
                    receiptAllocationDetails: receiptAllocationDetails,
                    cummulativeOutstanding: NumberFormat(doc.cummulativeOutstanding),

                    prevReceiptAccount: prevReceiptAccount,
                    prevReceiptDate: DateFormat(prevReceiptDate),
                    prevReceiptNumber: prevReceiptNumber,
                });
            });

            setFinalData(finalObj);

        } catch (error) { console.log(error) }
    }, [calculatedOutstandingReceiptDocs]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['receiptDate', 'receiptNumber', 'prevReceiptDate', 'prevReceiptNumber', 'receiptAmount', 'cummulativeOutstanding', 'receiptAllocationDetails',]

    const exportToExcelFileName = 'DIRECT COLLECTIONS REMITTED    --    INSURER: ' + directCollectionsRemmittedInsurerFilterRN + '    --    PERIOD: ' + DateFormat(directCollectionsRemmittedFilterFromDateRN) + ' - ' + DateFormat(directCollectionsRemmittedFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.receiptDate,
                doc.receiptNumber,
                doc.prevReceiptDate,
                doc.prevReceiptNumber,
                doc.receiptAmount,
                doc.cummulativeOutstanding,
                doc.receiptAllocationDetails,
            ])
        });


        const onlyDispatchedheaderData = 'DIRECT COLLECTIONS REMITTED    ||    INSURER: ' + directCollectionsRemmittedInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(directCollectionsRemmittedFilterFromDateRN) + ' - ' + DateFormat(directCollectionsRemmittedFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 380],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'RECEIPT DATE', 'RECEIPT NUMBER', 'BROKER RECEIPT DATE', 'BROKER RECEIPT NO', 'RECEIPT AMOUNT', 'CUMMULATIVE OUTSTANDING', 'RECEIPT ALLOCATION DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.receiptDate,
                doc.receiptNumber,
                doc.prevReceiptDate,
                doc.prevReceiptNumber,
                doc.receiptAmount,
                doc.cummulativeOutstanding,
                doc.receiptAllocationDetails,
            ])
        });


        const onlyDispatchedheaderData = 'DIRECT COLLECTIONS REMITTED    ||    INSURER: ' + directCollectionsRemmittedInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(directCollectionsRemmittedFilterFromDateRN) + ' - ' + DateFormat(directCollectionsRemmittedFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 380],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 8, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'RECEIPT DATE', 'RECEIPT NUMBER', 'BROKER RECEIPT DATE', 'BROKER RECEIPT NO', 'RECEIPT AMOUNT', 'CUMMULATIVE OUTSTANDING', 'RECEIPT ALLOCATION DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'receiptNumber', headerName: 'Receipt No', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'receiptDate', headerName: 'Receipt Date', width: 150, sortable: false, filter: false, },
        { field: 'prevReceiptNumber', headerName: 'Broker Receipt No', width: 150, sortable: false, filter: false, },
        { field: 'prevReceiptDate', headerName: 'Broker Receipt Date', width: 150, sortable: false, filter: false, },
        { field: 'receiptAmount', headerName: 'R Amount', width: 150, },
        { field: 'cummulativeOutstanding', headerName: 'C Outsta', width: 150, sortable: false, filter: false, },
        { field: 'receiptAllocationDetails', headerName: 'Details', minWidth: 200, width: 350, },
        {
            field: 'receiptDocuments', headerName: 'Receipt Documents', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Direct Collections Remmitted</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    );
}

export default DirectCollectionsRemmitted;