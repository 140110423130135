import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import db from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import GetAllClaims from "../../Hooks/BackendConn/Claims/GetAllClaims";
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import { useRecoilValue } from "recoil";
import { openClaimsFilterFromDate, openClaimsFilterToDate, openClaimsSelectInsurer } from "../../../atoms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const OpenClaimsGrid = (props) => {
    //################################################################
    // recoil values 
    const openClaimsFilterFromDateRN = useRecoilValue(openClaimsFilterFromDate);
    const openClaimsFilterToDateRN = useRecoilValue(openClaimsFilterToDate);
    const openClaimsSelectInsurerRN = useRecoilValue(openClaimsSelectInsurer);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all claims
    const [claimDocs, setClaimDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClaims();
            setClaimDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get open claims
    const [openClaimsDocs, setOpenClaimDocs] = useState([])
    useEffect(() => {
        const openclaims = claimDocs.filter(doc => doc.docData.stage === 'Open');
        setOpenClaimDocs(openclaims);
    }, [claimDocs])

    //################################################################
    //sort open claims
    const [dateSortedOpenClaimDocs, setDateSortedOpenClaimDocs] = useState([])
    useEffect(() => {
        const sortedList = openClaimsDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.dateOfLoss.seconds * 1000).valueOf() - new Date(b.docData.dateOfLoss.seconds * 1000).valueOf();
        }
        setDateSortedOpenClaimDocs(sortedList);
    }, [openClaimsDocs]);

    //################################################################
    //filter open claims
    const [dateFilteredOpenClaimDocs, setDateFilteredOpenClaimDocs] = useState([])
    useEffect(() => {
        const a = dateSortedOpenClaimDocs.filter(doc => new Date(doc.docData.dateOfLoss.seconds * 1000).valueOf() >= new Date(openClaimsFilterFromDateRN).valueOf() && new Date(doc.docData.dateOfLoss.seconds * 1000).valueOf() <= new Date(openClaimsFilterToDateRN).valueOf());
        setDateFilteredOpenClaimDocs(a)
    }, [dateSortedOpenClaimDocs, openClaimsFilterFromDateRN, openClaimsFilterToDateRN])

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateFilteredOpenClaimDocs.map((doc) => {

                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleDetails) {
                        details.push(doc.docData.vehicleDetails.regNo)
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                //the last status

                let status = '';
                if (doc.docData.status) {
                    const statusArray = Object.values(doc.docData.status);
                    const lastItem = statusArray[statusArray.length - 1];
                    status = lastItem.narration;
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    dateOfLoss: DateFormat(doc.docData.dateOfLoss),
                    natureOfLoss: doc.docData.natureOfLoss,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    status: status
                })

            });

            setFinalData(finalObj)

        } catch (error) { }
    }, [dateFilteredOpenClaimDocs]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'status', 'dateOfLoss', 'policyNumber', 'endorsmentNumber', 'policyClass', 'inceptionDate', 'expiryDate', 'details',]

    const exportToExcelFileName = 'OPEN CLAIMS LIST    --    INSURER: ' + openClaimsSelectInsurerRN + '    --    PERIOD: ' + DateFormat(openClaimsFilterFromDateRN) + ' - ' + DateFormat(openClaimsFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.status || '',
                doc.dateOfLoss,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
            ])
        });

        const onlyDispatchedheaderData = 'OPEN CLAIMS LIST    ||    INSURER: ' + openClaimsSelectInsurerRN + '    ||    PERIOD: ' + DateFormat(openClaimsFilterFromDateRN) + ' - ' + DateFormat(openClaimsFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 80, 25, 'auto', 'auto', 'auto', 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 10, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'STATUS', 'DATE OF LOSS', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.status || '',
                doc.dateOfLoss,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
            ])
        });


        const onlyDispatchedheaderData = 'OPEN CLAIMS LIST    ||    INSURER: ' + openClaimsSelectInsurerRN + '    ||    PERIOD: ' + DateFormat(openClaimsFilterFromDateRN) + ' - ' + DateFormat(openClaimsFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 80, 25, 'auto', 'auto', 'auto', 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 10, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'STATUS', 'DATE OF LOSS', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //  close claim
    // step 3
    const reloadOnSuccessCloseClaim = () => {
        const onePropObj = {
            setView: 'Open Claims',
            previousView: 'Open Claims',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Claim Closed'
        }
        props.onePropDown(onePropObj);
        setIsLoading(false);
        setReload(Math.random(1000, 100000));
    }

    //################################################################
    // step 2
    const closeClaimHandler = async () => {
        try {

            const docRef = doc(db, "Claims", "lqj4Qnx0jn6CJfHQdiff");

            const data = rightClickedPolicyID + '.stage';
            const a = await updateDoc(docRef, {
                [data]: 'Closed'
            });

        } catch (error) { console.log(error) }
        reloadOnSuccessCloseClaim();
    }

    //################################################################
    // step 1
    const closeClaim = () => {
        const executeAbort = ConfirmAction('Confirm You Want To Close This Claim.');
        if (!executeAbort) { return false }

        setIsLoading(true)
        setxPos('-1000px');
        setyPos('-1000px');
        closeClaimHandler();

    }

    //################################################################
    //view claim
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Claim',
            previousView: 'Open Claims',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Claim Details</p></span>
        <div className=''></div>
        <span onClick={closeClaim}><p>Close Claim</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'status', headerName: 'Status', sortable: false, filter: false, width: 90, },
        { field: 'natureOfLoss', headerName: 'Nature Of Loss', sortable: false,minWidth: 230, width: 230, },
        { field: 'dateOfLoss', headerName: 'Date Of Loss', sortable: false, filter: false, width: 100, },        
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', maxWidth: 150, },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section>
            <div className='body_section_header'>
                <p>Open Claims</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default OpenClaimsGrid;