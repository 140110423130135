import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import db from "../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import { useRecoilValue, useRecoilState } from "recoil";
import { DVAllocationSelectedDV, rightNavContent, activeInsurerPortfolio } from "../../../atoms";
import { DVAllocationSelectedDebits, DVAllocationUnselectedDebits } from "../../../atoms";
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import UUID from "../../Hooks/UUID/UUID";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const DVAllocation = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const DVAllocationSelectedDVRN = useRecoilValue(DVAllocationSelectedDV);
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent)
    const [DVAllocationSelectedDebitsRN, setDVAllocationSelectedDebitsRN] = useRecoilState(DVAllocationSelectedDebits)
    const [DVAllocationUnselectedDebitsRN, setDVAllocationUnselectedDebitsRN] = useRecoilState(DVAllocationUnselectedDebits)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const difference = DVAllocationSelectedDebitsRN.filter(item1 => !finalData.some(item2 => item2.docID === item1.docID));
            const finalObj = [];
            difference.map((doc) => {

                //look for details
                const details = [];
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                    commission: NumberFormat(doc.docData.commission),
                    withholdingTax: NumberFormat(doc.docData.withholdingTax),
                    commissionPayable: NumberFormat(doc.docData.commissionPayable),
                    commissionPaid: 0,
                })

            });

            setFinalData([...finalObj, ...finalData])

        } catch (error) { }
    }, [DVAllocationSelectedDebitsRN]);

    //################################################################
    // calculate Total
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0)

    //################################################################
    // selected DV
    const [totalsRow, setTotalsRow] = useState([{ clientName: 'Select A DV' }])
    useEffect(() => {
        try {

            if (Object.keys(DVAllocationSelectedDVRN).length > 0) {
                setTotalsRow([{
                    docID: DVAllocationSelectedDVRN.docID,
                    clientName: DVAllocationSelectedDVRN.docData.policyNumber,
                    policyNumber: 'DV Amounts:',
                    endorsmentNumber: NumberFormat(DVAllocationSelectedDVRN.docData.policyNet),
                    policyClass: '',
                    inceptionDate: '',
                    expiryDate: '',
                    details: '',
                    policyNet: '',
                    commission: totalAllocatedAmount,
                    withholdingTax: 'Remaining Amount:',
                    commissionPayable: NumberFormat(+DVAllocationSelectedDVRN.docData.policyNet - totalAllocatedAmount),
                    commissionPaid: 'Total Allocated:',
                }])
            }

        } catch (error) { }
    }, [DVAllocationSelectedDVRN, totalAllocatedAmount])

    //################################################################
    // post dv
    //step 2
    const showMSGOnSuccessfulDVAllocationSaved = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'DV Reallocation',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'DV Allocation Saved'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const postDVHandler = async () => {
        try {

            setIsLoading(true);
            //if(totalAllocatedAmount !== 0){return false}
            const DVAllocatedDebitDocs = finalData.reduce((result, current) => {
                result[current.docID] = { 'allocatedAmount': current.commissionPaid };
                return result;
            }, {});

            let selectedDVDocID = ''
            if (Object.keys(DVAllocationSelectedDVRN).length > 0) {
                selectedDVDocID = DVAllocationSelectedDVRN.docID;
            }

            const FinalDVAllocationObj = {
                DVAllocatedDebitDocs: DVAllocatedDebitDocs,
                DVDocID: selectedDVDocID,
                dateAllocated: new Date(),
                insurerID: activeInsurerPortfolioRN,
            }

            const sfDocRef = doc(db, "DVAllocations", "pn3firx4ZBkAp2DkzMVR");

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //get unique id
                const DVAllocationID = UUID() + ' - ' + UUID();

                transaction.update(sfDocRef, { [DVAllocationID]: FinalDVAllocationObj });
            });
            //console.log("Transaction successfully committed!");
            showMSGOnSuccessfulDVAllocationSaved();

        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [enteredData, setEnteredData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // remove handler
    const removeDebitHandler = () => {
        try {
            setxPos('-1000px');
            setyPos('-1000px');

            const unselectedValues = [...DVAllocationUnselectedDebitsRN]
            const selectedValues = [...DVAllocationSelectedDebitsRN];

            const c = selectedValues.filter(doc => doc.docID === rightClickedPolicyID)
            const a = unselectedValues.filter(doc => doc.docID === rightClickedPolicyID);
            const b = selectedValues.filter(doc => doc.docID !== rightClickedPolicyID);
            const d = finalData.filter(doc => doc.docID !== rightClickedPolicyID);
            if (a.length == 0) {
                setDVAllocationUnselectedDebitsRN([c[0], ...unselectedValues]);
                setDVAllocationSelectedDebitsRN(b)
                setFinalData(d)
            }

            const sum = d.reduce((acc, cur) => acc + +cur.commissionPaid, 0);
            setTotalAllocatedAmount(sum);

        } catch (error) { }
    }

    useEffect(() => {
        try {

            setFinalData([...enteredData])

        } catch (error) { }
    }, [enteredData])

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={removeDebitHandler}><p>Remove Policy</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onCellValueChanged = (event) => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });

        const sum = data.reduce((acc, cur) => acc + +cur.commissionPaid, 0);
        setTotalAllocatedAmount(sum);

    }


    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'commissionPaid', headerName: 'C. Paid', sortable: false, filter: false, width: 90, editable: true, singleClickEdit: true, },
        { field: 'commission', headerName: 'Commission', sortable: false, filter: false, width: 90, },
        { field: 'withholdingTax', headerName: 'W.Tax', sortable: false, filter: false, width: 90, },
        { field: 'commissionPayable', headerName: 'C.A.T', sortable: false, filter: false, width: 90, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },

        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, sortable: false, filter: false, width: 100, },


    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
                pinnedBottomRowData={totalsRow}
                onCellValueChanged={onCellValueChanged}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>DV Allocation</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRV('DV Allocation Select DV') }}>Select DV</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRV('DV Allocation Allocate DV') }}>Select Debits</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={postDVHandler}>Save Allocation</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    )
}

export default DVAllocation;