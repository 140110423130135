import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import DatePicker from 'react-date-picker';
import { useReactToPrint } from "react-to-print";
import Loader from '../Loader/Loader';
import './AccountsInsurerStatement.css';

const AccountsInsurerStatement = () => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //get user input
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])
    //console.log(fromDate)

    //to
    const [toDate, setToDate] = useState('');

    const [tvalue, onTChange] = useState(new Date());
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //from date echo
    const [echoFromDate, setEchoFromDate] = useState('');
    useEffect(() => {
        const echoFromDay = new Date(fromDate).toLocaleString('en-us', { day: '2-digit' });
        const echoFromMonth = new Date(fromDate).toLocaleString('en-us', { month: 'short' });
        const echoFromYear = new Date(fromDate).getFullYear();
        const echoDate = echoFromDay + '-' + echoFromMonth + '-' + echoFromYear;
        setEchoFromDate(echoDate);
    }, [fromDate]);

    //filter
    const [filterCreteria, setFilterCreteria] = useState('');
    const filterCreteriaChangeHandler = (event) => {
        setFilterCreteria(event.target.value)
    }


    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    // search section
    const filterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' minDate={new Date(2021, 4, 1)} />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' minDate={new Date(2021, 5, 1)} />
                    </div>
                </div>
                <div>
                    <div className='h_15'></div>
                    <p>Filter Policy Class</p>
                    <select onChange={filterCreteriaChangeHandler}>
                        <option>All Policies</option>
                        <option>Fully Paid</option>
                        <option>Outstanding</option>
                    </select>
                </div>
            </div>
        </div>
    </div>


    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    // assign date
                    let UDate = '';
                    let echoDate = '';
                    let policyClientName = '';
                    let filterDate = '';
                    if (doc.data().transactionType === 'Receipt') {
                        UDate = doc.data().receiptDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName[0];
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Debit') {
                        UDate = doc.data().insurerDebitDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    else if (doc.data().transactionType === 'Credit') {
                        UDate = doc.data().insurerDebitDate;
                        const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
                        const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(UDate).getFullYear();
                        echoDate = day + '-' + month + '-' + year;
                        policyClientName = doc.data().clientName;
                        //to be used to filter
                        const fYear = new Date(UDate).getFullYear();
                        const fMonth = new Date(UDate).getMonth() + 1;
                        const fDate = new Date(UDate).getDate();
                        filterDate = fYear + '-' + fMonth + '-' + fDate
                    }
                    const allPolicyDocs = {
                        policyClientName: policyClientName,
                        policyPolicyNumber: doc.data().policyNumber,
                        policyEndorsmentNumber: doc.data().endorsmentNumber,
                        policySelectedProductSubClass: doc.data().selectedProductSubClass,
                        policyInceptionDate: doc.data().inceptionDate,
                        policyPolicyNet: doc.data().policyNet,
                        policyOutstanding: doc.data().outstanding,
                        policyTransactionType: doc.data().transactionType,
                        policyInceptionDate: doc.data().inceptionDate,
                        policyLocationOfTheRisk: doc.data().locationOfTheRisk,
                        policyVehicleItems: doc.data().vehicleItems,
                        policyPremium: doc.data().premium,
                        policyPVTPremium: doc.data().PVTPremium,
                        policyStampDuty: doc.data().stampDuty,
                        policyTLevy: doc.data().TLevy,
                        policyPHCFund: doc.data().PHCFund,
                        policyEarthquake: doc.data().earthquake,
                        policyExcessProtector: doc.data().excessProtector,
                        policyWindScreen: doc.data().windScreen,
                        policyRadioCasset: doc.data().radioCasset,
                        policyComesaCard: doc.data().comesaCard,
                        policyCommission: doc.data().commission,
                        policyCommissionPayable: doc.data().commissionPayable,
                        policyWithholdingTax: doc.data().withholdingTax,
                        policyInsurerPolicyNet: doc.data().insurerPolicyNet,
                        policyStatus: doc.data().status,
                        receiptAllocationDetails: doc.data().receiptAllocationDetails,
                        receiptAmount: doc.data().receiptAmount,
                        receiptNumber: doc.data().receiptNumber,
                        receiptDate: doc.data().receiptDate,
                        uDate: UDate,
                        echoDate: echoDate,
                        filterDate: filterDate,
                        cummulativeOutstanding: ''
                    }
                    Rdata.push(allPolicyDocs);
                    setIsloading(false);
                },
                    setReceivedPolicyDocs(Rdata)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    //bf feature
    const [BFPolicyDocs, setBFPolicyDocs] = useState([]);
    useEffect(() => {
        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];


        receivedPolicyDocs.map((item, index) => {
            if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Debit') {
                BFDebits.push(item.policyInsurerPolicyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Credit') {
                BFCreditsReceipts.push(item.policyInsurerPolicyNet);
            }
            else if (new Date(fromDate).valueOf() > new Date(item.uDate).valueOf() && item.policyTransactionType === 'Receipt') {
                BFCreditsReceipts.push(item.receiptAmount);
            }
            // else if (new Date(item.uDate).valueOf() >= new Date(fromDate).valueOf() && new Date(item.uDate).getTime() <= new Date(tooDate).getTime() ) {
            //     PeriodSelected.push(item);
            // }
            else if (new Date(item.filterDate).valueOf() >= new Date(fromDate).valueOf() && new Date(item.filterDate).valueOf() <= new Date(toDate).valueOf()) {
                PeriodSelected.push(item);
            }
        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            uDate: fromDate,
            echoDate: echoFromDate,
            policyPolicyNumber: 'BF',
            policyPolicyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding
        }

        PeriodSelected.unshift(BTDetails);
        setBFPolicyDocs(PeriodSelected)

    }, [receivedPolicyDocs, fromDate, toDate, echoFromDate])
    //     let qw = '2022-08-31'
    //     qw = new Date(qw.replace(/-/g, "/"));
    //     let qwe = '2022-08-31'
    //     qwe = new Date(qwe.replace(/-/g, "/"));
    //    console.log(qw)
    //    console.log(qwe)
    //if(new Date('2022-08-31').valueOf() <= new Date('2022-08-31').valueOf()){console.log('kkkkk')}
    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.uDate).valueOf() - new Date(b.uDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [BFPolicyDocs]);



    //calculate outstanding
    const [outstandingMap, setOutstandingMap] = useState([]);
    useEffect(() => {
        if (sortedPolicyDocs.length > 0) {
            for (let i = 1; i < sortedPolicyDocs.length; i++) {
                const previousIndex = i - 1;

                if (sortedPolicyDocs[0].policyTransactionType === 'Receipt') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].receiptAmount
                }
                else if (sortedPolicyDocs[0].policyPolicyNumber === 'BF') {
                    sortedPolicyDocs[0].cummulativeOutstanding = sortedPolicyDocs[0].cummulativeOutstanding;
                }
                else if (sortedPolicyDocs[0].policyTransactionType === 'Credit') {
                    sortedPolicyDocs[0].cummulativeOutstanding = -sortedPolicyDocs[0].policyInsurerPolicyNet;
                }
                else {
                    sortedPolicyDocs[0].cummulativeOutstanding = sortedPolicyDocs[0].policyInsurerPolicyNet
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Debit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding + +sortedPolicyDocs[i].policyInsurerPolicyNet
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Receipt' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].receiptAmount
                }
                if (sortedPolicyDocs[i].policyTransactionType === 'Credit' && sortedPolicyDocs.length > 1) {
                    sortedPolicyDocs[i].cummulativeOutstanding = +sortedPolicyDocs[previousIndex].cummulativeOutstanding - +sortedPolicyDocs[i].policyInsurerPolicyNet
                }
            }
        }
        setOutstandingMap(sortedPolicyDocs);
    }, [sortedPolicyDocs])

    //get totals
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalPVTPremium, setTotalPVTPremium] = useState(0);
    const [totalStampDuty, setTotalStampDuty] = useState(0);
    const [totalTLevy, setTotalTLevy] = useState(0);
    const [totalPHCFund, setTotalPHCFund] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalWithholding, setTotalWithholding] = useState(0);
    const [totalPolicyNet, setTotalPolicyNet] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);

    useEffect(() => {
        const totalPremiumItems = [];
        const totalPVTPremiumItems = [];
        const totalStampDutyItems = [];
        const totalTLevyItems = [];
        const totalPHCFundItems = [];
        const totalCommissionItems = [];
        const totalWithholdingTaxItems = [];
        const totalPolicyNetItems = [];
        const totalReceiptsItems = [];

        outstandingMap.map((item, index) => {
            if (item.policyTransactionType === 'Debit' && !item.policyPolicyNumber.startsWith('JV') && !item.policyPolicyNumber.startsWith('DV') && !item.policyPolicyNumber.startsWith('Rev')) {
                totalPremiumItems.push(item.policyPremium)
                totalPremiumItems.push(item.policyEarthquake)
                totalPremiumItems.push(item.policyExcessProtector)
                totalPremiumItems.push(item.policyWindScreen)
                totalPremiumItems.push(item.policyRadioCasset)
                totalPremiumItems.push(item.policyComesaCard)
                totalPVTPremiumItems.push(item.policyPVTPremium)
                totalStampDutyItems.push(item.policyStampDuty)
                totalTLevyItems.push(item.policyTLevy)
                totalPHCFundItems.push(item.policyPHCFund)
                totalCommissionItems.push(item.policyCommission)
                totalWithholdingTaxItems.push(item.policyWithholdingTax)
                totalPolicyNetItems.push(item.policyInsurerPolicyNet)
            }
            else if (item.policyTransactionType === 'Credit' && !item.policyPolicyNumber.startsWith('CN')) {
                totalPremiumItems.push(-item.policyPremium)
                totalPremiumItems.push(-item.policyEarthquake)
                totalPremiumItems.push(-item.policyExcessProtector)
                totalPremiumItems.push(-item.policyWindScreen)
                totalPremiumItems.push(-item.policyRadioCasset)
                totalPremiumItems.push(-item.policyComesaCard)
                totalPVTPremiumItems.push(-item.policyPVTPremium)
                totalStampDutyItems.push(-item.policyStampDuty)
                totalTLevyItems.push(-item.policyTLevy)
                totalPHCFundItems.push(-item.policyPHCFund)
                totalCommissionItems.push(-item.policyCommission)
                totalWithholdingTaxItems.push(-item.policyWithholdingTax)
                totalPolicyNetItems.push(-item.policyInsurerPolicyNet)
            }
            else if (item.policyTransactionType === 'Receipt') {
                totalReceiptsItems.push(item.receiptAmount)
            }
            else if (item.policyPolicyNumber.startsWith('JV') || item.policyPolicyNumber.startsWith('DV') || item.policyPolicyNumber.startsWith('Rev')) {
                totalReceiptsItems.push(-item.policyInsurerPolicyNet)
            }
            else if (item.policyPolicyNumber.startsWith('CN')) {
                totalReceiptsItems.push(item.policyInsurerPolicyNet)
            }
        })
        //calc
        const a = totalPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPremium(a);
        const b = totalPVTPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPVTPremium(b);
        const c = totalStampDutyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalStampDuty(c);
        const d = totalTLevyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalTLevy(d);
        const e = totalPHCFundItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPHCFund(e);
        const f = totalCommissionItems.reduce((total, item) => { return total + +item }, 0);
        setTotalCommission(f);
        const g = totalWithholdingTaxItems.reduce((total, item) => { return total + +item }, 0);
        setTotalWithholding(g);
        const h = totalPolicyNetItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPolicyNet(h);
        const i = totalReceiptsItems.reduce((total, item) => { return total + +item }, 0);
        setTotalReceipts(i);

    }, [outstandingMap])

    //insurer stmt
    const insurerStatementContent = <section className='insurer_statement_category'>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Insurer Statement</p>
                <div className='client_controls'>
                    <span className='btn_yellow' onClick={() => showHideFilterRightNav('Show')}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='insurer_statement_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Insured</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Premium</p></td>
                                <td><p>PVT Premium</p></td>
                                <td><p>Stamp Duty</p></td>
                                <td><p>T Levy</p></td>
                                <td><p>PHC Fund</p></td>
                                <td><p>Comm</p></td>
                                <td><p>W Tax</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Credit Net</p></td>
                                <td><p>Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {outstandingMap.map((item, index) => {
                                const pTotals = +item.policyPremium + +item.policyEarthquake + +item.policyExcessProtector + +item.policyWindScreen + +item.policyRadioCasset + +item.policyComesaCard;
                                return (
                                    <tr key={index}>
                                        <td><p>{index + 1}</p></td>
                                        <td><p>{item.echoDate}</p></td>
                                        <td><p>{item.policyTransactionType}</p></td>
                                        <td><p>{item.policyClientName}</p></td>
                                        <td><p>{item.policyPolicyNumber}{item.receiptNumber}</p></td>
                                        <td><p>{item.policyEndorsmentNumber}</p></td>
                                        <td><p>
                                            { 
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyPremium ? '(' + parseInt(pTotals).toLocaleString("en-US") + ')' : ''
                                                    : item.policyPremium ? parseInt(pTotals).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyPVTPremium ? '(' + parseInt(item.policyPVTPremium).toLocaleString("en-US") + ')' : ''
                                                    : item.policyPVTPremium ? parseInt(item.policyPVTPremium).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyStampDuty ? '(' + parseInt(item.policyStampDuty).toLocaleString("en-US") + ')' : ''
                                                    : item.policyStampDuty ? parseInt(item.policyStampDuty).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyTLevy ? '(' + parseInt(item.policyTLevy).toLocaleString("en-US") + ')' : ''
                                                    : item.policyTLevy ? parseInt(item.policyTLevy).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyPHCFund ? '(' + parseInt(item.policyPHCFund).toLocaleString("en-US") + ')' : ''
                                                    : item.policyPHCFund ? parseInt(item.policyPHCFund).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyCommission ? '(' + parseInt(item.policyCommission).toLocaleString("en-US") + ')' : ''
                                                    : item.policyCommission ? parseInt(item.policyCommission).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyTransactionType === 'Credit'
                                                    ? item.policyWithholdingTax ? '(' + parseInt(item.policyWithholdingTax).toLocaleString("en-US") + ')' : ''
                                                    : item.policyWithholdingTax ? parseInt(item.policyWithholdingTax).toLocaleString("en-US") : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                item.policyPolicyNumber
                                                    ? item.policyPolicyNumber.startsWith('JV') || item.policyPolicyNumber.startsWith('DV') || item.policyPolicyNumber.startsWith('CN') || item.policyPolicyNumber.startsWith('Rev')
                                                        ? ''
                                                        : item.policyInsurerPolicyNet
                                                            ? item.policyTransactionType === 'Credit'
                                                                ? '(' + parseInt(item.policyInsurerPolicyNet).toLocaleString("en-US") + ')'
                                                                : parseInt(item.policyInsurerPolicyNet).toLocaleString("en-US")
                                                            : ''
                                                    : ''
                                            }
                                        </p></td>
                                        <td><p>
                                            {item.receiptAmount ? '(' + parseInt(item.receiptAmount).toLocaleString("en-US") + ')' : ''}
                                            {
                                                item.policyPolicyNumber
                                                    ? item.policyPolicyNumber.startsWith('CN')
                                                        ? item.policyInsurerPolicyNet ? '(' + parseInt(item.policyInsurerPolicyNet).toLocaleString("en-US") + ')' : ''
                                                        : ''
                                                    : ''
                                            }
                                            {
                                                item.policyPolicyNumber
                                                    ? item.policyPolicyNumber.startsWith('JV') || item.policyPolicyNumber.startsWith('DV') || item.policyPolicyNumber.startsWith('Rev')
                                                        ? item.policyInsurerPolicyNet ? parseInt(item.policyInsurerPolicyNet).toLocaleString("en-US") : ''
                                                        : ''
                                                    : ''
                                            }
                                        </p></td>
                                        <td><p>{parseInt(item.cummulativeOutstanding).toLocaleString("en-US")}</p></td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan='5'><p></p></td>
                                <td><p>Totals</p></td>
                                <td><p>{parseInt(totalPremium).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPVTPremium).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalStampDuty).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalTLevy).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPHCFund).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalCommission).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalWithholding).toLocaleString("en-US")}</p></td>
                                <td><p>{parseInt(totalPolicyNet).toLocaleString("en-US")}</p></td>
                                <td><p>{'(' + parseInt(totalReceipts).toLocaleString("en-US") + ')'}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>


    return (
        <section className='insurer_statement'>
            <div className='body_section_header'>
                <p>Insurer Statement</p>
            </div>
            <div className='body_section'>
                <div className='h_5'></div>
                {insurerStatementContent}
            </div>
            {filterContent}
            {loaderContent}
        </section>
    );
}

export default AccountsInsurerStatement;