import React, { useRef, useState } from "react";
import db from "../../../../firebase";
import { runTransaction, doc } from "firebase/firestore";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeInsurerPortfolio, reloadOnSuccessPolicyCategoryCreationHandler } from "../../../../atoms";
import Loader from "../../../Body/Loader/Loader";

const CreatePolicyCategory = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const [reloadOnSuccessPolicyCategoryCreationHandlerRN, setReloadOnSuccessPolicyCategoryCreationHandlerRN] = useRecoilState(reloadOnSuccessPolicyCategoryCreationHandler)

    //################################################################
    // get user input
    const categoryNameRef = useRef(null);

    //################################################################
    // show fail msg
    const displayFailMSG = () => {
        const onePropObj = {
            setView: 'Policy Categories',
            previousView: 'Policy Categories',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Class Already Exist',
        }
        props.onePropDown(onePropObj);
    }

    //################################################################
    // show success msg
    const displaySuccessCreateMSG = () => {
        setReloadOnSuccessPolicyCategoryCreationHandlerRN(true);

        const onePropObj = {
            setView: 'Policy Categories',
            previousView: 'Policy Categories',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Class Created Successfully.',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    // post data
    const createPolicyCategoryHandler = async () => {
        try {
            if (!categoryNameRef.current.value) {
                return false
            }

            setIsLoading(true);

            const sfDocRef = doc(db, "InsurerPortfolio", "YleFvRHWa48KYHcH650N");

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const allData = sfDoc.data();
                const insurerData = (allData[activeInsurerPortfolioRN]['policyCategories']);

                if (!Object.keys(insurerData).includes(categoryNameRef.current.value)) {
                    const newObj = { ...insurerData, [categoryNameRef.current.value]: {} }
                    const a = activeInsurerPortfolioRN + '.policyCategories'
                    transaction.update(sfDocRef, { [a]: newObj });
                    displaySuccessCreateMSG();
                }
                else { displayFailMSG(); }

            });

            setIsLoading(false);

        } catch (e) { console.log("Transaction failed: ", e); }

    }



    //################################################################
    // crete insurer Content
    const createInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Create Policy Category</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input ref={categoryNameRef} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Category Name</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createPolicyCategoryHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {createInsurerContent}
            {loaderContent}
        </section>
    )
}

export default CreatePolicyCategory;