import { useEffect, useState, useMemo } from 'react';
import './Body.css';
import './BodyInputStyles.css';
import { useRecoilValue, useRecoilState } from 'recoil';
import { activeTabs, rightNavContent } from '../../atoms';
import BodyCreateClient from './BodyClients/BodyCreateClient';
import BodyClientPortfolio from './BodyClients/BodyClientPortfolio';
import BodyCreatePolicy from './BodyPolicy/BodyCreatePolicy';
import BodyEndorsment from './BodyPolicy/BodyEndorsment';
import BodyPolicyPortfolio from './BodyPolicy/BodyPolicyPortfolio';
import BodyRiskNote from './BodyPolicy/BodyRiskNote';
import BodyViewPolicy from './BodyPolicy/BodyViewPolicy';
import BodyViewPolicyTwo from './BodyPolicy/BodyViewPolicyTwo';
import BodyCreditNote from './BodyPolicy/BodyCreditNote';
import BodyPostReceipts from './BodyReceipts/BodyPostReceipts';
import AccountsClientStatement from './BodyStatements/AccountsClientStatement';
import AccountsClientStatementTwo from './BodyStatements/AccountsClientStatementTwo';
import AccountsInsurerStatement from './BodyStatements/AccountsInsurerStatement';
import BodyEditPolicy from './BodyPolicy/BodyEditPolicy';
import BodyRenewPolicy from './BodyPolicy/BodyRenewPolicy';
import BodyReceiptPortfolio from './BodyReceipts/BodyReceiptPortfolio';
import Demo from './BodyPolicy/Demo';
import ReportPolicyClass from './BodyReports/ReportPolicyClass';
import BodyPendingList from './BodyPolicy/BodyPendingList';
import BodyViewClient from './BodyClients/BodyViewClient';
import BodyEditClient from './BodyClients/BodyEditClient';
import RenewalReport from './BodyReports/RenewalReport';
import ClientsAccountsOverview from './BodyStatements/ClientsAccountsOverview';
import Overview from './BodyStatements/Overview';
import BodyPendingListEdit from './BodyPolicy/BodyPendingListEdit';
import SystemLogs from './BodyDashboard/SystemLogs/SystemLogs';
import BodyViewPolicyFinal from './BodyPolicy/BodyViewPolicyFinal';
import BodyEndorsmentFinal from './BodyPolicy/BodyEndorsmentFinal';
import BodyRefundFinal from './BodyPolicy/BodyRefundFinal';
import BodyRenewalFinal from './BodyPolicy/BodyRenewFinal';
import DispatchAccountsClearance from './BodyDispatch/DispatchAccountsClearance';
import Dispatch from './BodyDispatch/Dispatch';
import PrintClientStatement from './BodyStatements/PrintClientStatement';
import RenewedReport from './BodyReports/RenewedReport';
import UnrenewedReport from './BodyReports/UnrenewedReport';
import MotorReport from './BodyReports/MotorReport';
import BodyLapsedPolicy from './BodyPolicy/BodyLapsedPolicy';
import BodyLapsedPolicyView from './BodyPolicy/BodyLapsedPolicyView';
import BodyCancelledPolicy from './BodyPolicy/BodyCancelledPolicy';
import BodyCancelledPolicyView from './BodyPolicy/BodyCancelledPolicyView';
import CreditNoteAllocations from './BodyPolicy/CreditNoteAllocations';
import BodyPostNewReceipt from './BodyReceipts/BodyPostNewReceipt';
import BodyReceiptList from './BodyReceipts/BodyReceiptList';
import TotalCommission from './BodyCommission/TotalCommission';
import CommissionRequest from './BodyCommission/CommissionRequest';
import RegisterAClaim from './BodyClaims/RegisterAClaim';
import BodyPolicySearch from './BodyPolicy/BodyPolicySearch';
import OpenClaims from './BodyClaims/OpenClaims';
import ClosedClaims from './BodyClaims/ClosedClaims';
import ViewClaim from './BodyClaims/ViewClaim';
import PolicyDocument from './BodyReports/PolicyDocuments';
import ValuationReports from './BodyReports/ValuationReports';
import LogBookReport from './BodyReports/LogBookReport';
import StickerCopyReport from './BodyReports/StickerCopyReport';
import ConclusiveMotorReport from './BodyReports/ConclusiveMotorReport';
import InsurerPortfolio from './BodyDashboard/InsurerPortfolio';
import InsurerStatementFinal from './BodyStatements/InsurerStatementFinal';
import ClientGroup from './BodyClients/ClientGroup';
import ViewReceipt from './BodyReceipts/ViewReceipt';
import AgentStatement from './BodyStatements/AgentStatement';
import DVList from './BodyExtras/DVList';
import BusinessReport from './BodyReports/BusinessReport';
import CNList from './BodyExtras/CNList';
import JVList from './BodyExtras/JVList';
import PostDV from './BodyExtras/PostDV';
import ViewDV from './BodyExtras/ViewDV';
import PostCN from './BodyExtras/PostCN';
import ViewCN from './BodyExtras/ViewCN';
import PostJV from './BodyExtras/PostJV';
import ViewJV from './BodyExtras/ViewJV';
import PendingListFinal from './BodyPolicy/PendingListFinal';
import TabsFeature from '../Dashboard/TabsFeature';
import Loader from './Loader/Loader';
import CancelledPolicyListFinal from './BodyPolicy/CancelledPolicyListFinal';
import GridExample from './GridExample';
import OverviewFinal from './BodyStatements/OverviewFinal';
import PendingListGrid from './BodyPolicy/PendingListGrid';
import ClientGroupFinal from './BodyClients/ClientGroupFinal';
import InsurerStatementGrid from './BodyStatements/InsurerStatementGrid';
import ValuationReportsGrid from './BodyReports/ValuationReportsGrid';
import PolicyListGrid from './BodyPolicy/PolicyListGrid';
import CreateQuote from './BodyQuotes/CreateQuote';
import RenewedReportGrid from './BodyReports/RenewedReportGrid';
import OpenClaimsGrid from './BodyClaims/OpenClaimsGrid';
import CreateActionMemo from './BodyActionMemo/CreateActionMemo';
import OpenActionMemo from './BodyActionMemo/OpenActionMemo';
import ClosedActionMemo from './BodyActionMemo/ClosedActionMemo';
import PolicyDocumentsReport from './BodyReports/PolicyDocumentsReport';
import LogBookReportGrid from './BodyReports/LogBookReportGrid';
import StickerCopyReportGrid from './BodyReports/StickerCopyReportGrid';
import ValuationReportsGridFinal from './BodyReports/ValuationReportsGridFinal';
import UnrenewedReportGrid from './BodyReports/UnrenewedReportGrid';
import ReceivedDocumentsReport from './BodyReports/ReceivedDocumentsReport';
import PolicyCategories from './BodyPolicyCategories/PolicyCategories';
import MainScreen from '../Dashboard/MainScreen';
import ActivityLog from './BodyDashboard/ActivityLog';
import ClientListGrid from './BodyClients/ClientListGrid';
import EditClientFinal from './BodyClients/EditClientFinal';
import ProductivityReport from './BodyDashboard/ProductivityReport';
import BrokerStatement from './BodyStatements/BrokerStatement';
import ReceiptListGrid from './BodyReceipts/ReceiptListGrid';
import DirectCollectionsRemmitted from './BodyStatements/DirectCollectionsRemmitted';
import DVAllocation from './BodyExtras/DVAllocation';
import ViewDVAllocation from './BodyExtras/ViewDVAllocation';
import PaidCommission from './BodyCommission/PaidCommission';
import DVReallocation from './BodyExtras/DVReallocation';
import Quotes from './BodyQuotes/Quotes';
import HumanResourse from './BodyHR/HumanResource';
import CreateExpense from './BodyFinance/CreateExpense';
import ExpensesList from './BodyFinance/ExpensesList';
import PettyCashReport from './BodyFinance/PettyCashReport';
import CreateEmployee from './BodyHR/CreateEmployee';
import MainScreenFinal from '../Dashboard/MainScreenFinal';
import PayroleSettings from './BodyHR/PayroleSettings';
import EmployeeProfile from './BodyHR/EmployeeProfile';
import PayroleDataGenerator from './BodyHR/PayroleDataGenerator';
import AccessControl from './BodyAccessControl/AccessControl';
import RealTimeBalance from './BodyReceipts/RealTimeBalance';
import CommissionReportFinal from './BodyCommission/CommissionReportFinal';
import EmployeeNotification from './BodyHR/EmployeeNotification';
import ViewExpense from './BodyFinance/ViewExpense';
import PendingBills from './BodyFinance/PendingBills';
import ViewPendingBill from './BodyFinance/ViewPendingBill';
import PettyCashVoucher from './BodyFinance/PettyCashVoucher';
import ProfitAndLoss from './BodyFinance/ProfitAndLoss';
import VendorProfile from './BodyFinance/VendorProfile';
import PurchaseOrder from './BodyFinance/PurchaseOrder';
import PostPayment from './BodyFinance/PostPayment';
import VendorStatement from './BodyFinance/VendorStatement';
import FinanceControls from './BodyFinance/FinanceControls';
import Income from './BodyFinance/Income';
import EmailClientStatement from './BodyStatements/EmailClientStatement';
import BiometricsData from './BodyHR/BiometricsData';
import BodyComesaPolicy from './BodyPolicy/BodyComesaPolicy';
import UnrenewedReportGridFinal from './BodyReports/UnrenewedReportGridFinal';
import GeneratePayroll from './BodyHR/GeneratePayroll';
import Payroll from './BodyHR/Payroll';
import AgentStatementGrid from './BodyStatements/AgentStatementGrid';
import BankRecon from './BodyFinance/BankRecon';

const Body = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    const successPolicyCreate = (viewPolicyCommand) => {
        props.onSuccessPolicyCreate(viewPolicyCommand);
    }

    const successCreateClientHandler = (viewClientPortfolioCommand) => {
        props.successCreateClient(viewClientPortfolioCommand);
    }

    const passEndorsmentHandler = (dataP) => {
        props.passEndorsment(dataP)
    }

    let content = '';


    //one prop
    const onePropDownHandler = (receivedData) => {
        props.onePropDown(receivedData)
    }


    if (Object.keys(props.onePropUp).length !== 0) {
        switch (props.onePropUp.setView) {
            case 'Main Screen':
                content = <MainScreenFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Pending List Edit':
                content = <BodyPendingListEdit onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Pending List Grid':
                content = <PendingListGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Pending List Final':
                content = <PendingListFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'System Logs':
                content = <ActivityLog onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Productivity Report':
                content = <ProductivityReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Access Control':
                content = <AccessControl onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Create Policy':
                content = <BodyCreatePolicy onePropDown={onePropDownHandler} />
                break;
            case 'Policy Portfolio':
                content = <BodyPolicyPortfolio onePropDown={onePropDownHandler} />;
                break;
            case 'Policy List Grid':
                content = <PolicyListGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View Policy Final':
                content = <BodyViewPolicyFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Policy Search':
                content = <BodyPolicySearch onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Comesa Policies':
                content = <BodyComesaPolicy onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Body Endorsment Final':
                content = <BodyEndorsmentFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Body Refund Final':
                content = <BodyRefundFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Body Renewal Final':
                content = <BodyRenewalFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            // dispatch
            case 'Accounts Clearance':
                content = <DispatchAccountsClearance onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Demo':
                content = <Demo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Agewise Outstanding':
                content = <OverviewFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Client Statement':
                content = <AccountsClientStatementTwo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Bulk Print Statement':
                content = <PrintClientStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            // case 'Agent Statement':
            //     content = <BrokerStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
            //     break;
            case 'Agent Statement':
                content = <AgentStatementGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Insurer Statement Final':
                content = <InsurerStatementFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Insurer Statement Grid':
                content = <InsurerStatementGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Direct Collections Unremmitted':
                content = <AgentStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Direct Collections Remmitted':
                content = <DirectCollectionsRemmitted onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Email Client Statement':
                content = <EmailClientStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //renewals
            case 'Renewed Report':
                content = <RenewedReportGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Unrenewed Report':
                content = <UnrenewedReportGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            // case 'Unrenewed Report':
            //     content = <UnrenewedReportGridFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
            //     break;
            case 'Lapsed Policies':
                content = <BodyLapsedPolicy onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Lapsed Policy View':
                content = <BodyLapsedPolicyView onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Cancelled Policies':
                content = <CancelledPolicyListFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            // case 'Cancelled Policies':
            //     content = <GridExample onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
            //     break;
            case 'Cancelled Policy View':
                content = <BodyCancelledPolicyView onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //accounts
            case 'Real Time Balance':
                content = <RealTimeBalance onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Credit Note Allocations':
                content = <CreditNoteAllocations onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Post New Receipt':
                content = <BodyPostNewReceipt onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Receipt List':
                content = <ReceiptListGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View Receipt':
                content = <ViewReceipt onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Total Commission':
                content = <TotalCommission onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            // case 'Partially Paid':
            //     content = <PartiallyPaid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
            //     break;Paid Commission
            case 'Commission Request':
                content = <CommissionRequest onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Commission Report Final':
                content = <CommissionReportFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Paid Commission':
                content = <PaidCommission onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //claims
            case 'Register A Claim':
                content = <RegisterAClaim onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View Claim':
                content = <ViewClaim onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Open Claims':
                content = <OpenClaimsGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Closed Claims':
                content = <ClosedClaims onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //reports
            case 'Policy Documents Report':
                content = <PolicyDocumentsReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Valuation Reports':
                content = <ValuationReportsGridFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Logbook Report':
                content = <LogBookReportGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Sticker Copy Report':
                content = <StickerCopyReportGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Conclusive Motor Report':
                content = <ConclusiveMotorReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Business Report':
                content = <BusinessReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Received Documents Report':
                content = <ReceivedDocumentsReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //dashbord
            case 'Insurer Portfolio':
                content = <InsurerPortfolio onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Policy Categories':
                content = <PolicyCategories onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //clients
            case 'Client Group':
                content = <ClientGroupFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //extras
            case 'DV List':
                content = <DVList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'CN List':
                content = <CNList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'JV List':
                content = <JVList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Post DV':
                content = <PostDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Post CN':
                content = <PostCN onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Post JV':
                content = <PostJV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View DV':
                content = <ViewDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View CN':
                content = <ViewCN onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View JV':
                content = <ViewJV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'DV Allocation':
                content = <DVAllocation onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View DV Allocation':
                content = <ViewDVAllocation onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'DV Reallocation':
                content = <DVReallocation onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Tabs':
                content = <TabsFeature onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Create Client':
                content = <BodyCreateClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
                break;
            case 'Edit Client Final':
                content = <EditClientFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
                break;
            // case 'Client Portfolio':
            //     content = <BodyClientPortfolio onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
            //     break;
            case 'Client List':
                content = <ClientListGrid onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'View Client':
                content = <BodyViewClient postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Edit Client':
                content = <BodyEditClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            //quotes
            case 'Create Quote':
                content = <CreateQuote onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Quotes List':
                content = <Quotes onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            //quotes
            case 'Create Action Memo':
                content = <CreateActionMemo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Open Action Memo':
                content = <OpenActionMemo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Closed Action Memo':
                content = <ClosedActionMemo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            //hr
            case 'Human Resource':
                content = <HumanResourse onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Create Employee':
                content = <CreateEmployee onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Payrole Settings':
                content = <PayroleSettings onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Employee Profile':
                content = <EmployeeProfile onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Payrole Data Generator':
                content = <PayroleDataGenerator onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Employee Notification':
                content = <EmployeeNotification onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Generate Payroll':
                content = <GeneratePayroll onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Payroll':
                content = <Payroll onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Biometrics Data':
                content = <BiometricsData onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            //finance
            case 'Create Expense':
                content = <CreateExpense onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'View Expense':
                content = <ViewExpense onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Expenses List':
                content = <ExpensesList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Petty Cash Report':
                content = <PettyCashReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'View Pending Bill':
                content = <ViewPendingBill onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Petty Cash Voucher':
                content = <PettyCashVoucher onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Profit & Loss':
                content = <ProfitAndLoss onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Vendor Profile':
                content = <VendorProfile onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Purchase Order':
                content = <PurchaseOrder onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Post Payment':
                content = <PostPayment onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Vendor Statement':
                content = <VendorStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Finance Controls':
                content = <FinanceControls onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Income':
                content = <Income onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
            case 'Bank Recon':
                content = <BankRecon onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
        }
    }

    else {
        switch (props.activeTextNavMenu) {
            case 'Create Client':
                content = <BodyCreateClient successCreateClient={successCreateClientHandler} />
                break;
            case 'Client Statement':
                content = <AccountsClientStatementTwo postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} backFeatureObj={props.backFeatureObj} />;
                break;
            case 'View Policy':
                content = <BodyViewPolicy postedDocRef={props.postedDocRef} passEndorsment={passEndorsmentHandler} />;
                break;
            case 'View Policy Two':
                content = <BodyViewPolicyTwo postedDocRef={props.postedDocRef} passEndorsment={passEndorsmentHandler} />;
                break;
            case 'Client Portfolio':
                content = <BodyClientPortfolio postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Insurer Statement':
                content = <AccountsInsurerStatement />;
                break;
            case 'Pass Endorsment':
                content = <BodyEndorsment postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Credit Note':
                content = <BodyCreditNote postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Edit Policy':
                content = <BodyEditPolicy postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Renew Policy':
                content = <BodyRenewPolicy postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Receipt Portfolio':
                content = <BodyReceiptPortfolio />;
                break;
            case 'Filter Policy Class':
                content = <ReportPolicyClass />;
                break;
            case 'Post Receipt':
                content = <BodyPostReceipts onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'View Receipt':
                content = <BodyPostReceipts onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Pending List':
                content = <BodyPendingList onePropDown={onePropDownHandler} />;
                break;
            case 'View Client':
                content = <BodyViewClient postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Edit Client':
                content = <BodyEditClient postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Demo':
                content = <Demo />;
                break;
            case 'Renewal Report':
                content = <RenewalReport postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />;
                break;
            case 'Agewise Outstanding':
                // content = <ClientsAccountsOverview />;
                content = <Overview onSuccessPolicyCreate={successPolicyCreate} backFeatureObj={props.backFeatureObj} />;
                break;
        }
    }

    const renderViewHandler = (setview) => {
        switch (setview) {
            //################################################################
            //dashboard
            case 'System Logs':
                content = <SystemLogs onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />;
                break;
        }
    }


    const logFruit = {
        // '': () => ,
        // '': () => ,
        //################################################################
        //dashboard
        'System Logs': () => <SystemLogs onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Client Group': () => <ClientGroup onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,

        //################################################################
        //underwriting
        // '': () => ,
        // '': () => ,
        'Create Client': () => <BodyCreateClient successCreateClient={successCreateClientHandler} />,
        'Client Portfolio': () => <BodyClientPortfolio postedDocRef={props.postedDocRef} onSuccessPolicyCreate={successPolicyCreate} />,
        'Pending List Edit': () => <BodyPendingListEdit onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Pending List Final': () => <PendingListFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Create Policy': () => <BodyCreatePolicy onePropDown={onePropDownHandler} />,
        'Policy Portfolio': () => <BodyPolicyPortfolio onePropDown={onePropDownHandler} />,
        'View Policy Final': () => <BodyViewPolicyFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Policy Search': () => <BodyPolicySearch onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Body Endorsment Final': () => <BodyEndorsmentFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Body Refund Final': () => <BodyRefundFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Body Renewal Final': () => <BodyRenewalFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Renewed Report': () => <RenewedReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Unrenewed Report': () => <UnrenewedReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Lapsed Policies': () => <BodyLapsedPolicy onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Lapsed Policy View': () => <BodyLapsedPolicyView onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Cancelled Policies': () => <CancelledPolicyListFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Cancelled Policy View': () => <BodyCancelledPolicyView onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        //################################################################
        //accounts
        'Credit Note Allocations': () => <CreditNoteAllocations onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Post New Receipt': () => <BodyPostNewReceipt onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Receipt List': () => <BodyReceiptList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'View Receipt': () => <ViewReceipt onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Total Commission': () => <TotalCommission onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Commission Request': () => <CommissionRequest onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Agewise Outstanding': () => <Overview onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Client Statement': () => <AccountsClientStatementTwo onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Bulk Print Statement': () => <PrintClientStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Insurer Statement Final': () => <InsurerStatementFinal onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Agent Statement': () => <AgentStatement onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        //################################################################
        //dispatch
        'Accounts Clearance': () => <DispatchAccountsClearance onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        //################################################################
        //claims
        'Register A Claim': () => <RegisterAClaim onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'View Claim': () => <ViewClaim onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Open Claims': () => <OpenClaims onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Closed Claims': () => <ClosedClaims onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        //################################################################
        //reports
        'Policy Documents': () => <PolicyDocument onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Valuation Reports': () => <ValuationReports onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Logbook Report': () => <LogBookReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Sticker Copy Report': () => <StickerCopyReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Conclusive Motor Report': () => <ConclusiveMotorReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Business Report': () => <BusinessReport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        //################################################################
        //extras
        'Post DV': () => <PostDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Post CN': () => <PostCN onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'Post JV': () => <PostJV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'DV List': () => <DVList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'CN List': () => <CNList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'JV List': () => <JVList onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'View DV': () => <ViewDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'View CN': () => <ViewCN onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'View JV': () => <ViewJV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />,
        'default': () => console.log()
    };

    const activeTabsRN = useRecoilValue(activeTabs)
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent)

    const [rb, setRB] = useState('')
    useMemo(() => {
        // if (activeTabsRN.length > 0) {
        //     setRB(props.onePropUp.setView)
        // }
        setRB(props.onePropUp.setView)
    }, [props.onePropUp])

    useEffect(() => {
        setRightNavContentRN(rb)
    }, [rb])

    const a = <section>
        {activeTabsRN.map((tab, index) => {
            return (
                <input key={index} onChange={(e) => { setRB(e.target.value) }} type="radio" id={tab.setView} className="radio-button" name="content-filter" value={tab.setView} />
            )
        })}

    </section>

    const b = <section>
        <header id="filter">
            {activeTabsRN.map((tab, index) => {
                return (
                    <label key={index} htmlFor={tab.setView} className={`filter-label featured ${rb === tab.setView && 'f'}`} id="feature-label">{tab.setView}</label>
                )
            })}
        </header>
    </section>

    const c = <section>
        <main>
            {activeTabsRN.map((tab, index) => {
                return (
                    <article key={index} className={`content ${rb === tab.setView && 'block'}`}>
                        {(logFruit[tab.setView] || logFruit['default'])()}
                        {/* {renderViewHandler(tab.setView)} */}
                    </article>
                )
            })}
        </main>
    </section>


    return (
        <section>
            {content}
            {/* {a}
            {b}
            {c} */}
        </section>
    );
}

export default Body;