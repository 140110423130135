import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { DVReallocationDVDocs,DVReallocationSelectedDV } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";
import Loader from "../../Body/Loader/Loader";

const DVReallocationSelectDV = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const DVReallocationDVDocsRN = useRecoilValue(DVReallocationDVDocs)
    const[DVReallocationSelectedDVRN,setDVReallocationSelectedDVRN] = useRecoilState(DVReallocationSelectedDV)
    
    //################################################################
    // get user input
    const [searchTerm, setSearchTerm] = useState('');

    //################################################################
    // filter dv list
    const [filteredDVData, setFilteredDVData] = useState([]);
    useEffect(() => {
        try {
            const list = DVReallocationDVDocsRN.filter((doc) => {
                if (searchTerm == '') {
                    return (doc);
                }
                else if (doc.DVDebitDocData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            });

            setFilteredDVData(list);

        } catch (error) { console.log(error) }
    }, [DVReallocationDVDocsRN, searchTerm])

    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} value={searchTerm} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search DV No.</p></label>
            </div>
            <div className='h_5'></div>
            <div>
                {
                    filteredDVData.map((doc, index) => {
                        return (
                            <div key={index} onClick={() => { setDVReallocationSelectedDVRN(doc) }} className={`px-2 py-1 mb-1 rounded cursor-pointer f_7 ${DVReallocationSelectedDVRN.docID === doc.docID ? 'border border-rose-500' : 'border'}`}>
                                <p className='text-black'>DV No</p>
                                <p>{doc.DVDebitDocData.policyNumber}</p>
                                <p className='text-black'>DV Amount</p>
                                <p>{doc.DVDebitDocData.policyNet}</p>
                                <p className='text-black'>Insurer Debit Date</p>
                                <p>{DateFormat(new Date(doc.DVDebitDocData.insurerDebitDate))}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
    
    return (
        <section className="right_nav_container">
            {content}
            {loaderContent}
        </section>
    );
}

export default DVReallocationSelectDV;