import React, { useEffect, useState, useRef, useCallback } from 'react';
import db from '../../../firebase';
import { addDoc, doc, updateDoc, collection } from "firebase/firestore";
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import DatePicker from 'react-date-picker';
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import { useReactToPrint } from 'react-to-print';
import './RenewedReport.css';
import ConfirmAction from '../../Hooks/ConfirmAction/ConfirmAction';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import { useRecoilValue } from 'recoil';
import { unrenewedReportFilterFromDate, unrenewedReportFilterToDate, unrenewedReportFilterPolicyClass } from '../../../atoms';
import { unrenewedReportLapseAsOfDate, unrenewedReportCancelAsOfDate } from '../../../atoms';
import DateFormat from '../../Hooks/UI/DateFormat';

const UnrenewedReport = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //get items from lrecoil state
    const unrenewedReportFilterFromDateRN = useRecoilValue(unrenewedReportFilterFromDate)
    const unrenewedReportFilterToDateRN = useRecoilValue(unrenewedReportFilterToDate)
    const unrenewedReportFilterPolicyClassRN = useRecoilValue(unrenewedReportFilterPolicyClass)
    const unrenewedReportLapseAsOfDateRN = useRecoilValue(unrenewedReportLapseAsOfDate)
    const unrenewedReportCancelAsOfDateRN = useRecoilValue(unrenewedReportCancelAsOfDate)

    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDebit();
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //remove endor, dj, jv, cn, marine, bond, travel
    const [removedEndorsments, setRemovedEndorsments] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        debitDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && !item.docData.policyNumber.startsWith('DV')
                && !item.docData.policyNumber.startsWith('JV')
                && !item.docData.policyNumber.startsWith('CN')
                && !item.docData.policyNumber.startsWith('Rev')
                && item.docData.selectedProductSubClass !== '(060) - Marine Hull'
                && item.docData.selectedProductSubClass !== '(061) - Marine Certificate'
                && item.docData.selectedProductSubClass !== '(120) - Immigration Bond'
                && item.docData.selectedProductSubClass !== '(127) - Advance Payment Bond'
                && item.docData.selectedProductSubClass !== '(128) - Custom Bond'
                && item.docData.selectedProductSubClass !== '(093) - Travel'
                && item.docData.selectedProductSubClass !== '(020) - Contractors All Risks'
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled'
                && item.docData.businessType !== 'Nil Endorsment') {
                removedEndorsmentsList.push(item);
            }
        });
        setRemovedEndorsments(removedEndorsmentsList)
    }, [debitDocs]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        removedEndorsments.map((item) => {
            //calculate next inception
            // const newInceptionDate = new Date(item.docData.expiryDate);
            // newInceptionDate.setDate(newInceptionDate.getDate() + 1);
            // newInceptionDate.setHours(0, 0, 0);

            const today = new Date(); // current date
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1); // add one day

            const oldInceptionDate = new Date(item.docData.expiryDate); // current date
            const newInceptionDate = new Date(oldInceptionDate);
            newInceptionDate.setDate(oldInceptionDate.getDate() + 1); // add one dayF
            const formattedNewInceptionDate = new Date(newInceptionDate)
            formattedNewInceptionDate.setHours(0, 0, 0);

            //filter period choosen
            if (new Date(formattedNewInceptionDate).valueOf() >= new Date(unrenewedReportFilterFromDateRN).valueOf() && new Date(unrenewedReportFilterToDateRN).valueOf() >= new Date(formattedNewInceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [removedEndorsments, unrenewedReportFilterFromDateRN, unrenewedReportFilterToDateRN]);

    //################################################################
    //find renewal
    const [renewedDebits, setRenewedDebits] = useState([]);
    useEffect(() => {
        const renewalsSelected = [];
        selectedPeriodFilteredDocs.map((item) => {
            debitDocs.map((value) => {
                if ((value.docData.policyNumber).replace(/\//g, "").slice(-12) === (item.docData.policyNumber).replace(/\//g, "").slice(-12)
                    && item.docData.endorsmentNumber !== value.docData.endorsmentNumber
                    && new Date(value.docData.insurerDebitDate).valueOf() > new Date(item.docData.insurerDebitDate).valueOf()
                    && !value.docData.endorsmentNumber.startsWith('05/')) {
                    renewalsSelected.push(item);
                }
            });
        });
        setRenewedDebits(renewalsSelected);
    }, [selectedPeriodFilteredDocs]);
    //item.docData.policyNumber === value.docData.policyNumber
    //value.docData.policyNumber.includes((item.docData.policyNumber).replace(/\//g, "").slice(-12))
    //(value.docData.policyNumber).replace(/\//g, "").slice(-12) === (item.docData.policyNumber).replace(/\//g, "").slice(-12)
    //################################################################
    //removed renewed
    const [removedRenewed, setRemovedRenewed] = useState([]);
    useEffect(() => {
        const filteredUnrenewed = selectedPeriodFilteredDocs.filter(item => !renewedDebits.includes(item));
        setRemovedRenewed(filteredUnrenewed)
    }, [renewedDebits])

    //################################################################
    //filter policy class
    const [filteredClassDocs, setFilteredClassDocs] = useState([])
    useEffect(() => {
        const a = removedRenewed.filter((doc) => {
            if (unrenewedReportFilterPolicyClassRN === 'ALL') { return doc }
            else if (unrenewedReportFilterPolicyClassRN === 'MOTOR') {
                if (doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial') {
                    return doc;
                }
            }
            else if (unrenewedReportFilterPolicyClassRN === 'NON-MOTOR') {
                if (doc.docData.selectedProductClass != '(07) - Motor Private' && doc.docData.selectedProductClass != '(08) - Motor Commercial') {
                    return doc;
                }
            }
        });
        setFilteredClassDocs(a);
    }, [removedRenewed, unrenewedReportFilterPolicyClassRN])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = filteredClassDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [filteredClassDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm, unrenewedReportFilterPolicyClassRN]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //right click

    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################
    //renew policy
    const renewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Renew This Policy?');
        if (!executeAbort) { return false }

        const onePropObj = {
            setView: 'Body Renewal Final',
            previousView: 'Unrenewed Report',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //lapse
    //################################################################
    //step 4    
    // show lasped msg and open new view
    const displayLapsedMSG = () => {
        const onePropObj = {
            setView: 'Lapsed Policy View',
            previousView: 'Unrenewed Report',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Lapsed'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################ 
    //step 3
    //create nil endors doc
    async function createNilEndorsmentPolicyHandler(newObj) {
        setIsLoading(true);

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), newObj);
            console.log("Document written with ID: ", docRef.id);
            setIsLoading(false)
            displayLapsedMSG();
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
    
    //################################################################
    //step 2
    //create nil endor policy
    const nilEndorsmentHandler = () => {
        const a = searchedPolicyDocs.filter(doc => doc.docID === rightClickedPolicy)
        const b = a[0];

        const additionalInstructionsItems = [
            { additionalInstructionItem: 'Kindly Note That This Is A Nil Endorsment Document To Indicate Lapsed Policy.' },
        ];

        const insurerDebitDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

        const newObj = {
            clientName: b.docData.clientName,
            insurerName: b.docData.insurerName,
            bankInterest: b.docData.bankInterest,
            policyNumber: b.docData.policyNumber,
            selectedProductClass: b.docData.selectedProductClass,
            selectedProductSubClass: b.docData.selectedProductSubClass,
            commissionRate: 0,
            businessType: 'Nil Endorsment',
            endorsmentNumber: '',
            inceptionDate: b.docData.inceptionDate,
            expiryDate: b.docData.expiryDate,
            insurerDebitDate: insurerDebitDate,
            locationOfTheRisk: b.docData.locationOfTheRisk,
            totalSumInsured: b.docData.totalSumInsured,
            premium: 0,
            PVTPremium: 0,
            stampDuty: 0,
            TLevy: 0,
            PHCFund: 0,
            commission: 0,
            commissionPayable: 0,
            expectedCommissionPayable: 0,
            outstandingCommission: 0,
            withholdingTax: 0,
            policyNet: 0,
            earthquake: 0,
            excessProtector: 0,
            comesaCard: 0,
            windScreen: 0,
            radioCasset: 0,
            insurerPolicyNet: 0,
            receiptAllocationBreakDown: [],
            commissionAllocationBreakDown: [],
            status: 'Pending',
            outstanding: 0,
            transactionType: 'Debit',
            employeesDetailsItems: {},
            propertyDescriptionItems: {},
            benefitsItems: {},
            deductibleItems: {},
            vehicleItems: {},
            clauseItems: {},
            additionalInstructionsItems: additionalInstructionsItems,
            motorDocuments: {},
            allocatedDocID: b.docID,
            dateCreated: new Date(),
            createdBy: currentUser,
        }

        createNilEndorsmentPolicyHandler(newObj);
    }

    //################################################################
    //step 1
    //lapse policy
    async function lapsePolicyHandler() {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Lapse This Policy?');
        if (!executeAbort) { return false }

        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightClickedPolicy);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'status': 'Lapsed',
                'lapsedDate': unrenewedReportLapseAsOfDateRN,
                'lapsedBy': currentUser,
                'lapsedOn': new Date(),
            });
            setIsLoading(false);
            nilEndorsmentHandler();
            setReload(Math.random(0, 1000))
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //step 3
    //show cancelled msg
    const viewCreatedCreditPolicyOnSuccessPosting = (newCreditDocID) => {
        const onePropObj = {
            setView: 'Pending List Edit',
            previousView: 'Unrenewed Report',
            postedDocRef: newCreditDocID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy Cancelled Successfully. Credit Note Created Successfully.'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################ 
    //step 3
    //create credit
    async function createCreditPolicyHandler(newObj) {
        setIsLoading(true);

        //post the data
        try {
            const docRef = await addDoc(collection(db, "PolicyDetails"), newObj);
            console.log("Document written with ID: ", docRef.id);
            setIsLoading(false)
            viewCreatedCreditPolicyOnSuccessPosting(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    //################################################################ 
    //step 2
    //calc prem
    const calculateNewPremium = () => {
        const a = searchedPolicyDocs.filter(doc => doc.docID === rightClickedPolicy)
        const b = a[0]
        const originalTotalPremium = +b.docData.premium + +b.docData.earthquake + +b.docData.excessProtector + +b.docData.windScreen + +b.docData.radioCasset + +b.docData.comesaCard

        //calculate days
        const dateOne = new Date(b.docData.inceptionDate);
        const dateTwo = new Date(unrenewedReportCancelAsOfDateRN);
        const datesCompared = Math.abs(dateOne - dateTwo);
        let datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
        const daysUnutilized = 365 - datesDifference + 1;

        const premium = Math.round(originalTotalPremium * daysUnutilized / 365);

        const TLevy = Math.round(premium * 0.002);

        const PHCFund = Math.round(premium * 0.0025);

        const commission = Math.round(premium * b.docData.commissionRate);

        const withholdingTax = Math.round(commission * 0.1);

        const policyNet = premium + TLevy + PHCFund;

        const insurerPolicyNet = policyNet - commission + withholdingTax;

        const insurerDebitDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

        const additionalInstructionsItems = [
            { additionalInstructionItem: 'Kindly Note This Policy Is Deemed To Be Cancelled As Of ' + DateFormat(unrenewedReportCancelAsOfDateRN) + ' And The Below Premium Refund Is Expected.' },
            { additionalInstructionItem: 'Note The Below Prorated Premium Calculation.' },
            { additionalInstructionItem: 'Unutilized Days = 365 - Debit Inception Date - Date Of Cancellation' },
            { additionalInstructionItem: daysUnutilized + ' = 365 - ' + DateFormat(b.docData.inceptionDate) + ' - ' + DateFormat(unrenewedReportCancelAsOfDateRN) },
            { additionalInstructionItem: 'Refund Premium = Debit Total Premium x Unutilized Days / 365' },
            { additionalInstructionItem: premium + ' = ' + originalTotalPremium + ' x ' + daysUnutilized + ' / 365' },
        ];

        const newObj = {
            clientName: b.docData.clientName,
            insurerName: b.docData.insurerName,
            bankInterest: b.docData.bankInterest,
            policyNumber: b.docData.policyNumber,
            selectedProductClass: b.docData.selectedProductClass,
            selectedProductSubClass: b.docData.selectedProductSubClass,
            commissionRate: b.docData.commissionRate,
            businessType: 'Refund',
            endorsmentNumber: '',
            inceptionDate: b.docData.inceptionDate,
            expiryDate: b.docData.expiryDate,
            insurerDebitDate: insurerDebitDate,
            locationOfTheRisk: b.docData.locationOfTheRisk,
            totalSumInsured: b.docData.totalSumInsured,
            premium: premium,
            PVTPremium: 0,
            stampDuty: 0,
            TLevy: TLevy,
            PHCFund: PHCFund,
            commission: commission,
            commissionPayable: 0,
            expectedCommissionPayable: 0,
            outstandingCommission: 0,
            withholdingTax: withholdingTax,
            policyNet: policyNet,
            earthquake: 0,
            excessProtector: 0,
            comesaCard: 0,
            windScreen: 0,
            radioCasset: 0,
            insurerPolicyNet: insurerPolicyNet,
            receiptAllocationBreakDown: [],
            commissionAllocationBreakDown: [],
            status: 'Pending',
            outstanding: 0,
            transactionType: 'Credit',
            employeesDetailsItems: b.docData.employeesDetailsItems,
            propertyDescriptionItems: b.docData.propertyDescriptionItems,
            benefitsItems: b.docData.benefitsItems,
            deductibleItems: b.docData.deductibleItems,
            vehicleItems: b.docData.vehicleItems,
            clauseItems: b.docData.clauseItems,
            additionalInstructionsItems: additionalInstructionsItems,
            motorDocuments: b.docData.motorDocuments ? b.docData.motorDocuments : {},
            allocatedDocID: b.docID,
            dateCreated: new Date(),
            createdBy: currentUser,
        }

        createCreditPolicyHandler(newObj);

    }

    //################################################################ 
    //step 1   
    //cancel policy
    async function cancelPolicyHandler() {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Calcel This Policy?');
        if (!executeAbort) { return false }

        //update data
        try {
            setIsLoading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightClickedPolicy);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'status': 'Cancelled',
                'cancelledDate': unrenewedReportCancelAsOfDateRN,
                'cancelledBy': currentUser,
                'cancelledOn': new Date(),
            });
            setIsLoading(false);
            calculateNewPremium()
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={renewPolicyHandler}><p>Renew Policy</p></span>
        <div className=''></div>
        <span onClick={lapsePolicyHandler}><p>Lapse Policy</p></span>
        <div className=''></div>
        <span onClick={cancelPolicyHandler}><p>Cancel Policy</p></span>
    </div>

    //################################################################
    //echo
    const renewedPoliciesContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='renewed_policies_report'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.policyTransactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                        <td id={item.docID}><p id={item.docID}>{item.id}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.clientName}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.endorsmentNumber}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.selectedProductSubClass}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{DateFormat(item.docData.inceptionDate)}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{DateFormat(item.docData.expiryDate)}</p></td>
                                        <td id={item.docID}>
                                            <p id={item.docID}>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td id={item.docID}><p id={item.docID}>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //printable
    const printablerenewedPoliciesContent = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='renewed_policies_report' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Unrenewed Report</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {new Date(unrenewedReportFilterFromDateRN).toDateString()} - {new Date(unrenewedReportFilterToDateRN).toDateString()}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedPolicyDocs.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.policyTransactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{DateFormat(item.docData.inceptionDate)}</p></td>
                                        <td><p>{DateFormat(item.docData.expiryDate)}</p></td>
                                        <td>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><i className="ri-attachment-2"></i></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='renewed_policies unrenewed_policies'>
            <div className='body_section_header'>
                <p>Unrenewed Report</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {renewedPoliciesContent}
                {printablerenewedPoliciesContent}
            </div>
            {URRightClickMenu}
            {loaderContent}
        </section>
    )
}

export default UnrenewedReport;