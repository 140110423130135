import React, { useState, useEffect, useRef } from 'react';
import Loader from '../Loader/Loader';
import './OpenClaims.css';
import GetAllClaims from '../../Hooks/BackendConn/Claims/GetAllClaims';
import DateFormat from '../../Hooks/UI/DateFormat';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rightNavContent } from '../../../atoms';
import { openClaimsFilterFromDate, openClaimsFilterToDate } from '../../../atoms';
import { useReactToPrint } from 'react-to-print';

const OpenClaims = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get recoil values
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent);
    const openClaimsFilterFromDateRN = useRecoilValue(openClaimsFilterFromDate)
    const openClaimsFilterToDateRN = useRecoilValue(openClaimsFilterToDate)

    //################################################################
    //get all claims
    const [claimDocs, setClaimDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClaims();
            setClaimDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get open claims
    const [openClaimsDocs, setOpenClaimDocs] = useState([])
    useEffect(() => {
        const openclaims = claimDocs.filter(doc => doc.docData.stage === 'Open')
        setOpenClaimDocs(openclaims)
    }, [claimDocs])

    //################################################################
    //sort open claims
    const [dateSortedOpenClaimDocs, setDateSortedOpenClaimDocs] = useState([])
    useEffect(() => {
        const sortedList = openClaimsDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.dateOfLoss.seconds * 1000).valueOf() - new Date(b.docData.dateOfLoss.seconds * 1000).valueOf();
        }
        setDateSortedOpenClaimDocs(sortedList);
    }, [openClaimsDocs])

    //################################################################
    //filter open claims
    const [dateFilteredOpenClaimDocs, setDateFilteredOpenClaimDocs] = useState([])
    useEffect(() => {
        const a = dateSortedOpenClaimDocs.filter(doc => new Date(doc.docData.dateOfLoss.seconds * 1000).valueOf() >= new Date(openClaimsFilterFromDateRN).valueOf() && new Date(doc.docData.dateOfLoss.seconds * 1000).valueOf() <= new Date(openClaimsFilterToDateRN).valueOf());
        setDateFilteredOpenClaimDocs(a)
    }, [dateSortedOpenClaimDocs, openClaimsFilterFromDateRN, openClaimsFilterToDateRN])

    //################################################################
    // search open claims
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedOpenClaimDocs, setSearchedOpenClaimDocs] = useState([])
    useEffect(() => {
        const c = [];
        const docs = dateFilteredOpenClaimDocs.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.vehicleDetails.regNo) {
                if (doc.docData.vehicleDetails.regNo.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            }

        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        });
        setSearchedOpenClaimDocs(c);
    }, [dateFilteredOpenClaimDocs, searchTerm])

    //################################################################
    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    const viewClaimHandler = () => {
        //hide menu
        setxPos('-1000px');
        setyPos('-1000px');

        //change view
        const onePropObj = {
            setView: 'View Claim',
            previousView: 'Open Claims',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewClaimHandler}><p>View Claim</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //echo
    const openClaimsContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Policy No. / Endorsment No. / Veh Plate No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    {
                        searchedOpenClaimDocs.length > 0
                            ? <table className='open_claims_table'>
                                <thead>
                                    <tr>
                                        <td><p>No</p></td>
                                        <td><p>Insured</p></td>
                                        <td><p>Policy Number</p></td>
                                        <td><p>Policy Class</p></td>
                                        <td><p>Details</p></td>
                                        <td><p>Date Of Loss</p></td>
                                        <td><p>Nature Of Loss</p></td>
                                        <td><p>Status</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchedOpenClaimDocs.map((doc, index) => {
                                            return (
                                                <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductClass}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>
                                                        {doc.docData.vehicleDetails.regNo}
                                                        {doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'}
                                                    </p></td>
                                                    <td id={doc.docID}>
                                                        {
                                                            Object.keys(doc.docData.dateOfLoss).length > 0
                                                                ? <p id={doc.docID}>{DateFormat(doc.docData.dateOfLoss)}</p>
                                                                : <p id={doc.docID}></p>
                                                        }
                                                    </td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.natureOfLoss}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>
                                                        {
                                                            Object.values(doc.docData.status).map((item, index) => {
                                                                if (index + 1 == Object.values(doc.docData.status).length) {
                                                                    return (item.narration)
                                                                }
                                                            })
                                                        }
                                                    </p></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            : ''
                    }
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //printable
    const openClaimsContentPrintable = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    {
                        searchedOpenClaimDocs.length > 0
                            ? <table className='open_claims_table' ref={componentRef}>
                                <thead>
                                    <tr><td colSpan='10'><center><p className='bold'>Open Claims</p></center></td></tr>
                                    <tr><td colSpan='10'><center><p className='bold'>Period : {DateFormat(openClaimsFilterFromDateRN)} - {DateFormat(openClaimsFilterToDateRN)}</p></center></td></tr>
                                    <tr>
                                        <td><p>No</p></td>
                                        <td><p>Insured</p></td>
                                        <td><p>Policy Number</p></td>
                                        <td><p>Policy Class</p></td>
                                        <td><p>Details</p></td>
                                        <td><p>Date Of Loss</p></td>
                                        <td><p>Nature Of Loss</p></td>
                                        <td><p>Status</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchedOpenClaimDocs.map((doc, index) => {
                                            return (
                                                <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductClass}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>
                                                        {doc.docData.vehicleDetails.regNo}
                                                        {doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'}
                                                    </p></td>
                                                    <td id={doc.docID}>
                                                        {
                                                            Object.keys(doc.docData.dateOfLoss).length > 0
                                                                ? <p id={doc.docID}>{DateFormat(doc.docData.dateOfLoss)}</p>
                                                                : <p id={doc.docID}></p>
                                                        }
                                                    </td>
                                                    <td id={doc.docID}><p id={doc.docID}>{doc.docData.natureOfLoss}</p></td>
                                                    <td id={doc.docID}><p id={doc.docID}>
                                                        {
                                                            Object.values(doc.docData.status).map((item, index) => {
                                                                if (index + 1 == Object.values(doc.docData.status).length) {
                                                                    return (item.narration)
                                                                }
                                                            })
                                                        }
                                                    </p></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            : ''
                    }
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //set upload valuation content  
    const openClaimsFilterHandler = () => {
        setRightNavContentRV('Open Claim Filter')
    }

    return (
        <section className='open_claims body_input_styles'>
            <div className='body_section_header'>
                <p>Open Claims</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={openClaimsFilterHandler}>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {openClaimsContent}
                {openClaimsContentPrintable}
            </div>
            {URRightClickMenu}
            {loaderContent}
        </section>
    );
}

export default OpenClaims;