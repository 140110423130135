import React, { useState, useEffect } from 'react';
import GetDebitsCredits from '../../Hooks/BackendConn/GeneralList/GetDebitsCredits';
import DateFormat from '../../Hooks/UI/DateFormat';
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rightNavContent, rightNavPolicyItem, pageReloaderHandler } from '../../../atoms';
import './PolicyDocuments.css';

const PolicyDocument = (props) => {
    //#####################################################################
    //recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [rightNavPolicyItemRN, setRightNavPolicyItemRN] = useRecoilState(rightNavPolicyItem);
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler);

    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    const fromDate = '2021-05-01';
    const toDate = '2022-12-31';
    const policyDocumentsReportFilter = 'Missing Document'

    //################################################################
    //get all debits and credits
    const [debitsCreditsDocs, setDebitsCreditsDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetDebitsCredits();
            setDebitsCreditsDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //remove dv
    const [cleanDebitscredits, setCleanDebitsCredits] = useState([]);
    useEffect(() => {
        const cleanDocs = []
        debitsCreditsDocs.map((doc) => {
            if (!doc.docData.policyNumber.startsWith('DV')
                && !doc.docData.policyNumber.startsWith('JV')
                && !doc.docData.policyNumber.startsWith('CN')
                && !doc.docData.policyNumber.startsWith('Rev')
                && doc.docData.status != 'Pending') {
                cleanDocs.push(doc)
            }
        });
        setCleanDebitsCredits(cleanDocs);
    }, [debitsCreditsDocs]);

    //################################################################
    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = cleanDebitscredits.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.inceptionDate).valueOf() - new Date(a.docData.inceptionDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [cleanDebitscredits]);

    //################################################################
    //filter date
    const [dateFilterPolicyDocs, setDateFilterPolicyDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        sortedPolicyDocs.map((item) => {
            if (new Date(item.docData.inceptionDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.docData.inceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        })
        setDateFilterPolicyDocs(periodSelected)
    }, [sortedPolicyDocs, fromDate, toDate])

    //################################################################
    //filter those with or without policy docs
    const [docsFilteredPolicyDocs, setDocsFilteredPolicyDocs] = useState([])
    useEffect(() => {
        const docFiltered = []
        dateFilterPolicyDocs.map((item) => {
            if (!item.docData.associateDocuments && policyDocumentsReportFilter === 'Missing Document') {
                docFiltered.push(item)
            }
            else if (item.docData.associateDocuments && policyDocumentsReportFilter === 'Missing Document') {
                const a = [];
                item.docData.associateDocuments.map((item) => {
                    if (item.documentUploadCategory === 'Policy Document') { a.push(item) }
                    if (a.length < 0) { docFiltered.push(item) }
                })
            }

        });
        setDocsFilteredPolicyDocs(docFiltered)
    }, [dateFilterPolicyDocs])

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = docsFilteredPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else {
                const vhArr = val.docData.vehicleItems
                for (var i = 0; i < vhArr.length; i++) {
                    if (vhArr[i].includes(searchTerm.toLowerCase())) {
                        return val;

                    }
                }
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedPolicyDocs(c);
    }, [docsFilteredPolicyDocs, searchTerm])

    //paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm])

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);

        }
    };

    //
    const viewPolicyHandler = () => {
        //open view
        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Policy Documents',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }

        props.onePropDown(onePropObj);

    }

    //
    const uploadPolicyDocumentHandler = () => {
        const a = searchedPolicyDocs.filter(doc => doc.docID === rightClickedPolicy)
        setRightNavPolicyItemRN(a[0]);

        setRightNavContentRN('Policy Document Upload')

        setxPos('-1000px');
        setyPos('-1000px');
    }

    //reload on success upload
    useEffect(()=>{
        if(pageReloaderHandlerRN.notification === 'Policy Document Uploaded Successfully'){
            setReload(Math.random(10001, 20000))
            setPageReloaderHandlerRN({})
        }
    },[pageReloaderHandlerRN])

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={uploadPolicyDocumentHandler}><p>Upload Policy Document</p></span>
        <div className=''></div>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>

    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const policyDocumentsContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Policy No. / Endorsment No. / Veh Plate No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='policy_documents_report_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy No</p></td>
                                <td><p>Endorsment No</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPosts.map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.clientName}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.policyNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.endorsmentNumber}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.selectedProductSubClass}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {doc.docData.locationOfTheRisk}
                                                {
                                                    Object.keys(doc.docData.vehicleItems).length > 0
                                                        ? Object.values(doc.docData.vehicleItems).map((veh) => {
                                                            return (veh.regNo + ', ')
                                                        })
                                                        : ''
                                                }
                                            </p></td>
                                            <td>
                                                {
                                                    doc.docData.associateDocuments
                                                        ? doc.docData.associateDocuments.map((doc) => {
                                                            if (doc.documentUploadCategory === 'Policy Document') {
                                                                return (
                                                                    <span onClick={() => openInNewTab(doc.associateDocuments[0].documentDownloadLink)} className='policy_list_doc_icon'><i className="ri-attachment-2"></i></span>
                                                                )
                                                            }
                                                        })
                                                        : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='policy_documents body_input_styles'>
            <div className='body_section_header'>
                <p>Policy Docs</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {policyDocumentsContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default PolicyDocument;