import React, { useState } from "react";
import db from "../../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import Loader from "../../../Body/Loader/Loader";
import UUID from "../../../Hooks/UUID/UUID";
import GetCookie from "../../../Hooks/Cookie/GetCookie";

const CreateInsurer = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get user input
    const [insurer, setInsurer] = useState('')
    const [postalAddress, setPostalAddress] = useState('')
    const [contactDetails, setContactDetails] = useState('')

    //################################################################
    //step 2
    // change view
    const changeViewOnSuccessfulInsurerCreation = () => {
        setInsurer('');
        setPostalAddress('');
        setContactDetails('');

        const onePropObj = {
            setView: 'Insurer Portfolio',
            previousView: 'Insurer Portfolio',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Insurer Profile Created Successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 1
    // post data to db
    const creteInsurerSubmitHandler = async (event) => {
        event.preventDefault();
       setIsLoading(true);

        //get unique id
        const claimID = UUID();

        //claim obj
        const insurerProcessedData = {
            insurer: insurer,
            postalAddress: postalAddress,
            contactDetails: contactDetails,
            commissionRates: {},
            policyRates: {},
            documents: {},
            createdBy: currentUser,
            dateCreated: new Date(),
        }

        //post data
        const sfDocRef = doc(db, "InsurerPortfolio", "YleFvRHWa48KYHcH650N");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //get unique id
                const insurerID = UUID();

                transaction.update(sfDocRef, { [insurerID]: insurerProcessedData });
            });
            setIsLoading(false);
            changeViewOnSuccessfulInsurerCreation();
            //console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    // crete insurer Content
    const createInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Create Insurer</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={insurer || ''} onChange={(e) => { setInsurer(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Insurer</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={postalAddress || ''} onChange={(e) => { setPostalAddress(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Postal Address</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={contactDetails || ''} onChange={(e) => { setContactDetails(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Contact Details</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={creteInsurerSubmitHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {createInsurerContent}
            {loaderContent}
        </section>
    );
}

export default CreateInsurer;