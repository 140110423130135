import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { doc, updateDoc } from "firebase/firestore";
import LogoImg from '../../Assets/img/logo.png'
import Loader from '../Loader/Loader';
import '../../Body/BodySectionModule.css';
import './BodyCreateClient.css'
import styles from './EditClientFinal.module.css'
import { useReactToPrint } from "react-to-print";
import GetClientData from '../../Hooks/BackendConn/ClientList/GetClientData';

const EditClientFinal = (props) => {
  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = '';
  if (isLoading === true) {
    loaderContent = <Loader />
  }

  //################################################################
  // printing
  const componentRef = useRef();
  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  //################################################################
  //get user input
  const [clientName, setClientName] = useState('');
  const [IDNumber, setIDNumber] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [KRAPin, setKRAPin] = useState('');
  const [occupation, setOccupation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [contactPerson, setContactPerson] = useState('');

  const [group, setGroup] = useState('');
  const [docID, setDocID] = useState('');
  const [insurerID, setInsurerID] = useState('');
  const [documents, setDocuments] = useState({});



  //################################################################
  //get all client data
  const [clientDataDocs, setClientDataDocs] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const DDocs = await GetClientData();
      setClientDataDocs(DDocs);
      if (DDocs) {
        setIsLoading(false)
      }
      else { setReload(Math.random(0, 10000)) }
    })();
  }, [reload]);

  //################################################################
  //filter active client
  const [activeClient, setActiveClient] = useState(null)
  useEffect(() => {
    try {

      const list = clientDataDocs.filter(doc => doc.docID === props.onePropUp.postedDocRef);
      setActiveClient(list[0])

    } catch (error) { }
  }, [clientDataDocs, props.onePropUp.postedDocRef])

  //################################################################
  //set initial state
  useEffect(() => {

    try {

      if (activeClient) {
        setClientName(activeClient.docData.clientName);
        setIDNumber(activeClient.docData.IDNumber);
        setPassportNumber(activeClient.docData.passportNumber);
        setKRAPin(activeClient.docData.KRAPin);
        setOccupation(activeClient.docData.occupation);
        setPhoneNumber(activeClient.docData.phoneNumber);
        setEmail(activeClient.docData.email);
        setAddress(activeClient.docData.address);
        setContactPerson(activeClient.docData.contactPerson);
        setGroup(activeClient.docData.group);
        setDocID(activeClient.docData.docID)
        setInsurerID(activeClient.docData.insurerID)
        
        if (Object.keys(activeClient).length > 0) {
          if (Object.keys(activeClient.docData).length > 0) {
            if (activeClient.docData.documents) { setDocuments(activeClient.docData.documents) }
          }
        }

      }

    } catch (error) { }

  }, [activeClient]);

  //################################################################
  //update client data
  //step 2
  const showMsgOnSuccessfulUpdate = () => {
    setIsLoading(false);

    const onePropObj = {
      setView: 'Edit Client Final',
      previousView: '',
      postedDocRef: props.onePropUp.postedDocRef,
      dateFrom: '',
      dateTo: '',
      searchTerm: '',
      notification: 'Client Details Update Successful'
    }
    props.onePropDown(onePropObj);
  }

  //step 1
  const updateClient = async (event) => {
    event.preventDefault();
    // setIsLoading(true);

    try {
      const newClientObj = {
        docID: docID,
        IDNumber: IDNumber,
        KRAPin: KRAPin,
        address: address,
        clientName: clientName,
        contactPerson: contactPerson,
        email: email,
        group: group,
        occupation: occupation,
        passportNumber: passportNumber,
        phoneNumber: phoneNumber,
        insurerID: insurerID,
        documents: documents,
      }
      
      const frankDocRef = doc(db, "ClientData", "JbrVkeL56d5lrA8R2gdF");

      await updateDoc(frankDocRef, {
          [props.onePropUp.postedDocRef]: newClientObj,
      });

      showMsgOnSuccessfulUpdate();

    } catch (error) { console.log(error) }
  }

  const printDetails = (
    <section className={styles.universal}>
      <div className={styles.logoContainer}>
        <img src={LogoImg} className={styles.logoImg} />
      </div>
      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Client Details</p>
      </div>
      <div className="body_section">
        <div className="h_5"></div>
        <div>
          <div className="category_header">
            <p>Basic Information</p>
          </div>
          <div className="category_content">
            <div className="category_content_one">
              <input
                value={clientName || ""}
                onChange={(e) => {
                  setClientName(e.target.value.trim().toUpperCase());
                }}
                type="text"
                placeholder="Client Name : "
                disabled
              />
              <div className="h_10"></div>
              <input
                value={IDNumber || ""}
                onChange={(e) => {
                  setIDNumber(e.target.value);
                }}
                type="text"
                placeholder="ID Number : "
              />
              <div className="h_10"></div>
              <input
                value={passportNumber || ""}
                onChange={(e) => {
                  setPassportNumber(e.target.value);
                }}
                type="text"
                placeholder="Passport No. : "
              />
            </div>
            <div className="category_content_two">
              <input
                value={KRAPin || ""}
                onChange={(e) => {
                  setKRAPin(e.target.value);
                }}
                type="text"
                placeholder="KRA Pin : "
              />
              <div className="h_10"></div>
              <input
                value={occupation || ""}
                onChange={(e) => {
                  setOccupation(e.target.value);
                }}
                type="text"
                placeholder="Occupation Details : "
              />
            </div>
          </div>
        </div>
        <div className="h_15"></div>
        <div>
          <div className="category_header">
            <p>Contact Details</p>
          </div>
          <div className="category_content">
            <div className="category_content_one">
              <input
                value={phoneNumber || ""}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                type="text"
                placeholder="Phone Number : "
              />
              <div className="h_10"></div>
              <input
                value={email || ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                placeholder="Email : "
              />
              <div className="h_10"></div>
            </div>
            <div className="category_content_two">
              <input
                value={address || ""}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                type="text"
                placeholder="Address : "
              />
            </div>
          </div>
        </div>
        <div className="h_15"></div>
        <div>
          <div className="category_header">
            <p>Contact Person</p>
          </div>
          <div className="category_content">
            <div className="category_content_three">
              <textarea
                value={contactPerson || ""}
                onChange={(e) => {
                  setContactPerson(e.target.value);
                }}
                type="text"
                placeholder="Contact Person : "
              />
              <div className="h_10"></div>
              {/* <textarea value={""} type="text" placeholder=" " /> */}
              <div className="h_10"></div>
              {/* <textarea value={""} type="text" placeholder=" " /> */}
              <div className="h_10"></div>
              {/* <textarea value={""} type="text" placeholder=" " /> */}
              <div className="h_10"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );


  return (
    <section>
      <form onSubmit={updateClient}>
        <div className='body_section_header'>
          <p>View Client</p>
          <div className="cursor-pointer">
            <p className="peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded">
              Controls
            </p>
            <div className="hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999">
              <p
                className="hover:bg-gray-200 px-2 py-2 rounded"
              >
                <button type='submit' >Update Details</button>
              </p>
              <p
                className="hover:bg-gray-200 px-2 py-2 rounded"
                onClick={printHandler}
              >
                Print Details
              </p>
            </div>
          </div>
        </div>
        <div className='body_section'>
          <div className='h_5'></div>
          <div className='category'>
            <div className='category_header'><p>Client Details</p></div>
            <div className='category_content'>
              <div className='category_content_one'>
                <input value={clientName || ''} onChange={(e) => { setClientName(e.target.value.trim().toUpperCase()) }} type='text' placeholder='Client Name' disabled />
                <div className='h_10'></div>
                <input value={IDNumber || ''} onChange={(e) => { setIDNumber(e.target.value) }} type='text' placeholder='ID Number' />
                <div className='h_10'></div>
                <input value={passportNumber || ''} onChange={(e) => { setPassportNumber(e.target.value) }} type='text' placeholder='Passport Number' />
              </div>
              <div className='category_content_two'>
                <input value={KRAPin || ''} onChange={(e) => { setKRAPin(e.target.value) }} type='text' placeholder='KRA Pin' />
                <div className='h_10'></div>
                <input value={occupation || ''} onChange={(e) => { setOccupation(e.target.value) }} type='text' placeholder='Occupation / Employment Details' />
              </div>
            </div>
          </div>
          <div className='h_15'></div>
          <div className='category'>
            <div className='category_header'><p>Contact Details</p></div>
            <div className='category_content'>
              <div className='category_content_one'>
                <input value={phoneNumber || ''} onChange={(e) => { setPhoneNumber(e.target.value) }} type='text' placeholder='Phone Number' />
                <div className='h_10'></div>
                <input value={email || ''} onChange={(e) => { setEmail(e.target.value) }} type='text' placeholder='Email' />
                <div className='h_10'></div>
              </div>
              <div className='category_content_two'>
                <input value={address || ''} onChange={(e) => { setAddress(e.target.value) }} type='text' placeholder='Address' />
              </div>
            </div>
          </div>
          <div className='h_15'></div>
          <div className='category'>
            <div className='category_header'><p>Contact Person</p></div>
            <div className='category_content'>
              <div className='category_content_three'>
                <textarea value={contactPerson || ''} onChange={(e) => { setContactPerson(e.target.value) }} type='text' placeholder='Contact Person' />
                <div className='h_10'></div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loaderContent}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>{printDetails}</div>
      </div>
    </section>
  );
}

export default EditClientFinal;