import React, { useState, useEffect } from "react";
import Loader from "../../Body/Loader/Loader";
import { useRecoilValue, useRecoilState } from "recoil";
import { DVReallocationSelectedDebits, DVReallocationUnselectedDebits } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";

const DVReallocationSelectDebit = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [DVReallocationSelectedDebitsRN, setDVReallocationSelectedDebitsRN] = useRecoilState(DVReallocationSelectedDebits)
    const [DVReallocationUnselectedDebitsRN, setDVReallocationUnselectedDebitsRN] = useRecoilState(DVReallocationUnselectedDebits)

    //################################################################
    // get user input
    const [searchTerm, setSearchTerm] = useState('');

    //################################################################
    // filter dv list
    const [filteredDebitData, setFilteredDebitData] = useState([]);
    useEffect(() => {
        try {
            const list = DVReallocationUnselectedDebitsRN.filter((doc) => {
                if (searchTerm == '') {
                    return (doc);
                }
                else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
                else if (doc.docData.endorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
                else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            });

            setFilteredDebitData(list);

        } catch (error) { console.log(error) }
    }, [DVReallocationUnselectedDebitsRN, searchTerm])

    //################################################################
    // select handler
    const selectedDebitHandler = (receivedDoc) => {
        try {

            const unselectedValues = [...DVReallocationUnselectedDebitsRN]
            const selectedValues = [...DVReallocationSelectedDebitsRN];

            const a = selectedValues.filter(doc => doc.docID === receivedDoc.docID);
            const b = unselectedValues.filter(doc => doc.docID !== receivedDoc.docID);
            if (a.length == 0) {
                setDVReallocationSelectedDebitsRN([receivedDoc, ...selectedValues]);
                setDVReallocationUnselectedDebitsRN(b)
            }

        } catch (error) { }
    }

    //################################################################
    // content
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} value={searchTerm} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search Debit</p></label>
            </div>
            <div className='h_5'></div>
            <div>
                {
                    searchTerm.length > 5 &&
                    filteredDebitData.map((doc, index) => {
                        return (
                            <div key={index} onClick={() => { selectedDebitHandler(doc) }} className={`border px-2 py-1 mb-1 rounded cursor-pointer f_7`}>
                                <p className='text-black'>Name</p>
                                <p>{doc.docData.clientName}</p>
                                <p className='text-black'>Policy No</p>
                                <p>{doc.docData.policyNumber}</p>
                                <p className='text-black'>Endor No</p>
                                <p>{doc.docData.endorsmentNumber}</p>
                                <p className='text-black'>Insurer Debit Date</p>
                                <p>{DateFormat(new Date(doc.docData.insurerDebitDate))}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>


    return (
        <section className="right_nav_container">
            {content}
            {loaderContent}
        </section>
    );
}

export default DVReallocationSelectDebit;