import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import NumberFormat from "../../Hooks/UI/NumberFormat";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const BiometricsDataStepTwo = (props) => {
    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            setFinalData(props.categorizedData);
        } catch (error) { }
    }, [props]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 15rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'name', headerName: 'Name', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        {
            field: 'totalHours', headerName: 'Total Hours', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{NumberFormat(params.value)}</p>
                </div>
            }
        },
        {
            field: 'expectedHrs', headerName: 'Expected Hours', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{NumberFormat(params.value)}</p>
                </div>
            }
        },
        {
            field: 'basicSalary', headerName: 'Basic Salary', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{NumberFormat(params.value)}</p>
                </div>
            }
        },
        {
            field: 'payPerHour', headerName: 'Pay Per Hour', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{NumberFormat(params.value)}</p>
                </div>
            }
        },
        {
            field: 'grossPay', headerName: 'Gross Salary', sortable: false, filter: false, width: 100,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{NumberFormat(params.value)}</p>
                </div>
            }
        },
        // { field: 'Check-in', headerName: 'Check-in', sortable: false, filter: false, width: 150, },
        // { field: 'Check-out', headerName: 'Check-out', sortable: false, filter: false, width: 150, },
        // { field: 'hoursWorked', headerName: 'Hours Worked', sortable: false, filter: false, width: 150, },
        // { field: 'hoursAccounted', headerName: 'Hours Accounted', sortable: false, filter: false, width: 150, },
        // { field: 'details', headerName: 'Details', width: 150, },
        // { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        // { field: 'transactionType', headerName: 'Voucher', width: 40 },
        // { field: 'businessType', headerName: 'Type', width: 40 },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (content)
}

export default BiometricsDataStepTwo;