import React, { useState, useRef } from "react";
import db from "../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import Loader from "../../Body/Loader/Loader";
import { useRecoilState } from "recoil";
import { reloadOnSuccessPayroleCategoryCreationHandler } from "../../../atoms";

const CreatePayroleCategory = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [reloadOnSuccessPayroleCategoryCreationHandlerRN, setReloadOnSuccessPayroleCategoryCreationHandler] = useRecoilState(reloadOnSuccessPayroleCategoryCreationHandler)

    //################################################################
    // get user input
    const categoryNameRef = useRef(null);

    //################################################################
    // show fail msg
    const displayFailMSG = () => {
        const onePropObj = {
            setView: 'Payrole Settings',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payrole Category Already Exist',
        }
        props.onePropDown(onePropObj);
    }

    //################################################################
    // show success msg
    const displaySuccessCreateMSG = () => {
        setReloadOnSuccessPayroleCategoryCreationHandler(true);

        const onePropObj = {
            setView: 'Payrole Settings',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payrole Category Created Successfully.',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    // post data
    const createPayroleCategoryHandler = async () => {
        try {
            if (!categoryNameRef.current.value) {
                return false
            }

            setIsLoading(true);

            const sfDocRef = doc(db, "PayroleData", "Zp3LTqUfsvz2u7FFnRmB");

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const branchID = 'f34a-c6e4-4bd0-bd48-804d345e3479 - 08bf5040-93a8-43c2-8eac-461424cc590c'
                const allData = sfDoc.data();
                const insurerData = (allData[branchID]['payroleSettings']);

                if (!Object.keys(insurerData).includes(categoryNameRef.current.value)) {
                    const newObj = { ...insurerData, [categoryNameRef.current.value]: {} }
                    const a = branchID + '.payroleSettings'
                    transaction.update(sfDocRef, { [a]: newObj });
                    displaySuccessCreateMSG();
                }
                else { displayFailMSG(); }

            });

            setIsLoading(false);

        } catch (e) { console.log("Transaction failed: ", e); }

    }



    //################################################################
    // crete insurer Content
    const createPayroleContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Create Payrole Category</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input ref={categoryNameRef} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Category Name</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createPayroleCategoryHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {createPayroleContent}
            {loaderContent}
        </section>
    )
}

export default CreatePayroleCategory;