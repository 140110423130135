import axios from "axios";

const GetAllUserActivity = async () => {
    const url = 'https://addmessage-7fqanz2g2q-uc.a.run.app/getallactivities';
    const headers = {
        'Authorization': 'Bearer your_token',
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US'
    };

    try {
        const response = await axios.get(url, { headers });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default GetAllUserActivity;