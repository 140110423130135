import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import db from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useRecoilValue, useRecoilState } from 'recoil';
import { PendingListInsurerFilter, PendingListFilterFromDate, PendingListFilterToDate, rightNavContent, PendingListUpdateDispatchDateInput, PendingListUpdateDispatchDateInputHandler } from '../../../atoms';
import GetPendingDocs from '../../Hooks/BackendConn/InsurerSpecific/GetPendingDocs';
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import { useReactToPrint } from "react-to-print";
import styles from './PendingListGrid.module.css';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Html2Pdf from 'html2pdf.js'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getFunctions, httpsCallable } from "firebase/functions";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import UUID from "../../Hooks/UUID/UUID";
import { nanoid } from 'nanoid';
import ConfirmAction from './../../Hooks/ConfirmAction/ConfirmAction'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import NumberFormat from "../../Hooks/UI/NumberFormat";
import GetAllActionMemos from "../../Hooks/BackendConn/ActionMemo/GetAllActionMemos";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '2300px';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
};

const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = 'calc(100vh - 10rem)';
    api.setDomLayout();
};

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};



const PendingListGrid = (props) => {
    //################################################################
    //recoil values
    const PendingListInsurerFilterRN = useRecoilValue(PendingListInsurerFilter);
    const PendingListFilterFromDateRN = useRecoilValue(PendingListFilterFromDate);
    const PendingListFilterToDateRN = useRecoilValue(PendingListFilterToDate);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const pendingListUpdateDispatchDateInputRN = useRecoilValue(PendingListUpdateDispatchDateInput);
    const [pendingListUpdateDispatchDateInputHandlerRN, setPendingListUpdateDispatchDateInputHandlerRN] = useRecoilState(PendingListUpdateDispatchDateInputHandler);

    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page { 
                size: landscape !important; 
                margin: 10mm !important;
            }
          }`,
        content: () => gridRef.current,
    });

    //################################################################
    //get all debits
    const [pendingDocs, setPendingDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useMemo(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetPendingDocs(PendingListInsurerFilterRN);
            setPendingDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, PendingListInsurerFilterRN]);

    //################################################################
    //get all action memos
    const [actionMemoDocs, setActionMemoDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllActionMemos();
            setActionMemoDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //get open action memos
    const [openActionMemoDocs, setOpenActionMemoDocs] = useState([])
    useEffect(() => {
        try {

            const actionMemo = actionMemoDocs.filter(doc => doc.docData.status === 'Open');
            setOpenActionMemoDocs(actionMemo);

        } catch (error) { }
    }, [actionMemoDocs])

    //################################################################
    //clean docs
    const [cleanDocs, setCleanDocs] = useState([]);
    useEffect(() => {
        try {

            const list = [];
            openActionMemoDocs.map((doc) => {
                const date = new Date(doc.docData.dateCreated.seconds * 1000).getDate();
                const month = new Date(doc.docData.dateCreated.seconds * 1000).getMonth() + 1;
                const year = new Date(doc.docData.dateCreated.seconds * 1000).getFullYear();
                const cleanDate = year + '-' + month + '-' + date
                list.push({
                    docID: doc.docID,
                    docData: {
                        clientName: doc.docData.title,
                        transactionType: 'Action Memo',
                        businessType: 'Action Memo',
                        policyNumber: doc.docData.actionNeeded,
                        endorsmentNumber: '',
                        policyClass: '',
                        selectedProductSubClass: '',
                        inceptionDate: cleanDate,
                        expiryDate: cleanDate,
                        details: '',
                        policyNet: 0,
                        locationOfTheRisk: '',
                        dateSubmittedToInsurer: doc.docData.dateCreated,
                        riskNoteDispatch: { date: doc.docData.dateCreated }
                    }
                })
            });
            setCleanDocs([...pendingDocs, ...list])

        } catch (error) { }
    }, [pendingDocs, openActionMemoDocs])

    //################################################################
    //calc no of pending days
    const [calculatedDaysDocs, setCalculatedDays] = useState([]);
    useMemo(() => {
        try {

            const calcDays = []
            cleanDocs.map((doc) => {
                //calculate pending days
                const dateOne = new Date();
                let dateTwo = '';
                let datesDifference = 0;

                if (doc.docData.riskNoteDispatch) {
                    dateTwo = new Date(doc.docData.riskNoteDispatch.date.seconds * 1000);
                    const datesCompared = Math.abs(dateOne - dateTwo);
                    datesDifference = Math.ceil(datesCompared / (1000 * 60 * 60 * 24));
                }
                //const dateTwo = new Date(doc.docData.dateCreated.seconds * 1000);

                calcDays.push({ docData: doc.docData, docID: doc.docID, daysPending: datesDifference })
            });
            setCalculatedDays(calcDays);

        } catch (error) { }
    }, [cleanDocs]);
   
    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useMemo(() => {
        try {

            const periodSelected = [];
            calculatedDaysDocs.map((doc) => {
                //clean date
                const filterDate = new Date(doc.docData.inceptionDate);
                filterDate.setHours(0, 0, 0);
                //filter period choosen
                if (new Date(filterDate).valueOf() >= new Date(PendingListFilterFromDateRN).valueOf() && new Date(PendingListFilterToDateRN).valueOf() >= new Date(filterDate).valueOf()) {
                    periodSelected.push(doc);
                }
            });
            setSelectedPeriodFilteredDocs(periodSelected);

        } catch (error) { }
    }, [calculatedDaysDocs, PendingListFilterFromDateRN, PendingListFilterToDateRN]);
    console.log(selectedPeriodFilteredDocs.length)
    //################################################################
    //prepare obj
    const [finalData, setFinalData] = useState([]);
    useMemo(() => {
        try {

            const data = [];
            selectedPeriodFilteredDocs.map((doc) => {
                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                //look for vr
                const valuationReportDocuments = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Valuation Report') {
                            valuationReportDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //look for lb
                const logBookDocuments = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Logbook') {
                            logBookDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //look for sc
                const stickerCopyDocuments = [];
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Motor Certificate') {
                            stickerCopyDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //look for pd
                const policyDocuments = [];
                if (doc.docData.associateDocuments) {
                    Object.values(doc.docData.associateDocuments).map((item) => {
                        if (item.documentUploadCategory === 'Policy Document') {
                            policyDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                //dispatch status
                let dateDispatched = 'On Hold';
                if (doc.docData.riskNoteDispatch) {
                    dateDispatched = DateFormat(doc.docData.riskNoteDispatch.date);
                }


                data.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    transactionType: doc.docData.transactionType,
                    businessType: doc.docData.businessType,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                    dateSubmittedToInsurer: dateDispatched,
                    daysPending: doc.daysPending,
                    VR: valuationReportDocuments,
                    LB: logBookDocuments,
                    SC: stickerCopyDocuments,
                    PD: policyDocuments,
                })
            });

            setFinalData(data)

        } catch (error) { }
    }, [selectedPeriodFilteredDocs]);


    //################################################################
    //print header
    const printHeaderContent = <section>
        <div className='print_only_component'>
            <center><p className='bold'>PENDING LIST</p></center>
            <div className='h_10'></div>
            <center><p className='bold'>PERIOD : {DateFormat(PendingListFilterFromDateRN)} - {DateFormat(PendingListFilterToDateRN)}</p></center>
            <div className='h_10'></div>
            <center><p className='bold'>INSURER : {PendingListInsurerFilterRN}</p></center>
            <div className='h_10'></div>
        </div>
    </section>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    //################################################################
    //################################################################
    //################################################################
    //grid actions
    // const headerData = 'PENDING LIST    ||    INSURER: ' + PendingListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(PendingListFilterFromDateRN) + ' - ' + DateFormat(PendingListFilterToDateRN);

    // const docDef = {
    //     content: [
    //         {
    //             layout: 'lightHorizontalLines', // optional
    //             table: {
    //                 // headers are automatically repeated if the table spans over multiple pages
    //                 // you can declare how many rows should be treated as headers
    //                 headerRows: 1,
    //                 widths: [12, 'auto', 25, 'auto', 'auto', 80, 25, 25, 80, 25, 25, 25,],
    //                 heights: function (row) {
    //                     if (row == 0) { return 25 }
    //                 },
    //                 body: [
    //                     ['NO', 'Client', 'TRANSACTION TYPE', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCE DATE', 'EXPI DATE', 'DETAILS', 'POLICY NET', 'DATE SUB', 'DAYS PENDING'],
    //                     ...currentData
    //                 ]
    //             }
    //         }
    //     ],
    //     defaultStyle: {
    //         fontSize: 6,
    //     },
    //     pageOrientation: 'landscape',
    //     pageMargins: [10, 15, 10, 15],
    //     header: headerData,
    //     footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
    // }



    //body: "{\"id\":\"3970a1b0-6e27-448a-adfc-0083db15b2fb\", \"tokens\":{\"design_token1\":\"Hi\",\"design_token2\":\"Hello\",\"design_token3\":\"World\",\"subject_token1\":\"XYZ\"}, \"recipient\":\"james@sample.com\"}",
    //body: "{\"name\":\"cm\"}"
    //firebase serve --only functions
    //firebase deploy --only functions
    //http://localhost:5000/simia-9e230/us-central1/addDocument?text="{doc:'doc'}"
    //https://us-central1-simia-9e230.cloudfunctions.net/addDocument


    // const qw = (data) => {

    //     const functions = getFunctions();
    //     const addMessage = httpsCallable(functions, 'hello');
    //     addMessage(JSON.stringify({
    //         doc: data,
    //         docCategory: "Pending List",
    //         date: new Date(),
    //         user: currentUser,
    //     }))
    //         .then((result) => {
    //             // Read result of the Cloud Function.
    //             /** @type {any} */
    //             const data = result.data;
    //             const sanitizedMessage = data.text;
    //             //const q = JSON.parse(result)
    //             console.log(result)
    //         })
    //         .catch((error) => {
    //             // Getting the Error details.
    //             const code = error.code;
    //             const message = error.message;
    //             const details = error.details;
    //             // ...
    //             console.log(error)
    //         });
    // }

    // const generate = () => {
    //     const pdfDocGenerator = pdfMake.createPdf(docDef);
    //     pdfDocGenerator.getBase64((data) => {
    //         qw(data)
    //     });
    // }

    //################################################################
    // grid states
    const [columnsSelected, setColumnsSelected] = useState([]);
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [exportData, setExportData] = useState([]);
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['#', 'clientName', 'transactionType', 'businessType', 'policyNumber','policyClass', 'endorsmentNumber', 'inceptionDate', 'expiryDate','details', 'policyNet']
    
    const exportToExcelFileName = 'PENDING LIST    --    INSURER: ' + PendingListInsurerFilterRN + '    --    PERIOD: ' + DateFormat(PendingListFilterFromDateRN) + ' - ' + DateFormat(PendingListFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);


    //################################################################
    // print only dispatched
    const printOnlyDispatched = () => {

        const a = exportData.filter(doc => doc.daysPending > 0)

        const sortedList = a.sort(byId);
        function byId(a, b) {
            return parseInt(b.daysPending) - parseInt(a.daysPending)
        }

        const onlyDispatchedData = []
        sortedList.map((doc, index) => {
            let details = '';
            let policyNumber = '';
            let inceptionDate = '';
            let expiryDate = '';
            let policyNet = '';
            if (doc.transactionType === 'Action Memo') {
                details = doc.policyNumber;
            }
            else {
                details = doc.details;
                policyNumber = doc.policyNumber;
                inceptionDate = doc.inceptionDate;
                expiryDate = doc.expiryDate;
                policyNet = doc.policyNet;
            }
            if (doc.daysPending > 0) {
                onlyDispatchedData.push([
                    index + 1,
                    doc.clientName,
                    doc.transactionType,
                    doc.businessType,
                    policyNumber,
                    doc.endorsmentNumber,
                    doc.policyClass,
                    inceptionDate,
                    expiryDate,
                    details,
                    policyNet,
                    doc.dateSubmittedToInsurer,
                    doc.daysPending,
                ])
            }
        })

        const onlyDispatchedheaderData = 'PENDING LIST    ||    INSURER: ' + PendingListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(PendingListFilterFromDateRN) + ' - ' + DateFormat(PendingListFilterToDateRN) + '    ||    DOCUMENTS DISPATCHED ';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 25, 25, 'auto', 'auto', 80, 25, 25, 'auto', 25, 25, 25,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Client', 'TRANSACTION TYPE', 'BUSINESS TYPE', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCE DATE', 'EXPI DATE', 'DETAILS', 'POLICY NET', 'DATE SUB', 'DAYS PENDING'],
                            ...onlyDispatchedData
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();

    }

    //################################################################
    // print only on hold
    const printOnlyOnHold = () => {

        const a = exportData.filter(doc => doc.daysPending < 1)

        const sortedList = a.sort(byId);
        function byId(a, b) {
            return parseInt(b.daysPending) - parseInt(a.daysPending)
        }

        const onlyOnHoldData = []
        sortedList.map((doc, index) => {
            if (doc.daysPending < 1) {
                onlyOnHoldData.push([
                    index + 1,
                    doc.clientName,
                    doc.transactionType,
                    doc.businessType,
                    doc.policyNumber,
                    doc.endorsmentNumber,
                    doc.policyClass,
                    doc.inceptionDate,
                    doc.expiryDate,
                    doc.details,
                    doc.policyNet,
                    doc.dateSubmittedToInsurer,
                    doc.daysPending,
                ])
            }
        })

        const onlyDispatchedheaderData = 'PENDING LIST    ||    INSURER: ' + PendingListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(PendingListFilterFromDateRN) + ' - ' + DateFormat(PendingListFilterToDateRN) + '    ||    DOCUMENTS ON HOLD ';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 25, 25, 'auto', 'auto', 80, 25, 25, 'auto', 25, 25, 25,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Client', 'TRANSACTION TYPE', 'BUSINESS TYPE', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCE DATE', 'EXPI DATE', 'DETAILS', 'POLICY NET', 'DATE SUB', 'DAYS PENDING'],
                            ...onlyOnHoldData
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();

    }

    //################################################################
    // print  selected
    const printSelected = () => {

        const sortedList = columnsSelected.sort(byId);
        function byId(a, b) {
            return parseInt(b.daysPending) - parseInt(a.daysPending)
        }

        const onlyDispatchedData = []
        sortedList.map((doc, index) => {
            let details = '';
            let policyNumber = '';
            let inceptionDate = '';
            let expiryDate = '';
            let policyNet = '';
            if (doc.transactionType === 'Action Memo') {
                details = doc.policyNumber;
            }
            else {
                details = doc.details;
                policyNumber = doc.policyNumber;
                inceptionDate = doc.inceptionDate;
                expiryDate = doc.expiryDate;
                policyNet = doc.policyNet;
            }
            if (doc.daysPending > 0) {
                onlyDispatchedData.push([
                    index + 1,
                    doc.clientName,
                    doc.transactionType,
                    doc.businessType,
                    policyNumber,
                    doc.endorsmentNumber,
                    doc.policyClass,
                    inceptionDate,
                    expiryDate,
                    details,
                    policyNet,
                    doc.dateSubmittedToInsurer,
                    doc.daysPending,
                ])
            }
        })

        const onlyDispatchedheaderData = 'PENDING LIST    ||    INSURER: ' + PendingListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(PendingListFilterFromDateRN) + ' - ' + DateFormat(PendingListFilterToDateRN) + '    ||    DOCUMENTS DISPATCHED ';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 25, 25, 'auto', 'auto', 80, 25, 25, 'auto', 25, 25, 25,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 13, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Client', 'TRANSACTION TYPE', 'BUSINESS TYPE', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCE DATE', 'EXPI DATE', 'DETAILS', 'POLICY NET', 'DATE SUB', 'DAYS PENDING'],
                            ...onlyDispatchedData
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();

    }

    //################################################################
    // update date submitted to insurer
    const updateDateSubmittedToInsurer = () => {
        setRightNavContentRN('PendingListGridUpdateDispatchDate')
    }

    //################################################################
    //################################################################
    //step 3
    //show msg
    const showSuccessMSGOnDispatchDateUpdate = (newCreditDocID) => {
        const onePropObj = {
            setView: 'Pending List Grid',
            previousView: 'Pending List Grid',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Dispatch Date Update Successful.'
        }
        props.onePropDown(onePropObj);

        setReload(Math.random(1000, 100000))
    }

    //################################################################
    //step 2
    //update date
    const updateRiskNoteDispatchedDateHandler = async (receivedDocID) => {

        try {

            const washingtonRef = doc(db, "PolicyDetails", receivedDocID);

            await updateDoc(washingtonRef, {
                'riskNoteDispatch.date': pendingListUpdateDispatchDateInputRN,
                'riskNoteDispatch.updatedBy': currentUser,
                'riskNoteDispatch.updateOn': new Date(),
            });


        } catch (error) { }
    }

    //################################################################
    //step 1
    //confirm action n proceed
    const a = () => {
        try {

            setPendingListUpdateDispatchDateInputHandlerRN(false);

            const executeAbort = ConfirmAction('Confirm You Want To Update Dispatch Date For The Selected Policies?');
            if (!executeAbort) { return false }

            setIsLoading(true);
            columnsSelected.map((doc) => {
                updateRiskNoteDispatchedDateHandler(doc.docID);
            })
            setIsLoading(false);

            showSuccessMSGOnDispatchDateUpdate();

        } catch (error) { }
    }

    useEffect(() => {
        if (pendingListUpdateDispatchDateInputHandlerRN) {
            a();
        }
    }, [pendingListUpdateDispatchDateInputHandlerRN])

    //################################################################
    // on view policy
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'Pending List Edit',
            previousView: 'Pending List Final',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>



    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
            //ProcessClientStatementTransactions({ clientName: selectedRow.client, insurer: 'MAYFAIR INSURANCE COMPANY LIMITED' })
        });
        setColumnsSelected(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, colSpan: params => params.data.transactionType === 'Action Memo' ? 7 : 1, },
        // { field: 'daysPending', headerName: 'Days', sort: 'desc', },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, wrapText: true, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        { field: 'dateSubmittedToInsurer', headerName: 'Sub Date', sortable: false, filter: false, width: 100, },
        { field: 'daysPending', headerName: 'Days', sort: 'desc', },
        { field: 'transactionType', headerName: 'Voucher', width: 40 },
        { field: 'businessType', headerName: 'Type', width: 40 },
        {
            field: 'VR', headerName: 'VR', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'LB', headerName: 'LB', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'SC', headerName: 'SC', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
        {
            field: 'PD', headerName: 'PD', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);


    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine" ref={componentRef}>
            {printHeaderContent}
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
            <div className={styles.pending_list_grid_page_break}></div>
        </div>
    </section>


    return (
        <section>
            <section className='pending_list_grid'>
                <div className='body_section_header' id="q">
                    <p>Pending List</p>
                    <div className='cursor-pointer'>
                        <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                        <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printOnlyDispatched}>Print Dispatched</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printOnlyOnHold}>Print On Hold </p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={updateDateSubmittedToInsurer}>Dispatch Date</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('PendingListGridFilterDate') }}>Filter Date</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    {content}
                </div>
            </section>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default PendingListGrid;