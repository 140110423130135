import React, { useRef, useState } from "react";
import db from "../../../firebase";
import { runTransaction, doc } from "firebase/firestore";
import { useRecoilState, useRecoilValue } from "recoil";
import { rightClickedPayroleSettingdCategory, activeBrokerBranchPortfolio, reloadOnSuccessPayroleSubSettingCreationHandler } from "../../../atoms";
import Loader from "../../Body/Loader/Loader";


const CreatePayroleSubCategory = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const rightClickedPayroleSettingsCategoryRN = useRecoilValue(rightClickedPayroleSettingdCategory)
    const activeBrokerBranchPortfolioRN = useRecoilValue(activeBrokerBranchPortfolio);
    const [reloadOnSuccessPayroleSubSettingCreationHandlerRN, setReloadOnSuccessPayroleSubSettingCreationHandler] = useRecoilState(reloadOnSuccessPayroleSubSettingCreationHandler)

    //################################################################
    // get user input
    const subCategoryNameRef = useRef(null);
    const conditionRef = useRef(null);

    //################################################################
    // show fail msg
    const displayFailMSG = () => {
        const onePropObj = {
            setView: 'Payrole Settings',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payrole Sub Setting Already Exist',
        }
        props.onePropDown(onePropObj);
    }

    //################################################################
    // show success msg
    const displaySuccessCreateMSG = () => {
        setReloadOnSuccessPayroleSubSettingCreationHandler(true);

        const onePropObj = {
            setView: 'Payrole Settings',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Payrole Sub Setting Created Successfully.',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    // post data
    const createPolicySubCategoryHandler = async () => {
        try {
            if (!subCategoryNameRef.current.value || !conditionRef.current.value) {
                return false
            }

            setIsLoading(true);

            const sfDocRef = doc(db, "PayroleData", "Zp3LTqUfsvz2u7FFnRmB");

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const allData = sfDoc.data();
                const payroleCategory = rightClickedPayroleSettingsCategoryRN.categoryID;
                const insurerData = (allData[activeBrokerBranchPortfolioRN]['payroleSettings'][payroleCategory]);

                if (!Object.keys(insurerData).includes(conditionRef.current.value)) {
                    const newObj = {
                        ...insurerData,
                        [subCategoryNameRef.current.value]: {
                            condition: conditionRef.current.value
                        }
                    }
                    const a = activeBrokerBranchPortfolioRN + '.payroleSettings.' + payroleCategory
                    transaction.update(sfDocRef, { [a]: newObj });
                    displaySuccessCreateMSG();

                }
                else {
                    displayFailMSG();
                }

            });

            setIsLoading(false);

        } catch (e) { console.log("Transaction failed: ", e); }

    }

    //################################################################
    // crete insurer Content
    const createInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <span className='border px-1.5 py-0.5 border-white rounded-sm text-[10px]'>Category</span>
            <div className='h_2 '></div>
            <p>{rightClickedPayroleSettingsCategoryRN.categoryID}</p>
            <div className='h_10'></div>
            <p className='bold'>Create Payrole Sub Category</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input ref={subCategoryNameRef} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Sub Category Name</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input ref={conditionRef} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Condition</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createPolicySubCategoryHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {createInsurerContent}
            {loaderContent}
        </section>
    )
}

export default CreatePayroleSubCategory;