import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import GetAllEmployeeDetails from "../../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import { useRecoilState } from "recoil";
import { rightNavContent , payroleGeneratorSelectedEmployees} from "../../../atoms";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const HumanResourse = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

     //################################################################
    // recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [payroleGeneratorSelectedEmployeesRN, setPayroleGeneratorSelectedEmployeesRN] = useRecoilState(payroleGeneratorSelectedEmployees)

    //################################################################
    //get employees
    const [employeeDocs, setEmployeeDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllEmployeeDetails();
            setEmployeeDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            employeeDocs.map((doc) => {
                finalObj.push({
                    name: doc.docData.name,
                    email: doc.docData.email,
                    employeeID: doc.docData.employeeID,
                    idNo: doc.docData.idNo,
                    kraPin: doc.docData.kraPin,
                    nhifNo: doc.docData.nhifNo,
                    nssfNo: doc.docData.nssfNo,
                    phoneNo: doc.docData.phoneNo,
                    bankName: doc.docData.bankName,
                    bankBranch: doc.docData.bankBranch,
                    bankAccName: doc.docData.bankAccName,
                    bankAccNo: doc.docData.bankAccNo,
                });
            });
            setFinalData(finalObj)

        } catch (error) { }
    }, [employeeDocs])

     //################################################################
    // generate payrole handler
    const generatePayroleHandler = () => {
        const onePropObj = {
            setView: 'Payrole Data Generator',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }
        props.onePropDown(onePropObj);
    }

     //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

     //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
        setPayroleGeneratorSelectedEmployeesRN(a)
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'name', headerName: 'Name', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left',  },
        { field: 'email', headerName: 'Email', width: 150, },
        { field: 'phoneNo', headerName: 'Phone No', sortable: false, filter: false, width: 90, },
        { field: 'employeeID', headerName: 'Employee ID', width: 150, },
        { field: 'idNo', headerName: 'ID No', width: 150, },
        { field: 'kraPin', headerName: 'KRA Pin', sortable: false, filter: false, width: 100, },
        { field: 'nhifNo', headerName: 'NHIF No', sortable: false, filter: false, width: 100, },
        { field: 'nssfNo', headerName: 'NSSF No', width: 150, },
        { field: 'bankName', headerName: 'Bank Name', sortable: false, filter: false, width: 90, },
        { field: 'bankBranch', headerName: 'Bank Branch', width: 40 },
        { field: 'bankAccName', headerName: 'Bank Acc Name', width: 40 },
        { field: 'bankAccNo', headerName: 'Bank Acc No', width: 40 },
        
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Employee Details</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                         <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={generatePayroleHandler}>Generate Payrole</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {/* {loaderContent}
            {URRightClickMenu} */}
        </section>
    )
}

export default HumanResourse;