import React, { useEffect, useState } from "react";
import Loader from "../../../Body/Loader/Loader";
import { useRecoilState } from "recoil";
import { activeInsurerPortfolio } from "../../../../atoms";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import styles from './MainScreenSelectActiveInsurer.module.css'

const MainScreenRightNav = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [activeInsurerPortfolioRN, setActiveInsurerPortfolioRN] = useRecoilState(activeInsurerPortfolio);

    //################################################################
    //get all insurer details
    const [insurerDocs, setInsurerDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //filter insurer details
    const [inactiveInsurerDocs, setInactiveInsurerDocs] = useState([]);
    useEffect(() => {
        try {

            const list = insurerDocs.filter(doc => doc.docID !== activeInsurerPortfolioRN && doc.docData.insurer !== 'SIMIA INSURANCE AGENCY LIMITED');
            setInactiveInsurerDocs(list)

        } catch (error) { }
    }, [insurerDocs, activeInsurerPortfolioRN]);

    //################################################################
    //listen for changes n set insurer


    //################################################################
    //content
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <p className="bold">INSURER ACCOUNTS</p>
            <div className='h_5'></div>
            <div>
                {
                    inactiveInsurerDocs.map((insurer, index) => {
                        return (
                            <div onClick={() => { setActiveInsurerPortfolioRN(insurer.docID) }} className={`mb-2 rounded cursor-pointer ${styles.select_insurer_display}`} key={index}>
                                <div className={`${styles.card_header}`}>
                                    <h3>{insurer.docData.insurer}</h3>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
    return (
        <section className='right_nav_container body_input_styles'>
            {content}
        </section>
    );
}

export default MainScreenRightNav;