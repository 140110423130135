import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllInsurerTransactions from "../../Hooks/BackendConn/InsurerSpecific/GetAllInsurerTransactions";
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { renewedReportFilterFromDate, renewedReportFilterToDate, renewedReportFilterInsurer, renewedReportFilterPolicyClass } from "../../../atoms";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import DateFormat from "../../Hooks/UI/DateFormat";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const RenewedReportGrid = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil states
    const renewedReportFilterFromDateRN = useRecoilValue(renewedReportFilterFromDate);
    const renewedReportFilterToDateRN = useRecoilValue(renewedReportFilterToDate);
    const renewedReportFilterInsurerRN = useRecoilValue(renewedReportFilterInsurer);
    const renewedReportFilterPolicyClassRN = useRecoilValue(renewedReportFilterPolicyClass);

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerTransactions({ transactionType: 'Debit', insurerName: renewedReportFilterInsurerRN });
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, renewedReportFilterInsurerRN]);

    //################################################################
    //remove endor, dj, jv, cn, marine, bond, travel
    const [debitsClean, setDebitsClean] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        debitDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && !item.docData.policyNumber.startsWith('DV')
                && !item.docData.policyNumber.startsWith('JV')
                && !item.docData.policyNumber.startsWith('CN')
                && !item.docData.policyNumber.startsWith('Rev')
                && item.docData.selectedProductSubClass !== '(060) - Marine Hull'
                && item.docData.selectedProductSubClass !== '(061) - Marine Certificate'
                && item.docData.selectedProductSubClass !== '(120) - Immigration Bond'
                && item.docData.selectedProductSubClass !== '(127) - Advance Payment Bond'
                && item.docData.selectedProductSubClass !== '(128) - Custom Bond'
                && item.docData.selectedProductSubClass !== '(093) - Travel'
                && item.docData.selectedProductSubClass !== '(020) - Contractors All Risks'
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled'
                && item.docData.businessType !== 'Nil Endorsment') {
                removedEndorsmentsList.push(item);
            }
        });
        setDebitsClean(removedEndorsmentsList)
    }, [debitDocs]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        debitsClean.map((item) => {
            const oldInceptionDate = new Date(item.docData.expiryDate); // current date
            const newInceptionDate = new Date(oldInceptionDate);
            newInceptionDate.setDate(oldInceptionDate.getDate() + 1); // add one dayF
            const formattedNewInceptionDate = new Date(newInceptionDate)
            formattedNewInceptionDate.setHours(0, 0, 0);

            //filter period choosen
            if (new Date(formattedNewInceptionDate).valueOf() >= new Date(renewedReportFilterFromDateRN).valueOf() && new Date(renewedReportFilterToDateRN).valueOf() >= new Date(formattedNewInceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [debitsClean, renewedReportFilterFromDateRN, renewedReportFilterToDateRN]);

    //################################################################
    //find renewal
    const [renewedDebits, setRenewedDebits] = useState([]);
    useEffect(() => {
        const renewalsSelected = [];
        selectedPeriodFilteredDocs.map((doc) => {
            debitDocs.map((value) => {
                if ((value.docData.policyNumber).replace(/\//g, "").slice(-12) === (doc.docData.policyNumber).replace(/\//g, "").slice(-12)
                    && doc.docData.endorsmentNumber !== value.docData.endorsmentNumber
                    && new Date(value.docData.insurerDebitDate).valueOf() > new Date(doc.docData.insurerDebitDate).valueOf()
                    && !value.docData.endorsmentNumber.startsWith('05/')) {
                    renewalsSelected.push(doc);
                }
            });
        });
        setRenewedDebits(renewalsSelected);
    }, [selectedPeriodFilteredDocs]);

    //################################################################
    //filter policy class
    const [filteredClassDocs, setFilteredClassDocs] = useState([])
    useEffect(() => {
        const a = renewedDebits.filter((doc) => {
            if (renewedReportFilterPolicyClassRN === 'ALL') { return doc }
            else if (renewedReportFilterPolicyClassRN === 'MOTOR') {
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    return doc;
                }
            }
            else if (renewedReportFilterPolicyClassRN === 'NON-MOTOR') {
                if (doc.docData.selectedProductClass !== '(07) - Motor Private'
                    && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
                    && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO') {
                    return doc;
                }
            }
        });
        setFilteredClassDocs(a);
    }, [renewedDebits, renewedReportFilterPolicyClassRN]);

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = filteredClassDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [filteredClassDocs]);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateSortedDocs.map((doc) => {

                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                //look for pd
                const policyDocuments = [];
                if (doc.docData.associateDocuments) {
                    Object.values(doc.docData.associateDocuments).map((item) => {
                        if (item.documentUploadCategory === 'Policy Document') {
                            policyDocuments.push(item.documentDownloadLink)
                        }
                    })
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                    PD: policyDocuments,
                })

            });

            setFinalData(finalObj)

        } catch (error) { }
    }, [dateSortedDocs]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'policyNumber', 'endorsmentNumber', 'policyClass', 'inceptionDate', 'expiryDate', 'details', 'policyNet']

    const exportToExcelFileName = 'Renewed Report    --    INSURER - ' + renewedReportFilterInsurerRN + '    --    PERIOD - ' + DateFormat(renewedReportFilterFromDateRN) + ' - ' + DateFormat(renewedReportFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'Renewed Report    ||    INSURER - ' + renewedReportFilterInsurerRN + '    ||    PERIOD - ' + DateFormat(renewedReportFilterFromDateRN) + ' - ' + DateFormat(renewedReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 25, 25, 'auto', 25],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 9, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
                doc.policyNet,
            ])
        });


        const onlyDispatchedheaderData = 'Renewed Report    ||    INSURER - ' + renewedReportFilterInsurerRN + '    ||    PERIOD - ' + DateFormat(renewedReportFilterFromDateRN) + ' - ' + DateFormat(renewedReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 'auto', 'auto', 'auto', 25, 25, 'auto', 25],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 9, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS', 'POLICY NET'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //view policy
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Insurer Statement Final',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        {
            field: 'PD', headerName: 'PD', sortable: false, filter: false, maxWidth: 60,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section>
            <div className='body_section_header'>
                <p>Renewed Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    )
}

export default RenewedReportGrid;