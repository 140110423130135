import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetLapsedPolicies = async () => {
    try {
        const q = query(collection(db, "PolicyDetails"), where("status", "==", "Lapsed"));

        const querySnapshot = await getDocs(q);

        const DData = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            DData.push({ docData: doc.data(), docID: doc.id })
        });

        if (DData.length < 1) {
            GetLapsedPolicies();
        }
        else {
            return DData;
        }
    }
    catch (error) {
        GetLapsedPolicies();
    }
}

export default GetLapsedPolicies;