import React, { useState, useEffect } from "react";
import db from "../../../firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { useRecoilState } from 'recoil';
import { rightNavContent, selectInsurerViewCN} from '../../../atoms';
import Loader from "../Loader/Loader";
import DatePicker from "react-date-picker";
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";

const ViewCN = (props) => {
    //################################################################
    //recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [selectInsurerViewCNRN, setSelectInsurerViewCNRN] = useRecoilState(selectInsurerViewCN);

    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get user input
    const [CNNumber, setCNNumber] = useState('')
    const [CNDate, setCNDate] = useState(new Date())
    const [narration, setNarration] = useState('')
    const [amount, setAmount] = useState(0)

    //################################################################
    //insurer debit date
    const [insurerDebitDate, setInsurerDebitDate] = useState('');

    useEffect(() => {
        const Dyear = new Date(CNDate).getFullYear();
        const Dmonth = new Date(CNDate).getMonth() + 1;
        const Ddate = new Date(CNDate).getDate();
        const DexpiryDateCleanedJan = Dyear + '-' + Dmonth + '-' + Ddate;
        setInsurerDebitDate(DexpiryDateCleanedJan)
    }, [CNDate])

    //################################################################
    //get doc
    useEffect(() => {

        (async () => {
            const docRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                //console.log("Document data:", docSnap.data());
                setCNNumber(docSnap.data().policyNumber)
                setCNDate(new Date(docSnap.data().insurerDebitDate))
                setNarration(docSnap.data().locationOfTheRisk)
                setAmount(docSnap.data().insurerPolicyNet)
                setSelectInsurerViewCNRN(docSnap.data().insurerName)

            } else {
                //doc.data() will be undefined in this case
                //console.log("No such document!");
            }
        })()

    }, [props.onePropUp.postedDocRef])

    //################################################################
    //step 3
    //show msg
    const displayCNSuccessUpdateMSG = () => {
        const onePropObj = {
            setView: 'View CN',
            previousView: 'View CN',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'CN Updated successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //post data
    async function editCN(CNObj) {
        try {
            setIsLoading(true);
            const docRef = doc(db, "PolicyDetails", props.onePropUp.postedDocRef);

            await updateDoc(docRef, CNObj);
            setIsLoading(false);
            displayCNSuccessUpdateMSG()
        } catch (error) {

        }
    }

    //################################################################
    //step 1
    //create obj for posting
    const editCNPostingObj = () => {
        //create main object for posting
        const CNProcessedData = {
            insurerName: selectInsurerViewCNRN,
            policyNumber: CNNumber,
            inceptionDate: insurerDebitDate,
            expiryDate: insurerDebitDate,
            insurerDebitDate: insurerDebitDate,
            newInceptionDate: CNDate,
            newExpiryDate: CNDate,
            newInsurerDebitDate: CNDate,
            locationOfTheRisk: narration,
            policyNet: amount,
            insurerPolicyNet: amount,
        }

        editCN(CNProcessedData)
    }

    //################################################################
    //step 2
    //change view after success delete
    const displayCNSuccessDeleteMSG = () => {
        const onePropObj = {
            setView: 'CN List',
            previousView: 'View CN',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'CN Record Deleted successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 1
    //delete CN
    async function deleteCN() {
        const executeAbort = ConfirmAction('Confirm You Want To Delete This CN Record?');
        if (!executeAbort) { return false }

        try {
            setIsLoading(true);
            await deleteDoc(doc(db, "PolicyDetails", props.onePropUp.postedDocRef));
            setIsLoading(false);
            displayCNSuccessDeleteMSG()
        } catch (error) {

        }

    }

    //################################################################
    //insurer content
    const insurerContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Insurer Details</p>
                <div className='client_details_controls'>
                    <div className='btn_yellow'>
                        <i className="ri-user-add-line"></i>
                    </div>
                    <div className='w_5'></div>
                    <div className='btn_pink' onClick={() => { setRightNavContentRN('Select Insurer') }}>
                        <i className="ri-search-2-line"></i>
                    </div>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <input value={selectInsurerViewCNRN} type='text' placeholder='Insurer' readOnly />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //insurer constent
    const CNDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>CN Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={CNNumber || ''} onChange={(e) => { setCNNumber(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>CN Number</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Amount</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Insurer Debit Date</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setCNDate} value={CNDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <textarea value={narration || ''} onChange={(e) => { setNarration(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Narration</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //#########################################################################
    //policy controls
    const [hideShowControls, setHideShowControls] = useState('control_list')
    const hideShowControlsClickHandler = (instruction) => {
        setHideShowControls(instruction)
    }

    return (
        <section className="view_CN create_policy_section">
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>View CN</p>
                    <div className='view_policy_controls'>
                        <span onMouseOver={() => hideShowControlsClickHandler('newlist shadow')} className='btn_pink toogle'>Controls</span>
                        <div className={hideShowControls} onMouseLeave={() => hideShowControlsClickHandler('control_list')}>
                            <div onClick={editCNPostingObj} className="controls_items">
                                <p>Save Changes</p>
                            </div>
                            <div onClick={deleteCN} className="controls_items">
                                <p>Delete CN Record</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    {insurerContent}
                    {CNDetailsContent}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default ViewCN;