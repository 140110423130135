import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { postPaymentSelectedVendor } from "../../../atoms";
import { vendorStatementFilterFromDate, vendorStatementFilterToDate } from "../../../atoms";
import Worker from './VendorStatement.Worker.js';
import NumberFormat from "../../Hooks/UI/NumberFormat";

const VendorStatement = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const postPaymentSelectedVendorRN = useRecoilValue(postPaymentSelectedVendor);
    const vendorStatementFilterFromDateRN = useRecoilValue(vendorStatementFilterFromDate);
    const vendorStatementFilterToDateRN = useRecoilValue(vendorStatementFilterToDate)

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            setIsLoading(true);
            var worker = new Worker();

            const data = {
                vendorID: postPaymentSelectedVendorRN._id,
                vendorStatementFilterFromDateRN: vendorStatementFilterFromDateRN,
                vendorStatementFilterToDateRN: vendorStatementFilterToDateRN,
            }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setFinalData(event.data);
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [postPaymentSelectedVendorRN]);

    const content = <section className='f_7'>
        <table className='w-full table-fixed border-collapse border border-slate-500'>
            <thead>
                <tr>
                    <td className='w-1/9 border border-slate-600 p-1'>Date</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Receipt / Invoice No</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Credit Account</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Debit Account</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Cheque No</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Debit</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Credit</td>
                    <td className='w-1/9 border border-slate-600 p-1'>Cummulative Outstanding</td>
                </tr>
            </thead>
            <tbody>
                {
                    finalData.map((doc, index) => {
                        return (
                            <tr key={index}>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.date}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.receiptNo}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.modeOfPayment}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.expenseAccount}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>{doc.paymentID}</td>
                                <td className='w-1/9 border border-slate-600 p-1'>
                                    {
                                        doc.category === 'Invoice' && NumberFormat(doc.amount)
                                    }
                                </td>
                                <td className='w-1/9 border border-slate-600 p-1'>
                                    {
                                        doc.category === 'Expense' && ' ( ' + NumberFormat(doc.amount) + ' ) '
                                    }
                                </td>
                                <td className='w-1/9 border border-slate-600 p-1'>{NumberFormat(doc.cummulativeOutstanding)}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Vendor Statement</p>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default VendorStatement;