const STDClauses = {
  defaultValue: [{ clauseItem: '' }],

  //020
  ContractorsAllRisks: [
    { clauseItem: 'endorsement 001- strike, riot and civil commotion' },
    { clauseItem: 'endorsement 010' },
    { clauseItem: 'endorsement 113 inland transit' },
    { clauseItem: 'endorsement 119' },
    { clauseItem: 'endorsement 302- surrounding property and third party Liability' },
    { clauseItem: 'endorsement 108' },
    { clauseItem: 'Endorsement 110' },
    { clauseItem: 'Memo 1- No known Losses' },
    { clauseItem: 'Declaration Clause' },
    { clauseItem: 'Communicable disease exclusion' },
    { clauseItem: 'endorsement 103' },
    { clauseItem: 'endorsement 120' },
    { clauseItem: 'Endorsement 014' },
    { clauseItem: 'Annexure III' },
    { clauseItem: 'Premium Payement warranty' },
    { clauseItem: 'Materials on Site Warranty' },
    { clauseItem: 'Theft Clause' },
    { clauseItem: 'Annexure V' },
    { clauseItem: 'endorsement 302- surrounding property and third party Liability' },
    { clauseItem: 'Endorsement 111' },
    { clauseItem: "Watchman (insured's employee ) clause" },
    { clauseItem: 'Annexure A' },
    { clauseItem: 'Annexure IV' },
    { clauseItem: 'earthquake Extension' },
    { clauseItem: 'payement on account clause' },
    { clauseItem: 'Endorsements 107- warranty concerning camps and stores' },
    { clauseItem: 'endorsement 001' },
    { clauseItem: 'endorsement 002- cross liability' },
    { clauseItem: 'Endorsements 112' },
    { clauseItem: 'Endorsements 102- underground cables pipes and other facilities' },
    { clauseItem: 'endorsement 003-Maintenance visits Cover' },
  ],

  //deductibles
  ContractorsAllRisksDeductible: [
    { deductibleItem: 'ACT OF GOD - 10% of loss minimum 1% of Sum Insured' },
    { deductibleItem: 'BURGLARY/ANY OTHER CLAIM - 10% of loss minimum 0.5% of Sums Insured' },
    { deductibleItem: 'THIRD PARTY LIABILITY - Kshs. 100,000/- eel' },
    { deductibleItem: 'CPM EXCESS - 10% of claim Amount min kshs. 100,000/-' },
    { deductibleItem: 'CAR proposal form completion' },
  ],

  //021
  ContractorsPlantAndMachinery: [
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Warranty for Maintenance of Contract' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Reinstatement Of Loss Clause' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION Commotion' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Machinery Breakdown Extension' },
    { clauseItem: 'Machinery Breakdown Extension' },
    { clauseItem: 'Annexure IV' },
    { clauseItem: 'Annexure V' },
    { clauseItem: 'Lien Clause' },
    { clauseItem: 'Endorsement 001' },
    { clauseItem: 'Endorsement 401 - Cover for Inland Transit' },
    { clauseItem: 'Endorsement 001 - Strike, Riot and Civil' },
    { clauseItem: 'Endorsement 302 - Surrounding Property & Third Party Liability' },
    { clauseItem: 'Annexure A' },
    { clauseItem: 'Annexure III' },
  ],

  //deductibles
  ContractorsPlantAndMachineryDeductible: [
    { deductibleItem: '1	10% of each and every loss,minimum ksh.500,000/-' },
  ],

  //023
  MachineryBreakDown: [
    { clauseItem: 'Engineering 1 & 2' },
    { clauseItem: 'Annexure III' },
    { clauseItem: 'Incorporation of TIC No. 22' },
    { clauseItem: 'Incorporation of TIC No. 22' },
    { clauseItem: 'Annexure V' },
    { clauseItem: 'Endorsement 391' },
    { clauseItem: 'Endorsement 303' },
    { clauseItem: "Watchman (Insured's Employee) Clause" },
    { clauseItem: 'Endorsement 331' },
    { clauseItem: 'Endorsement 344' },
    { clauseItem: 'Incorporation of TIC No. 22' },
    { clauseItem: 'Endorsement 343' },
    { clauseItem: 'Annexure IV' },
    { clauseItem: 'Endorsement 332' },
    { clauseItem: 'Endorsement 345' },
    { clauseItem: 'Incorporation of TIC No. 22' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Incorporation of TIC No.22' },
    { clauseItem: 'Incorporation of TIC No. 22' },
  ],

  //deductibles
  MachineryBreakDownDeductible: [
    { deductibleItem: '10% of Claim Amount,Minimum Kshs. 50,000/-' },
  ],

  //024
  ComputerElectronicEquipment: [
    { clauseItem: 'Endorsement 001 - Strike, Riot and Civil' },
    { clauseItem: 'Endorsement 001' },
    { clauseItem: 'Endorsement 002 - Cross Liability' },
    { clauseItem: 'Annexure A' },
    { clauseItem: 'Annexure IV' },
    { clauseItem: "Watchman (Insured's Employee) Clause" },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Automatic Additions and Deletions Clause' },
    { clauseItem: 'Warranty for Maintenance of Contract' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Reinstatement of Loss Clause' },
    { clauseItem: 'Endorsement 503 Cover for Theft' },
    { clauseItem: 'Endorsement 534 - Warranty Concerning Air conditioning plant' },
    { clauseItem: 'Endorsement 538 - Power Surge Protection device' },
    { clauseItem: 'Endorsement 563 - Special Condition Concerning obsolete equipment' },
    { clauseItem: 'Endorsement 563 - Special Condition Concerning delay in repair' },
    { clauseItem: 'Endorsement 591 - Warranty for Maintenance Contract' },
    { clauseItem: 'Endorsement 592 - Distinction between Maintenance Contract Services and Insurance  Cover' },
    { clauseItem: 'Professional Security Service Clause' },
    { clauseItem: 'Electrical Derangement' },
    { clauseItem: 'Hold Up' },
    { clauseItem: 'Nuclear Energy Risks Exclusion Clause, 1994 (NMA 1975)' },
    { clauseItem: 'Terrorism Exclusion Clause for Contamination andExplosive ' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Radioactive Exclusion Clause' },
    { clauseItem: 'Endorsement 564- Special Condition Concerning Delay in Repair' },
    { clauseItem: 'Endorsement 502 -Cover of Valves and Tubes' },
    { clauseItem: 'Endorsement 505 -Cover for earthquake' },
    { clauseItem: 'Electronic Date Recognition Clause Edre' },
    { clauseItem: 'Mechanical Derangement' },
    { clauseItem: 'Cancellation' },
  ],

  //deductibles
  ComputerElectronicEquipmentDeductible: [
    { deductibleItem: 'Normal Excess Percentage of Claim Amount With Minimum 5% each and every loss minimum KSH 10,000.00' },
    { deductibleItem: 'Theft: 25% each and every loss minimum KSH 10,000.00' },
  ],

  //026
  LossOfProfitFollowingMachineryBreakdown: [
    { clauseItem: 'Annexure V' },
    { clauseItem: 'Annexure III' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Endorsement 891. Delay In Repair' },
    { clauseItem: 'Annexure IV' },
    { clauseItem: 'Time Excess' },
    { clauseItem: 'Endorsement 861: Increased Cost Of Electricity, Water, Gas Or Steam Supply' },
    { clauseItem: 'Annexure A' },
    { clauseItem: 'Terrorism Exclusion Clause (All Other Terms & Conditions Remain Unaltered)' },
  ],

  //030
  DomesticPackage: [
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Seventy Two Hours' },
    { clauseItem: 'Cost Of Demolitions And Clearing And Erections Of Hoardings' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Earthquake Excess Clause' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: 'Public Utilities' },
    { clauseItem: 'Breach Of Warranties' },
    { clauseItem: 'Petrol and mineral oil warranty 1' },
    { clauseItem: 'Premium payment warranty' },
    { clauseItem: 'Adjoining Buildings' },
    { clauseItem: 'Payment On Account Clause' },
    { clauseItem: 'Loss from Vehicles Clause' },
    { clauseItem: 'Misdescription Clause' },
    { clauseItem: 'Electrical Clause 1' },
    { clauseItem: 'Emergency Entry' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Fire Brigades Clause' },
    { clauseItem: 'Memo 1 - VALUATION OF JEWELRY & RELATED VALUABLES' },
    { clauseItem: 'Memo 2 - PAIRS AND SETS CLAUSE' },
    { clauseItem: 'Alterations And Repairs Clause' },
    { clauseItem: 'Public Authorities Clause' },
    { clauseItem: 'Pollution / Contamination Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause Edrc' },
    { clauseItem: 'Designation Of Property Clause' },
    { clauseItem: 'Internal Removal Clause' },
  ],

  //deductibles
  DomesticPackageDeductible: [
    { deductibleItem: 'Excess - Sec C 10% of each and every loss minimum Kshs. 10,000/-' },
    { deductibleItem: 'Peril E : 2.5% of Sum Insured subject to maximum KSH .00 per claim whichever is less in respect of any' },
    { deductibleItem: 'loss or damage directly or indirectly caused by earthquake' },
  ],

  //031
  DomesticContents: [
    { clauseItem: 'Memo 1 - VALUATION OF JEWELRY & RELATED VALUABLES' },
    { clauseItem: 'Premium payment warranty' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Reinstatement Value Clause' },
    { clauseItem: 'Reinstatement of Loss Clause' },
    { clauseItem: 'Internal Removal Clause' },
    { clauseItem: 'Loss from Vehicles Clause' },
    { clauseItem: 'REPLACEMENT VALUE CLAUSE' },
    { clauseItem: 'RIOT AND STRIKE CLAUSE' },
    { clauseItem: 'Pollution / Contamination Exclusion Clause' },
    { clauseItem: 'Petrol and mineral oil warranty' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Other Tenants Clause' },
    { clauseItem: 'Payment On Account Clause' },
    { clauseItem: 'Entertainment Equipment' },
    { clauseItem: 'Personal Effects/Possession' },
    { clauseItem: 'Malicious damage' },
    { clauseItem: 'Breach Of Warranties' },
    { clauseItem: 'Electronic Date Recognition Clause Edrc' },
    { clauseItem: 'Guest Effects' },
    { clauseItem: 'Single Article / Pair Clause' },
    { clauseItem: 'Loss Reduction Clause' },
    { clauseItem: 'Earthquake Excess Clause' },
    { clauseItem: 'Temporary Removal' },
    { clauseItem: 'Fire Brigades Clause' },
    { clauseItem: 'Extensions Under Section C' },
    { clauseItem: 'Memo 2 - PAIRS AND SETS CLAUSE' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: "Domestic Worker's Property" },
    { clauseItem: 'Valuables - (Unspecified Items)' },
  ],

  //deductibles
  DomesticContentsDeductible: [
    { deductibleItem: 'Single article limit: Contents -5% of sum insured - Unless specifically stated' },
    { deductibleItem: 'Earthquake Excess .....subject to 2% of claim amount maximum Kshs. 5,000,000/-' },
    { deductibleItem: 'Excess (Section B&C).....subject to 10% of claim amount minimum Kshs. 5,000/-' },
  ],

  //040
  FireIndustrial: [
    { clauseItem: 'Pollution/Contamination' },
    { clauseItem: 'Earthquake Excess Clause' },
    { clauseItem: 'Workmen on Premises Clause' },
    { clauseItem: 'Electrical Clause III' },
    { clauseItem: 'Escalation Clause' },
    { clauseItem: 'Cost Of Demolition And Clearing And Erection Of Hoarding Clause III' },
    { clauseItem: 'Breach of Conditions and Warranties' },
    { clauseItem: 'Memo 6 - Seventy Two Hours Clause' },
    { clauseItem: 'Landlords Clause' },
    { clauseItem: 'Replacement Value Clause' },
    { clauseItem: 'Municipal Plans Scrutiny Fee Clause' },
    { clauseItem: 'Alterations And Repairs Clause' },
    { clauseItem: 'Public Utilities' },
    { clauseItem: 'Memo 2 - Debris Removal' },
    { clauseItem: 'Appraisement Clause' },
    { clauseItem: 'Accidental Error and Omission' },
    { clauseItem: 'Safe and Rooks Clause' },
    { clauseItem: 'Reinstatement of Loss Clause' },
    { clauseItem: 'Fire Extinguishing expense - Kshs 500,000.00' },
    { clauseItem: '85% Condition of Average' },
    { clauseItem: "Consulting Engineer's Fees Clause" },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Electronic Date Recognition Clause Edrc' },
    { clauseItem: 'Strike, Riot and Civil Commotion' },
    { clauseItem: 'Designation of property clause' },
    { clauseItem: 'Notice of Cancellation Clause' },
    { clauseItem: 'Tenants Clause' },
    { clauseItem: 'Adjoining Buildings' },
    { clauseItem: 'Misdescription Clause ' },
    { clauseItem: 'Memo 18 - Increase in Risk!' },
    { clauseItem: 'Nuclear Energy Risks Exclusion' },
    { clauseItem: 'Capital Additions Clause' },
    { clauseItem: 'Bush Fire' },
    { clauseItem: 'Mortgage Clause' },
    { clauseItem: 'Rent Receivable Clause' },
    { clauseItem: 'Rental Value Clause' },
    { clauseItem: 'Public Authorities Clause' },
    { clauseItem: 'Memo 4 - Loss Reduction' },
    { clauseItem: 'Payment On Account Clause' },
    { clauseItem: "Architects' Quantity Surveyors And Other Fees Clause" },
    { clauseItem: 'Cost Of Re-Erection Clause' },
    { clauseItem: 'Petrol and Oil Warranty III' },
    { clauseItem: 'Reinstatement Value Clause (Applicable To Property Other Than Merchandise /or Stock In Trade)' },
    { clauseItem: 'Definition Of Buildings' },
    { clauseItem: 'Fire Brigades Clause' },
    { clauseItem: 'Other Insurance And Declinature' },
    { clauseItem: 'Landlords Fixtures And Fittings' },
    { clauseItem: 'Unoccupancy' },
  ],

  //deductibles
  FireIndustrialDeductible: [
    { deductibleItem: 'Political violence and terrorism deductables : 5% of claim amount, minimum 0.5% of sum insured' },
    { deductibleItem: 'Peril E 2% of Sum insured subject to maximum KSH 5.000.000.00 per location whichever is less in respect of any loss or damage directly or indirectly caused by earthquake during any one period of forty eight hours ' },
    { deductibleItem: 'Peril D,F,G,H........ ...................KSH 2,500.00' },
  ],

  //041
  ConLossFollowingFire: [
    { clauseItem: 'Memo I- Customers And Suppliers' },
    { clauseItem: 'Memo 15- Premium Adjustment' },
    { clauseItem: 'Electronic Date Recognition Clause Edrc' },
    { clauseItem: '50% Rebate Clause' },
    { clauseItem: 'Upwards And 50% Downwards' },
    { clauseItem: 'Memo 16 – Deterioration Of Undamaged Stock' },
    { clauseItem: 'Memo 18 – Increase In Risk' },
    { clauseItem: 'Memo 20 – New / Additional Premises' },
    { clauseItem: 'Departmental Clause' },
    { clauseItem: 'Memo 5 - Professional Accountant Clause' },
    { clauseItem: 'Memo 7 - Alternative Trading Clause' },
    { clauseItem: 'Memo 6 - Payment Of Account Clause' },
    { clauseItem: 'Memo 19 – Waiver Of Material Damage' },
    { clauseItem: 'Provision' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Indemnity Period - 12 Months' },
    { clauseItem: 'Claims Preparation Costs' },
    { clauseItem: 'Memo 2 - Denial Of Access' },
    { clauseItem: 'Memo 3 - Fines And Damages' },
    { clauseItem: 'Memo 13- Reinstatement Of Loss Clause' },
    { clauseItem: 'Memo 17- Alternative Basis' },
    { clauseItem: 'Memo 9 - Salvage Sales Clause' },
    { clauseItem: 'Memo 11 - Failure Of Supply From Public Utilities' },
  ],

  //deductibles
  ConLossFollowingFireDeductible: [
    { deductibleItem: 'Time Excess : 7 days' },
    { deductibleItem: 'Earthquake Excess - 2% of sum insured per location Maximum KSH 5,000,000.00' },
  ],

  //043
  Terrorism: [
    { clauseItem: 'Claims Control Clause' },
    { clauseItem: 'LMA 3100 Sanctions and Limitations Exclusion Clause' },
    { clauseItem: 'Law and Jurisdiction – Kenya' },
    { clauseItem: 'Premium Warranty - 14 Days from policy inception' },
    { clauseItem: 'Insurrection, Revolution or Rebellion' },
    { clauseItem: "Mutiny/ and Coup D'Etat" },
    { clauseItem: 'Sabotage' },
    { clauseItem: 'Act of Terrorism' },
    { clauseItem: 'Memo 4- Loss Reduction' },
  ],

  //deductibles
  TerrorismDeductible: [
    { deductibleItem: '10% of each and every loss minimum Kshs. 100,000/-' },
  ],

  //050
  defaultValue: [
    { clauseItem: 'Limitation Of Jurisdiction' },
    { clauseItem: 'Cancellation Notice' },
    { clauseItem: 'Pollution/Contamination Exclusion Clause' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause (EDRC)' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Gradual Environmental Impairment Exclusion Clause' },
    { clauseItem: 'Food And Drinks Poisoning' },
    { clauseItem: 'Radioactive Exclusion Clause' },
  ],

  //deductibles
  TerrorismDeductible: [
    { deductibleItem: '2% of Limit of Liability any one claim' },
  ],

  //051
  ProfessionalLiability: [
    { clauseItem: 'Extension 3 – Loss Of Documents' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Limitation Of Jurisdiction' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'DISCOVERY – UNILATERAL – 12 MONTHS (12 MONTHS RETIRED DIRECTORS)' },
    { clauseItem: 'Gradual Environmental Impairment Exclusion Clause' },
    { clauseItem: 'Radioactive Exclusion Clause' },
    { clauseItem: 'Extension 2 – Amendment Of Dishonesty Exclusion' },
    { clauseItem: 'Pollution/Contamination Exclusion Clause' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Extension 1 – Libel And Slander' },
    { clauseItem: 'Dishonesty/Malicious Acts Extension' },
    { clauseItem: 'Compensation for Court attendance(included within the event limit) - Limit Sh.500,000/-' },
    { clauseItem: 'Electronic Date Recognition Clause (EDRC)' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
  ],

  //deductibles
  ProfessionalLiabilityDeductible: [
    { deductibleItem: 'Excess...10% of claim amount minimum KSH 10,000.00' },
  ],

  //052
  PublicLiabilty: [
    { clauseItem: 'Radioactive Exclusion Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Fire And Explosion' },
    { clauseItem: 'Car Park Extension' },
    { clauseItem: 'Employees Personal Effects Clause' },
    { clauseItem: 'Tools of Trade' },
    { clauseItem: 'Medical Treatment (First Aid)' },
    { clauseItem: 'Loading And Unloading Of Motor Vehicles' },
    { clauseItem: 'Plants And Machinery' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Flood/Fumes/Pollution' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Flood/Fumes/Pollution' },
    { clauseItem: 'Work Away Risks' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Defective Sanitation' },
    { clauseItem: 'Goods Held In Trust' },
    { clauseItem: 'Temporary Overseas Visits' },
    { clauseItem: 'Directors/Employees Liability' },
    { clauseItem: 'Plant hired In/Out' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Gradual Environmental Impairment Exclusion Clause' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause (EDRC)' },
    { clauseItem: 'Sports And Club Extension' },
    { clauseItem: 'Tenants Liability' },
    { clauseItem: 'Advertising Signs' },
    { clauseItem: 'Pollution/Contamination Exclusion Clause' },
    { clauseItem: 'COMMUNICABLE Disease EXCLUSION' },
    { clauseItem: 'Additional Instructions' },
    { clauseItem: 'Policy Associate Documents' },
  ],

  //054
  ContractualLiabilty: [
    { clauseItem: 'Copy of the standard contract form-A requirement' },
    { clauseItem: 'Laws and Jurisdiction - Kenya' },
    { clauseItem: 'All other terms as per Mayfair Insurance Standard' },
    { clauseItem: 'Excess: 1% of limit of indemnity' },
  ],

  //deductibles
  ContractualLiabiltyDeductible: [
    { deductibleItem: 'excess 1% of limit of indemnity' },
  ],

  //061
  MarineCertificate: [
    { clauseItem: 'Excluding Unexplained/Normal/Ordinary Loss IN Weight/Volume' },
    { clauseItem: 'INSTITUTE DANGEROUS DRUGS CLAUSE' },
    { clauseItem: 'co-mingling clause' },
    { clauseItem: 'inspection of cargo clause' },
    { clauseItem: 'axle load warranty' },
    { clauseItem: 'cargo ism endorsement' },
    { clauseItem: 'communicable diseases exclusion' },
    { clauseItem: 'premium payment warranty' },
    { clauseItem: 'pollution /contamination exclusion clause' },
    { clauseItem: 'institute war cancellation clause (cargo)' },
    { clauseItem: 'important slip' },
    { clauseItem: 'institute strike clauses (cargo)' },
    { clauseItem: 'malicious damage clause' },
    { clauseItem: 'electronic date recognition clause' },
    { clauseItem: 'damage to container' },
    { clauseItem: 'damage to container- as per declaration' },
    { clauseItem: 'institute classification clause' },
    { clauseItem: 'institute radioactive contamination exclusion clause' },
  ],

  //062
  GoodsInTransit: [
    { clauseItem: 'electronic date recognition clause' },
    { clauseItem: 'Strike riot and civil commotion' },
    { clauseItem: 'locked boot/car clause' },
    { clauseItem: 'tarpauline clause' },
    { clauseItem: 'premium payement warranty' },
    { clauseItem: 'malicious damage clause' },
    { clauseItem: 'loading and unloading' },
    { clauseItem: 'overnight warranty' },
    { clauseItem: 'declaration condition' },
    { clauseItem: 'terrorism exclusion clause' },
    { clauseItem: 'Delay or loss of market' },
    { clauseItem: 'Cancellation notice' },
    { clauseItem: 'hold up' },
    { clauseItem: 'jurisdiction clause' },
    { clauseItem: 'riot and strike' },
    { clauseItem: 'Automatic reinstatement of loss' },
    { clauseItem: 'Wider risk cover' },
    { clauseItem: 'Axle load Warranty' },
    { clauseItem: 'unattended motor vehcle warranty' },
    { clauseItem: 'Additional Instructions' },
  ],

  //deductibles
  GoodsInTransitDeductible: [
    { deductibleItem: '10% of claim amount minimum 10,000/-' },
  ],

  //064
  CARRIERSLEGALLIABILITY: [
    { clauseItem: 'Goods/Property in Trust' },
    { clauseItem: 'Fire And Explosion' },
    { clauseItem: 'Flood/Fumes/Pollution' },
    { clauseItem: 'Defective Sanitation' },
    { clauseItem: 'Plants And Machinery' },
    { clauseItem: 'Leased Premises' },
    { clauseItem: 'Work Away Risks' },
    { clauseItem: 'Cross Liability' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: 'Loading and Unloading' },
    { clauseItem: 'Riot & Strike' },
    { clauseItem: 'Hold-Up' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: ' Jurisdiction Clause' },
    { clauseItem: 'Hijack' },
  ],

  //deductibles
  CARRIERSLEGALLIABILITYDeductible: [
    { deductibleItem: '10% Each and Every loss Minimum Kshs. 100,000/-' },
  ],

  //090
  PersonalAccident: [
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Hold-Up Endorsement' },
    { clauseItem: 'Motor Cycling Up To 250cc' },
    { clauseItem: 'Age Limit' },
    { clauseItem: 'Worldwide Limit Clause' },
    { clauseItem: 'Asbestos exclusion clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Pollution / Contamination Exclusion Clause' },
    { clauseItem: '24 Hour cover' },
    { clauseItem: 'Trustee Clause' },
    { clauseItem: 'Hijack Clause' },
    { clauseItem: 'Disappearance Clause' },
    { clauseItem: 'Exposure Clause' },
    { clauseItem: 'Jurisdiction Clause' },
    { clauseItem: 'Repatriation' },
    { clauseItem: 'Assault clause' },
    { clauseItem: 'Electronic Date Recognition Clause EDRC (B)' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Airfare for treatment' },
    { clauseItem: 'Declaration' },
  ],

  //092
  GroupPersonalAccident: [
    { clauseItem: 'Hold-Up Endorsement' },
    { clauseItem: 'Strike Riot and Civil Commotion Extension' },
    { clauseItem: 'Electronic Date Recognition Clause EDRC (B)' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Repatriation' },
    { clauseItem: 'Disappearance Clause' },
    { clauseItem: 'Pollution / Contamination Exclusion Clause' },
    { clauseItem: 'Accumulation Clause' },
    { clauseItem: 'Beneficiaries' },
    { clauseItem: 'Hijack Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Motor Cycling Up To 250cc' },
    { clauseItem: 'Declaration' },
    { clauseItem: 'Trustee Clause' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Exposure Clause' },
    { clauseItem: 'Jurisdiction Clause' },
    { clauseItem: '24 Hour cover' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
  ],

  //0700 comp
  MotorPrivateComprehensive: [
    { clauseItem: 'Windscreen And Window Glass- Limit KSH. 50, 000 or as specified in the schedule' },
    { clauseItem: "New and Young Drivers' Clause" },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Radio Cassette / CD Player Extension Limit Ksh 50, 000 - as specified in the schedule' },
    { clauseItem: 'Track It Device' },
    { clauseItem: 'Excess Protection Clause' },
    { clauseItem: 'Young and / or inexperienced drivers excess' },
    { clauseItem: 'Reinstatement of Loss' },
    { clauseItem: 'Replacement Parts Clause' },
    { clauseItem: 'Political Violence Insurance Endorsement' },
    { clauseItem: 'Important Notes' },
    { clauseItem: 'Anti Theft Device Warranty' },
    { clauseItem: 'Inclusion of Special Perils' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'Strike, Riot and Civil Commotion' },
    { clauseItem: 'Excess Clause' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Carrying Capacity and Seating Capacity Clause' },
    { clauseItem: 'Legal liability to passengers for acts of negligence' },
    { clauseItem: 'Intoxication Clause' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Unobtainable Parts' },
    { clauseItem: 'Pollution / Contamination Clause' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'Endorsement No. 3(i) Third Party Fire and Theft' },
    { clauseItem: 'Electronic Date Recognition Clause - EDRC' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Additional Instructions' },
  ],

  //0700 tpo
  MotorPrivateTPO: [
    { clauseItem: 'New Young Driver Excess Kshs. 5,000/-' },
    { clauseItem: 'Inexperienced Driver Additional Excess Kshs. 5,000/-' },
    { clauseItem: 'Third Party Property Damage Excess Kshs. 7,500/-' },
    { clauseItem: 'Third Party Bodily Injury Excess NIL' },
    { clauseItem: 'Third Party Property Damage Limit Kshs. 5,000,000/' },
    { clauseItem: 'Third Party Injury-Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-)' },
    { clauseItem: 'Electronic Date Recognition Clause.' },
    { clauseItem: 'Driving Licence Clause' },
    { clauseItem: 'New Young Drivers Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Section 1 & Ill of the Policy is deemed to be deleted' },
    { clauseItem: 'Cover Whilst driven by a motor trader' },
    { clauseItem: 'Limitation as to use' },
  ],

  //0701 comp
  MotorCycleComprehensive: [
    { clauseItem: 'Comp/Own Damage Excess 5% of value min Kshs. 7,500.0' },
    { clauseItem: 'Theft Excess 10 % of insured estimated value or Shs. 10, 000/- whichever is higher' },
    { clauseItem: 'New Young Drivers excess Kshs. 5,000.00' },
    { clauseItem: 'Inexperienced Driver excess Kshs. 5,000.00' },
    { clauseItem: 'Third Party Property Damage Excess Kshs. 5,000.00' },
    { clauseItem: 'Third Party Bodily injury Excess' },
    { clauseItem: 'Third Party Property Damage Limit Kshs. 2,000,000.00' },
    { clauseItem: 'Special Perils Extension' },
    { clauseItem: 'Riot & Strike and Civil Commotion Extension' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Excess Protector Clause' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'Limitations as to use' },
    { clauseItem: 'Electronic date recognition clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Towing charges limit Kshs. 5,000.00' },
    { clauseItem: 'Authorized Repair limit Kshs. 1,000.00' },
    { clauseItem: "New and young driver's clause" },
    { clauseItem: 'Driving licence clause' },
    { clauseItem: 'Third party Injury (limits of liability - in respect of bodily injury to any person Ksh. 2,000,000.00 & in respect of a series of claims arising out of any one event Ksh. 6,000,000.00)In respect of any person - Death of or bodily injury to any person Kshs. 3,000,000.00 and in respect of a series of claim arising out of one event - Unlimited' },
  ],

  //0701 tpo
  MotorCycleTPO: [
    { clauseItem: 'Third Party Bodily Injury- Nil' },
    { clauseItem: 'Third Party Property Damage - KSH. 10,000.00' },
    { clauseItem: 'Young and Inexperienced Driver- Additional excess- KSH 10,000.00' },
    { clauseItem: 'Death or Bodily Injury - Any Other Persons ....... KSH 2,000,000.00/=' },
    { clauseItem: 'Series of claims from one event- Any other person...... KSH 6,000,000.00/=' },
    { clauseItem: 'Third Party Property Damage......... KSH 2,000,000.00/=' },
    { clauseItem: 'Cover whilst driven by  motor trader' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Electronic Date Recognition Clause- EDRC' },
    { clauseItem: 'Excess Clause' },
    { clauseItem: "New and Young Drivers' Clause" },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Pollution/ Contamination Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Proposal Form Memorandum' },
  ],

  //0800 comp
  MotorCommercialComprehensive: [
    { clauseItem: 'Comp/Own Damage Excess 5% of value min. Kshs. 20,000.00' },
    { clauseItem: "Theft Excess 20 % of insured estimated value or Shs.20,000/- whichever is higher if the vehicle does not have anti-theft device or 10 % of insured estimated value or Shs.20,000/- whichever is higher if anti-theft device is installed. 5% of the Insured's Estimated value minimum Kshs. 20,000/- if tracking device is installed." },
    { clauseItem: 'New Young Driver Excess Kshs. 7,500.00' },
    { clauseItem: 'In experienced Driver Excess Kshs. 7,500.00' },
    { clauseItem: 'Third Party excess Kshs. 10,000.00' },
    { clauseItem: 'Third Party Bodily Injury Excess....NIL' },
    { clauseItem: 'Third Party Property Damage limit Kshs. 5,000,000.00' },
    { clauseItem: 'Glass Cover extension limit Kshs 50,000.00' },
    { clauseItem: 'Radio Cassette extension limit Kshs. 50,000.00 at 10% Excess on Claim Amount' },
    { clauseItem: 'Special Perils Extension' },
    { clauseItem: 'Riot & Strike Extension' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'Limitations as to use' },
    { clauseItem: 'Electronic date recognition clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Cover whilst driven by motor trader' },
    { clauseItem: 'Pollution/contamination clause' },
    { clauseItem: 'Political Risk Exclusion Clause' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'Excess Protector Endorsement Clause' },
    { clauseItem: 'Towing charges limit Kshs. 50,000.00' },
    { clauseItem: 'Authorized Repair limit Kshs. 50,000.00' },
    { clauseItem: 'Medical Expenses Kshs.50,000.00' },
    { clauseItem: 'New and young Drivers Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Overloading of Vehicles/Unauthorized Passengers' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'Third party Injury. [Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-)in respect of any other Person - Death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Unlimited)' },
    { clauseItem: 'Legal Liability to Two(2) Passengers seated in front Cabin Only. (Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-' },
  ],

  //0800 tpo
  MotorCommercialTPO: [
    { clauseItem: 'New Young Driver Excess Kshs. 7,500/-' },
    { clauseItem: 'Inexperienced Driver Additional Excess Kshs. 7,500/-' },
    { clauseItem: 'Third Party Property Damage Excess Kshs. 10,000/-' },
    { clauseItem: 'Third Party Bodily Injury Excess NIL' },
    { clauseItem: 'Third Party Property Damage Limit Kshs. 5,000,000/-' },
    { clauseItem: 'Third Party Injury-Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-]' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'New Young Drivers Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Section I & III of the Policy is deemed to be deleted' },
    { clauseItem: 'Limitation As To Use Clause' },
  ],

  //0801 tpo
  TractorTPO: [
    { clauseItem: 'Section I & III of this policy is deemed to be deleted' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'CARRYING CAPACITY AND SEATING' },
    { clauseItem: 'CAPACITY CLAUSE' },
    { clauseItem: 'INTOXICATION CLAUSE' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause -EDRC' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'New and Young Drivers’ Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Young and/or inexperienced drivers excess' },
  ],

  //802 comp
  NewTradeComprehensive: [
    { clauseItem: 'Windscreen And Window Glass - As specified in the schedule' },
    { clauseItem: 'Radio Cassette / CD Player / Extension- As specified in the schedule subject to 10% of claim amount' },
    { clauseItem: 'Inclusion of Special Perils' },
    { clauseItem: 'Strike, Riot and Civil Commotion' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Legal liability of passengers for acts of negligence' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Pollution/Contamination Clause' },
    { clauseItem: 'Endorsement No. 3(i) Third Party Fire and Theft' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause -EDRC' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'New and Young Drivers’ Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Tracking Device' },
    { clauseItem: 'Young and/or inexperienced drivers excess' },
    { clauseItem: 'Replacement Parts Clause' },
  ],

  //0804 comp
  PSVComprehensive: [
    { clauseItem: 'Own Damage/Partial Theft - 5% of the Sum Insured subject to a Minimum of KSH 30,000.00' },
    { clauseItem: 'Theft Excess -  5% of the Sum Insured subject to KSH 30,000.00 with Tracking Device' },
    { clauseItem: 'Theft Excess - 10% of the Sum Insured subject to minimum of KSH 30,000.00 with anti-theft Device' },
    { clauseItem: 'Theft Excess - 20% of the Sum Insured subject to minimum of KSH 30,000.00 without anti-theft Device Third Party Bodily Injury    -  Nil' },
    { clauseItem: 'Thrid Party Property Damage - KSH 15,000.00' },
    { clauseItem: 'Young and Inexperienced Drvider  - Additional excess  - KSH 10,000.00' },
    { clauseItem: 'Authorized Repair Limit........KSH 30,000.00/=' },
    { clauseItem: 'Death or Bodily Injury - Any other Persons........KSH 3,000,000.00/=' },
    { clauseItem: 'Death or Bodily Injury to  - Any One Person........KSH 3,000,000.00/=' },
    { clauseItem: 'Medical Expenses In Respect of any one accident........KSH 50,000.00/=' },
    { clauseItem: 'Protection and Removal (Towing Charges) Limit........KSH 50,000.00/=' },
    { clauseItem: 'Series of claim arising out of one event........KSH 20,000,000.00/=' },
    { clauseItem: 'Series of claims from one event - Any other Person........Unlimited' },
    { clauseItem: 'Third Party Property Damage........KSH 5,000,000.00/=' },
    { clauseItem: 'Agreed Value Endorsement' },
    { clauseItem: 'Authorised Driver' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Electronic Date Recognition Clause -EDRC' },
    { clauseItem: 'Endorsement No. 3(i) Third Party Fire and Theft' },
    { clauseItem: 'Excess Protector Clause Important Notes' },
    { clauseItem: 'Inclusion of Special Perils' },
    { clauseItem: 'Legal liability of passengers for acts of negligence' },
    { clauseItem: 'Limitation As To Use' },
    { clauseItem: 'Limitations as to Use (PSV)' },
    { clauseItem: 'New and Young Drivers’ Clause' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'No-Claim Discount' },
    { clauseItem: 'Overloading Of Vehicles/Unauthorized Passengers' },
    { clauseItem: 'Passenger Risk - Vehicles Not Used For Carrying Fare Paying Passengers' },
    { clauseItem: 'Passenger Risk - Vehicles Not Used For Carrying Fare Paying Passengers' },
    { clauseItem: 'Political Violence Insurance Endorsement' },
    { clauseItem: 'Pollution/Contamination Clause' },
    { clauseItem: 'Premium Finance' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Radio Cassette / CD Player (Theft) Extension- Limit KSHS 30,000' },
    { clauseItem: 'Radio Cassette / CD Player / Extension- As specified in the schedule subject to 10% of claim amount Replacement Parts Clause' },
    { clauseItem: 'Strike, Riot and Civil Commotion' },
    { clauseItem: 'Sub- Standard Drivers Limitation Private Hire And Hirer Driving' },
    { clauseItem: 'Tracking Device' },
  ],

  //0807 comp
  GeneralCartageComprehensive: [
    { clauseItem: 'Windscreen And Window Glass - As specified in the schedule' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'Inclusion of Special Perils' },
    { clauseItem: 'Strike, Riot and Civil Commotion' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Legal liability of passengers for acts of negligence' },
    { clauseItem: 'Hire Purchase Agreement' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Pollution/Contamination Clause' },
    { clauseItem: 'Endorsement No. 3(i) Third Party Fire and Theft' },
    { clauseItem: 'Electronic Date Recognition Clause -EDRC' },
    { clauseItem: 'New and Young Drivers’ Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Tracking Device' },
    { clauseItem: 'Political Violence Insurance Endorsement' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'Overloading Of Vehicles/Unauthorized Passengers' },
    { clauseItem: 'Sub- Standard Drivers Limitation Private Hire And Hirer Driving' },
    { clauseItem: 'Excess Protector Clause' },
    { clauseItem: 'Young and/or inexperienced drivers excess' },
  ],

  //0807 tpo
  GeneralCartageTPO: [
    { clauseItem: 'Section I & III of this policy deemed to be deleted' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Political Risks Exclusion Clause' },
    { clauseItem: 'Electronic Date Recognition Clause- EDRC' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: "New and Young Drivers' Clause" },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Overloading Of Vehicles/ Unauthorized Passengers' },
    { clauseItem: 'Sub-Standard Drivers Limitation Private Hire And Hirer Driving' },
    { clauseItem: 'Young and/ or inexperienced drivers excess' },
  ],

  //0811 comp
  SpecialVehicleComprehensive: [
    { clauseItem: 'Comp/Own Damage Excess 5% of value min. Kshs. 20,000.00' },
    { clauseItem: 'Theft Excess 20 % of insured estimated value or Shs.20, 000/- whichever is higher if the vehicle does not have anti-theft device or' },
    { clauseItem: "10 % of insured estimated value or Shs. 20,000/- whichever is higher if anti-theft device is installed. 5% of the Insured's Estimated" },
    { clauseItem: 'value minimum Kshs. 20,000/- if tracking device is installed.' },
    { clauseItem: 'New Young Driver Excess Kshs. 7,500.00' },
    { clauseItem: 'In experienced Driver Excess Kshs. 7,500.00' },
    { clauseItem: 'Third Party excess Kshs. 10,000.00' },
    { clauseItem: 'Third Party Bodily Injury Excess….NIL' },
    { clauseItem: 'Third Party Property Damage limit Kshs. 5,000,000.00' },
    { clauseItem: 'Glass Cover extension limit Kshs. 50,000.00' },
    { clauseItem: 'Radio Cassette extension limit Kshs. 50,000.00 at 10% Excess on Claim Amount' },
    { clauseItem: 'Special Perils Extension' },
    { clauseItem: 'Riot & Strike Extension' },
    { clauseItem: 'Average Clause' },
    { clauseItem: 'Limitations as to use' },
    { clauseItem: 'Electronic date recognition clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Cover whilst driven by motor trader' },
    { clauseItem: 'Pollution/contamination clause' },
    { clauseItem: 'Political Violence and Terrorism Endorsement Clause' },
    { clauseItem: 'No Blame No Excess' },
    { clauseItem: 'Excess Protection Clause' },
    { clauseItem: 'Towing charges limit Kshs. 50,000.00' },
    { clauseItem: 'Authorized Repair limit Kshs. 50,000.00' },
    { clauseItem: 'Medical Expenses Kshs.50,000.00' },
    { clauseItem: 'New and young Drivers ‘Clause' },
    { clauseItem: 'Driving License Clause' },
    { clauseItem: 'Third party Injury. [Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-]In respect of any other Person - Death of or bodily injury to any person Kshs.3,000,000/- & In respect of a series of claims arising out of one event Unlimited]' },
    { clauseItem: 'Legal Liability to Two (2) Passengers seated in front Cabin Only. [Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/- & In respect of a series of claims arising out of one event Kshs. 20,000,000/-]' },
  ],

  //0811 tpo
  SpecialVehicleTPO: [
    { clauseItem: 'New Young Driver excess Additional Kshs. 5,000.00' },
    { clauseItem: 'In experienced driver excess Kshs. 5,000.00' },
    { clauseItem: 'Third Party excess Kshs. 10,000.00' },
    { clauseItem: 'Third Party Property Damage limit Kshs.5,000,000.00' },
    { clauseItem: 'Third Party Bodily Injury Excess..............NIL' },
    { clauseItem: 'Limitations as to use' },
    { clauseItem: 'Electronic date recognition clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'New and young Drivers Clause' },
    { clauseItem: 'Road License and Driving License Clause' },
    { clauseItem: 'Section 1 & III) of this policy is deemed to be deleted' },
    { clauseItem: 'Cover whilst driven by a motor trader' },
    { clauseItem: 'Third party injury, (Limits of Liability - In respect of death of or bodily injury to any person Kshs. 3,000,000/= & in repsect of a series of claims arising out of one event Kshs. 20,000,000/=) in respect of any other Person -Death of or bodily Injury to any person Kshs. 3,000,000/= & in respect of a series of claims arising out of one event Unlimited)' },
  ],

  //100
  CashInTransit: [
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Safe and Books Clause' },
    { clauseItem: 'Transit Security Warranty' },
    { clauseItem: 'Infidelity of employees - Discovery period' },
    { clauseItem: 'Political Violence and Terrorism Extension Clause' },
    { clauseItem: 'Employees And Guests Personal Effects' },
    { clauseItem: 'Hold-Up Endorsement' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Replacement of Keys Clause' },
    { clauseItem: 'Theft of Money From Unattended Motor Vehicle' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Keys Clause' },
  ],

  //deductibles
  CashInTransitDeductible: [
    { deductibleItem: '10% of Claim Amount, Minimum 20,000.00' },
  ],

  //101
  FidelityGurantee: [
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Automatic Additions/Deletions' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Legal Action against anemployee if required by insurers' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination and  Explosives' },
    { clauseItem: 'Referees Clause' },
    { clauseItem: 'Automatic Reinstatement of Loss' },
    { clauseItem: 'Excess Clause' },
    { clauseItem: 'Collusion With Other Employees' },
    { clauseItem: 'Automatic Additions and Deletions Clause' },
    { clauseItem: 'Discovery Period 12 Months' },
    { clauseItem: 'Safe and Books Clause' },
    { clauseItem: 'War, Civil War, Political Risk and Terrorism Exclusion Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
  ],

  //deductibles
  FidelityGuranteeDeductible: [
    { deductibleItem: '10 % of Claim amount, minimum KSH  10,000.00' },
  ],

  //102
  Burglary: [
    { clauseItem: 'Burglar Alarm Warranty' },
    { clauseItem: 'Definition of Stock' },
    { clauseItem: 'Professional Security Service Clause' },
    { clauseItem: '85% Condition of Average' },
    { clauseItem: 'Internal Removal Clause' },
    { clauseItem: 'Vehicle Load Clause' },
    { clauseItem: 'Notice of Cancellation clause' },
    { clauseItem: 'Tenants Clause' },
    { clauseItem: 'Unoccupancy' },
    { clauseItem: 'Appraisement Clause' },
    { clauseItem: 'Security Protection Warranty' },
    { clauseItem: 'Breach of Conditions and Warranties Clause' },
    { clauseItem: 'Hold-Up Endorsement' },
    { clauseItem: 'Strike Riot and Civil Commotion Extension' },
    { clauseItem: 'Goods/Property in Trust' },
    { clauseItem: 'Adjoining Buildings' },
    { clauseItem: 'Misdescription Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Hold - Up Endorsement' },
    { clauseItem: 'Subject to Clauses No. 1, 2 & 3 as stated in the original policy document' },
    { clauseItem: 'Employees And Guests Personal Effects' },
    { clauseItem: 'Safe and Books Clause' },
    { clauseItem: 'Jurisdiction Clause' },
    { clauseItem: 'Designation Of Property Clause' },
    { clauseItem: 'Violent and Forcible Entry' },
    { clauseItem: 'Reinstatement of Loss Clause' },
    { clauseItem: 'Alterations and Repairs Clause' },
    { clauseItem: 'Security Firm/Guard Warranty' },
    { clauseItem: 'Malicious Damage' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'First Loss Clause' },
    { clauseItem: 'Other Tenants Clause' },
    { clauseItem: 'Temporary Removal Clause' },
    { clauseItem: 'All Other Contents Clause' },
    { clauseItem: 'Reinstatement Value Clause' },
    { clauseItem: 'Own Watchman Warranty' },
  ],

  //deductibles
  BurglaryDeductible: [
    { deductibleItem: '10% of Claim Amount, Minimum KSH 10,000.00' },
  ],

  //103
  AllRisk: [
    { clauseItem: 'Memo 8 - Single Article Limit' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Strike Riot and Civil Commotion Extension' },
    { clauseItem: 'Hold-Up Endorsement' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Locked Boot Clause' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Excess Clause' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'Memo 1 - Electrical Derangement' },
    { clauseItem: 'Reinstatement of value- upto 3years' },
    { clauseItem: 'Loss from Vehicles Clause' },
    { clauseItem: 'Pairs and Sets Clause' },
  ],

  //deductibles
  AllRiskDeductible: [
    { deductibleItem: '10% of claim amount minimum Kshs. 10,000/' },
  ],

  //112
  Wiba: [
    { clauseItem: 'Radioactive Exclusion Clause' },
    { clauseItem: 'Statutory Requirements' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: 'Breach of conditions and warranties' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'Electronic Date Recognition Clause EDRC (B)' },
    { clauseItem: 'Records Warranty' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Safety and Health Committees Warranty' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Premium Payment Warranty' },
  ],

  //deductible
  WibaDeductible: [
    { deductibleItem: 'The insured shall be responsible for the first KSH 5,000.00 of each and every claim, excluding claims for funeral expenses' },
  ],

  //benefits
  WibaBenefits: [
    { title: 'Death', elaboration: '96 months earnings subject to the maximum amounts set out under the limit of liability above.' },
    { title: 'Permanent Total Disablement', elaboration: 'Percentages as set out in the First Schedule of Work Injury Benefits Act, 2007 subject to the maximum amounts set out under the limit of liability above' },
    { title: 'Temporary Total/Partial Disablement', elaboration: 'As per the Work Injury Benefits Act, 2007 subject to a maximum of twelve months earnings subject to the maximum amounts set out under the limit of liability above. ' },
    { title: 'Medical Expenses', elaboration: 'Actual expenses incurred but limited to Kshs.100,000/= per employee' },
    { title: 'Funeral Expenses', elaboration: 'Kshs.30,000/= per deceased employee' },
  ],

  //113
  CommonLaw: [
    { clauseItem: 'Radioactive Exclusion Clause' },
    { clauseItem: 'Riot And Strike' },
    { clauseItem: 'Records Warranty' },
    { clauseItem: 'Safety and Health Committees Warranty' },
    { clauseItem: 'Statutory Requirements' },
    { clauseItem: 'Transportation of Workers Clause' },
    { clauseItem: 'Electronic Date Recognition Clause EDRC(B)' },
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'War, Civil War, Political Risks And Terrorism Exclusion Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Nuclear Causes Exclusion Clause' },
    { clauseItem: 'Asbestos Exclusion Clause' },
  ],

  //deductible
  CommonLawDeductible: [
    { deductibleItem: 'The Insured Shall Be Responsible for the First KSH 25,000.00 of each and every claim' },
  ],

  //114
  WibaPlus: [
    { clauseItem: 'Proposal Form Memorandum' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Cancellation Notice' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Disappearance Clause' },
    { clauseItem: 'Exposure' },
    { clauseItem: 'Accumulation Clause' },
    { clauseItem: 'Air Travelling As Passenger' },
    { clauseItem: 'Air Fares For Treatment Clause' },
    { clauseItem: 'Motor Cycling Clause' },
    { clauseItem: 'Riot And Strike' },
    { clauseItem: 'Transportation of Employees for Social and Sporting Activities' },
    { clauseItem: 'Personal Accident Cover' },
    { clauseItem: 'Trustees Clause' },
    { clauseItem: 'Proposal Form Waiver' },
    { clauseItem: 'Age Limit - 18 to 70 years' },
  ],

  //deductible
  WibaPlusDeductible: [
    { deductibleItem: 'TTD excess..................    3 Days' },
    { deductibleItem: 'The insured shall be responsible for the first KSH 5,000.00 of each and every claim, excluding claimsfor funeral expenses' },
  ],

  WibaPlusBenefits: [
    { title: 'Death', elaboration: '96  months earnings subject to the maximum amounts set out under the limit of liability above.' },
    { title: 'Permanent Total Disablement', elaboration: 'Percentages as set out in the First Schedule of Work Injury Benefits Act, 2007 subject to the maximum amounts set out under the limit of liability above.' },
    { title: 'Temporary Total/Partial  Disablement', elaboration: 'As per the Work Injury Benefits Act, 2007 subject to a maximum of twelve months earnings subject to the maximum amounts set out under the limit of liability above.' },
    { title: 'Medical Expenses', elaboration: 'Actual expenses incurred but limited to Kshs.500,000/= per employee' },
    { title: 'Funeral Expenses', elaboration: 'Kshs.60,000/= per deceased employee' },
  ],

  //123
  PLATEGLASS: [
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'War, Civil War, Political Risk and Terrorism Exclusion Clause' },
    { clauseItem: 'Reinstatement of Loss Clause' },
    { clauseItem: 'Malicious Damage' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Payment On Account Clause' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Excess CLause' },
    { clauseItem: 'Proposal Form Waiver' },
    { clauseItem: 'Pollution / Contamination Exclusion Clause' },
    { clauseItem: 'Riot, Strike And Civil Commotion Extension' },
    { clauseItem: 'Replacement Value Clause' },
    { clauseItem: 'Reinstatement Value Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'Hold-Up Endorsement' },
  ],

  //deductible
  PLATEGLASSDeductible: [
    { deductibleItem: '10% of Each and Every Loss minimum KSH 10,000.00' },
  ],

  //125
  GOLFERS: [
    { clauseItem: 'Electronic Date Recognition Clause' },
    { clauseItem: 'Premium Payment Warranty' },
    { clauseItem: 'SUNSET CLAUSE - 18 Months' },
    { clauseItem: 'Terrorism Exclusion Clause' },
    { clauseItem: 'Terrorism Exclusion Clause For Contamination And Explosives' },
    { clauseItem: 'Gradual Environmental Impairment Exclusion Clause' },
    { clauseItem: 'War, Civil War, Political Risk and Terrorism Exclusion Clause' },
    { clauseItem: 'Asbestos Exclusion Clause' },
    { clauseItem: 'COMMUNICABLE DISEASE EXCLUSION' },
  ],

}

export default STDClauses;