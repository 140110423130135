import React, { useState, useEffect, useCallback } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import db, { auth, storage } from '../../firebase';
import { collection, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { totalCommissionFilterFromDateRS, totalCommissionFilterToDateRS } from '../../atoms';
import { commissionRequestFilterFromDate, commissionRequestFilterToDate } from '../../atoms';
import { rightNavContent, rightNavPolicyItem } from '../../atoms';
import { valuationReportsTypeFilter } from '../../atoms';
import { valuationReportsFilterFromDate, valuationReportsFilterToDate } from '../../atoms';
import { unrenewedReportFilterFromDate, unrenewedReportFilterToDate } from '../../atoms';
import { unrenewedReportLapseAsOfDate, unrenewedReportCancelAsOfDate } from '../../atoms';
import DatePicker from 'react-date-picker';
import { useDropzone } from 'react-dropzone';
import UUID from '../Hooks/UUID/UUID';
import Loader from '../Body/Loader/Loader';
import GetCookie from '../Hooks/Cookie/GetCookie';
import '../Body/BodyInputStyles.css';
import './RightNav.css';
import ClaimDocumentsUpload from './Claims/ClaimDocumentsUpload';
import LogbookUpload from './PolicySearch/LogbookUpload';
import OpenClaimsDateFilter from './Claims/OpenClaimsDateFilter';
import LogBookReportFilter from './Reports/LogBookReportFilter';
import StickerCopyReportFilter from './Reports/StickerCopyReportFilter';
import ConclusiveMotorReportFilter from './Reports/ConclusiveMotorReportFilter';
import ValuationReportFilter from './Reports/ValuationReportsFilter';
import CreateInsurer from './Dashboard/InsurerPortfolio/CreateInsurer';
import SelectInsurer from './Underwriting/Insurer/SelectInsurer';
import CreateClientGroup from './Underwriting/Clients/CreateClientGroup';
import ClientGroupSelectClient from './Underwriting/Clients/SelectClient/ClientGroupSelectClient';
import InsurerStatementFinalFilter from './Accounts/Statements/InsurerStatementFinalFilter';
import ReceiptUpload from './Accounts/Receipts/ReceiptUpload';
import PolicyDocumentUpload from './Reports/PolicyDocumentUpload';
import AgentStatementFilter from './Accounts/Statements/AgentStatementFilter';
import BusinessReportFilter from './Reports/BusinessReportFilter';
import DVListFilter from './Extras/DVListFilter';
import CNListFilter from './Extras/CNListFilter';
import JVListFilter from './Extras/JVListfilter';
import PendingListFinalFilter from './Underwriting/Policy/PendingListFinalFilter';
import UnrenewedReportFilter from './Reports/UnrenewedReportFilter';
import CancelledPolicyListFilter from './Underwriting/Policy/CancelledPolicyListFilter';
import RemitReceiptToInsurer from './Accounts/Receipts/RemitReceiptToInsurer';
import ClientGroupDetailView from './Underwriting/Clients/ClientGroupDetailView';
import PendingListGridUpdateDispatch from './Underwriting/Insurer/PendingListGridUpdateDispatch';
import PolicyListGridFilter from './Underwriting/Insurer/PolicyListGridFilter';
import RenewedReportGridFilter from './Reports/RenewedReportGridFilter';
import ClosedClaimsDateFilter from './Claims/ClosedClaimsDateFilter';
import OpenActionMemoDateFilter from './Dashboard/ActionMemo/OpenActionMemoDateFilter';
import PolicyDocumentsReportFilter from './Reports/PolicyDocumentsReportFilter';
import ReceivedDocumentsReportFilter from './Reports/ReceivedDocumentsReportFilter';
import PendingListGridDispatchDateUpdate from './Underwriting/Insurer/PendingListGridDispatchDateUpdate';
import CreatePolicyCategory from './Underwriting/Policy/CreatePolicyCategory';
import CreatePolicySubCategory from './Underwriting/Policy/CreatePolicySubCategory';
import MainScreenRightNav from './Dashboard/MainScreen/MainScreenRightNav';
import CreatePolicySelectClient from './Underwriting/Clients/SelectClient/CreatePolicySelectClient';
import ClientStatementSelectClient from './Underwriting/Clients/SelectClient/ClientStatementSelectClient';
import BrokerStatementFilter from './Accounts/Statements/BrokerStatementFilter';
import ViewPolicyFinalSelectClient from './Underwriting/Clients/SelectClient/ViewPolicyFinalSelectClient';
import ReceiptListGridFilter from './Accounts/Receipts/ReceiptListGridFilter';
import DirectCollectionsRemmittedFilter from './Accounts/Statements/DirectCollectionsRemmittedFilter';
import DVAllocationSelectDV from './Extras/DVAllocationSelectDV';
import DVAllocationSelectDebit from './Extras/DVAllocationSelectDebit';
import ViewDVAllocationSelectDV from './Extras/ViewDVAllocationSelectDV';
import ProductivityReportFilter from './Dashboard/ProductivityReport/ProductivityReportFilter';
import DVReallocationSelectDV from './Extras/DVReallocationSelectDV';
import DVReallocationSelectDebit from './Extras/DVReallocationSelectDebit';
import CreatePayroleCategory from './HumanResource/CreatePayroleCategory';
import CreatePayroleSubCategory from './HumanResource/CreatePayroleSubCategory';
import LeaveApplication from './HumanResource/LeaveApplication';
import GeneratePayrole from './HumanResource/GeneratePayrole';
import UploadExpenseReceipt from './Finance/UploadExpenseReceipt';
import PendingBillsFilter from './Finance/PendingBillsFilter';
import PettyCashReportFilter from './Finance/PettyCashReportFilter';
import ExpensesListFilter from './Finance/ExpensesListFilter';
import CreateVendor from './Finance/CreateVendor';
import InvoicePayment from './Finance/InvoicePayment';
import SelectVendor from './Finance/SelectVendor';
import ProfitAndLossYearFilter from './Finance/ProfitAndLossYearFilter';
import CreateExpenseAccount from './Finance/CreateExpenseAccount';
import WithholdingCertificate from './Finance/WithholdingCertificate';
import EmailStatementSelectClient from './Underwriting/Clients/SelectClient/EmailStatementSelectClient';
import EmailClientStatementFilterDate from './Accounts/Statements/EmailClientStatementFilterDate';
import BiometricsDataImport from './HumanResource/BiometricsDataImport';
import BiometricsDataMonthFilter from './HumanResource/BiometricsDataMonthFilter';
import GeneratePayrollMonthFilter from './HumanResource/GeneratePayrollMonthFilter';
import PayrollMonthFilter from './HumanResource/PayrollMonthFilter';
import AgentStatementGridFilter from './Accounts/Statements/AgentStatementGridFilter';
import CreateBankReconEntry from './Finance/CreateBankReconEntry';


const RightNav = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //one prop
    const onePropDownHandler = (receivedData) => {
        props.onePropDown(receivedData)
    }

    //################################################################
    //unrenewed report filter and lapse date  
    const [unrenewedFilterFromDate, onUnrenewedFilterFromDateChange] = useRecoilState(unrenewedReportFilterFromDate);
    const [unrenewedFilterToDate, onUnrenewedFilterToDateChange] = useRecoilState(unrenewedReportFilterToDate);
    const [unrenewedReportLapseAsOfDateRN, setUnrenewedReportLapseAsOfDateRN] = useRecoilState(unrenewedReportLapseAsOfDate)
    const [unrenewedReportCancelAsOfDateRN, setUnrenewedReportCancelAsOfDateRN] = useRecoilState(unrenewedReportCancelAsOfDate)

    //################################################################
    //set default filter to current month
    const setDefaultFilterDate = useCallback(() => {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        onUnrenewedFilterFromDateChange(firstDay);

        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        onUnrenewedFilterToDateChange(lastDay);
    }, [])

    useEffect(() => { setDefaultFilterDate() }, [setDefaultFilterDate])


    //################################################################
    //commission

    const [commissionRequestFilterToDateRN, setCommissionRequestFilterToDateRN] = useRecoilState(commissionRequestFilterToDate)
    const [commissionRequestFilterFromDateRN, setCommissionRequestFilterFromDateRN] = useRecoilState(commissionRequestFilterFromDate)

    //################################################################
    //Receipt list filter from date 
    const [totalCommissionFilterFromDate, onTotalCommissionFilterFromDateChange] = useRecoilState(totalCommissionFilterFromDateRS);
    const [totalCommissionFilterToDate, onTotalCommissionFilterToDateChange] = useRecoilState(totalCommissionFilterToDateRS);

    //#####################################################################
    //recoil values to control valuation content
    const rightNavContentRV = useRecoilValue(rightNavContent)
    const rightNavPolicyItemRV = useRecoilValue(rightNavPolicyItem)

    //################################################################
    //valuation reports filter
    const [valuationReportsTypeFilterRN, onValuationReportsTypeFilterRNChange] = useRecoilState(valuationReportsTypeFilter);
    const [valuationReportsFilterFromDateRN, setValuationReportsFilterFromDateRN] = useRecoilState(valuationReportsFilterFromDate)
    const [valuationReportsFilterToDateRN, setValuationReportsFilterToDateRN] = useRecoilState(valuationReportsFilterToDate)

    //#####################################################################
    //########################### upload claim docs ########################
    //#####################################################################

    //#####################################################################
    //########################### sticker copy ############################
    //#####################################################################

    //get user input for valuation
    const [vehPlateNoSC, setVehPlateNoSC] = useState('');
    const [stickerCopyFile, setStickerCopyFile] = useState('');
    const [stickerCopyDocUploadProgress, setStickerCopyDocUploadProgress] = useState(0);

    //################################################################
    //step 3
    const changeViewOnSuccessStickerCopyUpload = () => {
        //reset state var
        setVehPlateNoSC('');
        setStickerCopyFile('');
        setStickerCopyDocUploadProgress(0);
        //open view
        const onePropObj = {
            setView: 'Policy Search',
            previousView: 'Policy Search',
            postedDocRef: vehPlateNoSC,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Motor Certificate Uploaded Successfully',
            activity: 'Motor Certificate Document Upload',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //update motor record
    async function updateDocumentInsertStickerCopy(downloadURL) {
        //create obj to post
        const associateDocObj = {
            documentDownloadLink: downloadURL,
            documentCategory: 'Motor Certificate',
            documentVehiclePlateNo: vehPlateNoSC,
            documentPolicyNumber: rightNavPolicyItemRV.docData.policyNumber,
            documentEndorsmentNumber: rightNavPolicyItemRV.docData.endorsmentNumber,
            documentPolicyID: rightNavPolicyItemRV.docID,
            documentClientName: rightNavPolicyItemRV.docData.clientName,
            documentUploadDate: new Date(),
            uploadedBy: currentUser,
        }

        //update data
        try {
            setIsloading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightNavPolicyItemRV.docID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'motorDocuments': arrayUnion(associateDocObj)
            });
            setIsloading(false);
            changeViewOnSuccessStickerCopyUpload();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //step 1
    //upload sticker copy
    const StickerCopyUploadSubmitHandler = (e) => {
        e.preventDefault();

        const file = stickerCopyFile[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRV.docData.clientName,
                'clientID': '',
                'policyNumber': rightNavPolicyItemRV.docData.policyNumber,
                'endorsmentNumber': rightNavPolicyItemRV.docData.endorsmentNumber,
                'policyDocID': rightNavPolicyItemRV.docID,
                'documentID': UUID(),
                'vehiclePlateNo': vehPlateNoSC,
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'Sticker Copy Documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setStickerCopyDocUploadProgress(Math.round(progress))
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
                if (progress < 100) { setIsloading(true); }
                else { setIsloading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateDocumentInsertStickerCopy(downloadURL)
                });
            }
        );
    }

    //#####################################################################
    //########################### val report ##############################
    //#####################################################################

    //get user input for valuation
    const [vehPlateNoValRep, setVehPlateNoValRep] = useState('');
    const [assessedValueValRep, setAssessedValueValRep] = useState('')
    const [dateOfValuationValRep, setDateOfValuationValRep] = useState(new Date());
    const [valuationFileValRep, setValuationFileValRep] = useState('');
    const [valuationDocUploadProgress, setValuationDocUploadProgress] = useState(0);

    //################################################################
    //valuation file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setValuationFileValRep(acceptedFiles)
        setStickerCopyFile(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //step 3
    // change view on success posting
    const viewEditedPolicyOnSuccessDocPosting = () => {
        //reset state var
        setVehPlateNoValRep('');
        setAssessedValueValRep('');
        setDateOfValuationValRep(new Date());
        setValuationFileValRep('');
        setValuationDocUploadProgress(0);
        //open view
        const onePropObj = {
            setView: 'Policy Search',
            previousView: 'Policy Search',
            postedDocRef: vehPlateNoValRep,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Valuation Document Uploaded Successfully',
            activity: 'Valuation Report Document Upload',
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //update doc record
    async function updateAssociateDocRecord(downloadURL) {
        //create obj to post
        const associateDocObj = {
            documentDownloadLink: downloadURL,
            documentCategory: 'Valuation Report',
            documentVehiclePlateNo: vehPlateNoValRep,
            documentAssessedValue: assessedValueValRep,
            documentDateOfValuation: dateOfValuationValRep,
            documentPolicyNumber: rightNavPolicyItemRV.docData.policyNumber,
            documentEndorsmentNumber: rightNavPolicyItemRV.docData.endorsmentNumber,
            documentPolicyID: rightNavPolicyItemRV.docID,
            documentClientName: rightNavPolicyItemRV.docData.clientName,
            documentUploadDate: new Date(),
            uploadedBy: currentUser,
        }

        //update data
        try {
            setIsloading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightNavPolicyItemRV.docID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'motorDocuments': arrayUnion(associateDocObj)
            });
            setIsloading(false);
            viewEditedPolicyOnSuccessDocPosting();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    //################################################################
    //step 1
    //upload doc right nav
    const valuationReportUploadSubmitHandler = (e) => {
        e.preventDefault();

        const file = valuationFileValRep[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRV.docData.clientName,
                'clientID': '',
                'policyNumber': rightNavPolicyItemRV.docData.policyNumber,
                'endorsmentNumber': rightNavPolicyItemRV.docData.endorsmentNumber,
                'policyDocID': rightNavPolicyItemRV.docID,
                'documentID': UUID(),
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'valuation documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setValuationDocUploadProgress(Math.round(progress))
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
                if (progress < 100) { setIsloading(true); }
                else { setIsloading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateAssociateDocRecord(downloadURL)
                });
            }
        );
    }

    //##############################################################################
    //######################### content ############################################
    //##############################################################################
    //content
    let content = '';

    if (Object.keys(props.onePropUp).length !== 0) {
        if (props.onePropUp.setView === 'Policy Search') {

            if (rightNavContentRV) {
                switch (rightNavContentRV) {
                    //valuation report upload
                    case 'Valuation Report Upload':
                        content = <section className='doc_upload'>
                            <div className='right_nav_content'>
                                <div className='h_10'></div>
                                <p className='bold'>Valuation Doc Upload</p>
                                <div className='h_10'></div>
                                {
                                    valuationDocUploadProgress > 0
                                        ? <div><p className='f_7'>Upload Progress:  {valuationDocUploadProgress}% Done</p></div>
                                        : ''
                                }
                                <div className='h_15'></div>
                                <div className='input_group'>
                                    <select className='select' onChange={(e) => { setVehPlateNoValRep(e.target.value) }}>
                                        <option>Select Vehicle</option>
                                        {Object.values(rightNavPolicyItemRV.docData.vehicleItems).map((item, index) => {
                                            return (
                                                <option key={index}>{item.regNo}</option>
                                            )
                                        })}
                                    </select>
                                    <label className='placeholder'><p>Vehicle Plate No</p></label>
                                </div>
                                <div className='h_15'></div>
                                <div className='input_group'>
                                    <input onChange={(e) => { setAssessedValueValRep(e.target.value) }} type='text' className='input' placeholder='' />
                                    <label className='placeholder'><p>Assessed Value</p></label>
                                </div>
                                <div className='h_10'></div>
                                <div className='lapse_date_picker_container'>
                                    <p>Date Of Valuation</p>
                                    <div className='h_5'></div>
                                    <DatePicker onChange={setDateOfValuationValRep} value={dateOfValuationValRep} format='d/ M/y' />
                                </div>
                                <div className='h_10'></div>
                                <div className='file_upload_container'>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            valuationFileValRep
                                                ? <p>{valuationFileValRep[0].name}</p>
                                                : isDragActive
                                                    ? <p>Drop the files here ...</p>
                                                    : <p>Drag 'n' drop some files here, or click to select files</p>
                                        }
                                    </div>
                                </div>
                                <div className='h_5'></div>
                                <button onClick={valuationReportUploadSubmitHandler} className='btn_pink'>Upload</button>
                            </div>
                        </section>
                        break;
                    //sticker copy upload
                    case 'Motor Certificate Upload':
                        content = <section className='doc_upload'>
                            <div className='right_nav_content'>
                                <div className='h_10'></div>
                                <p className='bold'>Motor Certificate Upload</p>
                                <div className='h_10'></div>
                                {
                                    stickerCopyDocUploadProgress > 0
                                        ? <div><p className='f_7'>Upload Progress: {stickerCopyDocUploadProgress}% Done</p></div>
                                        : ''
                                }
                                <div className='h_15'></div>
                                <div className='input_group'>
                                    <select className='select' onChange={(e) => { setVehPlateNoSC(e.target.value) }}>
                                        <option>Select Vehicle</option>
                                        {Object.values(rightNavPolicyItemRV.docData.vehicleItems).map((item, index) => {
                                            return (
                                                <option key={index}>{item.regNo}</option>
                                            )
                                        })}
                                    </select>
                                    <label className='placeholder'><p>Vehicle Plate No</p></label>
                                </div>
                                <div className='h_10'></div>
                                <div className='file_upload_container'>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            stickerCopyFile
                                                ? <p>{stickerCopyFile[0].name}</p>
                                                : isDragActive
                                                    ? <p>Drop the files here ...</p>
                                                    : <p>Drag 'n' drop some files here, or click to select files</p>
                                        }
                                    </div>
                                </div>
                                <div className='h_5'></div>
                                <button onClick={StickerCopyUploadSubmitHandler} className='btn_pink'>Upload</button>
                            </div>
                        </section>
                        break;
                    case 'Logbook Upload':
                        content = <LogbookUpload onePropUp={props.onePropUp} onePropDown={onePropDownHandler}></LogbookUpload>
                        break;
                }
            }

        }
        //end of policy search
        //main screen
        if (props.onePropUp.setView === 'Main Screen') {
            content = <MainScreenRightNav onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //create policy select client
        if (props.onePropUp.setView === 'Create Policy') {
            if (rightNavContentRV === 'Create Policy Select Client') {
                content = <CreatePolicySelectClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //view policy select client
        if (props.onePropUp.setView === 'View Policy Final') {
            if (rightNavContentRV === 'View Policy Final Select Client') {
                content = <ViewPolicyFinalSelectClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //claim documents
        if (props.onePropUp.setView === 'View Claim') {
            content = <ClaimDocumentsUpload onePropUp={props.onePropUp} onePropDown={onePropDownHandler}></ClaimDocumentsUpload>
        }

        //open claims
        if (props.onePropUp.setView === 'Open Claims') {
            content = <OpenClaimsDateFilter></OpenClaimsDateFilter>
        }

        //open claims
        if (props.onePropUp.setView === 'Closed Claims') {
            content = <ClosedClaimsDateFilter />
        }

        //log book report filter
        if (props.onePropUp.setView === 'Logbook Report') {
            content = <LogBookReportFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler}></LogBookReportFilter>
        }

        //sticker copy report filter
        if (props.onePropUp.setView === 'Sticker Copy Report') {
            content = <StickerCopyReportFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler}></StickerCopyReportFilter>
        }

        //conclusive motor report filter
        if (props.onePropUp.setView === 'Conclusive Motor Report') {
            content = <ConclusiveMotorReportFilter></ConclusiveMotorReportFilter>
        }

        //received documents report filter
        if (props.onePropUp.setView === 'Received Documents Report') {
            content = <ReceivedDocumentsReportFilter />
        }

        //valuation report filter
        if (props.onePropUp.setView === 'Valuation Reports') {
            content = <ValuationReportFilter></ValuationReportFilter>
        }

        if (props.onePropUp.setView === 'Policy Documents Report') {
            content = <PolicyDocumentsReportFilter />
        }

        //policy docs report
        if (props.onePropUp.setView === 'Policy Documents') {
            if (rightNavContentRV === 'Policy Document Upload') {
                content = <PolicyDocumentUpload></PolicyDocumentUpload>
            }
        }

        if (rightNavContentRV === 'Policy Document Upload') {
            content = <PolicyDocumentUpload onePropUp={props.onePropUp} onePropDown={onePropDownHandler}></PolicyDocumentUpload>
        }

        //business report filter
        if (props.onePropUp.setView === 'Business Report') {
            content = <BusinessReportFilter></BusinessReportFilter>
        }

        //unrenewed report
        if (rightNavContentRV === 'Unrenewed Report') {
            content = <UnrenewedReportFilter></UnrenewedReportFilter>
        }

        //renewed report
        if (rightNavContentRV === 'Renewed Report') {
            content = <RenewedReportGridFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //dashboard
        //Insurer Portfolio
        if (props.onePropUp.setView === 'Insurer Portfolio') {
            content = <CreateInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        // open action memo
        if (props.onePropUp.setView === 'Open Action Memo') {
            content = <OpenActionMemoDateFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        // closed action memo
        if (props.onePropUp.setView === 'Closed Action Memo') {
            content = <ClosedClaimsDateFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //productivity report
        if (props.onePropUp.setView === 'Productivity Report') {
            content = <ProductivityReportFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //underwriting
        //create policy
        if (props.onePropUp.setView === 'Create Policy' || props.onePropUp.setView === 'Post New Receipt' || props.onePropUp.setView === 'Post DV' || props.onePropUp.setView === 'Post CN' || props.onePropUp.setView === 'Post JV') {
            if (rightNavContentRV === 'Select Insurer') {
                content = <SelectInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (rightNavContentRV === 'Select Client') {
            content = ''
        }

        //policy list grid
        if (props.onePropUp.setView === 'Policy List Grid') {
            content = <PolicyListGridFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        // PENDING

        if (props.onePropUp.setView === 'Pending List Final') {
            content = <PendingListFinalFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        if (props.onePropUp.setView === 'Pending List Grid') {
            content = <PendingListFinalFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        if (props.onePropUp.setView === 'Pending List Grid') {
            if (rightNavContentRV === 'PendingListGridFilterDate') {
                content = <PendingListFinalFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (props.onePropUp.setView === 'Pending List Grid') {
            if (rightNavContentRV === 'PendingListGridUpdateDispatchDate') {
                content = <PendingListGridDispatchDateUpdate onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //client
        //create client group
        if (props.onePropUp.setView === 'Client Group') {
            if (rightNavContentRV === 'Create New Client Group') {
                content = <CreateClientGroup onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }

            //client group view
            if (rightNavContentRV === 'Client Detailed View') {
                content = <ClientGroupDetailView onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }

            if (rightNavContentRV === 'Client Group Select Client') {
                content = <ClientGroupSelectClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //insurer statement
        if (props.onePropUp.setView === 'Insurer Statement Final') {
            content = <InsurerStatementFinalFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //insurer statement grid
        if (props.onePropUp.setView === 'Insurer Statement Grid') {
            content = <InsurerStatementFinalFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //insurer statement
        if (props.onePropUp.setView === 'Direct Collections Unremmitted') {
            content = <AgentStatementFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //insurer statement
        if (props.onePropUp.setView === 'Agent Statement') {
            content = <BrokerStatementFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //receipt
        //view receipt
        if (rightNavContentRV === 'View Receipt') {
            content = <ReceiptUpload onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //remit receipt to insurer
        if (rightNavContentRV === 'Remit Receipt To Insurer') {
            content = <RemitReceiptToInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //direct collections remmitted
        if (props.onePropUp.setView === 'Direct Collections Remmitted') {
            content = <DirectCollectionsRemmittedFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //extras
        //dv list
        if (rightNavContentRV === 'DV List') {
            content = <DVListFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //view dv
        if (rightNavContentRV === 'View DV') {
            content = <SelectInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //cn list
        if (rightNavContentRV === 'CN List') {
            content = <CNListFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //view CN
        if (rightNavContentRV === 'View CN') {
            content = <SelectInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //jv list
        if (rightNavContentRV === 'JV List') {
            content = <JVListFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //view JV
        if (rightNavContentRV === 'View JV') {
            content = <SelectInsurer onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //dv allocation select dv
        if (props.onePropUp.setView === 'DV Allocation') {
            if (rightNavContentRV === 'DV Allocation Select DV') {
                content = <DVAllocationSelectDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
            else if (rightNavContentRV === 'DV Allocation Allocate DV') {
                content = <DVAllocationSelectDebit onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //dv reallocation select dv
        if (props.onePropUp.setView === 'DV Reallocation') {
            if (rightNavContentRV === 'DV Reallocation Select DV') {
                content = <DVReallocationSelectDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
            else if (rightNavContentRV === 'DV Reallocation Select Debits') {
                content = <DVReallocationSelectDebit onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //dv allocation select dv
        if (props.onePropUp.setView === 'View DV Allocation') {
            content = <ViewDVAllocationSelectDV onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //underwriting
        //cancelled policies
        if (rightNavContentRV === 'Cancelled Policies') {
            content = <CancelledPolicyListFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //policy category
        //create policy category
        if (props.onePropUp.setView === 'Policy Categories') {
            if (rightNavContentRV === 'Create Policy Categories') {
                content = <CreatePolicyCategory onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }

            if (rightNavContentRV === 'Create Policy Sub Category') {
                content = <CreatePolicySubCategory onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }


        //client statement
        if (props.onePropUp.setView === 'Client Statement') {
            if (rightNavContentRV === 'Client Statement Select Client') {
                content = <ClientStatementSelectClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        //email client statement
        if (props.onePropUp.setView === 'Email Client Statement') {
            if (rightNavContentRV === 'Email Client Statement Select Client') {
                content = <EmailStatementSelectClient onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
            if (rightNavContentRV === 'Email Client Statement Filter Date') {
                content = <EmailClientStatementFilterDate onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (props.onePropUp.setView === 'Agent Statement') {
            content = <AgentStatementGridFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //receipt list
        if (props.onePropUp.setView === 'Receipt List') {
            content = <ReceiptListGridFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //hr
        if (props.onePropUp.setView === 'Payrole Settings') {
            if (rightNavContentRV === 'Create Payrole Category') {
                content = <CreatePayroleCategory onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }

            if (rightNavContentRV === 'Create Payrole Sub Category') {
                content = <CreatePayroleSubCategory onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (props.onePropUp.setView === 'Employee Profile') {
            if (rightNavContentRV === 'Leave Application') {
                content = <LeaveApplication onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (props.onePropUp.setView === 'Payrole Data Generator') {
            content = <GeneratePayrole onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        if (props.onePropUp.setView === 'Biometrics Data') {
            if (rightNavContentRV === 'Biometrics Data Upload Excel') {
                content = <BiometricsDataImport onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
            if (rightNavContentRV === 'Biometrics Data Month Filter') {
                content = <BiometricsDataMonthFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
            }
        }

        if (props.onePropUp.setView === 'Generate Payroll') {
            content = <GeneratePayrollMonthFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        if (props.onePropUp.setView === 'Payroll') {
            content = <PayrollMonthFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

        //finance
        // if (props.onePropUp.setView === 'View Expense') {
        //     content = <UploadExpenseReceipt onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        // }
        if (props.onePropUp.setView === 'Pending Bills') {
            content = <PendingBillsFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Petty Cash Report') {
            content = <PettyCashReportFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Expenses List') {
            content = <ExpensesListFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Vendor Profile') {
            content = <CreateVendor onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'View Pending Bill') {
            content = <InvoicePayment onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Post Payment' || props.onePropUp.setView === 'Vendor Statement') {
            content = <SelectVendor onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Profit & Loss') {
            content = <ProfitAndLossYearFilter onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Finance Controls') {
            content = <CreateExpenseAccount onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Income') {
            content = <WithholdingCertificate onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }
        if (props.onePropUp.setView === 'Bank Recon') {
            content = <CreateBankReconEntry onePropUp={props.onePropUp} onePropDown={onePropDownHandler} />
        }

    }

    //direct content
    if (Object.keys(props.onePropUp).length !== 0) {
        switch (props.onePropUp.setView) {
            case 'Commission Request':
                content = <section>
                    <div className='right_nav_content'>
                        <div className='h_10'></div>
                        <div><p className='right_nav_help'>This report queries policies using Receipt Date</p></div>
                        <div>
                            <div className='h_10'></div>
                            <p className='bold'>Filter Date</p>
                            <div className='h_5'></div>
                            <div className='lapse_date_picker_container'>
                                <p>From</p>
                                <div className='h_5'></div>
                                <DatePicker onChange={setCommissionRequestFilterFromDateRN} value={commissionRequestFilterFromDateRN} format='d/ M/y' />
                            </div>
                            <div className='h_10'></div>
                            <div className='lapse_date_picker_container'>
                                <p>To</p>
                                <div className='h_5'></div>
                                <DatePicker onChange={setCommissionRequestFilterToDateRN} value={commissionRequestFilterToDateRN} format='d/ M/y' />
                            </div>
                        </div>
                    </div>
                </section>
                break;
            case 'Total Commission':
                content = <section>
                    <form>
                        <div className='right_nav_content'>
                            <div className='h_10'></div>
                            <div><p className='right_nav_help'>This report queries policies using Insurer Debit Date</p></div>
                            <div>
                                <div className='h_10'></div>
                                <p className='bold'>Filter Date</p>
                                <div className='h_5'></div>
                                <div className='lapse_date_picker_container'>
                                    <p>From</p>
                                    <div className='h_5'></div>
                                    <DatePicker onChange={onTotalCommissionFilterFromDateChange} value={totalCommissionFilterFromDate} format='d/ M/y' />
                                </div>
                                <div className='h_10'></div>
                                <div className='lapse_date_picker_container'>
                                    <p>To</p>
                                    <div className='h_5'></div>
                                    <DatePicker onChange={onTotalCommissionFilterToDateChange} value={totalCommissionFilterToDate} format='d/ M/y' />
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                break;
        }
    }

    return (
        <section className='right_nav_container body_input_styles '>
            {content}
            {loaderContent}
        </section>
    )
}

export default RightNav;