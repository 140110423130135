import { useEffect, useState } from "react";
import companyLogo from "../../Assets/img/logo.png"
import DateFormat from "../../Hooks/UI/DateFormat";
import styles from './Payslip.module.css';
import NumberFormat from "../../Hooks/UI/NumberFormat";

const Payslip = (props) => {

    const [employee, setEmployee] = useState({});
    const [payrollMonthFilterRN, setPayrollMonthFilterRN] = useState('');
    useEffect(() => {
        try {
            setEmployee(props.employee);
            setPayrollMonthFilterRN(props.payrollMonthFilterRN);
        } catch (error) { }
    }, [props]);

    function getMonthInWords(date) {
        try {

            const months = [
                "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
                "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
            ];

            const monthIndex = new Date(date).getMonth();
            return months[monthIndex];

        } catch (error) { }
    }

    const [narration, setNarration] = useState('')
    useEffect(() => {
        try {
            const narrationn = getMonthInWords(payrollMonthFilterRN) + ' ' + new Date(payrollMonthFilterRN).getFullYear();
            setNarration(narrationn);
        } catch (error) { }
    }, [payrollMonthFilterRN])

    const content = <section className={styles.payslip_content}>
        <div className={`${styles.payslip_main_content}`}>

            <div><img className={styles.companyLogo} src={companyLogo} /></div>

            <div>
                <table className={`w-full table-fixed ${styles.payslip_main_table}`}>
                    <thead>
                        <tr>
                            <td className='w-1/2 p-1'><p className='bold'>POSTAL CODE</p></td>
                            <td className='w-1/2 p-1'><p className='bold text-right'>623 - 30100, Eldoret</p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p className='bold'>LOCATION</p></td>
                            <td className='w-1/2 p-1'><p className='bold text-right'>1st Floor Rupa's Mall</p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p className='bold'>EMAIL</p></td>
                            <td className='w-1/2 p-1'><p className='bold text-right'>info@simia.co.ke</p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p className='bold'>KRA PIN</p></td>
                            <td className='w-1/2 p-1'><p className='bold text-right'>P052024008J</p></td>
                        </tr>
                        <tr>
                            <td className='p-1' colSpan={2}>
                                <p className='bold text-center'>SALARY VOUCER FOR THE MONTH OF {narration}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>NAME</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.name
                                    && employee.name
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>ID NO</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.idNo
                                    && employee.idNo
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>KRA PIN</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.kraPin
                                    && employee.kraPin
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>PHONE NO</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.phoneNo
                                    && employee.phoneNo
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>Email</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.email
                                    && employee.email
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>NSSF NO</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.nssfNo
                                    && employee.nssfNo
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>NHIF NO</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.nhifNo
                                    && employee.nhifNo
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>ACCOUNT NAME</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.bankAccName
                                    && employee.bankAccName
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>BANK</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.bankName
                                    && employee.bankName
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>BANK ACCOUNT NO</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.bankAccNo
                                    && employee.bankAccNo
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2 p-1'><p>PAYING BANK</p></td>
                            <td className='w-1/2 p-1'><p className='text-right'>CIB KENYA LTD</p></td>
                        </tr>

                        <tr>
                            <td className='w-1/2  p-1'><p>BASIC SALARY</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.basicSalary
                                    && NumberFormat(employee.basicSalary)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>NSSF</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.NSSFAmount
                                    && NumberFormat(employee.NSSFAmount)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>TAXABLE PAY</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.taxablePayAmount
                                    && NumberFormat(employee.taxablePayAmount)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>INCOME TAX</p></td>
                            <td className='w-1/2  p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.incomeTaxAmount
                                    && NumberFormat(employee.incomeTaxAmount)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>NHIF INSURANCE RELIEF</p></td>
                            <td className='w-1/2  p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.NHIFInsuranceRelief
                                    && '( ' + NumberFormat(employee.NHIFInsuranceRelief) + ' )'
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>PERSONAL RELIEF</p></td>
                            <td className='w-1/2  p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.personalReliefAmount
                                    && '( ' + NumberFormat(employee.personalReliefAmount) + ' )'
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>P.A.Y.E</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.PAYEEAmount
                                    && NumberFormat(employee.PAYEEAmount)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>PAY AFTER TAX</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.payAfterTaxAmount
                                    && NumberFormat(employee.payAfterTaxAmount)
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>HOUSING LEVY</p></td>
                            <td className='w-1/2  p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.housingLevyAmount
                                    && '( ' + NumberFormat(employee.housingLevyAmount) + ' )'
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>NHIF</p></td>
                            <td className='w-1/2  p-1'><p className='text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.NHIFAmount
                                    && '( ' + NumberFormat(employee.NHIFAmount) + ' )'
                                }
                            </p></td>
                        </tr>
                        <tr>
                            <td className='w-1/2  p-1'><p>NET PAY</p></td>
                            <td className='w-1/2  p-1'><p className='bold text-right'>
                                {
                                    Object.keys(employee).length > 0
                                    && employee.netPayAmount
                                    && NumberFormat(employee.netPayAmount)
                                }
                            </p></td>
                        </tr>
                    </thead>
                </table>
                <table className={`${styles.table_signature}`}>
                    <tbody>
                        <tr>
                            <td className='w-1/2'>AUTHORIZED BY</td>
                            <td className='w-1/2'></td>
                        </tr>
                        <tr>
                            <td className='w-1/2'>DATE</td>
                            <td className='w-1/2'></td>
                        </tr>
                        <tr>
                            <td className='w-1/2'>SIGN</td>
                            <td className='w-1/2'></td>
                        </tr>
                    </tbody>
                </table>
                <div className={`${styles.payslip_page_break}`}>&nbsp;</div>
            </div>

        </div>

    </section>

    return (content)
}

export default Payslip;