import React, { useState } from 'react';
import db, { auth } from '../../firebase';
import { doc, updateDoc, arrayUnion } from "firebase/firestore"; 
import { createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "../Body/Loader/Loader";
import './SignUp.css';

const SignUp = (props) => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //process date
    const todaysDate = new Date();
    const year = new Date(todaysDate).getFullYear();
    const month = new Date(todaysDate).getMonth() + 1;
    const date = new Date(todaysDate).getDate();
    const todaysDateCleaned = year + '-' + month + '-' + date;

    //echo date
    const dayEcho = new Date(todaysDate).toLocaleString('en-us', { day: '2-digit' });
    const monthEcho = new Date(todaysDate).toLocaleString('en-us', { month: 'short' });
    const yearEcho = new Date(todaysDate).getFullYear();
    const echoDate = dayEcho + '-' + monthEcho + '-' + yearEcho;

    //get user input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');

    //add user to db
    async function createUserHandler() {
        setIsloading(true);

        //create main object for posting
        const userDetails = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            password: password,
            accountType: '',
            clearenceLevel: '',
            profileAvatar: '',
            date: todaysDate,
            echoDate: echoDate,
            time: todaysDate.toLocaleTimeString(),
            newFieldOne: '',
            newFieldTwo: '',
            newFieldThree: '',
            newFieldFour: '',
            newFieldSix: '',
            newFieldSeven: '',
            newFieldEight: '',
            newFieldNine: '',
            newFieldTen: ''
        }

        //feed db
        try {
            const docRef = doc(db, "UserAccounts", "BGjyi6I539cS7XfJDGTU");
            await updateDoc(docRef, {
                user: arrayUnion(userDetails)
            });

        } catch (error) {

        }
        setIsloading(false)
    }

    //add sign up to system logs
    async function systemLogsHandler() {
        setIsloading(true);

        //create main object for posting
        const logDetails = {
            activity: 'Sign Up',
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            date: todaysDate,
            echoDate: echoDate,
            time: todaysDate.toLocaleTimeString(),
            IPAddress: '',
            location: '',
            operatingSystem: '',
            browserUserAgent: '',
            device: '',
            newFieldOne: '',
            newFieldTwo: '',
            newFieldThree: '',
            newFieldFour: '',
            newFieldSix: '',
            newFieldSeven: '',
            newFieldEight: '',
            newFieldNine: '',
            newFieldTen: ''
        }

        //feed db
        try {
            const docRef = doc(db, "SystemLogs", "HnQgW7kO5yHWDkowX82q");
            await updateDoc(docRef, {
                log: arrayUnion(logDetails)
            });

        } catch (error) {

        }
        setIsloading(false)
    }

    //auth user
    async function signUpSubmitHandler(event) {
        event.preventDefault();
        setIsloading(true);

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                createUserHandler();
                systemLogsHandler();
                props.instructionsDown('Sign In');
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage)
                // ..
            });
        setIsloading(false)
    }

    const redirectHandler = () => { props.instructionsDown('Sign In') }

    return (
        <section>
            <div className='sign_up_hero'>
                <div className='sign_up_sub'></div>
                <div className='sign_up_sub'>
                    <div className='sign_in_empty'></div>
                    <div className="sign_in_content">
                        <p className='sign_up_header bold'>Create new account.</p>
                        <div>
                            <div className='h_30'></div>
                            <div className='log_in_input_container main_container'>
                                <div className='log_in_input_sub_container'>
                                    <input onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' />
                                </div>
                                <div className='log_in_input_sub_container'>
                                    <input onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' />
                                </div>
                            </div>
                            <div className='h_10'></div>
                            <div className='log_in_input_container'>
                                <input onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                            </div>
                            <div className='h_10'></div>
                            <div className='log_in_input_container'>
                                <input onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Phone Number' />
                            </div>
                            <div className='h_10'></div>
                            <div className='log_in_input_container'>
                                <input onChange={(e) => setPassword(e.target.value)} placeholder='Password' type='password' />
                            </div>
                            <div className='h_15'></div>
                            <span className='btn_pink sign_up_button bold' onClick={signUpSubmitHandler}>SIGN UP</span>
                            <div className='h_10'></div>
                            <div className='redirectController' onClick={redirectHandler}>
                                <p>Already Have An Account? Sign In.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sign_up_sub'></div>
            </div>
            {loaderContent}
        </section>
    )
}

export default SignUp;