import React, { useState, useEffect } from "react";
import db from "../../../firebase";
import { runTransaction, doc } from "firebase/firestore";
import GetAllClientGroups from "../../Hooks/BackendConn/InsurerSpecific/GetAllClientGroups";
import GetAllClients from "../../Hooks/BackendConn/ClientList/GetAllClients";
import Loader from "../Loader/Loader";
import { useRecoilState } from "recoil";
import { pageReloaderHandler, rightNavContent, selectClient, rightNavPolicyItem } from "../../../atoms";
import './ClientGroup.css';
import { async } from "@firebase/util";

const ClientGroup = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all client groups
    const [clientGroups, setClientGroups] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClientGroups();
            setClientGroups(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get all clients
    const [clientDetails, setClientDetails] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClients();
            setClientDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //get recols state to reload
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler)
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent)
    const [rightNavPolicyItemRN, setRightNavPolicyItemRN] = useRecoilState(rightNavPolicyItem)
    const selectClientRN = useRecoilState(selectClient)

    useEffect(() => {
        if (pageReloaderHandlerRN.notification === 'Client Group Created Successfully') {
            setReload(Math.random(10001, 20000))
            setPageReloaderHandlerRN({})
        }
    }, [pageReloaderHandlerRN])

    //################################################################
    //search
    const [searchTerm, setSearchTerm] = useState('')

    const [searchedClientGroups, setSearchedClientGroups] = useState([])
    useEffect(() => {
        const c = [];
        const docs = clientGroups.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.groupTitle.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }

        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        });
        setSearchedClientGroups(c);
    }, [clientGroups, searchTerm])

    //################################################################
    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState('')
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
            setRightNavPolicyItemRN(e.target.id);
        }
    };

    //################################################################
    //reload on success group member add
    const reloadOnSuccessGroupMemberAdd = () => {
        //change view
        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client has been Successfully added to group',
        }
        props.onePropDown(onePropObj);
        setReload(Math.random(1001, 20000))
    }

    async function a() {
        const sfDocRef = doc(db, "ClientGroups", "PvnnCxssBmfIt6BL6i4P");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                const newPopulation = sfDoc.data()[rightClickedPolicy];

                const selectedClientID = selectClientRN[0].docID
                const newGroupMembers = Object.values(newPopulation.groupMembers)
                if (!newGroupMembers.includes(selectedClientID)) { newGroupMembers.push(selectedClientID) }

                const newObj = {
                    groupTitle: newPopulation.groupTitle,
                    groupMembers: { ...newGroupMembers },
                    contactPerson: newPopulation.contactPerson,
                    documents: newPopulation.documents,
                    createdBy: newPopulation.createdBy,
                    dateCreated: newPopulation.dateCreated,
                }

                transaction.update(sfDocRef, { [rightClickedPolicy]: newObj });
                
            });
            console.log("Transaction successfully committed!");

        } catch (e) {
            console.log("Transaction failed: ", e);
        }
        
    }

    //################################################################
    //update client group members
    useEffect(() => {
        if (props.onePropUp.setView === 'Client Group') {
            if (rightNavContentRN === 'Client Group Select Client') {
                if (selectClientRN[0]) {

                    
                }
            }
        }
    }, [selectClientRN])

    //################################################################
    //add show select client
    const addClientHandler = () => {
        //hide menu
        setxPos('-1000px');
        setyPos('-1000px');

        //show select client
        setRightNavContentRN('Client Group Select Client')
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={addClientHandler}><p>Add Client</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //content
    const clientGroupContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => { setSearchTerm(e.target.value) }} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Group Title</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className="client_group_table">
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Group Title</p></td>
                                <td><p>Members</p></td>
                                <td><p>Contact Person</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedClientGroups.map((doc) => {
                                    const clientNames = [];
                                    if (doc.docData.groupMembers) {
                                        Object.values(doc.docData.groupMembers).map((clientID) => {
                                            const a = clientDetails.filter(client => client.docID === clientID)
                                            clientNames.push(a[0].docData.clientName)
                                        });
                                    }

                                    return (
                                        <tr key={doc.id} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.id}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.groupTitle}</p></td>
                                            <td id={doc.docID}><p id={doc.docID}>
                                                {
                                                    clientNames.length > 0
                                                        ? clientNames.map((clientName) => { return (clientName + ', ') })
                                                        : ''
                                                }
                                            </p></td>
                                            <td id={doc.docID}><p id={doc.docID}>{doc.docData.contactPerson}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='client_group body_input_styles'>
            <div className='body_section_header'>
                <p>Client Group</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={() => { setRightNavContentRN('Create New Client Group') }}>
                        <i className="ri-add-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {clientGroupContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default ClientGroup;