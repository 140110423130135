import React, { useState, useEffect, useRef, useCallback } from "react";
import { useRecoilValue } from 'recoil';
import { commissionRequestFilterFromDate, commissionRequestFilterToDate } from '../../../atoms.js';
import { commissionRequestInsurerFilter, activeInsurerPortfolio } from "../../../atoms.js";
import Loader from "../Loader/Loader.js";
import { useReactToPrint } from "react-to-print";
import './BodyCommission.css'
import DateFormat from "../../Hooks/UI/DateFormat.js";
import { utils, writeFileXLSX } from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './CommissionRequest.css';
import GetAllReceiptsInsurer from "../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurer.js";
import GetAllDocsTransactionInsurerPlusPending from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionInsurerPlusPending.js";

const CommissionRequest = () => {
    //################################################################
    //get items from recoil
    const commissionRequestFilterFromDateRN = useRecoilValue(commissionRequestFilterFromDate)
    const commissionRequestFilterToDateRN = useRecoilValue(commissionRequestFilterToDate)
    const commissionRequestInsurerFilterRN = useRecoilValue(commissionRequestInsurerFilter);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(componentRef.current);
        writeFileXLSX(wb, "Commission Request.xlsx");
    }, [tbl]);

    //################################################################
    //get recoil values
    const insurer = 'RC'

    //################################################################
    //get all debits
    // const [debitDocs, setAllDebitDocs] = useState([]);
    // const [reload, setReload] = useState(0);
    // const [isloadedOne, setLoadedOne] = useState(false);
    // useEffect(() => {
    //     if (!isloadedOne) {
    //         (async () => {
    //             setIsLoading(true)
    //             const DDocs = await GetAllDebit();
    //             setAllDebitDocs(DDocs);
    //             setLoadedOne(true);
    //             if (DDocs) {
    //                 setIsLoading(false)
    //             }
    //             else { setReload(Math.random(0, 1000)) }
    //         })();
    //     }
    //     return () => { }
    // }, [reload]);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionInsurerPlusPending({ insurerName: commissionRequestInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, commissionRequestInsurerFilterRN]);

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionInsurerPlusPending({ insurerName: commissionRequestInsurerFilterRN, transactionType: 'Credit' });
            setCreditDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree, commissionRequestInsurerFilterRN]);
    
    //################################################################
    //get all receipts
    // const [allReceiptDocs, setAllReceiptDocs] = useState([]);
    // const [reloadTwo, setReloadTwo] = useState(0);
    // const [isloadedTwo, setLoadedTwo] = useState(false);
    // useEffect(() => {
    //     if (!isloadedTwo) {
    //         (async () => {
    //             setIsLoading(true)
    //             const DDocs = await GetAllReceipts();
    //             setAllReceiptDocs(DDocs)
    //             setLoadedTwo(true);
    //             if (DDocs) {
    //                 setIsLoading(false)
    //             }
    //             else { setReloadTwo(Math.random(0, 1000)) }
    //         })();
    //     }
    //     return () => { }
    // }, [reloadTwo]);

    //################################################################
    //get all debits
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllReceiptsInsurer({ insurerName: commissionRequestInsurerFilterRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo, commissionRequestInsurerFilterRN]);

    //################################################################
    //get all credits
    // const [creditDocs, setCreditDocs] = useState([]);
    // const [reloadThree, setReloadThree] = useState(0);
    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true)
    //         const DDocs = await GetAllCredits();
    //         setCreditDocs(DDocs)
    //         if (DDocs) {
    //             setIsLoading(false)
    //         }
    //         else { setReloadThree(Math.random(0, 1000)) }
    //     })();
    // }, [reloadThree]);

    //################################################################
    //filter insurer
    // const [receiptDocs, setReceiptDocs] = useState([]);
    // useEffect(() => {
    //     const a = allReceiptDocs.filter(doc => doc.docData.receiptNumber.startsWith('RC'));
    //     setReceiptDocs(a)
    //     //allReceiptDocs.map((doc)=>{console.log(doc.docData.receiptNumber+ '##')})
    // }, [allReceiptDocs])

    //################################################################
    //get all receipts allocations from beginning to 'to' date
    // const [allocationsForBalQuery, setAllocationsForBalQuery] = useState([]);
    // useEffect(() => {

    //     const singleAllocations = [];
    //     receiptDocs.map((item) => {
    //         //clean receipt date
    //         const cleanReceiptDate = new Date(item.docData.receiptDate);
    //         cleanReceiptDate.setDate(cleanReceiptDate.getDate());
    //         if (new Date(cleanReceiptDate).valueOf() <= new Date(commissionRequestFilterToDateRN).valueOf()) {
    //             Object.values(item.docData.receiptAllocationDetails).map((value) => {
    //                 singleAllocations.push(value)
    //             });
    //         }
    //     });
    //     setAllocationsForBalQuery(singleAllocations);


    // }, [receiptDocs]);

    //################################################################
    //filter dates
    const [dateFilteredReceipts, setDateFilteredReceipts] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        receiptDocs.map((item) => {
            if (new Date(item.docData.receiptDate).setHours(0).valueOf() >= new Date(commissionRequestFilterFromDateRN).valueOf() && new Date(commissionRequestFilterToDateRN).valueOf() >= new Date(item.docData.receiptDate).setHours(0).valueOf()) {
                periodSelected.push(item);
            }
        });
        setDateFilteredReceipts(periodSelected);
    }, [receiptDocs, commissionRequestFilterFromDateRN, commissionRequestFilterToDateRN]);

    //################################################################
    //extract allocated policies id
    const [allocatedPolicies, setAllocatedPolicies] = useState([]);
    useEffect(() => {
        const singleAllocations = [];
        dateFilteredReceipts.map((item) => {
            Object.values(item.docData.receiptAllocationDetails).map((value) => {
                //if(value.receivedAllocatedAmount > 0){
                singleAllocations.push(value.receivedDocID);
                // }                
            });
        });
        setAllocatedPolicies(singleAllocations);
    }, [dateFilteredReceipts]);

    //################################################################
    //get debit paid for the period
    const [allocatedDebits, setAllocatedDebits] = useState([]);
    const [allocatedDebitsEditable, setAllocatedDebitsEditable] = useState([]);
    useEffect(() => {
        const paidPolicies = [];
        allocatedPolicies.map((item) => {
            debitDocs.map((value) => {
                if (item === value.docID) {
                    paidPolicies.push({ docData: value.docData, docID: value.docID, policyCredits: [], receiptNumbers: [], policyCreditsDetails: [], policyCreditsData: [] });
                }
            });
        });
        setAllocatedDebits(paidPolicies);
        setAllocatedDebitsEditable(paidPolicies);
    }, [allocatedPolicies]);

    //################################################################
    //add receipt allocations to debit
    const [addReceiptAllocation, setAddReceiptAllocation] = useState([]);
    useEffect(() => {
        try {

            receiptDocs.map((item) => {
                if (new Date(item.docData.receiptDate).setHours(0).valueOf() <= new Date(commissionRequestFilterToDateRN).valueOf()) {
                    Object.values(item.docData.receiptAllocationDetails).map((value) => {
                        allocatedDebits.map((val, index) => {
                            if (value.receivedDocID === val.docID) {
                                const Values = [...allocatedDebitsEditable];
                                if (Values[index]['receiptNumbers'].indexOf(item.docData.receiptNumber) == -1) {
                                    Values[index]['receiptNumbers'].push(item.docData.receiptNumber);
                                    Values[index]['policyCredits'].push(value.receivedAllocatedAmount);
                                    const a = item.docData.receiptNumber + ' - ' + DateFormat(item.docData.receiptDate) + ' - ' + value.receivedAllocatedAmount
                                    Values[index]['policyCreditsDetails'].push(a);
                                    Values[index]['policyCreditsData'].push({ value: value.receivedAllocatedAmount, date: item.docData.receiptDate })
                                    setAllocatedDebitsEditable(Values);
                                }
                            }

                        })
                    });
                }
            });

            creditDocs.map((item) => {
                allocatedDebits.map((val, index) => {
                    if (item.docData.allocatedDocID === val.docID) {
                        const Values = [...allocatedDebitsEditable];
                        if (Values[index]['receiptNumbers'].indexOf(item.docID) == -1) {
                            Values[index]['receiptNumbers'].push(item.docID);
                            Values[index]['policyCredits'].push(item.docData.policyNet);
                            const a = item.docData.policyNumber + ' - ' + item.docData.endorsmentNumber + ' - ' + DateFormat(item.docData.insurerDebitDate) + ' - ' + item.docData.policyNet;
                            Values[index]['policyCreditsDetails'].push(a);
                            Values[index]['policyCreditsData'].push({ value: item.docData.policyNet, date: item.docData.insurerDebitDate })
                            setAllocatedDebitsEditable(Values);
                        }
                    }
                })
            })

        } catch (error) { }
    }, [allocatedDebits, commissionRequestFilterFromDateRN, commissionRequestFilterToDateRN]);

    //################################################################
    //look for outstanding per policy
    const [zeroOutstanding, setZeroOutstanding] = useState([]);
    useEffect(() => {
        const zero = [];
        allocatedDebitsEditable.map((item) => {
            const totalCredits = item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0);
            const calcOutstanding = item.docData.policyNet - totalCredits;
            if (1000 > calcOutstanding) {
                //zero.push(item);

                const dateSortedDocList = item.policyCreditsData.sort(byDate);
                function byDate(a, b) {
                    return new Date(a.date).valueOf() - new Date(b.date).valueOf();
                } 

                let sum = 0;
                let dateAbove500;
                dateSortedDocList.forEach((obj) => {
                    if (!dateAbove500) {
                        sum += obj.value;
                        if ((item.docData.policyNet - sum) < 1000) {
                            dateAbove500 = obj.date;
                        }
                    }
                });

                if (new Date(dateAbove500).setHours(0).valueOf() >= new Date(commissionRequestFilterFromDateRN).valueOf() && new Date(commissionRequestFilterToDateRN).valueOf() >= new Date(dateAbove500).setHours(0).valueOf()) {
                    zero.push(item);
                }
            }

        });
        setZeroOutstanding(zero);
    }, [allocatedDebitsEditable, commissionRequestFilterFromDateRN, commissionRequestFilterToDateRN])

    //################################################################
    // remove duplicates
    const [withoutDuplicates, setWithoutDuplicates] = useState([])
    useEffect(() => {

        const uniqueIds = [];
        const uniqueEmployees = zeroOutstanding.filter(doc => {
            const isDuplicate = uniqueIds.includes(doc.docID);

            if (!isDuplicate) {
                uniqueIds.push(doc.docID);
                return true;
            }
            return false;
        });

        setWithoutDuplicates(uniqueEmployees)
    }, [zeroOutstanding])

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = withoutDuplicates.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [withoutDuplicates, searchTerm]);

    //################################################################
    // calc total
    const [commissionExLevies, setCommissionExLevies] = useState([]);
    useEffect(() => {
        const commissionExLevies = [];
        searchedPolicyDocs.map((item) => {
            const totalCredits = item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0);
            const calcOutstanding = parseInt(item.docData.policyNet) - totalCredits;
            if (calcOutstanding < 1) {
                commissionExLevies.push(parseInt(item.docData.commission) - parseInt(item.docData.withholdingTax))
            }
            else if (calcOutstanding >= 1 && calcOutstanding <= 1000) {
                commissionExLevies.push(parseInt(item.docData.commission) - parseInt(item.docData.withholdingTax) - parseInt(calcOutstanding))
            }
        });
        const c = commissionExLevies.reduce((total, item) => { return total + +item }, 0);
        setCommissionExLevies(c);
    }, [searchedPolicyDocs]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    //echo
    const commissionContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='commission_request_print_only_component'>
                        <center><p className='bold'>COMMISSION REQUEST</p></center>
                        <div className='h_4'></div>
                        <center><p className='bold'>PERIOD : {DateFormat(commissionRequestFilterFromDateRN)} - {DateFormat(commissionRequestFilterToDateRN)}</p></center>
                        <div className='h_4'></div>
                        {/* <center><p className='bold'>INSURER : {PendingListInsurerFilterRN}</p></center>
                        <div className='h_4'></div> */}
                    </div>
                    <table className='commission_request_table' ref={componentRef}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Credits Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Policy Credits</p></td>
                                <td><p>Outstanding</p></td>
                                <td><p>Commission</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedPolicyDocs.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>
                                            {
                                                item.policyCreditsDetails.map(item => (item + ', ## '))
                                            }
                                        </p></td>
                                        <td><p>{item.docData.policyNet}</p></td>
                                        <td><p>{item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                        <td><p>{parseInt(item.docData.policyNet) - item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                        <td><p>
                                            {
                                                (parseInt(item.docData.policyNet) - item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)) < 1
                                                    ? parseInt(item.docData.commission - item.docData.withholdingTax).toLocaleString()
                                                    : ''
                                            }
                                            {
                                                (parseInt(item.docData.policyNet) - item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)) > 1
                                                    ? parseInt(item.docData.commission) - parseInt(item.docData.withholdingTax) - (parseInt(item.docData.policyNet) - item.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0))
                                                    : ''
                                            }
                                        </p></td>
                                    </tr>
                                )
                            })
                            }
                            <tr>
                                <td colSpan='8'></td>
                                <td><p>Totals</p></td>
                                <td><p>{parseInt(commissionExLevies).toLocaleString()}</p></td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} /> */}
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='commission_request body_input_styles'>
            <div className='body_section_header'>
                <p>Commission Request</p>
                <div className='right_side_controls'>
                    <span className='btn_pink' onClick={exportFile} >
                        <i className="ri-file-excel-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {commissionContent}
            </div>
            {loaderContent}
        </section>
    );
}

export default CommissionRequest;