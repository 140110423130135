import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import db from "../../../firebase";
import { doc, updateDoc, deleteField } from "firebase/firestore";
import Loader from "../Loader/Loader";
import tippy from 'tippy.js';
import GetAllClientGroups from "../../Hooks/BackendConn/ClientList/GetAllClientGroups";
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";
import { useRecoilState, useRecoilValue } from "recoil";
import { rightNavContent, pageReloaderHandler, reloadOnSuccessClientGroupChangeHandler } from "../../../atoms";
import { clientGroupSelection, clientGroupSelectionPrintSchedule } from "../../../atoms";
import { useReactToPrint } from "react-to-print";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import { activeInsurerPortfolio } from "../../../atoms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllClients from "../../Hooks/BackendConn/ClientList/GetAllClients";
import GetRenewalSchedule from "./GetRenewalSchedule";
import GetAllClientDebits from "../../Hooks/BackendConn/ClientList/GetAllClientDebits";
import RenewalScheduleTemplate from "./RenewalScheduleTemplate";
import { async } from "@firebase/util";
import RenewalScheduleInterface from "./RenewalScheduleInterface";
import GetClientData from "../../Hooks/BackendConn/ClientList/GetClientData";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const ClientGroupFinal = (props) => {
    //################################################################
    //recoil values
    const [rightNavContentRN, setRightNavContent] = useRecoilState(rightNavContent);
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler);
    const [clientGroupSelectionRN, setClientGroupSelectionRN] = useRecoilState(clientGroupSelection)
    const clientGroupSelectionPrintScheduleRN = useRecoilValue(clientGroupSelectionPrintSchedule)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const [reloadOnSuccessClientGroupChangeHandlerRN, setReloadOnSuccessClientGroupChangeHandlerRN] = useRecoilState(reloadOnSuccessClientGroupChangeHandler)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing stmt
    const componentRefStmt = useRef();
    const handlePrintStmt = useReactToPrint({
        pageStyle: `@media print {
            @page { 
                size: portrait !important; 
                margin: 10mm !important;
            }
            table tbody tr {
                page-break-inside:avoid; 
                page-break-after:auto
            }
          }`,
        content: () => componentRefStmt.current,
    });


    //################################################################
    //get client groups
    const [clientGroups, setClientGroups] = useState([]);
    const [initialClientGroups, setInitialClientGroups] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClientGroups();
            setClientGroups(DDocs);
            setInitialClientGroups(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get client groups
    const [clientList, setClientList] = useState([]);
    const [clientListInitial, setClientListInitial] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClients();
            setClientList(DDocs);
            setClientListInitial(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //get all client data
    const [clientDataDocs, setClientDataDocs] = useState([]);
    const [reloadFour, setReloadFour] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetClientData();
            setClientDataDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadFour(Math.random(0, 10000)) }
        })();
    }, [reloadFour]);

    //################################################################
    //filter insurer
    const [insurerFiltered, setInsurerFiltered] = useState([]);
    const [initialInsurerFiltered, setInitialInsurerFiltered] = useState([]);
    useEffect(() => {
        const list = clientDataDocs.filter(doc => doc.docData.insurerID === activeInsurerPortfolioRN);
        setInsurerFiltered(list);
        setInitialInsurerFiltered(list);
    }, [clientDataDocs, activeInsurerPortfolioRN])

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);

    useEffect(() => {
        const a = [];

        // Grouped 
        const groupedClients = []

        clientGroups.map((doc) => {
            const groupMembers = insurerFiltered.filter(client => client.docData.group === doc.docID)

            a.push({
                id: doc.docID,
                groupTitle: doc.docData.groupTitle,
                contactPerson: doc.docData.contactPerson,
                members: groupMembers,
            })

            groupedClients.push({
                id: doc.docID,
                groupTitle: doc.docData.groupTitle,
                contactPerson: doc.docData.contactPerson,
                members: groupMembers,
            })
        });

        // UnGrouped 
        const ungroupedClients = []

        const ungrouped = insurerFiltered.filter(client => client.docData.group === '' || !client.docData.group);

        a.push({
            id: '',
            groupTitle: 'UNGROUPED',
            contactPerson: '',
            members: ungrouped,
        })

        ungroupedClients.push({
            id: '',
            groupTitle: 'UNGROUPED',
            contactPerson: '',
            members: ungrouped,
        })

        setFinalData(a);
        setExportData(a)
        setExportGrouped(groupedClients)
        setExportUnGrouped(ungroupedClients)
    }, [clientGroups, insurerFiltered]);



    //################################################################
    //print schedule
    const [clientDebitsForSchedule, setClientDebitsForSchedule] = useState([])
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const DDocs = await GetAllClientDebits({ transactionType: 'Debit', clientName: clientGroupSelectionRN.docData.clientName });
                const renewalSchedule = GetRenewalSchedule(DDocs)
                setClientDebitsForSchedule(renewalSchedule);
                if (DDocs) {
                    setIsLoading(false)
                }
                else { setReloadThree(Math.random(0, 1000)) }
            } catch (error) {

            }
        })();


    }, [clientGroupSelectionPrintScheduleRN, reloadThree])

    //################################################################
    //print shedule
    useEffect(() => {
        try {
            if (clientDebitsForSchedule.length > 0) {
                handlePrintStmt()
            }
        } catch (error) {

        }

    }, [clientDebitsForSchedule])

    // const aas = <section>
    //     <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
    //         <tbody>
    //             <tr>
    //                 <td className='w-1/2 border border-slate-600 p-1' colSpan='3'>
    //                     <p className='bold'>INSURED DETAILS</p>
    //                 </td>
    //                 <td className='w-1/2 border border-slate-600 p-1' colSpan='3'>
    //                     <div className='h_5'></div>
    //                     <p className='bold'>AGENT DETAILS</p>
    //                     <div className='h_5'></div>
    //                     <p>SIMIA INSURANCE AGENCY LIMITED</p>
    //                     <div className='h_5'></div>
    //                     <p>P.O BOX 623 – 30100 ELDORET </p>
    //                     <div className='h_5'></div>
    //                     <p>TEL: +254 722 968 637 </p>
    //                     <div className='h_5'></div>
    //                     <p>EMAIL: info@simiainsurance.com</p>
    //                     <div className='h_5'></div>
    //                 </td>
    //             </tr>
    //             {
    //                 clientDebitsForSchedule.map((doc, index) => {
    //                     if (doc.docData.selectedProductClass !== '(07) - Motor Private'
    //                         && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
    //                         && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
    //                         && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
    //                         && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
    //                         && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO') {
    //                         return (
    //                             <tr key={index}>
    //                                 <td className='w-1/2 border border-slate-600 p-1 align-top'>
    //                                     <p className='bold'>POLICY CLASS</p>
    //                                     <p>{doc.docData.selectedProductSubClass}</p>
    //                                     <div className='h_5'></div>
    //                                     <p className='bold'>POLICY NUMBER</p>
    //                                     <p>{doc.docData.policyNumber}</p>
    //                                 </td>
    //                                 <td className='w-1/2 border border-slate-600 p-1' colSpan='4'>
    //                                     <span className='bold'>LOCATION:</span>
    //                                     <span>: {doc.docData.locationOfTheRisk.toUpperCase()}</span>
    //                                     <div className='h_3'></div>

    //                                     <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
    //                                         <tbody>
    //                                             {
    //                                                 Object.values(doc.docData.propertyDescriptionItems).map((item, index) => {
    //                                                     return (
    //                                                         <tr key={index}>
    //                                                             <td className='w-3/4 border border-slate-600 p-1' >
    //                                                                 <p>{item.propertyDescription}</p>
    //                                                             </td>
    //                                                             <td className='w-1/4 border border-slate-600 p-1' >
    //                                                                 <p>{item.sumInsured}</p>
    //                                                             </td>
    //                                                         </tr>
    //                                                     )
    //                                                 })
    //                                             }
    //                                         </tbody>
    //                                     </table>

    //                                 </td>
    //                                 <td className='w-1/2 border border-slate-600 p-1 align-top'>
    //                                     <p className='bold'>PREMIUM</p>
    //                                     <p>{NumberFormat(doc.docData.policyNet)}</p>
    //                                 </td>
    //                             </tr>
    //                         )
    //                     }
    //                 })
    //             }
    //         </tbody>
    //     </table>
    // </section>

    //################################################################
    //renewal Schedule Content
    const renewalScheduleContent = () => {

        return (
            <section style={{ display: "none" }}>
                <section ref={componentRefStmt}  >

                    {
                        clientDebitsForSchedule.length > 0 ?
                            clientDebitsForSchedule.map((data, index) => {
                                if (index == 0) {
                                    return (
                                        <span key={index} >
                                            <RenewalScheduleInterface docsForRenewal={clientDebitsForSchedule} clientData={clientGroupSelectionRN} />
                                            {/* <RenewalScheduleTemplate docsForRenewal={clientDebitsForSchedule} clientData={clientGroupSelectionRN} /> */}
                                        </span>
                                    )
                                }


                            })

                            : ''
                    }

                </section>
            </section>
        )

    }

    //################################################################
    //search
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        const a = insurerFiltered.filter(client => (client.docData.clientName.toLowerCase()).includes(searchTerm.toLowerCase()))
        if (searchTerm) { setInsurerFiltered(a) }
        else { setInsurerFiltered(initialInsurerFiltered) }

        // const b = clientGroups.filter(client => (client.docData.groupTitle.toLowerCase()).includes(searchTerm.toLowerCase()))
        // if (searchTerm) { setClientGroups(b) }
        // else { setClientGroups(initialClientGroups) }

    }, [searchTerm])

    const search = <section>
        <div className="h_10"></div>
        <div className='search_wrapper'>
            <div className='input_group_search'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                <label className='placeholder'><p>Search Client</p></label>
            </div>
            <span className='search_icon'><i className="ri-search-2-line"></i></span>
        </div>
        <div className="h_5"></div>
    </section>

    const changeClientGroupDetails = (selectedClient) => {
        setRightNavContent('Client Detailed View');
        setClientGroupSelectionRN(selectedClient);
    }

    // reload on client add to group
    useEffect(() => {
        if (pageReloaderHandlerRN.notification === 'Client Group Updated Successfully' || pageReloaderHandlerRN.notification === 'Client Group Created Successfully') {
            setReloadTwo(Math.random(1000, 10000))
        }
        if (pageReloaderHandlerRN.notification === 'Client Group Created Successfully') {
            setReload(Math.random(1000, 10000))
        }
    }, [pageReloaderHandlerRN])

    // reloader
    useEffect(() => {
        try {
            if (reloadOnSuccessClientGroupChangeHandlerRN) {
                setReloadFour(Math.random(10000, 1000000));
                setReloadOnSuccessClientGroupChangeHandlerRN(false);
            }

        } catch (error) { }
    }, [reloadOnSuccessClientGroupChangeHandlerRN])

    //################################################################
    // grid states
    const [columnsSelected, setColumnsSelected] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [exportGrouped, setExportGrouped] = useState([]);
    const [exportUngrouped, setExportUnGrouped] = useState([])

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.groupTitle,
                doc.members.map((value) => {
                    const { id, docData } = value;
                    return docData.clientName;
                }),
            ]);
        });

        const onlyDispatchedheaderData = 'CLIENT GROUPS';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: ['auto', 'auto', 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {}],
                            ['NO', 'GROUP TITLE', 'GROUP MEMBERS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 7,
            },
            pageOrientation: '',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }


    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = columnsSelected.map((doc, index) => {
            printData.push([
                index + 1,
                doc.groupTitle,
                doc.members.map((value) => {
                    const { id, docData } = value;
                    return docData.clientName;
                }),
            ]);
        });

        const onlyDispatchedheaderData = 'CLIENT GROUPS';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: ['auto', 100, 330],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {}],
                            ['NO', 'GROUP TITLE', 'GROUP MEMBERS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 7,
            },
            pageOrientation: '',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print grouped
    const printGrouped = () => {
        const printData = [];
        const a = exportGrouped.map((doc, index) => {
            printData.push([
                index + 1,
                doc.groupTitle,
                doc.members.map((value) => {
                    const { id, docData } = value;
                    return docData.clientName;
                }),
            ]);
        });

        const onlyDispatchedheaderData = 'GROUPED CLIENTS';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: ['auto', 'auto', 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {}],
                            ['NO', 'GROUP TITLE', 'GROUP MEMBERS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 7,
            },
            pageOrientation: '',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print ungrouped
    const printUnGrouped = () => {
        const printData = [];
        const a = exportUngrouped.map((doc, index) => {
            printData.push([
                index + 1,
                doc.groupTitle,
                doc.members.map((value) => {
                    const { id, docData } = value;
                    return docData.clientName;
                }),
            ]);
        });

        const onlyDispatchedheaderData = 'UNGROUPED CLIENTS';

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: ['auto', 100, 330],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {}],
                            ['NO', 'GROUP TITLE', 'UNGROUPED MEMBERS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 7,
            },
            pageOrientation: '',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // functions consuming grid state
    //delete group - step 4
    const showSuccessMSGOnDeleteGroup = () => {

        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client Group Deleted Successfully.'
        }
        setIsLoading(false);
        setReloadFour(Math.random(10000, 1000000));
        setReload(Math.random(1000, 100000));
    }

    //################################################################
    //delete group - step 3
    const deleteSelectedGroupRecordFromGroups = async (receivedData) => {
        try {

            const cityRef = doc(db, 'ClientGroups', 'PvnnCxssBmfIt6BL6i4P');

            await updateDoc(cityRef, {
                [receivedData]: deleteField()
            });


        } catch (error) { }
    }

    const deleteGroupRecordHandler = () => {
        try {
            columnsSelected.map((group) => {
                deleteSelectedGroupRecordFromGroups(group.id)
            });
        } catch (error) { }
        showSuccessMSGOnDeleteGroup()
    }

    //################################################################
    //delete group - delete group record from client data - step 2
    const deleteGroupFromClientRecord = async (receivedData) => {
        try {

            // const docRef = doc(db, "ClientDetails", receivedData);

            // await updateDoc(docRef, {
            //     group: ''
            // });

            const docRef = doc(db, "ClientData", "JbrVkeL56d5lrA8R2gdF");

            const specificEdit = receivedData + '.group';

            await updateDoc(docRef, {
                [specificEdit]: '',
            });

        } catch (error) { }
    }

    //################################################################
    // delete group - dele - step 1
    const deleteSelectedGroup = () => {
        const executeAbort = ConfirmAction('Confirm You Want To Delete The Selected Client Group?');
        if (!executeAbort) { return false }

        setIsLoading(true)
        try {

            columnsSelected.map((group) => {
                group.members.map((member) => {
                    deleteGroupFromClientRecord(member.docID)
                })
            });

        } catch (error) { }
        deleteGroupRecordHandler();
    }

    //################################################################
    // delete client from group - show msg - step 2
    const showSuccessMSGAfterRemovingClientFromGroup = () => {

        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client Deleted From Group Successfully.'
        }
        props.onePropDown(onePropObj);
        setIsLoading(false)
        setReloadFour(Math.random(10000, 1000000));

    }

    //################################################################
    // delete client from group - step 1
    const deleteClientFromGroupHandler = (receivedClientID) => {
        const executeAbort = ConfirmAction('Confirm You Want To Delete The Selected Client From Group?');
        if (!executeAbort) { return false }

        setIsLoading(true);

        deleteGroupFromClientRecord(receivedClientID)
            .then(() => { showSuccessMSGAfterRemovingClientFromGroup() })
            .catch(error => { });

    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
            //ProcessClientStatementTransactions({ clientName: selectedRow.client, insurer: 'MAYFAIR INSURANCE COMPANY LIMITED' })
        });
        setColumnsSelected(a);
    };



    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 14rem)', width: '100%', size: 'landscape' }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 50, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'groupTitle', headerName: 'Group Title', checkboxSelection: true, headerCheckboxSelection: true, maxWidth: 170, sort: 'asc', },
        {
            field: 'members', headerName: 'Members', filter: true, wrapText: true, autoHeight: true, filter: false, sortable: false,
            cellRenderer: params => {
                return Object.values(params.value).map((member, index) => {
                    // return <span className='border mr-2 p-1 rounded	 border-indigo-500 cursor-pointer' key={index} onClick={() => { changeClientGroupDetails(member) }}>
                    //     {member.docData.clientName}
                    //     <i className="ri-close-line p-1 align-middle" onClick={() => { deleteClientFromGroupHandler(member.docID) }}></i>
                    // </span>

                    return <span className='border p-2 mr-1 rounded	 border-indigo-500 cursor-pointer' key={index} onClick={() => { changeClientGroupDetails(member) }}>
                        <span className='inline-block'>
                            <p className='inline-block mr-1'>{member.docData.clientName}</p>
                            <i className='ri-close-line  align-middle text-pink-950' onClick={() => { deleteClientFromGroupHandler(member.docID) }}></i>
                        </span>
                    </span>
                })
            },
        },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>

        {search}
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                preventDefaultOnContextMenu={true}
                onSelectionChanged={onSelectionChanged}
            ></AgGridReact>
        </div>
    </section>



    return (
        <section className="body_input_styles">
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Client Groups</p>
                    <div className='cursor-pointer'>
                        <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                        <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContent('Create New Client Group') }}>Create New Group</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={deleteSelectedGroup}>Delete Group</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print All</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printGrouped}>Print Grouped</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printUnGrouped}>Print Ungrouped</p>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    {renewalScheduleContent()}
                    {content}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default ClientGroupFinal;