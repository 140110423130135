import React, { useEffect, useState, useRef } from "react";
import styles from "./EmployeeProfile.module.css";
import Loader from "../Loader/Loader";
import { useRecoilState } from "recoil";
import {
  rightNavContent,
  leaveApplicationActiveEmployee,
  employeeProfileReloaderLeaveRequest,
} from "../../../atoms";
import { useReactToPrint } from "react-to-print";
import companyLogo from "../../Assets/img/logo.png";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import GetAllEmployeeDetails from "../../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import DateFormat from '../../Hooks/UI/DateFormat';

const EmployeeProfile = () => {
  //current user
  const currentUser = JSON.parse(GetCookie("CurrentUserSession"));

  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }

  //################################################################
  // recoil values
  const [rightNavContentRN, setRightNavContentRN] =
    useRecoilState(rightNavContent);
  const [
    leaveApplicationActiveEmployeeRN,
    setLeaveApplicationActiveEmployeeRN,
  ] = useRecoilState(leaveApplicationActiveEmployee);
  const [
    employeeProfileReloaderLeaveRequestRN,
    setEmployeeProfileReloaderLeaveRequestRN,
  ] = useRecoilState(employeeProfileReloaderLeaveRequest);

  //################################################################
  // printing
  const componentRef = useRef();
  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  //################################################################
  // get employee details
  const [employeeDocs, setEmployeeDocs] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAllEmployeeDetails();
      setEmployeeDocs(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReload(Math.random(0, 10000));
      }
    })();
  }, [reload]);

  //################################################################
  //active employee
  const [activeEmployeeProfile, setActiveEmployeeProfile] = useState({});
  useEffect(() => {
    try {
      const employee = employeeDocs.filter(
        (doc) => doc.docData.email === currentUser.userEmail
      );
      if (employee.length > 0) {
        setActiveEmployeeProfile(employee[0]);
      }
    } catch (error) {}
  }, [employeeDocs]);

  //################################################################
  // get user input
  const [name, setName] = useState("");

  //################################################################
  // client data handler
  const leaveApplicationHandler = () => {
    try {
      setLeaveApplicationActiveEmployeeRN(activeEmployeeProfile.docID);
      setRightNavContentRN("Leave Application");
    } catch (error) {}
  };

  //################################################################
  // reload on success leave request
  useEffect(() => {
    if (employeeProfileReloaderLeaveRequestRN === true) {
      setReload(Math.random(10000, 100000));
      setEmployeeProfileReloaderLeaveRequestRN(false);
    }
  }, [employeeProfileReloaderLeaveRequestRN]);

  // if (Object.keys(activeEmployeeProfile).length > 0) {
  //     console.log(activeEmployeeProfile.docData.pendingLeaveRequest.leaveDate.length);
  //     setShowLeaveForm(true)
  // }

  //################################################################
  // crete insurer Content
  const content = (
    <section className="f_7">
      <table className="w-full table-fixed border-collapse border border-slate-500">
        <tbody>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.name
                }
                placeholder="Name"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Email </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.email
                }
                placeholder="Email"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Kra Pin </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.kraPin
                }
                placeholder="KRA Pin"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>NHIF No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.nhifNo
                }
                placeholder="NHIF No"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>ID No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.idNo
                }
                placeholder="ID No"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Phone No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.phoneNo
                }
                placeholder="Phone No"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>NSSF No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.nssfNo
                }
                placeholder="NSSF No"
                className={styles.veh_input}
                disabled
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Company Branch </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.companyBranch
                }
                placeholder="Company Branch"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Account Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.bankAccName
                }
                placeholder="Bank Account Name"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.bankName
                }
                placeholder="Bank Name"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Branch </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.bankBranch
                }
                placeholder="Bank Branch"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Account </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.bankAccNo
                }
                placeholder="Bank Account No"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Documents </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={""}
                placeholder="Employee Documents"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Status </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.employeeStatus
                }
                placeholder="Employee Status"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Department </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.employeeDepartment
                }
                placeholder="Employee Status"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Basic Salary </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={
                  Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.basicSalary.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                }
                placeholder="Basic Salary"
                className={styles.veh_input}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Leave Days</p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <p>
                {Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.leaveDays + "/25"}
              </p>
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Sick Leave Days</p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <p>
                {Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.sickleaveDays + "/14"}
              </p>
            </td>
          </tr>

          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Leave Days Pending Approval</p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <p>
                {Object.keys(activeEmployeeProfile).length > 0 &&
                  activeEmployeeProfile.docData.pendingLeaveRequest.leaveDate
                    .length}
              </p>
            </td>
          </tr>

          {/* <tr>
                        <td className="w-3/12 border border-slate-600 p-1">
                            <p>Leave Days Pending Approval</p>
                        </td>
                        <td className="w-9/12 border border-slate-600 p-1">
                            <p>
                                {
                                    Object.keys(activeEmployeeProfile).length > 0 &&
                                    activeEmployeeProfile.docData.pendingLeaveRequest.leaveDate.length
                                }
                            </p>
                        </td>
                    </tr> */}
        </tbody>
      </table>
    </section>
  );


  // const leaveDateBegin = 
  //  activeEmployeeProfile.docData.pendingLeaveRequest.leaveDate
   //console.log(leaveDateBegin)
  

  // const dateObject = new Date(
  //   {}.seconds *
  //     1000
  // );

  // const formattedDate = dateObject.toLocaleDateString();

  // Handling remaining leave days
  const [leaveApproved, handleLeaveApproved] = useState(false);
  const [leaveRejected, handleLeaveRejected] = useState(false);
  const [reasonSick, setReasonSick] = useState(false);
  const [reasonBereavement, setReasonBereavement] = useState(false);
  const [reasonUnpaidLeave, setReasonUnpaidLeave] = useState(false);
  const [reasonPersonal, setReasonPersonal] = useState(false);
  const [reasonMaternity, setReasonMaternity] = useState(false);
  const [reasonOther, setReasonOther] = useState('')

  useEffect(() => {
    if (Object.keys(activeEmployeeProfile).length > 0 && 
      activeEmployeeProfile.docData.pendingLeaveRequest.reason === 'Sick') {
      setReasonSick(true) } else {
      setReasonSick(false)
      }
    if(Object.keys(activeEmployeeProfile).length > 0 &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason === 'Bereavement') {
        setReasonBereavement(true)
      } else {
        setReasonBereavement(false)
      }
    if(Object.keys(activeEmployeeProfile).length > 0 &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason === 'Unpaid') {
        setReasonUnpaidLeave(true)
      } else {
        setReasonUnpaidLeave(false)
      }
    if(Object.keys(activeEmployeeProfile).length > 0 &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason === 'Peasonal leave') {
        setReasonPersonal(true)
      } else {
        setReasonPersonal(false)
      }
    if(Object.keys(activeEmployeeProfile).length > 0 &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason === 'Maternity / paternity') {
        setReasonMaternity(true)
      } else{
        setReasonMaternity(false)
      } 
    if(Object.keys(activeEmployeeProfile).length > 0 && 
      activeEmployeeProfile.docData.pendingLeaveRequest.reason !== 'Sick' &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason !== 'Bereavement' &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason !== 'Unpaid' &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason !== 'Peasonal leave' &&
      activeEmployeeProfile.docData.pendingLeaveRequest.reason !== 'Maternity / paternity'
      )  {
      setReasonOther(activeEmployeeProfile.docData.pendingLeaveRequest.reason.toUpperCase())
    }

  }, [activeEmployeeProfile, reasonSick, reasonBereavement, reasonUnpaidLeave, reasonPersonal, reasonMaternity, reasonOther])

  const leaveApplicationForm = (
    <section>
      {Object.keys(activeEmployeeProfile).length > 0 && (
        <section>
          <div colSpan={4} className={styles.companyLogoContainer}>
            <img
              src={companyLogo}
              alt="Company Logo"
              className={styles.companyLogo}
            />
          </div>

          <h2 className={styles.leaveFormTitle}>LEAVE APPLICATION FORM</h2>
          <table className={styles.tableMain}>
            <tbody className={styles.tableBody}>
              <tr className={styles.tableRow}>
                <td className={styles.tableTitle}>
                  <p>NAME</p>
                </td>
                <td className={styles.tableField}>
                  
                  <p>
                    {Object.keys(activeEmployeeProfile).length > 0 &&
                    activeEmployeeProfile.docData.name}
                  </p>
                  {/* <input
                    value={
                      Object.keys(activeEmployeeProfile).length > 0 &&
                      activeEmployeeProfile.docData.name
                    }
                    placeholder="Name"
                    className={styles.veh_input}
                    disabled
                    readOnly
                  /> */}
                </td>
                <td className={styles.tableTitle}>
                  <p>COMPANY BRANCH</p>
                </td>
                <td className={styles.tableField}>
                  <input
                    value={
                      Object.keys(activeEmployeeProfile).length > 0 &&
                      activeEmployeeProfile.docData.companyBranch
                    }
                    placeholder="Company Branch"
                    className={styles.veh_input}
                    readOnly
                  />
                </td>
              </tr>
              <tr className={styles.tableRowDepartment}>
                <td className={styles.tableTitle}>
                  <p>DEPARTMENT</p>
                </td>
                <td className={styles.tableField}>
                  <input
                    value={
                      Object.keys(activeEmployeeProfile).length > 0 &&
                      activeEmployeeProfile.docData.employeeDepartment.toUpperCase()
                    }
                    placeholder="Department"
                    className={styles.veh_input}
                    disabled
                    readOnly
                  />
                </td>
              </tr>
              <tr className={styles.tableRowReason}>
                <td colSpan={4} className={styles.tableDataReason}>
                  <p className={styles.fieldsTitles}>
                    REASON FOR LEAVE REQUEST
                  </p>
                  <table colSpan={2} className={styles.reasonTable}>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>SICK</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <input
                          className={styles.approvalCheckboxInput}
                          type="checkbox"
                          checked={reasonSick}
                          // onChange={handleReason}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>BEREAVEMENT</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <input
                          className={styles.approvalCheckboxInput}
                          type="checkbox"
                          checked={reasonBereavement}
                          // onChange={handleReason}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>UNPAID LEAVE</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <input
                          className={styles.approvalCheckboxInput}
                          type="checkbox"
                          checked={reasonUnpaidLeave}
                          // onChange={handleReasonUnpaidLeave}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>PERSONAL LEAVE</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <input
                          className={styles.approvalCheckboxInput}
                          type="checkbox"
                          checked={reasonPersonal}
                          // onChange={handleReasonPersonal}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>MATERNITY / PATERNITY</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <input
                          className={styles.approvalCheckboxInput}
                          type="checkbox"
                          checked={reasonMaternity}
                          // onChange={handleReasonMaternity}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className={styles.reasonText}>
                        <p>OTHER</p>
                      </td>
                      <td colSpan={1} className={styles.reasonCheckbox}>
                        <p className={styles.otherReason}>{reasonOther}</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className={styles.tableRowReason}>
                <td colSpan={4} className={styles.tableDataReason}>
                  <p className={styles.fieldsTitles}>DATES REQUESTED</p>
                  <table colSpan={4} className={styles.periodTableHolder}>
                    <tr colSpan={2} className={styles.periodTableRow}>
                      <td colSpan={1} className={styles.periodTableTitle}>
                        <p>FROM</p>
                      </td>
                      <td colSpan={1} className={styles.periodTableField}>
                        <input
                          value={
                            Object.keys(activeEmployeeProfile).length > 0 &&
                              activeEmployeeProfile.docData.pendingLeaveRequest.length > 0
                              ? new Date(
                                  activeEmployeeProfile.docData
                                    .pendingLeaveRequest.leaveDate[0].seconds *
                                    1000
                                ).toLocaleDateString()
                              : ""
                          }
                          placeholder="Start Date"
                          className={styles.veh_input}
                          readOnly
                        />
                      </td>
                      <td colSpan={1} className={styles.periodTableTitle}>
                        <p>TO</p>
                      </td>
                      <td colSpan={1} className={styles.periodTableField}>
                        <input
                          value={
                            Object.keys(activeEmployeeProfile).length > 0 &&
                              activeEmployeeProfile.docData.pendingLeaveRequest.length > 0
                              ? new Date(
                                  activeEmployeeProfile.docData
                                    .pendingLeaveRequest.leaveDate[
                                    activeEmployeeProfile.docData
                                      .pendingLeaveRequest.leaveDate.length - 1
                                  ].seconds * 1000
                                ).toLocaleDateString()
                              : ""
                          }
                          placeholder="Start Date"
                          className={styles.veh_input}
                          readOnly
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className={styles.tableRowReason}>
                <td colSpan={4} className={styles.employerSignature}>
                  <p className={styles.fieldsTitles}>EMPLOYEE'S SIGNATURE</p>
                  <p className={styles.fieldsTitles}>DATE</p>
                </td>
              </tr>
              <tr className={styles.tableRowReason}>
                <td colSpan={4} className={styles.employerApproval}>
                  <div>
                    <p className={styles.fieldsTitles}>DIRECTOR APPROVAL</p>
                    <label className={styles.approvalCheckBox}>
                      <span className={styles.checkbox_text}>APPROVED:</span>
                      <input
                        className={styles.approvalCheckboxInput}
                        type="checkbox"
                        checked={leaveApproved}
                        onChange={handleLeaveApproved}
                      />
                    </label>
                    <label>
                      <span className={styles.checkbox_text_two}>
                        REJECTED:
                      </span>
                      <input
                        className={styles.approvalCheckboxInput}
                        type="checkbox"
                        checked={leaveRejected}
                        onChange={handleLeaveRejected}
                      />
                    </label>
                  </div>
                </td>
              </tr>
              <tr className={styles.tableRowReason}>
                <td colSpan={4} className={styles.employerComments}>
                  <p className={styles.fieldsTitles}>COMMENTS:</p>
                  <p>LEAVE DAYS BROUGHT FORWARD:</p>
                  <p>LEAVE DAYS APPLIED FOR:</p>
                  <p>LEAVE DAYS REMAINING:</p>
                </td>
              </tr>

              {/* <tr>
                        <td className="w-3/12 border border-slate-600 p-1">
                            <p>Leave Days Pending Approval</p>
                        </td>
                        <td className="w-9/12 border border-slate-600 p-1">
                            <p>
                                {
                                    Object.keys(activeEmployeeProfile).length > 0 &&
                                    activeEmployeeProfile.docData.pendingLeaveRequest.leaveDate.length
                                }
                            </p>
                        </td>
                    </tr> */}
            </tbody>
          </table>
        </section>
      )}
    </section>
  );

  return (
    <section>
      <div className="body_section_header">
        <p>Employee Profile</p>
        <div className="cursor-pointer">
          <p className="peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded">
            Controls
          </p>
          <div className="hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999">
            <p
              className="hover:bg-gray-200 px-2 py-2 rounded"
              onClick={leaveApplicationHandler}
            >
              Leave Application
            </p>
            <p
              className="hover:bg-gray-200 px-2 py-2 rounded"
              onClick={printHandler}
            >
              Print Leave Form
            </p>
          </div>
        </div>
      </div>
      <div className="body_section">
        {content}
        {/* Printing */}
        <div style={{ display: "none" }}>
          <div ref={componentRef}>{leaveApplicationForm}</div>
        </div>
      </div>

      {loaderContent}
    </section>
  );
};

export default EmployeeProfile;
