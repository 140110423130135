import axios from "axios";

const CreateUserActivity = async (data) => {
    
    const url = 'https://addmessage-7fqanz2g2q-uc.a.run.app/createuseractivity';
    
    axios.post(url, data)
        .then(response => { return response.data; })
        .catch(error => { console.error('Error:', error) });
}
export default CreateUserActivity;