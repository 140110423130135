import React, { useRef, useState } from "react";
import db from "../../../firebase";
import { doc, updateDoc, runTransaction } from "firebase/firestore";
import Loader from "../../Body/Loader/Loader";
import styles from './CreateEmployee.module.css';
import GetCookie from "../../Hooks/Cookie/GetCookie";
import { DatePickerInput } from "@mantine/dates";
import UUID from "../../Hooks/UUID/UUID";

const CreateEmployee = (props) => {
  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = '';
  if (isLoading === true) {
    loaderContent = <Loader />
  }

  //################################################################
  //current user
  const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

  //################################################################
  // get user input
  const [name, setName] = useState('')
  const [email, setEmail] = useState("");
  const [kraPin, setKraPin] = useState("");
  const [nhifNo, setNhifNo] = useState("");
  const [idNo, setIdNo] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [nssfNo, setNssfNo] = useState("");
  const [companyBranch, setCompanyBranch] = useState("")
  const [bankAccName, setBankAccName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [employeeDocuments, setEmployeeDocuments] = useState("");
  const [basicSalary, setBasicSalary] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("Active")
  const [dateStarted, setDateStarted] = useState(new Date());
  const [employeeDepartment, setEmployeeDepartment] = useState("")
  const payrollRecord = useState("")


  //################################################################
  //step 3
  //show msg
  const showSuccessMSGOnExpenseSave = () => {
    setIsLoading(false);

    const onePropObj = {
      setView: 'Human Resource',
      previousView: '',
      postedDocRef: '',
      dateFrom: '',
      dateTo: '',
      searchTerm: '',
      notification: 'Employee Record Created Successfully.'
    }
    props.onePropDown(onePropObj);
  }

  //step 2
  //post data
  const createEmployeeHandler = async (receivedEmployeeID) => {
    try {

      const employeeObj = {
        name: name.trim().toUpperCase(),
        employeeID: receivedEmployeeID,
        dateCreated: new Date(),
        createdBy: userDetails,
        email: email.trim(),
        kraPin: kraPin.trim().toUpperCase(),
        nhifNo: nhifNo.trim().toUpperCase(),
        idNo: idNo.trim().toUpperCase(),
        phoneNo: phoneNo.trim().toUpperCase(),
        nssfNo: nssfNo.trim().toUpperCase(),
        dateStarted: dateStarted,
        companyBranch: companyBranch.trim().toUpperCase(),
        bankAccName: bankAccName.trim().toUpperCase(),
        bankName: bankName.trim().toUpperCase(),
        bankBranch: bankBranch.trim().toUpperCase(),
        bankAccNo: bankAccNo.trim().toUpperCase(),
        employeeDocuments: {},
        leaveRecords: [],
        employeeStatus: employeeStatus.trim(),
        employeeDepartment : employeeDepartment.trim().toUpperCase(),
        basicSalary: basicSalary.trim().toUpperCase(),
        pendingLeaveRequest: [],
        accessControl:[],
        leaveDays: '25',
        sickleaveDays: '14'
      };

      const washingtonRef = doc(db, "EmployeeDetails", "hsPhw4lkUuDuR8jgABkH");

      //get unique id
      const employeeDataID = UUID() + ' - ' + UUID();

      // Set the "capital" field of the city 'DC'
      await updateDoc(washingtonRef, {
        [employeeDataID]: employeeObj
      });

      showSuccessMSGOnExpenseSave();

    } catch (error) { 
      console.log("The Error " + error)
    }
  }

  //step 1
  //get pc voucher
  const processPCN = async () => {
    setIsLoading(true);

    const sfDocRef = doc(db, "SystemControls", "57j4Ju1V7OvwBjGP0gyi");

    try {
      let qwe = await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);
        if (!sfDoc.exists()) {
          throw "Document does not exist!";
        }

        const newEmployeeID = sfDoc.data().employeeID + 1;
        transaction.update(sfDocRef, { employeeID: newEmployeeID });
        const newReceiptCountLength = newEmployeeID.toString().length;

        let SRSuffix = '';

        switch (true) {
          case (newReceiptCountLength == 1):
            SRSuffix = '000';
            break;
          case (newReceiptCountLength == 2):
            SRSuffix = '00';
            break;
          case (newReceiptCountLength == 3):
            SRSuffix = '0';
            break;
          case (newReceiptCountLength >= 4):
            SRSuffix = '';
            break;
        }

        const qw = 'SIAL' + SRSuffix + newEmployeeID;
        return qw
      });

      if (qwe) {
        createEmployeeHandler(qwe)
      }

    } catch (e) { }
  }

  //################################################################
  // crete insurer Content
  const content = (
    <section className="f_7">
      <table className="w-full table-fixed border-collapse border border-slate-500">
        <tbody>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Email </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Kra Pin </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={kraPin}
                onChange={(e) => {
                  setKraPin(e.target.value);
                }}
                placeholder="KRA Pin"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>NHIF No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={nhifNo}
                onChange={(e) => {
                  setNhifNo(e.target.value);
                }}
                placeholder="NHIF No"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>ID No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={idNo}
                onChange={(e) => {
                  setIdNo(e.target.value);
                }}
                placeholder="ID No"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Phone No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={phoneNo}
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
                placeholder="Phone No"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>NSSF No </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={nssfNo}
                onChange={(e) => {
                  setNssfNo(e.target.value);
                }}
                placeholder="NSSF No"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Company Branch </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={companyBranch}
                onChange={(e) => {
                  setCompanyBranch(e.target.value);
                }}
                placeholder="Company Branch"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Account Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={bankAccName}
                onChange={(e) => {
                  setBankAccName(e.target.value);
                }}
                placeholder="Bank Account Name"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Name </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
                placeholder="Bank Name"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Branch </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={bankBranch}
                onChange={(e) => {
                  setBankBranch(e.target.value);
                }}
                placeholder="Bank Branch"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Bank Account </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={bankAccNo}
                onChange={(e) => {
                  setBankAccNo(e.target.value);
                }}
                placeholder="Bank Account No"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Documents </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={employeeDocuments}
                onChange={(e) => {
                  setEmployeeDocuments(e.target.value);
                }}
                placeholder="Employee Documents"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Basic Salary </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={basicSalary}
                onChange={(e) => {
                  setBasicSalary(e.target.value);
                }}
                placeholder="Basic Salary"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Status </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={employeeStatus}
                onChange={(e) => {
                  setEmployeeStatus(e.target.value);
                }}
                placeholder="Employee Status"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Employee Department </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <input
                value={employeeDepartment}
                onChange={(e) => {
                  setEmployeeDepartment(e.target.value);
                }}
                placeholder="Employee Department"
                className={styles.veh_input}
              />
            </td>
          </tr>
          <tr>
            <td className="w-3/12 border border-slate-600 p-1">
              <p>Date Started </p>
            </td>
            <td className="w-9/12 border border-slate-600 p-1">
              <DatePickerInput
                dropdownType="modal"
                placeholder="Pick date"
                value={dateStarted}
                onChange={setDateStarted}
                minDate={new Date(2021, 5, 1)}
                variant="unstyled"
                mx="left"
                maw={300}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );

  return (
    <section>
      <div className='body_section_header'>
        <p>Create Employee</p>
        <button onClick={processPCN} className='btn_pink'>Save Details</button>
      </div>
      <div className='body_section'>
        {content}
      </div>
      {loaderContent}
    </section>
  )
}

export default CreateEmployee;