import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { logbookReportInsurerFilter, logbookReportFilterType } from "../../../atoms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllMotorDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllMotorDocsTransactionAndInsurerWise";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const LogBookReportGrid = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const logbookReportInsurerFilterRN = useRecoilValue(logbookReportInsurerFilter);
    const logbookReportFilterTypeRN = useRecoilValue(logbookReportFilterType);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllMotorDocsTransactionAndInsurerWise({ insurerName: logbookReportInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, logbookReportInsurerFilterRN]);

    //################################################################
    //remove cancelled lapsed
    const [debitDocsClean, setDebitDocsClean] = useState([]);
    useEffect(() => {
        const list = debitDocs.filter(doc => doc.docData.status != 'Cancelled' && doc.docData.status != 'Lapsed');
        setDebitDocsClean(list);
    }, [debitDocs])

    //################################################################
    //extract veh details
    const [extractedData, setExtractedData] = useState([])
    useEffect(() => {
        const list = [];
        debitDocsClean.map((doc) => {
            if (doc.docData.vehicleItems) {
                Object.values(doc.docData.vehicleItems).map((veh) => {
                    if (veh.regNo) {
                        list.push({
                            docID: doc.docID, docData: doc.docData, plateNo: veh.regNo, clientName: doc.docData.clientName, policyNumber: doc.docData.policyNumber, policyClass: doc.docData.selectedProductSubClass,
                        })
                    }
                })

            }
        });
        setExtractedData(list)
    }, [debitDocsClean]);

    //################################################################
    //categorise into plate no
    const [cleanData, setCleanData] = useState([]);
    useEffect(() => {
        const groupedItems = extractedData.reduce((acc, item) => {
            if (!acc[item.plateNo]) {
                acc[item.plateNo] = [];
            }
            acc[item.plateNo].push(item);
            return acc;
        }, {});
        setCleanData(groupedItems);
    }, [extractedData]);

    //################################################################
    //extract lb link
    const [analysedData, setAnalsedData] = useState([]);
    useEffect(() => {
        const list = []
        Object.values(cleanData).map((vehicle) => {
            const logBookDocuments = [];
            let clientName = '';
            let policyNumber = '';
            let policyClass = '';
            let plateNo = ''
            vehicle.map((doc) => {
                clientName = doc.clientName;
                plateNo = doc.plateNo;
                policyNumber = doc.policyNumber;
                policyClass = doc.policyClass;
                if (doc.docData.motorDocuments) {
                    Object.values(doc.docData.motorDocuments).map((item) => {
                        if (item.documentCategory === 'Logbook' && item.documentVehiclePlateNo.trim().toLowerCase() === doc.plateNo.trim().toLowerCase()) {
                            logBookDocuments.push(item.documentDownloadLink);
                        }
                    })
                }
            })
            list.push({
                logBookDocuments: logBookDocuments, plateNo: plateNo, clientName: clientName, policyNumber: policyNumber, policyClass: policyClass,
            });
        });
        setAnalsedData(list);
    }, [cleanData])

    //################################################################
    //filter missing n present
    const [typeFilteredDocs, setTypeFilteredDocs] = useState([]);
    useEffect(() => {
        try {

            const list = [];
            analysedData.map((doc) => {
                if (logbookReportFilterTypeRN === 'All') {
                    list.push(doc)
                }
                else if (logbookReportFilterTypeRN === 'Logbook Present') {
                    if (doc.logBookDocuments.length > 0) {
                        list.push(doc)
                    }
                }
                else if (logbookReportFilterTypeRN === 'Logbook Missing') {
                    if (doc.logBookDocuments.length == 0) {
                        list.push(doc)
                    }
                }
            });
            setTypeFilteredDocs(list);

        } catch (error) { console.log(error) }
    }, [analysedData, logbookReportFilterTypeRN]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'plateNo', 'policyNumber', 'policyClass',]

    const exportToExcelFileName = 'LOG BOOK REPORT    --    INSURER: ' + logbookReportInsurerFilterRN + '    --    CATEGORY: ' + logbookReportFilterTypeRN + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.plateNo,
                doc.policyNumber,
                doc.policyClass,
            ])
        });


        const onlyDispatchedheaderData = 'LOG BOOK REPORT    --    INSURER: ' + logbookReportInsurerFilterRN + '    --    CATEGORY: ' + logbookReportFilterTypeRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {},],
                            ['NO', 'INSURED', 'PLATE NO', 'POLICY NO', 'POLICY CLASS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.plateNo,
                doc.policyNumber,
                doc.policyClass,
            ])
        });


        const onlyDispatchedheaderData = 'LOG BOOK REPORT    --    INSURER: ' + logbookReportInsurerFilterRN + '    --    CATEGORY: ' + logbookReportFilterTypeRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {},],
                            ['NO', 'INSURED', 'PLATE NO', 'POLICY NO', 'POLICY CLASS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'plateNo', headerName: 'Plate No', width: 150, },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },        
        { field: 'policyClass', headerName: 'Policy Class', width: 150, },
        {
            field: 'logBookDocuments', headerName: 'LB', sortable: false, filter: false, maxWidth: 150,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={typeFilteredDocs}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>LogBook Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default LogBookReportGrid;