import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import db from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import GetAllReceipts from '../../Hooks/BackendConn/GeneralList/GetAllReceipts';
import Loader from '../Loader/Loader';
import GetAllPolicies from '../../Hooks/BackendConn/InsurerSpecific/GetAllPolicies';
import DateFormat from '../../Hooks/UI/DateFormat';
import { read, utils, writeFileXLSX } from 'xlsx';
import { useRecoilState, useRecoilValue } from 'recoil';
import { insurerStatementInsurerFilter, insurerStatementFilterFromDate, insurerStatementFilterToDate } from '../../../atoms';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};


const InsurerStatementGrid = (props) => {

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);
    const a = () => {
        const wb = XLSX.utils.table_to_book(tbl.current);
        XLSX.writeFile(wb, "ReactTable.xlsx");
    }

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "INSURER STATEMENT.xlsx");
    }, [tbl]);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const [insurerStatementInsurerFilterRN, setInsurerStatementInsurerFilterRN] = useRecoilState(insurerStatementInsurerFilter);
    const insurerStatementFilterFromDateRN = useRecoilValue(insurerStatementFilterFromDate);
    const insurerStatementFilterToDateRN = useRecoilValue(insurerStatementFilterToDate);

    //################################################################
    //get all claims
    const [allDocs, setAllDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllPolicies({ insurer: insurerStatementInsurerFilterRN });
            setAllDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, insurerStatementInsurerFilterRN]);

    //################################################################
    //brought forward feature
    const [BFPolicyDocs, setBFPolicyDocs] = useState([]);
    useEffect(() => {
        const BFDebits = [];
        const BFCreditsReceipts = [];
        const PeriodSelected = [];

        allDocs.map((doc, index) => {
            //declare filter date
            let filterDate = '';
            switch (doc.docData.transactionType) {
                case 'Debit':
                    filterDate = new Date(doc.docData.insurerDebitDate);
                    filterDate.setHours(0, 0, 0);
                    break;
                case 'Credit':
                    filterDate = new Date(doc.docData.insurerDebitDate);
                    filterDate.setHours(0, 0, 0);
                    break;
                case 'Receipt':
                    filterDate = new Date(doc.docData.receiptDate);
                    filterDate.setHours(0, 0, 0);
                    break;
            }
            //sort into array
            if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Debit') {
                BFDebits.push(doc.docData.insurerPolicyNet);
            }
            else if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Credit') {
                BFCreditsReceipts.push(doc.docData.insurerPolicyNet);
            }
            else if (new Date(insurerStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Receipt') {
                BFCreditsReceipts.push(doc.docData.receiptAmount);
            }
            else if (new Date(filterDate).valueOf() >= new Date(insurerStatementFilterFromDateRN).valueOf() && new Date(filterDate).valueOf() <= new Date(insurerStatementFilterToDateRN).valueOf()) {
                PeriodSelected.push(doc);
            }
        })

        const BFTotalBFDebits = BFDebits.reduce((total, item) => { return total + +item }, 0)
        const BFTotalCredits = BFCreditsReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = +BFTotalBFDebits - +BFTotalCredits

        //get BF
        const BTDetails = {
            policyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding,
            docData: {
                transactionType: 'Brought Forward',
                insurerDebitDate: insurerStatementFilterFromDateRN,
                policyNumber: 'BF',
            },
        }

        PeriodSelected.unshift(BTDetails);
        setBFPolicyDocs(PeriodSelected);

    }, [allDocs, insurerStatementFilterFromDateRN, insurerStatementFilterToDateRN])

    //################################################################
    // sort by date
    const [dateSortedPolicyDocs, setDateSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFPolicyDocs.sort(byDate);
        function byDate(a, b) {
            function ab(doc) {
                //declare filter date
                let filterDate = '';
                switch (doc.docData.transactionType) {
                    case 'Debit':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Credit':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Receipt':
                        filterDate = new Date(doc.docData.receiptDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Brought Forward':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                }
                return filterDate;
            }
            return new Date(ab(a)).valueOf() - new Date(ab(b)).valueOf();
        }
        setDateSortedPolicyDocs(sortedList);
    }, [BFPolicyDocs]);

    //################################################################
    //calculate outstanding
    const [calculatedOutstandingPolicyDocs, setCalculatedOutstandingPolicyDocs] = useState([]);
    useEffect(() => {
        if (dateSortedPolicyDocs.length > 0) {
            for (let i = 1; i < dateSortedPolicyDocs.length; i++) {
                const previousIndex = i - 1;

                if (dateSortedPolicyDocs[0].docData.transactionType === 'Receipt') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = -dateSortedPolicyDocs[0].docData.receiptAmount
                }
                else if (dateSortedPolicyDocs[0].docData.transactionType === 'Brought Forward') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = dateSortedPolicyDocs[0].cummulativeOutstanding;
                }
                else if (dateSortedPolicyDocs[0].docData.transactionType === 'Credit') {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = -dateSortedPolicyDocs[0].docData.insurerPolicyNet;
                }
                else {
                    dateSortedPolicyDocs[0].cummulativeOutstanding = dateSortedPolicyDocs[0].docData.insurerPolicyNet
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Debit' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding + +dateSortedPolicyDocs[i].docData.insurerPolicyNet
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Receipt' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding - +dateSortedPolicyDocs[i].docData.receiptAmount
                }
                if (dateSortedPolicyDocs[i].docData.transactionType === 'Credit' && dateSortedPolicyDocs.length > 1) {
                    dateSortedPolicyDocs[i].cummulativeOutstanding = +dateSortedPolicyDocs[previousIndex].cummulativeOutstanding - +dateSortedPolicyDocs[i].docData.insurerPolicyNet
                }
            }
        }
        setCalculatedOutstandingPolicyDocs(dateSortedPolicyDocs);
    }, [dateSortedPolicyDocs])

    //################################################################
    //get totals
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalPVTPremium, setTotalPVTPremium] = useState(0);
    const [totalStampDuty, setTotalStampDuty] = useState(0);
    const [totalTLevy, setTotalTLevy] = useState(0);
    const [totalPHCFund, setTotalPHCFund] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalWithholding, setTotalWithholding] = useState(0);
    const [totalPolicyNet, setTotalPolicyNet] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);

    const [addedTotals, setAddedTotals] = useState([])

    useEffect(() => {
        const totalPremiumItems = [];
        const totalPVTPremiumItems = [];
        const totalStampDutyItems = [];
        const totalTLevyItems = [];
        const totalPHCFundItems = [];
        const totalCommissionItems = [];
        const totalWithholdingTaxItems = [];
        const totalPolicyNetItems = [];
        const totalReceiptsItems = [];

        calculatedOutstandingPolicyDocs.map((doc) => {
            if (doc.docData.transactionType === 'Debit' && !doc.docData.policyNumber.startsWith('JV') && !doc.docData.policyNumber.startsWith('DV') && !doc.docData.policyNumber.startsWith('Rev')) {
                totalPremiumItems.push(doc.docData.premium)
                totalPremiumItems.push(doc.docData.earthquake)
                totalPremiumItems.push(doc.docData.excessProtector)
                totalPremiumItems.push(doc.docData.windScreen)
                totalPremiumItems.push(doc.docData.radioCasset)
                totalPremiumItems.push(doc.docData.comesaCard)
                totalPVTPremiumItems.push(doc.docData.PVTPremium)
                totalStampDutyItems.push(doc.docData.stampDuty)
                totalTLevyItems.push(doc.docData.TLevy)
                totalPHCFundItems.push(doc.docData.PHCFund)
                totalCommissionItems.push(doc.docData.commission)
                totalWithholdingTaxItems.push(doc.docData.withholdingTax)
                totalPolicyNetItems.push(doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.transactionType === 'Credit' && !doc.docData.policyNumber.startsWith('CN')) {
                totalPremiumItems.push(-doc.docData.premium)
                totalPremiumItems.push(-doc.docData.earthquake)
                totalPremiumItems.push(-doc.docData.excessProtector)
                totalPremiumItems.push(-doc.docData.windScreen)
                totalPremiumItems.push(-doc.docData.radioCasset)
                totalPremiumItems.push(-doc.docData.comesaCard)
                totalPVTPremiumItems.push(-doc.docData.PVTPremium)
                totalStampDutyItems.push(-doc.docData.stampDuty)
                totalTLevyItems.push(-doc.docData.TLevy)
                totalPHCFundItems.push(-doc.docData.PHCFund)
                totalCommissionItems.push(-doc.docData.commission)
                totalWithholdingTaxItems.push(-doc.docData.withholdingTax)
                totalPolicyNetItems.push(-doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.transactionType === 'Receipt') {
                totalReceiptsItems.push(doc.docData.receiptAmount)
            }
            else if (doc.docData.policyNumber.startsWith('JV') || doc.docData.policyNumber.startsWith('DV') || doc.docData.policyNumber.startsWith('Rev')) {
                totalReceiptsItems.push(-doc.docData.insurerPolicyNet)
            }
            else if (doc.docData.policyNumber.startsWith('CN')) {
                totalReceiptsItems.push(doc.docData.insurerPolicyNet)
            }
        })

        //calc
        const a = totalPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPremium(a);
        const b = totalPVTPremiumItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPVTPremium(b);
        const c = totalStampDutyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalStampDuty(c);
        const d = totalTLevyItems.reduce((total, item) => { return total + +item }, 0);
        setTotalTLevy(d);
        const e = totalPHCFundItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPHCFund(e);
        const f = totalCommissionItems.reduce((total, item) => { return total + +item }, 0);
        setTotalCommission(f);
        const g = totalWithholdingTaxItems.reduce((total, item) => { return total + +item }, 0);
        setTotalWithholding(g);
        const h = totalPolicyNetItems.reduce((total, item) => { return total + +item }, 0);
        setTotalPolicyNet(h);
        const i = totalReceiptsItems.reduce((total, item) => { return total + +item }, 0);
        setTotalReceipts(i);

        const totalsObj = [{
            Date: '',
            PolicyNumber: 'TOTALS',
            EndorsmentNumber: '',
            Insured: '',
            TransactionType: '',
            Premium: NumberFormat(a),
            PVTPremium: NumberFormat(b),
            SDuty: NumberFormat(c),
            TLevy: NumberFormat(d),
            PHCFund: NumberFormat(e),
            Comm: NumberFormat(f),
            WTax: NumberFormat(g),
            PolicyNet: NumberFormat(h),
            CreditNet: NumberFormat(i),
            Outstanding: '',
        }]

        setAddedTotals(totalsObj)

    }, [calculatedOutstandingPolicyDocs])


    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        const finalObj = [];
        calculatedOutstandingPolicyDocs.map((doc) => {

            let Date = '';
            let UnformatedDate = ''
            let PolicyNumber = '';
            let EndorsmentNumber = '';
            let clientName = doc.docData.clientName;
            let premium = 0;
            let PVTPremium = 0;
            let SDuty = 0;
            let TLevy = 0;
            let PHCFund = 0;
            let Comm = 0;
            let WTax = 0;
            let PolicyNet = 0;
            let CreditNet = 0;
            let Outstanding = NumberFormat(doc.cummulativeOutstanding);

            if (doc.docData.transactionType === 'Debit') {
                Date = DateFormat(doc.docData.insurerDebitDate);
                UnformatedDate = doc.docData.insurerDebitDate;
                PolicyNumber = doc.docData.policyNumber;
                premium = NumberFormat(+doc.docData.premium + +doc.docData.earthquake + +doc.docData.excessProtector + +doc.docData.windScreen + +doc.docData.radioCasset + +doc.docData.comesaCard);
                PVTPremium = NumberFormat(+doc.docData.PVTPremium);
                SDuty = NumberFormat(+doc.docData.stampDuty);
                TLevy = NumberFormat(+doc.docData.TLevy);
                PHCFund = NumberFormat(+doc.docData.PHCFund);
                Comm = NumberFormat(+doc.docData.commission);
                WTax = NumberFormat(+doc.docData.withholdingTax);
                if (doc.docData.policyNumber.startsWith('JV') || doc.docData.policyNumber.startsWith('DV') || doc.docData.policyNumber.startsWith('Rev')) {
                    PolicyNet = 0
                    CreditNet = NumberFormat(+doc.docData.insurerPolicyNet);
                    EndorsmentNumber = '';
                }
                else {
                    PolicyNet = NumberFormat(+doc.docData.insurerPolicyNet);
                    EndorsmentNumber = doc.docData.endorsmentNumber;
                }

            }
            else if (doc.docData.transactionType === 'Credit') {
                Date = DateFormat(doc.docData.insurerDebitDate);
                UnformatedDate = doc.docData.insurerDebitDate
                PolicyNumber = doc.docData.policyNumber;
                EndorsmentNumber = doc.docData.endorsmentNumber;
                premium = '( ' + NumberFormat(+doc.docData.premium + +doc.docData.earthquake + +doc.docData.excessProtector + +doc.docData.windScreen + +doc.docData.radioCasset + +doc.docData.comesaCard) + ' )';
                PVTPremium = '( ' + NumberFormat(+doc.docData.PVTPremium) + ' )';
                SDuty = '( ' + NumberFormat(+doc.docData.stampDuty) + ' )';
                TLevy = '( ' + NumberFormat(+doc.docData.TLevy) + ' )';
                PHCFund = '( ' + NumberFormat(+doc.docData.PHCFund) + ' )';
                Comm = '( ' + NumberFormat(+doc.docData.commission) + ' )';
                WTax = '( ' + NumberFormat(+doc.docData.withholdingTax) + ' )';
                if (doc.docData.policyNumber.startsWith('CN')) {
                    PolicyNet = '( ' + NumberFormat(0) + ' )';
                    CreditNet = '( ' + NumberFormat(+doc.docData.insurerPolicyNet) + ' )';
                    EndorsmentNumber = '';
                }
                else {
                    PolicyNet = '( ' + NumberFormat(+doc.docData.insurerPolicyNet) + ' )';
                    EndorsmentNumber = doc.docData.endorsmentNumber;
                }


            }
            else if (doc.docData.transactionType === 'Receipt') {
                Date = DateFormat(doc.docData.receiptDate);
                UnformatedDate = doc.docData.receiptDate;
                PolicyNumber = doc.docData.receiptNumber;
                clientName = doc.docData.clientName[0];
                CreditNet = NumberFormat(+doc.docData.receiptAmount);
            }
            else if (doc.docData.transactionType === 'Brought Forward') {
                Date = DateFormat(insurerStatementFilterFromDateRN);
                UnformatedDate = insurerStatementFilterFromDateRN;
                PolicyNumber = doc.docData.policyNumber;
                clientName = '';
            }




            finalObj.push({
                docID: doc.docID,
                Date: Date,
                UnformatedDate: UnformatedDate,
                PolicyNumber: PolicyNumber,
                EndorsmentNumber: EndorsmentNumber,
                Insured: clientName,
                TransactionType: doc.docData.transactionType,
                Premium: premium,
                PVTPremium: PVTPremium,
                SDuty: SDuty,
                TLevy: TLevy,
                PHCFund: PHCFund,
                Comm: Comm,
                WTax: WTax,
                PolicyNet: PolicyNet,
                CreditNet: CreditNet,
                Outstanding: Outstanding,
            });

        });

        addedTotals.map((doc) => {
            finalObj.push({
                docID: '',
                Date: '',
                UnformatedDate: '',
                PolicyNumber: 'Totals',
                EndorsmentNumber: '',
                Insured: '',
                TransactionType: '',
                Premium: doc.Premium,
                PVTPremium: doc.PVTPremium,
                SDuty: doc.SDuty,
                TLevy: doc.TLevy,
                PHCFund: doc.PHCFund,
                Comm: doc.Comm,
                WTax: doc.WTax,
                PolicyNet: doc.PolicyNet,
                CreditNet: doc.CreditNet,
                Outstanding: '',
            });
        })

        setFinalData(finalObj);

    }, [addedTotals])

    //################################################################
    // grid states
    const [columnsSelected, setColumnsSelected] = useState([]);
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');


    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.Date,
                doc.PolicyNumber,
                doc.EndorsmentNumber,
                doc.Insured,
                doc.TransactionType,
                doc.Premium,
                doc.PVTPremium,
                doc.SDuty,
                doc.TLevy,
                doc.PHCFund,
                doc.Comm,
                doc.WTax,
                doc.PolicyNet,
                doc.CreditNet,
                doc.Outstanding,
            ])
        })

        const onlyDispatchedheaderData = 'INSURER STATEMENT    ||    INSURER: ' + insurerStatementInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(insurerStatementFilterFromDateRN) + ' - ' + DateFormat(insurerStatementFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 25, 'auto', 'auto', 'auto', 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 16, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Date', 'POLICY NO', 'ENDORSMENT NO', 'INSURER', 'TRANSACTION TYPE', 'PREMIUM', 'PVT PREMIUM', 'S.DUTY', 'T.LEVY', 'PHC FUND', 'COMM', 'W.TAX', 'POLICY NET', 'CREDIT NET', 'C.OUTSTANDING'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // functions consuming grid state
    // function 1
    //export to excel
    const columnsToExport = ['Date', 'PolicyNumber', 'EndorsmentNumber', 'Insured', 'Premium', 'PVTPremium', 'SDuty', 'TLevy', 'PHCFund', 'Comm', 'WTax', 'PolicyNet', 'CreditNet', 'Outstanding']

    const exportToExcelFileName = 'INSURER STATEMENT    --    INSURER: ' + insurerStatementInsurerFilterRN + '    --    PERIOD: ' + DateFormat(insurerStatementFilterFromDateRN) + ' - ' + DateFormat(insurerStatementFilterToDateRN) + '.csv';


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.Date,
                doc.PolicyNumber,
                doc.EndorsmentNumber,
                doc.Insured,
                doc.TransactionType,
                doc.Premium,
                doc.PVTPremium,
                doc.SDuty,
                doc.TLevy,
                doc.PHCFund,
                doc.Comm,
                doc.WTax,
                doc.PolicyNet,
                doc.CreditNet,
                doc.Outstanding,
            ])
        })

        const onlyDispatchedheaderData = 'INSURER STATEMENT    ||    INSURER: ' + insurerStatementInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(insurerStatementFilterFromDateRN) + ' - ' + DateFormat(insurerStatementFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 25, 'auto', 'auto', 'auto', 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 16, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Date', 'POLICY NO', 'ENDORSMENT NO', 'INSURER', 'TRANSACTION TYPE', 'PREMIUM', 'PVT PREMIUM', 'S.DUTY', 'T.LEVY', 'PHC FUND', 'COMM', 'W.TAX', 'POLICY NET', 'CREDIT NET', 'C.OUTSTANDING'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    // function 2
    // move to the next month - show success msg - step 3
    const showSuccessMSGForDateUpdater = () => {
        const onePropObj = {
            setView: 'Insurer Statement Grid',
            previousView: 'Insurer Statement Grid',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy(s) Moved To The Next Month Successfully',
        }
        setIsLoading(false);
        props.onePropDown(onePropObj);
        setReload(Math.random(1000, 100000));
    }

    // function 2
    // move to the next month - show success msg - step 3
    const showSuccessMSGForPrevMonthDateUpdater = () => {
        const onePropObj = {
            setView: 'Insurer Statement Grid',
            previousView: 'Insurer Statement Grid',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Policy(s) Moved To The Previous Month Successfully',
        }
        setIsLoading(false);
        props.onePropDown(onePropObj);
        setReload(Math.random(1000, 100000));
    }

    //################################################################
    //move to the next month - step 2
    const newDateUpdater = async (receivedData) => {
        const docRef = doc(db, "PolicyDetails", receivedData.docID);

        await updateDoc(docRef, {
            insurerDebitDate: receivedData.newInsurerDebitDate
        });
    }

    //################################################################
    //move to the next month - step 1
    const moveSelectedRowsToTheNextMonth = () => {
        setIsLoading(true)
        selectedRows.map((doc) => {
            // create a new Date object with the current date
            const currentDate = new Date(doc.UnformatedDate);

            // add one month to the current date
            currentDate.setMonth(currentDate.getMonth() + 1);
            const newInsurerDebitDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()

            newDateUpdater({ docID: doc.docID, newInsurerDebitDate: newInsurerDebitDate })
        });

        showSuccessMSGForDateUpdater();
    }

    //################################################################
    //move to the pre month - step 1
    const moveSelectedRowsToThePreMonth = () => {
        try {

            setIsLoading(true)
            selectedRows.map((doc) => {
                // create a new Date object with the current date
                const currentDate = new Date(doc.UnformatedDate);

                // add one month to the current date
                currentDate.setMonth(currentDate.getMonth() - 1);
                const newInsurerDebitDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()

                newDateUpdater({ docID: doc.docID, newInsurerDebitDate: newInsurerDebitDate })
            });

            showSuccessMSGForPrevMonthDateUpdater();

        } catch (error) { }
    }

    //################################################################
    //view policy
    const viewPolicyHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Insurer Statement Final',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>




    //################################################################
    //grid events
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
    }

    const onCellClicked = (event) => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', size: 'landscape' }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            maxWidth: 90, valueGetter: hashValueGetter, checkboxSelection: true, headerCheckboxSelection: true, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'Date', headerName: 'Date', sortable: false, filter: false, width: 40, },
        { field: 'PolicyNumber', headerName: 'Policy No', pinned: 'left', width: 150, },
        { field: 'EndorsmentNumber', headerName: 'Endor No', filter: true, width: 90, },
        { field: 'Insured', headerName: 'Insured', filter: true, width: 110, },
        { field: 'TransactionType', headerName: 'Type', filter: true, },
        { field: 'Premium', headerName: 'Premium', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PVTPremium', headerName: 'PVTPremium', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'SDuty', headerName: 'SDuty', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'TLevy', headerName: 'TLevy', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PHCFund', headerName: 'PHCFund', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'Comm', headerName: 'Comm', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'WTax', headerName: 'WTax', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PolicyNet', headerName: 'Policy Net', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'CreditNet', headerName: 'Credit Net', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'Outstanding', headerName: 'Outstanding ', sortable: false, filter: false, wrapText: true, autoHeight: true, },


    ]);


    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);


    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onCellContextMenu={onCellContextMenu}
                onCellClicked={onCellClicked}
                preventDefaultOnContextMenu={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
            ></AgGridReact>
        </div>
    </section>








    return (
        <section className='insurer_statement_final body_input_styles'>
            <div className='body_section_header'>
                <p>Insurer Statement</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onBtnExport}>Export To Excel</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={moveSelectedRowsToTheNextMonth}>Move To Next Month</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={moveSelectedRowsToThePreMonth}>Move To Pre Month</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default InsurerStatementGrid;