import React from "react";
import DatePicker from "react-date-picker";
import { useRecoilState } from "recoil";
import { openClaimsFilterFromDate, openClaimsFilterToDate } from "../../../atoms";


const OpenClaimsDateFilter = () => {
    //#####################################################################
    //get recoil state
    const [openClaimsFilterFromDateRN,setOpenClaimsFilterFromDateRN] = useRecoilState(openClaimsFilterFromDate)
    const [openClaimsFilterToDateRN,setOpenClaimsFilterToDateRN] = useRecoilState(openClaimsFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div>
                    <div className='h_10'></div>
                    <p className='bold'>Filter Date</p>
                    <div className='h_5'></div>
                    <div className='lapse_date_picker_container'>
                        <p>From</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setOpenClaimsFilterFromDateRN} value={openClaimsFilterFromDateRN} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='lapse_date_picker_container'>
                        <p>To</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setOpenClaimsFilterToDateRN} value={openClaimsFilterToDateRN} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OpenClaimsDateFilter;