import React, { useState,useEffect } from "react";
import Loader from "../../Body/Loader/Loader";
import NumberFormat from "../../Hooks/UI/NumberFormat";

const CreateBankReconEntry = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //useable
    const [bankReconAccountCategories, setBankReconAccountCategories] = useState(['Debit', 'Credit']);
    const [amount, setAmount] = useState(0)
    
    //################################################################
    //get user input
    const [transactionType, setTransactionType] = useState('');

    const createBankReconEntryHandler = () => {}

    //################################################################
    //content 
    const createExpenseDebitAccountContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Create Entry</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setTransactionType(e.target.value) }} value={transactionType}>
                    <option>Select Transaction Type</option>
                    {
                        bankReconAccountCategories.map((doc, index) => {
                            return (
                                <option key={index}>{doc}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Transaction Type</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={NumberFormat(amount) || ''} onChange={(e) => { setAmount(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Amount</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createBankReconEntryHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {createExpenseDebitAccountContent}
            {loaderContent}
        </section>
    );
}

export default CreateBankReconEntry;