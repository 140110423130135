import React, { useEffect, useState } from "react";
import db from "../../../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import Loader from "../../../../Body/Loader/Loader";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectClient } from "../../../../../atoms";
import { rightNavPolicyItem } from "../../../../../atoms";
import GetAllClients from "../../../../Hooks/BackendConn/ClientList/GetAllClients";
import './SelectClient.css';

const ClientGroupSelectClient = (props) => {
    //################################################################
    //recoil state
    const [selectClientRN, setSelectClientRN] = useRecoilState(selectClient)
    const rightNavPolicyItemRN = useRecoilValue(rightNavPolicyItem)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all clients
    const [clientDetails, setClientDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClients();
            setClientDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //store selected client id
    const [selectedClientID, setSelectedClientID] = useState('')
//console.log(selectedClientID)
    //################################################################
    //reload on success group member add
    const reloadOnSuccessGroupMemberAdd = () => {
        //change view
        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client has been Successfully added to group',
        }
        props.onePropDown(onePropObj);
        setReload(Math.random(1001, 20000))
    }

    //################################################################
    //add client to group
    async function a() {
        
        const sfDocRef = doc(db, "ClientGroups", "PvnnCxssBmfIt6BL6i4P");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                
                const newPopulation = sfDoc.data()[rightNavPolicyItemRN];
               

                const newGroupMembers = Object.values(newPopulation.groupMembers);
                if (!newGroupMembers.includes(selectedClientID)) { newGroupMembers.push(selectedClientID) }
                console.log(newGroupMembers)
                const newObj = {
                    groupTitle: newPopulation.groupTitle,
                    groupMembers: { ...newGroupMembers },
                    contactPerson: newPopulation.contactPerson,
                    documents: newPopulation.documents,
                    createdBy: newPopulation.createdBy,
                    dateCreated: newPopulation.dateCreated,
                }
//console.log(newGroupMembers)
               transaction.update(sfDocRef, { [rightNavPolicyItemRN]: newObj });
                
            });
            console.log("Transaction successfully committed!");

        } catch (e) {
            console.log("Transaction failed: ", e);
        }
        

        
        
    }

    //################################################################
    //set selected client and make call to update db
    const selectedClientChangeHandler = (selectedClient) => {
        setSelectedClientID(selectedClient);
        a();
    }

    //################################################################
    // search insurer
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedInsurer, setSearchedInsurer] = useState([])

    useEffect(() => {
        const insurer = clientDetails.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.insurer.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        });
        setSearchedInsurer(insurer);
    }, [clientDetails, searchTerm]);


    //################################################################
    // select insurer Content
    const selectInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Select Client</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search Client</p></label>
            </div>
            <div className='h_10'></div>
            <div>
                <ul className='select_insurer_list'>
                    {
                        searchedInsurer.map((doc, index) => {
                            return (
                                <li key={index} onClick={() => { selectedClientChangeHandler(doc.docID) }}>
                                    <p onClick={() => { selectedClientChangeHandler(doc.docID) }}>{doc.docData.clientName}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </section>

    return (
        <section className='select_insurer right_nav_container body_input_styles'>
            {selectInsurerContent}
            {loaderContent}
        </section>
    );
}

export default ClientGroupSelectClient;