import React, {useState} from 'react';
import './TabsFeature.css';

const TabsFeature = () => {   

const [rb,setRB] = useState('')
console.log(rb)

    const a = <section>
        <input onChange={(e)=>{setRB(e.target.value)}} type="radio" id="featured-radio" className="radio-button" name="content-filter" value='featured'/>
        <input onChange={(e)=>{setRB(e.target.value)}} type="radio" id="personal-radio" className="radio-button" name="content-filter" value="Personal" />
        <input onChange={(e)=>{setRB(e.target.value)}} type="radio" id="tech-radio" className="radio-button" name="content-filter" value="Tech" />

        <header id="filter">
            <label htmlFor="featured-radio" className={`filter-label featured ${rb === 'featured' && 'f'}`} id="feature-label">Featured</label>
            <label htmlFor="personal-radio" className="filter-label personal" id="personal-label">Personal</label>
            <label htmlFor="tech-radio" className="filter-label tech" id="tech-label">Tech</label>
        </header>

        <main>
            <article className={`content featured tech ${rb === 'featured' && 'block'}`}>
                <header>
                    <h1>Cool Stuff</h1>
                    <h3 className="date">Today</h3>
                </header>

                <p>
                    I'm showing cool stuff in this article!
                </p>
            </article>

            <article className="content personal">
                <header>
                    <h1>Not As Cool</h1>
                    <h3 className="date">Tuesday</h3>
                </header>

                <p>
                    This stuff isn't nearly as cool for some reason :(;
                </p>
            </article>

            <article className="content tech">
                <header>
                    <h1>Cool Tech Article</h1>
                    <h3 className="date">Last Monday</h3>
                </header>

                <p>
                    This article has awesome stuff all over it!
                </p>
            </article>

            <article className="content featured personal">
                <header>
                    <h1>Cool Personal Article</h1>
                    <h3 className="date">Two Fridays Ago</h3>
                </header>

                <p>
                    This article talks about how I got a job at a cool startup because I rock!
                </p>
            </article>
        </main>
    </section>

    return (
        <section className='tabss'>
            {a}
        </section>
    );
}

export default TabsFeature;