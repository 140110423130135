import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { agentStatementGridFilterFromDate, agentStatementGridFilterToDate, agentStatementGridInsurerName } from '../../../atoms';
import { activeInsurerPortfolio } from "../../../atoms";
import { useRecoilValue } from 'recoil';
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import Loader from "../Loader/Loader";
import GetAllReceiptsInsurerID from '../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurerID';
import Worker from './AgentStatementGrid.Worker.js';
import GetAllDocsTransactionInsurerPlusPending from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionInsurerPlusPending";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import DateFormat from "../../Hooks/UI/DateFormat";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const AgentStatementGrid = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTwo, setIsLoadingTwo] = useState(false);
    const [isLoadingThree, setIsLoadingThree] = useState(false);
    const [isLoadingFour, setIsLoadingFour] = useState(false);
    let loaderContent = '';
    if (isLoading === true || isLoadingTwo === true || isLoadingThree === true || isLoadingFour === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil state
    const agentStatementGridFilterFromDateRN = useRecoilValue(agentStatementGridFilterFromDate);
    const agentStatementGridFilterToDateRN = useRecoilValue(agentStatementGridFilterToDate);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const agentStatementGridInsurerNameRN = useRecoilValue(agentStatementGridInsurerName);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionInsurerPlusPending({ insurerName: agentStatementGridInsurerNameRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, agentStatementGridInsurerNameRN]);

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoadingTwo(true)
            const DDocs = await GetAllDocsTransactionInsurerPlusPending({ insurerName: agentStatementGridInsurerNameRN, transactionType: 'Credit' });
            setCreditDocs(DDocs);
            if (DDocs) {
                setIsLoadingTwo(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo, agentStatementGridInsurerNameRN]);

    //################################################################
    //get all debits
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoadingThree(true)
            const DDocs = await GetAllReceiptsInsurerID({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoadingThree(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree, activeInsurerPortfolioRN]);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            //setIsLoadingFour(true);
            var worker = new Worker();

            const data = {
                agentStatementGridFilterFromDateRN: agentStatementGridFilterFromDateRN,
                agentStatementGridFilterToDateRN: agentStatementGridFilterToDateRN,
                debitDocs: debitDocs,
                creditDocs: creditDocs,
                receiptDocs: receiptDocs,
            }

            worker.postMessage(data);

            worker.onmessage = function (event) {
                //setIsLoadingFour(false);
                setFinalData(event.data.finalData);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [agentStatementGridFilterFromDateRN, agentStatementGridFilterToDateRN, debitDocs, creditDocs, receiptDocs]);
    
    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    // function 1
    //export to excel
    const columnsToExport = ['Date', 'PolicyNumber', 'EndorsmentNumber', 'Insured', 'Premium', 'PVTPremium', 'SDuty', 'TLevy', 'PHCFund', 'Comm', 'WTax', 'PolicyNet', 'CreditNet', 'Outstanding']

    const exportToExcelFileName = 'AGENT STATEMENT    --    INSURER: ' + agentStatementGridInsurerNameRN + '    --    PERIOD: ' + DateFormat(agentStatementGridFilterFromDateRN) + ' - ' + DateFormat(agentStatementGridFilterToDateRN) + '     --     PRINTED ON: ' + DateFormat(new Date()) + '.csv';


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1 || '',
                doc.Date || '',
                doc.PolicyNumber || '',
                doc.EndorsmentNumber || '',
                doc.Insured || '',
                doc.TransactionType || '',
                doc.Premium || '',
                doc.PVTPremium || '',
                doc.SDuty || '',
                doc.TLevy || '',
                doc.PHCFund || '',
                doc.Comm || '',
                doc.WTax || '',
                doc.PolicyNet || '',
                doc.CreditNet || '',
                doc.Outstanding || '',
            ])
        })

        const onlyDispatchedheaderData = 'AGENT STATEMENT    --    INSURER: ' + agentStatementGridInsurerNameRN + '    --    PERIOD: ' + DateFormat(agentStatementGridFilterFromDateRN) + ' - ' + DateFormat(agentStatementGridFilterToDateRN) + '     --     PRINTED ON: ' + DateFormat(new Date());

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 25, 'auto', 'auto', 'auto', 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 16, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Date', 'POLICY NO', 'ENDORSMENT NO', 'INSURER', 'TRANSACTION TYPE', 'PREMIUM', 'PVT PREMIUM', 'S.DUTY', 'T.LEVY', 'PHC FUND', 'COMM', 'W.TAX', 'POLICY NET', 'CREDIT NET', 'C.OUTSTANDING'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.Date || '',
                doc.PolicyNumber || '',
                doc.EndorsmentNumber || '',
                doc.Insured || '',
                doc.TransactionType || '',
                doc.Premium || '',
                doc.PVTPremium || '',
                doc.SDuty || '',
                doc.TLevy || '',
                doc.PHCFund || '',
                doc.Comm || '',
                doc.WTax || '',
                doc.PolicyNet || '',
                doc.CreditNet || '',
                doc.Outstanding || '',
            ])
        })

        const onlyDispatchedheaderData = 'AGENT STATEMENT    --    INSURER: ' + agentStatementGridInsurerNameRN + '    --    PERIOD: ' + DateFormat(agentStatementGridFilterFromDateRN) + ' - ' + DateFormat(agentStatementGridFilterToDateRN) + '     --     PRINTED ON: ' + DateFormat(new Date());

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 25, 'auto', 'auto', 'auto', 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 16, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},],
                            ['NO', 'Date', 'POLICY NO', 'ENDORSMENT NO', 'INSURER', 'TRANSACTION TYPE', 'PREMIUM', 'PVT PREMIUM', 'S.DUTY', 'T.LEVY', 'PHC FUND', 'COMM', 'W.TAX', 'POLICY NET', 'CREDIT NET', 'C.OUTSTANDING'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'Date', headerName: 'Date', sortable: false, filter: false, width: 40, },
        { field: 'PolicyNumber', headerName: 'Policy No', pinned: 'left', width: 150, },
        { field: 'EndorsmentNumber', headerName: 'Endor No', filter: true, width: 90, },
        { field: 'Insured', headerName: 'Insured', filter: true, width: 110, },
        { field: 'TransactionType', headerName: 'Type', filter: true, },
        { field: 'Premium', headerName: 'Premium', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PVTPremium', headerName: 'PVTPremium', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'SDuty', headerName: 'SDuty', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'TLevy', headerName: 'TLevy', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PHCFund', headerName: 'PHCFund', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'Comm', headerName: 'Comm', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'WTax', headerName: 'WTax', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'PolicyNet', headerName: 'Policy Net', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'CreditNet', headerName: 'Credit Net', sortable: false, filter: true, wrapText: true, autoHeight: true, },
        { field: 'Outstanding', headerName: 'Outstanding ', sortable: false, filter: false, wrapText: true, autoHeight: true, },
        {
            field: 'documents', headerName: 'Doc', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Agent Statement</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onBtnExport}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default AgentStatementGrid;