import React, { useCallback} from "react";
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from "recoil";
import { biometricsDataFile } from "../../../atoms";

const BiometricsDataImport = () => {
    //#####################################################################
    //get user input
    const [biometricsDataFileRN, setBiometricsDataFileRN] = useRecoilState(biometricsDataFile);

    //################################################################
    //valuation file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setBiometricsDataFileRN(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //################################################################
    //content    
    const LogbookUploadContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Upload File</p>
            <div className='h_10'></div>
            <div className='file_upload_container'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        biometricsDataFileRN.length > 0
                            ? <p>{biometricsDataFileRN[0].name}</p>
                            : isDragActive
                                ? <p>Drop the files here ...</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {LogbookUploadContent}
        </section>
    );
}

export default BiometricsDataImport;