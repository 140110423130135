import React, { useState, useEffect, useRef } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import './BodyReceiptPortfolio.css';

const BodyPolicyPortfolio = (props) => {
    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);
    const [noOfRecords, setNoOfRecords] = useState(0)

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "PolicyDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    // assign date
                    if (doc.data().transactionType === 'Receipt') {
                        const noOfRecordsPulled = querySnapshot.size.toString();
                        setNoOfRecords(noOfRecordsPulled);
                        let receiptClientName = '';
                        receiptClientName = doc.data().clientName[0];
                        const allPolicyDocs = {
                            policyID: doc.id,
                            clientName: doc.data(),
                            receiptClientName: receiptClientName,
                            receiptDate: doc.data().receiptDate,
                            receiptNumber: doc.data().receiptNumber,
                            receiptAmount: doc.data().receiptAmount,
                            paidBy: doc.data().paidBy,
                            paidTo: doc.data().paidTo,
                            receiptAllocationDetails: doc.data().clientAllocationList
                        }
                        Rdata.push(allPolicyDocs);
                        setIsloading(false);
                    }
                },
                    setReceivedPolicyDocs(Rdata),
                    
                );
            }
        }
        
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    // sort by date
    const [sortedPolicyDocs, setSortedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = receivedPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(b.receiptDate).valueOf() - new Date(a.receiptDate).valueOf();
        }
        setSortedPolicyDocs(sortedList);
    }, [receivedPolicyDocs]);

    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const values = sortedPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.receiptClientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.receiptNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.paidTo.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }) 
        setSearchedPolicyDocs(values);
    }, [sortedPolicyDocs, searchTerm])

    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(30);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost)

    // change view on success post                                                                                                                                                                                     
    const viewCreatedPolicyOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'View Policy Two', postedDocRef: postedDocRef });
    } 

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }
                                                                                                                                                                                                                          
    const allPolicyContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header receipt_portfolio_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Receipt Number / Paid To' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='all_receipts_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Receipt Date</p></td>
                                <td><p>Receipt Number</p></td>
                                <td><p>Client Name</p></td>
                                <td><p>Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{index + 1}</p></td>
                                        <td><p>{item.receiptDate}</p></td>
                                        <td><p>{item.receiptNumber}</p></td>
                                        <td><p>{item.receiptClientName}</p></td>
                                        <td><p>{item.receiptAmount}</p></td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='receipts_portfolio'>
            <div className='body_section_header'>
                <p>Receipt List</p>
            </div>
            <div className='body_section'>
                {allPolicyContent}
            </div>
            {loaderContent}
        </section>
    )
}

export default BodyPolicyPortfolio;