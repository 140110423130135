import React, { useState, useRef, useEffect } from 'react';
import db from '../../../firebase';
import { doc, runTransaction } from 'firebase/firestore';
import { collection, addDoc } from "firebase/firestore";
import Loader from '../Loader/Loader';
import '../../Body/BodySectionModule.css';
import './BodyCreateClient.css';
import { useRecoilValue } from 'recoil';
import { activeInsurerPortfolio } from '../../../atoms';
import UUID from '../../Hooks/UUID/UUID';

const BodyCreateClient = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //get user input
    const clientNameRef = useRef(null);
    const IDNumberRef = useRef(null);
    const passportNumberRef = useRef(null);
    const KRAPinRef = useRef(null);
    const occupationRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null)
    const addressRef = useRef(null);
    const contactPersonRef = useRef(null);

    //################################################################
    //create client 
    //step 2
    const changeViewOnSuccessClientCreation = (clientID) => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Edit Client Final',
            previousView: '',
            postedDocRef: clientID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client Created Successfully'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    const createClient = async (event) => {
        event.preventDefault();

        if (!clientNameRef.current.value) { return false; }

        setIsLoading(true);

        const createClientObj = {
            docID: '',
            IDNumber: IDNumberRef.current.value.trim(),
            KRAPin: KRAPinRef.current.value.trim(),
            address: addressRef.current.value.trim(),
            clientName: clientNameRef.current.value.trim().toUpperCase(),
            contactPerson: contactPersonRef.current.value.trim(),
            email: emailRef.current.value.trim(),
            group: '',
            occupation: occupationRef.current.value.trim(),
            passportNumber: passportNumberRef.current.value.trim(),
            phoneNumber: phoneNumberRef.current.value.trim(),
            insurerID: activeInsurerPortfolioRN,
            documents: {},
        }

        const sfDocRef = doc(db, "ClientData", "JbrVkeL56d5lrA8R2gdF");

        try {
            //get unique id
            const clientID = UUID() + ' - ' + UUID();

            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                transaction.update(sfDocRef, { [clientID]: createClientObj });
            });

            changeViewOnSuccessClientCreation(clientID);

        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }




    return (
        <section>
            <form onSubmit={createClient}>
                <div className='body_section_header'>
                    <p>Create Client</p>
                    <button type='submit' className='btn_pink'>Save Details</button>
                </div>
                <div className='body_section'>
                    <div className='h_5'></div>
                    <div className='category'>
                        <div className='category_header'><p>Client Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input ref={clientNameRef} type='text' placeholder='Client Name' />
                                <div className='h_10'></div>
                                <input ref={IDNumberRef} type='text' placeholder='ID Number' />
                                <div className='h_10'></div>
                                <input ref={passportNumberRef} type='text' placeholder='Passport Number' />
                            </div>
                            <div className='category_content_two'>
                                <input ref={KRAPinRef} type='text' placeholder='KRA Pin' />
                                <div className='h_10'></div>
                                <input ref={occupationRef} type='text' placeholder='Occupation / Employment Details' />
                            </div>
                        </div>
                    </div>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Contact Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input ref={phoneNumberRef} type='text' placeholder='Phone Number' />
                                <div className='h_10'></div>
                                <input ref={emailRef} type='text' placeholder='Email' />
                                <div className='h_10'></div>
                            </div>
                            <div className='category_content_two'>
                                <input ref={addressRef} type='text' placeholder='Address' />
                            </div>
                        </div>
                    </div>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Contact Person</p></div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <textarea ref={contactPersonRef} type='text' placeholder='Contact Person' />
                                <div className='h_10'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loaderContent}
        </section>
    );
}

export default BodyCreateClient;