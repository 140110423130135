import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetAllPolicies = async (props) => {

    const DData = [];

    //query 1
    const q = query(collection(db, "PolicyDetails"), where("insurerName", "==", props.insurer));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        DData.push({ docData: doc.data(), docID: doc.id })
    });

    //query 2
    const q2 = query(collection(db, "PolicyDetails"), where("paidTo", "==", props.insurer));

    const querySnapshot2 = await getDocs(q2);

    querySnapshot2.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        DData.push({ docData: doc.data(), docID: doc.id })
    });

    return DData;

}

export default GetAllPolicies;