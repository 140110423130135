import "remixicon/fonts/remixicon.css";
import "./App.css";
import "./universal.css";
import AuthController from "./Components/Authentication/AuthController";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase'
import Dashboard from "./Components/Dashboard/Dashboard";
import SignIn from "./Components/Authentication/SignIn";
import SignUp from "./Components/Authentication/SignUp";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

function App() {
 
  return (
    <RecoilRoot><Dashboard/></RecoilRoot>
  );
}

export default App;


// function App() {
//   return (
//     <AuthContextProvider>
//       <Routes>
//         <Route exact path="/home" element={<Dashboard />} />
//         <Route exact path="/signup" element={<Signup />} />
//         <Route exact path="/" element={<Signin />} />
//       </Routes>
//     </AuthContextProvider>
//   );
// }

// export default App;


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
