import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import db from "../../../firebase";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import GetAllEmployeeDetails from "../../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const EmployeeNotification = () => {
  //current user
  const currentUser = JSON.parse(GetCookie("CurrentUserSession"));

  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }


  // Get Employees file from firebase
  const [employeeDocs, setEmployeeDocs] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAllEmployeeDetails();
      setEmployeeDocs(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReload(Math.random(0, 10000));
      }
    })();
  }, [reload]);


  //################################################################
  //active employee
  const [notificationType, setNotificationType] = useState("Leave Request");
  const [employeeUserName, setEmployeeUserName] = useState({})
  
  useEffect(() => {
    try {
      // const employee = employeeDocs.docData.forEach(element => {
      //   console.log(element.name)
      // });

      const employee = employeeDocs.filter(
        (doc) => doc.docData.pendingLeaveRequest.length >= 1
      );

      if(employee !== null) {
        setNotificationType("Leave Request")
        const userName = employee[0].docData.name;
        setEmployeeUserName(employee[0].docData.name);


        console.log(employee[0].docData.name)

        
        console.log(employee[0].docData.pendingLeaveRequestlength);

        console.log(employee[0].docData.pendingLeaveRequest[0].toDate());

        employee[0].docData.pendingLeaveRequest.forEach(element => {
          console.log("Stuff" + element.toDate())
        });



      }
    } catch (error) {}
  }, [employeeDocs]);

  // Grid
  const [rowData] = useState([
    { NotificationType: notificationType, Name: employeeUserName, Dates: 35000 },
    // { make: "Ford", model: "Mondeo", price: 32000 },
    // { make: "Porsche", model: "Boxster", price: 72000 },
  ]);

  const [columnDefs] = useState([
    { field: "NotificationType" },
    { field: "Name" },
    { field: "Dates" },
  ]);

  return (
    // <section>{content}</section>

    <section>
      <div className="body_section_header">
        <p>Notification</p>
      </div>
      <div>
        <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
          <AgGridReact rowData={rowData} columnDefs={columnDefs}></AgGridReact>
        </div>
      </div>
      {/* <div className="body_section">{content}</div> */}
      {loaderContent}
    </section>
  );
}

export default EmployeeNotification