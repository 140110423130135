import React, { useState, useEffect, useCallback } from "react";
import db, { storage } from "../../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useRecoilState, useRecoilValue } from "recoil";
import { rightNavPolicyItem, pageReloaderHandler } from "../../../../atoms";
import { useDropzone } from "react-dropzone";
import UUID from "../../../Hooks/UUID/UUID";
import Loader from "../../../Body/Loader/Loader";
import GetCookie from "../../../Hooks/Cookie/GetCookie";
import { async } from "@firebase/util";

const ReceiptUpload = (props) => {
    //################################################################
    //recoil state
    const rightNavPolicyItemRN = useRecoilValue(rightNavPolicyItem)
    const [pageReloaderHandlerRN,setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler)

    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    const [receiptDocument, setReceiptDocument] = useState('')
    const [receiptDocUploadProgress, setReceiptDocUploadProgress] = useState(0);

    //################################################################
    //claim file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setReceiptDocument(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //#########################################################################
    //refresh 
    //step 3
    const refreshOnSuccessfulReceiptDocUpload = () => {
        setReceiptDocument('')
        setReceiptDocUploadProgress(0)
        //open view
        const onePropObj = {
            setView: 'View Receipt',
            previousView: 'View Receipt',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Receipt Document Uploaded Successfully',
            activity: 'Receipt Document Upload',
        }
        props.onePropDown(onePropObj)
        setPageReloaderHandlerRN(onePropObj)
    }


    //#########################################################################
    //update record
    //step 2
    async function updateReceiptRecord(downloadURL){
        try {
            //make obj
            const newObj = {
                receiptDocumentDownloadLink: downloadURL,
                uploadedBy: currentUser,
                uploadDate: new Date()
            }
            const objKey = 'receiptDocument.' + UUID()
            const receiptDocRef = doc(db, "PolicyDetails", rightNavPolicyItemRN.docID);
            const a = await updateDoc(receiptDocRef, {
                [objKey]: newObj
            });

            refreshOnSuccessfulReceiptDocUpload(a)

        } catch (error) {
            console.log(error)
        }
    }

    //#########################################################################
    //upload doc
    //step 1
    const receiptUploadHandler = (e) => {
        e.preventDefault();

        const file = receiptDocument[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRN.docData.clientName[0],
                'receiptNumber': rightNavPolicyItemRN.docData.receiptNumber,
                'policyDocID': rightNavPolicyItemRN.docID,
                'documentID': UUID(),
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'receipt documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setReceiptDocUploadProgress(Math.round(progress))
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
                if (progress < 100) { setIsLoading(true); }
                else { setIsLoading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateReceiptRecord(downloadURL)
                });
            }
        );
    }

    //#########################################################################
    //content
    const receiptUploadContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p>Receipt Document Upload</p>
            <div className='h_10'></div>
            {
                receiptDocUploadProgress > 0
                    ? <div><p className='f_7'>Upload Progress:  {receiptDocUploadProgress}% Done</p><div className='h_15'></div></div>
                    : ''
            }            
            <div className='file_upload_container'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        receiptDocument
                            ? <p>{receiptDocument[0].name}</p>
                            : isDragActive
                                ? <p>Drop the files here ...</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </div>
            <div className='h_10'></div>
            <button onClick={receiptUploadHandler} className='btn_pink'>Upload</button>
        </div>
    </section>

    return (
        <section className='right_nav_container body_input_styles'>
            {receiptUploadContent}
            {loaderContent}
        </section>
    )
}

export default ReceiptUpload;