import db from '../../../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const GetAllClients = async () => {
    
    const querySnapshot = await getDocs(collection(db, "ClientDetails"));
        
        const DData = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            DData.push({ docData: doc.data(), docID: doc.id })
        });

        return DData;
        
}

export default GetAllClients;