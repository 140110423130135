import React, { useState, useCallback, useEffect } from 'react';
import db from '../../../firebase';
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import logo from '../../Assets/img/logo.png';
import Loader from '../Loader/Loader';
import './BodyRiskNote.css';

const BodyRiskNote = (props) => {
    const [docRefNo, setPostedDocRef] = useState(props.docRefNo);

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    const [clientName, setClientName] = useState('');
    const [insurerName, setInsurerName] = useState('');
    const [bankInterest, setBankInterest] = useState('')
    const [policyNumber, setPolicyNumber] = useState('');
    const [selectedProductClass, setSelectedProductClass] = useState('');
    const [selectedProductSubClass, setSelectedProductSubClass] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [inceptionDate, setInceptionDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [locationOfTheRisk, setLocationOfTheRisk] = useState('');
    const [totalSumInsured, setTotalSumInsured] = useState('');
    const [premium, setPremium] = useState(0);
    const [PVTPremium, setPVTPremium] = useState(0);
    const [stampDuty, setStampDuty] = useState(0);
    const [TLevy, setTLevy] = useState(0);
    const [PHCFund, setPHCFund] = useState(0);
    const [earthquake, setEarthquake] = useState(0);
    const [excessProtector, setExcessProtector] = useState(0);
    const [comesaCard, setComesaCard] = useState(0);
    const [windScreen, setWindScreen] = useState(0);
    const [radioCasset, setRadioCasset] = useState(0);
    const [policyNet, setPolicyNet] = useState(0);
    const [transactionType, setTransactionType] = useState(0);

    const [employeesDetailsItems, setEmployeesDetailsItems] = useState([{ positionOfEmployee: '', noOfEmployees: '', estimatedAnnualSalary: '' },]);
    const [propertyDescriptionItems, setPropertyDescriptionItems] = useState([{ propertyDescription: '', sumInsured: '' },]);
    const [benefitsItems, setBenefitsItems] = useState([{ title: '', elaboration: '' },]);
    const [deductibleItems, setDeductibleItems] = useState([{ deductibleItem: '' }]);
    const [vehicleItems, setVehicleItems] = useState([{ regNo: '', make: '', bodyType: '', horsePower: '', yearOfManufacture: '', carryingCapacity: '', estimatedValue: '', windScreenLimit: '', radioCassetLimit: '' },]);
    const [clauseItems, setClauseItems] = useState([{ clauseItem: '' }]);
    const [additionalInstructionsItems, setadditionalInstructionsItems] = useState([{ additionalInstructionItem: '' }]);


    //fetch data
    const fetchPolicyDetails = useCallback(async () => {
        setIsloading(true);
        const docRef = doc(db, "PolicyDetails", docRefNo);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //format data
            //INCEPTION
            let IDate = new Date(docSnap.data().inceptionDate);
            const IDay = IDate.toLocaleString('en-us', { day: '2-digit' });
            const IMonth = IDate.toLocaleString('en-us', { month: 'long' });
            const IYear = IDate.getFullYear();
            IDate = IDay + ' ' + IMonth + ' ' + IYear;
            //EXPIRY
            let EDate = new Date(docSnap.data().expiryDate);
            const EDay = EDate.toLocaleString('en-us', { day: '2-digit' });
            const EMonth = EDate.toLocaleString('en-us', { month: 'long' });
            const EYear = EDate.getFullYear();
            EDate = EDay + ' ' + EMonth + ' ' + EYear;
            //console.log("Document data:", docSnap.data());
            setClientName(docSnap.data().clientName);
            setInsurerName(docSnap.data().insurerName);
            setBankInterest(docSnap.data().bankInterest)
            setPolicyNumber(docSnap.data().policyNumber);
            setSelectedProductClass(docSnap.data().selectedProductClass);
            setSelectedProductSubClass(docSnap.data().selectedProductSubClass);
            setBusinessType(docSnap.data().businessType);
            setInceptionDate(IDate);
            setExpiryDate(EDate);
            setLocationOfTheRisk(docSnap.data().locationOfTheRisk);
            setTotalSumInsured(docSnap.data().totalSumInsured);
            setPremium(docSnap.data().premium);
            setPVTPremium(docSnap.data().PVTPremium);
            setStampDuty(docSnap.data().stampDuty);
            setTLevy(docSnap.data().TLevy);
            setPHCFund(docSnap.data().PHCFund);
            setEarthquake(docSnap.data().earthquake);
            setExcessProtector(docSnap.data().excessProtector);
            setComesaCard(docSnap.data().comesaCard);
            setWindScreen(docSnap.data().windScreen);
            setRadioCasset(docSnap.data().radioCasset);
            setPolicyNet(docSnap.data().policyNet);
            setTransactionType(docSnap.data().transactionType)

            const mappedEmployeesDetails = Object.values(docSnap.data().employeesDetailsItems);
            setEmployeesDetailsItems(mappedEmployeesDetails)
            const mappedPropertyDescriptionItems = Object.values(docSnap.data().propertyDescriptionItems);
            setPropertyDescriptionItems(mappedPropertyDescriptionItems)
            const mappedBenefitsItems = Object.values(docSnap.data().benefitsItems);
            setBenefitsItems(mappedBenefitsItems)
            const mappedDeductibleItem = Object.values(docSnap.data().deductibleItems);
            setDeductibleItems(mappedDeductibleItem)
            const mappedVehicleItems = Object.values(docSnap.data().vehicleItems);
            setVehicleItems(mappedVehicleItems)
            const mappedClauseItems = Object.values(docSnap.data().clauseItems);
            setClauseItems(mappedClauseItems)
            if (!docSnap.data().additionalInstructionsItems) {
                setadditionalInstructionsItems([{ additionalInstructionItem: '' }])
            }
            else {
                const mappedAdditionalInstructionsItems = Object.values(docSnap.data().additionalInstructionsItems);
                setadditionalInstructionsItems(mappedAdditionalInstructionsItems)
            }
            setIsloading(false);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }, [])

    useEffect(() => {
        fetchPolicyDetails();
    }, []);

    // get client data
    const [receivedClientDetails, setReceivedClientDetails] = useState([]);
    const setRetrievedClientData = (receivedData) => {
        setReceivedClientDetails((prevState) => {
            return [receivedData, ...prevState]
        });
    }

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsloading(true);
            const querySnapshot = await getDocs(collection(db, "ClientDetails"));
            if (!isCancelled) {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    const allClientDetails = {
                        receivedDocID: doc.id,
                        receivedClientName: doc.data().clientName,
                        clientName: doc.data().clientName,
                        IDNumber: doc.data().IDNumber,
                        passportNumber: doc.data().passportNumber,
                        KRAPin: doc.data().KRAPin,
                        occupation: doc.data().occupation,
                        phoneNumber: doc.data().phoneNumber,
                        email: doc.data().email,
                        address: doc.data().address
                    }
                    setRetrievedClientData(allClientDetails);
                    setIsloading(false);
                });
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, [])

    //search client
    const [clientDetails, setClientDetails] = useState('')
    useEffect(() => {
        receivedClientDetails.map((item) => {
            if (item.clientName === clientName) {
                setClientDetails(item)
            }
        })
    }, [receivedClientDetails])

    // particulars of the vehicle
    let particularsOfTheVehicleRNContent = ''
    if (selectedProductClass === '(07) - Motor Private'
        || selectedProductClass === '(07) - Motor Private - Comprehensive'
        || selectedProductClass === '(07) - Motor Private - TPO'
        || selectedProductClass === '(08) - Motor Commercial'
        || selectedProductClass === '(08) - Motor Commercial - Comprehensive'
        || selectedProductClass === '(08) - Motor Commercial - TPO') {
        particularsOfTheVehicleRNContent = <tr>
            <td><p>Particulars Of The Vehicle</p></td>
            <td>
                <table className='sub_table particularsOfTheVehicle'>
                    <thead>
                        <tr>
                            <td><p>Plate No</p></td>
                            <td><p>Estimated Value</p></td>
                            <td><p>Wind Screen Limit</p></td>
                            <td><p>Radio Casset Limit</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><p>{item.regNo}</p></td>
                                    <td><p>{item.estimatedValue}</p></td>
                                    <td><p>{item.windScreenLimit}</p></td>
                                    <td><p>{item.radioCassetLimit}</p></td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </td>
        </tr>
    }

    // particulars of the risk
    let particularsOfTheRiskRNContent = ''
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        particularsOfTheRiskRNContent = <tr>
            <td><p>Particulars Of The Risk</p></td>
            <td>
                <table className='sub_table particularsOfTheRisk'>
                    <thead>
                        <tr>
                            <td><p className='bold'>Location Of The Risk</p></td>
                            <td><p className='bold'>Total Sum Insured</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p>{locationOfTheRisk}</p></td>
                            <td><p>{totalSumInsured}</p></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    //employee details
    let employeeDetailsRNContent = '';
    if (employeesDetailsItems.length > 0) {
        if (selectedProductClass === '(11) - Workmens Compensation' && employeesDetailsItems[0].positionOfEmployee !== '') {
            employeeDetailsRNContent = <tr>
                <td><p>Employee Details</p></td>
                <td>
                    <table className='sub_table employeeDetails'>
                        <thead>
                            <tr>
                                <td><p>Position Of Employee</p></td>
                                <td><p>No Of Employees</p></td>
                                <td><p>Estimated Annual Salary</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {employeesDetailsItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{item.positionOfEmployee}</p></td>
                                        <td><p>{item.noOfEmployees}</p></td>
                                        <td><p>{item.estimatedAnnualSalary}</p></td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </td>
            </tr>
        }
    }


    //description
    let descriptionRNContent = ''
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        descriptionRNContent = <tr>
            <td><p>Description / Liability / Circumstances / Limits</p></td>
            <td>
                <table className='sub_table description bold'>
                    <thead>
                        <tr>
                            <td><p className='bold'>Description</p></td>
                            <td><p className='bold'>Sum Insured</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        {propertyDescriptionItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><p>{item.propertyDescription}</p></td>
                                    <td><p>{item.sumInsured}</p></td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </td>
        </tr>
    }


    // benefits
    let benefitsRNContent = ''
    if (selectedProductSubClass === '(112) - Wiba' || selectedProductSubClass === '(114) - Wiba Plus' && benefitsItems[0].title !== '') {
        benefitsRNContent = <tr>
            <td><p>Benefits</p></td>
            <td>
                <table className='sub_table deductibles'>
                    <thead>
                        <tr>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {benefitsItems.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <p>{index + 1}. {item.title}</p>
                                            <p>{item.elaboration}</p>
                                        </div>
                                    )
                                })
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    // deductibles
    let deductiblesRNContent = '';
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        deductiblesRNContent = <tr>
            <td><p>Deductibles</p></td>
            <td>
                <table className='sub_table deductibles'>
                    <thead>
                        <tr>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='risk_note_p_20'>
                                <ol>
                                    {deductibleItems.map((item, index) => {
                                        return (
                                            <li key={index}><p>{item.deductibleItem}</p></li>
                                        )
                                    })
                                    }
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    //clauses
    let clausesRNContent = ''
    if (clauseItems[0] !== '') {
        clausesRNContent = <tr>
            <td><p>Clauses</p></td>
            <td>
                <table className='sub_table clauses'>
                    <thead>
                        <tr>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='risk_note_p_20'>
                                <ol>
                                    {clauseItems.map((item, index) => {
                                        return (
                                            <li key={index}><p>{item.clauseItem}</p></li>
                                        )
                                    })}
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    //additional instructions
    let additionalInstructionsContent = ''
    if (additionalInstructionsItems[0] !== '') {
        additionalInstructionsContent = <tr>
            <td><p>Additional Instructions</p></td>
            <td>
                <table className='sub_table clauses'>
                    <thead>
                        <tr>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='risk_note_p_20'>
                                <ol>
                                    {additionalInstructionsItems.map((item, index) => {
                                        return (
                                            <li key={index}><p className='bold'>{item.additionalInstructionItem}</p></li>
                                        )
                                    })}
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    let premiumRNContent = ''
    if (selectedProductClass !== '(07) - Motor Private'
        && selectedProductClass !== '(07) - Motor Private - Comprehensive'
        && selectedProductClass !== '(07) - Motor Private - TPO'
        && selectedProductClass !== '(08) - Motor Commercial'
        && selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
        && selectedProductClass !== '(08) - Motor Commercial - TPO') {
        premiumRNContent = <tr>
            <td><p>Premium</p></td>
            <td>
                <table className='sub_table premium'>
                    <thead>
                        <tr><td></td><td></td><td></td><td></td></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan='11' colSpan='2'></td>
                            <td><p>Premium</p></td>
                            <td><p>{parseInt(premium).toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>PVT</p></td>
                            <td><p>{PVTPremium.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Stamp Duty</p></td>
                            <td><p>{stampDuty.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Earthquake</p></td>
                            <td><p>{earthquake.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Training levy</p></td>
                            <td><p>{TLevy.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>PHC Fund</p></td>
                            <td><p>{PHCFund.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p className='bold'>Total (KSH)</p></td>
                            <td><p className='bold'>{policyNet.toLocaleString()}</p></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }
    else if (selectedProductClass === '(07) - Motor Private'
    || selectedProductClass === '(07) - Motor Private - Comprehensive'
    || selectedProductClass === '(07) - Motor Private - TPO'
    || selectedProductClass === '(08) - Motor Commercial'
    || selectedProductClass === '(08) - Motor Commercial - Comprehensive'
    || selectedProductClass === '(08) - Motor Commercial - TPO') {
        premiumRNContent = <tr>
            <td><p>Premium</p></td>
            <td>
                <table className='sub_table premium'>
                    <thead>
                        <tr><td></td><td></td><td></td><td></td></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan='11' colSpan='2'></td>
                            <td><p>Premium</p></td>
                            <td><p>{parseInt(premium).toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>PVT</p></td>
                            <td><p>{PVTPremium.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Stamp Duty</p></td>
                            <td><p>{stampDuty.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>ExcessProtector</p></td>
                            <td><p>{excessProtector.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Comesa Card</p></td>
                            <td><p>{comesaCard.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Wind Screen</p></td>
                            <td><p>{windScreen.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Radio Casset</p></td>
                            <td><p>{radioCasset.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p>Training levy</p></td>
                            <td><p>{TLevy.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p className='bold'>PHC Fund</p></td>
                            <td><p>{PHCFund.toLocaleString()}</p></td>
                        </tr>
                        <tr>
                            <td><p className='bold'>Total (KSH)</p></td>
                            <td><p className='bold'>{policyNet.toLocaleString()}</p></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    }

    let RNDate = new Date();
    const RNDay = RNDate.toLocaleString('en-us', { day: '2-digit' });
    const RNMonth = RNDate.toLocaleString('en-us', { month: 'long' });
    const RNYear = RNDate.getFullYear();
    RNDate = RNDay + ' ' + RNMonth + ' ' + RNYear;

    //unique rn no
    function getRandomNumbers() {
        const typedArray = new Uint8Array(2);
        const randomValues = window.crypto.getRandomValues(typedArray);
        return randomValues.join('');
    }

    //risk note type
    const [riskNoteType, setRiskNoteType] = useState('Risk Note');
    useEffect(() => {
        if (transactionType === 'Debit') {
            if (businessType === 'New') {
                setRiskNoteType('New Risk Note')
            }
            else if (businessType === 'Nil Endorsment') {
                setRiskNoteType('Nil Endorsment Risk Note')
            }
            else if (businessType === 'Renewal') {
                setRiskNoteType('Renewal Risk Note')
            }
            else if (businessType === 'Endorsment') {
                setRiskNoteType('Addendum Risk Note')
            }
            else if (businessType === 'Renewal') {
                setRiskNoteType('Renewal Risk Note')
            }
            else if (businessType === 'Comesa Endorsment') {
                setRiskNoteType('Comesa Endorsment Risk Note')
            }
        }
        else if (transactionType === 'Credit') {
            setRiskNoteType('Refund Risk Note')
        }
    }, [transactionType, businessType])

    //########################################################################################################
    //########################################################################################################
    //########################################################################################################


    return (
        <section className='risk_note_container'>
            <div className='logo_container'><img alt='logo' src={logo} /></div>
            <div className='title'>
                <p className='bold'>{riskNoteType}</p></div>
            <div className='risk_note_number'><p>No: {getRandomNumbers()}</p></div>
            <table className='main_table'>
                <thead><tr><td></td><td></td></tr></thead>
                <tbody>
                    <tr>
                        <td><p>Date</p></td>
                        <td><p>{RNDate}</p></td>
                    </tr>
                    <tr>
                        <td><p>Insured Details</p></td>
                        <td>
                            <table className='sub_table client_details'>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p className='bold'>Name</p></td>
                                        <td><p>{clientName}</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold'>KRA Pin</p></td>
                                        <td><p>{clientDetails.KRAPin}</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold'>Address</p></td>
                                        <td><p>{clientDetails.address}</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Insurer</p></td>
                        <td><p>{insurerName}</p></td>
                    </tr>
                    {!bankInterest ? ''
                        : <tr>
                            <td><p>Bank Interest</p></td>
                            <td><p>{bankInterest}</p></td>
                        </tr>
                    }

                    <tr>
                        <td><p>Policy Class</p></td>
                        <td><p>{selectedProductSubClass}</p></td>
                    </tr>
                    <tr>
                        <td><p>Policy Number</p></td>
                        <td><p>{policyNumber}</p></td>
                    </tr>
                    <tr>
                        <td><p>Period</p></td>
                        <td>
                            <p className='bold'>From: {inceptionDate}</p>
                            <div className='h_5'></div>
                            <p className='bold'>To: {expiryDate}</p>
                        </td>
                    </tr>
                    {particularsOfTheVehicleRNContent}
                    {particularsOfTheRiskRNContent}
                    {additionalInstructionsContent}
                    {employeeDetailsRNContent}
                    {descriptionRNContent}
                    {benefitsRNContent}
                    {deductiblesRNContent}
                    {clausesRNContent}
                    {premiumRNContent}
                    {/* Summary */}
                    <tr>
                        <td></td>
                        <td>
                            <table className='sub_table summary'>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan='2'>
                                            <p>This is a summary of this policy, for more information please refer to the policy document.</p>
                                        </td>
                                        <td>
                                            <center><p>For: Simia Insurance Agency</p></center>
                                            <div className='h_30'></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><center><p>Authorized By</p></center></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='risk_note_number'>
                <p>Prepared On: {RNDate}</p>
            </div>
            <div className='footer'>
                <center><p>SIMIA INSURANCE AGENCY LIMITED | P.O BOX 623 – 30100, ELDORET | TEL: +254722968637 | EMAIL: info@simiainsurance.com</p></center>
            </div>
            {loaderContent}
        </section>

    )

}
export default BodyRiskNote;