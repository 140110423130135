import './Paginate.css';

const Paginate = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='paginate'>
            <ul>
                {pageNumbers.map((number) => (
                    <li onClick={() => { paginate(number) }} key={number}>
                        <p>{number}</p>
                    </li>
                ))}

            </ul>
        </div>
    )
}

export default Paginate;