import React, { useEffect, useState, useRef } from 'react';
import { doc, runTransaction, updateDoc } from "firebase/firestore";
import db from '../../../firebase';
import Loader from '../Loader/Loader';
import GetAllClaims from '../../Hooks/BackendConn/Claims/GetAllClaims';
import DateFormat from '../../Hooks/UI/DateFormat';
import DatePicker from 'react-date-picker';
import logo from '../../Assets/img/logo.png';
import { useReactToPrint } from 'react-to-print';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import ConfirmAction from '../../Hooks/ConfirmAction/ConfirmAction';
import { useRecoilState } from 'recoil';
import { rightNavPolicyItem, pageReloaderHandler } from '../../../atoms';
import './ViewClaim.css';
import '../BodyPolicy/BodyRiskNote.css'

const ViewClaim = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get all claims
    const [claimDocs, setClaimDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClaims();
            setClaimDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //get recols state to reload
    const [pageReloaderHandlerVC, setPageReloaderHandlerVC] = useRecoilState(pageReloaderHandler)

    useEffect(() => {
        if (pageReloaderHandlerVC.notification === 'Claim Document Uploaded Successfully') {
            setReload(Math.random(10001, 20000))
            setPageReloaderHandlerVC({})
        }
    }, [pageReloaderHandlerVC])

    const [rightNavPolicyItemRV, setRightNavPolicyItemRV] = useRecoilState(rightNavPolicyItem)

    //################################################################
    //get claim to view
    const [viewingClaimDoc, setViewingClaimDoc] = useState([])
    useEffect(() => {
        const viewingClaim = claimDocs.filter(doc => doc.docID == props.onePropUp.postedDocRef)
        setViewingClaimDoc(viewingClaim)
        setRightNavPolicyItemRV({ ...viewingClaim[0] })
    }, [claimDocs])

    //################################################################
    // get user Input
    const [productClass, setProductClass] = useState();
    const [productSubClass, setProductsSubClass] = useState();
    const [insurerClaimNumber, setInsurerClaimNumber] = useState('');
    const [dateOfLoss, setDateOfLoss] = useState('');
    const [natureOfLoss, setNatureOfLoss] = useState('');
    const [estimatedAmountOfLoss, setEstimatedAmountOfLoss] = useState('');
    const [locationOfTheVehicle, setLocationOfTheVehicle] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [reserveAmount, setReserveAmount] = useState('');
    const [assessor, setAssessor] = useState('');
    const [assessorFirm, setAssessorFirm] = useState('');
    const [assessmentDate, setAssessmentDate] = useState('');
    const [assessorAppointmentDate, setAssessorAppointmentDate] = useState('');
    const [status, setStatus] = useState([{ narration: '' },]);
    const [paidAmount, setPaidAmount] = useState('');
    const [title, setTitle] = useState('');
    const [claimDocuments, setClaimDocuments] = useState({})

    //################################################################
    // set state vars
    useEffect(() => {
        viewingClaimDoc.map((doc) => {
            setProductClass(doc.docData.selectedProductClass);
            setProductsSubClass(doc.docData.selectedProductSubClass);
            setInsurerClaimNumber(doc.docData.insurerClaimNumber);
            Object.keys(doc.docData.dateOfLoss).length > 0
                ? setDateOfLoss(new Date(doc.docData.dateOfLoss.seconds * 1000))
                : setDateOfLoss('')
            setNatureOfLoss(doc.docData.natureOfLoss);
            setEstimatedAmountOfLoss(doc.docData.estimatedAmountOfLoss);
            setLocationOfTheVehicle(doc.docData.locationOfTheVehicle);
            setContactPerson(doc.docData.contactPerson);
            setReserveAmount(doc.docData.reserveAmount);
            setAssessor(doc.docData.assessor);
            setAssessorFirm(doc.docData.assessorFirm);
            Object.keys(doc.docData.assessmentDate).length > 0
                ? setAssessmentDate(new Date(doc.docData.assessmentDate.seconds * 1000))
                : setAssessmentDate('');
            Object.keys(doc.docData.assessorAppointmentDate).length > 0
                ? setAssessorAppointmentDate(new Date(doc.docData.assessorAppointmentDate.seconds * 1000))
                : setAssessorAppointmentDate('');
            Object.keys(doc.docData.status).length > 0
                ? setStatus(Object.values(doc.docData.status))
                : setStatus([{ narration: '' },])
            setPaidAmount(doc.docData.paidAmount);
            setTitle(doc.docData.title);
            setClaimDocuments(doc.docData.claimDocuments);
        })
    }, [viewingClaimDoc])

    //################################################################
    // open new view
    const reloadOnSuccessUpdate = () => {
        const onePropObj = {
            setView: 'View Claim',
            previousView: 'View Claim',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Claim Updated Successfully'
        }
        props.onePropDown(onePropObj);
        setReload(Math.random(0, 1000));
    }

    //################################################################
    // update obj 
    async function editPolicyFormSubmitHandler(event) {
        event.preventDefault();
        setIsLoading(true);

        const sfDocRef = doc(db, "Claims", "lqj4Qnx0jn6CJfHQdiff");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //console.log(sfDoc)

                const newObj = {
                    policyDocID: viewingClaimDoc[0].docData.policyDocID,
                    clientName: viewingClaimDoc[0].docData.clientName,
                    insurerName: viewingClaimDoc[0].docData.insurerName,
                    insurerClaimNumber: insurerClaimNumber,
                    policyNumber: viewingClaimDoc[0].docData.policyNumber,
                    endorsmentNumber: viewingClaimDoc[0].docData.endorsmentNumber,
                    bankInterest: viewingClaimDoc[0].docData.bankInterest,
                    selectedProductClass: viewingClaimDoc[0].docData.selectedProductClass,
                    selectedProductSubClass: viewingClaimDoc[0].docData.selectedProductSubClass,
                    inceptionDate: viewingClaimDoc[0].docData.inceptionDate,
                    expiryDate: viewingClaimDoc[0].docData.expiryDate,
                    insurerDebitDate: viewingClaimDoc[0].docData.insurerDebitDate,
                    locationOfTheRisk: viewingClaimDoc[0].docData.locationOfTheRisk,
                    totalSumInsured: viewingClaimDoc[0].docData.totalSumInsured,
                    dateOfLoss: dateOfLoss,
                    natureOfLoss: natureOfLoss,
                    vehicleDetails: viewingClaimDoc[0].docData.vehicleDetails,
                    estimatedAmountOfLoss: estimatedAmountOfLoss,
                    locationOfTheVehicle: locationOfTheVehicle,
                    contactPerson: contactPerson,
                    reserveAmount: reserveAmount,
                    assessor: assessor,
                    assessmentDate: assessmentDate,
                    assessorFirm: assessorFirm,
                    assessorAppointmentDate: assessorAppointmentDate,
                    createdBy: viewingClaimDoc[0].docData.createdBy,
                    dateCreated: viewingClaimDoc[0].docData.dateCreated,
                    status: { ...status },
                    stage: viewingClaimDoc[0].docData.stage,
                    paidAmount: paidAmount,
                    title: title,
                    claimDocuments: viewingClaimDoc[0].docData.claimDocuments,
                };
                transaction.update(sfDocRef, { [props.onePropUp.postedDocRef]: newObj });
                setIsLoading(false);
            });
            //console.log("Transaction successfully committed!");
            reloadOnSuccessUpdate()
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    //  close claim
    //step 3
    const reloadOnSuccessCloseClaim = () => {
        const onePropObj = {
            setView: 'View Claim',
            previousView: 'View Claim',
            postedDocRef: props.onePropUp.postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Claim Closed'
        }
        props.onePropDown(onePropObj);
        setIsLoading(false);
    }

    //################################################################
    //step 2
    const closeClaimHandler = async () => {
        try {

            const docRef = doc(db, "Claims", "lqj4Qnx0jn6CJfHQdiff");

            const data = props.onePropUp.postedDocRef + '.stage';
            console.log(data)
            const a = await updateDoc(docRef, {
                [data]: 'Closed'
            });

        } catch (error) { console.log(error) }
        reloadOnSuccessCloseClaim();
    }

    //################################################################
    //step 1
    const closeClaim = () => {
        const executeAbort = ConfirmAction('Confirm You Want To Close This Claim.');
        if (!executeAbort) { return false }

        setIsLoading(true)
        closeClaimHandler();

    }

    //################################################################
    // policy Details Content
    const selectedPolicyDetailsContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Policy Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='h_5'></div>
                    <table className='view_claim_policy_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                viewingClaimDoc.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{index + 1}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.endorsmentNumber}</p></td>
                                            <td><p>{doc.docData.selectedProductSubClass}</p></td>
                                            <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td><p>
                                                {doc.docData.vehicleDetails.regNo}
                                                {doc.docData.locationOfTheRisk}
                                            </p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // motor claim Details Content
    const motorClaimDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Claim Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={insurerClaimNumber || ''} onChange={(e) => { setInsurerClaimNumber(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Insurer Claim No.</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <select value={natureOfLoss || ''} className='select' onChange={(e) => { setNatureOfLoss(e.target.value) }}>
                            <option>Select Option</option>
                            <option>Own Damage</option>
                            <option>Third Party</option>
                            <option>Windscreen</option>
                        </select>
                        <label className='placeholder'><p>Nature Of Loss</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={estimatedAmountOfLoss || ''} onChange={(e) => { setEstimatedAmountOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Estimated Amount Of Loss</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={locationOfTheVehicle || ''} onChange={(e) => { setLocationOfTheVehicle(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Location Of The Vehicle</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Loss</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setDateOfLoss} value={dateOfLoss || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={contactPerson || ''} onChange={(e) => { setContactPerson(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Contact Person</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={paidAmount || ''} onChange={(e) => { setPaidAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Paid Amount</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={assessor || ''} onChange={(e) => { setAssessor(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Assessor</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={assessorFirm || ''} onChange={(e) => { setAssessorFirm(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Assessor Firm</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Assessor Appointment</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setAssessorAppointmentDate} value={assessorAppointmentDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Assessment</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setAssessmentDate} value={assessmentDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // non motor claim Details Content
    const nonMotorClaimDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Claim Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={insurerClaimNumber || ''} onChange={(e) => { setInsurerClaimNumber(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Insurer Claim No.</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={estimatedAmountOfLoss || ''} onChange={(e) => { setEstimatedAmountOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Estimated Amount Of Loss</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={natureOfLoss || ''} onChange={(e) => { setNatureOfLoss(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Nature Of Loss</p></label>
                    </div>
                    {
                        viewingClaimDoc.length > 0
                            ? viewingClaimDoc[0].docData.selectedProductSubClass === '(112) - Wiba' || viewingClaimDoc[0].docData.selectedProductSubClass === '(114) - Wiba Plus'
                                ?
                                <div>
                                    <div className='h_15'></div>
                                    <div className='input_group'>
                                        <input value={title || ''} onChange={(e) => { setTitle(e.target.value) }} type='text' className='input' placeholder='' />
                                        <label className='placeholder'><p>Title</p></label>
                                    </div>
                                </div>
                                : ''
                            : ''
                    }
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={contactPerson || ''} onChange={(e) => { setContactPerson(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Contact Person</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Loss</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setDateOfLoss} value={dateOfLoss || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={paidAmount || ''} onChange={(e) => { setPaidAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Paid Amount</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={reserveAmount || ''} onChange={(e) => { setReserveAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Reserve Amount</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={assessor || ''} onChange={(e) => { setAssessor(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Assessor</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={assessorFirm || ''} onChange={(e) => { setAssessorFirm(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Assessor Firm</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Assessor Appointment</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setAssessorAppointmentDate} value={assessorAppointmentDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Date Of Assessment</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setAssessmentDate} value={assessmentDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // status
    const onBenefitsItemChangeHandler = (index, event) => {
        const benefitsValues = [...status];
        benefitsValues[index][event.target.name] = event.target.value;
        setStatus(benefitsValues);
    }

    const addBenefitshandler = () => {
        setStatus([...status, { narration: '' }])
    }

    const removeBenefitshandler = (index) => {
        const benefitsValues = [...status];
        benefitsValues.splice(index, 1);

        setStatus(benefitsValues);
    }

    const statusContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Status</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='status_table'>
                        <thead>
                            <tr>
                                <td><p>Narration</p></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {status.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <input value={item.narration || ''} onChange={event => onBenefitsItemChangeHandler(index, event)} name='narration' type='text' />
                                        </td>
                                        <td className='property_description_controls'>
                                            <i className="ri-close-circle-line" onClick={() => removeBenefitshandler(index)}></i>
                                            <div className='w_10'></div>
                                            <i className="ri-add-circle-line" onClick={addBenefitshandler}></i>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //#########################################################################
    //claim note content
    //particulars of the veh
    const particularsOfTheVehicleCNContent = <tr>
        <td><p>Particulars Of The Vehicle</p></td>
        <td>
            <table className='sub_table particularsOfTheVehicle'>
                <thead>
                    <tr>
                        <td><p>Plate No</p></td>
                        <td><p>Estimated Value</p></td>
                        <td><p>Wind Screen Limit</p></td>
                        <td><p>Radio Casset Limit</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        viewingClaimDoc.length > 0
                            ? <tr>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.regNo}</p></td>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.estimatedValue}</p></td>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.windScreenLimit}</p></td>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.radioCassetLimit}</p></td>
                            </tr>
                            : ''

                    }
                </tbody>
            </table>
        </td>
    </tr>

    //particulars of the risk
    const particularsOfTheRiskCNContent = <tr>
        <td><p>Particulars Of The Risk</p></td>
        <td>
            <table className='sub_table particularsOfTheRisk'>
                <thead>
                    <tr>
                        <td><p className='bold'>Location Of The Risk</p></td>
                        <td><p className='bold'>Total Sum Insured</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        viewingClaimDoc.length > 0
                            ? <tr>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.locationOfTheRisk}</p></td>
                                <td><p>{viewingClaimDoc[0].docData.vehicleDetails.totalSumInsured}</p></td>
                            </tr>
                            : ''
                    }
                </tbody>
            </table>
        </td>
    </tr>

    //#########################################################################
    //claim note
    const claimNoteContent = <section className='risk_note_container'>
        <div className='logo_container'><img alt='logo' src={logo} /></div>
        <div className='title'>
            <p className='bold'>Claim Note</p>
        </div>
        {viewingClaimDoc.length > 0
            ? <table className='main_table'>
                <thead><tr><td></td><td></td></tr></thead>
                <tbody>
                    <tr>
                        <td><p>Date</p></td>
                        <td><p>{DateFormat(new Date())}</p></td>
                    </tr>
                    <tr>
                        <td><p>Insured Details</p></td>
                        <td>
                            <table className='sub_table client_details'>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p className='bold'>Name</p></td>
                                        <td><p>{viewingClaimDoc[0].docData.clientName}</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold'>KRA Pin</p></td>
                                        <td><p>{ }</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='bold'>Address</p></td>
                                        <td><p>{ }</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Insurer</p></td>
                        <td><p>{viewingClaimDoc[0].docData.insurerName}</p></td>
                    </tr>
                    {
                        viewingClaimDoc[0].docData.bankInterest
                            ? <tr>
                                <td><p>Bank Interest</p></td>
                                <td><p>{viewingClaimDoc[0].docData.bankInterest}</p></td>
                            </tr>
                            : ''
                    }
                    <tr>
                        <td><p>Policy Class</p></td>
                        <td><p>{viewingClaimDoc[0].docData.selectedProductSubClass}</p></td>
                    </tr>
                    <tr>
                        <td><p>Policy Number</p></td>
                        <td><p>{viewingClaimDoc[0].docData.policyNumber}</p></td>
                    </tr>
                    <tr>
                        <td><p>Period</p></td>
                        <td>
                            <p className='bold'>From: {DateFormat(viewingClaimDoc[0].docData.inceptionDate)}</p>
                            <div className='h_5'></div>
                            <p className='bold'>To: {DateFormat(viewingClaimDoc[0].docData.expiryDate)}</p>
                        </td>
                    </tr>
                    {
                        viewingClaimDoc.length > 0
                            ? viewingClaimDoc[0].docData.selectedProductClass === '(07) - Motor Private' || viewingClaimDoc[0].docData.selectedProductClass === '(08) - Motor Commercial'
                                ? particularsOfTheVehicleCNContent
                                : particularsOfTheRiskCNContent
                            : <tr></tr>
                    }
                    <tr>
                        <td><p>Date Of Loss</p></td>
                        <td><p className='bold'>{DateFormat(viewingClaimDoc[0].docData.dateOfLoss)}</p></td>
                    </tr>
                    <tr>
                        <td><p>Nature Of Loss</p></td>
                        <td><p className='bold'>{viewingClaimDoc[0].docData.natureOfLoss}</p></td>
                    </tr>
                </tbody>
            </table>
            : ''
        }
    </section>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //#########################################################################
    //documents
    const documentsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Claim Documents</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='claim_documents_table'>
                        <thead>
                            <tr>
                                <td></td>
                                <td><p className='bold'>Category</p></td>
                                <td><p className='bold'>Upload Date</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(claimDocuments).map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={() => openInNewTab(doc.claimDocumentDownloadLink)}>
                                            <td><input type='checkbox' checked readOnly /></td>
                                            <td><p>
                                                {
                                                    doc.claimDocumentCategory === 'Other'
                                                        ? doc.claimDocumentCategoryNarration
                                                        : doc.claimDocumentCategory
                                                }
                                            </p></td>
                                            <td>{DateFormat(doc.documentUploadDate)}</td>
                                        </tr>

                                    )
                                })
                            }
                            {
                                Object.values(claimDocuments).filter(doc => doc.claimDocumentCategory === 'Claim Form').length < 1
                                    ? <tr>
                                        <td><input type='checkbox' readOnly /></td>
                                        <td><p>Claim Form</p></td>
                                        <td><p></p></td>
                                    </tr>
                                    : ''
                            }
                            {
                                productClass === '(07) - Motor Private' || productClass === '(08) - Motor Commercial'
                                    ? Object.values(claimDocuments).filter(doc => doc.claimDocumentCategory === 'Driving License').length < 1
                                        ? <tr>
                                            <td><input type='checkbox' readOnly /></td>
                                            <td><p>Driving License</p></td>
                                            <td><p></p></td>
                                        </tr>
                                        : ''
                                    : ''
                            }
                            {
                                productClass === '(07) - Motor Private' || productClass === '(08) - Motor Commercial'
                                    ? Object.values(claimDocuments).filter(doc => doc.claimDocumentCategory === 'Logbook').length < 1
                                        ? <tr>
                                            <td><input type='checkbox' readOnly /></td>
                                            <td><p>Logbook</p></td>
                                            <td><p></p></td>
                                        </tr>
                                        : ''
                                    : ''
                            }
                            {
                                productClass === '(07) - Motor Private' || productClass === '(08) - Motor Commercial'
                                    ? natureOfLoss === 'Windscreen'
                                        ? Object.values(claimDocuments).filter(doc => doc.claimDocumentCategory === 'ETR Receipt').length < 1
                                            ? <tr>
                                                <td><input type='checkbox' readOnly /></td>
                                                <td><p>ETR Receipt</p></td>
                                                <td><p></p></td>
                                            </tr>
                                            : <tr></tr>
                                        : ''
                                    : ''
                            }
                            {
                                productClass === '(07) - Motor Private' || productClass === '(08) - Motor Commercial'
                                    ? natureOfLoss === 'Own Damage' || natureOfLoss === 'Third Party'
                                        ? Object.values(claimDocuments).filter(doc => doc.claimDocumentCategory === 'Police Abstract').length < 1
                                            ? <tr>
                                                <td><input type='checkbox' readOnly /></td>
                                                <td><p>Police Abstract</p></td>
                                                <td><p></p></td>
                                            </tr>
                                            : <tr></tr>
                                        : ''
                                    : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //#########################################################################
    //policy controls
    const [hideShowControls, setHideShowControls] = useState('control_list')
    const hideShowControlsClickHandler = (instruction) => {
        setHideShowControls(instruction)
    }

    return (
        <section className='view_claim body_input_styles'>
            <div className='body_section_header'>
                <p>View Claim</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={event => editPolicyFormSubmitHandler(event)}>Save Changes</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrint}>Print Claim Note</p> */}
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={closeClaim}>Close Claim</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {selectedPolicyDetailsContent}
                {
                    viewingClaimDoc.length > 0
                        ? viewingClaimDoc[0].docData.selectedProductClass === '(07) - Motor Private' || viewingClaimDoc[0].docData.selectedProductClass === '(08) - Motor Commercial'
                            ? motorClaimDetailsContent
                            : nonMotorClaimDetailsContent
                        : ''
                }
                {statusContent}
                {documentsContent}
                <div ref={componentRef} style={{ display: "none" }}>
                    {claimNoteContent}
                </div>
            </div>
            {loaderContent}
        </section>
    );
}

export default ViewClaim;