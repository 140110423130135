import { useRecoilState } from "recoil";
import { businessReportFilterFromDate, businessReportFilterToDate} from "../../../atoms";
import DatePicker from "react-date-picker";

const BusinessReportFilter = () => {
    //################################################################
    //business report filter
    const [businessReportFilterFromDateRN, setBusinessReportFilterFromDateRN] = useRecoilState(businessReportFilterFromDate)
    const [businessReportFilterToDateRN, setBusinessReportFilterToDateRN] = useRecoilState(businessReportFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <div><p className='right_nav_help'>This report queries policy net of customer's policies and filters using insurer debit date</p></div>
                <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setBusinessReportFilterFromDateRN} value={businessReportFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setBusinessReportFilterToDateRN} value={businessReportFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
        </section>
    )
}

export default BusinessReportFilter;