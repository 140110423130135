import React, { useEffect, useState } from "react";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import styles from './ClientStatementTemplate.module.css';
import logo from '../../Assets/img/logo.png'

const ClientStatementTemplate = (props) => {

    const [data, setData] = useState({})

    useEffect(() => { setData(props) }, [props])
    //console.log(data)

    const [clientObj, setClientObj] = useState({})
    useEffect(() => {
        if (data.hasOwnProperty('data')) {
            if (data.data.hasOwnProperty('clientDetailsObj')) {
                setClientObj(data.data.clientDetailsObj)
            }
        }
    }, [data])

    if (Object.keys(data).length > 0) {
        // console.log(props)
        // console.log(props.data.clientDetailsObj.docData.clientName)
        





        return (
            <section className={styles.client_statement_template}>
                <section>
                    <table className={styles.client_statement_template_stmt_details_table}>
                        <thead>
                            <tr>
                                <td className='align-top'>
                                    <p className='bold f_8'>Billed By</p>
                                    <div className='h_5'></div>
                                    <p>SIMIA INSURANCE AGENCY LIMITED</p>
                                    <div className='h_5'></div>
                                    <p>P.O BOX 623 – 30100 ELDORET </p>
                                    <div className='h_5'></div>
                                    <p>TEL: +254 722 968 637 </p>
                                    <div className='h_5'></div>
                                    <p>EMAIL: info@simiainsurance.com</p>
                                </td>
                                <td className='align-top'>
                                    <p className='bold f_8'>Billed To</p>
                                    <div className='h_5'></div>
                                    <p>{props.data.clientDetailsObj.docData.clientName}</p>
                                    <div className='h_5'></div>
                                    <p>{Object.entries(props.data.clientDetailsObj).length > 0 && props.data.clientDetailsObj.docData.KRAPin}</p>
                                    <div className='h_5'></div>
                                    <p>{Object.entries(props.data.clientDetailsObj).length > 0 && props.data.clientDetailsObj.docData.address}</p>
                                </td>
                                <td className='align-top'>
                                    <p className='bold'>Period</p>
                                    <div className='h_5'></div>
                                    <p>{Object.entries(props.data.stmtDetails).length > 0 && props.data.stmtDetails.periodFrom + ' - ' + props.data.stmtDetails.periodTo}</p>
                                    <div className='h_5'></div>
                                    <p className='bold'>Insurer</p>
                                    <div className='h_5'></div>
                                    <p>{Object.entries(props.data.stmtDetails).length > 0 && props.data.stmtDetails.insurer}</p>
                                    <div className='h_5'></div>
                                    <p className='bold'>Date Prepared</p>
                                    <div className='h_5'></div>
                                    <p>{DateFormat(new Date())}</p>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div className='h_15'></div>
                    <table className={styles.client_statement_template_cummulative_table}>
                        <thead>
                            <tr className='border border-sky-500'>
                                <td><p>Date</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Risk Class</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Credit Net</p></td>
                                <td><p>Outsta- nding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.outstandingObj.map((doc, index) => {
                                return (
                                    <tr key={index}>
                                        <td><p>{DateFormat(doc.universalDate)}</p></td>
                                        <td><p>{doc.transactionType}</p></td>
                                        <td><p>{doc.policyNumber}</p></td>
                                        <td><p>{doc.endorsmentNumber}</p></td>
                                        <td><p>{doc.policyClass}</p></td>
                                        <td><p>{doc.details}</p></td>
                                        <td><p>
                                            {
                                                doc.transactionType === 'Debit' && NumberFormat(doc.policyNet)
                                            }
                                        </p></td>
                                        <td><p>
                                            {
                                                doc.transactionType === 'Receipt' && '(' + NumberFormat(doc.policyNet) + ')'
                                            }
                                            {
                                                doc.transactionType === 'Credit' && '(' + NumberFormat(doc.policyNet) + ')'
                                            }
                                        </p></td>
                                        <td><p>{NumberFormat(doc.cummulativeOutstanding)}</p></td>
                                    </tr>
                                )
                            })}

                            {/* <tr>
                                <td colSpan='6'></td>
                                <td><p>{props.data.ageingTotalClientDebits}</p></td>
                                <td><p>{props.data.ageingTotalClientCreditsReceipts}</p></td>
                                <td></td>
                            </tr> */}

                        </tbody>
                    </table>
                    <div className='h_15'></div>
                    <table className={styles.client_statement_template_ageing_table}>
                        <thead>
                            <tr>
                                <td rowSpan='2'><p>AGEING</p></td>
                                <td><p>Credit</p></td>
                                <td><p>0 - 30 Days</p></td>
                                <td><p>31 - 60 Days</p></td>
                                <td><p>61 - 90 Days</p></td>
                                <td><p>91 - 120 Days</p></td>
                                <td><p>Above 120 Days</p></td>
                                <td><p>Total</p></td>
                            </tr>
                            <tr>
                                <td><p>{props.data.ageingObj.credit}</p></td>
                                <td><p>{props.data.ageingObj.thirtyDays}</p></td>
                                <td><p>{props.data.ageingObj.sixtyDays}</p></td>
                                <td><p>{props.data.ageingObj.ninetyDays}</p></td>
                                <td><p>{props.data.ageingObj.oneTwentyDays}</p></td>
                                <td><p>{props.data.ageingObj.aboveOneTwentyDays}</p></td>
                                <td><p>{props.data.ageingObj.total}</p></td>
                            </tr>
                        </thead>
                    </table>
                </section>
                <div className={styles.client_statement_template_page_break}></div>
                <div className={styles.watermark_logo}><img src={logo} /></div>

            </section>
        );

    }
}

export default ClientStatementTemplate;