import NumberFormat from "../../Hooks/UI/NumberFormat";
import styles from './RenewalScheduleInterface.module.css'
import logo from '../../Assets/img/logo.png';
import DateFormat from "../../Hooks/UI/DateFormat";

const RenewalScheduleInterface = (receivedData) => {
    try {

        return (
            <section>
                <div className={styles.renewal_schedule_template_logo_container}><img alt='logo' src={logo} /></div>
                <div className='h_2'></div>
                <p className={`text-center bold ${styles.f_1}`}>RENEWAL SCHEDULE</p>
                <table className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                    <tbody>
                        <tr>
                            <td className='w-1/2 border border-slate-600 p-1' colSpan='3'>
                                <div className='h_5'></div>
                                <p className='bold'>INSURED DETAILS</p>
                                <div className='h_5'></div>
                                <p>{Object.entries(receivedData.clientData).length > 0 ? receivedData.clientData.docData.clientName : ''}</p>
                                <div className='h_5'></div>
                                <p>{Object.entries(receivedData.clientData).length > 0 ? receivedData.clientData.docData.KRAPin : ''}</p>
                                <div className='h_5'></div>
                                <p>{Object.entries(receivedData.clientData).length > 0 ? receivedData.clientData.docData.address : ''}</p>
                                <div className='h_5'></div>
                            </td>
                            <td className='w-1/2 border border-slate-600 p-1' colSpan='3'>
                                <div className='h_5'></div>
                                <p className='bold'>AGENT DETAILS</p>
                                <div className='h_5'></div>
                                <p>SIMIA INSURANCE AGENCY LIMITED</p>
                                <div className='h_5'></div>
                                <p>P.O BOX 623 – 30100 ELDORET </p>
                                <div className='h_5'></div>
                                <p>TEL: +254 722 968 637 </p>
                                <div className='h_5'></div>
                                <p>EMAIL: info@simiainsurance.com</p>
                                <div className='h_5'></div>
                            </td>
                        </tr>
                        {
                            receivedData.docsForRenewal.map((doc, index) => {
                                let renewableCheck = '';
                                if (doc.docData.selectedProductSubClass === '(060) - Marine Hull'
                                    || doc.docData.selectedProductSubClass === '(061) - Marine Certificate'
                                    || doc.docData.selectedProductSubClass === '(120) - Immigration Bond'
                                    || doc.docData.selectedProductSubClass === '(127) - Advance Payment Bond'
                                    || doc.docData.selectedProductSubClass === '(128) - Custom Bond'
                                    || doc.docData.selectedProductSubClass === '(093) - Travel'
                                    || doc.docData.selectedProductSubClass === '(020) - Contractors All Risks') {
                                    renewableCheck = 'NOT RENEWABLE'
                                }
                                if (doc.docData.selectedProductClass !== '(07) - Motor Private'
                                    && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
                                    && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO'
                                    && renewableCheck === '') {

                                    return (
                                        <tr key={index}>
                                            <td className='w-1/2 border border-slate-600 p-1 align-top'>
                                                <p className='bold'>POLICY CLASS</p>
                                                <p>{doc.docData.selectedProductSubClass}</p>
                                                <div className='h_5'></div>
                                                <p className='bold'>POLICY NUMBER</p>
                                                <p>{doc.docData.policyNumber}</p>
                                            </td>
                                            <td className='w-1/2 border border-slate-600 p-1' colSpan='4'>
                                                <span className='bold'>LOCATION:</span>
                                                <span>: {doc.docData.locationOfTheRisk.toUpperCase()}</span>
                                                <div className='h_3'></div>
                                                <div>
                                                    <span className='bold'>PERIOD: </span>
                                                    <span>{DateFormat(doc.docData.inceptionDate)}</span>
                                                    <span> - </span>
                                                    <span>{DateFormat(doc.docData.expiryDate)}</span>
                                                </div>
                                                <div className='h_3'></div>

                                                {
                                                    Object.values(doc.docData.employeesDetailsItems)[0]['positionOfEmployee'] != '' ?
                                                        Object.values(doc.docData.employeesDetailsItems).map((item, index) => {
                                                            return (
                                                                <table key={index} className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                                                                    <tbody>

                                                                        <tr key={index}>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.positionOfEmployee}</p>
                                                                            </td>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.noOfEmployees}</p>
                                                                            </td>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.estimatedAnnualSalary}</p>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            )
                                                        })
                                                        : ''
                                                }
                                                <div className='h_5'></div>
                                                {
                                                    Object.values(doc.docData.propertyDescriptionItems)[0]['propertyDescription'] !== '' &&
                                                    Object.values(doc.docData.propertyDescriptionItems).map((item, index) => {
                                                        return (
                                                            <table key={index} className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                                                                <tbody>

                                                                    <tr>
                                                                        <td className='w-3/4 border border-slate-600 p-1' >
                                                                            <p>{item.propertyDescription}</p>
                                                                        </td>
                                                                        <td className='w-1/4 border border-slate-600 p-1' >
                                                                            <p>{item.sumInsured}</p>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        )
                                                    })
                                                }


                                            </td>
                                            <td className='w-1/2 border border-slate-600 p-1 align-top'>
                                                <p className='bold'>PREMIUM</p>
                                                <p>{NumberFormat(doc.docData.policyNet)}</p>

                                                <p className='bold mt-4'>{renewableCheck}</p>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }


                        {
                            receivedData.docsForRenewal.map((doc, index) => {
                                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                                    // console.log(doc.docData.vehicleItems)
                                    if (Object.keys(doc.docData.vehicleItems).length > 0) {
                                        if (Object.values(doc.docData.vehicleItems)[0]['regNo'] !== '') {

                                            return (
                                                <tr key={index}>
                                                    <td className='w-1/2 border border-slate-600 p-1 align-top truncate'>
                                                        <p className='bold'>POLICY CLASS</p>
                                                        <p>{doc.docData.selectedProductSubClass}</p>
                                                        <div className='h_5'></div>
                                                        <p className='bold'>POLICY NUMBER</p>
                                                        <p>{doc.docData.policyNumber}</p>
                                                    </td>
                                                    <td className='w-1/2 border border-slate-600 p-1' colSpan='4'>
                                                        <div>
                                                            <span className='bold'>PERIOD: </span>
                                                            <span>{DateFormat(doc.docData.inceptionDate)}</span>
                                                            <span> - </span>
                                                            <span>{DateFormat(doc.docData.expiryDate)}</span>
                                                        </div>
                                                        <div className='h_3'></div>
                                                        <span className='bold'>VEHICLE DETAILS:</span>
                                                        <span>: {doc.docData.locationOfTheRisk.toUpperCase()}</span>
                                                        <div className='h_3'></div>

                                                        {
                                                            Object.values(doc.docData.vehicleItems)[0]['regNo'] !== '' &&
                                                            Object.values(doc.docData.vehicleItems).map((item, index) => {
                                                                return (
                                                                    <table key={index} className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                                                                        <tbody>

                                                                            <tr>
                                                                                <td className='w-3/4 border border-slate-600 p-1' >
                                                                                    <p>{item.regNo}</p>
                                                                                </td>
                                                                                <td className='w-1/4 border border-slate-600 p-1' >
                                                                                    <p>{item.estimatedValue}</p>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                )
                                                            })
                                                        }


                                                    </td>
                                                    <td className='w-1/2 border border-slate-600 p-1 align-top'>
                                                        <p className='bold'>PREMIUM</p>
                                                        <p>{NumberFormat(doc.docData.policyNet)}</p>
                                                    </td>
                                                </tr>
                                            )

                                        }
                                    }

                                }
                            })
                        }

                        {
                            receivedData.docsForRenewal.map((doc, index) => {
                                let renewableCheck = '';
                                if (doc.docData.selectedProductSubClass === '(060) - Marine Hull'
                                    || doc.docData.selectedProductSubClass === '(061) - Marine Certificate'
                                    || doc.docData.selectedProductSubClass === '(120) - Immigration Bond'
                                    || doc.docData.selectedProductSubClass === '(127) - Advance Payment Bond'
                                    || doc.docData.selectedProductSubClass === '(128) - Custom Bond'
                                    || doc.docData.selectedProductSubClass === '(093) - Travel'
                                    || doc.docData.selectedProductSubClass === '(020) - Contractors All Risks') {
                                    renewableCheck = 'NOT RENEWABLE'
                                }
                                if (doc.docData.selectedProductClass !== '(07) - Motor Private'
                                    && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
                                    && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
                                    && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO'
                                    && renewableCheck === 'NOT RENEWABLE') {

                                    return (
                                        <tr key={index}>
                                            <td className='w-1/2 border border-slate-600 p-1 align-top'>
                                                <p className='bold'>POLICY CLASS</p>
                                                <p>{doc.docData.selectedProductSubClass}</p>
                                                <div className='h_5'></div>
                                                <p className='bold'>POLICY NUMBER</p>
                                                <p>{doc.docData.policyNumber}</p>
                                            </td>
                                            <td className='w-1/2 border border-slate-600 p-1' colSpan='4'>
                                                <span className='bold'>LOCATION:</span>
                                                <span>: {doc.docData.locationOfTheRisk.toUpperCase()}</span>
                                                <div className='h_3'></div>

                                                {
                                                    Object.values(doc.docData.employeesDetailsItems)[0]['positionOfEmployee'] != '' ?
                                                        Object.values(doc.docData.employeesDetailsItems).map((item, index) => {
                                                            return (
                                                                <table key={index} className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                                                                    <tbody>

                                                                        <tr>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.positionOfEmployee}</p>
                                                                            </td>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.noOfEmployees}</p>
                                                                            </td>
                                                                            <td className='w-1/3 border border-slate-600 p-1' >
                                                                                <p>{item.estimatedAnnualSalary}</p>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            )
                                                        })
                                                        : ''
                                                }
                                                <div className='h_5'></div>
                                                {
                                                    Object.values(doc.docData.propertyDescriptionItems)[0]['propertyDescription'] !== '' &&
                                                    Object.values(doc.docData.propertyDescriptionItems).map((item, index) => {
                                                        return (
                                                            <table key={index} className={`w-full table-fixed border-collapse border border-slate-500 ${styles.f_1}`} >
                                                                <tbody>

                                                                    <tr>
                                                                        <td className='w-3/4 border border-slate-600 p-1' >
                                                                            <p>{item.propertyDescription}</p>
                                                                        </td>
                                                                        <td className='w-1/4 border border-slate-600 p-1' >
                                                                            <p>{item.sumInsured}</p>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        )
                                                    })
                                                }


                                            </td>
                                            <td className='w-1/2 border border-slate-600 p-1 align-top'>
                                                <p className='bold'>PREMIUM</p>
                                                <p>{NumberFormat(doc.docData.policyNet)}</p>

                                                <p className='bold mt-4'>{renewableCheck}</p>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }


                    </tbody>
                </table>
            </section>
        )

    } catch (error) { }

}

export default RenewalScheduleInterface;