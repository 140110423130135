import db from '../../../../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetAllMotor = async () => {
    
        const q = query(collection(db, "PolicyDetails"), where('selectedProductClass', 'in', ['(07) - Motor Private', '(08) - Motor Commercial']));

        const querySnapshot = await getDocs(q);

        const DData = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            DData.push({ docData: doc.data(), docID: doc.id })
        });

        return DData;
}

export default GetAllMotor;