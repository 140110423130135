import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rightNavContent, selectInsurerPostDV } from '../../../atoms';
import DatePicker from 'react-date-picker';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import Loader from '../Loader/Loader';
import './PostDV.css';

const PostDV = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //recoil values
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const selectInsurerPostDVRN = useRecoilValue(selectInsurerPostDV)

     //loading
     const [isLoading, setIsLoading] = useState(false);
     let loaderContent = '';
     if (isLoading === true) {
         loaderContent = <Loader />
     }

    //################################################################
    //get user input
    const [DVNumber, setDVNumber] = useState('')
    const [DVDate, setDVDate] = useState(new Date())
    const [narration, setNarration] = useState('')
    const [amount, setAmount] = useState(0)

    //################################################################
    //insurer debit date
    const [insurerDebitDate, setInsurerDebitDate] = useState('');

    useEffect(() => {
        const Dyear = new Date(DVDate).getFullYear();
        const Dmonth = new Date(DVDate).getMonth() + 1;
        const Ddate = new Date(DVDate).getDate();
        const DexpiryDateCleanedJan = Dyear + '-' + Dmonth + '-' + Ddate;
        setInsurerDebitDate(DexpiryDateCleanedJan)
    }, [DVDate])

    //################################################################
    //step 3
    //show msg
    const displayDVSuccessMSG = (receivedDocID) => {
        const onePropObj = {
            setView: 'View DV',
            previousView: 'Post DV',
            postedDocRef: receivedDocID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'DV posted successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //post data
    async function createDV(DVObj) {        

        try {
            // Add a new document with a generated id.
            setIsLoading(true);
            const docRef = await addDoc(collection(db, "PolicyDetails"), DVObj);
            //console.log("Document written with ID: ", docRef.id);
            displayDVSuccessMSG(docRef.id)
            setIsLoading(false);
        } catch (error) {

        }

    }

    //################################################################
    //step 1
    //create obj
    const createDVPostingObj = () => {
        //create main object for posting
        const DVProcessedData = {
            clientName: '',
            insurerName: selectInsurerPostDVRN,
            bankInterest: '',
            policyNumber: DVNumber,
            selectedProductClass: '',
            selectedProductSubClass: '',
            commissionRate: 0,
            businessType: 'DV',
            endorsmentNumber: '',
            inceptionDate: insurerDebitDate,
            expiryDate: insurerDebitDate,
            insurerDebitDate: insurerDebitDate,
            newInceptionDate: DVDate,
            newExpiryDate: DVDate,
            newInsurerDebitDate: DVDate,
            locationOfTheRisk: narration,
            totalSumInsured: '',
            premium: 0,
            PVTPremium: 0,
            stampDuty: 0,
            TLevy: 0,
            PHCFund: 0,
            commission: 0,
            commissionPayable: 0,
            expectedCommissionPayable: 0,
            outstandingCommission: 0,
            withholdingTax: 0,
            policyNet: amount,
            earthquake: 0,
            excessProtector: 0,
            comesaCard: 0,
            windScreen: 0,
            radioCasset: 0,
            insurerPolicyNet: amount,
            receiptAllocationBreakDown: [],
            commissionAllocationBreakDown: [],
            status: '',
            outstanding: 0,
            transactionType: 'Debit',
            employeesDetailsItems: {},
            propertyDescriptionItems: {},
            benefitsItems: {},
            deductibleItems: {},
            vehicleItems: {},
            clauseItems: {},
            dateCreated: new Date(),
            createdBy: currentUser,
            policyDocID: '',
            policyDocName: '',
            policyDocLocation: ''
        }

        createDV(DVProcessedData)
    }

    //################################################################
    //insurer content
    const insurerContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Insurer Details</p>
                <div className='client_details_controls'>
                    <div className='btn_yellow'>
                        <i className="ri-user-add-line"></i>
                    </div>
                    <div className='w_5'></div>
                    <div className='btn_pink' onClick={() => { setRightNavContentRN('Select Insurer') }}>
                        <i className="ri-search-2-line"></i>
                    </div>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <input value={selectInsurerPostDVRN} type='text' placeholder='Insurer' readOnly />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //insurer constent
    const DVDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>DV Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input value={DVNumber || ''} onChange={(e) => { setDVNumber(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>DV Number</p></label>
                    </div>
                    <div className='h_15'></div>
                    <div className='input_group'>
                        <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Amount</p></label>
                    </div>
                    <div className='h_5'></div>
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Insurer Debit Date</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setDVDate} value={DVDate || ''} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <textarea value={narration || ''} onChange={(e) => { setNarration(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Narration</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='post_DV create_policy_section body_input_styles'>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Post DV</p>
                    <button onClick={createDVPostingObj} className='btn_pink'>Save Details</button>
                </div>
                <div className='body_section'>
                    {insurerContent}
                    {DVDetailsContent}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default PostDV;