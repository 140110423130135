import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import GetAllClients from "../../Hooks/BackendConn/ClientList/GetAllClients";
import Loader from "../Loader/Loader";
import ProcessClientStatementTransactions from "./ProcessClientStatementTransactions";
import { useRecoilState, useRecoilValue } from "recoil";
import { agewiseOutstandingInsurerFilter, agewiseOutstandingFilterFromDate, agewiseOutstandingFilterToDate } from "../../../atoms";
import GetAllClientTransactions from "../../Hooks/BackendConn/InsurerSpecific/GetAllClientTransactions";
import GetAllDebit from "../../Hooks/BackendConn/GeneralList/GetAllDebits";
import GetAllCredits from "../../Hooks/BackendConn/GeneralList/GetAllCredits";
import GetAllReceipts from "../../Hooks/BackendConn/GeneralList/GetAllReceipts";
import { useReactToPrint } from "react-to-print";
import DateFormat from "../../Hooks/UI/DateFormat";
import styles from './OverviewFinal.module.css';
import { activeInsurerPortfolio } from "../../../atoms";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import ClientStatementTemplate from './ClientStatementTemplate';
import { nanoid, random } from 'nanoid';
import GetClientData from "../../Hooks/BackendConn/ClientList/GetClientData";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import GetAllInsurerReceipts from "../../Hooks/BackendConn/InsurerSpecific/GetAllInsurerReceipts";
import NumberFormat from "../../Hooks/UI/NumberFormat";


const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
};

const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = 'calc(100vh - 10rem)';
    api.setDomLayout();
};

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};


const OverviewFinal = () => {
    //################################################################//3/01/112/1593/2014
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page { 
                size: landscape !important; 
                margin: 10mm !important;
            }
          }`,
        content: () => componentRef.current,
    });

    //printing stmt
    const componentRefStmt = useRef();
    const handlePrintStmt = useReactToPrint({
        content: () => componentRefStmt.current,
    });

    //################################################################
    //recoil values
    const agewiseOutstandingInsurerFilterRN = useRecoilValue(agewiseOutstandingInsurerFilter)
    const agewiseOutstandingFilterFromDateRN = useRecoilValue(agewiseOutstandingFilterFromDate)
    const agewiseOutstandingFilterToDateRN = useRecoilValue(agewiseOutstandingFilterToDate)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    const [isloadedOne, setLoadedOne] = useState(false);
    useEffect(() => {
        if (!isloadedOne) {
            (async () => {
                setIsLoading(true)
                const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: agewiseOutstandingInsurerFilterRN, transactionType: 'Debit' });
                setAllDebitDocs(DDocs);
                setLoadedOne(true);
                if (DDocs) {
                    setIsLoading(false)
                }
                else { setReload(Math.random(0, 1000)) }
            })();
        }
        return () => { }
    }, [reload, agewiseOutstandingInsurerFilterRN]);

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    const [isloadedTwo, setLoadedTwo] = useState(false);
    useEffect(() => {
        if (!isloadedTwo) {
            (async () => {
                setIsLoading(true)
                const DDocs = await GetAllInsurerReceipts({ insurerName: agewiseOutstandingInsurerFilterRN });
                setReceiptDocs(DDocs)
                setLoadedTwo(true);
                if (DDocs) {
                    setIsLoading(false)
                }
                else { setReloadTwo(Math.random(0, 1000)) }
            })();
        }
        return () => { }
    }, [reloadTwo, agewiseOutstandingInsurerFilterRN]);

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: agewiseOutstandingInsurerFilterRN, transactionType: 'Credit' });
            setCreditDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 1000)) }
        })();
    }, [reloadThree, agewiseOutstandingInsurerFilterRN]);

    //################################################################
    //get all client data
    const [clientDataDocs, setClientDataDocs] = useState([]);
    const [reloadFive, setReloadFive] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetClientData();
            setClientDataDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadFive(Math.random(0, 10000)) }
        })();
    }, [reloadFive]);

    //################################################################
    //filter insurer
    const [insurerFiltered, setInsurerFiltered] = useState([]);
    useEffect(() => {
        const list = clientDataDocs.filter(doc => doc.docData.insurerID === activeInsurerPortfolioRN);
        setInsurerFiltered(list)
    }, [clientDataDocs, activeInsurerPortfolioRN])

    //################################################################
    //################################################################
    //analyse transactions
    const makeApiCall = async (client) => {
        try {

            const a = []
            const filteredDebits = debitDocs.filter(doc => doc.docData.clientName === client.docData.clientName);
            const filteredcredits = creditDocs.filter(doc => doc.docData.clientName === client.docData.clientName);
            const filteredReceipts = receiptDocs.filter(doc => doc.docData.clientName.includes(client.docData.clientName))
            const filteredCombined = [...filteredDebits, ...filteredcredits, ...filteredReceipts]

            const b = await ProcessClientStatementTransactions({
                clientDocs: filteredCombined,
                clientName: client.docData.clientName,
                clientDetails: client,
                agewiseOutstandingFilterFromDateRN: agewiseOutstandingFilterFromDateRN,
                agewiseOutstandingFilterToDateRN: agewiseOutstandingFilterToDateRN,
                agewiseOutstandingInsurerFilterRN: agewiseOutstandingInsurerFilterRN,
            });

            return b;

        } catch (error) {

        }
    }

    //################################################################
    //loop thro each client
    const fetchData = async (list) => {
        const updatedList = await Promise.all(list.map(makeApiCall));
        return updatedList;
    }

    //################################################################
    //initial call
    const [semiFinalData, setSemiFinalData] = useState([]);
    useMemo(() => {

        if (debitDocs.length > 0 && insurerFiltered.length > 0) {
            fetchData(insurerFiltered).then(setSemiFinalData);
        }

    }, [debitDocs, creditDocs, receiptDocs, insurerFiltered])



    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const a = semiFinalData.filter(doc => doc.id !== undefined || null);
            setFinalData(a);

        } catch (error) { }

    }, [semiFinalData])

    const [totalsRow, setTotalsRow] = useState([])
    useEffect(() => {
        try {

            const ageingThirtyDaysTotal = finalData.reduce((acc, cur) => acc + cur.ageingThirtyDays, 0);
            const ageingSixtyDaysTotal = finalData.reduce((acc, cur) => acc + cur.ageingSixtyDays, 0);
            const ageingNinetyDaysTotal = finalData.reduce((acc, cur) => acc + cur.ageingNinetyDays, 0);
            const ageingOneTwentyDaysTotal = finalData.reduce((acc, cur) => acc + cur.ageingOneTwentyDays, 0);
            const ageingMoreThanOneTwentyDaysTotal = finalData.reduce((acc, cur) => acc + cur.ageingMoreThanOneTwentyDays, 0);

            const ageingCreditTotal = finalData.reduce((acc, cur) => acc + cur.ageingCredit, 0);
            const ageingTotalClientCreditsReceiptsTotal = finalData.reduce((acc, cur) => acc + cur.ageingTotalClientCreditsReceipts, 0);
            const ageingTotalClientDebitsTotal = finalData.reduce((acc, cur) => acc + cur.ageingTotalClientDebits, 0);
            const cummulativeAgeingOutstandingTotal = finalData.reduce((acc, cur) => acc + cur.cummulativeAgeingOutstanding, 0);
            const cummulativeOutstandingTotal = finalData.reduce((acc, cur) => acc + cur.cummulativeOutstanding, 0);

            const totalsObj = {
                ageingMoreThanOneTwentyDays: NumberFormat(ageingMoreThanOneTwentyDaysTotal),
                ageingNinetyDays: NumberFormat(ageingNinetyDaysTotal),
                ageingOneTwentyDays: NumberFormat(ageingOneTwentyDaysTotal),
                ageingSixtyDays: NumberFormat(ageingSixtyDaysTotal),
                ageingThirtyDays: NumberFormat(ageingThirtyDaysTotal),

                ageingCredit: NumberFormat(ageingCreditTotal),
                ageingTotalClientCreditsReceipts: NumberFormat(ageingTotalClientCreditsReceiptsTotal),
                ageingTotalClientDebits: NumberFormat(ageingTotalClientDebitsTotal),
                cummulativeAgeingOutstanding: NumberFormat(cummulativeAgeingOutstandingTotal),
                cummulativeOutstanding: NumberFormat(cummulativeOutstandingTotal),
                id: 'TOTAL',
                clientDetailsObj: {
                    docID: '',
                    docData: {}
                },
                ageingObj: {
                    thirtyDays: 0,
                    sixtyDays: 0,
                    ninetyDays: 0,
                    oneTwentyDays: 0,
                    aboveOneTwentyDays: 0,
                    credit: 0,
                    total: 0,
                }
            }


            setTotalsRow([totalsObj])

        } catch (error) { }
    }, [finalData])

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //################################################################
    //export to excel
    const columnsToExport = ['#', 'id', 'ageingThirtyDays', 'ageingSixtyDays', 'ageingNinetyDays', 'ageingOneTwentyDays', 'ageingMoreThanOneTwentyDays', 'ageingTotalClientDebits', 'ageingTotalClientCreditsReceipts', 'cummulativeAgeingOutstanding', 'cummulativeOutstanding']

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'Agewise Outstanding.csv',
            columnKeys: columnsToExport,
        });
    }, []);


    //################################################################
    // Example of consuming Grid Event    
    // const onSelectionChanged = () => {
    //     var selectedRows = gridRef.current.api.getSelectedRows();
    //     const a = []
    //     selectedRows.forEach(function (selectedRow) {
    //         a.push(selectedRow)
    //     });
    //     setSelectedRows(a);
    // };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }



    //################################################################
    // print stmt
    const [printStmtSelection, setPrintStmtSelection] = useState([])

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
            //ProcessClientStatementTransactions({ clientName: selectedRow.client, insurer: 'MAYFAIR INSURANCE COMPANY LIMITED' })
        });
        setPrintStmtSelection(a);
    };




    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', size: 'landscape' }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'id', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, maxWidth: 220,pinned: 'left', },
        {
            field: 'cummulativeAgeingOutstanding', headerName: 'Credit', sortable: false, filter: false, maxWidth: 90,
            cellRenderer: params => {
                if (params.value < 0) {
                    return <p>{params.value}</p>
                }
                else { return <p>0</p> }
            }
        },
        { field: 'ageingThirtyDays', headerName: '0 - 30 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingSixtyDays', headerName: '31 - 60 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingNinetyDays', headerName: '61 - 90 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingOneTwentyDays', headerName: '91 - 120 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingMoreThanOneTwentyDays', headerName: 'Over 120 Days', sortable: false, filter: false, maxWidth: 90, },
        { field: 'ageingTotalClientDebits', headerName: 'Total De', filter: false, },
        { field: 'ageingTotalClientCreditsReceipts', headerName: 'Total CR', filter: false, },
        { field: 'cummulativeAgeingOutstanding', headerName: 'A.O', filter: false, },
        { field: 'cummulativeOutstanding', headerName: 'C.S.O', filter: false,sort:'desc' },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        resizable: true, minWidth: 120,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine" ref={componentRef}>
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
                pinnedBottomRowData={totalsRow}
            ></AgGridReact>
            <div className={styles.overview_final_page_break}></div>
        </div>
    </section>

    //################################################################
    //stmt
    const display = () => {

        return (
            <section ref={componentRefStmt}>

                {
                    printStmtSelection.length > 0 ?
                        printStmtSelection.map((data, index) => {
                            if (data.hasOwnProperty('clientDetailsObj')) {

                                return (
                                    <span key={index} >
                                        <ClientStatementTemplate data={data} />
                                    </span>
                                )

                            }
                        })

                        : ''
                }

            </section>
        )

    }



    return (
        <section>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Agewise Outstanding</p>
                    <div className='cursor-pointer'>
                        <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                        <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded'>Print Table</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={handlePrintStmt}>Print Statement</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onBtnExport}>Export To Excel</p>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    {content}
                    {display()}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default OverviewFinal;