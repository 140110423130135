import React, { useState } from "react";
import Loader from "../../Body/Loader/Loader";
import axios from "axios";
import CapitalizedLetterOne from "../../Hooks/UI/CapitalizedLetterOne";
import { reloadOnSuccessExpenseAccountCreationHandler } from "../../../atoms";
import { useRecoilState } from "recoil";

const CreateExpenseAccount = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get user input
    const categories = ['Debit', 'Credit']
    const debitAccountTypes = ['Operation Expense', 'Administrative Expense', 'Employment Expense', 'Financial Expense', 'Other Expense']
    const creditAccountTypes = ['Bank Account', 'Other']
    const [account, setAccount] = useState('');
    const [type, setType] = useState('');
    const [category, setCategory] = useState('');

    //################################################################
    // recoil values
    const [reloadOnSuccessExpenseAccountCreationHandlerRN, setReloadOnSuccessExpenseAccountCreationHandlerRN] = useRecoilState(reloadOnSuccessExpenseAccountCreationHandler);

    //################################################################
    //create handler
    //step 2
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);
        setAccount('');
        setType('');
        setCategory('');

        const onePropObj = {
            setView: 'Finance Controls',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Expense Account Created.'
        }
        props.onePropDown(onePropObj);
        setReloadOnSuccessExpenseAccountCreationHandlerRN(true);
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Finance Controls',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Creating Expense Account.'
        }
        props.onePropDown(onePropObj);
    }

    //step 1
    //post data
    const createExpenseAccountHandler = () => {
        setIsLoading(true);

        if(!account || !type || !category){
            showErrorMSGOnExpenseSave();
            return false;
        }

        const quoteFinalObj = {
            account: CapitalizedLetterOne(account),
            category: category,
            type: type,
        }

        const CreateExpenseAPI = async () => {

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createexpenseaccount', quoteFinalObj)
                .then(response => {
                    //console.log(response)
                    // Handle the response data
                    //console.log(response.data);
                    showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    //console.log(error);
                    showErrorMSGOnExpenseSave();
                });
        }
        CreateExpenseAPI();

    }

    //################################################################
    //content 
    const createExpenseDebitAccountContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Create Expense Account</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setCategory(e.target.value) }} value={category}>
                    <option>Select Account Category</option>
                    {
                        categories.map((doc, index) => {
                            return (
                                <option key={index}>{doc}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Account Category</p></label>
            </div>
            <div className={`h_15 ${category === 'Debit'  ? 'block' : 'hidden'}`}></div>
            <div className={`input_group ${category === 'Debit' ? 'block' : 'hidden'}`}>
                <select className='select' onChange={(e) => { setType(e.target.value) }} value={type}>
                    <option>Select Account Type</option>
                    {
                        debitAccountTypes.map((doc, index) => {
                            return (
                                <option key={index}>{doc}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Account Type</p></label>
            </div>
            <div className={`h_15 ${category === 'Credit' ? 'block' : 'hidden'}`}></div>
            <div className={`input_group ${category === 'Credit' ? 'block' : 'hidden'}`}>
                <select className='select' onChange={(e) => { setType(e.target.value) }} value={type}>
                    <option>Select Account Type</option>
                    {
                        creditAccountTypes.map((doc, index) => {
                            return (
                                <option key={index}>{doc}</option>
                            )
                        })
                    }
                </select>
                <label className='placeholder'><p>Account Type</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={account || ''} onChange={(e) => { setAccount(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Account Name</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={createExpenseAccountHandler} className='btn_pink'>Submit</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {createExpenseDebitAccountContent}
            {loaderContent}
        </section>
    );
}

export default CreateExpenseAccount;