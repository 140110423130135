import React, { useState, useEffect, useRef } from 'react';
import GetInsurerReceipts from '../../Hooks/BackendConn/InsurerSpecific/GetInsurerReceipts';
import Loader from '../Loader/Loader';
import { useRecoilValue } from 'recoil';
import { useReactToPrint } from 'react-to-print';
import { agentStatementFilterFromDate, agentStatementFilterToDate, activeInsurerPortfolio } from '../../../atoms';
import './AgentStatement.css';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import DateFormat from '../../Hooks/UI/DateFormat';

const AgentStatement = () => {
    //################################################################
    //recoil state
    const agentStatementFilterFromDateRN = useRecoilValue(agentStatementFilterFromDate)
    const agentStatementFilterToDateRN = useRecoilValue(agentStatementFilterToDate)
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get agennt receipt
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetInsurerReceipts('SIMIA INSURANCE AGENCY LIMITED');
            setReceiptDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //filter insurer
    const [insurerFilteredReceiptDocs, setInsurerFilteredReceiptDocs] = useState([])
    useEffect(() => {
        try {

            const list = receiptDocs.filter(doc => doc.docData.insurerID === activeInsurerPortfolioRN);
            setInsurerFilteredReceiptDocs(list);

        } catch (error) { }
    }, [receiptDocs, activeInsurerPortfolioRN])

    //################################################################
    //brought forward feature
    const [BFReceiptDocs, setBFReceiptDocs] = useState([]);
    useEffect(() => {
        const BFReceipts = [];
        const PeriodSelected = [];

        insurerFilteredReceiptDocs.map((doc, index) => {
            //declare filter date
            let filterDate = '';
            switch (doc.docData.transactionType) {
                case 'Receipt':
                    filterDate = new Date(doc.docData.receiptDate);
                    filterDate.setHours(0, 0, 0);
                    break;
            }
            //sort into array
            if (new Date(agentStatementFilterFromDateRN).valueOf() > new Date(filterDate).valueOf() && doc.docData.transactionType === 'Receipt') {
                BFReceipts.push(doc.docData.receiptAmount);
            }
            else if (new Date(filterDate).valueOf() >= new Date(agentStatementFilterFromDateRN).valueOf() && new Date(filterDate).valueOf() <= new Date(agentStatementFilterToDateRN).valueOf()) {
                PeriodSelected.push(doc);
            }
        })

        const BFTotalReceipts = BFReceipts.reduce((total, item) => { return total + +item }, 0)
        const BFTotalOutstanding = BFTotalReceipts

        //get BF
        const BTDetails = {
            policyNet: '',
            receiptAmount: '',
            cummulativeOutstanding: BFTotalOutstanding,
            docData: {
                transactionType: 'Brought Forward',
                insurerDebitDate: agentStatementFilterFromDateRN,
                policyNumber: 'BF',
            },
        }

        PeriodSelected.unshift(BTDetails);
        setBFReceiptDocs(PeriodSelected);

    }, [insurerFilteredReceiptDocs, agentStatementFilterFromDateRN, agentStatementFilterToDateRN])

    //################################################################
    // sort by date
    const [dateSortedReceiptDocs, setDateSortedReceiptDocs] = useState([]);
    useEffect(() => {
        const sortedList = BFReceiptDocs.sort(byDate);
        function byDate(a, b) {
            function ab(doc) {
                //declare filter date
                let filterDate = '';
                switch (doc.docData.transactionType) {
                    case 'Receipt':
                        filterDate = new Date(doc.docData.receiptDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                    case 'Brought Forward':
                        filterDate = new Date(doc.docData.insurerDebitDate);
                        filterDate.setHours(0, 0, 0);
                        break;
                }
                return filterDate;
            }
            return new Date(ab(a)).valueOf() - new Date(ab(b)).valueOf();
        }
        setDateSortedReceiptDocs(sortedList);
    }, [BFReceiptDocs]);

    //################################################################
    //calculate outstanding
    const [calculatedOutstandingReceiptDocs, setCalculatedOutstandingReceiptDocs] = useState([]);
    useEffect(() => {
        if (dateSortedReceiptDocs.length > 0) {
            for (let i = 1; i < dateSortedReceiptDocs.length; i++) {
                const previousIndex = i - 1;

                if (dateSortedReceiptDocs[0].docData.transactionType === 'Receipt') {
                    dateSortedReceiptDocs[0].cummulativeOutstanding = dateSortedReceiptDocs[0].docData.receiptAmount
                }
                else if (dateSortedReceiptDocs[0].docData.transactionType === 'Brought Forward') {
                    dateSortedReceiptDocs[0].cummulativeOutstanding = dateSortedReceiptDocs[0].cummulativeOutstanding;
                }
                if (dateSortedReceiptDocs[i].docData.transactionType === 'Receipt' && dateSortedReceiptDocs.length > 1) {
                    dateSortedReceiptDocs[i].cummulativeOutstanding = +dateSortedReceiptDocs[previousIndex].cummulativeOutstanding + +dateSortedReceiptDocs[i].docData.receiptAmount
                }
            }
        }
        setCalculatedOutstandingReceiptDocs(dateSortedReceiptDocs);
    }, [dateSortedReceiptDocs])

    //################################################################
    //search
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedReceiptDocs, setSearchedReceiptDocs] = useState([]);

    useEffect(() => {
        const a = calculatedOutstandingReceiptDocs.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.transactionType == 'Receipt') {
                if (doc.docData.clientName[0].toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
                else if (doc.docData.receiptNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return doc;
                }
            }
        })
        setSearchedReceiptDocs(a)
    }, [calculatedOutstandingReceiptDocs, searchTerm])

    //################################################################
    //echo
    const agentStatementContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Client Name / Receipt No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='agent_statement_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Receipt Number</p></td>
                                <td><p>Client Name</p></td>
                                <td><p>Receipt Amount</p></td>
                                <td><p>Cummulative Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedReceiptDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{index + 1}</p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType === 'Receipt'
                                                    && DateFormat(doc.docData.receiptDate)
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType === 'Brought Forward'
                                                        ? 'BF'
                                                        : doc.docData.receiptNumber
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType != 'Brought Forward'
                                                    && doc.docData.clientName.length > 0
                                                    && doc.docData.clientName[0]

                                                }
                                            </p></td>
                                            <td><p>{doc.docData.receiptAmount && NumberFormat(doc.docData.receiptAmount)}</p></td>
                                            <td><p>{doc.cummulativeOutstanding && NumberFormat(doc.cummulativeOutstanding)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo
    const agentStatementContentPrintable = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='agent_statement_table' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Direct Collections - Unremmitted</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {DateFormat(agentStatementFilterFromDateRN)} - {DateFormat(agentStatementFilterToDateRN)}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Date</p></td>
                                <td><p>Receipt Number</p></td>
                                <td><p>Client Name</p></td>
                                <td><p>Receipt Amount</p></td>
                                <td><p>Cummulative Outstanding</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedReceiptDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{index + 1}</p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType === 'Receipt'
                                                    && DateFormat(doc.docData.receiptDate)
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType === 'Brought Forward'
                                                        ? 'BF'
                                                        : doc.docData.receiptNumber
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.transactionType != 'Brought Forward'
                                                    && doc.docData.clientName.length > 0
                                                    && doc.docData.clientName[0]

                                                }
                                            </p></td>
                                            <td><p>{doc.docData.receiptAmount && NumberFormat(doc.docData.receiptAmount)}</p></td>
                                            <td><p>{doc.cummulativeOutstanding && NumberFormat(doc.cummulativeOutstanding)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Direct Collections Unremmitted</p>
                    <div className='right_side_controls'>
                        <span className='btn_pink' onClick={handlePrint}>
                            <i className="ri-printer-line"></i>
                        </span>
                    </div>
                </div>
                <div className='body_section'>
                    {agentStatementContent}
                    {agentStatementContentPrintable}
                </div>
            </section>
        </section>
    );
}

export default AgentStatement;