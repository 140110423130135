import React, { useState, useEffect, useRef } from 'react';
import GetAllMotor from '../../Hooks/BackendConn/GeneralList/GetAllMotor';
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import DateFormat from '../../Hooks/UI/DateFormat';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { logbookReportFilterType, logbookReportFilterFromDate, logbookReportFilterToDate } from '../../../atoms';
import './LogBookReport.css'

const LogBookReport = () => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //get recoil values
    const logbookReportTypeFilterRN = useRecoilValue(logbookReportFilterType)
    const logbookReportFilterFromDateRN = useRecoilValue(logbookReportFilterFromDate)
    const logbookReportFilterToDateRN = useRecoilValue(logbookReportFilterToDate)

    //################################################################
    //get all motor
    const [motorDocs, setMotorDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllMotor();
            setMotorDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //remove endor n credits
    const [cleanDocs, setCleanDocs] = useState([]);
    useEffect(() => {
        const removedEndorsmentsList = [];
        motorDocs.map((item) => {
            if (!item.docData.endorsmentNumber.startsWith('05/')
                && !item.docData.endorsmentNumber.startsWith('07/')
                && item.docData.status !== 'Lapsed'
                && item.docData.status !== 'Cancelled'
                && item.docData.businessType !== 'Nil Endorsment') {
                removedEndorsmentsList.push(item);
            }
        });
        setCleanDocs(removedEndorsmentsList)
    }, [motorDocs])

    //################################################################
    //extract individual veh
    const [individualVehicle, setIndividualVehicle] = useState([])
    useEffect(() => {
        const vehicles = []
        cleanDocs.map((doc) => {
            Object.values(doc.docData.vehicleItems).map((vehicle) => {
                vehicles.push({ docData: doc.docData, docID: doc.docID, vehicle: vehicle })
            })
        });
        setIndividualVehicle(vehicles)
    }, [cleanDocs])

    //################################################################
    //filter type
    const [typeFilteredLogbooks, setTypeFilteredLogbooks] = useState([])
    useEffect(() => {
        const a = [];
        individualVehicle.map((doc) => {
            if (logbookReportTypeFilterRN === 'All') { a.push(doc) }
            else if (logbookReportTypeFilterRN === 'Logbook Present' && doc.docData.motorDocuments) {
                doc.docData.motorDocuments.map((item) => {
                    if (doc.vehicle.regNo == item.documentVehiclePlateNo && item.documentCategory === 'Logbook') {
                        a.push(doc)
                    }
                })
            }
            // else if (logbookReportTypeFilterRN === 'Logbook Missing' && doc.docData.motorDocuments) {
            //     if (doc.docData.motorDocuments.filter(item => doc.vehicle.regNo == item.documentVehiclePlateNo && item.documentCategory === 'Logbook').length == 0) {
            //         a.push(doc)
            //     }
            // }
            // else if (logbookReportTypeFilterRN === 'Logbook Missing' && !doc.docData.motorDocuments) {
            //     a.push(doc)
            // }
            else if (logbookReportTypeFilterRN === 'Logbook Missing') {
                if (doc.docData.motorDocuments) {
                    if (doc.docData.motorDocuments.filter(item => doc.vehicle.regNo.trim() == item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook').length == 0) {
                        a.push(doc)
                    }
                }
                else { a.push(doc) }
            }
        });
        setTypeFilteredLogbooks(a)
    }, [individualVehicle, logbookReportTypeFilterRN])

    //################################################################
    //sort by date
    const [dateSortedVehicles, setDateSortedVehicles] = useState([]);
    useEffect(() => {
        const sortedList = typeFilteredLogbooks.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.inceptionDate).valueOf() - new Date(a.docData.inceptionDate).valueOf();
        }
        setDateSortedVehicles(sortedList);
    }, [typeFilteredLogbooks])

    //################################################################
    //filter date
    const [dateFilteredVehicles, setDateFilteredVehicles] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        dateSortedVehicles.map((doc) => {
            if (new Date(doc.docData.inceptionDate).valueOf() >= new Date(logbookReportFilterFromDateRN).valueOf() && new Date(logbookReportFilterToDateRN).valueOf() >= new Date(doc.docData.inceptionDate).valueOf()) {
                periodSelected.push(doc);
            }
        })
        setDateFilteredVehicles(periodSelected)
    }, [dateSortedVehicles, logbookReportFilterFromDateRN, logbookReportFilterToDateRN]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedVehicles, setSearchedVehicles] = useState([])
    useEffect(() => {
        const c = [];
        const values = dateFilteredVehicles.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.vehicle.regNo.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedVehicles(c);
    }, [dateFilteredVehicles, searchTerm])

    //################################################################
    //paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedVehicles.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm, logbookReportTypeFilterRN, logbookReportFilterFromDateRN, logbookReportFilterToDateRN]);

    function sortLogbookByDate(a, b) {
        return new Date(b.documentUploadDate.seconds * 1000).valueOf() - new Date(a.documentUploadDate.seconds * 1000).valueOf();
    }

    function sortPolicyDocsByDate(a, b) {
        return new Date(b.documentUploadDate.seconds * 1000).valueOf() - new Date(a.documentUploadDate.seconds * 1000).valueOf();
    }

    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // content
    const logbookReportContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Policy No. / Endorsment No. / Veh Plate No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='logbook_report_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Chassis</p></td>
                                <td><p>Body</p></td>
                                <td><p>Passangers</p></td>
                                <td><p>Tare Weight</p></td>
                                <td><center><p>LB</p></center></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedVehicles.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.endorsmentNumber}</p></td>
                                            <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td><p>{doc.vehicle.regNo}</p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleChassis
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleBody
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehiclePassengers
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleTareWeight
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.associateDocuments
                                                        ? doc.docData.associateDocuments
                                                            .filter(item => item.documentUploadCategory === 'Policy Document')
                                                            .sort(sortPolicyDocsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedVehicles.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // printable content
    const logbookReportPrintableContent = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='logbook_report_table' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Log Book Report</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {DateFormat(logbookReportFilterFromDateRN)} - {DateFormat(logbookReportFilterToDateRN)}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Chassis</p></td>
                                <td><p>Body</p></td>
                                <td><p>Passangers</p></td>
                                <td><p>Tare Weight</p></td>
                                <td><center><p>LB</p></center></td>
                                <td><center><p>PD</p></center></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedVehicles.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.endorsmentNumber}</p></td>
                                            <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td><p>{doc.vehicle.regNo}</p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleChassis
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleBody
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehiclePassengers
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        item.documentVehicleTareWeight
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.motorDocuments
                                                        ? doc.docData.motorDocuments
                                                            .filter(item => doc.vehicle.regNo.trim() === item.documentVehiclePlateNo.trim() && item.documentCategory === 'Logbook')
                                                            .sort(sortLogbookByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                            <td><center><p>
                                                {
                                                    doc.docData.associateDocuments
                                                        ? doc.docData.associateDocuments
                                                            .filter(item => item.documentUploadCategory === 'Policy Document')
                                                            .sort(sortPolicyDocsByDate)
                                                            .map((item, index) => {
                                                                if (index == 0) {
                                                                    return (
                                                                        <span key={index} onClick={() => openInNewTab(item.documentDownloadLink)} className='policy_search_list_doc_icon_small'><i className="ri-attachment-2"></i></span>
                                                                    )
                                                                }
                                                            })
                                                        : ''
                                                }
                                            </p></center></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='logbook_report body_input_styles'>
            <div className='body_section_header'>
                <p>Log Books Report</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {logbookReportContent}
                {logbookReportPrintableContent}
            </div>
            {loaderContent}
        </section>
    );
}

export default LogBookReport;