import React, { useState, useEffect } from 'react';
import { doc, updateDoc, arrayUnion, addDoc, collection, runTransaction, getDoc } from 'firebase/firestore';
import db from '../../../firebase';
import GetAllDebit from '../../Hooks/BackendConn/GeneralList/GetAllDebits';
import GetAllReceipts from '../../Hooks/BackendConn/GeneralList/GetAllReceipts';
import GetAllCredits from '../../Hooks/BackendConn/GeneralList/GetAllCredits';
import DatePicker from 'react-date-picker';
import DateFormat from '../../Hooks/UI/DateFormat';
import Loader from '../Loader/Loader';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rightNavContent, selectInsurerPostNewReceipt, activeInsurerPortfolio } from '../../../atoms';
import './BodyPostNewReceipt.css';
import { async } from '@firebase/util';

const BodyPostNewReceipt = (props) => {
    //################################################################
    // recoil values
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent);
    const selectInsurerPostNewReceiptRN = useRecoilValue(selectInsurerPostNewReceipt);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio)

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    const [isloadedOne, setLoadedOne] = useState(false);
    useEffect(() => {
        if (!isloadedOne) {
            (async () => {
                setIsLoading(true)
                const DDocs = await GetAllDebit();
                setAllDebitDocs(DDocs);
                setLoadedOne(true);
                if (DDocs) {
                    setIsLoading(false)
                }
                else { setReload(Math.random(0, 1000)) }
            })();
        }
        return () => { }
    }, [reload]);

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    const [isloadedTwo, setLoadedTwo] = useState(false);
    useEffect(() => {
        if (!isloadedTwo) {
            (async () => {
                setIsLoading(true)
                const DDocs = await GetAllReceipts();
                setReceiptDocs(DDocs)
                setLoadedTwo(true);
                if (DDocs) {
                    setIsLoading(false)
                }
                else { setReloadTwo(Math.random(0, 1000)) }
            })();
        }
        return () => { }
    }, [reloadTwo]);

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllCredits();
            setCreditDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 1000)) }
        })();
    }, [reloadThree]);

    //################################################################
    //create fields in debit
    const [editableDebitDocs, setEditableDebitDocs] = useState([]);
    const [nonEditableDebitDocs, setNonEditableDebitDocs] = useState([]);
    useEffect(() => {
        const editableDebitDoc = []
        debitDocs.map((doc) => {
            editableDebitDoc.push({ docData: doc.docData, docID: doc.docID, policyCredits: [], receiptNumbers: [], policyCreditsDetails: [], receivedAllocatedAmount: 0 });
        });
        setEditableDebitDocs(editableDebitDoc)
        setNonEditableDebitDocs(editableDebitDoc)
    }, [debitDocs])

    //################################################################
    //allocate credits
    useEffect(() => {
        receiptDocs.map((item) => {
            Object.values(item.docData.receiptAllocationDetails).map((value) => {
                editableDebitDocs.map((val, index) => {
                    if (value.receivedDocID === val.docID) {
                        const Values = [...editableDebitDocs];
                        if (Values[index]['receiptNumbers'].indexOf(item.docData.receiptNumber) == -1) {
                            Values[index]['receiptNumbers'].push(item.docData.receiptNumber);
                            Values[index]['policyCredits'].push(value.receivedAllocatedAmount);
                            const a = item.docData.receiptNumber + ' - ' + DateFormat(item.docData.receiptDate) + ' - ' + value.receivedAllocatedAmount
                            Values[index]['policyCreditsDetails'].push(a);
                            setEditableDebitDocs(Values);
                        }
                    }
                })
            });
        });

        creditDocs.map((item) => {
            editableDebitDocs.map((val, index) => {
                if (item.docData.allocatedDocID === val.docID) {
                    const Values = [...editableDebitDocs];
                    if (Values[index]['receiptNumbers'].indexOf(item.docID) == -1) {
                        Values[index]['receiptNumbers'].push(item.docID);
                        Values[index]['policyCredits'].push(item.docData.policyNet);
                        const a = item.docData.policyNumber + ' - ' + item.docData.endorsmentNumber + ' - ' + item.docData.policyNet;
                        Values[index]['policyCreditsDetails'].push(a);
                        setEditableDebitDocs(Values);
                    }
                }
            });
        });
    }, [receiptDocs, debitDocs, creditDocs, editableDebitDocs])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = editableDebitDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.inceptionDate).valueOf() - new Date(b.docData.inceptionDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [editableDebitDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '' && searchTerm.length > 5) { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase()) && searchTerm.length > 5) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase()) && searchTerm.length > 5) {
                return val;
            }
            else if (val.docData.endorsmentNumber.toLowerCase().includes(searchTerm.toLowerCase()) && searchTerm.length > 5) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);


    //################################################################
    //################################################################
    //get user input
    const [receiptDate, setReceiptDate] = useState(new Date());
    const [oldreceiptDateFormat, setReceiptDateFormat] = useState(new Date());
    const [paidTo, setPaidTo] = useState('')
    const [receiptAmount, setReceiptAmount] = useState(0)
    const [receiptNumber, setReceiptNumber] = useState('')
    const [paidBy, setPaidBy] = useState('')

    //################################################################
    //old receipt date format
    const Ryear = new Date(receiptDate).getFullYear();
    const Rmonth = new Date(receiptDate).getMonth() + 1;
    const Rdate = new Date(receiptDate).getDate();
    const receiptDateCleaned = Ryear + '-' + Rmonth + '-' + Rdate;

    useEffect(() => {
        setReceiptDateFormat(receiptDateCleaned)
    }, [receiptDateCleaned])

    //################################################################
    //################################################################

    //################################################################
    //step 2
    //allocations
    const [selectedDebitsForAllocation, setSelectedDebitsForAllocation] = useState([])

    const addSelectedDebitsForAllocation = (doc) => {
        setSelectedDebitsForAllocation([...selectedDebitsForAllocation, doc])
    }

    //################################################################
    //update array to include allocated amount
    const onAllocatedAmountChange = (index, event) => {
        const selectedDebitsForAllocationValues = [...selectedDebitsForAllocation];
        selectedDebitsForAllocationValues[index][event.target.name] = event.target.value;
        setSelectedDebitsForAllocation(selectedDebitsForAllocationValues);
    }

    //################################################################
    //calculate total allocated
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
    useEffect(() => {
        let totalAmountAllocated = 0;
        selectedDebitsForAllocation.forEach(item => {
            totalAmountAllocated += +item.receivedAllocatedAmount;
        });
        setTotalAllocatedAmount(totalAmountAllocated);
    }, [selectedDebitsForAllocation]);

    //################################################################
    //calculate remaining amount
    const [remainingAmount, setRemainingAmount] = useState(0);
    useEffect(() => {
        const rem = +receiptAmount - +totalAllocatedAmount;
        setRemainingAmount(rem);
    }, [totalAllocatedAmount, receiptAmount])


    //################################################################
    //########################### post receipt #######################
    //################################################################
    const openReceiptListOnSuccessPost = (docID) => {
        const onePropObj = {
            setView: 'View Receipt',
            previousView: 'Post New Receipt',
            postedDocRef: docID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Receipt Posted Successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //step 2
    //post receipt
    async function postReceiptHandler(receivedReceiptNumber) {
        //set receipt number
        let processedReceiptNumber = ''
        if (selectInsurerPostNewReceiptRN === 'SIMIA INSURANCE AGENCY LIMITED') {
            processedReceiptNumber = receivedReceiptNumber
        }
        else { processedReceiptNumber = receiptNumber }

        //create obj
        const receiptAllocationBreakDown = {
            receiptNumber: processedReceiptNumber,
            receiptDate: receiptDate,
            receiptAmount: receiptAmount
        }

        //get client nameS
        const allocatedClients = []
        selectedDebitsForAllocation.map((doc) => {
            allocatedClients.push(doc.docData.clientName);
        })

        //create receipt allocation details
        const receiptAllocationDetails = []
        selectedDebitsForAllocation.map((doc) => {
            const a = {
                receivedAllocatedAmount: doc.receivedAllocatedAmount,
                receivedClientName: doc.docData.clientName,
                receivedDocID: doc.docID,
                receivedEndorsmentNumber: doc.docData.endorsmentNumber,
                receivedOutstanding: '',
                receivedPolicyNet: doc.docData.policyNet,
                receivedPolicyNumber: doc.docData.policyNumber,
            }
            receiptAllocationDetails.push(a);
        });

        //create obj
        const processedReceiptData = {
            clientName: allocatedClients,
            receiptDate: oldreceiptDateFormat,
            newReceiptDate: receiptDate,
            receiptNumber: processedReceiptNumber,
            receiptAmount: receiptAmount,
            paidBy: paidBy,
            paidTo: selectInsurerPostNewReceiptRN,
            transactionType: 'Receipt',
            receiptAllocationDetails: receiptAllocationDetails,
            insurerID: activeInsurerPortfolioRN,
        }

        //update data
        try {
            setIsLoading(true);
            selectedDebitsForAllocation.forEach(async (item) => {
                // Create an initial document to update.
                const policyDocRef = doc(db, "PolicyDetails", item.docID);

                // To update age and favorite color:
                await updateDoc(policyDocRef, {
                    'receiptAllocationBreakDown': arrayUnion(receiptAllocationBreakDown)
                });
            });
            setIsLoading(false);
        } catch (error) {
            console.error("Error updating document: ", error);
        }

        //post data
        try {
            setIsLoading(true);
            const docRef = await addDoc(collection(db, "PolicyDetails"), processedReceiptData);
            //console.log("Document written with ID: ", docRef.id);
            //ActivityLog({ activity: 'Receipt Posting', affectedDoc: docRef.id });
            setIsLoading(false);
            openReceiptListOnSuccessPost(docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    //################################################################
    //step 1
    //get simia receipt number
    async function processSRN() {

        if (selectInsurerPostNewReceiptRN === 'SIMIA INSURANCE AGENCY LIMITED') {

            const sfDocRef = doc(db, "SystemControls", "57j4Ju1V7OvwBjGP0gyi");

            try {
                setIsLoading(true);
                let qwe = await runTransaction(db, async (transaction) => {
                    const sfDoc = await transaction.get(sfDocRef);
                    if (!sfDoc.exists()) {
                        throw "Document does not exist!";
                    }

                    const newReceiptCount = sfDoc.data().ReceiptnumberCount + 1;
                    transaction.update(sfDocRef, { ReceiptnumberCount: newReceiptCount });
                    const newReceiptCountLength = newReceiptCount.toString().length;

                    let SRSuffix = '';

                    switch (true) {
                        case (newReceiptCountLength == 1):
                            SRSuffix = '000';
                            break;
                        case (newReceiptCountLength == 2):
                            SRSuffix = '00';
                            break;
                        case (newReceiptCountLength == 3):
                            SRSuffix = '0';
                            break;
                        case (newReceiptCountLength >= 4):
                            SRSuffix = '';
                            break;
                    }

                    const qw = 'SR' + SRSuffix + newReceiptCount;
                    return qw
                });

                //console.log("Transaction successfully committed!");
                setIsLoading(false);
                if (qwe) {
                    postReceiptHandler(qwe)
                }

            } catch (e) {
                //console.log("Transaction failed: ", e);
            }

        }
        else { postReceiptHandler() }
    }


    //################################################################
    //################################################################
    //################################################################
    //allocations
    const allocationsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'><p>Receipt Allocation Details</p></div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='post_new_receipt_allocations_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Credit Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Policy Credits</p></td>
                                <td><p>Outstanding Before Allocation</p></td>
                                <td><p>Outstanding After Allocation</p></td>
                                <td><p>Allocated Amount</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedDebitsForAllocation.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{index + 1}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.endorsmentNumber}</p></td>
                                            <td><p>
                                                {
                                                    doc.policyCreditsDetails.map(item => (item + ', ## '))
                                                }
                                            </p></td>
                                            <td><p>{doc.docData.policyNet}</p></td>
                                            <td><p>{doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                            <td><p>{parseInt(doc.docData.policyNet) - doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                            <td><p>{parseInt(doc.docData.policyNet) - doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0) - doc.receivedAllocatedAmount}</p></td>
                                            <td><input value={doc.receivedAllocatedAmount} onChange={event => onAllocatedAmountChange(index, event)} name='receivedAllocatedAmount' /></td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan='8'><p></p></td>
                                <td><p>Allocated Amount</p></td>
                                <td><p>{totalAllocatedAmount}</p></td>
                            </tr>
                            <tr>
                                <td colSpan='8'><p></p></td>
                                <td><p>Remaining Amount</p></td>
                                <td><p>{remainingAmount}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //step 1
    //search content
    const searchPolicyPostNewReceiptContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={searchTermChangeHandler} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Debit By Client Name / Policy No. / Endorsment No.</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    {searchedPolicyDocs.length > 0 &&
                        <table className='search_policy_post_new_receipt_table'>
                            <thead>
                                <tr>
                                    <td><p>No</p></td>
                                    <td><p>Name</p></td>
                                    <td><p>Policy Number</p></td>
                                    <td><p>Endorsment Number</p></td>
                                    <td><p>Policy Class</p></td>
                                    <td><p>Inception Date</p></td>
                                    <td><p>Credits Details</p></td>
                                    <td><p>Policy Net</p></td>
                                    <td><p>Policy Credits</p></td>
                                    <td><p>Outstanding</p></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    searchedPolicyDocs.map((doc, index) => {
                                        return (
                                            <tr key={index} onClick={() => { addSelectedDebitsForAllocation(doc) }}>
                                                <td><p>{doc.id}</p></td>
                                                <td><p>{doc.docData.clientName}</p></td>
                                                <td><p>{doc.docData.policyNumber}</p></td>
                                                <td><p>{doc.docData.endorsmentNumber}</p></td>
                                                <td><p>{doc.docData.selectedProductSubClass}</p></td>
                                                <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                                <td><p>
                                                    {
                                                        doc.policyCreditsDetails.map(item => (item + ', ## '))
                                                    }
                                                </p></td>
                                                <td><p>{doc.docData.policyNet}</p></td>
                                                <td><p>{doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                                <td><p>{parseInt(doc.docData.policyNet) - doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)}</p></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //received by
    const receivedByContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Receiver Details</p>
                <div className='client_details_controls'>
                    <div className='btn_pink' onClick={() => { setRightNavContentRV('Select Insurer') }}>
                        <i className="ri-search-2-line"></i>
                    </div>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <div className='input_group'>
                        <input value={selectInsurerPostNewReceiptRN} readOnly type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Paid To</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //receipt number
    let receiptNumberContent = <section>
        <div className='h_15'></div>
        <div className='input_group'>
            <input onChange={(e) => { setReceiptNumber(e.target.value) }} type='text' className='input' placeholder='' />
            <label className='placeholder'><p>Receipt Number</p></label>
        </div>
    </section>

    if (selectInsurerPostNewReceiptRN === 'SIMIA INSURANCE AGENCY LIMITED') {
        receiptNumberContent = ''
    }

    //################################################################
    //receipt details
    const receiptDetailsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Receipt Details</p>
            </div>
            <div className='category_content'>
                <div className='category_content_one'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setReceiptAmount(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Receipt Amount</p></label>
                    </div>
                    {receiptNumberContent}
                    <div className='date_picker_container'>
                        <div className='date_container'>
                            <div className='w_5'></div>
                            <p>Receipt Date</p>
                            <div className='w_10'></div>
                            <DatePicker onChange={setReceiptDate} value={receiptDate} format='d/ M/y' />
                        </div>
                    </div>
                </div>
                <div className='category_content_two'>
                    <div className='h_5'></div>
                    <div className='input_group'>
                        <input onChange={(e) => { setPaidBy(e.target.value) }} type='text' className='input' placeholder='' />
                        <label className='placeholder'><p>Paid By</p></label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='body_post_new_receipt body_input_styles'>
            <div className='body_section_header'>
                <p>Receipt Posting</p>
                <button onClick={processSRN} type='submit' className='btn_pink'>Submit</button>
            </div>
            <div className='body_section'>
                {receivedByContent}
                {receiptDetailsContent}
                {searchPolicyPostNewReceiptContent}
                {allocationsContent}

            </div>
            {loaderContent}
        </section>
    );
}

export default BodyPostNewReceipt;