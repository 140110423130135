import db from "../../../../firebase";
import { collection, getDocs, where, query } from 'firebase/firestore';

const GetPoliciesByClientName = async (propsClientName) => {
    const q = query(collection(db, "PolicyDetails"), where("clientName", "==", propsClientName));

    const CData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        // assign date
        let UDate = '';
        let EDate = '';
        let echoDate = '';
        let filterDate = '';
        let policyClientName = '';
        if (doc.data().transactionType === 'Receipt') {
            UDate = doc.data().receiptDate;
            const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
            const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
            const year = new Date(UDate).getFullYear();
            echoDate = day + '-' + month + '-' + year;
            //to be used to filter
            const fYear = new Date(UDate).getFullYear();
            const fMonth = new Date(UDate).getMonth() + 1;
            const fDate = new Date(UDate).getDate();
            filterDate = fYear + '-' + fMonth + '-' + fDate
        }
        else if (doc.data().transactionType === 'Debit') {
            UDate = doc.data().insurerDebitDate;
            EDate = doc.data().inceptionDate;
            const day = new Date(EDate).toLocaleString('en-us', { day: '2-digit' });
            const month = new Date(EDate).toLocaleString('en-us', { month: 'short' });
            const year = new Date(EDate).getFullYear();
            echoDate = day + '-' + month + '-' + year;
            //to be used to filter
            const fYear = new Date(UDate).getFullYear();
            const fMonth = new Date(UDate).getMonth() + 1;
            const fDate = new Date(UDate).getDate();
            filterDate = fYear + '-' + fMonth + '-' + fDate
        }
        else if (doc.data().transactionType === 'Credit') {
            UDate = doc.data().inceptionDate;
            const day = new Date(UDate).toLocaleString('en-us', { day: '2-digit' });
            const month = new Date(UDate).toLocaleString('en-us', { month: 'short' });
            const year = new Date(UDate).getFullYear();
            echoDate = day + '-' + month + '-' + year;
            //to be used to filter
            const fYear = new Date(UDate).getFullYear();
            const fMonth = new Date(UDate).getMonth() + 1;
            const fDate = new Date(UDate).getDate();
            filterDate = fYear + '-' + fMonth + '-' + fDate
        }
        const PC = [];
        const allPolicyDocs = {
            docID: doc.id,
            clientName: doc.data().clientName,
            policyNumber: doc.data().policyNumber,
            endorsmentNumber: doc.data().endorsmentNumber,
            selectedProductSubClass: doc.data().selectedProductSubClass,
            inceptionDate: doc.data().inceptionDate,
            policyNet: doc.data().policyNet,
            outstanding: doc.data().outstanding,
            transactionType: doc.data().transactionType,
            locationOfTheRisk: doc.data().locationOfTheRisk,
            vehicleItems: doc.data().vehicleItems,
            receiptAllocationBreakDown: doc.data().receiptAllocationBreakDown,
            status: doc.data().status,

            receiptDate: doc.data().receiptDate,
            receiptNumber: doc.data().receiptNumber,
            receiptAmount: doc.data().receiptAmount,
            receiptAllocationDetails: doc.data().receiptAllocationDetails,

            uDate: UDate,
            echoDate: echoDate,
            filterDate: filterDate,
            cummulativeOutstanding: '',
            realTimeOutstanding: doc.data().policyNet,
            policyCredits: PC
        }
        CData.push(allPolicyDocs);
    })

    return CData;
}

export default GetPoliciesByClientName;