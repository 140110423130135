import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loader from '../Loader/Loader';
import Paginate from '../BodyPolicy/Paginate';
import '../../Body/BodySectionModule.css';
import './BodyClientPortfolio.css';
import GetAllClientGroups from '../../Hooks/BackendConn/ClientList/GetAllClientGroups';

const BodyClientPortfolio = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }


    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    //################################################################
    //get client groups
    const [clientGroups, setClientGroups] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetAllClientGroups();
            setClientGroups(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    // get policy data
    const [receivedPolicyDocs, setReceivedPolicyDocs] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        const fetchData = async () => {
            setIsLoading(true);
            const querySnapshot = await getDocs(collection(db, "ClientDetails"));
            if (!isCancelled) {
                const Rdata = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    const allClientDocs = {
                        clientID: doc.id,
                        clientName: doc.data().clientName,
                        IDNumber: doc.data().IDNumber,
                        passportNumber: doc.data().passportNumber,
                        KRAPin: doc.data().KRAPin,
                        occupation: '',
                        phoneNumber: doc.data().phoneNumber,
                        email: doc.data().email,
                        address: doc.data().address,
                        contactPerson: doc.data().contactPerson,
                        group: doc.data().group,
                    }
                    Rdata.push(allClientDocs);
                    setIsLoading(false);
                },
                    setReceivedPolicyDocs(Rdata)
                );
            }
        }
        fetchData();
        return () => {
            isCancelled = true;
        }
    }, []);

    // sort alphabet
    const [sortedClientDocs, setSortedClientDocs] = useState([]);
    useEffect(() => {
        const sortedList = receivedPolicyDocs.sort(byName);
        function byName(a, b) {
            if (a.clientName > b.clientName) { return 1 }
            else if (b.clientName > a.clientName) { return -1 }
            else { return 0 }
        }
        setSortedClientDocs(sortedList);
    }, [receivedPolicyDocs]);

    //searched
    const [searchedClientDocs, setSearchedClientsDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = sortedClientDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedClientsDocs(c)

    }, [sortedClientDocs, searchTerm]);

    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedClientDocs.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm])

    const viewCreatedPolicyOnSuccessPosting = (postedDocRef) => {
        props.onSuccessPolicyCreate({ setView: 'Edit Client', postedDocRef: postedDocRef });
    }

    //################################################################
    //step 3
    //show cancelled msg
    const viewClientProfile = (postedDocRef) => {
        const onePropObj = {
            setView: 'Edit Client',
            previousView: 'Client Portfolio',
            postedDocRef: postedDocRef,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //check group
    const checkGroup = (clientGroup) => {
        const a = clientGroups.filter(group => group.docID === clientGroup)
        if (a.length > 0) {
            return a[0].docData.groupTitle;
        }
        else { return '' }
    }

    //render
    const allClientContent = <section>
        <div>
            <table className='all_clients_table'>
                <thead>
                    <tr>
                        <td><p>No</p></td>
                        <td><p>Name</p></td>
                        <td><p>Phone Number</p></td>
                        <td><p>Email</p></td>
                        <td><p>KRA Pin</p></td>
                        <td><p>Address</p></td>
                        <td><p>Group</p></td>
                        <td><p>Contact Person</p></td>
                    </tr>
                </thead>
                <tbody>
                    {searchedClientDocs.map((item, index) => {
                        return (
                            <tr key={index} onClick={() => viewClientProfile(item)}>
                                <td><p>{item.id}</p></td>
                                <td><p>{item.clientName}</p></td>
                                <td><p>{item.phoneNumber}</p></td>
                                <td><p>{item.email}</p></td>
                                <td><p>{item.KRAPin}</p></td>
                                <td><p>{item.address}</p></td>
                                <td><p>{checkGroup(item.group)}</p></td>
                                <td><p>{item.contactPerson}</p></td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
            <Paginate postsPerPage={postsPerPage} totalPosts={searchedClientDocs.length} paginate={paginate} />
        </div>
    </section>

    //#################################################################################################
    //#################################################################################################
    //#################################################################################################

    return (
        <section>
            <div className='body_section_header'>
                <p>Client List</p>
            </div>
            <div className='body_section client_portfolio'>
                <div className='h_5'></div>
                <div className='category'>
                    <div className='category_header client_search_container'>
                        <input onChange={searchTermChangeHandler} placeholder='Search Client Name' />
                        <i className="ri-search-2-line"></i>
                    </div>
                    <div className='category_content'>
                        <div className='category_content_three'>
                            {allClientContent}
                        </div>
                    </div>
                </div>
            </div>
            {loaderContent}
        </section>
    )
}

export default BodyClientPortfolio;