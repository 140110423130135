const MotorCommTPOGeneralCartage = (receivedVehicleDetails) => {
    try {
        
        let premium = [];

        //premium
        if (receivedVehicleDetails.tareWeight <= 8) {
            premium.push(7500);
        }
        else if(receivedVehicleDetails.tareWeight >8 && receivedVehicleDetails.tareWeight <= 20){
            premium.push(20000);
        }
        else if(receivedVehicleDetails.tareWeight > 20 && receivedVehicleDetails.tareWeight <= 30){
            premium.push(25000);
        }
        else if(receivedVehicleDetails.tareWeight > 30){
            const diff = receivedVehicleDetails.tareWeight - 30;
            const over = diff * 500
            premium.push(25000 + over);
        }

        const p = premium.reduce((total, item) => { return total + +item }, 0);

        const updatedPremiumCalculation = { ...receivedVehicleDetails };
        updatedPremiumCalculation.premiumCharge = p;

        return (updatedPremiumCalculation)

    } catch (error) {}
}

export default MotorCommTPOGeneralCartage;