import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import Loader from '../Loader/Loader';
import GetCancelledPolicies from '../../Hooks/BackendConn/GeneralList/GetCancelledPolicies';
import { nanoid } from 'nanoid';
import Paginate from './Paginate';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { cancelledPolicyListInsurerFilter, cancelledPolicyListFilterFromDate, cancelledPolicyListFilterToDate, cancelledPolicyListFilterPolicyClass } from '../../../atoms';
import styles from './CancelledPolicyListFinal.module.css';
import DateFormat from '../../Hooks/UI/DateFormat';
import NumberFormat from '../../Hooks/UI/NumberFormat';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';


const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
};

const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = 'calc(100vh - 10rem)';
    api.setDomLayout();
};

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const CancelledPolicyListFinal = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page { 
                size: landscape !important; 
                margin: 10mm !important;
            }
          }`,
        content: () => componentRef.current,
    });


    //################################################################
    //recoil values
    const cancelledPolicyListInsurerFilterRN = useRecoilValue(cancelledPolicyListInsurerFilter)
    const cancelledPolicyListFilterFromDateRN = useRecoilValue(cancelledPolicyListFilterFromDate)
    const cancelledPolicyListFilterToDateRN = useRecoilValue(cancelledPolicyListFilterToDate)
    const cancelledPolicyListFilterPolicyClassRN = useRecoilValue(cancelledPolicyListFilterPolicyClass)

    //################################################################
    //get cancelled policies
    const [cancelledPolicyDocs, setCancelledPolicyDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetCancelledPolicies(cancelledPolicyListInsurerFilterRN);
            setCancelledPolicyDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload, cancelledPolicyListInsurerFilterRN]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        cancelledPolicyDocs.map((doc) => {
            //clean date
            const newInceptionDate = new Date(doc.docData.inceptionDate);
            newInceptionDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(newInceptionDate).valueOf() >= new Date(cancelledPolicyListFilterFromDateRN).valueOf() && new Date(cancelledPolicyListFilterToDateRN).valueOf() >= new Date(newInceptionDate).valueOf()) {
                periodSelected.push(doc);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [cancelledPolicyDocs, cancelledPolicyListFilterFromDateRN, cancelledPolicyListFilterToDateRN]);

    //################################################################
    //filter policy class
    const [filteredClassDocs, setFilteredClassDocs] = useState([])
    useEffect(() => {
        const a = selectedPeriodFilteredDocs.filter((doc) => {
            if (cancelledPolicyListFilterPolicyClassRN === 'ALL') { return doc }
            else if (cancelledPolicyListFilterPolicyClassRN === 'MOTOR') {
                if (doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial') {
                    return doc;
                }
            }
            else if (cancelledPolicyListFilterPolicyClassRN === 'NON-MOTOR') {
                if (doc.docData.selectedProductClass != '(07) - Motor Private' && doc.docData.selectedProductClass != '(08) - Motor Commercial') {
                    return doc;
                }
            }
        });
        setFilteredClassDocs(a);
    }, [selectedPeriodFilteredDocs, cancelledPolicyListFilterPolicyClassRN])

    //################################################################
    //final content
    const [finalContent, setFinalContent] = useState([])
    useEffect(() => {


        const a = []
        filteredClassDocs.map((doc) => {
            //look for details
            const details = []
            if (doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial') {
                if (doc.docData.vehicleItems) {
                    Object.values(doc.docData.vehicleItems).map((veh) => {
                        details.push(veh.regNo + ', ')
                    })
                }
            }
            else {
                details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
            }

            //look for pd
            const policyDocuments = [];
            if (doc.docData.associateDocuments) {
                Object.values(doc.docData.associateDocuments).map((item) => {
                    if (item.documentUploadCategory === 'Policy Document') {
                        policyDocuments.push(item.documentDownloadLink)
                    }
                })
            }

            a.push({
                client: doc.docData.clientName,
                policyNo: doc.docData.policyNumber,
                endorNo: doc.docData.endorsmentNumber,
                class: doc.docData.selectedProductSubClass,
                inception: DateFormat(doc.docData.inceptionDate),
                expiry: DateFormat(doc.docData.expiryDate),
                details: details,
                policyNet: NumberFormat(doc.docData.policyNet),
                pd: policyDocuments,
            })
        });
        setFinalContent(a)
    }, [filteredClassDocs])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = filteredClassDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.expiryDate).valueOf() - new Date(b.docData.expiryDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [filteredClassDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
            else if (doc.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm, cancelledPolicyListFilterPolicyClassRN]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //echo 
    const cancelledPolicyListContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Client Name / Policy Number</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='cancelled_policy_list_final_table' >
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPosts.map((doc) => {
                                    const policyDocuments = [];
                                    if (doc.docData.associateDocuments) {
                                        Object.values(doc.docData.associateDocuments).map((item) => {
                                            if (item.documentUploadCategory === 'Policy Document') {
                                                policyDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    return (
                                        <tr key={nanoid()}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{doc.docData.policyNumber}</p></td>
                                            <td><p>{doc.docData.endorsmentNumber}</p></td>
                                            <td><p>{doc.docData.selectedProductSubClass}</p></td>
                                            <td><p>{DateFormat(doc.docData.inceptionDate)}</p></td>
                                            <td><p>{DateFormat(doc.docData.expiryDate)}</p></td>
                                            <td><p>
                                                {
                                                    doc.docData.selectedProductClass === '(07) - Motor Private' || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                                                        ? doc.docData.vehicleItems
                                                        && Object.values(doc.docData.vehicleItems).map((veh) => {
                                                            return (
                                                                veh.regNo + ', '
                                                            )
                                                        })
                                                        : doc.docData.locationOfTheRisk.slice(0, 20) + ' ...'
                                                }
                                            </p></td>
                                            <td><p>{doc.docData.policyNet}</p></td>
                                            <td>
                                                {
                                                    policyDocuments.length > 0
                                                        ? policyDocuments.map((item, index) => {
                                                            return (
                                                                <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                            )
                                                        })
                                                        : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    const printHeaderContent = <section>
        <div className='print_only_component'>
            <center><p className='bold'>CANCELLED POLICIES</p></center>
            <div className='h_10'></div>
            <center><p className='bold'>PERIOD : {DateFormat(cancelledPolicyListFilterFromDateRN)} - {DateFormat(cancelledPolicyListFilterToDateRN)}</p></center>
            <div className='h_10'></div>
            <center><p className='bold'>INSURER : {cancelledPolicyListInsurerFilterRN}</p></center>
            <div className='h_10'></div>
            <center>
                <div className='print_only_component_policy_class_filter'>
                    <input type="checkbox" name="ALL" id="ALL" value="ALL" checked={cancelledPolicyListFilterPolicyClassRN === 'ALL' && 'checked'} readOnly />
                    <label htmlFor="ALL" className='bold'> ALL</label>
                    <i className='p-h_5'></i>
                    <input type="checkbox" name="MOTOR" id="MOTOR" value="MOTOR" checked={cancelledPolicyListFilterPolicyClassRN === 'MOTOR' && 'checked'} readOnly />
                    <label htmlFor="MOTOR" className='bold'> MOTOR</label>
                    <span className='p-h_5'></span>
                    <input type="checkbox" name="NON-MOTOR" id="NON-MOTOR" value="NON-MOTOR" checked={cancelledPolicyListFilterPolicyClassRN === 'NON-MOTOR' && 'checked'} readOnly />
                    <label htmlFor="NON-MOTOR" className='bold'> NON-MOTOR</label>
                </div>
            </center>
            <div className='h_10'></div>
        </div>
    </section>

    const footerContent = <section className='universal_company_footer'>
        <p className='bold'>SIMIA INSURANCE AGENCY LIMITED | P.O BOX 623 – 30100, ELDORET | TEL: +254 722 968 637 | EMAIL: info@simiainsurance.com</p>
    </section>


    tippy('#myButton', {
        content: '<p onclick={toolti}>My tooltip!</p>',
        interactive: true,
        trigger: 'click',
        placement: 'auto',
        allowHTML: true,
    });


    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', size: 'landscape' }), []);

    const [rowData, setRowData] = useState([filteredClassDocs]);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 50, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, },
        { field: 'policyNo' },
        { field: 'endorNo' },
        { field: 'class' },
        { field: 'inception', sortable: false, filter: false, maxWidth: 110, },
        { field: 'expiry', sortable: false, filter: false, maxWidth: 110, },
        { field: 'details' },
        { field: 'policyNet', sortable: false, filter: false, maxWidth: 90, },
        {
            field: 'pd', sortable: false, filter: false, maxWidth: 50,
            cellRenderer: params => {
                // console.log(params) 
                // put the value in bold
                //return 'Value is **' + params.value + '**';
                return <i onClick={() => openInNewTab(params.value)} className='ri-attachment-2'></i>
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        resizable: true, minWidth: 120,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //print step 2
    const onBtPrint = useCallback(() => {
        const api = gridRef.current.api;
        setPrinterFriendly(api);
        setTimeout(function () {

            handlePrint()

            setNormal(api);

            autoSizeAll(false)

        }, 2000);
    }, []);

    //################################################################
    //print step 1
    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        if (skipHeader === true) {
            onBtPrint()
        }
    }, []);

    //################################################################
    //export to excel
    const columnsToExport = ['#', 'client', 'policyNo', 'endorNo', 'class', 'inception', 'expiry', 'details', 'policyNet']

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'Cancelled Policies.csv',
            columnKeys: columnsToExport,
        });
    }, []);

    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
    }

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine" ref={componentRef}>
            {printHeaderContent}
            <AgGridReact
                ref={gridRef}
                rowData={finalContent}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'single'}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
        
            ></AgGridReact>
            {footerContent}
        </div>
    </section>

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span><p>View Policy</p></span>
        <div className=''></div>
    </div>

    return (
        <section className='cancelled_policy_list_final body_input_styles'>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Cancelled Policy List</p>
                    <div className='cursor-pointer'>
                        <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                        <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => autoSizeAll(true)}>Print</p>
                            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onBtnExport}>Export To Excel</p>
                        </div>
                    </div>
                </div>
                <div className='body_section'>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded'>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded'>Export To Excel</p>
                    </div>
                    {content}
                    {/* {cancelledPolicyListContent} */}
                </div>
            </section>
            {URRightClickMenu}
            {loaderContent}
        </section>
    );
}

export default CancelledPolicyListFinal;