import STDDeductibles from "./STDDeductibles";

const STDDeductiblesHandler = (fruit) => {   
  
    const logFruit = {
      '(020) - Contractors All Risks': () => STDDeductibles.ContractorsAllRisksDeductible,

      '(021) - ContractorsPlant and Machinery': () => STDDeductibles.ContractorsAllRisksDeductible,

      '(023) - Machinery Break Down': () => STDDeductibles.MachineryBreakDownDeductible,
      
      '(024) - Computer Electronic Equipment': () => STDDeductibles.ComputerElectronicEquipmentDeductible,

      '(030) - Domestic Package': () => STDDeductibles.DomesticPackageDeductible,

      '(031) - Domestic Contents': () => STDDeductibles.DomesticContentsDeductible,

      '(040) - Fire Industrial': () => STDDeductibles.FireIndustrialDeductible,

      '(041) - Con Loss Following Fire': () => STDDeductibles.ConLossFollowingFireDeductible,

      '(043) - Terrorism': () => STDDeductibles.TerrorismDeductible,

      '(051) - Professional Liability': () => STDDeductibles.ProfessionalLiabilityDeductible,
      
      '(054) - Contractual Liabilty': () => STDDeductibles.ContractualLiabiltyDeductible,

      '(062) - Goods in Transit (GIT)': () => STDDeductibles.GoodsInTransitDeductible,

      '(064) - CARRIERSLEGALLIABILITY': () => STDDeductibles.CARRIERSLEGALLIABILITYDeductible,

      '(100) - Cash in Transit': () => STDDeductibles.CashInTransitDeductible,

      '(101) - Fidelity Gurantee': () => STDDeductibles.FidelityGuranteeDeductible,

      '(102) - Burglary': () => STDDeductibles.BurglaryDeductible,

      '(103) - All Risk': () => STDDeductibles.AllRiskDeductible,

      '(112) - Wiba': () => STDDeductibles.WibaDeductible,

      '(113) - Common Law': () => STDDeductibles.CommonLawDeductible,  

      '(114) - Wiba Plus': () => STDDeductibles.WibaPlusDeductible,

      '(123) - PLATE GLASS': () => STDDeductibles.PLATEGLASSDeductible,
            
      'default': () => STDDeductibles.defaultValue,
    };
    
    return (logFruit[fruit] || logFruit['default'])();
    
  }
  
  export default STDDeductiblesHandler;
  
  
  
  
  
  
  
  
  
  
  
  
  
  