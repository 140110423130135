import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Worker from './UnrenewedReportGridFinal.Worker.js';
import Loader from "../Loader/Loader.js";
import { activeInsurerPortfolio, unrenewedReportFilterFromDate, unrenewedReportFilterToDate, } from "../../../atoms.js";
import { unrenewedReportFilterInsurer } from "../../../atoms.js";
import { useRecoilValue } from "recoil";
import GetAllInsurerTransactions from '../../Hooks/BackendConn/InsurerSpecific/GetAllInsurerTransactions';
import GetInsurerReceiptsInsurerID from "../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurerID.js";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const UnrenewedReportGridFinal = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTwo, setIsLoadingTwo] = useState(false);
    const [isLoadingThree, setIsLoadingThree] = useState(false);
    const [isLoadingFour, setIsLoadingFour] = useState(false);
    let loaderContent = '';
    if (isLoading === true || isLoadingTwo === true || isLoadingThree === true || isLoadingFour === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const unrenewedReportFilterFromDateRN = useRecoilValue(unrenewedReportFilterFromDate);
    const unrenewedReportFilterToDateRN = useRecoilValue(unrenewedReportFilterToDate);
    const unrenewedReportFilterInsurerRN = useRecoilValue(unrenewedReportFilterInsurer);

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerTransactions({ insurerName: unrenewedReportFilterInsurerRN, transactionType: 'Debit' });;
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get all credits
    const [creditDocs, setAllCreditDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoadingTwo(true)
            const DDocs = await GetAllInsurerTransactions({ insurerName: unrenewedReportFilterInsurerRN, transactionType: 'Credit' });;
            setAllCreditDocs(DDocs)
            if (DDocs) {
                setIsLoadingTwo(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo, unrenewedReportFilterInsurerRN]);

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoadingThree(true)
            const DDocs = await GetInsurerReceiptsInsurerID({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoadingThree(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree, activeInsurerPortfolioRN]);

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {
            setIsLoadingFour(true);
            var worker = new Worker();

            const data = {
                debitDocs: debitDocs,
                creditDocs: creditDocs,
                receiptDocs: receiptDocs,
                unrenewedReportFilterFromDateRN: unrenewedReportFilterFromDateRN,
                unrenewedReportFilterToDateRN: unrenewedReportFilterToDateRN,
            }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setIsLoadingFour(false);
                setFinalData(event.data);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [debitDocs, creditDocs, receiptDocs, unrenewedReportFilterFromDateRN, unrenewedReportFilterToDateRN]);
    console.log(finalData)
    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');


    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        // { field: 'transactionType', headerName: 'Voucher', width: 40 },
        // { field: 'businessType', headerName: 'Type', width: 40 },
        // {
        //     field: 'VR', headerName: 'VR', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'LB', headerName: 'LB', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'SC', headerName: 'SC', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
        // {
        //     field: 'PD', headerName: 'PD', sortable: false, filter: false, width: 50,
        //     cellRenderer: params => {
        //         return params.value.map((docLink, index) => {
        //             return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
        //         })
        //     }
        // },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Unrenewed Report</p>
                {/* <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div> */}
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default UnrenewedReportGridFinal;