import React, { useEffect, useState } from "react";
import Loader from "../../../Body/Loader/Loader";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import { useRecoilState, useRecoilValue } from "recoil";
import { rightNavContent, selectInsurer, selectInsurerPostNewReceipt, selectInsurerPostDV, selectInsurerViewDV, selectInsurerPostCN, selectInsurerViewCN, selectInsurerPostJV, selectInsurerViewJV } from "../../../../atoms";
import './SelectInsurer.css';

const SelectInsurer = (props) => {
    //################################################################
    //recoil state
    const rightNavContentRN = useRecoilValue(rightNavContent)
    const [selectInsurerRN, setSelectInsurerRN] = useRecoilState(selectInsurer)
    const [selectInsurerPostNewReceiptRN, setSelectInsurerPostNewReceiptRN] = useRecoilState(selectInsurerPostNewReceipt);
    const [selectInsurerPostDVRN, setSelectInsurerPostDVRN] = useRecoilState(selectInsurerPostDV)
    const [selectInsurerViewDVRN, setSelectInsurerViewDVRN] = useRecoilState(selectInsurerViewDV)
    const [selectInsurerPostCNRN, setSelectInsurerPostCNRN] = useRecoilState(selectInsurerPostCN)
    const [selectInsurerViewCNRN, setSelectInsurerViewCNRN] = useRecoilState(selectInsurerViewCN)
    const [selectInsurerPostJVRN, setSelectInsurerPostJVRN] = useRecoilState(selectInsurerPostJV)
    const [selectInsurerViewJVRN, setSelectInsurerViewJVRN] = useRecoilState(selectInsurerViewJV)

    //################################################################
    //selected insurer state
    const [selectedInsurerAccount, setSelectedInsurerAccount] = useState('')

    // ################################################################
    // configure selected insurer
    useEffect(() => {console.log(props.onePropUp.setView)
        if (props.onePropUp.setView) {
            switch (props.onePropUp.setView) {
                case 'Create Policy':
                    setSelectInsurerRN(selectedInsurerAccount);
                    break;
                case 'Post New Receipt':
                    setSelectInsurerPostNewReceiptRN(selectedInsurerAccount);
                    break;
                case 'Post DV':
                    setSelectInsurerPostDVRN(selectedInsurerAccount);
                    break;
                case 'View DV':
                    setSelectInsurerViewDVRN(selectedInsurerAccount);
                    break;
                case 'Post CN':
                    setSelectInsurerPostCNRN(selectedInsurerAccount);
                    break;
                case 'View CN':
                    setSelectInsurerViewCNRN(selectedInsurerAccount);
                    break;
                case 'Post JV':
                    setSelectInsurerPostJVRN(selectedInsurerAccount);
                    break;
                case 'View JV':
                    setSelectInsurerViewJVRN(selectedInsurerAccount);
                    break;
            }
        }
    }, [selectedInsurerAccount])

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    // search insurer
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedInsurer, setSearchedInsurer] = useState([])

    useEffect(() => {
        const insurer = InsurerDetails.filter((doc) => {
            if (searchTerm === '') { return doc }
            else if (doc.docData.insurer.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        });
        setSearchedInsurer(insurer);
    }, [InsurerDetails, searchTerm]);


    //################################################################
    // select insurer Content
    const selectInsurerContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Select Insurer</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setSearchTerm(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Search Insurer</p></label>
            </div>
            <div className='h_10'></div>
            <div>
                <ul className='select_insurer_list'>
                    {
                        searchedInsurer.map((doc, index) => {
                            return (
                                <li key={index} onClick={() => { setSelectedInsurerAccount(doc.docData.insurer) }}>
                                    <p onClick={() => { setSelectedInsurerAccount(doc.docData.insurer) }}>{doc.docData.insurer}</p>
                                </li>
                            )
                        })
                    }</ul>
            </div>
        </div>
    </section>

    return (
        <section className='select_insurer right_nav_container body_input_styles'>
            {selectInsurerContent}
            {loaderContent}
        </section>
    );
}

export default SelectInsurer;