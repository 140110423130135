import { useRecoilState } from "recoil";
import { logbookReportFilterType, logbookReportFilterFromDate, logbookReportFilterToDate } from "../../../atoms";
import DatePicker from "react-date-picker";

const LogBookReportFilter = () => {
    //################################################################
    //get recoil values
    const [logbookReportTypeFilterRN,setLogbookReportTypeFilterRN] = useRecoilState(logbookReportFilterType);
    const [logbookReportFilterFromDateRN,setLogbookReportFilterFromDateRN] = useRecoilState(logbookReportFilterFromDate);
    const [logbookReportFilterToDateRN,setLogbookReportFilterToDateRN] = useRecoilState(logbookReportFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { setLogbookReportTypeFilterRN(e.target.value) }} value={logbookReportTypeFilterRN}>
                        <option>Select Filter</option>
                        <option>All</option>
                        <option>Logbook Present</option>
                        <option>Logbook Missing</option>
                    </select>
                    <label className='placeholder'><p>Report Filter</p></label>
                </div>
                {/* <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setLogbookReportFilterFromDateRN} value={logbookReportFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setLogbookReportFilterToDateRN} value={logbookReportFilterToDateRN} format='d/ M/y' />
                </div> */}
            </div>
        </section>
    )
}

export default LogBookReportFilter;