import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { updateDoc, doc } from "firebase/firestore";
import db from "../../../firebase";
import GetAllEmployeeDetails from "../../Hooks/BackendConn/HR/GetAllEmployeeDetails";
import Loader from "../Loader/Loader";
import GetCookie from "../../Hooks/Cookie/GetCookie";
import GetAccessControlMenuItems from "../../Hooks/BackendConn/Dashboard/GetAccessControlMenuItems";

import pdfMake, { async } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomHeader from "../CustomHeader";
import "../GridExample.css";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
  return params.node ? params.node.rowIndex + 1 : null;
};

const AccessControl = (props) => {
  //################################################################
  //current user
  const currentUser = JSON.parse(GetCookie("CurrentUserSession"));

  //################################################################
  //loading
  const [isLoading, setIsLoading] = useState(false);
  let loaderContent = "";
  if (isLoading === true) {
    loaderContent = <Loader />;
  }

  // get all employees
  const [allEmployees, setAllEmployees] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAllEmployeeDetails();
      setAllEmployees(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReload(Math.random(0, 10000));
      }
    })();
  }, [reload]);

  //################################################################
  //filter active employee
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  useEffect(() => {
    try {
      const list = allEmployees.filter(
        (doc) => doc.docData.employeeStatus === "Active"
      );
      setFilteredEmployees(list);
    } catch (error) { }
  }, [allEmployees]);

  // Get employeeId
  // const [employeeIndexPosition, setEmployeeIndexPosition] = useState();
  // useEffect(() => {
  //   try {
  //       const employee = allEmployees.filter(
  //         (doc) => doc.docID ===
  //       )
  //   } catch(error) {}

  // }, [allEmployees])

  //################################################################
  // get all employees
  const [accessControlMenuItems, setAccessControlMenuItems] = useState({});
  const [reloadTwo, setReloadTwo] = useState(0);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const DDocs = await GetAccessControlMenuItems();
      setAccessControlMenuItems(DDocs);
      if (DDocs) {
        setIsLoading(false);
      } else {
        setReloadTwo(Math.random(0, 10000));
      }
    })();
  }, [reloadTwo]);

  //################################################################
  //final
  const [finalData, setFinalData] = useState([]);
  useMemo(() => {
    try {
      const finalObj = [];
      filteredEmployees.map((employee, index) => {
        Object.keys(accessControlMenuItems).forEach((key, indexx) => {
          const array = accessControlMenuItems[key];
          const mappedArray = array.map((item, index) => {
            if (indexx == 0) {
              if (index == 0) {
                finalObj.push({
                  employeeName: employee.docData.name,
                  employeeId: employee.docID,
                  menuItem: key,
                  menuSubItem: item,
                  accessGranted: false,
                  hiddenEmployeeName: employee.docData.name,
                  hiddenEmployeeId: employee.docID,
                  hiddenMenuItem: key,
                  hiddenMenuSubItem: item,
                });
              } else {
                finalObj.push({
                  employeeName: "",
                  employeeId: employee.docID,
                  menuItem: '',
                  menuSubItem: item,
                  accessGranted: false,
                  hiddenEmployeeName: employee.docData.name,
                  hiddenEmployeeId: employee.docID,
                  hiddenMenuItem: key,
                  hiddenMenuSubItem: item,
                });
              }
            }
            else {
              if (index == 0) {
                finalObj.push({
                  employeeName: '',
                  employeeId: employee.docID,
                  menuItem: key,
                  menuSubItem: item,
                  accessGranted: false,
                  hiddenEmployeeName: employee.docData.name,
                  hiddenEmployeeId: employee.docID,
                  hiddenMenuItem: key,
                  hiddenMenuSubItem: item,
                });
              } else {
                finalObj.push({
                  employeeName: "",
                  employeeId: employee.docID,
                  menuItem: '',
                  menuSubItem: item,
                  accessGranted: false,
                  hiddenEmployeeName: employee.docData.name,
                  hiddenEmployeeId: employee.docID,
                  hiddenMenuItem: key,
                  hiddenMenuSubItem: item,
                });
              }
            }

          });

        });
      });

      setFinalData(finalObj);
    } catch (error) { }
  }, [filteredEmployees, accessControlMenuItems]);

  //################################################################
  // change state of final
  const onFinalDataItemChangeHandler = (index, event) => {
    try {
      setFinalData((data) => {
        const finalDataValues = [...data];
        finalDataValues[index][event.target.name] = event.target.checked;
        setFinalData(finalDataValues);
      })
    } catch (error) { }
  };

  //################################################################
  //step 3
  //show msg
  const showSuccessMSGOnExpenseSave = () => {
    setIsLoading(false);

    const onePropObj = {
      setView: 'Access Control',
      previousView: '',
      postedDocRef: '',
      dateFrom: '',
      dateTo: '',
      searchTerm: '',
      notification: 'Update Successfully.'
    }
    props.onePropDown(onePropObj);
  }

  //################################################################
  //step 2
  // functions consuming grid state
  const updateEmployeeAccessControls = async (receivedData) => {
    try {

      const washingtonRef = doc(db, "EmployeeDetails", "hsPhw4lkUuDuR8jgABkH");

      const objKey = receivedData.employeeID + '.accessControl';

      await updateDoc(washingtonRef, {
        [objKey]: receivedData.menu
      });
      
    } catch (error) { }
  }

  //################################################################
  // step 1
  const updateEmployeeAccessControlsHandler = () => {
    try {

      setIsLoading(true);
      const groupedAsPerEmployee = finalData.reduce((acc, item) => {
        if (!acc[item.employeeId]) {
          acc[item.employeeId] = [];
        }
        acc[item.employeeId].push(item);
        return acc;
      }, {});

      const aa = Object.keys(groupedAsPerEmployee).map((employee) => {
        const addddd = groupedAsPerEmployee[employee].reduce((acc, item) => {
          if (!acc[item.hiddenMenuItem]) {
            acc[item.hiddenMenuItem] = [];
          }
          acc[item.hiddenMenuItem].push(item);
          return acc;
        }, {});
        return addddd;
      })

      aa.map((doc) => {
        const menuItems = [];
        let menuSubItems = [];
        let employeeID = ''
        const we = Object.keys(doc).reduce((accc, item) => {
          const subItems = [];
          const as = doc[item].reduce((acc, val) => {
            acc[val.hiddenMenuSubItem] = val.accessGranted;
            employeeID = val.hiddenEmployeeId;
            return acc;
          }, {}); 
          accc[item] = as;
          return accc;
        }, {})

        updateEmployeeAccessControls({ menu: we, employeeID: employeeID });
        
      })

      setIsLoading(false);
      showSuccessMSGOnExpenseSave()

    } catch (error) { console.log(error) }
  }



  //################################################################
  // grid states
  const [rightClickedPolicyID, setRightClickedPolicyID] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [xPos, setxPos] = useState("-1000px");
  const [yPos, setyPos] = useState("-1000px");

  // Print All
  const printAll = () => {
    const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.employeeName,
                doc.menuItem,
                doc.menuSubItem,
                " ",
            ])
        });

        const onlyDispatchedheaderData = 'ACCESS CONTROL ' 

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['auto', 110, 110, 110, 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {}],
                            ['NO', 'EMPLOYEE NAME', 'MENU ITEM', 'MENU SUB ITEM', 'GRANT ACCESS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
  }

  //################################################################
  // Example of consuming Grid Event
  const onSelectionChanged = () => {
    var selectedRows = gridRef.current.api.getSelectedRows();
    const a = [];
    selectedRows.forEach(function (selectedRow) {
      a.push(selectedRow);
    });
    setSelectedRows(a);
  };

  const onCellContextMenu = (event) => {
    setxPos(event.event.x);
    setyPos(event.event.y);
    setRightClickedPolicyID(event.data.docID);
  };

  const onCellClicked = () => {
    setxPos("-1000px");
    setyPos("-1000px");
  };

  const onFilterChanged = () => {
    const data = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      data.push(rowNode.data);
    });
    setExportData(data);
  };

  const onSortChanged = () => {
    const data = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      data.push(rowNode.data);
    });
    setExportData(data);
  };

  const onGridReady = () => {
    const data = [];
    gridRef.current.api.forEachNode((rowNode, index) => {
      data.push(rowNode.data);
    });
    setExportData(data);
  };

  //################################################################
  //AG GRID

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "calc(100vh - 10rem)", width: "100%" }), []);

  //const [rowData, setRowData] = useState(...finalData);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#", maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
      cellRenderer: (params) => {
        return (
          <div>
            <p id="myButton" className="peer rounded">
              {params.value}
            </p>
          </div>
        );
      },
    },
    { field: "employeeName", headerName: "Employee Name", checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: "left", sortable: false, filter: false, },
    { field: "menuItem", headerName: "Menu Item", width: 150, sortable: false, filter: false, },
    { field: "menuSubItem", headerName: "Menu Sub Item", width: 150, sortable: false, filter: false, },
    {
      field: "accessGranted", headerName: "Access Granted", width: 150, sortable: false, filter: false, cellRenderer: (params) => {
        return (
          <div>
            <input type="checkbox" onChange={(event) => onFinalDataItemChangeHandler(params.rowIndex, event)} name="accessGranted" />
          </div>
        );
      },
    },

  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 120,
      resizable: true,
    }),
    []
  );

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.expandAll();
  }, []);

  //################################################################
  //content
  const content = (
    <section style={containerStyle}>
      <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={finalData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          groupDisplayType={"groupRows"}
          onFirstDataRendered={onFirstDataRendered}
          components={components}
          columnHoverHighlight={true}
          enableCellTextSelection={true}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onSelectionChanged={onSelectionChanged}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          onRowDataUpdated={onGridReady}
          onCellContextMenu={onCellContextMenu}
          preventDefaultOnContextMenu={true}
          onCellClicked={onCellClicked}
        ></AgGridReact>
      </div>
    </section>
  );

  return (
    <section className='policy_list_grid'>
      <div className='body_section_header'>
        <p>Access Control</p>
        <div className='cursor-pointer'>
          <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
          <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
            {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={updateEmployeeAccessControlsHandler}>Save Details</p>
            <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
          </div>
        </div>
      </div>
      <div className='body_section'>
        {content}
      </div>
      {loaderContent}
      {/* {URRightClickMenu} */}
    </section>
  );
};

export default AccessControl;
