import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { profitAndLossFilterYear } from "../../../atoms";
import { YearPickerInput } from "@mantine/dates";

const ProfitAndLossYearFilter = () => {
    //################################################################
    //receiol values
    const [profitAndLossFilterYearRN, setProfitAndLossFilterYearRN] = useRecoilState(profitAndLossFilterYear)

    //################################################################
    //echo
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <YearPickerInput
                    dropdownType="modal"
                    label="Pick Year"
                    placeholder="Pick Year"
                    value={profitAndLossFilterYearRN}
                    onChange={setProfitAndLossFilterYearRN}
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    mx="auto"
                    maw={400}
                />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {content}
        </section>
    )
}

export default ProfitAndLossYearFilter;