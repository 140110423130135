import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { productivityReportFilterFromDate, productivityReportFilterToDate } from "../../../../atoms";
import { DatePickerInput } from "@mantine/dates";

const ProductivityReportFilter = () => {
    //################################################################
    //recoil values
    const [productivityReportFilterFromDateRN, setProductivityReportFilterFromDateRN] = useRecoilState(productivityReportFilterFromDate)
    const [productivityReportFilterToDateRN, setProductivityReportFilterToDateRN] = useRecoilState(productivityReportFilterToDate)

    //################################################################
    //echo
    const insurerStatementFinalContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Filter Date</p>
            <div className='h_5'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="From Date"
                    placeholder="From Date"
                    value={productivityReportFilterFromDateRN}
                    onChange={setProductivityReportFilterFromDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="To Date"
                    placeholder="To Date"
                    value={productivityReportFilterToDateRN}
                    onChange={setProductivityReportFilterToDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
            {/* <div className='lapse_date_picker_container'>
                <p>From</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyDocumentsReportFilterFromDateRN} value={policyDocumentsReportFilterFromDateRN} format='d/ M/y' />
            </div>
            <div className='h_10'></div>
            <div className='lapse_date_picker_container'>
                <p>To</p>
                <div className='h_5'></div>
                <DatePicker onChange={setPolicyDocumentsReportFilterToDateRN} value={policyDocumentsReportFilterToDateRN} format='d/ M/y' />
            </div> */}
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {insurerStatementFinalContent}
        </section>
    )
}

export default ProductivityReportFilter;