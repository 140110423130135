import React from "react";
import DatePicker from "react-date-picker";
import { useRecoilState } from "recoil";
import { closedClaimsFilterFromDate, closedClaimsFilterToDate, } from "../../../atoms";


const ClosedClaimsDateFilter = () => {
    //#####################################################################
    //get recoil state
    const [closedClaimsFilterFromDateRN,setClosedClaimsFilterFromDateRN] = useRecoilState(closedClaimsFilterFromDate)
    const [closedClaimsFilterToDateRN,setClosedClaimsFilterToDateRN] = useRecoilState(closedClaimsFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div>
                    <div className='h_10'></div>
                    <p className='bold'>Filter Date</p>
                    <div className='h_5'></div>
                    <div className='lapse_date_picker_container'>
                        <p>From</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setClosedClaimsFilterFromDateRN} value={closedClaimsFilterFromDateRN} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='lapse_date_picker_container'>
                        <p>To</p>
                        <div className='h_5'></div>
                        <DatePicker onChange={setClosedClaimsFilterToDateRN} value={closedClaimsFilterToDateRN} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClosedClaimsDateFilter;