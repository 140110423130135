import { useEffect, useState } from "react";
import styles from './PayrollSummary.module.css'
import NumberFormat from "../../Hooks/UI/NumberFormat";

const PayrollSummary = (props) => {
    //################################################################
    //create payroll
    function getMonthInWords(date) {
        try {

            const months = [
                "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
                "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
            ];

            const monthIndex = new Date(date).getMonth();
            return months[monthIndex];

        } catch (error) { }
    }

    //################################################################
    //enrty
    const [finalData, setFinalData] = useState([]);
    const [finalNHIFStatutoryDeduction, setFinalNHIFStatutoryDeduction] = useState([]);
    const [finalNSSFStatutoryDeduction, setFinalNSSFStatutoryDeduction] = useState([]);
    const [finalPAYEStatutoryDeduction, setFinalPAYEStatutoryDeduction] = useState([]);
    const [finalHousingLevyStatutoryDeduction, setFinalHousingLevyStatutoryDeduction] = useState([]);
    const [finalNITAStatutoryDeduction, setFinalNITAStatutoryDeduction] = useState([]);
    const [payrollMonthFilterRN, setPayrollMonthFilterRN] = useState([]);
    const [narration, setNarration] = useState('');
    useEffect(() => {
        try {

            setFinalData(props.finalData);
            setFinalNHIFStatutoryDeduction(props.finalNHIFStatutoryDeduction);
            setFinalNSSFStatutoryDeduction(props.finalNSSFStatutoryDeduction);
            setFinalPAYEStatutoryDeduction(props.finalPAYEStatutoryDeduction);
            setFinalHousingLevyStatutoryDeduction(props.finalHousingLevyStatutoryDeduction);
            setFinalNITAStatutoryDeduction(props.finalNITAStatutoryDeduction);
            setPayrollMonthFilterRN(props.payrollMonthFilterRN);

            const narrationn = getMonthInWords(payrollMonthFilterRN) + ' ' + new Date(payrollMonthFilterRN).getFullYear();
            setNarration(narrationn);

        } catch (error) { }
    }, [props]);


    //################################################################
    //totals    
    const [totalNetPay, setTotalNetpay] = useState(0);
    const [totalNHIF, setTotalNHIF] = useState(0);
    const [totalNSSF, setTotalNSSF] = useState(0);
    const [totalPAYE, setTotalPAYE] = useState(0);
    const [totalHousingLevy, setTotalHousingLevy] = useState(0);
    const [totalNITA, setTotalNITA] = useState(0);
    useEffect(() => {
        try {

            if (Array.isArray(finalData)) {
                const totalNP = finalData.reduce((accumulator, currentObject) => {
                    return accumulator + currentObject.netPayAmount;
                }, 0);
                setTotalNetpay(totalNP)
            }
            if (Array.isArray(finalNHIFStatutoryDeduction)) {
                const a = finalNHIFStatutoryDeduction.find(doc => doc.name === 'NHIF Total');
                setTotalNHIF(a.amount);
            }
            if (Array.isArray(finalNSSFStatutoryDeduction)) {
                const a = finalNSSFStatutoryDeduction.find(doc => doc.name === 'NSSF Total');
                setTotalNSSF(a.amount);
            }
            if (Array.isArray(finalPAYEStatutoryDeduction)) {
                const a = finalPAYEStatutoryDeduction.find(doc => doc.name === 'PAYE Total');
                setTotalPAYE(a.amount);
            }
            if (Array.isArray(finalHousingLevyStatutoryDeduction)) {
                const a = finalHousingLevyStatutoryDeduction.find(doc => doc.name === 'HOUSING LEVY Total');
                setTotalHousingLevy(a.amount);
            }
            if (Array.isArray(finalNITAStatutoryDeduction)) {
                const a = finalNITAStatutoryDeduction.find(doc => doc.name === 'NITA Total');
                setTotalNITA(a.amount);
            }


        } catch (error) { }
    }, [finalData]);

    //################################################################
    //content
    const content = <section className={styles.payroll_summary_main_section}>
        <section className='f_6'>
            <p className="text-center bold">PAYROLL FOR THE MONTH OF {narration}</p>
            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>SUMMARY</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>CATEGORY</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>NET PAY</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalNetPay)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>PAYE</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalPAYE)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>NSSF</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalNSSF)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>NHIF</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalNHIF)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>HOUSING LEVY</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalHousingLevy)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>NITA</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalNITA)}
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p>TOTAL</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            {NumberFormat(totalNetPay + totalPAYE + totalNSSF + totalNHIF + totalHousingLevy + totalNITA)}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>NET PAY</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalData)
                        && finalData.map((doc, index) => {
                            try {

                                return (
                                    <tr key={index}>
                                        <td className='w-1/2 border border-slate-600 p-1'>
                                            <p>{doc.name}</p>
                                        </td>
                                        <td className='w-1/2 bold border border-slate-600 p-1'>
                                            {NumberFormat(doc.netPayAmount)}
                                        </td>
                                    </tr>
                                )

                            } catch (error) { }
                        })
                    }
                    {

                        <tr>
                            <td className='w-1/2 border border-slate-600 p-1'>
                                <p>Total Net Pay</p>
                            </td>
                            <td className='w-1/2 bold border border-slate-600 p-1'>
                                {NumberFormat(totalNetPay)}
                            </td>
                        </tr>
                    }

                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>PAYE STATUTORY DEDUCTIONS</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalPAYEStatutoryDeduction)
                        && finalPAYEStatutoryDeduction.map((doc, index) => {
                            try {

                                if (!isNaN(doc.amount)) {
                                    return (
                                        <tr key={index}>
                                            <td className='w-1/2 border border-slate-600 p-1'>
                                                <p>{doc.name}</p>
                                            </td>
                                            <td className='w-1/2 bold border border-slate-600 p-1'>
                                                {NumberFormat(doc.amount)}
                                            </td>
                                        </tr>
                                    )
                                }

                            } catch (error) { }
                        })
                    }

                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>NSSF STATUTORY DEDUCTIONS</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalNSSFStatutoryDeduction)
                        && finalNSSFStatutoryDeduction.map((doc, index) => {
                            try {

                                if (!isNaN(doc.amount)) {
                                    return (
                                        <tr key={index}>
                                            <td className='w-1/2 border border-slate-600 p-1'>
                                                <p>{doc.name}</p>
                                            </td>
                                            <td className='w-1/2 bold border border-slate-600 p-1'>
                                                {NumberFormat(doc.amount)}
                                            </td>
                                        </tr>
                                    )
                                }

                            } catch (error) { }
                        })
                    }

                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>NHIF STATUTORY DEDUCTIONS</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalNHIFStatutoryDeduction)
                        && finalNHIFStatutoryDeduction.map((doc, index) => {

                            if (!isNaN(doc.amount)) {
                                return (
                                    <tr key={index}>
                                        <td className='w-1/2 border border-slate-600 p-1'>
                                            <p>{doc.name}</p>
                                        </td>
                                        <td className='w-1/2 bold border border-slate-600 p-1'>
                                            {NumberFormat(doc.amount)}
                                        </td>
                                    </tr>
                                )
                            }

                        })

                    }

                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>HOUSING LEVY STATUTORY DEDUCTIONS</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalHousingLevyStatutoryDeduction)
                        && finalHousingLevyStatutoryDeduction.map((doc, index) => {

                            if (!isNaN(doc.amount)) {
                                return (
                                    <tr key={index}>
                                        <td className='w-1/2 border border-slate-600 p-1'>
                                            <p>{doc.name}</p>
                                        </td>
                                        <td className='w-1/2 bold border border-slate-600 p-1'>
                                            {NumberFormat(doc.amount)}
                                        </td>
                                    </tr>
                                )
                            }


                        })
                    }

                </tbody>
            </table>

            <div className="h_15"></div>

            <table className='w-full table-fixed border-collapse border border-slate-500'>
                <thead>
                    <tr>
                        <td colSpan={2} className='bold border border-slate-600 px-1 py-2'>
                            <p className='bold'>NITA STATUTORY DEDUCTIONS</p>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-1/2 border border-slate-600 p-1'>
                            <p className='bold'>NAME</p>
                        </td>
                        <td className='w-1/2 bold border border-slate-600 p-1'>
                            <p className='bold'>AMOUNT</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(finalNITAStatutoryDeduction)
                        && finalNITAStatutoryDeduction.map((doc, index) => {

                            if (!isNaN(doc.amount)) {
                                return (
                                    <tr key={index}>
                                        <td className='w-1/2 border border-slate-600 p-1'>
                                            <p>{doc.name}</p>
                                        </td>
                                        <td className='w-1/2 bold border border-slate-600 p-1'>
                                            {NumberFormat(doc.amount)}
                                        </td>
                                    </tr>
                                )
                            }

                        })
                    }

                </tbody>
            </table>

        </section>
    </section >

    return (content)
}

export default PayrollSummary;