import React, { useState } from "react"
import db from "../../../../firebase";
import { doc, runTransaction } from "firebase/firestore";
import Loader from "../../../Body/Loader/Loader";
import GetCookie from "../../../Hooks/Cookie/GetCookie";
import UUID from "../../../Hooks/UUID/UUID";
import { useRecoilState } from "recoil";
import { pageReloaderHandler } from "../../../../atoms";

const CreateClientGroup = (props) => {
    //################################################################
    //recoil state
    const [pageReloaderHandlerRN, setPageReloaderHandlerRN] = useRecoilState(pageReloaderHandler)

    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get user input
    const [groupTitle, setGroupTitle] = useState('');
    const [contactPerson, setContactperson] = useState('');

    //################################################################
    //step 2
    // change view
    const changeViewOnSuccessfulClientGroupCreation = () => {
        setGroupTitle('');
        setContactperson('');

        const onePropObj = {
            setView: 'Client Group',
            previousView: 'Client Group',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Client Group Created Successfully'
        }
        props.onePropDown(onePropObj);
        setPageReloaderHandlerRN(onePropObj);

    }

    //################################################################
    //step 1
    // post data to db
    const creteClientGroupSubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        //get unique id
        const claimID = UUID();

        //claim obj
        const clientGroupProcessedData = {
            groupTitle: groupTitle,
            groupMembers: {},
            contactPerson: contactPerson,
            documents: {},
            createdBy: currentUser,
            dateCreated: new Date(),
        }

        //post data
        const sfDocRef = doc(db, "ClientGroups", "PvnnCxssBmfIt6BL6i4P");

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                //get unique id
                const clientGroupID = UUID();

                transaction.update(sfDocRef, { [clientGroupID]: clientGroupProcessedData });
            });
            setIsLoading(false);
            changeViewOnSuccessfulClientGroupCreation();
            //console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    //################################################################
    // crete insurer Content
    const createClientGroupContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <p className='bold'>Create Client Group</p>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={groupTitle || ''} onChange={(e) => { setGroupTitle(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Group Title</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input value={contactPerson || ''} onChange={(e) => { setContactperson(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Contact Person</p></label>
            </div>
            <div className='h_5'></div>
            <button onClick={creteClientGroupSubmitHandler} className='btn_pink'>Submit</button>
        </div>
    </section>


    return (
        <section className='right_nav_container body_input_styles'>
            {createClientGroupContent}
            {loaderContent}
        </section>
    );
}

export default CreateClientGroup;