import React from "react";
import { useRecoilState } from "recoil";
import { emailClientStatementFilterFromDate, emailClientStatementFilterToDate } from "../../../../atoms";
import { DatePickerInput } from "@mantine/dates";

const EmailClientStatementFilterDate = () => {
    //################################################################
    //valuation reports filter
    const [emailClientStatementFilterFromDateRN, setEmailClientStatementFilterFromDateRN] = useRecoilState(emailClientStatementFilterFromDate)
    const [emailClientStatementFilterToDateRN, setEmailClientStatementFilterToDateRN] = useRecoilState(emailClientStatementFilterToDate)

    //################################################################
    //echo
    const content = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="From Date"
                    placeholder="From Date"
                    value={emailClientStatementFilterFromDateRN}
                    onChange={setEmailClientStatementFilterFromDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
            <div className='h_10'></div>
            <div className='mantine-right-nav-modal-date-picker'>
                <DatePickerInput
                    icon={<i className="ri-calendar-2-line" size="1.1rem" stroke={1.5}></i>}
                    dropdownType="modal"
                    label="To Date"
                    placeholder="To Date"
                    value={emailClientStatementFilterToDateRN}
                    onChange={setEmailClientStatementFilterToDateRN}
                    mx="auto"
                    maw={400}
                />
            </div>
        </div>
    </section>

    //################################################################
    //return
    return (
        <section className='right_nav_container body_input_styles'>
            {content}
        </section>
    )
}

export default EmailClientStatementFilterDate;