import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllReceiptsInsurer from "../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurer";
import GetAllReceiptsInsurerID from '../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurerID'
import { useRecoilState, useRecoilValue } from "recoil";
import { receiptListFilterFromDate, receiptListFilterToDate, receiptListInsurerFilter, receiptListTypeFilter } from "../../../atoms";
import { activeInsurerPortfolio } from "../../../atoms";
import Loader from "../Loader/Loader";
import DateFormat from "../../Hooks/UI/DateFormat";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import NumberFormat from "../../Hooks/UI/NumberFormat";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const ReceiptListGrid = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const [receiptListInsurerFilterRN, setReceiptListInsurerFilterRN] = useRecoilState(receiptListInsurerFilter);
    const [receiptListFilterFromDateRN, setReceiptListFilterFromDateRN] = useRecoilState(receiptListFilterFromDate);
    const [receiptListFilterToDateRN, setReceiptListFilterToDateRN] = useRecoilState(receiptListFilterToDate);
    const receiptListTypeFilterRN = useRecoilValue(receiptListTypeFilter);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all debits
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllReceiptsInsurerID({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, receiptListInsurerFilterRN]);

    //################################################################
    //filter dates
    const [selectedPeriodDocs, setSelectedPeriodDocs] = useState([]);
    useEffect(() => {
        try {

            const periodSelected = [];

            receiptDocs.map((doc) => {
                //clean date
                const formattedReceiptDate = new Date(doc.docData.receiptDate);
                formattedReceiptDate.setHours(0, 0, 0);
                //filter period choosen
                if (new Date(formattedReceiptDate).valueOf() >= new Date(receiptListFilterFromDateRN).valueOf() && new Date(receiptListFilterToDateRN).valueOf() >= new Date(formattedReceiptDate).valueOf() && doc.docData.businessType !== 'JV' && doc.docData.businessType !== 'DV') {
                    periodSelected.push(doc);
                }
            });

            setSelectedPeriodDocs(periodSelected);

        } catch (error) { }
    }, [receiptDocs, receiptListFilterFromDateRN, receiptListFilterToDateRN]);

    //filter present / missing documents
    const [documentsFilter, setDocumentsFilter] = useState([]);
    useEffect(() => {
        try {

            const pdList = [];
            selectedPeriodDocs.map((doc) => {
                const receiptDocuments = [];
                if (doc.docData.receiptDocument) {
                    Object.keys(doc.docData.receiptDocument).map((key) => {
                        receiptDocuments.push(doc.docData.receiptDocument[key]['receiptDocumentDownloadLink']);
                    });
                }

                if (receiptListTypeFilterRN === 'All') {
                    pdList.push(doc)
                }
                else if (receiptListTypeFilterRN === 'Receipt Document Present') {

                    if (receiptDocuments.length > 0) { pdList.push(doc) }
                }
                else if (receiptListTypeFilterRN === 'Receipt Document Missing') {
                    if (receiptDocuments.length < 1) { pdList.push(doc) }
                }
            });

            setDocumentsFilter(pdList);

        } catch (error) { }

    }, [selectedPeriodDocs, receiptListTypeFilterRN])

    //################################################################
    //sort by date
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedDocList = documentsFilter.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.receiptDate).valueOf() - new Date(a.docData.receiptDate).valueOf();
        }
        setDateSortedDocs(dateSortedDocList);
    }, [documentsFilter]);

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = []
            dateSortedDocs.map((doc) => {

                //look for vr
                const receiptDocuments = [];
                if (doc.docData.receiptDocument) {
                    Object.keys(doc.docData.receiptDocument).map((key) => {
                        receiptDocuments.push(doc.docData.receiptDocument[key]['receiptDocumentDownloadLink']);
                    });
                }

                const receiptAllocationDetails = [];
                if (doc.docData.receiptAllocationDetails) {
                    doc.docData.receiptAllocationDetails.map((allocation) => {
                        receiptAllocationDetails.push(
                            allocation.receivedClientName + ' - ' + allocation.receivedPolicyNumber + ' - ' + allocation.receivedEndorsmentNumber + ' - ' + allocation.receivedAllocatedAmount
                        )
                    })
                }

                finalObj.push({
                    docID:doc.docID,
                    receiptDate: DateFormat(doc.docData.receiptDate),
                    receiptNumber: doc.docData.receiptNumber,
                    receiptAmount: NumberFormat(doc.docData.receiptAmount),
                    receiptDocuments: receiptDocuments,
                    receiptAllocationDetails: receiptAllocationDetails
                });
            });

            setFinalData(finalObj);

        } catch (error) { }
    }, [dateSortedDocs]);

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['receiptDate', 'receiptNumber', 'receiptAmount', 'receiptAllocationDetails',]

    const exportToExcelFileName = 'RECEIPT LIST    --    INSURER: ' + receiptListInsurerFilterRN + '    --    PERIOD: ' + DateFormat(receiptListFilterFromDateRN) + ' - ' + DateFormat(receiptListFilterToDateRN) + '    --    CATEGORY: ' + receiptListTypeFilterRN + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.receiptDate,
                doc.receiptNumber,
                doc.receiptAmount,
                doc.receiptAllocationDetails,
            ])
        });


        const onlyDispatchedheaderData = 'RECEIPT LIST    ||    INSURER: ' + receiptListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(receiptListFilterFromDateRN) + ' - ' + DateFormat(receiptListFilterToDateRN) + '    ||    CATEGORY: ' + receiptListTypeFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 500],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {},],
                            ['NO', 'RECEIPT DATE', 'RECEIPT NUMBER', 'RECEIPT AMOUNT', 'RECEIPT ALLOCATION DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.receiptDate,
                doc.receiptNumber,
                doc.receiptAmount,
                doc.receiptAllocationDetails,
            ])
        });


        const onlyDispatchedheaderData = 'RECEIPT LIST    ||    INSURER: ' + receiptListInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(receiptListFilterFromDateRN) + ' - ' + DateFormat(receiptListFilterToDateRN) + '    ||    CATEGORY: ' + receiptListTypeFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 500],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {},],
                            ['NO', 'RECEIPT DATE', 'RECEIPT NUMBER', 'RECEIPT AMOUNT', 'RECEIPT ALLOCATION DETAILS',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    const viewReceiptAllocation = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const onePropObj = {
            setView: 'View Receipt',
            previousView: 'Receipt List',
            postedDocRef: rightClickedPolicyID,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }
        props.onePropDown(onePropObj)
    }

    // ################################################################
    // right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={viewReceiptAllocation}><p>View Receipt Details</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'receiptNumber', headerName: 'Receipt Number', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'receiptDate', headerName: 'Receipt Date', width: 150, },
        { field: 'receiptAmount', headerName: 'Receipt Amount', width: 150, },
        { field: 'receiptAllocationDetails', headerName: 'Receipt Alloca', width: 150, },
        {
            field: 'receiptDocuments', headerName: 'Receipt Documents', sortable: false, filter: false, width: 50,
            cellRenderer: params => {
                return params.value.map((docLink, index) => {
                    return <i onClick={() => openInNewTab(docLink)} key={index} className='ri-attachment-2'></i>
                })
            }
        },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Receipt List</p>
                <div className='cursor-pointer'>
                <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                    <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                    <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                    <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                </div>
            </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default ReceiptListGrid;