import axios from "axios";

const GetExpenseAPI = async (receivedID) => {
    const url = `https://addmessage-7fqanz2g2q-uc.a.run.app/getexpense?id=${receivedID}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default GetExpenseAPI;