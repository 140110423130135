import React, { useEffect, useState } from "react";
import { doc, updateDoc, runTransaction } from "firebase/firestore";
import db from "../../../firebase";
import UUID from "../../Hooks/UUID/UUID";
import Loader from "../Loader/Loader";
import styles from './CreateExpense.module.css'
import GetCookie from "../../Hooks/Cookie/GetCookie";
import { useRecoilValue } from "recoil";
import { activeBrokerBranchPortfolio } from "../../../atoms";
import axios from "axios";
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from "@tabler/icons-react";
import Worker from './CreateExpense.Worker.js';

const CreateExpense = (props) => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const activeBrokerBranchPortfolioRN = useRecoilValue(activeBrokerBranchPortfolio);

    //################################################################
    //current user
    const userDetails = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //set default
    const modeOfPaymentOptions = ['Petty Cash', 'CIB Kenya - 040XXX0081', 'CIB Kenya - 040XXX0090', 'Cash', 'Salary Advance'];
    const ExpenseAccountOptions = ['Salaries, Benefits and Wages', 'Salary Advance', 'Utilities', 'Miscellaneous', 'Petty Cash', 'Advertising and Marketing', 'Office Supplies and Stationary', 'Office Equipment', 'Furniture and Fittings', 'Telecommunication', 'Travel', 'Rent', 'Repairs and Maintenance', 'Entertainment', 'Courier Services', 'Airtime', 'Cleaning Services', 'Taxi Service', 'Fuel', 'Tea', 'Permits and Licences']
    const [modeOfPaymentOptionss, setModeOfPaymentOptionss] = useState([])
    const [expenseAccountOptionss, setExpenseAccountOptionss] = useState([])

    //################################################################
    //enrty
    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = { sampleData: 'sample data' }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setModeOfPaymentOptionss(event.data.Credit)
                setExpenseAccountOptionss(event.data.Debit)
                setIsLoading(false);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, []);

    //################################################################
    //get user input
    const [purposeOfPayment, setPurposeOfPayment] = useState('');
    const [amount, setAmount] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [paymentID, setPaymentID] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    const [receiptNo, setReceiptNo] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [invoiceDate, setInvoiceDate] = useState(null);

    //################################################################
    //step 3
    //show msg
    const showSuccessMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Petty Cash Report',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Expense Created.',
            activity: 'Expense Creation',
        }
        props.onePropDown(onePropObj);
    }

    // show err
    const showErrorMSGOnExpenseSave = () => {
        setIsLoading(false);

        const onePropObj = {
            setView: 'Create Expense',
            previousView: '',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Error Creating Expense.'
        }
        props.onePropDown(onePropObj);
    }

    //step 2
    //post data
    const createExpenseHandler = (receivedPaymentID) => {
        setIsLoading(true);

        let paymntDate = paymentDate
        let invDate = invoiceDate
        if (paymentDate) { paymntDate = paymentDate.toISOString() }
        if (invoiceDate) { invDate = invoiceDate.toISOString() }

        const quoteFinalObj = {
            purposeOfPayment: purposeOfPayment.trim().toUpperCase(),
            amount: amount.trim(),
            paymentID: receivedPaymentID.trim(),
            modeOfPayment: modeOfPayment,
            expenseAccount: expenseAccount,
            receiptNo: receiptNo,
            status: "Cleared",
            createdBy: userDetails,
            expenseReceiptDocument: {},
            branchID: activeBrokerBranchPortfolioRN,
            paymentDate: paymntDate,
            invoiceDate: invDate,
            vendorID: '',
            category: 'Expense',
            dateCreated: new Date(),
            allocationDetails: '',
        }

        const CreateExpenseAPI = async () => {

            axios.post('https://addmessage-7fqanz2g2q-uc.a.run.app/createexpense', quoteFinalObj)
                .then(response => {
                    //console.log(response)
                    // Handle the response data
                    //console.log(response.data);
                    showSuccessMSGOnExpenseSave();
                })
                .catch(error => {
                    // Handle any errors
                    //console.log(error);
                    showErrorMSGOnExpenseSave();
                });
        }
        CreateExpenseAPI()

        // CreateExpenseAPI(quoteFinalObj)
        //     .then(data => {
        //         console.log(data)
        //         if(data.insertedId){showSuccessMSGOnExpenseSave()}    
        //         else {showErrorMSGOnExpenseSave()}            
        //     })
        //     .catch(error => {
        //         console.log('Error:', error);
        //         setIsLoading(false)
        //     });
    }

    const createExpenseHandlerold = async (receivedPaymentID) => {
        try {
            let status = '';
            if (modeOfPayment === 'Cheque') {
                status = 'Pending'
            }

            const quoteFinalObj = {
                purposeOfPayment: purposeOfPayment.trim().toUpperCase(),
                amount: amount.trim(),
                paymentID: receivedPaymentID.trim(),
                modeOfPayment: modeOfPayment,
                expenseAccount: expenseAccount,
                receiptNo: receiptNo,
                status: status,
                dateCreated: new Date(),
                createdBy: userDetails,
                expenseReceiptDocument: '',
            }

            const washingtonRef = doc(db, "Expenses", "X5Yq0JOUoNkc9V2iiBSh");

            //get unique id
            const quoteID = UUID() + ' - ' + UUID();

            // Set the "capital" field of the city 'DC'
            await updateDoc(washingtonRef, {
                [quoteID]: quoteFinalObj
            });

            showSuccessMSGOnExpenseSave();

        } catch (error) { }
    }

    //step 1
    //get pc voucher
    const processPCN = async () => {
        setIsLoading(true);

        if (modeOfPayment === 'Petty Cash') {

            const sfDocRef = doc(db, "SystemControls", "57j4Ju1V7OvwBjGP0gyi");

            try {
                let qwe = await runTransaction(db, async (transaction) => {
                    const sfDoc = await transaction.get(sfDocRef);
                    if (!sfDoc.exists()) {
                        throw "Document does not exist!";
                    }

                    const newReceiptCount = sfDoc.data().pettyCashVoucherNumberCount + 1;
                    transaction.update(sfDocRef, { pettyCashVoucherNumberCount: newReceiptCount });
                    const newReceiptCountLength = newReceiptCount.toString().length;

                    let SRSuffix = '';

                    switch (true) {
                        case (newReceiptCountLength == 1):
                            SRSuffix = '000';
                            break;
                        case (newReceiptCountLength == 2):
                            SRSuffix = '00';
                            break;
                        case (newReceiptCountLength == 3):
                            SRSuffix = '0';
                            break;
                        case (newReceiptCountLength >= 4):
                            SRSuffix = '';
                            break;
                    }

                    const qw = 'PC' + SRSuffix + newReceiptCount;
                    return qw
                });

                if (qwe) {
                    createExpenseHandler(qwe)
                }

            } catch (e) { }

        }
        else { createExpenseHandler(paymentID) }
    }

    //################################################################
    //content
    const content = <section className='f_7'>
        <table className='w-full table-fixed border-collapse border border-slate-500'>
            <tbody>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Debit Account</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        {
                            expenseAccountOptionss.map((expenseAccountOption, index) => {
                                return (
                                    <span className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${expenseAccountOption.account === expenseAccount && styles.selected_insurance_classes}`} key={index} onClick={() => { setExpenseAccount(expenseAccountOption.account) }}>{expenseAccountOption.account}</span>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Credit Account</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        {
                            modeOfPaymentOptionss.map((modeOfPaymentOption, index) => {
                                return (
                                    <span className={`border px-0.5 m-0.5 rounded border-indigo-500 cursor-pointer inline-block ${modeOfPaymentOption.account === modeOfPayment && styles.selected_insurance_classes}`} key={index} onClick={() => { setModeOfPayment(modeOfPaymentOption.account) }}>{modeOfPaymentOption.account}</span>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Purpose / Description</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={purposeOfPayment || ''} onChange={(e) => { setPurposeOfPayment(e.target.value) }} placeholder='Purchase of office stationary' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Receipt / Invoice Number</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={receiptNo || ''} onChange={(e) => { setReceiptNo(e.target.value) }} placeholder='RC000001' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Amount</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={amount || ''} onChange={(e) => { setAmount(e.target.value) }} placeholder='1,000' className={styles.veh_input} />
                    </td>
                </tr>
                <tr className={`${modeOfPayment === 'Cash' || modeOfPayment === 'Petty Cash' || modeOfPayment === 'Salaries, Benefits and Wages' ? styles.create_expense_hide_component : ''}`}>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Payment ID / Cheque No</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <input value={paymentID || ''} onChange={(e) => { setPaymentID(e.target.value) }} placeholder='Cheque Number / Petty Cash Vourcher Number' className={styles.veh_input} />
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Payment Date</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <div className='mantine_main_content_table_date_picker'>
                            <DatePickerInput
                                dropdownType="modal"
                                placeholder="Pick date"
                                value={paymentDate}
                                onChange={setPaymentDate}
                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                maw={400}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className='w-3/12 border border-slate-600 p-1'>
                        <p className="bold">Invoice Date</p>
                    </td>
                    <td className='w-9/12 border border-slate-600 p-1'>
                        <div className='mantine_main_content_table_date_picker'>
                            <DatePickerInput
                                dropdownType="modal"
                                placeholder="Pick date"
                                value={invoiceDate}
                                onChange={setInvoiceDate}
                                maw={400}
                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Create Expense</p>
                <button onClick={processPCN} className='btn_pink'>Save Details</button>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
        </section>
    )
}

export default CreateExpense;