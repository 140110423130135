const GetRenewalSchedule = (debitDocs) => {
    //################################################################
    //remove unrenewed classes
    const removeNonRenewables = debitDocs.filter(doc =>
        !doc.docData.endorsmentNumber.startsWith('05/')
        && !doc.docData.policyNumber.startsWith('DV')
        && !doc.docData.policyNumber.startsWith('JV')
        && !doc.docData.policyNumber.startsWith('CN')
        && !doc.docData.policyNumber.startsWith('Rev')
        // && doc.docData.selectedProductSubClass !== '(060) - Marine Hull'
        // && doc.docData.selectedProductSubClass !== '(061) - Marine Certificate'
        // && doc.docData.selectedProductSubClass !== '(120) - Immigration Bond'
        // && doc.docData.selectedProductSubClass !== '(127) - Advance Payment Bond'
        // && doc.docData.selectedProductSubClass !== '(128) - Custom Bond'
        // && doc.docData.selectedProductSubClass !== '(093) - Travel'
        // && doc.docData.selectedProductSubClass !== '(020) - Contractors All Risks'
        && doc.docData.status !== 'Lapsed'
        && doc.docData.status !== 'Cancelled'
        && doc.docData.businessType !== 'Nil Endorsment'
    );

    //################################################################
    //group according to policy no
    const groupedItems = removeNonRenewables.reduce((acc, item) => {
        if (!acc[item.docData.policyNumber]) {
            acc[item.docData.policyNumber] = [];
        }
        acc[item.docData.policyNumber].push(item);
        return acc;
    }, {});

    //################################################################
    //sort to get  latest schedule
    const latestPolicies = []
    Object.keys(groupedItems).map(key => {
        // console.log(`Array for key '${key}':`);
        // console.log(groupedItems[key]);

        const ourArray = groupedItems[key]

        const dateSortedList = ourArray.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.insurerDebitDate).valueOf() - new Date(a.docData.insurerDebitDate).valueOf();
        }

        latestPolicies.push(dateSortedList[0])


    });

    // //################################################################
    // //clean to return only needed data
    // const cleanData = []
    // latestPolicies.map((doc, index) => {

    //     let id = index + 1;
    //     let policyNumber = doc.docData.policyNumber;
    //     let productSubClass = doc.docData.selectedProductSubClass;
    //     let location = '';
    //     let totalSumInsured = '';
    //     let totalPremium = doc.docData.policyNet;
    //     let description = [];


    //     if (doc.docData.selectedProductClass !== '(07) - Motor Private'
    //         && doc.docData.selectedProductClass !== '(07) - Motor Private - Comprehensive'
    //         && doc.docData.selectedProductClass !== '(07) - Motor Private - TPO'
    //         && doc.docData.selectedProductClass !== '(08) - Motor Commercial'
    //         && doc.docData.selectedProductClass !== '(08) - Motor Commercial - Comprehensive'
    //         && doc.docData.selectedProductClass !== '(08) - Motor Commercial - TPO') {
    //         location = 'Location: ' + doc.docData.locationOfTheRisk;
    //         totalSumInsured = doc.docData.totalSumInsured;
    //         //console.log(Object.values(doc.docData.propertyDescriptionItems))
    //         Object.values(doc.docData.propertyDescriptionItems).map((item) => {
    //             console.log(item)
    //             Object.values(item)
    //             description = [
    //                 '', '', item.propertyDescription, item.sumInsured, '', ''
    //             ]
    //         })


    //     }
    //     else {
    //         description = [
    //             '', '', '', '', '', ''
    //         ]
    //     }



    //     cleanData.push(
    //         [
    //             id,
    //             policyNumber,
    //             productSubClass,
    //             location,
    //             description,
    //             '',
    //         ], description

    //     )
    // })

    // console.log(cleanData)

    return latestPolicies;
}

export default GetRenewalSchedule;