const MotorCommCompClauses = () => {
    return (
        <section>
            <p className='leading-none'>Conditions</p>
            <p className='leading-none'>Excess</p>
            <li className='leading-none'>Own damage 5% of sum insured subject to min Ksh 30,000</li>
            <li className='leading-none'>Young and new drivers Kshs 7,500</li>
            <li className='leading-none'>Theft or attempted theft therof with anti theft device 10% subject to min Ksh 20,000</li>
            <li className='leading-none'>Theft or attempted thereof without anti theft device 25% subject to minimum 20,000</li>
            <li className='leading-none'>Third party property damage Ksh 10,000</li>
            <p className='leading-none'>Limits</p>
            <li className='leading-none'>TPPD - Kshs 5,000,000</li>
            <li className='leading-none'>Passanger liability - Ksh 20,000,000 per event, Ksh 3,000,000 per person</li>
            <li className='leading-none'>Third party injury - Any one person Ksh 4,000,000, Any one event unlimited</li>
            <li className='leading-none'>Medical expenses - Ksh 30,000</li>
            <li className='leading-none'>Territorial limits - Kenya only</li>
            <li className='leading-none'>Including excess protector</li>
            <li className='leading-none'>Including political violence and terrorism</li>
            <li className='leading-none'>Agreed value at 85% of the current valuation report by insurers approved valuer</li>
        </section>
    )
}

export default MotorCommCompClauses;