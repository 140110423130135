import React, { useState, useRef, useEffect } from 'react';
import db from '../../../firebase';
import { collection, addDoc, getDocs, doc, updateDoc } from "firebase/firestore";
import Loader from '../Loader/Loader';
import '../../Body/BodySectionModule.css';
import './BodyCreateClient.css'

const BodyEditClient = (props) => {
    const [postedDocRef, setPostedDocRef] = useState(props.onePropUp.postedDocRef);

    //loading
    const [isLoading, setIsloading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //get user input
    const [clientName, setClientName] = useState(postedDocRef.clientName);
    const clientNameChangeHandler = (event) => {
        setClientName(event.target.value);
    }

    const [IDNumber, setIDNumber] = useState(postedDocRef.IDNumber);
    const IDNumberChangeHandler = (event) => {
        setIDNumber(event.target.value);
    }

    const [passportNumber, setPassportNumber] = useState(postedDocRef.passportNumber);
    const passportNumberChangeHandler = (event) => {
        setPassportNumber(event.target.value);
    }

    const [KRAPin, setKRAPin] = useState(postedDocRef.KRAPin);
    const KRAPinChangeHandler = (event) => {
        setKRAPin(event.target.value);
    }

    const [occupation, setOccupation] = useState(postedDocRef.occupation);
    const occupationChangeHandler = (event) => {
        setOccupation(event.target.value);
    }

    const [phoneNumber, setPhoneNumber] = useState(postedDocRef.phoneNumber);
    const phoneNumberChangeHandler = (event) => {
        setPhoneNumber(event.target.value);
    }

    const [email, setEmail] = useState(postedDocRef.email);
    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const [address, setAddress] = useState(postedDocRef.address);
    const addressChangeHandler = (event) => {
        setAddress(event.target.value);
    }

    const [contactPerson, setContactPerson] = useState('');

    useEffect(() => {
        if (postedDocRef.contactPerson) {
            setContactPerson(postedDocRef.contactPerson)
        }
    }, [postedDocRef])

    //view client
    const viewEditedClientOnSuccessPosting = (clientDetails) => {
        props.onSuccessPolicyCreate({ setView: 'View Client', postedDocRef: clientDetails });
    }
    

    //edit client
    async function editClientFormSubmitHandler(event) {
        event.preventDefault();
        setIsloading(true);

        //prepare obj
        const clientDetails = {
            // clientName: clientName,
            IDNumber: IDNumber,
            passportNumber: passportNumber,
            KRAPin: KRAPin,
            occupation: occupation,
            phoneNumber: phoneNumber,
            email: email,
            address: address,
            contactPerson:contactPerson,
        }

        //update data
        try {
            setIsloading(true);

            // Create an initial document to update.
            const policyDocRef = doc(db, "ClientDetails", postedDocRef.clientID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, clientDetails);
            setIsloading(false);
            viewEditedClientOnSuccessPosting(clientDetails);
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    return (
        <section>
            <form onSubmit={editClientFormSubmitHandler}>
                <div className='body_section_header'>
                    <p>Edit Client</p>
                    <button type='submit' className='btn_pink'>Save Details</button>
                </div>
                <div className='body_section'>
                    <div className='h_5'></div>
                    <div className='category'>
                        <div className='category_header'><p>Client Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input onChange={clientNameChangeHandler} value={clientName} type='text' placeholder='Client Name' />
                                <div className='h_10'></div>
                                <input onChange={IDNumberChangeHandler} value={IDNumber} type='text' placeholder='ID Number' />
                                <div className='h_10'></div>
                                <input onChange={passportNumberChangeHandler} value={passportNumber} type='text' placeholder='Passport Number' />
                            </div>
                            <div className='category_content_two'>
                                <input onChange={KRAPinChangeHandler} value={KRAPin} type='text' placeholder='KRA Pin' />
                                <div className='h_10'></div>
                                <input onChange={occupationChangeHandler} value={occupation} type='text' placeholder='Occupation / Employment Details' />
                            </div>
                        </div>
                    </div>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Contact Details</p></div>
                        <div className='category_content'>
                            <div className='category_content_one'>
                                <input onChange={phoneNumberChangeHandler} value={phoneNumber} type='text' placeholder='Phone Number' />
                                <div className='h_10'></div>
                                <input onChange={emailChangeHandler} value={email} type='text' placeholder='Email' />
                                <div className='h_10'></div>
                            </div>
                            <div className='category_content_two'>
                                <input onChange={addressChangeHandler} value={address} type='text' placeholder='Address' />
                            </div>
                        </div>
                    </div>
                    <div className='h_15'></div>
                    <div className='category'>
                        <div className='category_header'><p>Contact Person</p></div>
                        <div className='category_content'>
                            <div className='category_content_three'>
                                <textarea onChange={(e) => setContactPerson(e.target.value.trim())} value={contactPerson} type='text' placeholder='Contact Person' />
                                <div className='h_10'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loaderContent}
        </section>
    );
}

export default BodyEditClient;