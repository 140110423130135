import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import db from "../../../firebase";
import { updateDoc,doc } from "firebase/firestore";
import GetAllActionMemos from "../../Hooks/BackendConn/ActionMemo/GetAllActionMemos";
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { actionMemoFilterFromDate, actionMemoFilterToDate } from "../../../atoms";
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import DateFormat from "../../Hooks/UI/DateFormat";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const OpenActionMemo = (props) => {
    //################################################################
    // recoil values
    const actionMemoFilterFromDateRN = useRecoilValue(actionMemoFilterFromDate);
    const actionMemoFilterToDateRN = useRecoilValue(actionMemoFilterToDate);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all action memos
    const [actionMemoDocs, setActionMemoDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllActionMemos();
            setActionMemoDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get open action memos
    const [openActionMemoDocs, setOpenActionMemoDocs] = useState([])
    useEffect(() => {
        try {

            const actionMemo = actionMemoDocs.filter(doc => doc.docData.status === 'Open');
            setOpenActionMemoDocs(actionMemo);

        } catch (error) { }
    }, [actionMemoDocs])

    //################################################################
    //sort open claims
    const [dateSortedOpenActionMemoDocs, setDateSortedOpenActionMemoDocs] = useState([])
    useEffect(() => {
        try {

            const sortedList = openActionMemoDocs.sort(byDate);
            function byDate(a, b) {
                return new Date(a.docData.dateCreated.seconds * 1000).valueOf() - new Date(b.docData.dateCreated.seconds * 1000).valueOf();
            }
            setDateSortedOpenActionMemoDocs(sortedList);

        } catch (error) { }
    }, [openActionMemoDocs]);

    //################################################################
    //filter open claims
    const [dateFilteredOpenActionMemoDocs, setDateFilteredOpenActionMemoDocs] = useState([])
    useEffect(() => {
        try {

            const filteredList = [];
            dateSortedOpenActionMemoDocs.map((doc) => {
                //clean date
                const formattedDateCreated = new Date(doc.docData.dateCreated.seconds * 1000);
                formattedDateCreated.setHours(0, 0, 0);
                //filter period choosen
                if (new Date(formattedDateCreated).valueOf() >= new Date(actionMemoFilterFromDateRN).valueOf() && new Date(formattedDateCreated).valueOf() <= new Date(actionMemoFilterToDateRN).valueOf()) {
                    filteredList.push(doc);
                }
            });
            setDateFilteredOpenActionMemoDocs(filteredList);

        } catch (error) { }
    }, [dateSortedOpenActionMemoDocs, actionMemoFilterFromDateRN, actionMemoFilterToDateRN])

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            dateFilteredOpenActionMemoDocs.map((doc) => {
                finalObj.push({
                    docID: doc.docID,
                    title: doc.docData.title,
                    actionNeeded: doc.docData.actionNeeded,
                    dateCreated: DateFormat(doc.docData.dateCreated),
                });
            });
            setFinalData(finalObj);

        } catch (error) { }
    }, [dateFilteredOpenActionMemoDocs]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['dateCreated', 'title', 'actionNeeded',]

    const exportToExcelFileName = 'OPEN ACTION MEMO LIST    --    PERIOD: ' + DateFormat(actionMemoFilterFromDateRN) + ' - ' + DateFormat(actionMemoFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.dateCreated,
                doc.title,
                doc.actionNeeded,
            ])
        });


        const onlyDispatchedheaderData = 'OPEN ACTION MEMO LIST    --    PERIOD: ' + DateFormat(actionMemoFilterFromDateRN) + ' - ' + DateFormat(actionMemoFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 4, alignment: 'center' }, {}, {}, {},],
                            ['NO', 'DATE CREATED', 'TITLE', 'ACTION NEEDED',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.dateCreated,
                doc.title,
                doc.actionNeeded,
            ])
        });


        const onlyDispatchedheaderData = 'OPEN ACTION MEMO LIST    --    PERIOD: ' + DateFormat(actionMemoFilterFromDateRN) + ' - ' + DateFormat(actionMemoFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: [12, 'auto', 'auto', 'auto',],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 4, alignment: 'center' }, {}, {}, {},],
                            ['NO', 'DATE CREATED', 'TITLE', 'ACTION NEEDED',],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //  close claim
    // step 3
    const reloadOnSuccessCloseActionMemo = () => {
        const onePropObj = {
            setView: 'Open Action Memo',
            previousView: 'Open Action Memo',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Action Memo Closed'
        }
        props.onePropDown(onePropObj);
        setIsLoading(false);
        setReload(Math.random(1000, 100000));
    }

    //################################################################
    // step 2
    const closeActionMemoHandler = async () => {
        try {

            const docRef = doc(db, "ActionMemo", "FXjncWTtNGyrkbowKMVs");

            const data = rightClickedPolicyID + '.status';
            const a = await updateDoc(docRef, {
                [data]: 'Closed'
            });

        } catch (error) { console.log(error) }
        reloadOnSuccessCloseActionMemo();
    }

    //################################################################
    // step 1
    const closeActionMemo = () => {
        const executeAbort = ConfirmAction('Confirm You Want To Close This Action Memo.');
        if (!executeAbort) { return false }

        setIsLoading(true)
        setxPos('-1000px');
        setyPos('-1000px');
        closeActionMemoHandler();

    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={closeActionMemo}><p>Close Action Memo</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'title', headerName: 'Title', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'actionNeeded', headerName: 'Action Needed', wrapText: true, autoHeight: true, },
        { field: 'dateCreated', headerName: 'Date Created', sortable: false, filter: false, maxWidth: 120, },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Open Action Memo</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    )
}

export default OpenActionMemo;