import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { cancelledPolicyListInsurerFilter, cancelledPolicyListFilterFromDate, cancelledPolicyListFilterToDate, cancelledPolicyListFilterPolicyClass } from "../../../../atoms";
import GetAllInsurerDetails from "../../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import DatePicker from "react-date-picker";
import Loader from "../../../Body/Loader/Loader";

const CancelledPolicyListFilter = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const [cancelledPolicyListFilterPolicyClassRN, setCancelledPolicyListFilterPolicyClassRN] = useRecoilState(cancelledPolicyListFilterPolicyClass)
    const [cancelledPolicyListInsurerFilterRN, setCancelledPolicyListInsurerFilterRN] = useRecoilState(cancelledPolicyListInsurerFilter)
    const [cancelledPolicyListFilterFromDateRN, setCancelledPolicyListFilterFromDateRN] = useRecoilState(cancelledPolicyListFilterFromDate)
    const [cancelledPolicyListFilterToDateRN, setCancelledPolicyListFilterToDateRN] = useRecoilState(cancelledPolicyListFilterToDate)

    //################################################################
    //get all insurer details
    const [InsurerDetails, setInsurerDetails] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDetails(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    return (
        <section className='doc_upload'>
            <div className='right_nav_content'>
                <div className='h_10'></div>
                <div className='input_group'>
                    <select className='select' value={cancelledPolicyListFilterPolicyClassRN} onChange={(e) => { setCancelledPolicyListFilterPolicyClassRN(e.target.value) }}>
                        <option>Select Class Filter</option>
                        <option>ALL</option>
                        <option>MOTOR</option>
                        <option>NON-MOTOR</option>
                    </select>
                    <label className='placeholder'><p>Policy Class Filter</p></label>
                </div>
                <div className='h_15'></div>
                <div className='input_group'>
                    <select className='select' onChange={(e) => { setCancelledPolicyListInsurerFilterRN(e.target.value) }} value={cancelledPolicyListInsurerFilterRN}>
                        <option>Select Insurer Filter</option>
                        {
                            InsurerDetails.map((doc, index) => {
                                return (
                                    <option key={index}>{doc.docData.insurer}</option>
                                )
                            })
                        }
                    </select>
                    <label className='placeholder'><p>Insurer Account</p></label>
                </div>
                <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setCancelledPolicyListFilterFromDateRN} value={cancelledPolicyListFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setCancelledPolicyListFilterToDateRN} value={cancelledPolicyListFilterToDateRN} format='d/ M/y' />
                </div>

                
               




            </div>
            {loaderContent}
        </section>
    )
}

export default CancelledPolicyListFilter;