import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import { Stepper } from '@mantine/core';
import BiometricsDataStepOne from "./BiometricsDataStepOne";
import Worker from './BiometricsData.Worker.js';
import { useRecoilValue, useRecoilState } from "recoil";
import { biometricsDataFile, rightNavContent, biometricsDataMonthFilter } from "../../../atoms";
import { read, utils } from 'xlsx';
import BiometricsDataStepTwo from "./BiometricsDataStepTwo";

const BiometricsData = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const biometricsDataFileRN = useRecoilValue(biometricsDataFile);
    const biometricsDataMonthFilterRN = useRecoilValue(biometricsDataMonthFilter);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    
    // ################################################################
    // process file data
    const [rawData, setRawData] = useState([]);
    /* Fetch and update the state once */
    useEffect(() => {
        (async () => {
            try {

                if (biometricsDataFileRN.length > 0) {

                    let image = biometricsDataFileRN[0];
                    const buffer = await image.arrayBuffer();

                    const wb = read(buffer); // parse the array buffer
                    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                    const data = utils.sheet_to_json(ws); // generate objects
                    setRawData(data); // update state

                }

            } catch (error) { console.log(error) }

        })();
    }, [biometricsDataFileRN]);

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([])
    const [categorizedData, setCategorizedData] = useState([])
    useEffect(() => {
        try {
            setIsLoading(true);
            var worker = new Worker();

            const data = {
                rawData: rawData,
                biometricsDataMonthFilterRN: biometricsDataMonthFilterRN,
            }
            worker.postMessage(data);

            worker.onmessage = function (event) {
                setIsLoading(false);
                setFinalData(event.data.finalData);
                setCategorizedData(event.data.categorizedData);
            }

            return () => { worker.terminate() };

        } catch (error) { console.log(error) }
    }, [rawData]);

    //################################################################
    //steper  
    // Allow the user to freely go back and forth between visited steps.
    const [active, setActive] = useState(0);
    const shouldAllowSelectStep = (step) => {
        const back = active - step;
        const forward = step - active;
        return back == 1 || forward == 1 && active !== step
    }

    //################################################################
    //content
    const content = <section>
        <div className="h_8"></div>
        <div>
            <Stepper active={active} onStepClick={setActive} breakpoint="xs">
                <Stepper.Step
                    label="First Step"
                    description="Raw Data"
                    allowStepSelect={shouldAllowSelectStep(0)}
                >
                    <BiometricsDataStepOne finalData={finalData} />

                </Stepper.Step>
                <Stepper.Step
                    label="Second Step"
                    description="Computations"
                    allowStepSelect={shouldAllowSelectStep(1)}
                >
                    <BiometricsDataStepTwo categorizedData={categorizedData} />

                </Stepper.Step>
            </Stepper>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Biometrics Data</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Biometrics Data Upload Excel') }}>Upload Excel</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Biometrics Data Month Filter') }}>Filter Month</p>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p> */}
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default BiometricsData;