import React, { useEffect, useState } from 'react';
import SearchSpecificPolicy from '../../Hooks/BackendConn/GeneralList/SearchSpecificPolicy';
import Loader from '../Loader/Loader';
import { rightNavContent, rightNavPolicyItem } from '../../../atoms';
import { useRecoilState } from 'recoil';
import './BodyPolicySearch.css';

const BodyPolicySearch = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');

    //################################################################
    // search submit handler
    const [returnedDocPolicy, setReturnedDocPolicy] = useState([]);

    //################################################################
    // function to search for policy
    const searchSubmitHandler = async (event) => {
        event.preventDefault();

        setIsLoading(true)
        const DDocs = await SearchSpecificPolicy(searchTerm);
        setReturnedDocPolicy(DDocs)
        setIsLoading(false)

    }

    //################################################################
    // function from right click click from diff page
    const searchSubmitHandlerTwo = async (a) => {

        setIsLoading(true)
        const DDocs = await SearchSpecificPolicy(a);
        setReturnedDocPolicy(DDocs)
        setIsLoading(false)

    }

    //################################################################
    // call fuction on right click from diff page
    useEffect(() => {
        if (props.onePropUp.postedDocRef) {
            setSearchTerm(props.onePropUp.postedDocRef);
            searchSubmitHandlerTwo(props.onePropUp.postedDocRef)
        }
    }, [props.onePropUp.postedDocRef])


    //################################################################
    //set upload valuation content
    const [rightNavContentRV, setRightNavContentRV] = useRecoilState(rightNavContent);
    const [rightNavPolicyItemRV, setRightNavPolicyItemRV] = useRecoilState(rightNavPolicyItem)

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        setRightNavContentRV('')

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            const a = returnedDocPolicy.filter(item => item.docID === e.target.id)
            setRightNavPolicyItemRV(a[0]);

            setRightClickedPolicy(e.target.id);

        }
    };

    //################################################################
    //upload valuation handler - show
    const uploadValuationDocumentHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        setRightNavContentRV('Valuation Report Upload')

    }

    //################################################################
    //upload motor certificate - show
    const uploadMotorCertificateHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        setRightNavContentRV('Motor Certificate Upload');

    }

    //################################################################
    //upload motor certificate - show
    const uploadLogBookHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        setRightNavContentRV('Logbook Upload');

    }

    //################################################################
    //upload policy Document
    const uploadPolicyDocumentHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        setRightNavContentRV('Policy Document Upload');

    }

    //################################################################
    //view policy
    const viewPolicyHandler = () => {
        //open view
        const onePropObj = {
            setView: 'View Policy Final',
            previousView: 'Policy Documents',
            postedDocRef: rightClickedPolicy,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: '',
        }

        props.onePropDown(onePropObj);

    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={uploadValuationDocumentHandler}><p>Upload Valuation Document</p></span>
        <div className=''></div>
        <span onClick={uploadMotorCertificateHandler}><p>Upload Motor Certificate</p></span>
        <div className=''></div>
        <span onClick={uploadLogBookHandler}><p>Upload Logbook</p></span>
        <div className=''></div>
        <span onClick={uploadPolicyDocumentHandler}><p>Upload Policy Document</p></span>
        <div className=''></div>
        <span onClick={viewPolicyHandler}><p>View Policy</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const seachContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <form onSubmit={searchSubmitHandler}>
                        <div className='input_group_search'>
                            <input onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                            <label className='placeholder'><p>Search Policy No. / Endorsment No.</p></label>
                        </div>
                    </form>
                    <span onClick={searchSubmitHandler} className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className={`category_content ${returnedDocPolicy.length > 0 ? 'visible_component' : 'hidden_component'}`}>
                <div className='category_content_three'>
                    <div>
                        <div className='h_5'></div>
                        <table className='body_policy_search_table' cellSpacing="0">
                            <thead>
                                <tr>
                                    <td><p>No</p></td>
                                    <td><p>Name</p></td>
                                    <td><p>Policy Number</p></td>
                                    <td><p>Endorsment Number</p></td>
                                    <td><p>Policy Class</p></td>
                                    <td><p>Inception Date</p></td>
                                    <td><p>Expiry Date</p></td>
                                    <td><p>Details</p></td>
                                    <td><center><p>VR</p></center></td>
                                    <td><center><p>LB</p></center></td>
                                    <td><center><p>SC</p></center></td>
                                    <td><center><p>PD</p></center></td>
                                </tr>
                            </thead>
                            <tbody>
                                {returnedDocPolicy.map((item, index) => {
                                    const clientVehicleItems = [];
                                    if (item.docData.vehicleItems) {
                                        Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                    }
                                    const valuationReportDocuments = [];
                                    if (item.docData.motorDocuments) {
                                        Object.values(item.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Valuation Report') {
                                                valuationReportDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    const logBookDocuments = [];
                                    if (item.docData.motorDocuments) {
                                        Object.values(item.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Logbook') {
                                                logBookDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    const stickerCopyDocuments = [];
                                    if (item.docData.motorDocuments) {
                                        Object.values(item.docData.motorDocuments).map((item) => {
                                            if (item.documentCategory === 'Motor Certificate') {
                                                stickerCopyDocuments.push(item.documentDownloadLink)
                                            }
                                        })
                                    }
                                    return (
                                        <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                            <td id={item.docID}><p id={item.docID}>{index + 1}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{item.docData.clientName}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{item.docData.policyNumber}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{item.docData.endorsmentNumber}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{item.docData.selectedProductSubClass}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                            <td id={item.docID}><p id={item.docID}>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                            <td><p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p></td>
                                            <td>
                                                {
                                                    item.docData.selectedProductClass === '(07) - Motor Private' || item.docData.selectedProductClass === '(07) - Motor Private - Comprehensive' || item.docData.selectedProductClass === '(07) - Motor Private - TPO' || item.docData.selectedProductClass === '(08) - Motor Commercial' || item.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive' || item.docData.selectedProductClass === '(08) - Motor Commercial - TPO'
                                                        ? item.docData.policyNumber.includes('COMP')
                                                            ? item.docData.motorDocuments
                                                                ? valuationReportDocuments.length > 0
                                                                    ? valuationReportDocuments.map((item, index) => {
                                                                        return (
                                                                            <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                        )
                                                                    })
                                                                    : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                                : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                            : <center><p>N/A</p></center>
                                                        : <center><p>N/A</p></center>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.docData.selectedProductClass === '(07) - Motor Private' || item.docData.selectedProductClass === '(07) - Motor Private - Comprehensive' || item.docData.selectedProductClass === '(07) - Motor Private - TPO' || item.docData.selectedProductClass === '(08) - Motor Commercial' || item.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive' || item.docData.selectedProductClass === '(08) - Motor Commercial - TPO'
                                                        ? item.docData.motorDocuments
                                                            ? logBookDocuments.length > 0
                                                                ? logBookDocuments.map((item, index) => {
                                                                    return (
                                                                        <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                    )
                                                                })
                                                                : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                            : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                        : <center><p>N/A</p></center>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.docData.selectedProductClass === '(07) - Motor Private' || item.docData.selectedProductClass === '(07) - Motor Private - Comprehensive' || item.docData.selectedProductClass === '(07) - Motor Private - TPO' || item.docData.selectedProductClass === '(08) - Motor Commercial' || item.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive' || item.docData.selectedProductClass === '(08) - Motor Commercial - TPO'
                                                        ? item.docData.motorDocuments
                                                            ? stickerCopyDocuments.length > 0
                                                                ? stickerCopyDocuments.map((item, index) => {
                                                                    return (
                                                                        <center key={index}><span className='policy_search_list_doc_icon_large'><i onClick={() => openInNewTab(item)} className='ri-attachment-2'></i></span></center>
                                                                    )
                                                                })
                                                                : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                            : <center><span className='policy_search_list_doc_icon_small'><i className='ri-history-line'></i></span></center>
                                                        : <center><p>N/A</p></center>
                                                }
                                            </td>
                                            {
                                                item.docData.associateDocuments
                                                    ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_search_list_doc_icon_large'><center><i className="ri-attachment-2"></i></center></span></td>
                                                    : <td></td>
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section >

    return (
        <section className='body_policy_search body_input_styles'>
            <div className='body_section_header'>
                <p>Search Policy</p>
            </div>
            <div className='body_section'>
                {seachContent}
            </div>
            {URRightClickMenu}
            {loaderContent}
        </section>
    );
}

export default BodyPolicySearch;