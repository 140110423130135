import React, { useState, useEffect, useRef } from 'react';
import GetLapsedPolicies from '../../Hooks/BackendConn/GeneralList/GetLapsedPolicies';
import Loader from '../Loader/Loader';
import DatePicker from 'react-date-picker';
import Paginate from './Paginate';
import { useReactToPrint } from 'react-to-print';
import DateFormat from '../../Hooks/UI/DateFormat';
import './BodyLapsedPolicy.css';

const BodyLapsedPolicy = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //right nav filter date
    const [fromDate, setFromDate] = useState('');

    const [fvalue, onFChange] = useState(new Date('2021-05-01'));
    const year = new Date(fvalue).getFullYear();
    const month = new Date(fvalue).getMonth() + 1;
    const date = new Date(fvalue).getDate();
    const expiryDateCleanedJan = year + '-' + month + '-' + date;

    useEffect(() => {
        setFromDate(expiryDateCleanedJan)
    }, [expiryDateCleanedJan])

    //to
    const [toDate, setToDate] = useState(new Date());

    const [tvalue, onTChange] = useState(new Date('2022-12-31'));
    const Tyear = new Date(tvalue).getFullYear();
    const Tmonth = new Date(tvalue).getMonth() + 1;
    const Tdate = new Date(tvalue).getDate();
    const TexpiryDateCleanedJan = Tyear + '-' + Tmonth + '-' + Tdate;

    useEffect(() => {
        setToDate(TexpiryDateCleanedJan)
    }, [TexpiryDateCleanedJan])

    //################################################################
    //show hide search
    const [toogleRightNav, setToogleRightNav] = useState('receipt_allocation_rightNav hide');
    const showHideFilterRightNav = (instruction) => {
        if (instruction === 'Show') { setToogleRightNav('receipt_allocation_rightNav') }
        if (instruction === 'Hide') { setToogleRightNav('receipt_allocation_rightNav hide') }
    }

    // search section
    const dateFilterContent = <div className={toogleRightNav}>
        <div className='receipt_allocation_minimise' onClick={() => showHideFilterRightNav('Hide')}></div>
        <div className='receipt_allocation_search'>
            <div className='right_nav_filter'>
                <div className='h_5'></div>
                <p>Select Filters</p>
                <div className='h_10'></div>
                <div className='date_picker_container'>
                    <div className='date_container'>
                        <p>Date From</p>
                        <DatePicker onChange={onFChange} value={fvalue} format='d/ M/y' />
                    </div>
                    <div className='h_10'></div>
                    <div className='date_container'>
                        <p>Date To</p>
                        <DatePicker onChange={onTChange} value={tvalue} format='d/ M/y' />
                    </div>
                </div>
            </div>
        </div>
    </div>

    //################################################################
    //get all motor
    const [lapsedPolicies, setLapsedPolicies] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetLapsedPolicies();
            setLapsedPolicies(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //filter date
    const [dateFilterLapsedPolicyDocs, setDateFilterLapsedPolicyDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        lapsedPolicies.map((item) => {
            if (new Date(item.docData.inceptionDate).valueOf() >= new Date(fromDate).valueOf() && new Date(toDate).valueOf() >= new Date(item.docData.inceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setDateFilterLapsedPolicyDocs(periodSelected);
    }, [lapsedPolicies, fromDate, toDate]);

    //################################################################
    // sort by date
    const [dateSortedLapsedPolicyDocs, setSortedLapsedPolicyDocs] = useState([]);
    useEffect(() => {
        const sortedList = dateFilterLapsedPolicyDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(b.docData.inceptionDate).valueOf() - new Date(a.docData.inceptionDate).valueOf();
        }
        setSortedLapsedPolicyDocs(sortedList);
    }, [dateFilterLapsedPolicyDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    const [searchedLapsedPolicyDocs, setSearchedLapsedPolicyDocs] = useState([]);
    useEffect(() => {
        const c = [];
        const values = dateSortedLapsedPolicyDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else {
                const vehicleArray = Object.values(val.docData.vehicleItems)
                vehicleArray.map((item) => {
                    if (item.regNo.toLowerCase().includes(searchTerm.toLowerCase())) {

                    }
                });

            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            c.push(b)
        })
        setSearchedLapsedPolicyDocs(c);
    }, [dateSortedLapsedPolicyDocs, searchTerm]);

    //################################################################
    // paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedLapsedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //reset page no on search
    useEffect(() => { setCurrentPage(1) }, [searchTerm]);

    //################################################################
    // view clicked policy
    const viewClickedPolicy = (postedDocRef) => {
        const onePropObj = {
            setView: 'Lapsed Policy View',
            previousView: 'Lapsed Policies',
            postedDocRef: postedDocRef,
            dateFrom: fromDate,
            dateTo: toDate,
            searchTerm: searchTerm,
            notification: ''
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    // display
    const lapsedPoliciesContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number / Vehicle Plate No.' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='lapsed_policies_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.docData.transactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.id}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.clientName}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.transactionType}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.endorsmentNumber}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><i className="ri-attachment-2"></i></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedLapsedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    // display
    const lapsedPoliciesContentPrintable = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='lapsed_policies_table' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='12'><center><p className='bold'>LAPSED POLICIES</p></center></td></tr>
                            <tr><td colSpan='12'><center><p className='bold'>Period : {DateFormat(fromDate)} - {DateFormat(toDate)}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Voucher Type</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedLapsedPolicyDocs.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.docData.transactionType !== 'Receipt') {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.id}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.clientName}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.transactionType}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.endorsmentNumber}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td onClick={() => viewClickedPolicy(item.docID)}><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='lapsed_policies'>
            <div className='body_section_header'>
                <p>Lapsed Policies</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink' onClick={handlePrint}>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {lapsedPoliciesContent}
                {lapsedPoliciesContentPrintable}
            </div>
            {loaderContent}
        </section>
    );
}

export default BodyLapsedPolicy;