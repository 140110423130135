import { useEffect, useState } from "react";
import db from '../../../firebase';
import { doc, updateDoc } from "firebase/firestore";
import GetAllCredits from "../../Hooks/BackendConn/GeneralList/GetAllCredits";
import GetAllDebit from "../../Hooks/BackendConn/GeneralList/GetAllDebits";
import Loader from "../Loader/Loader";
import Paginate from "./Paginate";
import ConfirmAction from "../../Hooks/ConfirmAction/ConfirmAction";
import './CreditNoteAllocations.css'

const CreditNoteAllocations = (props) => {
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllCredits();
            setCreditDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //sort by date all credits
    const [dateSortedDocs, setDateSortedDocs] = useState([]);
    useEffect(() => {
        const dateSortedList = creditDocs.sort(byDate);
        function byDate(a, b) {
            return new Date(a.docData.inceptionDate).valueOf() - new Date(b.docData.inceptionDate).valueOf();
        }
        setDateSortedDocs(dateSortedList);
    }, [creditDocs]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    }

    //search all credits
    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = dateSortedDocs.filter((val) => {
            if (searchTerm === '') { return val; }
            else if (val.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
            else if (val.docData.policyNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [dateSortedDocs, searchTerm]);

    //################################################################
    // paginate all credits
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchedPolicyDocs.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    //################################################################
    //get all debits
    const [debitDocs, setAllDebitDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDebit();
            setAllDebitDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //filter all debits
    const [selectedCredit, setSelectedCredit] = useState({});
    const [filteredDebits, setFilteredDebits] = useState([]);

    useEffect(() => {
        if (selectedCredit && Object.keys(selectedCredit).length>0) {
            const a = debitDocs.filter(item => item.docData.policyNumber === selectedCredit.item.docData.policyNumber);
            setFilteredDebits(a);
        }
    }, [selectedCredit]);

    //################################################################
    //right click
    const [rightClickedPolicy, setRightClickedPolicy] = useState()
    const [xPos, setxPos] = useState('-1000px')
    const [yPos, setyPos] = useState('-1000px')

    const handleClick = (e) => {
        e.preventDefault();

        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";

        if (e.type === 'click') {
            setxPos('-1000px');
            setyPos('-1000px');
        }
        else if (e.type === 'contextmenu') {
            setxPos(xPos);
            setyPos(yPos);

            setRightClickedPolicy(e.target.id);
        }
    };

    //################################################################    
    // show allocated msg
    const displayAllocatedMSG = () => {
        const onePropObj = {
            setView: 'Credit Note Allocations',
            previousView: 'Credit Note Allocations',
            postedDocRef: '',
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Credit Note Allocated Successfully'
        }
        props.onePropDown(onePropObj)
    }

    //################################################################
    //allocate
    const allocatePolicyHandler = async () => {
        setxPos('-1000px');
        setyPos('-1000px');

        const executeAbort = ConfirmAction('Confirm You Want To Allocated This Policy To The Selected Credit Note?');
        if (!executeAbort) { return false }

        if (selectedCredit && Object.keys(selectedCredit).length>0) {
            try {
                setIsLoading(true);

                // Create an initial document to update.
                const policyDocRef = doc(db, "PolicyDetails", selectedCredit.item.docID);

                // To update age and favorite color:
                await updateDoc(policyDocRef, {
                    'allocatedDocID': rightClickedPolicy,
                });
                setIsLoading(false);
                displayAllocatedMSG();
                setReload(Math.random(0, 1000))
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={allocatePolicyHandler}><p>Allocate To The Selected Credit</p></span>
        <div className=''></div>
    </div>

    //################################################################
    //view policy doc on click
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //################################################################
    //################################################################
    //################################################################
    //echo all credits
    const allCreditsContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header policy_search_container'>
                <input onChange={searchTermChangeHandler} placeholder='Search Client Name / Policy Number' />
                <i className="ri-search-2-line"></i>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='credit_note_allocations_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Insurer Debit Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Allocated To</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.docData.vehicleItems) {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index} onClick={() => setSelectedCredit({ item })}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.insurerDebitDate).toDateString()}</p></td>
                                        <td>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        <td>
                                            {
                                                item.docData.allocatedDocID
                                                    ? <p>{item.docData.allocatedDocID}</p>
                                                    : ''
                                            }
                                        </td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <Paginate postsPerPage={postsPerPage} totalPosts={searchedPolicyDocs.length} paginate={paginate} />
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo selected credits
    const selectedCreditContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Selected Credit Note</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='credit_note_allocations_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Insurer Debit Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Allocated To</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(selectedCredit).map((item, index) => {
                                const clientVehicleItems = []
                                if (item.docData.vehicleItems) {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index}>
                                        <td><p>{item.id}</p></td>
                                        <td><p>{item.docData.clientName}</p></td>
                                        <td><p>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td><p>{item.docData.endorsmentNumber}</p></td>
                                        <td><p>{item.docData.selectedProductSubClass}</p></td>
                                        <td><p>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td><p>{new Date(item.docData.insurerDebitDate).toDateString()}</p></td>
                                        <td>
                                            <p>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td><p>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        <td>
                                            {
                                                item.docData.allocatedDocID
                                                    ? <p>{item.docData.allocatedDocID}</p>
                                                    : ''
                                            }
                                        </td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //echo filtered debits
    const filteredDebitsContent = <section>
        <div className='h_15'></div>
        <div className='category'>
            <div className='category_header'>
                <p>Associate Debits</p>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='credit_note_allocations_table'>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Name</p></td>
                                <td><p>Policy Number</p></td>
                                <td><p>Endorsment Number</p></td>
                                <td><p>Policy Class</p></td>
                                <td><p>Inception Date</p></td>
                                <td><p>Expiry Date</p></td>
                                <td><p>Insurer Debit Date</p></td>
                                <td><p>Details</p></td>
                                <td><p>Policy Net</p></td>
                                <td><p>Document ID</p></td>
                                <td><p>Doc</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDebits.map((item, index) => {
                                const clientVehicleItems = []
                                if (item.docData.vehicleItems) {
                                    Object.values(item.docData.vehicleItems).map((veh) => { clientVehicleItems.push(veh.regNo) })
                                }
                                return (
                                    <tr key={index} onClick={handleClick} onContextMenu={handleClick}>
                                        <td id={item.docID}><p id={item.docID}>{index + 1}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.clientName}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.policyNumber}{item.docData.receiptNumber}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.endorsmentNumber}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docData.selectedProductSubClass}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{new Date(item.docData.inceptionDate).toDateString()}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{new Date(item.docData.expiryDate).toDateString()}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{new Date(item.docData.insurerDebitDate).toDateString()}</p></td>
                                        <td id={item.docID}>
                                            <p id={item.docID}>
                                                {!item.docData.totalSumInsured ? '' : item.docData.totalSumInsured}
                                                {!clientVehicleItems[0] ? '' : clientVehicleItems.map((item) => { return item + ',   ' })}
                                            </p>
                                        </td>
                                        <td id={item.docID}><p id={item.docID}>{parseInt(item.docData.policyNet).toLocaleString("en-US")}</p></td>
                                        <td id={item.docID}><p id={item.docID}>{item.docID}</p></td>
                                        {
                                            item.docData.associateDocuments
                                                ? <td onClick={() => openInNewTab(item.docData.associateDocuments[0].documentDownloadLink)}><span className='policy_list_doc_icon'><center><i className="ri-attachment-2"></i></center></span></td>
                                                : <td></td>
                                        }
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='credit_note_allocations'>
            <div className='body_section_header'>
                <p>Credit Note Allocations</p>
                <div className='right_side_controls'>
                    <span className='btn_pink'>
                        <i className="ri-filter-2-line"></i>
                    </span>
                    <div className='w_5'></div>
                    <span className='btn_pink'>
                        <i className="ri-printer-line"></i>
                    </span>
                </div>
            </div>
            <div className='body_section'>
                {allCreditsContent}
                {selectedCreditContent}
                {filteredDebitsContent}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    )
}

export default CreditNoteAllocations;