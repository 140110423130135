import React, { useState, useCallback } from 'react';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import db, { storage } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRecoilValue } from 'recoil';
import { rightNavContent, rightNavPolicyItem } from '../../../atoms';
import UUID from '../../Hooks/UUID/UUID';
import GetCookie from '../../Hooks/Cookie/GetCookie';
import { useDropzone } from 'react-dropzone';
import Loader from '../../Body/Loader/Loader';
import './LogbookUpload.css';

const LogbookUpload = (props) => {
    //current user
    const currentUser = JSON.parse(GetCookie('CurrentUserSession'));

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //#####################################################################
    //recoil values 
    const rightNavContentRV = useRecoilValue(rightNavContent)
    const rightNavPolicyItemRV = useRecoilValue(rightNavPolicyItem)

    //#####################################################################
    //log book upload progress
    const [logbookUploadProgress, setLogbookUploadProgress] = useState(0)

    //#####################################################################
    //get user input
    const [logbookFile, setLogbookFile] = useState([]);
    const [vehiclePlateNo, setVehiclePlateNo] = useState('');
    const [vehicleChassis, setVehicleChassis] = useState('');
    const [vehicleBody, setVehicleBody] = useState('');
    const [vehiclePassengers, setVehiclePassengers] = useState('');
    const [vehicleTareWeight, setVehicleTareWeight] = useState('');

    //################################################################
    //valuation file choose 
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setLogbookFile(acceptedFiles)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //step 3
    // change view on success posting
    const showMessageOnSuccessRecordUpdate = () => {
        console.log('dd')
        //open view
        const onePropObj = {
            setView: 'Policy Search',
            previousView: 'Policy Search',
            postedDocRef: vehiclePlateNo,
            dateFrom: '',
            dateTo: '',
            searchTerm: '',
            notification: 'Logbook Document Uploaded Successfully',
            activity: 'Logbook Document Upload',
        }
        props.onePropDown(onePropObj)

        //reset state var
        setLogbookFile([]);
        setVehiclePlateNo('');
        setIsLoading(false);

    }

    //################################################################
    //step 2
    //update doc record
    async function updateMotorDocumentRecord(downloadURL) {
        //create obj to post
        const associateDocObj = {
            documentDownloadLink: downloadURL,
            documentCategory: 'Logbook',
            documentVehiclePlateNo: vehiclePlateNo,
            documentVehicleChassis: vehicleChassis,
            documentVehicleBody: vehicleBody,
            documentVehiclePassengers: vehiclePassengers,
            documentVehicleTareWeight: vehicleTareWeight,
            documentPolicyNumber: rightNavPolicyItemRV.docData.policyNumber,
            documentEndorsmentNumber: rightNavPolicyItemRV.docData.endorsmentNumber,
            documentPolicyID: rightNavPolicyItemRV.docID,
            documentClientName: rightNavPolicyItemRV.docData.clientName,
            documentUploadDate: new Date(),
            uploadedBy: currentUser,
        }

        //update data
        try {

            // Create an initial document to update.
            const policyDocRef = doc(db, "PolicyDetails", rightNavPolicyItemRV.docID);

            // To update age and favorite color:
            await updateDoc(policyDocRef, {
                'motorDocuments': arrayUnion(associateDocObj)
            });

            showMessageOnSuccessRecordUpdate();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }


    //################################################################
    //step 1
    //upload doc right nav
    const logbookUploadSubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const file = logbookFile[0];

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: file.type,
            customMetadata: {
                'clientName': rightNavPolicyItemRV.docData.clientName,
                'clientID': '',
                'policyNumber': rightNavPolicyItemRV.docData.policyNumber,
                'endorsmentNumber': rightNavPolicyItemRV.docData.endorsmentNumber,
                'policyDocID': rightNavPolicyItemRV.docData.policyDocID,
                'documentID': UUID(),
            }

        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'logbook documents/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                setLogbookUploadProgress(Math.round(progress))
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }
                // if (progress < 100) { setIsLoading(true); }
                // else { setIsLoading(false); }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //console.log('File available at', downloadURL);
                    updateMotorDocumentRecord(downloadURL)
                });
            }
        );
    }

    //################################################################
    //content    
    const LogbookUploadContent = <section className='doc_upload'>
        <div className='right_nav_content'>
            <div className='h_5'></div>
            <p className='bold'>Logbook Upload</p>
            <div className='h_10'></div>
            {
                logbookUploadProgress > 0
                    ? <div><p className='f_7'>Upload Progress: {logbookUploadProgress}% Done</p></div>
                    : ''
            }
            <div className='h_5'></div>
            <div className='input_group'>
                <select className='select' onChange={(e) => { setVehiclePlateNo(e.target.value) }}>
                    <option>Select Vehicle</option>
                    {Object.values(rightNavPolicyItemRV.docData.vehicleItems).map((item, index) => {
                        return (
                            <option key={index}>{item.regNo}</option>
                        )
                    })}
                </select>
                <label className='placeholder'><p>Vehicle Plate No</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setVehicleChassis(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Chassis / Frame</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setVehicleBody(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Body</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setVehiclePassengers(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Passengers</p></label>
            </div>
            <div className='h_10'></div>
            <div className='input_group'>
                <input onChange={(e) => { setVehicleTareWeight(e.target.value) }} type='text' className='input' placeholder='' />
                <label className='placeholder'><p>Tare Weight</p></label>
            </div>
            <div className='h_5'></div>
            <div className='file_upload_container'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        logbookFile.length > 0
                            ? <p>{logbookFile[0].name}</p>
                            : isDragActive
                                ? <p>Drop the files here ...</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </div>
            <div className='h_5'></div>
            <button onClick={logbookUploadSubmitHandler} className='btn_pink'>Upload</button>
        </div>
    </section>

    return (
        <section className='logbook_upload right_nav_container body_input_styles'>
            {LogbookUploadContent}
            {loaderContent}
        </section>
    );
}

export default LogbookUpload;