import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import GetAllInsurerDetails from "../../Hooks/InsurerPortfolio/GetAllInsurerDetails";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeInsurerPortfolio, reloadOnSuccessPolicyCategoryCreationHandler, rightNavContent, rightClickedPolicyCategory,reloadOnSuccessPolicySubCategoryCreationHandler } from "../../../atoms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const PolicyCategories = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);
    const [reloadOnSuccessPolicyCategoryCreationHandlerRN, setReloadOnSuccessPolicyCategoryCreationHandlerRN] = useRecoilState(reloadOnSuccessPolicyCategoryCreationHandler);
    const [reloadOnSuccessPolicySubCategoryCreationHandlerRN,setReloadOnSuccessPolicySubCategoryCreationHandlerRN] = useRecoilState(reloadOnSuccessPolicySubCategoryCreationHandler);
    const [rightNavContentRN, setRightNavContentRN] = useRecoilState(rightNavContent);
    const [rightClickedPolicyCategoryRN, setRightClickedPolicyCategoryRN] = useRecoilState(rightClickedPolicyCategory);

    //################################################################
    //get all insurer details
    const [insurerDocs, setInsurerDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllInsurerDetails();
            setInsurerDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload]);

    //################################################################
    //filter insurer details
    const [activeInsurerDocs, setActiveInsurerDocs] = useState(null);
    useEffect(() => {
        try {

            const list = insurerDocs.filter(doc => doc.docID === activeInsurerPortfolioRN);
            setActiveInsurerDocs(list[0])

        } catch (error) { }
    }, [insurerDocs]);

    //################################################################
    //final data
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const list = [];
            if (activeInsurerDocs) {
                if (activeInsurerDocs.docData.policyCategories) {
                    const policyCategory = activeInsurerDocs.docData.policyCategories;
                    Object.keys(policyCategory).map((key) => {
                        const policySubCategory = policyCategory[key];

                        if (Object.keys(policySubCategory).length > 0) {
                            Object.keys(policySubCategory).map((subKey, index) => {

                                let originalObjkey = ''
                                if (index == 0) { originalObjkey = key }

                                list.push({
                                    categoryID: key,
                                    policyCategory: originalObjkey,
                                    policySubCategories: subKey,
                                    commissionRate: policySubCategory[subKey].commissionRate
                                })
                            })
                        }
                        else {
                            list.push({
                                categoryID: key,
                                policyCategory: key,
                                policySubCategories: '',
                                commissionRate: ''
                            })
                        }


                    })
                }
            }
            setFinalData(list);

        } catch (error) { }

    }, [activeInsurerDocs]);

    //################################################################
    // general functions
    // reload on success policy class creation
    useEffect(() => {
        if (reloadOnSuccessPolicyCategoryCreationHandlerRN) {
            setReload(Math.random(10000, 100000));
            setReloadOnSuccessPolicyCategoryCreationHandlerRN(false);
        }
    }, [reloadOnSuccessPolicyCategoryCreationHandlerRN]);

    // reload on success policy class creation
    useEffect(() => {
        if (reloadOnSuccessPolicySubCategoryCreationHandlerRN) {
            setReload(Math.random(10000, 100000));
            setReloadOnSuccessPolicySubCategoryCreationHandlerRN(false);
        }
    }, [reloadOnSuccessPolicySubCategoryCreationHandlerRN]);

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    const createSubCategoryHandler = () => {
        setxPos('-1000px');
        setyPos('-1000px');

        setRightNavContentRN('Create Policy Sub Category')
    }

    //################################################################
    //right click menu
    const URRightClickMenu = <div className="u_r_right_click_menu shadow" style={{ top: yPos, left: xPos, position: 'absolute' }}>
        <span onClick={createSubCategoryHandler}><p>Create A Sub Category</p></span>
        <div className=''></div>
    </div>

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID);
        setRightClickedPolicyCategoryRN(event.data);
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
        setRightNavContentRN('')
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }


    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'policyCategory', headerName: 'Policy Category', checkboxSelection: true, headerCheckboxSelection: true, width: 250, pinned: 'left', sortable: false, filter: false, },
        { field: 'policySubCategories', headerName: 'Policy Sub Categories', width: 250, sortable: false, filter: false, },
        { field: 'commissionRate', headerName: 'Comm Rate (%)', width: 70, sortable: false, filter: false, },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section>
            <div className='body_section_header'>
                <p>Policy Categories</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        {/* <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p> */}
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={() => { setRightNavContentRN('Create Policy Categories') }}>Create Category</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {URRightClickMenu}
        </section>
    );
}

export default PolicyCategories;