import db from "../../../../firebase";
import { doc, getDoc } from "firebase/firestore";

const GetAccessControlMenuItems = async () => {
  const docRef = doc(db, "SystemControls", "57j4Ju1V7OvwBjGP0gyi");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    const DData = docSnap.data();

    const a = [];
    Object.entries(DData).map((doc) => {
      if(doc[0] === 'accessControlMenuItems'){
a.push({ docID: doc[0], docData: doc[1] });
}
    });

    return a[0].docData;
  } else {
    // doc.data() will be undefined in this case
    //console.log("No such document!");
  }
};

export default GetAccessControlMenuItems;
