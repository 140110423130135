import React, { useState, useEffect, useRef, useCallback } from 'react';
import GetAllClients from '../../Hooks/BackendConn/ClientList/GetAllClients';
import GetAllClientsDebitsCredits from '../../Hooks/BackendConn/InsurerSpecific/GetAllClientsDebitsCredits';
import Loader from '../Loader/Loader';
import { utils, writeFileXLSX } from 'xlsx';
import NumberFormat from '../../Hooks/UI/NumberFormat';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { businessReportFilterFromDate, businessReportFilterToDate } from '../../../atoms';
import './BusinessReport.css';
import DateFormat from '../../Hooks/UI/DateFormat';

const BusinessReport = () => {
    //################################################################
    //recoil values
    const businessReportFilterFromDateRN = useRecoilValue(businessReportFilterFromDate);
    const businessReportFilterToDateRN = useRecoilValue(businessReportFilterToDate);

    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //printing
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //################################################################
    //export to excel
    var XLSX = require("xlsx");
    const tbl = useRef(null);
    const a = () => {
        const wb = XLSX.utils.table_to_book(tbl.current);
        XLSX.writeFile(wb, "ReactTable.xlsx");
    }

    const exportFile = useCallback(() => {
        const wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, "Business Report.xlsx");
    }, [tbl]);

    //################################################################
    //get all debits
    const [clientdebitsCreditsDocs, setClientdebitsCreditsDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClientsDebitsCredits();
            setClientdebitsCreditsDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 1000)) }
        })();
    }, [reload]);

    //################################################################
    //get all clients
    const [clientDocs, setClientDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllClients();
            setClientDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 1000)) }
        })();
    }, [reloadTwo]);

    //################################################################
    //filter dates
    const [selectedPeriodFilteredDocs, setSelectedPeriodFilteredDocs] = useState([]);
    useEffect(() => {
        const periodSelected = [];
        clientdebitsCreditsDocs.map((item) => {
            //clean date
            const newInceptionDate = new Date(item.docData.insurerDebitDate);
            newInceptionDate.setHours(0, 0, 0);
            //filter period choosen
            if (new Date(newInceptionDate).valueOf() >= new Date(businessReportFilterFromDateRN).valueOf() && new Date(businessReportFilterToDateRN).valueOf() >= new Date(newInceptionDate).valueOf()) {
                periodSelected.push(item);
            }
        });
        setSelectedPeriodFilteredDocs(periodSelected);
    }, [clientdebitsCreditsDocs, businessReportFilterFromDateRN, businessReportFilterToDateRN]);

    //################################################################
    //map clients
    const [clientPortfolio, setClientPortfolio] = useState([])
    useEffect(() => {
        const clientTransactions = [];
        clientDocs.map((doc) => {
            const a = selectedPeriodFilteredDocs.filter(item => item.docData.clientName === doc.docData.clientName);
            const newObj = { docID: doc.docID, docData: doc.docData, debitsCredits: a }
            clientTransactions.push(newObj)
        })
        setClientPortfolio(clientTransactions)
    }, [clientDocs, selectedPeriodFilteredDocs])

    //################################################################
    //separate d c
    const [debCreSeparation, setDebCreSeparation] = useState([])
    useEffect(() => {
        const separation = [];
        clientPortfolio.map((doc) => {
            const d = doc.debitsCredits.filter(item => item.docData.transactionType === 'Debit')
            const c = doc.debitsCredits.filter(item => item.docData.transactionType === 'Credit')
            const newObj = { docID: doc.docID, docData: doc.docData, debitsCredits: doc.debitsCredits, debits: d, credits: c }
            separation.push(newObj)
        });
        setDebCreSeparation(separation);
    }, [clientPortfolio])

    //################################################################
    //calc totals
    const [calcPortfolioTotals, setCalcPortfolioTotals] = useState([])
    useEffect(() => {
        const calcPortfolio = [];
        debCreSeparation.map((doc) => {
            const dt = []
            doc.debits.map((item) => { dt.push(item.docData.policyNet) })
            const ct = []
            doc.credits.map((item) => { ct.push(item.docData.policyNet) })

            const dtTotal = dt.reduce((total, item) => { return total + +item }, 0);
            const ctTotal = ct.reduce((total, item) => { return total + +item }, 0);
            const bt = dtTotal - ctTotal;

            const newObj = { docID: doc.docID, docData: doc.docData, debitsCredits: doc.debitsCredits, debits: doc.debits, credits: doc.credits, debitTotal: dtTotal, creditsTotal: ctTotal, businessTotal: bt }
            calcPortfolio.push(newObj)
        });

        setCalcPortfolioTotals(calcPortfolio)
    }, [debCreSeparation])

    //################################################################
    //sort by business total
    const [businessTotalSortedDocs, setBusinessTotalSortedDocs] = useState([]);
    useEffect(() => {
        const businessTotalSortedList = calcPortfolioTotals.sort(byBusinessTotal);
        function byBusinessTotal(a, b) {
            return parseInt(b.businessTotal) - parseInt(a.businessTotal)
        }
        setBusinessTotalSortedDocs(businessTotalSortedList);
    }, [calcPortfolioTotals]);

    //################################################################
    // search
    const [searchTerm, setSearchTerm] = useState('');

    const [searchedPolicyDocs, setSearchedPolicyDocs] = useState([]);
    useEffect(() => {
        const searchedDocs = [];
        const values = businessTotalSortedDocs.filter((doc) => {
            if (searchTerm === '') { return doc; }
            else if (doc.docData.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return doc;
            }
        }).map((item, index) => {
            let a = item;
            let b = Object.assign({}, a, { id: index + 1 })
            searchedDocs.push(b)
        })
        setSearchedPolicyDocs(searchedDocs);
    }, [businessTotalSortedDocs, searchTerm]);

    //################################################################
    //echo 
    const commissionContent = <section>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_header'>
                <div className='search_wrapper'>
                    <div className='input_group_search'>
                        <input onChange={(e) => setSearchTerm(e.target.value)} placeholder='' type="text" className='input_search' name="search" id="search" autoComplete="off" />
                        <label className='placeholder'><p>Search Client Name</p></label>
                    </div>
                    <span className='search_icon'><i className="ri-search-2-line"></i></span>
                </div>
            </div>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='business_report_table' ref={tbl}>
                        <thead>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client Name</p></td>
                                <td><p>Debits</p></td>
                                <td><p>Credits</p></td>
                                <td><p>Total Business</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedPolicyDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{NumberFormat(doc.debitTotal)}</p></td>
                                            <td><p>{NumberFormat(doc.creditsTotal)}</p></td>
                                            <td><p>{NumberFormat(doc.businessTotal)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    //################################################################
    //print
    const printableCommissionContent = <section style={{ display: "none" }}>
        <div className='h_5'></div>
        <div className='category'>
            <div className='category_content'>
                <div className='category_content_three'>
                    <table className='business_report_table' ref={componentRef}>
                        <thead>
                            <tr><td colSpan='10'><center><p className='bold'>Business Report</p></center></td></tr>
                            <tr><td colSpan='10'><center><p className='bold'>Period : {DateFormat(businessReportFilterFromDateRN)} - {DateFormat(businessReportFilterToDateRN)}</p></center></td></tr>
                            <tr>
                                <td><p>No</p></td>
                                <td><p>Client Name</p></td>
                                <td><p>Debits</p></td>
                                <td><p>Credits</p></td>
                                <td><p>Total Business</p></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchedPolicyDocs.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{doc.id}</p></td>
                                            <td><p>{doc.docData.clientName}</p></td>
                                            <td><p>{NumberFormat(doc.debitTotal)}</p></td>
                                            <td><p>{NumberFormat(doc.creditsTotal)}</p></td>
                                            <td><p>{NumberFormat(doc.businessTotal)}</p></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

    return (
        <section className='business_report body_input_styles'>
            <section className='agent_statement body_input_styles'>
                <div className='body_section_header'>
                    <p>Business Report</p>
                    <div className='right_side_controls'>
                        <span className='btn_pink' onClick={exportFile} >
                            <i className="ri-file-excel-2-line"></i>
                        </span>
                        <div className='w_5'></div>
                        <span className='btn_pink' onClick={handlePrint}>
                            <i className="ri-printer-line"></i>
                        </span>
                    </div>
                </div>
                <div className='body_section'>
                    {commissionContent}
                    {printableCommissionContent}
                </div>
            </section>
            {loaderContent}
        </section>
    );
}

export default BusinessReport;