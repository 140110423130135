import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllDVAllocations from "../../Hooks/BackendConn/Accounts/GetAllDVAllocations";
import Loader from "../Loader/Loader";
import { useRecoilState, useRecoilValue } from "recoil";
import { ViewDVAllocationDVDocs, DVAllocationInsurerFilter, ViewDVAllocationSelectedDV } from "../../../atoms";
import GetBusinessType from "../../Hooks/BackendConn/InsurerSpecific/GetBusinessType";
import GetADocument from "../../Hooks/BackendConn/InsurerSpecific/GetADocument";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import DateFormat from "../../Hooks/UI/DateFormat";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const ViewDVAllocation = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const DVAllocationInsurerFilterRN = useRecoilValue(DVAllocationInsurerFilter);
    const ViewDVAllocationSelectedDVRN = useRecoilValue(ViewDVAllocationSelectedDV);
    const [viewDVAllocationDVDocsRN, setViewDVAllocationDVDocsRN] = useRecoilState(ViewDVAllocationDVDocs)

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: DVAllocationInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, DVAllocationInsurerFilterRN]);

    //################################################################
    //get all DVs
    const [DVDocs, setDVDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const DDocs = await GetBusinessType({ businessType: 'DV', insurer: DVAllocationInsurerFilterRN });
            setDVDocs(DDocs)
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 1000)) }
        })();
    }, [reloadThree, DVAllocationInsurerFilterRN]);

    //################################################################
    //get past allocated dvs
    const [PastDVAllocationData, setPastDVAllocationData] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDVAllocations();
            setPastDVAllocationData(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo]);

    useEffect(() => {
        try {

            const combined = DVDocs.reduce((acc, obj) => {
                const match = PastDVAllocationData.find(item => item.docData.DVDocID === obj.docID);
                if (match) {
                    acc.push({ 'DVDebitDocID': obj.docID, 'DVDebitDocData': obj.docData, ...match });
                }
                return acc;
            }, []);

            setViewDVAllocationDVDocsRN(combined)

        } catch (error) { }
    }, [DVDocs, PastDVAllocationData]);


    const [combinedDebitDVData, setCombinedDebitDVData] = useState([])
    useEffect(() => {
        try {

            if (Object.keys(ViewDVAllocationSelectedDVRN).length > 0) {
                if (Object.keys(ViewDVAllocationSelectedDVRN.docData.DVAllocatedDebitDocs).length > 0) {

                    const combined = Object.keys(ViewDVAllocationSelectedDVRN.docData.DVAllocatedDebitDocs).reduce((acc, obj) => {
                        const match = debitDocs.find(item => item.docID === obj);
                        if (match) {
                            acc.push({ 'allocatedAmount': ViewDVAllocationSelectedDVRN.docData.DVAllocatedDebitDocs[obj]['allocatedAmount'], ...match });
                        }
                        return acc;
                    }, []);

                    setCombinedDebitDVData(combined);

                }
            }

        } catch (error) { }
    }, [ViewDVAllocationSelectedDVRN])

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            combinedDebitDVData.map((doc) => {

                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    commissionPaid: NumberFormat(doc.allocatedAmount),
                    debitedCommission: NumberFormat(doc.docData.commission),
                    debitedWithholding: NumberFormat(doc.docData.withholdingTax),
                    debitedCommissionAfterTax: NumberFormat(+doc.docData.commission - +doc.docData.withholdingTax),
                    debitedCommissionRateUsed: Math.round((+doc.docData.commission * 100) / +doc.docData.policyNet),
                    expectedCommission: NumberFormat(+doc.docData.policyNet * +doc.docData.commissionRate),
                    expectedWithholding: NumberFormat((+doc.docData.policyNet * +doc.docData.commissionRate) * 0.01),
                    expectedCommissionAfterTax: NumberFormat((+doc.docData.policyNet * +doc.docData.commissionRate) - ((+doc.docData.policyNet * +doc.docData.commissionRate) * 0.01)),
                    expectedCommissionRate: doc.docData.commissionRate * 100,

                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),
                })

            });

            setFinalData(finalObj)

        } catch (error) { console.log(error) }
    }, [combinedDebitDVData]);
    
    //################################################################
    // selected DV
    const [totalsRow, setTotalsRow] = useState([{ clientName: 'Select A DV' }])
    useEffect(() => {
        try {

            if (Object.keys(ViewDVAllocationSelectedDVRN).length > 0) {
                setTotalsRow([{
                    docID: ViewDVAllocationSelectedDVRN.DVDebitDocID,
                    clientName: ViewDVAllocationSelectedDVRN.DVDebitDocData.policyNumber,
                    policyNumber: 'DV Amounts:',
                    endorsmentNumber: NumberFormat(ViewDVAllocationSelectedDVRN.DVDebitDocData.policyNet),
                    policyClass: '',
                    inceptionDate: '',
                    expiryDate: '',
                    details: '',
                    policyNet: '',
                    commission: '',
                    withholdingTax: '',
                    commissionPayable: '',
                    commissionPaid: '',
                }])
            }

        } catch (error) { }
    }, [ViewDVAllocationSelectedDVRN])

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'commissionPaid', headerName: 'Commission Paid', sortable: false, filter: false, width: 90, },
        { field: 'debitedCommission', headerName: 'Debited Commission', sortable: false, filter: false, width: 90, },
        { field: 'debitedWithholding', headerName: 'Debited Withholding', sortable: false, filter: false, width: 90, },
        { field: 'debitedCommissionAfterTax', headerName: 'Debited Commission After Tax', sortable: false, filter: false, width: 90, },
        { field: 'debitedCommissionRateUsed', headerName: 'Debited Commission Rate Used', sortable: false, filter: false, width: 90, },
        { field: 'expectedCommission', headerName: 'Expected Commission', sortable: false, filter: false, width: 90, },
        { field: 'expectedWithholding', headerName: 'Expected Withholding', sortable: false, filter: false, width: 90, },
        { field: 'expectedCommissionAfterTax', headerName: 'Expected Commission After Tax', sortable: false, filter: false, width: 90, },
        { field: 'expectedCommissionRate', headerName: 'Expected Commission Rate', sortable: false, filter: false, width: 90, },

        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, },
        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
                pinnedBottomRowData={totalsRow}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>View DV Allocation</p>
                {/* <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div> */}
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default ViewDVAllocation;