import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Loader from "../Loader/Loader";
import GetAllActivityLogs from "../../Hooks/ActivityLog/GetAllActivityLogs";
import { useRecoilValue } from "recoil";
import { productivityReportFilterFromDate, productivityReportFilterToDate, productivityReportInsurerFilter, activeInsurerPortfolio } from "../../../atoms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DateFormat from "../../Hooks/UI/DateFormat";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'
import GetAllUserActivity from "../../Hooks/APIConn/UserActivity/GetAllUserActivity";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const ProductivityReport = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    //recoil values
    const productivityReportFilterFromDateRN = useRecoilValue(productivityReportFilterFromDate);
    const productivityReportFilterToDateRN = useRecoilValue(productivityReportFilterToDate);
    const productivityReportInsurerFilterRN = useRecoilValue(productivityReportInsurerFilter);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all activities
    const [activityLogsDocss, setActivityLogsDocss] = useState([]);
    //     const [reloadd, setReloadd] = useState(0);
    //     useEffect(() => {
    //         (async () => {
    //             setIsLoading(true)
    //             const DDocs = await GetAllUserActivity();
    //             setActivityLogsDocss(DDocs)
    //             if (DDocs) {
    //                 setIsLoading(false)
    //             }
    //             else { setReloadd(Math.random(0, 1000)) }
    //         })();
    //     }, [reloadd]);
    // console.log(activityLogsDocss)


    //################################################################
    //get all activities
    const [userActivityLogsDocs, setUserActivityLogsDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        setIsLoading(true)
        GetAllUserActivity()
            .then(data => {
                setUserActivityLogsDocs(data);
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)
            });
    }, []);

    //################################################################
    //filter dates
    const [selectedPeriodActivityLogDocs, setSelectedPeriodActivityLogDocs] = useState([]);
    useEffect(() => {
        try {

            const periodSelected = [];

            userActivityLogsDocs.map((doc) => {
                let formattedActivityDate = '';
                if(doc.date.seconds){formattedActivityDate = new Date(doc.date.seconds * 1000)}
                else {formattedActivityDate = new Date(doc.date)}
                
                formattedActivityDate.setHours(0, 0, 0);
                formattedActivityDate.setMilliseconds(0);
                
                //filter period choosen
                if (new Date(formattedActivityDate).valueOf() >= new Date(productivityReportFilterFromDateRN).valueOf() && new Date(productivityReportFilterToDateRN).valueOf() >= new Date(formattedActivityDate).valueOf()) {
                    periodSelected.push(doc);
                }
            });

            setSelectedPeriodActivityLogDocs(periodSelected);

        } catch (error) { }
    }, [userActivityLogsDocs, productivityReportFilterFromDateRN, productivityReportFilterToDateRN]);

    //################################################################
    //filter dates
    const [insurerFilterActivityLogDocs, setInsurerFilterActivityLogDocs] = useState([])
    useEffect(() => {
        try {

            const list = selectedPeriodActivityLogDocs.filter(doc => doc.insurerID === activeInsurerPortfolioRN);
            setInsurerFilterActivityLogDocs(list)

        } catch (error) { }
    }, [selectedPeriodActivityLogDocs])

    //################################################################
    //categorise per user
    const [userCategorizedData, setUserCategorizedData] = useState({});
    useEffect(() => {
        try {

            const groupedItems = insurerFilterActivityLogDocs.reduce((acc, item) => {
                if (!acc[item.user.userEmail]) {
                    acc[item.user.userEmail] = [];
                }
                acc[item.user.userEmail].push(item);
                return acc;
            }, {});

            setUserCategorizedData(groupedItems)

        } catch (error) { }
    }, [insurerFilterActivityLogDocs]);

    //################################################################
    //categorize activity
    const [activityCategorizedData, setActivityCatorizedData] = useState([]);
    useEffect(() => {
        try {
            const aa = []
            Object.keys(userCategorizedData).map(key => {
                // console.log(`Array for key '${key}':`);
                // console.log(userCategorizedData[key]);

                const groupedItems = userCategorizedData[key].reduce((acc, item) => {
                    if (!acc[item.activity]) {
                        acc[item.activity] = [];
                    }
                    acc[item.activity].push(item);
                    return acc;
                }, {});

                // aa[key] = []
                // aa[key].push(groupedItems)
                aa.push({ [key]: groupedItems });

            });

            setActivityCatorizedData(aa)

        } catch (error) { console.log(error) }
    }, [userCategorizedData])

    //################################################################
    //final
    const [finalData, setFinalData] = useState([])
    useEffect(() => {
        try {
            const list = [];
            activityCategorizedData.map((doc) => {
                Object.keys(doc).map(key => {
                    // console.log(`Array for key '${key}':`);
                    // console.log(userCategorizedData[key]);

                    Object.keys(doc[key]).map((keyy, index) => {
                        // console.log(`Array for key '${key}':`);
                        // console.log(userCategorizedData[key]);
                        if (index == 0) {
                            list.push({
                                user: key,
                                activity: keyy,
                                count: doc[key][keyy].length
                            })
                        }
                        else {
                            list.push({
                                user: '',
                                activity: keyy,
                                count: doc[key][keyy].length
                            })
                        }
                    })

                })
            })
            setFinalData(list)

        } catch (error) { }
    }, [activityCategorizedData])

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['user', 'activity', 'count']

    const exportToExcelFileName = 'PRODUCTIVITY REPORT    --    INSURER: ' + productivityReportInsurerFilterRN + '    --    PERIOD: ' + DateFormat(productivityReportFilterFromDateRN) + ' - ' + DateFormat(productivityReportFilterToDateRN) + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.user,
                doc.activity,
                doc.count,
            ])
        });


        const onlyDispatchedheaderData = 'PRODUCTIVITY REPORT    ||    INSURER: ' + productivityReportInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(productivityReportFilterFromDateRN) + ' - ' + DateFormat(productivityReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 140, 260, 40],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 4, alignment: 'center' }, {}, {}, {}],
                            ['NO', 'USER', 'ACTIVITY', 'COUNT'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.user,
                doc.activity,
                doc.count,
            ])
        });


        const onlyDispatchedheaderData = 'PRODUCTIVITY REPORT    ||    INSURER: ' + productivityReportInsurerFilterRN + '    ||    PERIOD: ' + DateFormat(productivityReportFilterFromDateRN) + ' - ' + DateFormat(productivityReportFilterToDateRN);

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto'],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 4, alignment: 'center' }, {}, {}, {}],
                            ['NO', 'USER', 'ACTIVITY', 'COUNT'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'portrait',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'user', headerName: 'User', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 300, width: 300, pinned: 'left', sortable: false, filter: false, },
        { field: 'activity', headerName: 'Activity', width: 150, sortable: false, filter: false, },
        { field: 'count', headerName: 'Count', width: 150, sortable: false, filter: false, },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>


    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Productivity Report</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default ProductivityReport;