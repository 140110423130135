import { useRecoilState } from "recoil";
import { conclusiveMotorReportFilterFromDate, conclusiveMotorReportFilterToDate } from "../../../atoms";
import DatePicker from "react-date-picker";

const ConclusiveMotorReportFilter = () => {
    //################################################################
    //get recoil values
    const [conclusiveMotorReportFilterFromDateRN, setConclusiveMotorReportFilterFromDateRN] = useRecoilState(conclusiveMotorReportFilterFromDate);
    const [conclusiveMotorReportFilterToDateRN, setConclusiveMotorReportFilterToDateRN] = useRecoilState(conclusiveMotorReportFilterToDate)

    return (
        <section>
            <div className='right_nav_content'>
                <div className='h_15'></div>
                <div><p className='right_nav_help'>This report queries policies using Policy Inception Date</p></div>
                <div className='h_15'></div>
                <p className='bold'>Filter Date</p>
                <div className='h_5'></div>
                <div className='lapse_date_picker_container'>
                    <p>From</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setConclusiveMotorReportFilterFromDateRN} value={conclusiveMotorReportFilterFromDateRN} format='d/ M/y' />
                </div>
                <div className='h_10'></div>
                <div className='lapse_date_picker_container'>
                    <p>To</p>
                    <div className='h_5'></div>
                    <DatePicker onChange={setConclusiveMotorReportFilterToDateRN} value={conclusiveMotorReportFilterToDateRN} format='d/ M/y' />
                </div>
            </div>
        </section>
    )
}

export default ConclusiveMotorReportFilter;