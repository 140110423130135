import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import GetAllDocsTransactionAndInsurerWise from "../../Hooks/BackendConn/InsurerSpecific/GetAllDocsTransactionAndInsurerWise";
import GetAllReceiptsInsurerID from '../../Hooks/BackendConn/InsurerSpecific/GetAllReceiptsInsurerID';
import Loader from "../Loader/Loader";
import { useRecoilValue } from "recoil";
import { realTimeBalanceInsurerFilter, activeInsurerPortfolio } from "../../../atoms";
import DateFormat from "../../Hooks/UI/DateFormat";
import NumberFormat from "../../Hooks/UI/NumberFormat";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeader from '../CustomHeader';
import '../GridExample.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const hashValueGetter = (params) => {
    return params.node ? params.node.rowIndex + 1 : null;
};

const RealTimeBalance = () => {
    //################################################################
    //loading
    const [isLoading, setIsLoading] = useState(false);
    let loaderContent = '';
    if (isLoading === true) {
        loaderContent = <Loader />
    }

    //################################################################
    // recoil values
    const realTimeBalanceInsurerFilterRN = useRecoilValue(realTimeBalanceInsurerFilter);
    const activeInsurerPortfolioRN = useRecoilValue(activeInsurerPortfolio);

    //################################################################
    //get all debits
    const [debitDocs, setDebitDocs] = useState([]);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: realTimeBalanceInsurerFilterRN, transactionType: 'Debit' });
            setDebitDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReload(Math.random(0, 10000)) }
        })();
    }, [reload, realTimeBalanceInsurerFilterRN]);

    //################################################################
    //get all debits
    const [cleanDebitDocs, setCleanDebitDocs] = useState([]);
    useEffect(() => {
        try {

            const list = debitDocs.filter(doc => doc.docData.businessType !== 'DV' && doc.docData.businessType !== 'JV' && doc.docData.businessType !== 'receiptReversal')
            setCleanDebitDocs(list)

        } catch (error) { }
    }, [debitDocs])

    //################################################################
    //get all credits
    const [creditDocs, setCreditDocs] = useState([]);
    const [reloadTwo, setReloadTwo] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllDocsTransactionAndInsurerWise({ insurerName: realTimeBalanceInsurerFilterRN, transactionType: 'Credit' });
            setCreditDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadTwo(Math.random(0, 10000)) }
        })();
    }, [reloadTwo, realTimeBalanceInsurerFilterRN]);

    //################################################################
    //get all receipts
    const [receiptDocs, setReceiptDocs] = useState([]);
    const [reloadThree, setReloadThree] = useState(0);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const DDocs = await GetAllReceiptsInsurerID({ insurerID: activeInsurerPortfolioRN });
            setReceiptDocs(DDocs);
            if (DDocs) {
                setIsLoading(false)
            }
            else { setReloadThree(Math.random(0, 10000)) }
        })();
    }, [reloadThree, activeInsurerPortfolioRN]);

    //################################################################
    //create fields in debit
    const [editableDebitDocs, setEditableDebitDocs] = useState([]);
    const [nonEditableDebitDocs, setNonEditableDebitDocs] = useState([]);
    useEffect(() => {
        try {

            const editableDebitDoc = []
            cleanDebitDocs.map((doc) => {
                editableDebitDoc.push({ docData: doc.docData, docID: doc.docID, policyCredits: [], receiptNumbers: [], policyCreditsDetails: [], receivedAllocatedAmount: 0 });
            });
            setEditableDebitDocs(editableDebitDoc)
            setNonEditableDebitDocs(editableDebitDoc)

        } catch (error) { }
    }, [cleanDebitDocs])

    //################################################################
    //allocate credits
    useEffect(() => {
        try {

            receiptDocs.map((item) => {
                Object.values(item.docData.receiptAllocationDetails).map((value) => {
                    editableDebitDocs.map((val, index) => {
                        if (value.receivedDocID === val.docID) {
                            const Values = [...editableDebitDocs];
                            if (Values[index]['receiptNumbers'].indexOf(item.docData.receiptNumber) == -1) {
                                Values[index]['receiptNumbers'].push(item.docData.receiptNumber);
                                Values[index]['policyCredits'].push(value.receivedAllocatedAmount);
                                const a = item.docData.receiptNumber + ' - ' + DateFormat(item.docData.receiptDate) + ' - ' + value.receivedAllocatedAmount
                                Values[index]['policyCreditsDetails'].push(a);
                                setEditableDebitDocs(Values);
                            }
                        }
                    })
                });
            });

            creditDocs.map((item) => {
                editableDebitDocs.map((val, index) => {
                    if (item.docData.allocatedDocID === val.docID) {
                        const Values = [...editableDebitDocs];
                        if (Values[index]['receiptNumbers'].indexOf(item.docID) == -1) {
                            Values[index]['receiptNumbers'].push(item.docID);
                            Values[index]['policyCredits'].push(item.docData.policyNet);
                            const a = item.docData.policyNumber + ' - ' + item.docData.endorsmentNumber + ' - ' + item.docData.policyNet;
                            Values[index]['policyCreditsDetails'].push(a);
                            setEditableDebitDocs(Values);
                        }
                    }
                });
            });

        } catch (error) { }
    }, [receiptDocs, cleanDebitDocs, editableDebitDocs])

    //################################################################
    //final
    const [finalData, setFinalData] = useState([]);
    useEffect(() => {
        try {

            const finalObj = [];
            editableDebitDocs.map((doc) => {

                //look for details
                const details = []
                if (doc.docData.selectedProductClass === '(07) - Motor Private'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - Comprehensive'
                    || doc.docData.selectedProductClass === '(07) - Motor Private - TPO'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - Comprehensive'
                    || doc.docData.selectedProductClass === '(08) - Motor Commercial - TPO') {
                    if (doc.docData.vehicleItems) {
                        Object.values(doc.docData.vehicleItems).map((veh) => {
                            details.push(veh.regNo + ', ')
                        })
                    }
                }
                else {
                    details.push(doc.docData.locationOfTheRisk.slice(0, 20) + ' ...')
                }

                finalObj.push({
                    docID: doc.docID,
                    clientName: doc.docData.clientName,
                    policyNumber: doc.docData.policyNumber,
                    endorsmentNumber: doc.docData.endorsmentNumber,
                    policyClass: doc.docData.selectedProductSubClass,
                    inceptionDate: DateFormat(doc.docData.inceptionDate),
                    expiryDate: DateFormat(doc.docData.expiryDate),
                    details: details,
                    policyNet: NumberFormat(doc.docData.policyNet),

                    policyCredits: NumberFormat(doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)),
                    policyCreditsDetails: doc.policyCreditsDetails,
                    outstanding: NumberFormat(parseInt(doc.docData.policyNet) - doc.policyCredits.reduce((total, itemm) => { return total + +itemm }, 0)),
                })
            });
            setFinalData(finalObj)

        } catch (error) { }
    }, [editableDebitDocs])

    //################################################################
    // grid states
    const [rightClickedPolicyID, setRightClickedPolicyID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [exportData, setExportData] = useState([])
    const [xPos, setxPos] = useState('-1000px');
    const [yPos, setyPos] = useState('-1000px');

    //################################################################
    // functions consuming grid state
    //export to excel
    const columnsToExport = ['clientName', 'policyNumber', 'endorsmentNumber', 'policyCreditsDetails', 'policyCredits', 'outstanding', 'policyNet', 'policyClass', 'inceptionDate', 'expiryDate', 'details']

    const exportToExcelFileName = 'REAL TIME BALANCE    --    INSURER: ' + realTimeBalanceInsurerFilterRN + '.csv';

    const onExportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: exportToExcelFileName,
            columnKeys: columnsToExport,
        });
    }, []);

    //################################################################
    //print all
    const printAll = () => {

        const printData = [];
        const a = exportData.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyCreditsDetails,
                doc.policyCredits,
                doc.outstanding,
                doc.policyNet,

                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
            ])
        });


        const onlyDispatchedheaderData = 'REAL TIME BALANCE    ||    INSURER: ' + realTimeBalanceInsurerFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 110, 'auto', 'auto', 25, 70, 25, 25, 80,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 12, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'CREDIT DETAILS', 'TOTAL CREDITS', 'OUTSTANDING', 'POLICY NET', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }

    //################################################################
    //print selected
    const printSelected = () => {

        const printData = [];
        const a = selectedRows.map((doc, index) => {
            printData.push([
                index + 1,
                doc.clientName,
                doc.policyNumber,
                doc.endorsmentNumber,
                doc.policyCreditsDetails,
                doc.policyCredits,
                doc.outstanding,
                doc.policyNet,

                doc.policyClass,
                doc.inceptionDate,
                doc.expiryDate,
                doc.details.toString(),
            ])
        });


        const onlyDispatchedheaderData = 'REAL TIME BALANCE    ||    INSURER: ' + realTimeBalanceInsurerFilterRN;

        const docDefinition = {
            content: [
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [12, 'auto', 'auto', 'auto', 110, 'auto', 'auto', 25, 70, 25, 25, 80,],
                        heights: function (row) {
                            if (row == 0) { return 25 }
                        },
                        body: [
                            [{ text: onlyDispatchedheaderData, style: 'tableHeader', colSpan: 12, alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                            ['NO', 'INSURED', 'POLICY NO', 'ENDORSMENT NO', 'CREDIT DETAILS', 'TOTAL CREDITS', 'OUTSTANDING', 'POLICY NET', 'POLICY CLASS', 'INCEPTION DATE', 'EXPIRY DATE', 'DETAILS'],
                            ...printData,
                        ]
                    }
                }
            ],
            defaultStyle: {
                fontSize: 6,
            },
            pageOrientation: 'landscape',
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
        }

        pdfMake.createPdf(docDefinition).print();
    }


    //################################################################
    // Example of consuming Grid Event    
    const onSelectionChanged = () => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        const a = []
        selectedRows.forEach(function (selectedRow) {
            a.push(selectedRow)
        });
        setSelectedRows(a);
    };

    const onCellContextMenu = (event) => {
        setxPos(event.event.x);
        setyPos(event.event.y);
        setRightClickedPolicyID(event.data.docID)
    }

    const onCellClicked = () => {
        setxPos('-1000px');
        setyPos('-1000px');
    }

    const onFilterChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onSortChanged = () => {
        const data = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    const onGridReady = () => {
        const data = [];
        gridRef.current.api.forEachNode((rowNode, index) => {
            data.push(rowNode.data)
        });
        setExportData(data);
    }

    //################################################################
    //AG GRID

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 10rem)', width: '100%', }), []);

    const [rowData, setRowData] = useState(...finalData);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '#', maxWidth: 60, valueGetter: hashValueGetter, sortable: false, filter: false,
            cellRenderer: params => {
                return <div>
                    <p id="myButton" className='peer rounded'>{params.value}</p>
                </div>
            }
        },
        { field: 'clientName', headerName: 'Client', checkboxSelection: true, headerCheckboxSelection: true, minWidth: 180, width: 180, pinned: 'left', sort: 'asc', },
        { field: 'policyNumber', headerName: 'Policy No', width: 150, },
        { field: 'endorsmentNumber', headerName: 'Endor No', width: 150, },
        { field: 'policyCreditsDetails', headerName: 'Credit Details', width: 150, sortable: false, },
        { field: 'policyCredits', headerName: 'Total Credits', width: 150, sortable: false, filter: false, },
        { field: 'outstanding', headerName: 'Outstanding', width: 150, filter: false, cellStyle: { 'backgroundColor': '#B1AFCF' } },

        { field: 'policyNet', headerName: 'Policy Net', sortable: false, filter: false, width: 90, },
        { field: 'policyClass', headerName: 'Risk Class', width: 150, },
        { field: 'inceptionDate', headerName: 'Ince Date', sortable: false, filter: false, width: 100, },
        { field: 'expiryDate', headerName: 'Expi Date', sortable: false, filter: false, width: 100, },
        { field: 'details', headerName: 'Details', width: 150, },
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 120,
        resizable: true,
    }), []);

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.expandAll();
    }, []);

    //################################################################
    //content
    const content = <section style={containerStyle}>
        <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={finalData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                groupDisplayType={'groupRows'}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
                columnHoverHighlight={true}
                enableCellTextSelection={true}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onRowDataUpdated={onGridReady}
                onCellContextMenu={onCellContextMenu}
                preventDefaultOnContextMenu={true}
                onCellClicked={onCellClicked}
            ></AgGridReact>
        </div>
    </section>

    return (
        <section className='policy_list_grid'>
            <div className='body_section_header'>
                <p>Real Time Balance</p>
                <div className='cursor-pointer'>
                    <p className='peer px-3 py-2 text-white bg-pink-600 hover:bg-pink-700 rounded'>Controls</p>
                    <div className='hidden absolute shadow-lg bg-white  rounded peer-hover:flex hover:flex w-[170px] flex-col z-index:999'>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printAll}>Print</p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={printSelected}>Print Selected </p>
                        <p className='hover:bg-gray-200 px-2 py-2 rounded' onClick={onExportToExcel}>Export To Excel</p>
                    </div>
                </div>
            </div>
            <div className='body_section'>
                {content}
            </div>
            {loaderContent}
            {/* {URRightClickMenu} */}
        </section>
    )
}

export default RealTimeBalance;